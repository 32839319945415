import { useLayoutEffect, useRef } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'

import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_SPACING, darkblue, white } from '../styles'
import FastBuilderContainer from '../components/planBuilder/FastBuilderContainer'
import Form from '../components/Form'
import Input from '../components/Input'
import InformationBlock from '../components/InformationBlock'
import { AgeValidationSchema, Fields } from '../schemas/fastPlanSchemas'
import { currentUserSelector } from '../selectors'
import generateBirthday from '../functions/generateFakeUserBirthday'
import { setFastPlanModeData } from '../actions'
import computeAge from '../functions/computeAge'

const basicStyles = {
  container: { flex: 1 },
  title: {
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '500'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {},
  [minSize(DEVICE_SIZES.LG)]: {}
})

function EditAgeScreen({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const ageField = useRef()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => currentUserSelector(state))

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerTintColor: white,
      title: null
    })
  }, [])

  const handeSubmit = (age) => {
    const birthday = currentUser.is_fake ? generateBirthday(age) : currentUser.birth_date
    dispatch(setFastPlanModeData({ birthday }))
    navigation.navigate('Modal', { screen: 'Fast Edit Location' })
  }

  return (
    <FastBuilderContainer currentPage="Age">
      <View style={styles.container}>
        <Form
          buttonText="Next"
          containerStyle={{ width: '100%' }}
          validationSchema={AgeValidationSchema}
          initialValues={{
            age: currentUser.is_fake ? '' : computeAge(currentUser.birth_date)
          }}
          withPadding={false}
          onSubmit={(values) => handeSubmit(values.age)}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <View style={{ width: '100%', paddingTop: 80 }}>
              <Text style={styles.title}>I am currently</Text>
              <View style={{ marginTop: DEFAULT_SPACING * 2 }}>
                <Input
                  ref={ageField}
                  keyboardType="numeric"
                  label="Age"
                  name={Fields.age}
                  value={values.age}
                  touched={touched[Fields.age]}
                  errorMessage={errors[Fields.age]}
                  onChangeText={(v) => {
                    setFieldValue(Fields.age, v)

                    if (v) {
                      const now = new Date()
                      const currentYear = now.getFullYear()
                      const birthYear = currentYear - v
                      setFieldValue(Fields.birth_date, new Date(birthYear, 1, 1))
                    }
                  }}
                />
              </View>
              <Text style={styles.title}>years old</Text>
              <View style={{ marginTop: Platform.OS !== 'android' ? 100 : 40 }}>
                <InformationBlock text="Don’t worry you will be able to change all of this at any time" />
              </View>
            </View>
          )}
        </Form>
      </View>
    </FastBuilderContainer>
  )
}

export default EditAgeScreen
