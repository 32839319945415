import { Linking } from 'react-native'
import * as WebBrowser from 'expo-web-browser'
import MoreTools from './components/tools/MoreTools'

function TWToolsScreen({ navigation }) {
  const title = 'More From Troutwood'
  const sections = [
    {
      image: require('./assets/bsh/icon.png'),
      title: 'Buy Sell Hold',
      subtitle: 'An investment game',
      onPress: () => {
        Linking.openURL('https://buysellhold.app.link/download')
      }
    }, {
      image: require('./assets/crisis-calculator-icon.png'),
      title: 'Crisis Calculator',
      subtitle: 'OH NO the stock market dropped',
      onPress: () => {
        WebBrowser.openBrowserAsync('https://www.troutwood.com/crisis-calculator')
      }
    }, {
      image: require('./assets/finulator/icon.png'),
      title: 'Finulator',
      subtitle: 'Practice adulting before you become one',
      onPress: () => {
        WebBrowser.openBrowserAsync('https://finulator.troutwood.com/')
      }
    }, {
      image: require('./assets/stock-market-calculator-icon.png'),
      title: 'Stock Market Calculator',
      subtitle: 'Investing and time are a powerful combination',
      onPress: () => {
        WebBrowser.openBrowserAsync('https://www.troutwood.com/sp500-calculator')
      }
    }, {
      image: require('./assets/timeportal/icon.png'),
      title: 'Time Portal',
      subtitle: 'A stock market simulation',
      onPress: () => {
        Linking.openURL('https://troutwood-timeportal.app.link/download')
      }
    }
  ]

  return (
    <MoreTools navigation={navigation} sections={sections} title={title} />
  );
}

export default TWToolsScreen
