import {
  pink,
  primary,
  info,
  purple,
  moss,
  red,
  gray,
  lightgray,
  orange,
  darkOrange,
  skyblue
} from '../styles'

export const DARK_BACKGROUND_ROUTES = [
  'Wellness Checklist'
]

export const APP_LINK = 'https://troutwood.app.link'
export const APP_LINK_TITLE = 'Found an interesting financial planning app, thought you might like it!'

export const VACATION = 'VACATION'
export const DONATION = 'DONATION'
export const EXPEND = 'EXPEND'
export const WINDFALL = 'WINDFALL'
export const BUY_CAR = 'BUY_CAR'
export const HOME_BUY = 'HOME_BUY'
export const HOME_SELL = 'HOME_SELL'
export const WEDDING = 'WEDDING'
export const WEDDING_KID = 'WEDDING_KID'
export const COLLEGE_SAVE = 'COLLEGE_SAVE'
export const COLLEGE_PAY = 'COLLEGE_PAY'
export const COLLEGE_KID_PAY = 'COLLEGE_KID_PAY'
export const PET = 'PET'

export const DOWNPAYMENT_OPTIONS = [{
  leftText: 'Down Payment as a Percentage',
  value: false
}, {
  leftText: 'Down Payment as a Dollar Amount',
  value: true
}]

export const pagesToNavigationWellness = [{
  value: 'Go to Career Explore',
  route: 'Career Explore Intro'
},
{
  value: 'Go to Emergency Preparedness',
  route: 'Emergency Preparedness Intro'
},
{
  value: 'Go to Plans',
  route: 'Plan Builder Intro'
},
{
  value: 'Go to Start Investing',
  route: 'Plan Summary'
},
{
  value: 'Go to Life-hacks',
  route: 'Plan Summary'
},
{
  value: 'Go to Living Essentials',
  route: 'Plan Summary'
},
{
  value: 'Go to Finmoji',
  route: 'My Finmoji'
},
{
  value: 'Go to Debt Rank',
  route: 'My Debts'
},
]
export const lifeEventOptions = {
  VACATION: { label: 'Vacation', icon: 'island-tropical', color: pink, value: VACATION },
  DONATION: { label: 'Large Donation', icon: 'envelope-open-dollar', color: primary, value: DONATION },
  EXPEND: { label: 'One Time Expense', icon: 'sack-dollar', color: red, value: EXPEND },
  WINDFALL: { label: 'One Time Windfall', icon: 'sack-dollar', color: info, value: WINDFALL },
  BUY_CAR: { label: 'Buy a Car', icon: 'car', color: purple, value: BUY_CAR },
  HOME_BUY: { label: 'Buy a Home', icon: 'house-circle-check', color: moss, value: HOME_BUY },
  // HOME_SELL: { label: 'Sell a Home', icon: 'house-circle-xmark', color: red, value: HOME_SELL },
  WEDDING: { label: 'Wedding', icon: 'rings-wedding', color: gray, value: WEDDING },
  WEDDING_KID: { label: "Child's Wedding", icon: 'rings-wedding', color: lightgray, value: WEDDING_KID },
  COLLEGE_SAVE: { label: 'Save for College', icon: 'building-columns', color: orange, value: COLLEGE_SAVE },
  COLLEGE_PAY: { label: 'Go to School', icon: 'graduation-cap', color: red, value: COLLEGE_PAY },
  COLLEGE_KID_PAY: { label: "Child's Education", icon: 'graduation-cap', color: darkOrange, value: COLLEGE_KID_PAY },
  PET: { label: 'Buy a Pet', icon: 'dog', color: skyblue, value: PET },
}

export const investorProfileOptions = [{
  label: 'Custom',
  sublabel: 'Add any investments you\'d like to contribute to for your goal!',
  value: 'DOLLAR_AMOUNT',
  icon: 'star',
  color: pink,
  route: 'Custom Goal'
},
{
  label: 'Custom',
  sublabel: 'You have edited your goal to a customized contribution.',
  value: 'ADVANCE',
  icon: 'star',
  color: pink,
  route: 'Custom Goal'
},
{
  label: 'Custom',
  sublabel: 'Add any investments you\'d like to contribute to for your goal!',
  value: 'CUSTOM_GOAL',
  icon: 'star',
  color: pink,
  route: 'Custom Goal'
},
{
  label: 'Custom',
  sublabel: 'You have edited your goal to a customized contribution.',
  value: 'ADVANCE',
  icon: 'star',
  color: pink,
  route: 'Custom Goal'
},
{
  label: 'Custom',
  sublabel: 'Add any investments you\'d like to contribute to for your goal!',
  value: 'CUSTOM_GOAL',
  icon: 'star',
  color: pink,
  route: 'Custom Goal'
}, {
  label: 'Dollar Per Day',
  sublabel: 'Contribute a dollar a day',
  value: 'DOLLAR_A_DAY',
  icon: 'calendar-day',
  color: purple,
  moreInformation: "Doing a little bit, a lot of times can really add up, over time.  This goal profile is for younger first-time investors who want to get started, but don't have much to start with."
}, {
  label: 'Millionaire Maker',
  sublabel: 'Plan to become a millionaire',
  value: 'MILLIONAIRE_MAKER',
  icon: 'money-bill-wave',
  color: gray,
  moreInformation: 'You can do it!  Use the life hacks, build a plan to be a millionaire and "make" it happen'
}, {
  label: 'Stay the Course (Percent)',
  sublabel: 'Contribute 10% of your income, as your income grows',
  value: 'PCT_OF_SALARY',
  icon: 'badge-percent',
  color: primary,
  moreInformation: 'This goal profile is for investors who want to contribute a percentage of their income, as their income grows.  It\'s a great way to start investing, but it\'s not for everyone.  If you\'re not sure, start with the Dollar Per Day profile.'
}, {
  label: 'Roth IRA Maximizer',
  sublabel: 'Maximize your Roth IRA contribution, each year',
  value: 'ROTH_IRA',
  icon: 'sack-dollar',
  color: orange,
  moreInformation: "A Roth IRA is well suited for young investors with earned income and a low tax rate.  It's also appropriate for investors that dislike uncertainty.  The money in a Roth IRA is made with after tax dollars, which means as it grows, it's your money - all of it."
},]

export const employerSponsoredOptions = [
  {
    label: 'Retirement Maximizer',
    sublabel: 'Maximize your 401k contribution, each year',
    value: 'MAX_401K',
    icon: 'piggy-bank',
    color: info,
    moreInformation: "The most common employee directed type of retirement.  Start on day 1 of your job, don't stop contributing and importantly, contribute at least the minimum percent of your salary to receive the maximum company match from your employer."
  },
  {
    label: 'Pension',
    sublabel: 'A percent of salary for life',
    value: 'PENSION',
    icon: 'lock-open',
    color: orange,
    route: 'Pension',
    moreInformation: 'A pension is when individuals and often their employers contribute money over time into a special fund, which is then invested. When individuals retire, they receive regular payments from this fund to support their living expenses during their retirement years.'
  }
]

export const ANNUAL = 'annual'
export const MONTHLY = 'monthly'
export const WEEKLY = 'weekly'

export const AVERAGE = 'average'
export const BEST = 'best'
export const WORST = 'worst'
