import { stateReducer } from '@truefit/redux-utils'
import {
  GET_MILITARY_OCCUPATION_FAILURE,
  GET_MILITARY_OCCUPATION_REQUEST,
  GET_MILITARY_OCCUPATION_SUCCESS,
  CLEAR
} from '../actions';

const initialState = {
  militaryOccupations: null,
  loading: false,
  error: null,
}

export default stateReducer(initialState, {
  [GET_MILITARY_OCCUPATION_REQUEST]: (state) => ({ ...state, loading: true, error: null }),
  [GET_MILITARY_OCCUPATION_SUCCESS]: (state, payload) => (
    { ...state, militaryOccupations: payload, loading: false, error: null }),
  [GET_MILITARY_OCCUPATION_FAILURE]: (state, payload) => ({ ...state, loading: false, error: payload }),
  [CLEAR]: () => initialState
});
