import { View, StyleSheet, Image, Text } from 'react-native'
import { Formik } from 'formik'
import { useNavigation } from '@react-navigation/core'
import { useDispatch } from 'react-redux'
import SlideupDrawer from '../SlideupDrawer'
import Button from '../Button'
import Input from '../Input'
import Screen from '../Screen'

import {
  ValidationSchema,
  Fields
} from '../../schemas/planNameSchema'

import { saveCurrentPlan, saveProPlan } from '../../actions'
import { darkblue, gunmetal } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 80,
    flex: 1,
    height: '100%'
  },
  keyboardAvoidingViewStyles: { height: '100%' },
  image: {
    width: 110,
    height: 110,
    marginBottom: DEFAULT_SPACING,
    alignSelf: 'center'
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center'
  },
  body: {
    marginVertical: DEFAULT_SPACING,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center'
  },
  button: { marginTop: DEFAULT_SPACING },
  input: {
    backgroundColor: 'rgba(242, 248, 250, 0.9)',
    marginBottom: 12
  }
})

export default function NamePlanSlideupDrawer({ visible, setVisible, setSaving, plan, navigate }) {
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const finn = require('../../assets/finn/smart.png')

  function navigateAfterSave() {
    navigation.pop()
    navigation.goBack()
  }

  async function handleFreeSubmit(values) {
    await dispatch(saveCurrentPlan(values))
  }

  async function handleProSubmit(values) {
    await dispatch(saveProPlan(values))
  }

  async function handleSubmit(values) {
    if (setSaving) setSaving(true)

    try {
      if (plan.pro) {
        const payload = {
          ...values,
          planId: plan.planId
        }
        await handleProSubmit(payload)
      } else {
        await handleFreeSubmit(values)
      }

      if (setSaving) setSaving(false)
      setVisible(false)
      if (navigate) navigateAfterSave()
    } catch (e) {
      setVisible(false)
      if (setSaving) setSaving(false)
      console.warn(e, e.response)
    }
  }

  return (
    <SlideupDrawer snapPointIndex={3} scrollEnabled={false} visible={visible} setVisible={setVisible}>
      <Screen disableScroll={false} keyboardAvoidingViewStyles={styles.keyboardAvoidingViewStyles} style={styles.container}>
        <Image style={styles.image} source={finn} resizeMode="contain" />

        <Text style={styles.header}>Save Plan</Text>
        <Text style={styles.body}>You can compare this plan with other plans by accessing the comparison tool. Just tap on the menu icon in the top right hand corner and view your saved plans!</Text>

        <Formik
          enableReinitialize
          validationSchema={ValidationSchema}
          initialValues={{ name: plan.name }}
          onSubmit={handleSubmit}
        >
          {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, touched, errors }) => (
            <View>
              <Input
                name={Fields.name}
                touched={touched[Fields.name]}
                errorMessage={errors[Fields.name]}
                onChangeText={handleChange('name')}
                onBlur={handleBlur('name')}
                value={values.name}
                style={styles.input}
                label="Name"
                onSubmitEditing={handleSubmit}
              />
              <Button disabled={isSubmitting} style={styles.button} onPress={handleSubmit}>Submit</Button>
            </View>
          )}
        </Formik>
      </Screen>
    </SlideupDrawer>
  )
}
