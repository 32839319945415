import { Platform, StyleSheet, View, Text, Image, useWindowDimensions } from 'react-native'
import HTML from 'react-native-render-html'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import HeaderLabel from '../HeaderLabel'
import Card from '../Card'

import useImageAspectRatio from '../../hooks/useAspectRatio'

import { grayblue, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const basicStyles = {
  container: {
    backgroundColor: 'rgb(236, 241, 244)',
    flex: 1
  },
  headerContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING
  },
  descriptionContainer: {
    padding: DEFAULT_SPACING,
    backgroundColor: white,
  },
  scrollviewContentContainer: { paddingVertical: DEFAULT_SPACING },
  card: {
    padding: DEFAULT_SPACING,
    borderRadius: 12,
    flex: 1,
    marginBottom: DEFAULT_SPACING
  },
  rowParallel: { justifyContent: 'space-between' },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  cardRow: {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  cardIcon: { marginRight: DEFAULT_SPACING },
  image: {
    marginVertical: DEFAULT_SPACING,
    width: '100%'
  },
  label: {
    fontSize: 14,
    color: 'rgb(109, 122, 125)',
    marginBottom: DEFAULT_SPACING / 2
  },
  value: {
    fontSize: 24,
    fontWeight: '600',
    color: grayblue
  },
  headerLabel: {
    fontSize: 18,
    color: 'rgb(51, 63, 75)',
    marginBottom: DEFAULT_SPACING
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  { [minSize(DEVICE_SIZES.LG)]: {} }
)

const isNotWeb = Platform.OS !== 'web'

function CardRow({ label, value, icon }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  return (
    <View style={{ flex: 1 }}>
      <Text style={styles.label}>{label}</Text>
      <View style={[styles.row, styles.cardRow]}>
        {icon && <FontAwesomeIcon icon={icon} style={styles.cardIcon} fill={grayblue} size={20} />}
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  )
}

export default function CareerAdditionalInformation({ detail, occupation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const aspectRatio = useImageAspectRatio(detail?.general_image?.url)
  const displaySummary = !!((detail.description || occupation.description))

  return (
    <>
      <View style={styles.cardContainer}>
        <View style={[styles.row, styles.rowParallel]}>
          <Card style={[styles.card, { marginRight: DEFAULT_SPACING / 2 }]}>
            <CardRow
              label="Job Concentration"
              value={occupation.jobs_1000 || '-'}
            />
          </Card>
          <Card style={[styles.card, { marginLeft: DEFAULT_SPACING / 2 }]}>
            <CardRow
              label="Growth Outlook"
              icon={detail?.grow_percent_rate && detail.grow_percent_rate > 0 ? 'arrow-up' : 'arrow-down'}
              value={detail?.grow_percent_rate ? `${(detail.grow_percent_rate || 0).toFixed(2)}%` : '-'}
            />
          </Card>
        </View>

        <Card style={styles.card}>
          <CardRow
            label="Education Level"
            value={detail.entry_education_level || '-'}
          />

          {detail.additional_training && detail.additional_training != 'None'
            && (
              <CardRow
                label="Additional Education Level"
                value={detail.additional_training || '-'}
              />
            )}
        </Card>
      </View>

      {displaySummary
        && (
          <View style={styles.descriptionContainer}>
            <HeaderLabel style={styles.headerLabel}>Description</HeaderLabel>
            <Text>{detail.description || occupation.description || '-'}</Text>
            {detail?.general_image?.url
            && (
              <Image
                style={[styles.image, { aspectRatio }]}
                source={{ uri: detail?.general_image?.url }}
              />
            )}
            {detail.summary && <HTML html={detail.summary} />}
          </View>
        )}
    </>
  )
}
