import * as Yup from 'yup'

import { REQUIRED, INVALID_EMAIL, PASSWORD_REGEX } from './constants'

export const Fields = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  password1: 'password1',
  password2: 'password2',
  birthDate: 'birth_date',
  newsletter: 'newsletter',
  tos: 'tos'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.firstName]: Yup.string().required(REQUIRED),
  [Fields.lastName]: Yup.string().required(REQUIRED),
  [Fields.email]: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED),
  [Fields.birthDate]: Yup.date()
    .required(REQUIRED),
  [Fields.password1]: Yup.string()
    .required(REQUIRED)
    .matches(PASSWORD_REGEX, 'must be 9 characters and have an uppercase, lowercase, number, and 1 special character.'),
  [Fields.password2]: Yup.string()
    .required(REQUIRED)
    .matches(PASSWORD_REGEX, 'must be 9 characters and have an uppercase, lowercase, number, and 1 special character.')
    .oneOf([Yup.ref(Fields.password1), null], "Passwords don't match"),
  [Fields.newsletter]: Yup.boolean(),
  [Fields.tos]: Yup.boolean().oneOf([true], 'You must agree to the Terms of Service')
})

export const InitialValues = {
  [Fields.firstName]: '',
  [Fields.lastName]: '',
  [Fields.email]: '',
  [Fields.birthDate]: '',
  [Fields.password1]: '',
  [Fields.password2]: '',
  [Fields.newsletter]: false,
  [Fields.tos]: false
}
