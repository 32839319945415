// This component replaces the Header in react-navigation
// If that package gets upgraded, this most likely will have to be upgraded too

import * as React from 'react'
import { StackActions } from '@react-navigation/native'

import HeaderTitle from '@react-navigation/stack/src/views/Header/HeaderTitle'
import debounce from '@react-navigation/stack/src/utils/debounce'
import HeaderSegment from './CustomHeaderSegment'

export default React.memo((props) => {
  const {
    scene,
    previous,
    layout,
    insets,
    navigation,
    styleInterpolator,
  } = props
  const { options } = scene.descriptor
  const title = typeof options.headerTitle !== 'function'
      && options.headerTitle !== undefined
    ? options.headerTitle
    : options.title !== undefined
      ? options.title
      : scene.route.name

  let leftLabel

  // The label for the left back button shows the title of the previous screen
  // If a custom label is specified, we use it, otherwise use previous screen's title
  if (options.headerBackTitle !== undefined) {
    leftLabel = options.headerBackTitle
  } else if (previous) {
    const o = previous.descriptor.options

    leftLabel = typeof o.headerTitle !== 'function' && o.headerTitle !== undefined
      ? o.headerTitle
      : o.title !== undefined
        ? o.title
        : previous.route.name
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goBack = React.useCallback(
    debounce(() => {
      if (navigation.isFocused() && navigation.canGoBack()) {
        navigation.dispatch({
          ...StackActions.pop(),
          source: scene.route.key,
        })
      }
    }, 50),
    [navigation, scene.route.key]
  )

  const popToTop = React.useCallback(
    debounce(() => {
      if (navigation.isFocused() && navigation.canGoBack()) {
        navigation.dispatch({
          ...StackActions.popToTop(),
          source: scene.route.key,
        })

        navigation.dispatch({
          ...StackActions.pop(),
          source: scene.route.key,
        })
      }
    }, 50),
    [navigation, scene.route.key]
  )

  return (
    <HeaderSegment
      {...options}
      insets={insets}
      layout={layout}
      scene={scene}
      title={title}
      leftLabel={leftLabel}
      headerTitle={
        typeof options.headerTitle !== 'function'
          ? (props) => <HeaderTitle {...props} />
          : options.headerTitle
      }
      headerSubtitle={
        typeof options.headerSubtitle !== 'function'
          ? (props) => <HeaderTitle {...props} />
          : options.headerSubtitle
      }
      onGoBack={previous ? goBack : undefined}
      styleInterpolator={styleInterpolator}
      popToTop={popToTop}
    />
  )
})
