import { Platform, StyleSheet, View, Text, } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { LinearGradient } from 'expo-linear-gradient'
import TouchableOpacity from '../TouchableOpacity'
import { info, DEFAULT_SPACING, white, rgba } from '../../styles'

const basicStyles = {
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING * 4,
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING,
    borderRadius: 16,
    flexDirection: 'row',
    overflow: 'hidden'
  },
  icon: { marginRight: DEFAULT_SPACING },
  heading: {
    fontSize: 20,
    fontWeight: '600',
    color: white
  },
  text: {
    fontSize: 14,
    color: white
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: { marginLeft: 0, marginBottom: 0, },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function ProNudge({ onPress, disabled = true }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      action="Go Pro"
      style={[styles.container, { backgroundColor: disabled ? rgba(info, 0.2) : info, }]}
    >
      <LinearGradient
        colors={['#C7DDFF', info]}
        style={{
          position: 'absolute',
          left: '20%',
          top: 0,
          bottom: 0,
          transform: [{ rotateZ: '245deg' }],
          width: '141.4%', // Square root of 2 (the diagonal length of a square) multiplied by 100% (the width of the parent)
          marginTop: '-20%', // Adjusting for the increase in height due to the rotation
          opacity: 0.2
        }}
      />

      <View>
        <FontAwesomeIcon color={white} size={36} icon={['fas', 'gem']} style={styles.icon} />
      </View>
      <View>
        <Text style={styles.heading}>
          Go Pro
        </Text>

        <Text style={styles.text}>
          Upgrade to pro and live this plan!
        </Text>
      </View>

      <View style={{ width: 35 }}>
        <FontAwesomeIcon style={{ alignSelf: 'flex-end' }} icon="circle-chevron-right" size={16} color={white} />
      </View>
    </TouchableOpacity>
  )
}
