import { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { StyleSheet } from 'react-native'

import { map } from 'lodash'
import { useDispatch } from 'react-redux'
import { searchLocation } from './api/locations'

import SearchInput from './components/SearchInput'
import Container from './components/Container'
import ScrollingListContainer from './components/ScrollingListContainer'
import SearchListItem from './components/SearchListItem'

import SearchResultFinn from './components/SearchResultFinn'
import { white } from './styles/colors'

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    flex: 1
  }
})

export default function CostOfLivingLocationSearchScreen({ navigation }) {
  const [locationSearch, setLocationSearch] = useState()
  const [locationOptions, setLocationOptions] = useState([])

  async function searchLocationAPI(value) {
    if (value && value.length >= 3) {
      try {
        const locationResults = await searchLocation(value)
        setLocationOptions(locationResults.data)
      } catch (error) {
        console.error(error.response, ' -- error')
      }
    } else {
      setLocationOptions([])
    }
  }

  useEffect(() => {
    searchLocationAPI(locationSearch)
  }, [locationSearch])

  const searchInput = useRef()

  useEffect(() => {
    if (searchInput.current) searchInput.current.focus()
  }, [searchInput.current])

  useLayoutEffect(() => {
    navigation.setOptions({ title: 'Explore Locations', })
  }, [navigation])

  return (
    <Container withPadding>
      <SearchInput
        ref={searchInput}
        value={locationSearch}
        onChange={(value) => {
          setLocationSearch(value || null)
        }}
        onCancel={() => {
          setLocationSearch(null)
        }}
      />

      <ScrollingListContainer
        displayButton={false}
      >
        {map(locationOptions, (loc) => (
          <SearchListItem
            key={loc.id}
            onPress={() => {
              navigation.navigate('Modal', { screen: 'Cost of Living', params: { location: loc } })
            }}
            leftLabel={`${loc.name}`}
          />
        ))}

        {locationOptions.length == 0 && locationSearch && locationSearch.length > 3
          && (
            <SearchResultFinn
              image={require('./assets/finn/question.png')}
              title="No Results"
              subtitle="Explore another location"
            />
          )}

        {locationOptions.length == 0 && !locationSearch
          && (
            <SearchResultFinn
              image={require('./assets/finn/happy.png')}
              title="Search for a Location"
              subtitle="Try typing the city you live in now"
            />
          )}
      </ScrollingListContainer>
    </Container>
  )
}
