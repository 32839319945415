import { StyleSheet, Text, View, Image } from 'react-native'

import { useDispatch } from 'react-redux'
import Button from '../Button'
import Link from '../Link'
import Modal from '../Modal'

import { assignPrimaryPlan } from '../../actions'

import { darkblue, darkgrayblue, gunmetal } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  modal: { backgroundColor: 'rgba(6, 15, 18, 0.85)', },
  container: {
    paddingVertical: DEFAULT_SPACING,
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: 180,
    height: 20
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    zIndex: 100
  },
  body: {
    marginVertical: DEFAULT_SPACING,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center',
    zIndex: 100
  },
  emphasisedBody: {
    fontSize: 18,
    textAlign: 'center',
    zIndex: 100,
    paddingVertical: DEFAULT_SPACING
  },
  button: { marginTop: DEFAULT_SPACING },
  finn: {
    height: 120,
    width: 120,
    marginTop: DEFAULT_SPACING
  },
  link: {
    color: darkgrayblue,
    fontWeight: '600',
    marginTop: DEFAULT_SPACING
  }
})

export default function BirthDateIntroModal({ visible, setVisible }) {
  const dispatch = useDispatch()

  function handleUpdate() {
    dispatch(assignPrimaryPlan(true))
    setVisible(false)
  }

  function handleClose() {
    setVisible(false)
  }

  return (
    <Modal closeable={false} scrollEnabled={false} visible={visible} setVisible={setVisible}>
      <View style={styles.container}>
        <Text style={styles.header}>Set as Primary Plan</Text>
        <Image style={styles.finn} source={require('../../assets/finn/party.png')} resizeMode="contain" />

        <View>
          <Text style={styles.body}>
            By marking this plan as your primary plan, we will use your birth date for more accuracy.
            Do you want to continue?
          </Text>
        </View>

        <Button onPress={handleUpdate} style={styles.button}>Set as Primary Plan</Button>
        <Link onPress={handleClose} style={styles.link}>No Thanks</Link>
      </View>
    </Modal>
  )
}
