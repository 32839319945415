import { View, Text, StyleSheet } from 'react-native'
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field'

import InputWrapper from './InputWrapper'
import InformationBlock from './InformationBlock'
import { darkblue, DEFAULT_SPACING, gainsboro, lightestgray } from '../styles'

const styles = StyleSheet.create({
  inputWrapper: { backgroundColor: 'transparent', borderWidth: 0, },
  informationBlock: {
    width: 300,
    alignSelf: 'center',
    marginTop: DEFAULT_SPACING * 4
  },
  title: {
    textAlign: 'center',
    fontSize: 30
  },
  codeFieldRoot: {
    marginTop: 20,
    // width: 300,
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
  },
  cellContainer: {
    width: 53,
    height: 81,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: lightestgray,
    borderWidth: 1,
    borderColor: gainsboro,
    marginHorizontal: 2,
  },
  cell: {
    lineHeight: 45,
    fontSize: 38,
    textAlign: 'center',
    color: darkblue,
    opacity: 0.5
  },
  focusCell: { borderColor: darkblue },
})

const CELL_COUNT = 6

export default function CodeInput(props) {
  const {
    secureTextEntry,
    returnKeyType,
    onSubmitEditing,
    disabled,
    value,
    setValue,
    onChangeText,
    onFocus,
    onBlur,
    onPress
  } = props

  const cellCount = props.cellCount || CELL_COUNT

  const ref = useBlurOnFulfill({ value, cellCount })
  const [clearCellProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  })

  // remove errorMessage from props so it doesn't get passed to InputWrapper
  const inputWrapperProps = { ...props }
  delete inputWrapperProps.errorMessage

  return (
    <InputWrapper {...inputWrapperProps} inputElement={ref} onPress={() => { inputElement.current.focus() }} style={styles.inputWrapper}>
      <CodeField
        ref={ref}
        {...clearCellProps}
        value={value}
        cellCount={cellCount}
        rootStyle={styles.codeFieldRoot}
        textContentType="oneTimeCode"
        returnKeyType={returnKeyType || 'done'}
        returnKeyLabel={returnKeyType || 'done'}
        editable={!disabled}
        secureTextEntry={secureTextEntry}
        onChangeText={(val) => {
          if (setValue) setValue(val)
          if (onChangeText) onChangeText(val)
        }}
        onFocus={onFocus}
        onPress={onPress}
        onBlur={onBlur}
        onSubmitEditing={onSubmitEditing}

        renderCell={({ index, symbol, isFocused }) => (
          <View style={styles.cellContainer}>
            <Text
              key={index}
              style={[styles.cell, isFocused && styles.focusCell]}
              onLayout={getCellOnLayoutHandler(index)}
            >
              {symbol || (isFocused ? <Cursor cursorSymbol="—" /> : null)}
            </Text>
          </View>
        )}
      />

      { props.touched && props.errorMessage
        && (
          <InformationBlock
            header="Uh oh!"
            text={props.errorMessage}
            type="error"
            style={styles.informationBlock}
          />
        )}
    </InputWrapper>
  )
}
