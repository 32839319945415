import { stateReducer } from '@truefit/redux-utils'
import { each, filter } from 'lodash'
import { SET_ACCOUNTS, SET_ACCOUNT, DELETE_ACCOUNT, CLEAR } from '../actions'

const initialState = []

export default stateReducer(initialState, {
  [SET_ACCOUNT]: (state, payload) => {
    const { account, id } = payload
    const newState = []

    each(state, (item) => {
      if (item.id == id) {
        item = { ...item, ...account }
      }

      newState.push(item)
    })

    if (!id) {
      newState.push(account)
    }

    return [...newState]
  },
  [SET_ACCOUNTS]: (state, payload) => payload,
  [DELETE_ACCOUNT]: (state, id) => [...filter(state, (item) => item.id != id)],
  [CLEAR]: () => initialState
});
