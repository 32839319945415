import { groupBy, find, each } from 'lodash'
import { INCOME_TYPE } from 'constants/budgetItemConstants'
import { rgba } from 'styles'

export default function addColorAndIconToTransactions(budgetCategories, transactions) {
  const newTransactions = []
  const transactionsByCategory = groupBy(transactions, 'budgetCategory')

  Object.keys(transactionsByCategory).map((budgetCategoryKey) => {
    const budgetCategoryTransactions = transactionsByCategory[budgetCategoryKey]
    const budgetCategoryObject = find(budgetCategories, { key: budgetCategoryKey })

    const { color } = budgetCategoryObject
    const { icon } = budgetCategoryObject

    each(budgetCategoryTransactions, (transaction) => {
      const budgetSubCategoryObject = find(budgetCategoryObject.subCategories, { key: transaction.budgetSubCategory })

      newTransactions.push({
        ...transaction,
        description: budgetSubCategoryObject.label,
        amount: transaction.budgetCategory === INCOME_TYPE ? 
          transaction.amount : -transaction.amount,
        displayAmountColor: true,
        iconProps: {
          backgroundColor: rgba(color, 0.1),
          color,
          icon
        }
      })
    })
  })

  return newTransactions
}
