import { useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { each } from 'lodash'
import { VictoryChart, VictoryArea, VictoryStack, VictoryLabel, VictoryTheme, VictoryAxis } from 'victory-native'
import abbreviateNumber from '../functions/abbreviateNumber'

import TooltippedText from './TooltippedText'
import { gunmetal, info, lightestgray, primary, rgba } from '../styles/colors'

const basicStyles = {
  container: { minHeight: 200, },
  chartContainer: {
    flex: 1,
    padding: 10,
    flexDirection: 'row'
  },
  smallText: {
    textAlign: 'center',
    fontSize: 8,
    marginTop: 12
  },
  mediumText: { fontSize: 11 },
  spaceAround: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 8 / 2,
    marginRight: 4,
    marginTop: 3
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: { width: '40vh', height: '40vh', alignSelf: 'center', }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function ReturnChart({ result, scenario, minHeight }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const {
    summary_stats,
    cash_returns
  } = result

  const firstData = (summary_stats && summary_stats[scenario] && summary_stats[scenario].return_data) || []
  const firstCashData = cash_returns

  const data = []
  const years = Object.keys(cash_returns || [])

  each(years, (year, index) => {
    data.push({
      contributions: firstCashData[year] || 0,
      value: firstData[index] || 0
    })
  })

  const [investmentReturnsTooltipIsVisible, setInvestmentReturnsTooltipIsVisible] = useState(false)
  const [cumulativeContributionsTooltipIsVisible, setCumulativeContributionsTooltipIsVisible] = useState(false)

  return (
    <View style={styles.container}>
      <View style={styles.chartContainer}>
        <VictoryChart
          theme={VictoryTheme.material}
          animate={{ duration: 600 }}
          style={{ axis: { stroke: 'transparent', strokeWidth: 1 }, }}
        >
          <VictoryAxis
            tickFormat={(value) => years[value - 1]}
            axisLabelComponent={<VictoryLabel dy={12 * 2} />}
            style={{
              axis: { strokeWidth: 1, stroke: lightestgray },
              grid: { stroke: 'transparent', strokeWidth: 0 },
              tickLabels: { fontSize: 10, fill: gunmetal, padding: 10 }
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(value) => `$${abbreviateNumber(value, 1)}`}
            style={{
              axis: { strokeWidth: 1, stroke: lightestgray },
              grid: { stroke: lightestgray, strokeWidth: 1, strokeDasharray: '3' },
              tickLabels: { fontSize: 10, fill: gunmetal, padding: 10 }
            }}
          />

          <VictoryStack>
            <VictoryArea
              style={{ data: { fill: rgba(info, 0.1), stroke: info, strokeWidth: 2 } }}
              data={data.map((item) => item.contributions)}
            />
            <VictoryArea
              style={{ data: { fill: rgba(primary, 0.1), stroke: primary, strokeWidth: 2 } }}
              data={data.map((item) => item.value)}
            />
          </VictoryStack>
        </VictoryChart>
      </View>

      <View style={styles.spaceAround}>
        <View style={styles.spaceAround}>
          <View style={[styles.dot, { backgroundColor: primary }]} />
          <TooltippedText
            visible={investmentReturnsTooltipIsVisible}
            setVisible={setInvestmentReturnsTooltipIsVisible}
            anchorStyle={styles.mediumText}
            anchorText="Investment Returns"
            contentHeader="Investment Returns"
            contentBody="The total amount your investment contributions are projected to grow."
          />
        </View>
        <View style={styles.spaceAround}>
          <View style={[styles.dot, { backgroundColor: info }]} />
          <TooltippedText
            visible={cumulativeContributionsTooltipIsVisible}
            setVisible={setCumulativeContributionsTooltipIsVisible}
            anchorStyle={styles.mediumText}
            anchorText="Cumulative Contributions"
            contentHeader="Cumulative Contributions"
            contentBody="The total amount of your combined contributions into your investment account."
          />
        </View>
      </View>
    </View>
  )
}
