import { useEffect } from 'react'
import { AppState } from 'react-native'
import { useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  AUTHORIZATION_CODE_LOCAL_STORAGE_KEY,
  getAuthorizationCode
} from '../actions'

const MINUTES_TILL_EXPIRATION = 50
const INTERVAL_MINUTES = 1

function useAuthorizationCode() {
  const dispatch = useDispatch()

  useEffect(() => {
    let timeoutId

    async function checkTimeToRefresh() {
      const unparsedToken = await AsyncStorage.getItem(
        AUTHORIZATION_CODE_LOCAL_STORAGE_KEY
      )
      const tokens = unparsedToken ? JSON.parse(unparsedToken) : {}
      const { code, createdAt } = tokens

      if (code) {
        const elapsedTime = Date.now() - createdAt

        if (elapsedTime >= MINUTES_TILL_EXPIRATION * 60 * 1000) {
          dispatch(getAuthorizationCode())
        }
      }

      timeoutId = setTimeout(checkTimeToRefresh, INTERVAL_MINUTES * 60 * 1000)
    }

    checkTimeToRefresh()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return null
}

export default useAuthorizationCode
