import { View, Text, StyleSheet, Platform } from 'react-native'
import DateTimePickerModal from 'react-native-modal-datetime-picker'
import moment from 'moment'
import { white, DEFAULT_SPACING } from '../styles'

import Link from './Link'
import SlideRule from './SlideRule'

const styles = StyleSheet.create({
  monthPickerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: DEFAULT_SPACING,
    backgroundColor: white
  },
  monthPickerLink: { fontSize: 14 },
  slideRule: { backgroundColor: white }
})

export default function DateSliderRule({
  selectedDate,
  projectionDates,
  onDatePicked,
  isDatePickerVisible,
  setIsDatePickerVisible
}) {
  const monthYearFormat = (date) => moment(date).format('MMM YYYY')

  return (
    <>
      <View style={styles.monthPickerContainer}>
        <Text>Timeline</Text>
        <Link
          style={styles.monthPickerLink}
          onPress={() => { setIsDatePickerVisible(true) }}
        >
          Choose Date
        </Link>
        <DateTimePickerModal
          mode="date"
          display={Platform.OS == 'android' ? 'default' : 'spinner'}
          headerTextIOS="Select Date"
          isVisible={isDatePickerVisible}
          date={selectedDate._d}
          onConfirm={(date) => {
            setIsDatePickerVisible(false)
            onDatePicked(date)
          }}
          onCancel={() => {
            setIsDatePickerVisible(false)
          }}
        />
      </View>
      <SlideRule
        selectedItemId={monthYearFormat(selectedDate)}
        itemsPerSlide={5}
        items={projectionDates}
        onSlideEnd={({ date }) => {
          onDatePicked(date)
        }}
        style={styles.slideRule}
      />
    </>
  )
}
