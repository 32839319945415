import { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { map, uniqWith, isEqual, groupBy, orderBy } from 'lodash'
import { DEFAULT_SPACING, lightestgray, white, rgba } from './styles'

import CategoryButton from './components/wellness/CategoryButton'
import MilestoneButton from './components/wellness/MilestoneButton'

import CongratsSlideupDrawer from './components/CongratsSlideupDrawer'

import {
  completedWellnessChecklistsSelector,
  wellnessChecklistsSelector,
  currentUserSelector,
} from './selectors'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: lightestgray
  },
  contentContain: {
    paddingBottom: DEFAULT_SPACING / 2,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  contentContainer: {
    paddingBottom: 60,
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
})

export default function WellnessChecklistToolkitScreen({ navigation }) {
  const [visible, setVisible] = useState(false)
  const wellnessChecklists = useSelector((state) => wellnessChecklistsSelector(state))
  const completedWellnessChecklists = useSelector((state) => completedWellnessChecklistsSelector(state))
  const currentUser = useSelector((state) => currentUserSelector(state))
  const { metadata } = currentUser
  const orderedChecklists = orderBy(wellnessChecklists, 'position')
  const groupedChecklist = groupBy(orderedChecklists, 'category_description')
  const categories = uniqWith(map(orderedChecklists, (item) => ({
    description: item.category_description,
    color: item.category_color,
    icon: item.category_icon,
  })), isEqual)

  const [currentWellnessChecklistItemPopupText, setCurrentWellnessChecklistItemPopupText] = useState(null)

  useEffect(() => {
    const onTrackWellness = wellnessChecklists.length > 0 && wellnessChecklists.length === completedWellnessChecklists.length

    if (onTrackWellness && !currentWellnessChecklistItemPopupText) {
      setTimeout(() => { setVisible(true) }, 1000)
    }
  }, [currentWellnessChecklistItemPopupText])
  const colorGradientTop = ['#4F8FF0', '#528860', '#8674E5', '#D7A856'];
  const colorGradientBottom = ['#294EC7', '#87CA78', '#5A24AE', '#BA7A3E']
  function CategoryButtonColorBackgroundColorGradient(index, direction) {
    if (direction === 'bottom') {
      return colorGradientBottom[index] !== undefined ? colorGradientBottom[index] : white;
    }
    return colorGradientTop[index] !== undefined ? colorGradientTop[index] : white
  }

  const [expanded, setExpanded] = useState(false);
  return (
    <>

      <ScrollView showsVerticalScrollIndicator style={styles.container} contentContainerStyle={styles.contentContainer}>
        <View style={styles.contentContain}>
          <MilestoneButton
            navigation={navigation}
            expanded={expanded}
            onPress={() => {
              setExpanded(!expanded)
            }}
          />
        </View>

        {map(categories, (category, index) => (
          <CategoryButton
            {...category}
            backgroundColorGradientTop={CategoryButtonColorBackgroundColorGradient(index, 'top')}
            backgroundColorGradientBottom={CategoryButtonColorBackgroundColorGradient(index, 'bottom')}
            checklist={groupedChecklist[category.description]}
            key={`category-group-${category.description}`}

            progressBarColor={white}
            progressBarBackgroundColor={rgba(CategoryButtonColorBackgroundColorGradient(index, 'top'), 0.6)}
            backgroundColor={rgba(white, 0.3)}
            onPress={(item) => {
              navigation.navigate('Modal', { screen: 'Wellness Checklist', params: { category: { ...category, backgroundColorGradientTop: CategoryButtonColorBackgroundColorGradient(index, 'top'), backgroundColorGradientBottom: CategoryButtonColorBackgroundColorGradient(index, 'bottom') }, wellnessChecklist: groupedChecklist[category.description] } })
            }}
          />
        ))}
      </ScrollView>

      <CongratsSlideupDrawer
        visible={visible}
        setVisible={setVisible}
        title={"You've earned the On Track Shield!"}
        subtitle={"Looks like you're ready"}
        metadataKey="TROUTWOOD_CONGRADULATIONS_READINESS_SHIELD"
      />
    </>
  )
}
