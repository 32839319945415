import { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { createProPlanFromFree } from './actions'
import { NAVIGATION_TYPE } from './constants'
import handleError from 'functions/handleError'
import CloseButton from 'components/CloseButton'
import IntroScreenPro from 'components/IntroScreenPro'
import { white } from 'styles'

export default function UpgradePlanIntroScreen({ navigation, route }) {
  const dispatch = useDispatch()
  const {
    params: { freePlan }
  } = route
  const [submitting, setSubmitting] = useState(false)

  const onPress = async () => {
    // Create a new pro plan
    try {
      setSubmitting(true)
      await dispatch(createProPlanFromFree(freePlan))
      setSubmitting(false)
      navigation.navigate('Modal', { screen: 'Upgrade Plan Add Goal' })
    } catch (e) {
      setSubmitting(false)
      handleError(e)
    }
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: null,
      headerTransparent: true,
      headerLeft: null,
      headerRight: () => <CloseButton color={white} onPress={() => navigation.pop()} />
    })
  }, [navigation])

  return (
    <IntroScreenPro
      title="Live Your Plan!"
      subtitle="This tool will help you take your plan and tailor it to your current financial situation. You will get a detailed budget and net worth forecast."
      buttonText="Convert Plan"
      onPress={onPress}
      isSubmitting={submitting}
    />
  )
}

UpgradePlanIntroScreen.propTypes = {
  navigation: NAVIGATION_TYPE.isRequired,
  route: PropTypes.object.isRequired
}
