import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheck, } from '@fortawesome/pro-duotone-svg-icons'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { white } from '../../../styles'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
  },
  label: {
    fontSize: 18,
    paddingLeft: 15,
    flex: 1,
  }
})

function RadioInput({ label, checked, size = 16, gap = 40, onPress }) {
  return (
    <TouchableOpacity onPress={() => {
      if (!checked) onPress()
    }}
    >
      <View style={[styles.container, { marginBottom: gap }]}>
        <View style={[
          { width: 26, height: 26, borderRadius: 13, padding: 4, justifyContent: 'center', alignItems: 'center' },
          { backgroundColor: checked ? '#7CBA82' : '#EBF0F3' }]}
        >
          {checked && (
            <FontAwesomeIcon
              color={white}
              size={size}
              icon={faCheck}
            />
          )}
        </View>
        <Text style={styles.label}>{label}</Text>
      </View>
    </TouchableOpacity>
  )
}

export default RadioInput
