import { useLayoutEffect, useState } from 'react'
import { Image, Platform, View, ScrollView, StyleSheet, Text } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { times, map } from 'lodash'

import Container from './components/Container'
import ReturnChart from './components/ReturnChart'

import formatCurrency from './functions/formatCurrency'
import investmentSelection from './functions/investmentSelection'
import DataRow from './components/DataRow'
import PillGroup from './components/PillGroup'

import { DEFAULT_SPACING, gunmetal, lightgray } from './styles'
import { MONTHLY, WEEKLY, BEST, WORST, AVERAGE } from './constants'

const basicStyles = {
  container: { flex: 1 },
  textContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingTop: DEFAULT_SPACING * 2
  },
  title: {
    fontSize: 48,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -0.6
  },
  smallTitle: {
    fontSize: 24,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -0.6,
    width: '80%',
    alignSelf: 'center',
    marginTop: DEFAULT_SPACING * 2
  },
  tableContainer: { width: '100%', },
  help: {
    fontSize: 16,
    textAlign: 'center',
    letterSpacing: -0.67,
    color: gunmetal,
    marginVertical: DEFAULT_SPACING * 2,
    lineHeight: 24
  },
  divider: {
    marginVertical: DEFAULT_SPACING * 2,
    width: '100%'
  },
  smallText: {
    textAlign: 'center',
    fontSize: 8,
    marginTop: 12
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      tableContainer: {
        width: '40vw',
        alignSelf: 'center',
        borderWidth: 1,
        borderColor: lightgray,
        borderRadius: 6,
        padding: DEFAULT_SPACING,
        marginBottom: DEFAULT_SPACING,
      }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

function DataTable({ result, duration }) {
  let divisor = 1
  if (duration === MONTHLY) {
    divisor = 12
  } else if (duration === WEEKLY) {
    divisor = 52
  }

  const salary = (result.gross_income && result.gross_income[duration]) || 0
  const annualGoal = result.annual_contribution_amount / divisor

  const data = [
    {
      label: 'Investment Type',
      value: investmentSelection(result.investment_selection)
    },
    {
      label: 'Annual Contribution',
      value: `$${formatCurrency(annualGoal || 0)}`
    },
    {
      label: 'Percent of Salary',
      value: `${((annualGoal / salary) * 100).toFixed(1)}%`
    }
  ]

  return map(data, (rowProps, index) => (
    <DataRow key={rowProps.label} {...rowProps} index={index} />
  ))
}

export default function FreeEstimatedValueScreen({ navigation, route }) {
  const {
    params: { result, years, scenario: originalScenario }
  } = route
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [scenario, setScenario] = useState(originalScenario || AVERAGE)

  const today = new Date()
  const yearOptions = times(years, (i) => today.getFullYear() + i)

  const options = [
    {
      label: 'Best',
      inverted: true,
      active: scenario === BEST,
      onPress: () => setScenario(BEST)
    },
    {
      label: 'Average',
      inverted: true,
      active: scenario === AVERAGE,
      onPress: () => setScenario(AVERAGE)
    },
    {
      label: 'Worst',
      inverted: true,
      active: scenario === WORST,
      onPress: () => setScenario(WORST)
    }
  ]

  const investmentData =
    (result.summary_stats && result.summary_stats[scenario]) || {}
  const estimatedValue = investmentData.projected_ending_value
  const isPension = result.contribution_selection === 'PENSION'

  useLayoutEffect(() => {
    if (isPension) navigation.setOptions({ headerTitle: 'Future Value' })
  }, [navigation])

  return (
    <Container style={styles.container}>
      <ScrollView contentContainerStyle={{ paddingBottom: 100 }}>
        {!isPension && (
          <>
            <Text style={styles.title}>${formatCurrency(estimatedValue)}</Text>
            <PillGroup options={options} />
            <ReturnChart
              result={result}
              years={yearOptions}
              scenario={scenario}
            />
          </>
        )}

        <View style={styles.textContainer}>
          {!isPension && (
            <View style={styles.tableContainer}>
              <DataTable result={result} duration="annual" />
            </View>)}
          <Image
            source={require('./assets/tools-journey.png')}
            resizeMode="contain"
            style={{ width: '100%', height: 400 }}
          />

          <Text style={styles.smallTitle}>Future Value</Text>

          {isPension ? (
            <Text style={styles.help}>
              Your Estimated Lump Sum Value is the amount you'd receive if you
              chose to take your pension benefits as a single payment the day
              you retire, instead of receiving periodic payments in the future.
              It's calculated based on various factors, including your salary at
              retirement, pension multiplier, and years you plan to be retired.
            </Text>
          ) : (
            <Text style={styles.help}>
              Estimated future values are based on the best, worst and average
              return sequences, using historical data (1926-2022) for calendar
              years, for the length of time selected. Historical returns are
              nominal and include dividend reinvestment. Investing involves
              risk, including possible loss of principal.
            </Text>
          )}

          {!isPension && (
            <Text style={styles.smallText}>
              *Graph for illustrative purposes only and not intended as an
              investment recommendation.
            </Text>
          )}
        </View>
      </ScrollView>
    </Container>
  )
}
