import { useState } from 'react'
import { View, StyleSheet, Platform, Text, Image } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { LinearGradient } from 'expo-linear-gradient'

import { useSafeArea } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import {
  darkblue,
  primaryGradientEnd,
  primaryGradientStart,
  darkJungleGreen
} from '../styles/colors'
import SFPro from '../fonts/SF-Pro-Display-Medium.otf'
import ApplePlay from '../assets/btn-appstore-white.png'
import GooglePlay from '../assets/btn-googleplay-white.png'

import TroutwoodLogo from '../components/TroutwoodLogo'

import PlanBuilderScreen from '../PlanBuilderScreen'
import ToolsScreen from '../ToolsScreen'
import DashboardScreen from '../DashboardScreen'

import BottomTabBar from '../components/BottomTabBar'
import HeaderBackImage from '../components/HeaderBackImage'
import CustomHeader from '../components/CustomHeader'
import AddRightButton from '../components/AddRightButton'

import { deviceSelector, currentUserSelector } from '../selectors'
import { setCurrentPlan } from '../actions'
import useAuthorizationCode from '../hooks/useAuthorizationCode'

const TabbedStack = createBottomTabNavigator()

const basicStyles = {
  headerStyle: {
    height: 105,
    elevation: 0,
    shadowOpacity: 0
  },
  headerTitleStyle: {
    fontSize: 20,
    color: darkblue,
    textAlign: 'center'
  },
  headerSubtitleStyle: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: '400',
    letterSpacing: -0.48,
    marginTop: 2
  },
  playStores: {
    height: 76,
    width: 196,
    margin: 'auto',
    justifyContent: 'center'
  },
  troutwoodLogo: {
    height: 286,
    width: 286,
    position: 'relative',
    justifyContent: 'center',
    margin: 'auto',
    marginTop: 80
  },
  troutwoodLogo2: {
    position: 'relative',
    justifyContent: 'center',
    margin: 'auto',
    marginTop: 10,
    marginBottom: 20
  },
  container: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
    left: 0,
    width: '100%'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.SM)]: {
    container: {
      overflowY: 'hidden',
      left: 80,
      width: 'calc(100% - 80px)'
    }
  }
})

const isNotWeb = Platform.OS !== 'web'

const options = {
  headerBackTitleVisible: false,
  headerBackImage: HeaderBackImage,
  header: (props) => <CustomHeader {...props} />
}

function CreatePlanAddButton() {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const device = useSelector((state) => deviceSelector(state))
  const currentUser = useSelector((state) => currentUserSelector(state))

  return (
    <AddRightButton
      onPress={() => {
        const birth_date = currentUser.is_fake ? null : currentUser.birth_date
        dispatch(setCurrentPlan({ step: 0, device, birth_date }))
        navigation.navigate('Modal', { screen: 'Plan Builder Intro' })
      }}
    />
  )
}

const addRightButton = { headerRight: () => <CreatePlanAddButton /> }

function ToolsStackScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const ToolsStack = createStackNavigator()
  return (
    <ToolsStack.Navigator headerMode="float" screenOptions={{ ...styles }}>
      <ToolsStack.Screen
        name="Tools"
        component={ToolsScreen}
        options={{ ...options, ...{ title: 'Tools', headerShown: isNotWeb } }}
      />
    </ToolsStack.Navigator>
  )
}

function WebWrapper(props) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { navigation, route } = props
  const stackModal = route.name

  const screensMapping = {
    'Plan Builder': <PlanBuilderScreen navigation={navigation} route={route} />,
    'My Dashboard': <DashboardScreen navigation={navigation} route={route} />,
    Tools: <ToolsScreen navigation={navigation} route={route} />
  }

  return (
    <View style={styles.container}>
      {screensMapping[stackModal] || <DashboardScreen navigation={navigation} route={route} />}
    </View>
  )
}

function PlanBuilderStackScreen(navigation) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const PlanBuilderStack = createStackNavigator()
  return (
    <PlanBuilderStack.Navigator headerMode="float" screenOptions={{ ...styles }}>
      <PlanBuilderStack.Screen
        name="Plan Builder"
        component={WebWrapper}
        options={{
          ...options,
          ...{ title: 'Plan Builder', ...addRightButton }
        }}
      />
    </PlanBuilderStack.Navigator>
  )
}

function DashboardStackScreen(navigation) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const DashboardStack = createStackNavigator()
  return (
    <DashboardStack.Navigator headerMode="float" screenOptions={{ ...styles }}>
      <DashboardStack.Screen
        name="My Dashboard"
        component={WebWrapper}
        options={{ ...options, ...{ title: 'My Dashboard' } }}
      />
    </DashboardStack.Navigator>
  )
}

export default function _TabbedStack() {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [displayWidthWeb, setDisplayWidthWeb] = useState(1800)
  const [displayHeightWeb, setDisplayHeightWeb] = useState(1800)

  useAuthorizationCode()

  const onLayout = (event) => {
    const { height, width } = event.nativeEvent.layout
    setDisplayWidthWeb(width)
    setDisplayHeightWeb(height)
  }
  return (
    <View style={{ flex: 1, position: 'relative' }} onLayout={onLayout}>
      {(isNotWeb | displayWidthWeb > 760) && (
        <TabbedStack.Navigator tabBar={(props) => <BottomTabBar {...props} />}>
          <TabbedStack.Screen
            name="Dashboard"
            component={DashboardStackScreen}
            options={{
              tabBarLabel: 'My Dashboard',
              tabBarIcon: 'grid-2'
            }}
          />
          <TabbedStack.Screen
            name="Plan Builder"
            component={PlanBuilderStackScreen}
            options={{
              tabBarLabel: 'Plans',
              tabBarIcon: 'cubes'
            }}
          />
          <TabbedStack.Screen
            name="Tools"
            component={ToolsStackScreen}
            options={{
              tabBarLabel: 'Tools',
              tabBarIcon: 'screwdriver-wrench'
            }}
          />
        </TabbedStack.Navigator>
      )}
      {!isNotWeb && displayWidthWeb <= 760 && (
        <View style={{ position: 'absolute', width: '100%', justifyContent: 'center' }}>
          <LinearGradient
            style={{
              position: 'absolute',
              height: displayHeightWeb / 3,
              width: '100%',
              top: 0,
              zIndex: -1
            }}
            colors={[primaryGradientEnd, primaryGradientStart]}
            start={[1, 1]}
            end={[0, 1]}
          />
          <View style={styles.troutwoodLogo2}>
            <TroutwoodLogo fill="#2C3A48" />
          </View>
          <Text
            style={{
              justifyContent: 'center',
              fontWeight: '400',
              color: '#6D7A7D',
              textAlign: 'center',
              width: 200,
              fontFamily: SFPro,
              position: 'relative',
              margin: 'auto'
            }}
          >
            For the best experience try downloading our mobile app!
          </Text>
          <View style={{ marginTop: 60 }}>
            <a
              href="https://apps.apple.com/us/app/troutwood/id1530619297"
              style={styles.playStores}
              target="_blank"
              rel="noreferrer"
            >
              <Image style={styles.playStores} resizeMode="auto" source={ApplePlay} />
            </a>
            <a
              style={styles.playStores}
              href="https://play.google.com/store/apps/details?id=com.troutwood.sfp&hl=en_US&gl=US&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <Image style={styles.playStores} resizeMode="auto" source={GooglePlay} />
            </a>
          </View>
        </View>
      )}
      {useSafeArea().bottom > 0 && (
        <View
          style={{
            height: useSafeArea().bottom - 5,
            backgroundColor: darkJungleGreen
          }}
        />
      )}
    </View>
  )
}
