import { useEffect, useRef } from 'react'
import { StyleSheet, Text, View, useWindowDimensions, Keyboard } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import SlideupDrawer from './SlideupDrawer'
import Button from './Button'
import Input from './Input'
import { plansSelector } from '../selectors'
import { darkblue, gunmetal, red } from '../styles/colors'
import { deletePersonalMilestoneItem, savePersonalMilestoneItem, setCurrentPlan, setPersonalMilestoneItem } from '../actions'
import Form from '../components/Form'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 20,
  },
  header: {
    fontWeight: '600',
    width: '100%',
    fontSize: 28,
    color: darkblue,
    zIndex: 100
  },
  body: {
    marginVertical: 20,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'left',
    zIndex: 100
  },
  input: { marginTop: 20 },
  button: { marginTop: -10 },
  deleteButton: { marginTop: 10, marginBottom: 10 }
})

export default function EditMilestoneSlideupDrawer({ visible, setVisible, onClose, screenToNavigate, currentEditMilestone, navigation, currentUser }) {
  const dispatch = useDispatch()
  const plans = useSelector((state) => plansSelector(state))
  const windowDimensions = useWindowDimensions();

  let temp = '';
  if (currentEditMilestone) {
    temp = currentEditMilestone.description;
  }
  const inputValues = { description: temp }

  useEffect(() => {
  }, [currentUser])

  function handleClose() {
    setVisible(false)
    setTimeout(() => {
      if (onClose) onClose()
    }, 1000)
  }

  const handleDelete = (values) => {
    setVisible(false)
    dispatch(deletePersonalMilestoneItem({ id: currentEditMilestone.id }))

    // Make this call after the modal animation finishes
    // otherwise it will be choppy
    setTimeout(() => {
      if (onClose) onClose()
    }, 1000)
  }

  const handleSubmit = (values) => {
    setVisible(false)
    dispatch(savePersonalMilestoneItem({ id: currentEditMilestone.id, description: values.description }))

    // Make this call after the modal animation finishes
    // otherwise it will be choppy
    setTimeout(() => {
      if (onClose) onClose()
    }, 1000)
  }
  return (
    <SlideupDrawer snapPointIndex={1} visible={visible} setVisible={handleClose}>
      <View style={styles.container}>
        <Text style={styles.header}>Edit Milestone</Text>
        <Formik
          initialValues={inputValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <>
              <Input
                style={[styles.input, { width: windowDimensions.width - 60, height: 100 }]}
                label="Your Goal"
                value={values.description}
                onChangeText={handleChange('description')}
                placeholder={temp}
                blurOnSubmit={true}
                onSubmitEditing={()=>{Keyboard.dismiss()}}
                multiline={true}
                keyboardType="default"

              />
              <Button
                style={styles.deleteButton}
                color={red}
                inverted
                withBorder={false}
                onPress={handleDelete}
              >
                Delete this Milestone
              </Button>
              <Button
                style={styles.button}
                disabled={values.description.length <= 0}
                onPress={handleSubmit}
              >
                Save
              </Button>
            </>
          )}
        </Formik>
      </View>
    </SlideupDrawer>
  )
}
