import { useState } from 'react'
import { Text, View, TouchableOpacity, StyleSheet, useWindowDimensions } from 'react-native'

import { map, capitalize, compact, forEach } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import hapticFeedback from '../hapticFeedback'
import Button from '../Button'
import Divider from '../Divider'

import CircleIcon from '../CircleIcon'
import SlideupDrawer from '../SlideupDrawer'
import IconDataRow from '../IconDataRow'

import formatCurrency from '../../functions/formatCurrency'

import { gunmetal, lightblue, primary, rgba, info } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'
import PillGroup from '../PillGroup'
import PlanSummaryCard from './PlanSummaryCard'
import HeaderTitleAndSubtitle from './HeaderTitleAndSubtitle'
import IconContainer from './IconContainer'
import { MONTHLY, WEEKLY } from '../../constants'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: DEFAULT_SPACING,
  }
})

export default function SurplusCard({
  result,
  loading,
  navigation,
  duration,
  setDuration
}) {
  const [visible, setVisible] = useState(false)
  const window = useWindowDimensions()

  let divisor = 1
  if (duration == MONTHLY) {
    divisor = 12
  } else if (duration == WEEKLY) {
    divisor = 52
  }
  const surplus = result.surplus && result.surplus[duration] || 0
  const hasSurplus = surplus > 0
  const fontColor = surplus >= 0 ? primary : 'rgb(222, 54, 84)'

  const marginTop = window.height / 10
  const height = window.height - marginTop

  const options = [{
    label: 'Weekly',
    inverted: true,
    active: duration === 'weekly',
    onPress: () => setDuration('weekly')
  }, {
    label: 'Monthly',
    inverted: true,
    active: duration === 'monthly',
    onPress: () => setDuration('monthly')
  }, {
    label: 'Annually',
    inverted: true,
    active: duration === 'annual',
    onPress: () => setDuration('annual')
  }]

  const bulletPoints = [
    {
      title: 'Reinvest',
      subtitle: 'Reinvest and watch your money grow \n even more',
      iconStyle: {
        icon: 'lightbulb-dollar',
        color: primary,
        backgroundColor: rgba(primary, 0.1)
      }
    }, {
      title: 'Charity',
      subtitle: 'Feel great and help others',
      iconStyle: {
        icon: 'heart',
        color: 'rgb(222, 54, 54)',
        backgroundColor: 'rgba(222, 54, 54, 0.1)'
      }
    }, {
      title: 'Annual Vacation',
      subtitle: 'You could enjoy the beach each year',
      iconStyle: {
        icon: 'island-tropical',
        color: 'rgb(244, 155, 32)',
        backgroundColor: 'rgba(244, 155, 32, 0.1)'
      }
    }, {
      title: 'New Vehicle',
      subtitle: 'You could afford a new vehicle',
      iconStyle: {
        icon: 'car',
        color: lightblue,
        backgroundColor: 'rgba(52, 145, 247, 0.1)'
      }
    }
  ]

  const addOnOptions = []

  let lifehacks = [
    result.housing_cost && result.housing_cost.without_life_hack[duration] > result.housing_cost.cost[duration] && {
      label: 'Housing',
      value: `+ $${formatCurrency((result.housing_cost.without_life_hack[duration] - result.housing_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'house',
        color: 'rgb(66, 86, 103)',
        backgroundColor: 'rgba(66, 86, 103, 0.1)'
      }
    },

    result.utilities_cost && result.utilities_cost.without_life_hack[duration] > result.utilities_cost.cost[duration] && {
      label: 'Utilities',
      value: `+ $${formatCurrency((result.utilities_cost.without_life_hack[duration] - result.utilities_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'bolt',
        color: 'rgb(255, 103, 77)',
        backgroundColor: 'rgba(255, 103, 77, 0.1)'
      }
    },

    result.transportation_cost && result.transportation_cost.without_life_hack[duration] > result.transportation_cost.cost[duration] && {
      label: 'Transportation',
      value: `+ $${formatCurrency((result.transportation_cost.without_life_hack[duration] - result.transportation_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'car',
        color: 'rgb(118, 88, 227)',
        backgroundColor: 'rgba(118, 88, 227, 0.1)'
      }
    },

    result.food_cost && result.food_cost.without_life_hack[duration] > result.food_cost.cost[duration] && {
      label: 'Food',
      value: `+ $${formatCurrency((result.food_cost.without_life_hack[duration] - result.food_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'utensils',
        color: gunmetal,
        backgroundColor: 'rgba(106, 123, 126, 0.1)'
      }
    },

    result.additional_income_amount && result.additional_income_amount > 0 && {
      label: 'Side Hustle',
      value: `+ $${formatCurrency(result.additional_income_amount / divisor)}`,
      iconStyle: {
        icon: 'chart-line',
        color: primary,
        backgroundColor: rgba(primary, 0.1)
      }
    },

    result.student_loan_payment && result.student_loan_payment.without_life_hack[duration] > result.student_loan_payment.cost[duration] && {
      label: 'Student Loan Forgiveness',
      value: `+ $${formatCurrency((result.student_loan_payment.without_life_hack[duration] - result.student_loan_payment.cost[duration]))}`,
      iconStyle: {
        icon: 'graduation-cap',
        color: 'rgb(255, 201, 77)',
        backgroundColor: 'rgba(255, 201, 77, 0.1)'
      }
    },

    result.healthcare_cost && result.healthcare_cost.without_life_hack[duration] > result.healthcare_cost.cost[duration] && {
      label: "Parent's Health Insurance",
      value: `+ $${formatCurrency((result.healthcare_cost.without_life_hack[duration] - result.healthcare_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'heart',
        color: 'rgb(255, 61, 99)',
        backgroundColor: 'rgba(255, 61, 99, 0.1)'
      }
    }
  ]

  lifehacks = compact(lifehacks)

  const iconToLabelMap = {
    subscription: 'credit-card',
    entertainment: 'tv',
    pet: 'dog',
    charity: 'circle-heart',
    hobby: 'guitar',
    custom: 'star'
  }

  if (result.add_ons) {
    forEach(result.add_ons, (addOn) => {
      const key = addOn.category.toLowerCase()
      addOnOptions.push({
        addOn,
        label: addOn.name || capitalize(key),
        value: `$${formatCurrency((addOn.amount * 12) / divisor)}`,
        iconStyle: {
          icon: iconToLabelMap[key],
          type: 'fal',
          color: lightblue,
          backgroundColor: 'rgba(52, 145, 247, 0.1)'
        }
      })
    })
  }

  function BulletPoint({ title, subtitle, iconStyle }) {
    return (
      <View style={{ flexDirection: 'row', paddingHorizontal: DEFAULT_SPACING, marginBottom: 24 }}>
        <CircleIcon
          icon={iconStyle.icon}
          style={{
            width: 57,
            height: 57,
            borderRadius: 57 / 2,
            backgroundColor: iconStyle.backgroundColor
          }}
          color={iconStyle.color}
          size={28}
        />

        <View style={{ marginLeft: DEFAULT_SPACING }}>
          <Text style={{ marginBottom: DEFAULT_SPACING / 2, fontSize: 18, color: 'rgb(40, 58, 73)', letterSpacing: -0.75, fontWeight: '700' }}>{title}</Text>
          <Text style={{ fontSize: 15, color: gunmetal, letterSpacing: -0.625, fontWeight: '500' }}>{subtitle}</Text>
        </View>
      </View>
    )
  }

  return (
    <PlanSummaryCard
      title={hasSurplus || loading ? 'Surplus' : 'Deficit'}
      subtitle={hasSurplus || loading ? 'After factoring in your living essentials, \nyou have a surplus of…' : 'After factoring in your living essentials, \nyou have a deficit of…'}
      titleTooltip={hasSurplus || loading ? 'Extra money left over after accounting for your financial goal, living essentials and personalized plan. Invest, save or spend or  this money however you wish!' : 'Oh no! A deficit means you\'re spending more money than you are earning. Try reducing your expenses to balance your budget or use a life hack to free up some cash.'}
    >
      {hasSurplus
        && (
          <TouchableOpacity
            style={{ zIndex: 100, width: 50, position: 'absolute', top: 20, right: 20 }}
            onPress={() => {
              setVisible(true)
              hapticFeedback()
            }}
          >

            <FontAwesomeIcon icon={['far', 'magnifying-glass-dollar']} style={{ alignSelf: 'flex-end' }} size={18} color={lightblue} />

            <SlideupDrawer snapPointIndex={3} marginTop={marginTop} height={height} visible={visible} setVisible={setVisible}>
              <Text style={{ fontSize: 28, color: 'rgb(40, 58, 73)', fontWeight: '700', textAlign: 'center', marginTop: 68, marginBottom: 16 }}>You hit your goal</Text>
              <Text style={{ letterSpacing: -0.58, fontSize: 14, color: gunmetal, textAlign: 'center', marginBottom: 44 }}>With your extra savings, you could afford</Text>

              {map(bulletPoints, (bulletPoint) => <BulletPoint key={bulletPoint.title} {...bulletPoint} />)}

              <Button style={{ marginHorizontal: DEFAULT_SPACING }} onPress={() => setVisible(false)}>Got it</Button>
            </SlideupDrawer>
          </TouchableOpacity>
        )}

      <Text style={{ textAlign: 'center', fontSize: 42, color: fontColor, fontWeight: '600', letterSpacing: -1.457143 }}>{`$${loading ? '—' : formatCurrency(surplus)}`}</Text>

      <PillGroup options={options} />

      <Divider />

      <View style={{ marginVertical: DEFAULT_SPACING }}>
        <HeaderTitleAndSubtitle
          small
          title="Life Hacks"
          subtitle="Looking to save some money? Life hacks are a simple way you can save on your living essentials."
        />

        {map(lifehacks, (rowProps) => (
          <IconDataRow
            key={rowProps.label}
            {...rowProps}
            route="Edit Life Hacks"
            params={{ currentPlan: result }}
          />
        ))}

        { lifehacks.length == 0
          && (
            <View style={styles.row}>
              <IconContainer icon="house-chimney" text="Housing" />
              <IconContainer icon="car" text="Transportation" />
              <IconContainer icon="utensils" text="Food" />
              <IconContainer icon="chart-line-up" text="Hustle" />
              <IconContainer icon="heart" text="Health" />
            </View>
          )}

        <Button color={info} onPress={() => navigation.navigate('Modal', { screen: 'Edit Life Hacks', params: { title: 'Life Hacks', subtitle: 'Reduce the Cost of Living essentials', currentPlan: result } })} style={{ marginTop: DEFAULT_SPACING, paddingHorizontal: DEFAULT_SPACING, flex: 1, fontSize: 14, textAlign: lifehacks.length == 0 ? 'center' : 'left' }}>
          Add
        </Button>
      </View>

      <Divider />

      <View style={{ marginVertical: DEFAULT_SPACING }}>
        <HeaderTitleAndSubtitle
          small
          title="Add Ons"
          subtitle="Have a big item you want to budget for? Customize your plan with an Add-On!"
        />

        {map(addOnOptions, (rowProps, index) => (
          <IconDataRow
            key={`${rowProps.label}-${index}`}
            {...rowProps}
            route="Edit Add On"
            params={{ addOn: rowProps.addOn }}
          />
        ))}
        { addOnOptions.length == 0
          && (
            <View style={styles.row}>
              <IconContainer icon="tv" text="Media" />
              <IconContainer icon="guitar" text="Hobby" />
              <IconContainer icon="dog" text="Pet" />
              <IconContainer icon="circle-heart" text="Charity" />
              <IconContainer icon="star" text="Custom" />
            </View>
          )}
        <Button color={info} onPress={() => navigation.navigate('Modal', { screen: 'Edit Add Ons', params: { title: 'Add Ons', subtitle: 'How would you like to spend your surplus?' } })} style={{ marginTop: DEFAULT_SPACING * 2, paddingHorizontal: DEFAULT_SPACING, flex: 1, fontSize: 14, textAlign: addOnOptions.length == 0 ? 'center' : 'left' }}>
          Add
        </Button>
      </View>
    </PlanSummaryCard>
  )
}
