import { useLayoutEffect, useEffect, useState } from 'react'
import { View, Text, StyleSheet, Linking } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { InAppBrowser } from 'react-native-inappbrowser-reborn'
import { post, get } from 'http'
import { useDispatch, useSelector } from 'react-redux'
import { DEFAULT_SPACING, gunmetal, info, lightblue, white, rgba } from './styles'
import Button from './components/Button'
import HeaderLabel from './components/HeaderLabel'
import Pill from './components/Pill'
import ListOptionItem from './components/ListOptionItem'

import openLink from './functions/openLink'
import { getCurrentUser } from './actions'
import { currentUserSelector } from './selectors'
import CircleImage from './components/CircleImage'

const styles = StyleSheet.create({
  subheading: { backgroundColor: white },
  subheadingText: {
    fontSize: 14,
    letterSpacing: -0.42,
    textAlign: 'center',
    color: gunmetal,
    padding: DEFAULT_SPACING * 2,
    paddingTop: 0
  },
  myBiasesContainer: {
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingBottom: DEFAULT_SPACING * 2
  },
  buttonContainer: { paddingHorizontal: DEFAULT_SPACING, },
  infoButton: {
    backgroundColor: info,
    marginTop: DEFAULT_SPACING
  },
  scrollView: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingTop: DEFAULT_SPACING
  },
  container: { backgroundColor: '#EDF1F3' },
  blankListOptionItem: {
    borderRadius: 12,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: rgba(lightblue, 0.1),
    height: 100,
    marginBottom: DEFAULT_SPACING,
  }
})

function BlankListOptionItem() {
  return (
    <View style={styles.blankListOptionItem} />
  )
}

export default function MyFinmojiScreen({ navigation }) {
  const dispatch = useDispatch()
  const currentUser = useSelector(currentUserSelector)

  const [trustees, setTrustees] = useState([])

  async function fetchTrustees() {
    // fetch trustees
    const response = await get('api/v1/finmojis/')
    setTrustees(response.data)
  }

  async function assignFinmojis(finmojis) {
    await post('api/users/assign-finmojis/', { finmoji_ids: finmojis })
    dispatch(getCurrentUser())
  }

  useEffect(() => {
    fetchTrustees()
  }, [])

  useEffect(() => {
    // Subscribe to incoming links
    const unsubscribe = Linking.addEventListener('url', (event) => {
      console.log(`Opened url: ${event.url}`)

      InAppBrowser.close()

      // get params from event.url
      const params = event.url.split('?')[1].split('&').reduce((acc, curr) => {
        const [key, value] = curr.split('=')
        acc[key] = value
        return acc
      }, {})

      const { finmoji } = params
      assignFinmojis(finmoji.split(','))
    });

    // Unsubscribe on cleanup
    return () => {
      unsubscribe();
    };
  }, []);

  function handleBrowse() {
    navigation.navigate('Modal', { screen: 'Finmoji Library', params: { trustees } })
  }

  function handleAssessment() {
    openLink('https://finmoji.io/assessment?mobile=true')
  }

  useLayoutEffect(() => {
    navigation.setOptions({ title: 'Finmoji' })
  }, [navigation])

  const finmojiSelected = []

  // find selected finmojis from trustees
  // currentUser.finmoji_ids contains the finmojis
  // that the user has selected
  trustees.forEach((trustee) => {
    if (currentUser.finmoji_ids.includes(trustee.id)) {
      finmojiSelected.push(trustee)
    }
  })

  // make sure finmojiSelected is always 3 elements long
  while (finmojiSelected.length < 3) {
    finmojiSelected.push({})
  }

  const biases = finmojiSelected.map((finmoji) => finmoji.specialty)
    .filter((bias) => bias)

  return (
    <View style={styles.container}>
      <View style={styles.subheading}>
        <Text style={styles.subheadingText}>
          Explore different financial topics and learn how the Finmoji team can
          help you better understand your financial psychology.
        </Text>

        <View style={styles.myBiasesContainer}>
          <HeaderLabel>My Biases:</HeaderLabel>

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            { biases.map((bias, index) => (
              <Pill
                style={{ marginTop: DEFAULT_SPACING, marginRight: DEFAULT_SPACING }}
                color={info}
                key={`${bias}-${index}`}
              >
                {bias}
              </Pill>
            ))}
          </View>
        </View>
      </View>

      <ScrollView style={[styles.scrollView, { height: biases.length > 0 ? '45%' : '60%' }]}>
        { finmojiSelected.map((finmoji, index) => (
          finmoji.id ? (
            <ListOptionItem
              key={index}
              label={finmoji.display_name}
              sublabel={finmoji.specialty}
              iconComponent={(
                <CircleImage
                  size={50}
                  url={finmoji.image_url}
                  style={{ marginRight: DEFAULT_SPACING }}
                />
              )}
              navigatable
              onPress={() => {
                navigation.navigate('Modal', { screen: 'Finmoji', params: { finmoji } })
              }}
            />
          ) : (
            <BlankListOptionItem key={index} />
          )
        ))}
      </ScrollView>

      <View style={styles.buttonContainer}>
        <Button onPress={handleAssessment}>Take Assessment</Button>
        <Button onPress={handleBrowse} style={styles.infoButton}>Browse All Trustees</Button>
      </View>
    </View>
  )
}
