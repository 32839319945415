import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { primary } from '../styles';
import hapticFeedback from './hapticFeedback';
import TouchableOpacity from './TouchableOpacity'

export const LABEL_POSITION = {
  RIGHT: 'right',
  LEFT: 'left'
};

const styles = StyleSheet.create({
  checkBoxContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  alignStyle: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkBoxLabel: {
    marginLeft: 5,
    marginRight: 5
  }
});

export default function CircleCheckBox({
  checked = false,
  outerSize = 20,
  filterSize = 16,
  innerSize = 14,
  outerColor = 'rgb(242, 248, 250)',
  filterColor = 'rgb(242, 248, 250)',
  innerColor = primary,
  label = '',
  labelPosition = LABEL_POSITION.RIGHT,
  styleLabel = {},
  styleCheckboxContainerStyle = {},
  onPress
}) {
  const calcuatedOuterSize = (parseInt(outerSize) < 10 || isNaN(parseInt(outerSize))) ? 10 : parseInt(outerSize);
  const calculatedFilterSize = (parseInt(filterSize) > outerSize + 3 || isNaN(parseInt(filterSize))) ? outerSize - 3 : parseInt(filterSize);
  const calculatedInnerSize = (parseInt(innerSize) > filterSize + 5 || isNaN(parseInt(innerSize))) ? filterSize - 5 : parseInt(innerSize);

  const calculatedStyles = StyleSheet.create({
    circleOuterStyle: {
      width: calcuatedOuterSize,
      height: calcuatedOuterSize,
      borderRadius: calcuatedOuterSize / 2,
      backgroundColor: outerColor
    },
    circleFilterStyle: {
      width: calculatedFilterSize,
      height: calculatedFilterSize,
      borderRadius: calculatedFilterSize / 2,
      backgroundColor: filterColor
    },
    circleInnerStyle: {
      width: calculatedInnerSize,
      height: calculatedInnerSize,
      borderRadius: calculatedInnerSize / 2,
      backgroundColor: innerColor
    }
  });

  function handlePress() {
    hapticFeedback()
    if (onPress) onPress(!checked)
  }

  function renderInner() {
    return checked ? (<View style={calculatedStyles.circleInnerStyle} />) : (<View />);
  }

  function renderLabel(position) {
    return ((label.length > 0) && (position === labelPosition))
      ? <Text style={[styles.checkBoxLabel, styleLabel]}>{label}</Text>
      : <View />
  }

  return (
    <TouchableOpacity onPress={handlePress}>
      <View style={[styles.checkBoxContainer, styleCheckboxContainerStyle]}>
        {renderLabel(LABEL_POSITION.LEFT)}
        <View style={[styles.alignStyle, calculatedStyles.circleOuterStyle]}>
          <View style={[styles.alignStyle, calculatedStyles.circleFilterStyle]}>
            {renderInner()}
          </View>
        </View>
        {renderLabel(LABEL_POSITION.RIGHT)}
      </View>
    </TouchableOpacity>
  );
}
