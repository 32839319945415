
import { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import TransactionsSection from './components/budgeting/TransactionsSection'
import { DEFAULT_SPACING, white } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import {
  currentUserSelector,
  transactionsSelector
} from './selectors'

import {
  getTransactions
} from './actions'

const styles = StyleSheet.create({
  container: { flex: 1 },
  pillGroup: {
    width: 250,
    marginBottom: 20
  },
  pill: { width: 100 },
  pillGroupContainer: {
    alignItems: 'center',
    backgroundColor: white,
    paddingBottom: DEFAULT_SPACING * 2
  }
})

export default function BudgetingScreen({ navigation }) {
  const dispatch = useDispatch()
  const currentUser = useSelector(currentUserSelector)

  useEffect(() => {
    const endDate = moment().format('YYYY-MM-DD')
    const startDate = moment().subtract(1, 'month').format('YYYY-MM-DD')

    dispatch(getTransactions(currentUser.id, startDate, endDate))
  }, [])  

  const transactions = useSelector(transactionsSelector)

  return (
    <View style={styles.container}>
      <TransactionsSection 
        navigation={navigation} 
        transactions={transactions}
      />
    </View>
  )
}

BudgetingScreen.propTypes = {}
