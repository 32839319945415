import { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DEFAULT_SPACING, gray, lightestgray, white } from '../../styles';
import AccountListDropdown from '../AccountListDropdown';
import Card from '../Card';
import CircleIcon from '../CircleIcon';
import DataDonut from '../DataDonut';
import groupBSAccountsByCategory from '../../functions/groupBSAccountsByCategory';
import AccountsListHeader from '../AccountsListHeader';
import { bsAccountCategoriesSelector } from '../../selectors';
import { useNavigation } from '@react-navigation/native';

const styles = StyleSheet.create({
  cardContainer: { shadowColor: white },
  accountContainer: {
    marginVertical: DEFAULT_SPACING,
    shadowColor: white
  },
  header: {
    paddingHorizontal: DEFAULT_SPACING,
    marginTop: DEFAULT_SPACING,
    fontWeight: '500',
    fontSize: 20,
    width: '90%',
  },
  toggleHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: white
  }
})

function ToggleBar({ text, listView, onClick }) {
  return (
    <View style={styles.toggleHeaderContainer}>
      <Text style={[styles.header]}>
        {text || 'My Accounts'}
      </Text>
      {!listView && (
        <TouchableOpacity
          onPress={onClick}
          style={{ marginRight: 10 }}
        >
          <CircleIcon icon="list" size={15} color={gray} backgroundColor={lightestgray} />

        </TouchableOpacity>
      ) }

      {listView && (
        <TouchableOpacity
          onPress={onClick}
          style={{ marginRight: 10 }}
        >
          <CircleIcon icon="chart-pie" size={15} color={gray} backgroundColor={lightestgray} />

        </TouchableOpacity>
      )}
    </View>
  )
}

function OverviewDonut({ chartData, isAsset, accounts }) {
  return (
    <View>
      <AccountsListHeader isAsset={isAsset} accounts={accounts} />

      <DataDonut
        chartData={chartData}
      />
    </View>
  )
}

export default function MyAccountToggle({ bsAccounts, style }) {
  const [listView, setListView] = useState(false);
  const toggleListView = () => setListView(!listView)
  const allAccountCats = useSelector(bsAccountCategoriesSelector)
  const navigation = useNavigation()

  const assetsByCategory = groupBSAccountsByCategory(bsAccounts.assetAccounts, allAccountCats)
  const liabilitiesByCategory = groupBSAccountsByCategory(
    bsAccounts.liabilityAccounts,
    allAccountCats
  )

  const onShowAccount = (account) => {
    navigation.navigate('Modal', {
      screen: 'View BS Account',
      params: { account }
    })
  }

  return (
    <Card style={[style, styles.cardContainer]}>
      <ToggleBar text="My Accounts" listView={listView} onClick={toggleListView} />
      {listView && (
        <View>
          <AccountListDropdown
            accounts={bsAccounts.assetAccounts}
            isAsset
            expandable={false}
            style={styles.accountContainer}
            onShowAccount={onShowAccount}
          />
          <AccountListDropdown
            accounts={bsAccounts.liabilityAccounts}
            isAsset={false}
            expandable={false}
            style={styles.accountContainer}
            onShowAccount={onShowAccount}
          />
        </View>
      )}
      {!listView && (
        <Card style={styles.accountContainer}>
          <OverviewDonut
            accounts={bsAccounts.assetAccounts}
            chartData={assetsByCategory}
            isAsset
          />
          <OverviewDonut
            accounts={bsAccounts.liabilityAccounts}
            chartData={liabilitiesByCategory}
          />
        </Card>
      )}
    </Card>
  )
}

MyAccountToggle.defaultProps = { style: {} }

MyAccountToggle.propTypes = {
  bsAccounts: PropTypes.shape({
    assetAccounts: PropTypes.array.isRequired,
    liabilityAccounts: PropTypes.array.isRequired,
  }).isRequired,
  style: PropTypes.object
}
