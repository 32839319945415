import { useEffect, useLayoutEffect, useState } from 'react'
import {
  Platform,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView
} from 'react-native'

import { get } from 'http'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { useDispatch, useSelector } from 'react-redux'
import { each, map, orderBy } from 'lodash'
import { DEFAULT_SPACING, black, darkblue, gunmetal, lightblue, primary, white } from '../styles'
import FastBuilderContainer from '../components/planBuilder/FastBuilderContainer'
import Form from '../components/Form'
import MockInput from '../components/MockInput'
import timeout from '../functions/timeout'
import { saveFastPlan, setCareerLocation, setLoading } from '../actions'
import { fastPlanModeDataSelector } from '../selectors'
import handleError from '../functions/handleError'
import { CareerValidationSchema, Fields } from '../schemas/fastPlanSchemas'
import LoadingIcon from '../components/planBuilder/LoadingIcon'
import SearchInput from '../components/SearchInput'
import SearchListItem from '../components/SearchListItem'
import SearchResultFinn from '../components/SearchResultFinn'

function matchesSearch(string, careerSearch) {
  return string.toLowerCase().match(careerSearch.toLowerCase())
}

const basicStyles = {
  container: { flex: 1 },
  title: {
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '500'
  },
  boldText: { fontWeight: 'bold' }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {},
  [minSize(DEVICE_SIZES.LG)]: {}
})

function EditCareerScreen({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [visible, setVisible] = useState(true)
  const dispatch = useDispatch()
  const { birthday: birth_date, city } = useSelector((state) => fastPlanModeDataSelector(state))
  const [careers, setCareers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [careerSearch, setCareerSearch] = useState()
  const [careerOptions, setCareerOptions] = useState([])

  useEffect(() => {
    const fetchCareers = async () => {
      setIsLoading(true)
      const res = await get(`location/${city.id}/occupations/`)
      const groups = orderBy(res.data, ['name'])
      const mRes = await get('api/v1/occupations/military/')
      const militaryCareers = []
      if (mRes.data) {
        each(Object.keys(mRes.data.careers), (key) => {
          militaryCareers.push(...mRes.data.careers[key])
        })
      }
      const careerList = []
      const occGroups = [...groups, { ...mRes.data, careers: militaryCareers }]
      if (occGroups.length > 0) {
        each(occGroups, (group) => {
          each(group.careers, (career) => {
            careerList.push(career)
          })
        })
      }
      setCareers(orderBy(careerList || [], ['name']))
      setIsLoading(false)
    }
    fetchCareers()
  }, [])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerTintColor: white,
      title: null
    })
  }, [])

  function searchCareers(searchIndex) {
    if (searchIndex && searchIndex.length >= 3) {
      const careerList = []
      each(careers, (career) => {
        const nameMatch = matchesSearch(career.name, searchIndex)
        const termMatch = matchesSearch(career.tags_string || '', searchIndex)
        const descriptionMatch = matchesSearch(career.description || '', searchIndex)

        if (nameMatch || termMatch || descriptionMatch) {
          careerList.push(career)
        }
      })
      setCareerOptions(careerList)
    } else {
      setCareerOptions([])
    }
  }

  useEffect(() => {
    searchCareers(careerSearch)
  }, [careerSearch])

  return (
    <FastBuilderContainer currentPage="Career">
      {isLoading ? (
        <ActivityIndicator style={{ marginHorizontal: 12 }} color={black} size="small" />
      ) : (
        <View style={styles.container}>
          <Form
            buttonText="Next"
            containerStyle={{ width: '100%' }}
            validationSchema={CareerValidationSchema}
            withPadding={false}
            onSubmit={async (values) => {
              try {
                dispatch(
                  setLoading({
                    loading: true,
                    fullScreen: true,
                    loadingMessage: 'Building your financial plan...',
                    useGradient: true,
                    fullScreenOpacity: 1,
                    fullScreenIconComponent: <LoadingIcon />
                  })
                )

                dispatch(
                  saveFastPlan({
                    birth_date,
                    city_id: city.id,
                    occupation_id: values.career.id,
                    step: 3
                  })
                )

                setTimeout(() => {
                  navigation.reset({
                    index: 1,
                    routes: [
                      {
                        name: 'Main',
                        state: {
                          index: 0,
                          routes: [{ name: 'Plan Builder' }]
                        }
                      },
                      {
                        name: 'Modal',
                        state: {
                          routes: [{ name: 'Plan Summary' }]
                        }
                      }
                    ]
                  })

                  dispatch(
                    setLoading({
                      loading: false,
                      fullScreen: false,
                      loadingMessage: null,
                      useGradient: null,
                      fullScreenOpacity: null,
                      fullScreenIconComponent: null
                    })
                  )
                }, 2000)
              } catch (error) {
                handleError(error, 'Oops. Looks like something went wrong.')

                dispatch(
                  setLoading({
                    loading: false,
                    fullScreen: false,
                    loadingMessage: null,
                    useGradient: null,
                    fullScreenOpacity: null,
                    fullScreenIconComponent: null
                  })
                )
              }
            }}
          >
            {({ setFieldTouched, setFieldValue, values, errors, touched }) => (
              <View style={{ width: '100%', paddingTop: 80 }}>
                <Text style={styles.title}>I am, or want to, work as a</Text>
                <View style={{ marginTop: DEFAULT_SPACING * 2 }}>
                  {!visible && (
                    <MockInput
                      onPress={async () => {
                        setVisible(true)

                        await timeout(600)

                        setFieldTouched(Fields.career, true)
                      }}
                      label="Career"
                      touched={touched[Fields.career]}
                      errorMessage={errors[Fields.city]}
                      value={values.career && values.career.name ? values.career.name : ' '}
                    />
                  )}
                  {visible && (
                    <>
                      <SearchInput
                        placeholder="Find Career"
                        value={careerSearch}
                        onChange={(value) => {
                          setCareerSearch(value || null)
                        }}
                        onCancel={() => {
                          setCareerSearch(null)
                        }}
                      />
                      <ScrollView displayButton={false}>
                        {map(careerOptions, (c) => (
                          <SearchListItem
                            key={c.id}
                            onPress={() => {
                              setFieldValue(Fields.career, c)
                              setVisible(false)
                              setCareerSearch(c.name)
                              setCareerOptions([])
                            }}
                            leftLabel={`${c.name}`}
                          />
                        ))}

                        {careerOptions.length === 0 && careerSearch && careerSearch.length > 3 && (
                          <SearchResultFinn
                            image={require('../assets/finn/question.png')}
                            title="No Results"
                            subtitle="Explore another career"
                          />
                        )}
                      </ScrollView>
                    </>
                  )}
                </View>
                <View
                  style={{
                    marginTop: Platform.OS !== 'android' ? 100 : 40,
                    paddingHorizontal: 8
                  }}
                >
                  <Text style={{ textAlign: 'center' }}>
                    <Text style={{ color: gunmetal }}>
                      Compare salaries, growth outlook, job satisfaction and more with our{' '}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        dispatch(setCareerLocation(city))
                        const p = {
                          title: 'Plan Builder',
                          subtitle: 'Explore Careers',
                          builder: true
                        }
                        navigation.navigate('Modal', {
                          screen: 'Edit Career',
                          params: p
                        })
                      }}
                    >
                      <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                        <Text style={{ color: lightblue, top: 4 }}>Career Tool</Text>
                      </View>
                    </TouchableOpacity>
                  </Text>
                </View>
              </View>
            )}
          </Form>
        </View>
      )}
    </FastBuilderContainer>
  )
}

export default EditCareerScreen
