import { del } from 'http'
import { getDebts } from './getDebts'
import handleError from '../functions/handleError'

export const deleteLoan = function (payload) {
  return async (dispatch) => {
    try {
      await del(`loans/${payload.id}/`)

      dispatch(getDebts())
    } catch (e) {
      console.warn(e)
      handleError(e, 'Oops. Looks like something went wrong.')
    }
  }
}
