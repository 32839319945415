import { map, compact, sum } from 'lodash'

import { primary } from '../../styles/colors'

import formatCurrency from '../../functions/formatCurrency'
import occupationName from '../../functions/occupationName'
import computeAge from '../../functions/computeAge'
import investmentType from '../../functions/investmentType'
import investmentSelection from '../../functions/investmentSelection'

import PlanSummaryCard from './PlanSummaryCard'

import DataRow from '../DataRow'

import PillGroup from '../PillGroup'
import PrimaryPlanFlag from '../PrimaryPlanFlag'
import { MONTHLY, WEEKLY } from '../../constants'

function DataTable({ loading, result, duration }) {
  const occupation = occupationName(result)

  const surplus = (result.surplus && result.surplus[duration]) || 0
  const essentials = (result.essentials && result.essentials[duration]) || 0
  const salary = (result.gross_income && result.gross_income[duration]) || 0
  const { savings_goal } = result
  let divisor = 1
  if (duration == MONTHLY) {
    divisor = 12
  } else if (duration == WEEKLY) {
    divisor = 52
  }

  console.log(result, ' --- result')

  const fontColor = surplus >= 0 ? primary : 'rgb(222, 54, 84)'

  const { investment_selection } = result
  const { fixed_weight } = result
  const { contribution_selection } = result

  let lifehacks = [
    result.housing_cost &&
    result.housing_cost.without_life_hack[duration] > result.housing_cost.cost[duration]
      ? result.housing_cost.without_life_hack[duration] - result.housing_cost.cost[duration]
      : 0,

    result.utilities_cost &&
    result.utilities_cost.without_life_hack[duration] > result.utilities_cost.cost[duration]
      ? result.utilities_cost.without_life_hack[duration] - result.utilities_cost.cost[duration]
      : 0,

    result.transportation_cost &&
    result.transportation_cost.without_life_hack[duration] >
      result.transportation_cost.cost[duration]
      ? result.transportation_cost.without_life_hack[duration] -
        result.transportation_cost.cost[duration]
      : 0,

    result.food_cost &&
    result.food_cost.without_life_hack[duration] > result.food_cost.cost[duration]
      ? result.food_cost.without_life_hack[duration] - result.food_cost.cost[duration]
      : 0,

    result.additional_income_amount && result.additional_income_amount > 0
      ? result.additional_income_amount / divisor
      : 0,

    result.student_loan_payment &&
    result.student_loan_payment.without_life_hack[duration] >
      result.student_loan_payment.cost[duration]
      ? result.student_loan_payment.without_life_hack[duration] -
        result.student_loan_payment.cost[duration]
      : 0,

    result.healthcare_cost &&
    result.healthcare_cost.without_life_hack[duration] > result.healthcare_cost.cost[duration]
      ? result.healthcare_cost.without_life_hack[duration] - result.healthcare_cost.cost[duration]
      : 0
  ]

  lifehacks = sum(lifehacks)

  const addOnsAmount = map(Object.values(result.add_ons || {}), 'amount_summary')
  const addOns = sum(map(addOnsAmount, 'annual')) / divisor

  const data = [
    {
      label: 'Goal',
      route: result.contribution_selection !== 'PENSION' ? 'Goal Lockin' : null,
      value: loading ? '—' : `$${formatCurrency(savings_goal || 0)}`,
      color: primary,
      params: {
        buttonText: 'Confirm',
        useGradient: true,
        includeProgress: false,
        estimatedValue: savings_goal
      }
    },
    {
      label: 'Investor Profile',
      route: 'Investment Options',
      params: { buttonText: 'Update' },
      value: loading ? '—' : investmentType(result),
      params: {
        investment_selection,
        useGradient: true,
        includeProgress: false,
        advancedSavings: savings_goal,
        value: contribution_selection
      }
    },
    {
      label: 'Investment Selection',
      route: loading || result.contribution_selection === 'PENSION' ? null : 'Investment Profile',
      params: {
        buttonText: 'Update',
        useGradient: true,
        title: 'Plan Builder',
        subtitle: 'Goal',
        value: contribution_selection,
        contributionAmount: result.contribution_amount,
        includeProgress: false
      },
      value: loading
        ? '—'
        : result.contribution_selection !== 'PENSION'
        ? investmentSelection(fixed_weight)
        : 'Employer Driven'
    },
    {
      label:
        result.contribution_selection !== 'PENSION'
          ? 'Years to Goal'
          : 'Years of Service (At Retirement)',
      route: loading
        ? null
        : result.contribution_selection !== 'PENSION'
        ? 'Edit Profile'
        : 'Pension',
      params: {
        buttonText: 'Update',
        useGradient: true,
        includeProgress: false
      },
      value: loading ? '—' : result.periods
    },
    {
      label: 'Age',
      value: loading ? '—' : computeAge(result.birth_date),
      route: 'Edit Profile',
      params: {
        buttonText: 'Update',
        useGradient: true,
        includeProgress: false
      },
      editable: true
    },
    {
      label: 'Career',
      route: 'Custom Career',
      params: {
        buttonText: 'Update',
        useGradient: true,
        includeProgress: false
      },
      value: loading
        ? '—'
        : occupation && occupation.length > 16
        ? `${occupation.substring(0, 16)}...`
        : occupation
    },
    {
      label: 'Location',
      route: 'Edit Location',
      params: {
        buttonText: 'Update',
        useGradient: true,
        includeProgress: false
      },
      value: loading ? '—' : result.city ? result.city.name : 'N/A'
    },
    {
      label: 'Salary',
      route: result.input_custom_career
        ? 'Custom Career'
        : !!result.occupation && result.occupation.object === 'military-occupation-detail'
        ? 'Explore Military Career'
        : 'Explore Career',
      params: {
        occupation: result.occupation,
        buttonText: 'Update',
        useGradient: true,
        includeProgress: false
      },
      value: loading ? '—' : `$${formatCurrency(salary)}`
    },
    {
      label: 'Essentials',
      value: loading ? '—' : `$${formatCurrency(essentials)}`
    },
    {
      label: 'Life Hacks',
      value: loading ? '—' : `$${formatCurrency(lifehacks)}`,
      route: 'Edit Life Hacks',
      color: lifehacks > 0 ? primary : null,
      params: { currentPlan: result }
    },
    {
      label: 'Add Ons',
      value: loading ? '—' : `$${formatCurrency(-addOns)}`,
      route: 'Edit Add Ons',
      color: addOns > 0 ? 'rgb(222, 54, 84)' : null
    },
    {
      label: loading || surplus > 0 ? 'Surplus' : 'Deficit',
      value: loading ? '—' : `$${formatCurrency(surplus)}`,
      color: fontColor,
      route: 'Surplus',
      params: { id: result.id, includeProgress: false, title: surplus > 0 ? 'Surplus' : 'Deficit' }
    }
  ]

  return map(compact(data), (rowProps, index) => (
    <DataRow
      key={`${rowProps.label}-${result.id}`}
      {...rowProps}
      index={index}
      rightLabelStyle={{ textAlign: 'right' }}
    />
  ))
}

export default function PlanDetailsCard({ loading, result, duration, setDuration, currentPlan }) {
  const options = [
    {
      label: 'Weekly',
      inverted: true,
      active: duration === 'weekly',
      onPress: () => setDuration('weekly')
    },
    {
      label: 'Monthly',
      inverted: true,
      active: duration === 'monthly',
      onPress: () => setDuration('monthly')
    },
    {
      label: 'Annually',
      inverted: true,
      active: duration === 'annual',
      onPress: () => setDuration('annual')
    }
  ]

  return (
    <PlanSummaryCard title="Plan Details" subtitle="Tap on a row to customize or edit an item.">
      {currentPlan && currentPlan.is_primary_plan && <PrimaryPlanFlag />}

      <DataTable loading={loading} result={result} duration={duration} />

      <PillGroup options={options} />
    </PlanSummaryCard>
  )
}
