import { useLayoutEffect, useState } from 'react'
import { Platform, View, StyleSheet, Text, Image, TouchableOpacity, useWindowDimensions } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import Form from '../Form'
import CurrencyInput from '../CurrencyInput'
import PillGroup from '../PillGroup'

import timeout from '../../functions/timeout'

import BuilderWrapper from './BuilderWrapper'

import { saveCurrentPlan } from '../../actions'

import { DEFAULT_SPACING } from '../../styles'

import {
  ValidationSchema,
  ValidationSchemaCustom,
  Fields
} from '../../schemas/customGoalSchema'

import { currentPlanSelector } from '../../selectors'
import { gunmetal, white } from '../../styles/colors'

const basicStyles = {
  formContainer: {},
  container: { flex: 1, },
  text: {
    marginVertical: 8,
    color: gunmetal
  },
  pillGroup: { width: '100%', },
  breadcrumbsWrapper: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  mobileStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      formContainer: { borderRadius: 20, maxHeight: 460, maxWidth: 800, paddingHorizontal: 16, },
      container: { paddingBottom: 72, },
      pillGroup: { width: 160, },
      breadcrumbsWrapper: {
        width: '100%',
        paddingTop: 40,
        paddingLeft: 40,
      },
    },
    [minSize(DEVICE_SIZES.LG)]: { },
  }
)

export default function GoalForm({ route, navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const window = useWindowDimensions()
  const dispatch = useDispatch()
  const [duration, setDuration] = useState('monthly')
  const form = useSelector((state) => currentPlanSelector(state))

  const finnHeight = window.height > 668 ? 125 : 95
  const {
    title,
    subtitle,
    builder,
    includeProgress,
    useGradient,
    buttonText,
    investment_selection,
    advancedSavings
  } = route.params

  const { contribution_amount } = form

  const planValues = { contribution_amount }
  const options = [{
    label: 'Monthly',
    inverted: true,
    active: duration === 'monthly',
    onPress: () => setDuration('monthly')
  }, {
    label: 'Lifetime',
    inverted: true,
    active: duration === 'lifetime',
    onPress: () => setDuration('lifetime')
  }]

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0,
      },
      headerTitleStyle: { color: white },
      headerTintColor: white,
      title: null
    })
  }, [title, subtitle])

  return (
    <BuilderWrapper includeTopper={isNotWeb} option="Goal" includeProgress={includeProgress}>
      <View style={{ width: '100%', marginBottom: 24, }}>
        <TouchableOpacity onPress={() => navigation.pop()}>
          <FontAwesomeIcon icon={faArrowLeft} color="white" size={32} />
        </TouchableOpacity>
      </View>
      <Form
        buttonText={buttonText}
        validationSchema={duration === 'monthly' ? ValidationSchema : ValidationSchemaCustom}
        initialValues={planValues}
        onSubmit={async (values) => {
          await timeout(600)
          if (duration === 'monthly') {
            navigation.navigate('Modal', { screen: 'Investment Profile', params: { title: 'Plan Builder', subtitle: 'Goal', value: 'DOLLAR_AMOUNT', contributionAmount: values.contribution_amount, builder, includeProgress, advancedSavings } })
            dispatch(saveCurrentPlan({
              contribution_selection: 'DOLLAR_AMOUNT',
              contribution_amount: values.contribution_amount,
              investment_selection,
              step: 3
            }))
          } else {
            navigation.navigate('Modal', { screen: 'Investment Profile', params: { title: 'Plan Builder', subtitle: 'Goal', value: 'CUSTOM_GOAL', savingsGoal: values.savings_goal, builder, includeProgress, advancedSavings } })
            dispatch(saveCurrentPlan({
              contribution_selection: 'CUSTOM_GOAL',
              savings_goal: values.savings_goal,
              investment_selection,
              step: 3
            }))
          }
        }}
        containerStyle={styles.formContainer}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <View style={styles.container}>
            <View style={{ alignItems: 'center', marginBottom: DEFAULT_SPACING }}>
              <Image
                style={{ height: finnHeight, width: finnHeight / 1.47, alignSelf: 'center', marginVertical: 30 }}
                source={require('../../assets/finn/happy.png')}
              />
              <Text style={{ textAlign: 'center', letterSpacing: -0.67, color: gunmetal, fontSize: 16 }}>
                Set a target savings goal!
              </Text>
            </View>
            <PillGroup options={options} style={styles.pillGroup} />

            {duration === 'monthly'
            && (
              <CurrencyInput
                prefix="$"
                label="Monthly Amount"
                value={values.contribution_amount}
                name={Fields.contribution_amount}
                touched={touched[Fields.contribution_amount]}
                errorMessage={errors[Fields.contribution_amount]}
                onChangeText={handleChange(Fields.contribution_amount)}
                precision={0}
                onSubmitEditing={handleSubmit}
              />
            )}
            {duration === 'lifetime'
            && (
              <CurrencyInput
                prefix="$"
                label="End Goal Amount"
                value={values.savings_goal}
                name={Fields.savings_goal}
                touched={touched[Fields.savings_goal]}
                errorMessage={errors[Fields.savings_goal]}
                onChangeText={handleChange(Fields.savings_goal)}
                precision={0}
                onSubmitEditing={handleSubmit}
              />
            )}
          </View>
        )}
      </Form>
    </BuilderWrapper>
  )
}
