import { useLayoutEffect, useState } from 'react'
import { View, Image, StyleSheet, Text, ScrollView } from 'react-native'

import moment from 'moment'
import { gunmetal, info, primary, rgba, white } from './styles/colors'

import Container from './components/Container'
import Card from './components/Card'
import Pill from './components/Pill'

import { DEFAULT_SPACING } from './styles'
import Flag from './components/Flag'
import CircleIcon from './components/CircleIcon'
import TouchableOpacity from './components/TouchableOpacity'
import Modal from './components/Modal'
import SlideupDrawer from './components/SlideupDrawer'

const styles = StyleSheet.create({
  container: {
    backgroundColor: primary,
    paddingTop: 160
  },
  card: {
    width: '60%',
    alignSelf: 'center',
    marginBottom: DEFAULT_SPACING * 4,
    alignItems: 'center',
    paddingVertical: DEFAULT_SPACING * 2,
    borderRadius: 12
  },
  cardImage: {
    height: 150,
    width: 150,
  },
  cardTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: DEFAULT_SPACING * 2,
    textAlign: 'center'
  },
  cardSubtitle: {
    fontSize: 16,
    marginBottom: DEFAULT_SPACING,
    textAlign: 'center'
  },
  cardAccent: {
    fontSize: 12,
    color: gunmetal
  },
  cardPillText: {
    fontSize: 10,
    fontWeight: '600'
  },
  section: {
    backgroundColor: white,
    flex: 1,
    paddingVertical: DEFAULT_SPACING * 2,
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: DEFAULT_SPACING
  },
  sectionText: {
    fontSize: 16,
    color: gunmetal,
    marginBottom: DEFAULT_SPACING * 2
  },
  touchableCircleIcon: { marginHorizontal: DEFAULT_SPACING / 2 },
  slideupDrawerText: {
    fontSize: 14,
    color: gunmetal,
    paddingHorizontal: DEFAULT_SPACING,
    paddingBottom: DEFAULT_SPACING * 2
  }
})

function TouchableCircleIcon({ label, icon, attribute, finmoji }) {
  const [visible, setVisible] = useState(false)

  function handlePress() {
    setVisible(true)
  }

  return (
    <>
      <TouchableOpacity onPress={handlePress} style={styles.touchableCircleIcon}>
        <CircleIcon
          type="far"
          icon={icon}
          size={36}
          color={info}
          backgroundColor={rgba(info, 0.1)}
          label={label}
        />
      </TouchableOpacity>

      <SlideupDrawer closeEnabled title={label} scrollable snapPointIndex={3} visible={visible} setVisible={setVisible}>
        <Text style={styles.slideupDrawerText}>
          {finmoji[attribute].replace(/\\n/g, '\n')}
        </Text>
      </SlideupDrawer>
    </>
  )
}

export default function FinmojiScreen({ navigation, route }) {
  const { params } = route
  const { finmoji } = params

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0,
      },
      headerTitleStyle: { color: white, },
      headerTintColor: white,
      title: 'Finmoji Assessment'
    })
  }, [navigation])

  const startDate = moment(finmoji.start_date).format('MMM YYYY').toUpperCase()

  const flagImage = finmoji.bias.match('Cognative')
    ? require('./assets/brain-banner.png')
    : require('./assets/heart-banner.png')

  const options = [{
    label: 'Intro',
    icon: 'pen-nib',
    attribute: 'quote'
  }, {
    label: 'Life',
    icon: 'life-ring',
    attribute: 'life'
  }, {
    label: 'Finances',
    icon: 'dollar-sign',
    attribute: 'finances'
  }, {
    label: 'Benefits',
    icon: 'shield-check',
    attribute: 'benefits'
  }, {
    label: 'Risks',
    icon: 'triangle-exclamation',
    attribute: 'risks'
  }, {
    label: 'Strategies',
    icon: 'lightbulb',
    attribute: 'strategies'
  }, {
    label: 'Summary',
    icon: 'earth-americas',
    attribute: 'summary'
  }]

  return (
    <Container style={styles.container}>
      <Card style={styles.card}>
        <Flag
          left={false}
          source={flagImage}
        />

        <Image style={styles.cardImage} source={{ uri: finmoji.image_url }} />
        <Text style={styles.cardTitle}>{finmoji.display_name}</Text>

        <Text style={styles.cardAccent}>Specialty</Text>
        <Text style={styles.cardSubtitle}>{finmoji.specialty.replace(/,/g, ',\n')}</Text>

        <Pill textStyle={styles.cardPillText} color={info}>
          TRUSTEE SINCE
          {startDate}
        </Pill>
      </Card>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          Meet
          {finmoji.display_name}
        </Text>
        <Text style={styles.sectionText}>
          We found the perfect trustee we think
          {'\n'}
          will help you reach your financial goals.
        </Text>

        <ScrollView style={styles.scrollView} horizontal>
          {options.map((option, index) => (
            <TouchableCircleIcon
              key={`${option.label}-${finmoji.display_name}-${index}`}
              {...option}
              finmoji={finmoji}
            />
          ))}
        </ScrollView>
      </View>

    </Container>
  )
}
