import { useState } from 'react'
import { Platform, TouchableOpacity, View, Text, StyleSheet, } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import PropTypes from 'prop-types'
import { darkblue, gunmetal, primary, red, white } from '../styles/colors'
import Link from './Link'
import formatCurrency from '../functions/formatCurrency'
import SlideupDrawer from './SlideupDrawer'
import { DEFAULT_SPACING } from '../styles'
import ConfidenceIntervalChart from './plans/ConfidenceIntervalChart'
import PrimaryPlanFlag from './PrimaryPlanFlag'

export const CARD_BORDER_RADIUS = DEFAULT_SPACING

const basicStyles = {
  containerStyle: (vertical) => ({
    flexDirection: vertical ? 'column' : 'row',
    justifyContent: vertical ? 'center' : 'space-around',
    backgroundColor: white,
    borderRadius: CARD_BORDER_RADIUS,
    marginVertical: 10,
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING,
    minHeight: vertical ? 220 : 'auto',
  }),
  main: (vertical) => ({
    flex: vertical ? 2 : 1,
    marginLeft: vertical ? 0 : 24,
  }),
  headerText: {
    fontSize: 13,
    color: darkblue,
    letterSpacing: 0,
    fontWeight: '600',
    marginTop: 10
  },
  summaryItem: {
    flexDirection: 'row',
    marginBottom: 4,
    width: '100%'
  },
  summaryItemText: {
    fontSize: 12,
    color: gunmetal
  },
  infoContainer: { marginVertical: 10 },
  slideUpContainer: { alignItems: 'flex-start', marginHorizontal: 20 },
  slideupIcon: { color: primary, marginVertical: 10 },
  slideupLinkStyle: { color: darkblue, fontSize: 18, fontWeight: '500', margin: 10 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      headerText: {
        fontSize: 22,
        marginTop: 12,
      },
      infoContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
        justifyContent: 'space-between',
      },
      summaryItem: {
        width: '49%',
        marginVertical: 4,
      },
      summaryItemText: { fontSize: 16, },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function CareerCardBase({
  careerInfo: {
    id,
    modified,
    name,
    city,
    periods,
    investmentSelection,
    amount,
    confidenceInterval
  },
  pro,
  vertical,
  primary,
  onEdit,
  onView,
  onRename,
  onDelete,
  actionButton,
  style
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [visible, setVisible] = useState(false)
  const cardContents = [
    { icon: 'location-dot', value: city?.name ?? null, },
    { icon: 'bullseye-arrow', value: `$${formatCurrency(amount)}`, },
    { icon: 'piggy-bank', value: investmentSelection, },
    { icon: 'briefcase', value: `${periods} ${periods == 1 ? 'Year' : 'Years'}`, },
  ]

  const Container = !actionButton
    ? TouchableOpacity
    : View

  const handleDelete = () => {
    setVisible(false)
    onDelete()
  }

  const handleEdit = () => {
    setVisible(false)
    onEdit()
  }

  const handleView = () => {
    setVisible(false)
    onView()
  }

  const handleRename = () => {
    setVisible(false)
    onRename()
  }

  return (
    <Container
      key={`career-card-${id}-${modified}`}
      onPress={() => setVisible(true)}
      style={[styles.containerStyle(vertical), style]}
    >
      {primary && <PrimaryPlanFlag left={false} pro={true} />}

      <ConfidenceIntervalChart confidenceInterval={confidenceInterval} />

      <View style={styles.main(vertical)}>
        <Text style={styles.headerText} numberOfLines={1}>{name}</Text>

        <View style={styles.infoContainer}>
          {cardContents.filter(({ value }) => !!value).map(({ icon, value }) => (<View style={styles.summaryItem}>
            <FontAwesomeIcon icon={['fas', icon]} style={{ marginTop: 3, marginRight: 10 }} size={10} color={gunmetal} />
            <Text style={styles.summaryItemText}>{value}</Text>
          </View>))}
        </View>

        {actionButton}
      </View>

      <SlideupDrawer title={name} visible={visible} setVisible={setVisible}      >
        <View style={styles.slideUpContainer}>
          {
            onEdit && (
              <Link
                iconStyle={styles.slideupIcon}
                style={styles.slideupLinkStyle}
                icon="eye"
                type="fas"
                onPress={handleEdit}
              >
                Edit
              </Link>
            )
          }
          {
            onView && (
              <Link
                iconStyle={styles.slideupIcon}
                style={styles.slideupLinkStyle}
                icon="eye"
                type="fas"
                onPress={handleView}
              >
                View
              </Link>
            )
          }

          {
            onRename && (
              <Link
                iconStyle={styles.slideupIcon}
                style={styles.slideupLinkStyle}
                icon="pencil"
                type="fas"
                onPress={handleRename}
              >
                Rename
              </Link>
            )
          }

          {
            onDelete && (
              <Link
                iconStyle={{ color: red, marginVertical: 10 }}
                style={[styles.slideupLinkStyle, { color: red }]}
                icon="trash"
                type="fas"
                onPress={handleDelete}
              >
                Delete
              </Link>
            )
          }
        </View>
      </SlideupDrawer>
    </Container>
  )
}

CareerCardBase.defaultProps = {
  vertical: false,
  primary: false,
  onRename: null,
  onDelete: null,
  onView: null,
  style: {},
  actionButton: null
}

CareerCardBase.propTypes = {
  careerInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    city: PropTypes.object.isRequired,
    periods: PropTypes.number.isRequired,
    investmentSelection: PropTypes.any,
    amount: PropTypes.number.isRequired,
    confidenceInterval: PropTypes.number.isRequired
  }).isRequired,
  vertical: PropTypes.bool,
  primary: PropTypes.bool,
  onRename: PropTypes.func,
  onDelete: PropTypes.func,
  onView: PropTypes.func,
  style: PropTypes.object,
  actionButton: PropTypes.object
}
