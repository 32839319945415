import { Gzip } from '../Gzip'
import AsyncStorage from '@react-native-async-storage/async-storage'

/* Get gzipped blob from storage;
  unzip it;
  load the CSV file into JS object; */
export default async function getProjectionsFromStorage(planFilePath) {
  let projections = []

  try {
    const data = await AsyncStorage.getItem(planFilePath)
    const decompressed = Gzip.unzip(data)
    projections = decompressed.split('\n').map((line) => line.split(','))
  } catch (error) {
    console.log('error reading file', error)
  }

  return projections
}
