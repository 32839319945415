import { Text, View } from 'react-native';
import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css';

function DetailsTabs({ options, college }) {
  const getTabs = () => Object.keys(options).map((key) => {
    const CurrentView = options[key]
    return ({
      title: <Text>{key}</Text>,
      getContent: () => <CurrentView college={college} />,
      /* Optional parameters */
      key,
      tabClassName: 'tab',
      panelClassName: 'panel',
    })
  })

  return (
    <View style={{ marginTop: 16 }}>
      <Tabs items={getTabs()} transformWidth={420} showInkBar />
    </View>
  )
}

export default DetailsTabs
