import TouchableOpacity from '../TouchableOpacity'
import { View, StyleSheet } from 'react-native'
import ProBudgetItem from '../ProBudgetItem'
import PropTypes from 'prop-types'
import { ICON_TYPE } from '../../constants'
import {
  DEFAULT_SPACING,
  orange,
  white,
  gunmetal,
  secondary,
  darkgrayblue
} from '../../styles'
import BudgetBreakdownForMonth from 'components/budgeting/BudgetBreakdownForMonth'

const styles = StyleSheet.create({
  noProjectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: orange,
    padding: DEFAULT_SPACING
  },
  noProjectionsText: {
    color: white,
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: DEFAULT_SPACING
  },
  budgetItemHeader: {
    fontSize: 14,
    fontWeight: '600'
  },
  targetBudget: {
    color: gunmetal,
    fontSize: 10,
    marginBottom: DEFAULT_SPACING * 2,
    marginRight: 30
  },
  thisMonth: {
    color: secondary,
    fontSize: 12
  },
  smallButton: {
    paddingVertical: 8,
    width: 180,
    borderRadius: 8,
    alignSelf: 'center'
  },
  smallButtonText: {
    fontSize: 14
  },
  circleIconStyle: {
    marginRight: DEFAULT_SPACING
  }
})

function BudgetBreakdownCard({
  title,
  budgetAmount,
  actualAmount,
  isIncome,
  iconProps,
  onPress,
  style
}) {
  return (
    <TouchableOpacity
      style={[{ paddingBottom: DEFAULT_SPACING }, style]}
      onPress={onPress}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          marginBottom: DEFAULT_SPACING,
          paddingHorizontal: DEFAULT_SPACING
        }}
      >
        <ProBudgetItem
          onPress={() => {}}
          iconProps={iconProps}
          title={title}
          titleStyle={styles.budgetItemHeader}
          budgetAmount={budgetAmount}
          budgetAmountStyle={{
            color: darkgrayblue,
            fontWeight: '600',
            letterSpacing: -0.6
          }}
          isIncome={isIncome}
          actualAmount={actualAmount}
          style={{ marginRight: DEFAULT_SPACING, flex: 1, paddingBottom: 0 }}
        />
      </View>

      <BudgetBreakdownForMonth
        budgetAmount={budgetAmount}
        actualAmount={actualAmount}
        isIncome={isIncome}
      />
    </TouchableOpacity>
  )
}

BudgetBreakdownCard.defaultProps = { iconProps: {}, style: {}, actualAmount: 0 }

BudgetBreakdownCard.propTypes = {
  title: PropTypes.string.isRequired,
  budgetAmount: PropTypes.number.isRequired,
  actualAmount: PropTypes.number,
  onPress: PropTypes.func.isRequired,
  iconProps: ICON_TYPE,
  style: PropTypes.object
}

export default BudgetBreakdownCard
