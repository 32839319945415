import { View, Text, StyleSheet } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import { gunmetal, rgba, DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%'
  },
  iconText: {
    fontSize: 9,
    color: rgba(gunmetal, 0.4),
    marginTop: DEFAULT_SPACING / 2
  }
})

export default function IconContainer({ icon, text }) {
  return (
    <View style={styles.iconContainer}>
      <FontAwesomeIcon icon={['fal', icon]} color={rgba(gunmetal, 0.4)} size={26} />
      <Text style={styles.iconText}>{text}</Text>
    </View>
  )
}
