import { put } from 'http'
import { currentUserSelector } from '../selectors'
import { setMetaData } from './setMetaData'

export const saveMetaData = function (payload) {
  return async (dispatch, getState) => {
    const state = getState()
    const currentUser = currentUserSelector(state)
    const { metadata } = currentUser

    try {
      const result = await put('api/users/metadata/', { data: { ...metadata, ...payload } })
      dispatch(setMetaData(result.data.data))
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
