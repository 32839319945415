/**
 * react-native-custom-check-box
 * Customizable Checkbox component for react native, it works on iOS and Android
 * https://github.com/bgoyal2222/react-native-custom-check-box
 * Email:bhaveshgoyal999@gmail.com
 * Profile:https://www.linkedin.com/in/bhavesh-goyal/
 */

import { StyleSheet, View, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { darkblue, primary } from '../styles'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 6,
    justifyContent: 'space-between'
  },
  rightText: {
    fontWeight: '400',
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
    color: darkblue
  },
  leftText: {
    fontWeight: '400',
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
    color: darkblue
  }
})

export default function SquareCheckBox(props) {
  const {
    style,
    rightText,
    leftText,
    rightTextStyle,
    leftTextStyle,
    checkedComponent,
    unCheckedComponent,
    onClick,
    onPress,
    isChecked,
    checkBoxColor,
    checkedCheckBoxColor = primary,
    uncheckedCheckBoxColor = '#D6DDDE',
    disabled,
    index,
    size = 20,
    variant
  } = props

  function handlePress() {
    if (onPress) onPress(!isChecked, index)
    if (onClick) onClick(!isChecked, index)
  }

  function renderRight() {
    if (!rightText) return null
    return (
      <Text style={[styles.rightText, rightTextStyle]}>{rightText}</Text>
    )
  }

  function renderLeft() {
    if (!leftText) return null
    return (
      <Text style={[styles.leftText, leftTextStyle]}>{leftText}</Text>
    )
  }

  function renderImage() {
    if (isChecked) {
      return checkedComponent || genCheckedIcon()
    }
    return unCheckedComponent || genCheckedIcon()
  }

  function getCheckedCheckBoxColor() {
    return checkedCheckBoxColor || checkBoxColor
  }

  function getUncheckedCheckBoxColor() {
    return uncheckedCheckBoxColor || checkBoxColor
  }

  function getTintColor() {
    return isChecked ? getCheckedCheckBoxColor() : getUncheckedCheckBoxColor()
  }

  function genCheckedIcon() {
    const sourceSquare = isChecked ? 'square-check' : ['far', 'square']
    const sourceCircle = isChecked ? 'circle-check' : ['fa', 'circle']
    const source = variant === 'circle' ? sourceCircle : sourceSquare
    return (
      <FontAwesomeIcon icon={source} size={size} color={getTintColor()} />
    )
  }

  return (
    <TouchableOpacity
      style={style}
      onPress={handlePress}
      disabled={disabled}
    >
      <View style={styles.container}>
        {renderLeft()}
        {renderImage()}
        {renderRight()}
      </View>

    </TouchableOpacity>

  )
}
