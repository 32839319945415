/**
 * TESTING NOTES:
 * This file is automatically mocked in `jestConfiguration/mockBSAccountReduxMethods.js`
 * See the file for why that happens
*/
export const SET_BS_ACCOUNT = 'SET_BS_ACCOUNT'

export const setBSAccount = ({ account, accountId, accountCategories }) => ({
  type: SET_BS_ACCOUNT,
  payload: { account, accountId, accountCategories },
});
