import { Platform } from 'react-native'
import { purple, rgba } from './styles'
import MoreTools from './components/tools/MoreTools'

function CalculatorsScreen({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const title = 'Calculators'
  const sections = [
    {
      icon: 'chart-line-up',
      title: 'Future Value Calculator',
      subtitle: 'Calculate how much money you will have in the future',
      iconColor: purple,
      iconBackgroundColor: rgba(purple, 0.1),
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Future Value Calculator Intro' })
      }
    }, {
      icon: 'hand-holding-dollar',
      title: 'Loan Calculator',
      subtitle: 'Calculate how much you will pay in interest',
      iconColor: purple,
      iconBackgroundColor: rgba(purple, 0.1),
      onPress: () => {
        navigation.navigate('Modal', { screen: isNotWeb ? 'Loan Calculator Intro' : 'Loan Calculator Selection' })
      }
    }, {
      icon: 'sack-dollar',
      title: 'Millionaire Calculator',
      subtitle: 'Calculate how long it will take to become a millionaire',
      iconColor: purple,
      iconBackgroundColor: rgba(purple, 0.1),
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Millionaire Calculator Intro' })
      }
    }, {
      icon: 'magnifying-glass-dollar',
      title: 'Net Worth Calculator',
      subtitle: 'Calculate your net worth',
      iconColor: purple,
      iconBackgroundColor: rgba(purple, 0.1),
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Net Worth Calculator Intro' })
      }
    },
    {
      icon: 'calculator',
      title: 'Opportunity Cost',
      subtitle: 'Calculate the opportunity cost of your decisions',
      iconColor: purple,
      iconBackgroundColor: rgba(purple, 0.1),
      onPress: () => {
        navigation.navigate('Modal', { screen: isNotWeb ? 'Opportunity Cost Intro' : 'Opportunity Cost' })
      }
    },
  ]

  return (
    <MoreTools navigation={navigation} sections={sections} title={title} />
  );
}

export default CalculatorsScreen
