import { StyleSheet, Text, View } from 'react-native'
import hapticFeedback from './hapticFeedback'
import { red, lightestgray, gainsboro } from '../styles/colors'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  container: {
    backgroundColor: lightestgray,
    flexDirection: 'column',
    padding: 2 * 5,
    borderRadius: 2 * 3,
    marginBottom: 12,
    borderWidth: 1,
    borderColor: gainsboro
  },
  valueContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    color: 'rgb(66, 86, 103)',
    fontSize: 12
  },
  input: {
    color: 'rgb(66, 86, 103)',
    fontSize: 18,
    fontWeight: '500'
  },
  icon: { marginRight: 12 },
  errorStyle: {
    paddingVertical: 6,
    paddingHorizontal: 6
  },
  error: {
    color: red,
    marginBottom: 12,
    fontSize: 12
  }
})

export default function MockInput({ Icon, style, label, value, errorMessage, touched, onPress }) {
  function handlePress() {
    hapticFeedback()
    if (onPress) onPress()
  }

  return (
    <>
      <TouchableOpacity
        disabled={!onPress}
        onPress={handlePress}
        style={[
          styles.container,
          style,
          { borderColor: touched && errorMessage ? 'red' : gainsboro }
        ]}
      >
        {label && (
          <Text
            style={[styles.label, { color: touched && errorMessage ? 'red' : 'rgb(66, 86, 103)' }]}
          >
            {label}
          </Text>
        )}

        <View style={styles.valueContainer}>
          {Icon && <Icon fill="rgb(66, 86, 103)" height={18} width={18} style={styles.icon} />}
          <Text style={[styles.input]}>{value}</Text>
        </View>
      </TouchableOpacity>
      {errorMessage && touched && (
        <View style={styles.errorStyle}>
          <Text style={styles.error}>{errorMessage}</Text>
        </View>
      )}
    </>
  )
}
