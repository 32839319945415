import { useState } from 'react'
import { View, StyleSheet, Image, Text, useWindowDimensions, } from 'react-native'

import { LinearGradient } from 'expo-linear-gradient'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import CloseLeftButton from '../CloseLeftButton'

import capitalize from '../../functions/capitalize'

import FadeInView from '../FadeInView'
import { black, darkblue, lightgray, primary, red, rgba, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'
import hapticFeedback from '../hapticFeedback'
import TouchableOpacity from '../TouchableOpacity'

import { saveWellnessChecklistItem } from '../../actions'

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1
  },
  topContainer: { flex: 1, },
  bottomContainer: {
    flex: 1,
    backgroundColor: lightgray,
    alignItems: 'center'
  },
  closeButton: { color: white },
  title: {
    color: white,
    fontSize: 24,
    fontWeight: '600',
    paddingHorizontal: DEFAULT_SPACING,
    marginTop: DEFAULT_SPACING * 2
  },
  questionNumber: {
    color: white,
    fontSize: 14,
    paddingHorizontal: DEFAULT_SPACING
  },
  categoryContainer: {
    flex: 1,
    justifyContent: 'flex-start'
  },
  finn: {
    width: 150,
    height: 150
  },
  safeContainer: { flex: 1 },
  linearGradient: {
    width: '100%',
    position: 'absolute',
    zIndex: 0
  },
  speechBubble: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: white,
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING * 3,
    margin: DEFAULT_SPACING,
    borderRadius: DEFAULT_SPACING * 2
  },
  speechBubbleText: {
    color: darkblue,
    fontSize: 28,
    fontWeight: '500'
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 35,
    borderBottomWidth: 70,
    borderLeftWidth: 35,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: white,
    borderLeftColor: 'transparent',
    transform: [{ rotate: '160deg' }],
    position: 'absolute',
    bottom: -40,
    left: '50%',
    marginLeft: -92
  },
  finnContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  innerActionButton: {
    height: 80,
    width: 80,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center'
  },
  actionButtonBorder: {
    height: 90,
    width: 90,
    borderRadius: 45,
    borderWidth: 5,
    backgroundColor: 'transparent'
  },
  failInnerActionButton: { backgroundColor: red },
  successInnerActionButton: { backgroundColor: primary },
  failActionButton: { borderColor: rgba(red, 0.2) },
  successActionButton: { borderColor: rgba(primary, 0.2) },
  actionButtonContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  }
})

function ActionButton({ success, onPress }) {
  const buttonStyle = success ? styles.successActionButton : styles.failActionButton
  const innerButtonStyle = success ? styles.successInnerActionButton : styles.failInnerActionButton

  return (
    <TouchableOpacity
      style={[styles.actionButtonBorder, buttonStyle]}
      onPress={() => {
        if (onPress) onPress()
        hapticFeedback()
      }}
    >
      <View style={[styles.innerActionButton, innerButtonStyle]}>
        <FontAwesomeIcon
          icon={success ? 'check' : 'x'}
          size={30}
          color={white}
        />
      </View>
    </TouchableOpacity>
  )
}

export default function WellnessQuizWizard({ navigation, category, wellnessChecklist, onFinish }) {
  const dispatch = useDispatch()
  const { height } = useWindowDimensions()
  const image = require('../../assets/finn/smart.png')

  const [questionNumber, setQuestionNumber] = useState(0)
  const currentQuestion = wellnessChecklist[questionNumber]

  function onSuccess() {
    dispatch(saveWellnessChecklistItem(currentQuestion.financial_wellness_item_id, true))

    if (questionNumber + 1 === wellnessChecklist.length) {
      onFinish()
    } else {
      setQuestionNumber(questionNumber + 1)
    }
  }

  function onFail() {
    dispatch(saveWellnessChecklistItem(currentQuestion.financial_wellness_item_id, false))

    if (questionNumber + 1 === wellnessChecklist.length) {
      onFinish()
    } else {
      setQuestionNumber(questionNumber + 1)
    }
  }

  return (
    <View style={styles.container}>
      <View style={[styles.topContainer, { backgroundColor: category.color }]}>
        <LinearGradient
          colors={['transparent', 'transparent', rgba(black, 0.35)]}
          style={[styles.linearGradient, { height: height / 2 }]}
          locations={[0, 0.1, 1]}
        />
        <View style={[styles.safeContainer]}>
          <CloseLeftButton onPress={() => onFinish()} closeButtonTextStyle={styles.closeButton} offset close />

          <View style={styles.categoryContainer}>
            <FadeInView>
              <Text style={styles.title}>{capitalize(category.description)}</Text>
              <Text style={styles.questionNumber}>
                {questionNumber + 1}
                {' '}
                of
                {' '}
                {wellnessChecklist.length}
              </Text>
            </FadeInView>
          </View>
        </View>
      </View>
      <View style={styles.bottomContainer}>
        <FadeInView style={styles.finnContainer}>
          <Image style={[styles.finn, { marginTop: height / 8 }]} source={image} resizeMode="contain" />

          <View style={styles.actionButtonContainer}>
            <ActionButton success={false} onPress={onFail} />
            <ActionButton success onPress={onSuccess} />
          </View>
        </FadeInView>
      </View>

      <FadeInView style={[styles.speechBubble, { top: height / 4, minHeight: height / 3 }]}>
        <Text style={styles.speechBubbleText}>{currentQuestion.wizard_description}</Text>
        <View style={styles.triangle} />
      </FadeInView>
    </View>
  )
}
