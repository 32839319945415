import { del } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { currentUserSelector } from '../selectors'

export const DELETE_BS_ACCOUNT = 'DELETE_BS_ACCOUNT'

export const deleteBSAccount = ({ accountType, accountId }) => async (dispatch, getState) => {
  try {
    const { id: userId } = currentUserSelector(getState())
    await del(proApplicationEndpoints.deleteAccount(userId, accountId))

    dispatch({
      type: DELETE_BS_ACCOUNT,
      payload: { accountType, accountId }
    })
  } catch (e) {
    console.warn(e, e.response)
  }
}
