import { post } from 'http'

import {
  getBrand,
  getBuildNumber,
  getDeviceId,
  getSystemName,
  getSystemVersion,
  getUniqueId,
  getVersion
} from 'react-native-device-info'

export const setDevice = function (token) {
  return async (dispatch) => {
    try {
      const payload = {
        brand: getBrand(),
        build_number: getBuildNumber(),
        device_id: getDeviceId(),
        system_name: getSystemName(),
        system_version: getSystemVersion(),
        unique_id: getUniqueId(),
        version: getVersion()
      }

      if (token) payload.token = token

      await post('api/users/devices/', payload)
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
