import { filter, find } from 'lodash'
import moment from 'moment'
import isSameYearMonth from './isSameYearMonth'

/**
 * INITIALIZE the function with projections because we want to avoid passing it around all the time
 * See functions/getFinancialProjectionsOverview for a reference of what projections look like
 * */
export default function financialProjectionUtils(projections) {
  return {
    /**
     * This function expects startMonth and endMonth to be moment objects
     * NOTE: This utility function is used as a closure because
     * we don't want to constantly pass around the `projections` list.
     * The projections list is projections for every month for the next 70 years (atleast)
     * */
    between: (startDate, endDate) =>
      filter(
        projections,
        (projn) =>
          projn.date &&
          moment(projn.date).isBetween(startDate, endDate, 'month', 'year')
      ),
    findAtDate: (date) =>
      find(projections, (projection) => {
        return isSameYearMonth(moment(projection.date), date)
      })
  }
}
