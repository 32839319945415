export const white = '#ffffff'
export const black = '#000000'
export const red = '#DE3654'
export const magicPotion = '#FF4566'
export const skyblue = '#27d5ff'
export const lightblue = '#4F8FEF'
export const grayblue = '#425667'
export const darkblue = '#2C3A48'

export const lightestgray = '#EBF1F4'
export const lightgray = '#D0D7D9'
export const darkgrayblue = '#283a49'
export const gray = '#8597a5'
export const gunmetal = '#6A7B7E'
export const antiFlashWhite = '#EDF1F3'
export const lotion = '#fafbfb'

export const primary = '#1eb86e'
export const success = primary
export const secondary = '#5A6669'
export const spanishGray = '#8d969a'
export const romanSilver = '#808a8e'
export const tertary = '#93c9ba'
export const info = lightblue
export const warning = '#ffc94c'
export const indianYellow = '#D8A856'
export const danger = red
export const error = red
export const moss = '#5C6746'

export const pink = '#F933B1'
export const purple = '#7159DB'
export const orange = '#EB8826'
export const darkOrange = '#A65A1A'
export const orangeRed = '#ff674d'

export const primaryGradientStart = '#3E8A5C'
export const primaryGradientEnd = '#70CC6E'

export const calPolyPomonaGreen = '#204223'
export const kombuGreen = '#324d30'
export const iguanaGreen = '#7CBA82'
export const lightSilverGreen = '#D2E5D4'
export const darkJungleGreen = '#112A18'

export const gainsboro = '#d5dde1'

export const backgroundColorOpacity = 0.1

export function rgba(hex, alpha) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`
  }

  console.error('Bad Hex')

  return 'rgba(0,0,0,0.9)'
}
