import { get } from 'http'
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  ActivityIndicator,
  Platform
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { findIndex, each } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import LocationSwitcher from './components/careers/LocationSwitcher'
import HeaderLabel from './components/HeaderLabel'
import Card from './components/Card'
import MultiSlider from './components/MultiSlider'
import InformationBlock from './components/InformationBlock'
import InputSelect from './components/InputSelect'

import formatCurrency from './functions/formatCurrency'

import { grayblue, white } from './styles/colors'

import CareerAdditionalInformation from './components/careers/CareerAdditionalInformation'

import { getOccupations, setCareerLocation } from './actions'
import {
  careerLocationSelector,
  loadingSelector,
  occupationsSelector
} from './selectors'
import { DEFAULT_SPACING } from './styles'

const basicStyles = {
  container: {
    backgroundColor: 'rgb(236, 241, 244)',
    flex: 1
  },
  headerContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING
  },
  cardContainer: { paddingHorizontal: DEFAULT_SPACING },
  descriptionContainer: {
    padding: DEFAULT_SPACING,
    backgroundColor: white
  },
  scrollviewContentContainer: { paddingVertical: DEFAULT_SPACING },
  card: {
    padding: DEFAULT_SPACING,
    borderRadius: 12,
    flex: 1,
    marginBottom: 12
  },
  cardParallel: {
    width: '48%',
    flex: 0
  },
  rowParallel: { justifyContent: 'space-between' },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  cardRow: {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  cardIcon: { marginRight: 12 },
  image: {
    marginVertical: DEFAULT_SPACING,
    width: '100%'
  },
  label: {
    fontSize: 14,
    color: 'rgb(109, 122, 125)',
    marginBottom: 6
  },
  value: {
    fontSize: 24,
    fontWeight: '600',
    color: grayblue
  },
  headerLabel: {
    fontSize: 18,
    color: 'rgb(51, 63, 75)',
    marginBottom: DEFAULT_SPACING
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    scrollviewContentContainer: { paddingRight: 36 },
    headerContainer: { marginBottom: 0 },
    container: { paddingRight: 40 },
    listHeader: {
      fontSize: 36,
      fontWeight: '600',
      paddingVertical: 0
    },
    cardContainer: { paddingHorizontal: 0 },
    headerLabel: { fontSize: 36 }
  }
})

const isNotWeb = Platform.OS !== 'web'

const experienceLevelHash = {
  BOTTOM_10: 'Bottom 10%',
  BOTTOM_25: 'Bottom 25%',
  MEDIAN: 'Median',
  TOP_25: 'Top 25%',
  TOP_10: 'Top 10%'
}

const experienceLevelOptions = [
  'BOTTOM_10',
  'BOTTOM_25',
  'MEDIAN',
  'TOP_25',
  'TOP_10'
]

function CardRow({ label, value, Icon }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <View style={[styles.row, styles.cardRow]}>
        {Icon && (
          <Icon
            style={styles.cardIcon}
            fill={grayblue}
            height={20}
            width={20}
          />
        )}
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  )
}

const optionsForSelect = Object.keys(experienceLevelHash).map((key) => ({
  label: experienceLevelHash[key],
  value: key
}))

export function CareerExplore({ occupation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const isMounted = useRef(false)
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => loadingSelector(state))
  const [experienceLevel, setExperienceLevel] = useState('MEDIAN')
  const [detail, setDetail] = useState({})
  const careerLocation = useSelector((state) => careerLocationSelector(state))

  const experienceLevelToWage = {
    BOTTOM_10: occupation.annual_pct10,
    BOTTOM_25: occupation.annual_pct25,
    MEDIAN: occupation.annual_median,
    TOP_25: occupation.annual_pct75,
    TOP_10: occupation.annual_pct90
  }

  function SalaryForOccupation({ experienceLevel }) {
    return (
      <View style={[styles.row, { alignItems: 'flex-end' }]}>
        <Text style={styles.value}>
          {experienceLevelToWage[experienceLevel]
            ? `$${formatCurrency(experienceLevelToWage[experienceLevel])}`
            : '> $208,000'}
        </Text>
        <Text style={styles.label}>
          {` (${experienceLevelHash[experienceLevel]})`}
        </Text>
      </View>
    )
  }

  const searchGroups = async (careerLocation) => {
    try {
      dispatch(getOccupations(careerLocation.id))
    } catch (error) {
      console.error(error, error.response)
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      searchGroups(careerLocation)
    } else {
      isMounted.current = true
    }
  }, [careerLocation])

  const fetchDetail = async () => {
    const response = await get(occupation.detail)
    setDetail(response.data)
  }

  useEffect(() => {
    fetchDetail()
  }, [occupation, careerLocation])

  return (
    <ScrollView contentContainerStyle={styles.scrollviewContentContainer}>
      {loading && isMounted && <ActivityIndicator />}

      <View style={styles.headerContainer}>
        <HeaderLabel style={styles.headerLabel}>{occupation.name}</HeaderLabel>
      </View>

      <View style={styles.cardContainer}>
        <Card style={styles.card}>
          <CardRow
            label={
              occupation.national ? 'National Average Salary' : 'Average Salary'
            }
            value={<SalaryForOccupation experienceLevel={experienceLevel} />}
          />

          <Text style={[styles.label, { marginTop: DEFAULT_SPACING }]}>
            Experience Level
          </Text>
          <View>
            {isNotWeb ? (
              <MultiSlider
                min={0}
                max={experienceLevelOptions.length - 1}
                values={[
                  findIndex(
                    experienceLevelOptions,
                    (v) => v === experienceLevel
                  )
                ]}
                enableLabel={false}
                onValuesChange={(values) => {
                  setExperienceLevel(experienceLevelOptions[values[0]])
                }}
              />
            ) : (
              <InputSelect
                placeholder="--"
                value={experienceLevel}
                options={optionsForSelect}
                onChangeText={(evt) => {
                  setExperienceLevel(evt.target.value)
                }}
                onSubmitEditing={(value) => {
                  setExperienceLevel(value)
                }}
              />
            )}
          </View>

          {!experienceLevelToWage[experienceLevel] && (
            <InformationBlock text="Looks like you chose a high-paying career! Our data set does not include incomes above $208,000 (at this time), but this should give you some idea about what to expect." />
          )}
        </Card>
      </View>

      <CareerAdditionalInformation detail={detail} occupation={occupation} />
    </ScrollView>
  )
}

export default function CareerExploreScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const careerLocation = useSelector((state) => careerLocationSelector(state))

  const { params } = route
  const { occ_code } = params.occupation
  const [occupation, setOccupation] = useState(params.occupation)

  const occupationGroups = useSelector((state) =>
    occupationsSelector(state, careerLocation.id)
  )

  useEffect(() => {
    each(occupationGroups, (occGroup) => {
      each(occGroup.careers, (occ) => {
        if (occ.occ_code == occ_code) {
          setOccupation(occ)
        }
      })
    })
  }, [occupationGroups])

  useLayoutEffect(() => {
    navigation.setOptions({ title: 'Explore Careers' })
  }, [navigation])

  return (
    <View style={styles.container}>
      <LocationSwitcher
        city={careerLocation}
        setCity={(loc) => dispatch(setCareerLocation(loc))}
      />
      <CareerExplore occupation={occupation} />
    </View>
  )
}
