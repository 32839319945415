export default function investmentType({ contribution_selection, annual_contribution_amount }) {
  console.log('contribution_selection', contribution_selection)
  console.log('annual_contribution_amount', annual_contribution_amount)

  if (contribution_selection === 'ANNUAL_CONTRIBUTION') {
    if (annual_contribution_amount == 365) {
      return 'Dollar per Day'
    }
    return 'None'
  } if (contribution_selection === 'MILLIONAIRE_MAKER') {
    return 'Millionaire Maker'
  } if (contribution_selection === 'PCT_OF_SALARY') {
    return 'Stay the Course'
  } if (contribution_selection === 'DOLLAR_AMOUNT') {
    return 'Dollar Amount (Custom)'
  } if (contribution_selection === 'DOLLAR_A_DAY') {
    return 'Dollar per Day'
  } if (contribution_selection === 'MAX_401K') {
    return 'Retirement Maximizer'
  } if (contribution_selection === 'ROTH_IRA') {
    return 'Roth IRA Maximizer'
  } if (contribution_selection === 'CUSTOM_GOAL') {
    return 'Custom'
  } if (contribution_selection === 'PENSION') {
    return 'Pension'
  }
  return 'Custom'
}
