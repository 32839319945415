import { last } from 'lodash'

const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'P', 'E']

export default function abbreviateCurrency(number, decimals = 1) {
  // handle 0 separately
  if (number === 0) return '0'

  // handle negative numbers
  const sign = number < 0 ? '-' : ''

  // work with absolute value
  const absNumber = Math.abs(number)

  // determine tier (this determines SI symbol)
  const tier = (Math.log10(absNumber) / 3) | 0

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier]
  const scale = 10 ** (tier * 3)

  // scale the number
  const scaled = absNumber / scale

  // if 1.0 just return 1
  const decimalsShouldBeZero = last(scaled.toFixed(decimals).split('.')) == '0'

  // format number, add suffix, and reintroduce the negative sign if needed
  return sign + scaled.toFixed(decimalsShouldBeZero ? 0 : decimals) + suffix
}
