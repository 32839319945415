import { useState } from 'react';
import { Platform, StyleSheet, Text, TextInput, View } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import { gray, lightestgray, lightgray, red } from '../styles';

const basicStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputWrapper: { width: '49%', },
  input: {
    width: 'calc(100% - 12px)',
    padding: 6,
    placeholder: lightgray,
    backgroundColor: lightestgray,
    borderRadius: 4,
  },
  hint: {
    color: gray,
    paddingHorizontal: 2
  },
  error: { color: red, },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      input: {
        paddingHorizontal: 12,
        paddingVertical: 8,
      },
      hint: { paddingTop: 6, }
    },
  }
)

function RangePicker({ min, max, values, onValuesChange }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [errs, setErrs] = useState([false, false]);

  const handleChange = (val, idx) => {
    const numVal = val * 1
    let temp = values;
    temp[idx] = numVal;
    onValuesChange(temp);
    temp = errs
    temp[0] = numVal < min
    temp[1] = numVal > max
    setErrs(temp)
  };

  return (
    <View style={styles.container}>
      <View style={styles.inputWrapper}>
        <TextInput
          style={styles.input}
          placeholder="From"
          placeholderTextColor={gray}
          onChange={(e) => handleChange(e.target.value, 0)}
        />
        <Text style={errs[0] ? [styles.hint, styles.error] : styles.hint}>
          Min:
          {min}
        </Text>
      </View>
      <View style={styles.inputWrapper}>
        <TextInput
          style={styles.input}
          placeholder="To"
          placeholderTextColor={gray}
          onChange={(e) => handleChange(e.target.value, 1)}
        />
        <Text style={errs[1] ? [styles.hint, styles.error] : styles.hint}>
          Max:
          {max}
        </Text>
      </View>
    </View>
  );
}

export default RangePicker;
