import { isEmpty, isNil, map } from 'lodash';
import { useLayoutEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { saveBudgetItem, deleteBudgetItem } from './actions';
import CurrencyInput from './components/CurrencyInput';
import DateTimePicker from './components/DateTimePicker';
import Form from './components/Form';
import Input from './components/Input';
import InputSelect from './components/InputSelect';
import Link from './components/Link';
import { GET_PERIDICITY_TEXT, PERIODICITY_VALUES } from './constants';
import { Fields, InitialValues, ValidationSchema } from './schemas/budgetSchema';
import { bsAccountsSelector, currentProPlanSelector, currentUserSelector } from './selectors';
import { DEFAULT_SPACING, info, rgba, white } from './styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    paddingHorizontal: 12,
    position: 'relative',
  },
  iconContainer: {
    backgroundColor: white,
    alignItems: 'center',
    marginBottom: 2 * DEFAULT_SPACING
  },
  addLabelContainer: {
    alignItems: 'flex-start',
    marginLeft: 12
  },
  addLabel: {
    color: info,
    alignContent: 'stretch'
  },
  inputField: { marginBottom: DEFAULT_SPACING },
  button: { marginBottom: DEFAULT_SPACING }
})

const getFrequencyOptions = (options) => map(
  options,
  (option) => ({
    label: GET_PERIDICITY_TEXT(option),
    value: option
  })
)

  const getAssetAccountOptions = (options) => {
    const nullOption = [{
      name: '',
      accountId: null
    }];

    return map(
      [...nullOption, ...options],
      (option) => ({
        label: option.name,
        value: option.accountId
      })
    );
  }

export default function UpdateBudgetItemScreen({ route, navigation }) {
  const { title, budgetSubCategory, budgetCategory, budgetItem = {} } = route.params
  const existingItem = !isEmpty(budgetItem) && !isNil(budgetItem.budgetItemId)

  const { id: userId } = useSelector(currentUserSelector)
  const { planId } = useSelector(currentProPlanSelector)
  const { assetAccounts } = useSelector(bsAccountsSelector)


  const SAVE_FIRST_CONTRIBUTION_TARGET = 'SAVE_FIRST_CONTRIBUTION_TARGET'

  const dispatch = useDispatch()
  const callAddBudgetItem = (values) => {
    const budgetReq = {
      userId,
      planId,
      budgetSubCategory,
      name: values[Fields.name],
      amount: values[Fields.amount],
      periodicity: {
        frequency: values[Fields.frequency],
        startDate: values[Fields.startDate],
        endDate: values[Fields.endDate]
      }
    }

    if(values[Fields.contributionAccountId]) {
      budgetReq.advanced = {
        contributionAccountId: values[Fields.contributionAccountId]
      }      
    }

    if (existingItem) {
      budgetReq.budgetItemId = budgetItem.budgetItemId
    }

    dispatch(saveBudgetItem(budgetReq, planId))
    navigation.goBack()
  }

  const onNegtiveButtonPress = () => {
    const { budgetItemId } = budgetItem
    dispatch(deleteBudgetItem({ budgetItemId, planId }))
    navigation.pop()
  }

  useLayoutEffect(() => {
    navigation.setOptions({ title: existingItem ? `Edit ${title}` : `New ${title} Item` })
  })

  const initialValues = InitialValues(budgetItem)

  return (
    <Form
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      buttonText={existingItem ? 'Save Changes' : 'Add Item'}
      negativeButtonText="Delete Budget Item"
      onNegativeButtonPress={existingItem ? onNegtiveButtonPress : null}
      onSubmit={callAddBudgetItem}
    >
      {({
        setFieldValue,
        handleChange,
        values,
        errors,
        touched
      }) => {
        const [advancedFieldsVisible, setAdvancedFieldsVisible] = useState(false);
        const toggleAdvancedFields = () => setAdvancedFieldsVisible(!advancedFieldsVisible)

        return (

            <View style={styles.container}>
              <Input
                name={Fields.name}
                errorMessage={errors[Fields.name]}
                touched={touched[Fields.name]}
                style={styles.inputField}
                onChangeText={handleChange(Fields.name)}
                label="Name"
                value={values[Fields.name]}
              />

              <CurrencyInput
                name={Fields.amount}
                errorMessage={errors[Fields.amount]}
                touched={touched[Fields.amount]}
                style={styles.inputField}
                onChangeText={handleChange(Fields.amount)}
                label="Amount"
                value={values[Fields.amount]}
                precision={2}
              />

              <View style={styles.addLabelContainer}>
                <Link
                  style={styles.addLabel}
                  icon={advancedFieldsVisible ? 'caret-down' : 'caret-right'}
                  type="fas"
                  iconSize={12}
                  iconRight
                  onPress={toggleAdvancedFields}
                >
                  Go Deeper (optional)
                </Link>
              </View>

              {
                advancedFieldsVisible && (
                  <View>
                    <InputSelect
                      name={Fields.frequency}
                      errorMessage={errors[Fields.frequency]}
                      touched={touched[Fields.frequency]}
                      style={styles.inputField}
                      onSubmitEditing={(v) => setFieldValue(Fields.frequency, v)}
                      label="Frequency"
                      value={values[Fields.frequency]}
                      options={getFrequencyOptions(PERIODICITY_VALUES)}
                    />
                    <DateTimePicker
                      name={Fields.startDate}
                      errorMessage={errors[Fields.startDate]}
                      touched={touched[Fields.startDate]}
                      onChange={(v) => setFieldValue(Fields.startDate, v)}
                      label="Start Date"
                      value={values[Fields.startDate]}
                      display="spinner"
                    />
                    <DateTimePicker
                      name={Fields.endDate}
                      errorMessage={errors[Fields.endDate]}
                      touched={touched[Fields.endDate]}
                      onChange={(v) => setFieldValue(Fields.endDate, v)}
                      label="End Date"
                      value={values[Fields.endDate]}
                      display="spinner"
                    />

                    { budgetCategory == SAVE_FIRST_CONTRIBUTION_TARGET && (
                      <InputSelect
                        name={Fields.contributionAccountId}
                        errorMessage={errors[Fields.contributionAccountId]}
                        touched={touched[Fields.contributionAccountId]}
                        style={styles.inputField}
                        onSubmitEditing={(v) => setFieldValue(Fields.contributionAccountId, v)}
                        label="Contribution Account"
                        value={values[Fields.contributionAccountId]}
                        options={getAssetAccountOptions(assetAccounts)}
                      />
                    )}
                  </View>
                )
              }
            </View>
        )
      }}
    </Form>
  )
}
