import handleBranchParams from './handleBranchParams'

// You can test this by going to
// https://troutwood.app.link/without-class-data?$fallback_url=http://localhost:19006/

export default function setUpBranch() {
  (function (b, r, a, n, c, h, _, s, d, k) { if (!b[n] || !b[n]._q) { for (;s < _.length;)c(h, _[s++]); d = r.createElement(a); d.async = 1; d.src = 'https://cdn.branch.io/branch-latest.min.js'; k = r.getElementsByTagName(a)[0]; k.parentNode.insertBefore(d, k); b[n] = h } }(window, document, 'script', 'branch', (b, r) => { b[r] = function () { b._q.push([r, arguments]) } }, { _q: [], _v: 1 }, 'addListener applyCode banner closeBanner creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode'.split(' '), 0));

  branch.init('key_live_cl0n0VxgV9PbFKYxWcu6HinhCEf2s2Ei')

  branch.data((error, data) => {
    const params = data.data_parsed
    handleBranchParams({ error, params })
  })
}
