import { useState, useEffect, useLayoutEffect } from 'react'
import { Text, View, StyleSheet, ScrollView } from 'react-native'

import { map, capitalize, compact, forEach } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'http'
import Button from './components/Button'
import Divider from './components/Divider'
import Container from './components/Container'

import IconDataRow from './components/IconDataRow'

import HeaderTitleAndSubtitle from './components/planSummary/HeaderTitleAndSubtitle'

import formatCurrency from './functions/formatCurrency'

import { gunmetal, info, lightblue, primary, rgba, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import IconContainer from './components/planSummary/IconContainer'
import { ANNUAL } from './constants'
import { loadingSelector, currentPlanSelector } from './selectors'
import { setLoading } from './actions'
import handleError from './functions/handleError'

const styles = StyleSheet.create({
  row: { flexDirection: 'row', },
  container: { backgroundColor: white },
  dollarAmount: {
    textAlign: 'center',
    fontSize: 64,
    fontWeight: '600',
    letterSpacing: -1.457143,
    marginTop: DEFAULT_SPACING * 3,
    marginBottom: DEFAULT_SPACING * 2
  },
  subtitles: {
    color: gunmetal,
    textAlign: 'center'
  },
  divider: { marginVertical: DEFAULT_SPACING * 2 },
  contentContainer: { paddingBottom: DEFAULT_SPACING * 2 }
})

export default function SurplusScreen({ route, navigation }) {
  const { id, params } = route
  const { title, } = params
  const [result, setResult] = useState({})
  const dispatch = useDispatch()

  const currentPlan = useSelector((state) => currentPlanSelector(state, id))

  async function fetchAndSetData(currentPlan) {
    try {
      dispatch(setLoading({ loading: true }))
      const plan = await get(`api/v1/plans/${currentPlan.id}/`)

      setResult({ ...currentPlan, ...plan.data })

      dispatch(setLoading({ loading: false }))
    } catch (error) {
      handleError(error, 'Error fetching plan data')
      dispatch(setLoading({ loading: false }))
    }
  }

  useEffect(() => {
    fetchAndSetData(currentPlan)
  }, [currentPlan])

  const divisor = 1
  const duration = ANNUAL

  const surplus = result.surplus && result.surplus[duration] || 0
  const fontColor = surplus >= 0 ? primary : 'rgb(222, 54, 84)'

  const { loading } = useSelector((state) => loadingSelector(state))

  const addOnOptions = []

  let lifehacks = [
    result.housing_cost && result.housing_cost.without_life_hack[duration] > result.housing_cost.cost[duration] && {
      label: 'Housing',
      value: `+ $${formatCurrency((result.housing_cost.without_life_hack[duration] - result.housing_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'house',
        color: 'rgb(66, 86, 103)',
        backgroundColor: 'rgba(66, 86, 103, 0.1)'
      }
    },

    result.utilities_cost && result.utilities_cost.without_life_hack[duration] > result.utilities_cost.cost[duration] && {
      label: 'Utilities',
      value: `+ $${formatCurrency((result.utilities_cost.without_life_hack[duration] - result.utilities_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'bolt',
        color: 'rgb(255, 103, 77)',
        backgroundColor: 'rgba(255, 103, 77, 0.1)'
      }
    },

    result.transportation_cost && result.transportation_cost.without_life_hack[duration] > result.transportation_cost.cost[duration] && {
      label: 'Transportation',
      value: `+ $${formatCurrency((result.transportation_cost.without_life_hack[duration] - result.transportation_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'car',
        color: 'rgb(118, 88, 227)',
        backgroundColor: 'rgba(118, 88, 227, 0.1)'
      }
    },

    result.food_cost && result.food_cost.without_life_hack[duration] > result.food_cost.cost[duration] && {
      label: 'Food',
      value: `+ $${formatCurrency((result.food_cost.without_life_hack[duration] - result.food_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'utensils',
        color: gunmetal,
        backgroundColor: 'rgba(106, 123, 126, 0.1)'
      }
    },

    result.additional_income_amount && result.additional_income_amount > 0 && {
      label: 'Side Hustle',
      value: `+ $${formatCurrency(result.additional_income_amount / divisor)}`,
      iconStyle: {
        icon: 'chart-line',
        color: primary,
        backgroundColor: rgba(primary, 0.1)
      }
    },

    result.student_loan_payment && result.student_loan_payment.without_life_hack[duration] > result.student_loan_payment.cost[duration] && {
      label: 'Student Loan Forgiveness',
      value: `+ $${formatCurrency((result.student_loan_payment.without_life_hack[duration] - result.student_loan_payment.cost[duration]))}`,
      iconStyle: {
        icon: 'graduation-cap',
        color: 'rgb(255, 201, 77)',
        backgroundColor: 'rgba(255, 201, 77, 0.1)'
      }
    },

    result.healthcare_cost && result.healthcare_cost.without_life_hack[duration] > result.healthcare_cost.cost[duration] && {
      label: "Parent's Health Insurance",
      value: `+ $${formatCurrency((result.healthcare_cost.without_life_hack[duration] - result.healthcare_cost.cost[duration]))}`,
      iconStyle: {
        icon: 'heart',
        color: 'rgb(255, 61, 99)',
        backgroundColor: 'rgba(255, 61, 99, 0.1)'
      }
    }
  ]

  lifehacks = compact(lifehacks)

  const iconToLabelMap = {
    subscription: 'credit-card',
    entertainment: 'tv',
    pet: 'dog',
    charity: 'circle-heart',
    hobby: 'guitar',
    custom: 'star'
  }

  if (result.add_ons) {
    forEach(result.add_ons, (addOn) => {
      const key = addOn.category.toLowerCase()
      addOnOptions.push({
        label: addOn.name || capitalize(key),
        value: `$${formatCurrency((addOn.amount * 12) / divisor)}`,
        iconStyle: {
          icon: iconToLabelMap[key],
          type: 'fal',
          color: lightblue,
          backgroundColor: 'rgba(52, 145, 247, 0.1)'
        }
      })
    })
  }

  useLayoutEffect(() => {
    navigation.setOptions({ headerTitle: title, })
  }, [navigation])

  return (
    <Container>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <Text style={styles.subtitles}>After factoring in living essentials, you have…</Text>

        <Text style={[styles.dollarAmount, { color: fontColor }]}>
          {`$${loading ? '—' : formatCurrency(surplus)}`}
        </Text>

        <Text style={styles.subtitles}>available to spend per year</Text>

        <Divider style={styles.divider} />

        <View style={{ margin: DEFAULT_SPACING }}>
          <HeaderTitleAndSubtitle
            small
            title="Life Hacks"
            subtitle="Looking to save some money? Life hacks are a simple way you can save on your living essentials."
          />

          {map(lifehacks, (rowProps) => <IconDataRow key={rowProps.label} {...rowProps} />)}

          { lifehacks.length == 0
            && (
              <View style={styles.row}>
                <IconContainer icon="house-chimney" text="Housing" />
                <IconContainer icon="car" text="Transportation" />
                <IconContainer icon="utensils" text="Food" />
                <IconContainer icon="chart-line-up" text="Hustle" />
                <IconContainer icon="heart" text="Health" />
              </View>
            )}

          <Button color={info} onPress={() => navigation.navigate('Modal', { screen: 'Edit Life Hacks', params: { title: 'Life Hacks', subtitle: 'Reduce the Cost of Living essentials', currentPlan: result } })} style={{ marginTop: DEFAULT_SPACING, paddingHorizontal: DEFAULT_SPACING, flex: 1, fontSize: 14, textAlign: lifehacks.length == 0 ? 'center' : 'left' }}>
            Add
          </Button>
        </View>

        <Divider style={styles.divider} />

        <View style={{ margin: DEFAULT_SPACING }}>
          <HeaderTitleAndSubtitle
            small
            title="Add Ons"
            subtitle="Have a big item you want to budget for? Customize your plan with an Add-On!"
          />

          {map(addOnOptions, (rowProps, index) => <IconDataRow key={`${rowProps.label}-${index}`} {...rowProps} />)}
          { addOnOptions.length == 0
            && (
              <View style={styles.row}>
                <IconContainer icon="tv" text="Media" />
                <IconContainer icon="guitar" text="Hobby" />
                <IconContainer icon="dog" text="Pet" />
                <IconContainer icon="circle-heart" text="Charity" />
                <IconContainer icon="star" text="Custom" />
              </View>
            )}
          <Button color={info} onPress={() => navigation.navigate('Modal', { screen: 'Edit Add Ons', params: { title: 'Add Ons', subtitle: 'How would you like to spend your surplus?' } })} style={{ marginTop: DEFAULT_SPACING * 2, paddingHorizontal: DEFAULT_SPACING, flex: 1, fontSize: 14, textAlign: addOnOptions.length == 0 ? 'center' : 'left' }}>
            Add
          </Button>
        </View>
      </ScrollView>
    </Container>
  )
}
