import { useLayoutEffect, useRef, useState } from 'react'
import { View, Platform, StyleSheet, Text, ScrollView } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import NumberInput from '../NumberInput'
import Switch from '../Switch'
import MockInput from '../MockInput'
import Form from '../Form'
import InformationBlock from '../InformationBlock'
import HeaderLabel from '../HeaderLabel'

import timeout from '../../functions/timeout'

import { DEFAULT_SPACING, white } from '../../styles'

import { saveCurrentPlan } from '../../actions'

import { ValidationSchema, Fields } from '../../schemas/profileSchema'

import { currentPlanSelector } from '../../selectors'
import CitySearch from '../colleges/CitySearch'
import { gunmetal } from '../../styles/colors'
import CurrencyInput from '../CurrencyInput'
import Input from '../Input'
import computeAge from '../../functions/computeAge'
import BuilderWrapper from './BuilderWrapper'
import Divider from '../Divider'

const basicStyles = {
  container: { flex: 1 },
  text: {
    marginVertical: 8,
    color: gunmetal
  },
  header: {
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING * 2
  },
  formContainer: { paddingBottom: 100 },
  groupContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  column: { width: '100%' },
  avgInterestRate: {},
  submitBtn: {},
  divider: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(mobileStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    header: { textAlign: 'left', width: '100%', fontSize: 24 },
    formContainer: {},
    column: { width: '49%' },
    submitBtn: { bottom: 0, marginBottom: 0, maxWidth: 266 },
    avgInterestRate: { marginBottom: 0 },
    text: { fontSize: 18, marginTop: 0, marginBottom: 24 },
    divider: { marginVertical: 30 }
  },
  [minSize(DEVICE_SIZES.LG)]: {}
})

const isNotWeb = Platform.OS !== 'web'

export default function ProfileForm({ route, navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)

  const form = useSelector((state) => currentPlanSelector(state))

  const ageField = useRef()

  const { title, subtitle, builder, buttonText, useGradient, includeProgress } = route.params

  let {
    is_primary_plan,
    loan_balance,
    loan_rate,
    loan_period,
    have_loans,
    age,
    birth_date,
    periods,
    step,
    city
  } = form

  age = computeAge(birth_date)

  const planValues = {
    is_primary_plan,
    loan_balance,
    loan_rate,
    loan_period,
    have_loans,
    age,
    birth_date,
    periods,
    city
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      headerTintColor: white,
      title: null
    })
  }, [title, subtitle])

  return (
    <BuilderWrapper
      includeTopper
      includeProgress={includeProgress}
      option="Profile"
    >
      <View style={styles.container}>
        <Form
          buttonText={buttonText}
          validationSchema={ValidationSchema}
          initialValues={planValues}
          buttonContainerStyle={styles.submitBtn}
          onSubmit={async (values) => {
            await timeout(600)

            if (builder) {
              const params = {
                title: 'Plan Builder',
                subtitle: 'Career',
                builder,
                useGradient,
                includeProgress
              }
              navigation.navigate('Modal', { screen: 'Custom Career', params })
            } else {
              navigation.pop()
            }

            dispatch(
              saveCurrentPlan({
                have_loans: values.have_loans,
                loan_balance: values.have_loans && values.loan_balance ? values.loan_balance : null,
                loan_rate: values.have_loans && values.loan_rate ? values.loan_rate : null,
                loan_period: values.have_loans && values.loan_period ? values.loan_period : null,
                birth_date: values.birth_date,
                age: values.age,
                periods: values.periods,
                city_id: values.city.id,
                step: step === 0 ? 1 : step
              })
            )
          }}
        >
          {({ setFieldValue, handleChange, setFieldTouched, values, errors, touched }) => (
            <ScrollView contentContainerStyle={styles.formContainer}>
              <View style={styles.groupContainer}>
                <HeaderLabel style={styles.header}>Profile</HeaderLabel>

                {is_primary_plan && (
                  <View style={styles.column}>
                    <MockInput
                      label="Birth Date"
                      value={
                        values.birth_date ? moment(values.birth_date).format('MM/DD/YYYY') : null
                      }
                    />
                  </View>
                )}

                {!is_primary_plan && (
                  <View style={styles.column}>
                    <Input
                      ref={ageField}
                      keyboardType="numeric"
                      label="Age"
                      name={Fields.age}
                      value={values.age}
                      touched={touched[Fields.age]}
                      errorMessage={errors[Fields.age]}
                      onChangeText={(v) => {
                        setFieldValue(Fields.age, v)

                        if (v) {
                          const now = new Date()
                          const currentYear = now.getFullYear()
                          const birthYear = currentYear - v
                          setFieldValue(Fields.birth_date, new Date(birthYear, 1, 1))
                        }
                      }}
                    />
                  </View>
                )}

                <View style={styles.column}>
                  <Input
                    keyboardType="numeric"
                    label="Years you want to save or invest"
                    value={values.periods}
                    name={Fields.periods}
                    touched={touched[Fields.periods]}
                    errorMessage={errors[Fields.periods]}
                    onChangeText={handleChange(Fields.periods)}
                  />
                </View>

                <View style={styles.column}>
                  <MockInput
                    onPress={async () => {
                      setVisible(true)

                      await timeout(600)

                      setFieldTouched(Fields.city, true)
                    }}
                    label="City, State"
                    touched={touched[Fields.city]}
                    errorMessage={errors[Fields.city]}
                    value={values.city && values.city.name ? values.city.name : ' '}
                  />
                </View>

                <View style={styles.column}>
                  <InformationBlock text="Your location plays a huge factor in reaching your financial goal, from your salary to your living essentials." />
                </View>
              </View>

              {!isNotWeb && <Divider style={styles.divider} />}
              <View>
                <Text style={styles.text}>Do you have or plan to have student loans?</Text>
              </View>

              <Switch
                onPress={() => {
                  setFieldValue('loan_balance', null)
                  setFieldValue('loan_period', null)
                  setFieldValue('loan_rate', null)
                  setFieldValue('loan_national_average', null)
                  setFieldValue('have_loans', !values.have_loans)
                }}
                value={values.have_loans}
                label="I do/will have student loans"
              />

              {values.have_loans && (
                <View>
                  <CurrencyInput
                    unit="$"
                    label="Total Balance"
                    name={Fields.loan_balance}
                    value={values.loan_balance}
                    touched={touched[Fields.loan_balance]}
                    errorMessage={errors[Fields.loan_balance]}
                    onChangeText={handleChange(Fields.loan_balance)}
                  />
                  <NumberInput
                    suffixUnit="%"
                    precision={3}
                    label="Average Interest Rate (Annual)"
                    name={Fields.loan_rate}
                    value={values.loan_rate}
                    touched={touched[Fields.loan_rate]}
                    errorMessage={errors[Fields.loan_rate]}
                    onChangeText={(v) => setFieldValue(Fields.loan_rate, v)}
                    containerStyle={styles.avgInterestRate}
                  />
                  <Input
                    keyboardType="numeric"
                    label="Loan Term (Years)"
                    name={Fields.loan_period}
                    value={values.loan_period}
                    touched={touched[Fields.loan_period]}
                    errorMessage={errors[Fields.loan_period]}
                    onChangeText={(v) => setFieldValue(Fields.loan_period, v)}
                  />
                  <Switch
                    label="Use National Averages"
                    value={values.loan_national_average}
                    onPress={async () => {
                      setFieldTouched(Fields.loan_balance, false)
                      setFieldTouched(Fields.loan_rate, false)
                      setFieldTouched(Fields.loan_period, false)

                      if (!values.loan_national_average) {
                        setFieldValue(Fields.loan_balance, 30000)
                        setFieldValue(Fields.loan_rate, '6.00')
                        setFieldValue(Fields.loan_period, '15')
                      } else {
                        setFieldValue(Fields.loan_balance, null)
                        setFieldValue(Fields.loan_rate, null)
                        setFieldValue(Fields.loan_period, null)
                      }

                      setFieldValue(Fields.loan_national_average, !values.loan_national_average)
                    }}
                  />

                  <InformationBlock text="Don’t know your student loan information? That’s okay! The average student borrower graduates with approximately $30,000 in loans at a rate of 6%." />
                </View>
              )}

              <CitySearch
                visible={visible}
                setVisible={setVisible}
                city={values.city}
                setCity={(l) => {
                  setFieldValue(Fields.city, l)
                }}
              />
            </ScrollView>
          )}
        </Form>
      </View>
    </BuilderWrapper>
  )
}
