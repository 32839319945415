import { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import RiskProfileChart from '../RiskProfileChart/RiskProfileChart'
import { DEFAULT_SPACING, white } from '../../../styles'
import Link from '../../Link'
import Button from '../../Button'
import { setAssessScore } from '../../../actions'
import { riskAssessmentSelector } from '../../../selectors'

const styles = StyleSheet.create({
  container: { height: '100%', backgroundColor: white, overflow: 'scroll' },
  description: {
    color: '#2C3A48',
    textAlign: 'center',
    fontSize: 16,
    padding: 24,
  },
  takeAssessmentLink: {
    textAlign: 'center',
    backgroundColor: 'none'
  },
  continueBtn: { marginVertical: DEFAULT_SPACING * 2, marginHorizontal: 18, }
})

function RiskProfile({ navigation }) {
  const dispatch = useDispatch()
  const { assessScore, setting, error } = useSelector((state) => riskAssessmentSelector(state))
  const [apiRes, setApiRes] = useState({
    ability: 0,
    willingness: 0,
    user: 0,
  })
  const [data, setData] = useState({
    ability: 0,
    willingness: 0,
  })
  const [isChanged, setIsChanged] = useState(false)

  const handlePress = () => {
    navigation.navigate('Modal', { screen: 'Risk Assessment' })
  }

  const handleContinue = async () => {
    dispatch(setAssessScore(data))
    const timer = setInterval(() => {
      if (!setting && !error) {
        clearInterval(timer)
        navigation.navigate('Dashboard')
      }
    }, 100);
  }

  const handleChange = (val) => {
    const prev = { ...data }
    setData({ ...prev, ...val })
    setIsChanged(true)
  }

  useEffect(() => {
    if (assessScore) {
      const { ability, willingness, user } = assessScore
      setApiRes({ willingness, ability, user })
      setData({ willingness, ability, user })
    }
  }, [])

  return (
    <ScrollView style={styles.container}>
      <RiskProfileChart
        ability={apiRes?.ability || 0}
        willingness={apiRes?.willingness || 0}
        onChange={handleChange}
      />
      <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
        <Text style={{ fontSize: 12 }}>
          {`Ability: ${data.ability ?? 0}%`}
        </Text>
        <Text style={{ marginLeft: 24, fontSize: 12 }}>
          {`Willingness: ${data.willingness ?? 0}%`}
        </Text>
      </View>
      <Text style={styles.description}>
        Your ability to risk is the more important factor verse your willingness.
        Drag the circle above to the location that best describes your ability and willingness to take risk.
      </Text>
      <Link style={styles.takeAssessmentLink} onPress={handlePress}>Not sure? Take assessment</Link>
      <Button
        disabled={!isChanged}
        style={styles.continueBtn}
        onPress={() => handleContinue()}
      >
        Continue

      </Button>
    </ScrollView>
  )
}

export default RiskProfile
