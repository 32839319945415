import { Animated, StyleSheet, useWindowDimensions } from 'react-native'
import { DEFAULT_SPACING, white, gray } from '../../styles'
import ListItemOption from './ListItemOption'
import Carousel from '../Carousel'

const styles = StyleSheet.create({
  planDetailContainer: {
    marginHorizontal: DEFAULT_SPACING,
    paddingTop: 20
  },
  planNameContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  optionsContainer: {
    zIndex: 100,
    paddingVertical: DEFAULT_SPACING,
    height: 300,
    marginTop: 40
  },
  planName: {
    fontSize: 24,
    color: white,
    fontWeight: '600'
  },
  planDate: {
    fontSize: 14,
    color: white
  },
  sublabel: {
    fontSize: 12,
    color: gray
  }
})

export default function PlanSummaryOptions({
  animatedValue,
  loading,
  options
}) {
  const height = 400

  const opacity = animatedValue.interpolate({
    inputRange: [0, height - 350],
    outputRange: [1, 0],
    extrapolate: 'clamp',
    useNativeDriver: true
  })

  const slides = options.map((option, index) => (
    <ListItemOption style={{ zIndex: 12000 }} key={index} {...option} />
  ))

  return (
    <Animated.View
      style={[styles.optionsContainer, { height: 100, zIndex: 100 }]}
    >
      <Animated.View style={{ opacity }}>
        <Carousel height={160} disablePagination={loading} data={slides} />
      </Animated.View>
    </Animated.View>
  )
}
