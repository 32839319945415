import { useLayoutEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { FlatList, ScrollView } from 'react-native-gesture-handler'
import { groupBy, forEach } from 'lodash'
import {
  proBudgetSelector,
  transactionsByMonthAndYearSelector,
  budgetCategoriesSelector,
  financialProjectionsSelector
} from './selectors'
import HeaderLabel from './components/HeaderLabel'
import Divider from './components/Divider'
import {
  DEFAULT_SPACING,
  gunmetal,
  info,
  lightestgray,
  lightgray,
  orange,
  rgba,
  secondary,
  white
} from './styles'
import Card from './components/Card'
import DataDonut from './components/DataDonut'
import financialProjectionUtils from './functions/financialProjectionUtils'
import { getProBudgetTotal } from './functions/getMonthlyProBudgetTotal'
import getProBudgetStats from './functions/getProBudgetStats'
import Link from './components/Link'
import { isEmpty, reduce, find } from 'lodash'
import addColorAndIconToTransactions from 'functions/addColorAndIconToTransactions'
import ThinBudgetBreakdownCard from './components/budgeting/ThinBudgetBreakdownCard'
import RecentTransactionsCard from './components/budgeting/RecentTransactionsCard'
import MonthCongradulations from './components/proPlanSummary/MonthCongradulations'
import moment from 'moment'
import CircleIcon from './components/CircleIcon'
import TouchableOpacity from './components/TouchableOpacity'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import AddBudgetItemSlideupDrawer from './components/AddBudgetItemSlideupDrawer'
import formatCurrency from './functions/formatCurrency'
import getProjectedBudgetItemsByCategory from 'functions/getProjectedBudgetItemsByCategory'

const styles = StyleSheet.create({
  noProjectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: orange,
    padding: DEFAULT_SPACING
  },
  noProjectionsText: {
    color: white,
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: DEFAULT_SPACING
  },
  budgetItemHeader: {
    fontSize: 14,
    fontWeight: '600'
  },
  targetBudget: {
    color: gunmetal,
    fontSize: 14,
    marginBottom: DEFAULT_SPACING * 2,
    marginRight: 30
  },
  thisMonth: {
    color: secondary,
    fontSize: 10
  }
})

export default function BudgetCategoryDetailsScreen({ navigation, route }) {
  /* Fetch budget items and categories */
  const { params } = route
  const { budgetCategoryKey, selectedDate } = params
  const [visible, setVisible] = useState(false)

  const budget = useSelector(proBudgetSelector)
  const transactionsByMonthAndYear = useSelector(
    transactionsByMonthAndYearSelector
  )

  /* State variables based on date */
  const projections = useSelector(financialProjectionsSelector)
  const { findAtDate: findProjection } = financialProjectionUtils(projections)

  function onEditPress(subcategory, item) {
    console.log(item, ' ------ item')

    navigation.navigate('Modal', {
      screen: 'Update Budget',
      params: {
        title: subcategory.label,
        budgetSubCategory: subcategory.key,
        budgetCategory: budgetCategoryKey,
        budgetItem: {
          ...item,
          amount: item.unprojectedAmount
        }
      }
    })
  }

  function addNewItem(subcategory) {
    navigation.navigate('Modal', {
      screen: 'Update Budget',
      params: {
        title: subcategory.label,
        budgetSubCategory: subcategory.key,
        budgetCategory: budgetCategoryKey
      }
    })
  }

  function onAddBudgetItemPress() {
    setVisible(true)
  }

  const getProjectedBudgetItemsBySubCategory = (projection) => {
    const newBudgetItemsBySubCategory = {}
    const budgetSubCategories = Object.values(budget.budgetItemsByCategory)
      .map((category) => category.subCategories)
      .flat()
    const budgetItems = Object.values(budget.budgetItemsByHash)

    forEach(budgetSubCategories, (budgetSubCategory) => {
      const projectedItems = reduce(
        budgetItems,
        (filteredItems, budgetItem) => {
          const projectedBudgetItem = find(
            projection.budgetItems,
            (projectedItem) =>
              projectedItem.budgetItemId === budgetItem.budgetItemId &&
              budgetItem.budgetSubCategory === budgetSubCategory.key
          )

          if (projectedBudgetItem) {
            filteredItems.push({
              ...budgetItem,
              amount: projectedBudgetItem.amount,
              unprojectedAmount: budgetItem.amount
            })
          }

          return filteredItems
        },
        []
      )

      newBudgetItemsBySubCategory[budgetSubCategory.key] = {
        ...budgetSubCategory,
        items: projectedItems,
        count: projectedItems.length,
        total: getProBudgetTotal(projectedItems)
      }
    })

    return newBudgetItemsBySubCategory
  }

  const getProjectedBudget = (projection) => {
    if (isEmpty(projection)) {
      return {
        budgetItemsByCategory: {},
        total: 0,
        count: 0
      }
    }

    const projectedBudgetItemsByCategory = getProjectedBudgetItemsByCategory(
      projection,
      budget
    )
    const projectedBudgetItemsBySubCategory =
      getProjectedBudgetItemsBySubCategory(projection)

    return {
      budgetItemsBySubCategory: projectedBudgetItemsBySubCategory,
      budgetItemsByCategory: projectedBudgetItemsByCategory,
      ...getProBudgetStats(projectedBudgetItemsByCategory)
    }
  }

  const currentProjection = getProjectedBudget(findProjection(selectedDate))

  const budgetCategories = useSelector(budgetCategoriesSelector)

  const sumTransactions = (key, transactions) => {
    let total = 0

    if (key) {
      forEach(transactions, (transaction) => {
        if (
          transaction.budgetSubCategory == key ||
          transaction.budgetCategory == key
        ) {
          total += transaction.amount
        }
      })
    } else {
      forEach(transactions, (transaction) => {
        total += transaction.amount
      })
    }

    return total
  }

  const monthYear = selectedDate.format('YYYY-MM')
  const transactions = transactionsByMonthAndYear[monthYear] || []
  const transactionsForBudgetCategory = transactions.filter((transaction) => {
    return transaction.budgetCategory === budgetCategoryKey
  })
  const budgetCategory = budgetCategories.find(
    (category) => category.key == budgetCategoryKey
  )
  const budgetSubCategories = budgetCategory.subCategories

  // group by subcategory
  const budgetItemsBySubCategory =
    groupBy(budgetCategory.subCategories, (item) => item.budgetSubCategory) ||
    {}

  budgetSubCategories.forEach((subCategory) => {
    subCategory.items = budgetItemsBySubCategory[subCategory.key] || []
  })

  const actualAmount = sumTransactions(budgetCategoryKey, transactions)
  const { total } =
    currentProjection.budgetItemsByCategory[budgetCategoryKey] || {}
  const isIncome =
    budgetCategory.key === 'INCOME' ||
    budgetCategory.key == 'SAVE_FIRST_CONTRIBUTION_TARGET'

  /* Render BudgetItem in Flatlist */

  const renderBudgetSubCategoryData = ({ item: budgetSubCategory }) => {
    const actualTotal = sumTransactions(budgetSubCategory.key, transactions)
    const transactionsForBudgetSubCategory = transactions.filter(
      (transaction) => {
        return transaction.budgetSubCategory === budgetSubCategory.key
      }
    )

    const name = budgetSubCategory.label
    const { total } =
      currentProjection.budgetItemsBySubCategory[budgetSubCategory.key]

    const coloredTransactions = addColorAndIconToTransactions(
      budgetCategories,
      transactionsForBudgetSubCategory
    )

    const budgetItems =
      currentProjection.budgetItemsBySubCategory[budgetSubCategory.key].items

    return (
      <ThinBudgetBreakdownCard
        title={name && name.length > 41 ? name.substring(0, 41) + '...' : name}
        actualAmount={actualTotal}
        budgetAmount={total}
        transactions={coloredTransactions}
        onAddPress={() => {
          addNewItem(budgetSubCategory)
        }}
        count={budgetItems.length}
        budgetCategory={budgetCategory}
        budgetSubCategory={budgetSubCategory}
        navigation={navigation}
        isIncome={isIncome}
        onPress={() => {
          navigation.navigate('Modal', {
            screen: 'Budget Subcategory Details',
            params: {
              budgetSubCategoryKey: budgetSubCategory.key,
              transactions,
              selectedDate
            }
          })
        }}
      >
        {budgetItems.length > 0 &&
          budgetItems.map((item) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginTop: DEFAULT_SPACING * 2,
                  paddingHorizontal: DEFAULT_SPACING
                }}
              >
                <View>
                  <Text style={styles.budgetItemHeader}>{item.name}</Text>
                  <Text style={styles.targetBudget}>
                    {formatCurrency(item.amount, {
                      accurate: true,
                      showDollarSign: true
                    })}{' '}
                    this month
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row'
                    }}
                    onPress={() => {
                      onEditPress(budgetSubCategory, item)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={['far', 'pencil']}
                      color={info}
                      size={16}
                    />
                    <Text
                      style={{ color: info, marginLeft: DEFAULT_SPACING / 2 }}
                    >
                      Edit
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            )
          })}
      </ThinBudgetBreakdownCard>
    )
  }

  useLayoutEffect(() => {
    navigation.setOptions({ headerTitle: budgetCategory.label })
  }, [navigation])

  const colors = [
    '#57B575',
    '#F7CB64',
    '#5F6A4A',
    '#755DDB',
    '#4F8FEF',
    '#EB5168',
    '#ED7157',
    '#6D7A7D',
    '#D453A9',
    '#2C3A48',
    '#24525C',
    '#67D0FA',
    '#8BD016'
  ]

  const data = budgetSubCategories.map((subCategory, index) => ({
    label: subCategory.label,
    val: sumTransactions(subCategory.key, transactions),
    color: colors[index % colors.length],
    count: currentProjection.budgetItemsBySubCategory[subCategory.key].count,
    key: subCategory.key
  }))

  // update header with edit button
  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Link
          onPress={() => {
            navigation.navigate('Modal', {
              screen: 'Budget Items List',
              params: {
                title: budgetCategory.label,
                color: budgetCategory.color,
                icon: budgetCategory.icon,
                key: budgetCategory.key
              }
            })
          }}
          style={{ marginRight: DEFAULT_SPACING }}
        >
          Edit
        </Link>
      )
    })
  }, [navigation])

  const budgetSubCategoriesWithItems = data.filter(
    (subCategory) => subCategory.count > 0
  )
  const budgetSubCategoriesWithoutItems = data.filter(
    (subCategory) => subCategory.count === 0
  )

  const [withoutItemsVisible, setWithoutItemsVisible] = useState(false)

  return (
    <View style={styles.container}>
      <ScrollView
        style={{ backgroundColor: lightestgray }}
        contentContainerStyle={{ paddingBottom: 200 }}
      >
        {budgetSubCategoriesWithItems.length > 0 ? (
          <Card
            style={{
              paddingHorizontal: DEFAULT_SPACING,
              paddingTop: DEFAULT_SPACING,
              paddingBottom: DEFAULT_SPACING * 2,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: lightgray,
              margin: DEFAULT_SPACING
            }}
          >
            <MonthCongradulations
              remainingAmount={total - actualAmount}
              actual={actualAmount}
              totalExpenses={total}
              header={`${moment().format('MMMM')} Budget`}
              isIncome={budgetCategory.key === 'INCOME'}
              isSavings={
                budgetCategory.key === 'SAVE_FIRST_CONTRIBUTION_TARGET'
              }
            />

            <Divider />

            <FlatList
              data={budgetSubCategoriesWithItems}
              renderItem={renderBudgetSubCategoryData}
            />

            {budgetSubCategoriesWithoutItems.length > 0 && (
              <Link
                containerStyle={{
                  justifyContent: 'flex-start',
                  marginHorizontal: DEFAULT_SPACING
                }}
                icon={!withoutItemsVisible ? 'caret-up' : 'caret-down'}
                type="fas"
                iconColor={info}
                iconRight={true}
                iconSize={12}
                onPress={() => setWithoutItemsVisible(!withoutItemsVisible)}
              >
                {withoutItemsVisible ? 'Hide Categories' : 'Show Categories'}
              </Link>
            )}

            {withoutItemsVisible && (
              <FlatList
                data={budgetSubCategoriesWithoutItems}
                renderItem={renderBudgetSubCategoryData}
              />
            )}
          </Card>
        ) : (
          <Card
            style={{
              paddingHorizontal: DEFAULT_SPACING,
              paddingTop: DEFAULT_SPACING,
              paddingBottom: DEFAULT_SPACING * 2,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: lightgray,
              margin: DEFAULT_SPACING
            }}
          >
            <HeaderLabel style={{ marginBottom: DEFAULT_SPACING }}>
              {moment().format('MMMM')} Budget
            </HeaderLabel>

            <View
              style={{
                alignItems: 'center'
              }}
            >
              <CircleIcon
                icon={budgetCategory.icon}
                color={budgetCategory.color}
                backgroundColor={rgba(budgetCategory.color, 0.1)}
                size={40}
              />

              <HeaderLabel style={{ margin: DEFAULT_SPACING }}>
                {budgetCategory.label}
              </HeaderLabel>

              <Text style={{ fontSize: 14, color: gunmetal }}>
                You haven't added any budget items yet.
              </Text>

              <Link
                onPress={onAddBudgetItemPress}
                style={{ marginTop: DEFAULT_SPACING }}
              >
                + Add Item
              </Link>
            </View>
          </Card>
        )}

        {actualAmount > 0 && (
          <Card
            style={{
              padding: DEFAULT_SPACING,
              margin: DEFAULT_SPACING
            }}
          >
            <HeaderLabel style={styles.header}>Breakdown</HeaderLabel>
            <Text>Amount Spent</Text>
            <DataDonut chartData={data} />
          </Card>
        )}

        <RecentTransactionsCard
          transactions={transactionsForBudgetCategory}
          budgetCategories={budgetCategories}
          navigation={navigation}
          budgetCategory={budgetCategory}
        />

        <AddBudgetItemSlideupDrawer
          subCategories={budgetSubCategories}
          visible={visible}
          setVisible={setVisible}
          addNewItem={addNewItem}
        />
      </ScrollView>
    </View>
  )
}

BudgetCategoryDetailsScreen.propTypes = {}
