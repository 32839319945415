import { useState } from 'react'
import { Text, TextInput, View } from 'react-native'

function InputOption({ max, value, onChangeText }) {
  const [isError, setIsError] = useState(false)

  const handleChange = (val) => {
    if (val <= max) {
      onChangeText(val)
    }
    setIsError(val > max)
  }

  return (
    <View>
      <TextInput
        value={value}
        placeholder={`Min:0 - Max:${max}`}
        placeholderTextColor="#5559"
        onChangeText={handleChange}
        keyboardType="numeric"
        style={{ borderWidth: 1, paddingHorizontal: 8, paddingVertical: 4, borderRadius: 4, borderColor: '#b8bbbb' }}
      />
      {isError && (
        <Text style={{ color: 'red', marginTop: 4 }}>
          {`Max: ${max}`}
        </Text>
      )}
    </View>
  )
}

export default InputOption
