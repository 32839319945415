import { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { FontAwesome5 } from '@expo/vector-icons'
import { isUndefined } from 'lodash'
import CircleIcon from './CircleIcon'
import TooltippedText from './TooltippedText'
import { DEFAULT_SPACING, gunmetal, gray, rgba, lightestgray, grayblue } from '../styles'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  container: {
    borderBottomColor: lightestgray,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 14
  },
  iconAndLabelContainer: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  },
  leftLabel: {
    fontSize: 14,
    fontWeight: '600',
    color: grayblue
  },
  rightLabel: {
    letterSpacing: -0.6,
    fontSize: 14,
    flex: 1,
    textAlign: 'right',
    fontWeight: '400'
  },
  icon: { marginRight: DEFAULT_SPACING },
  pill: {
    backgroundColor: lightestgray,
    borderRadius: 14,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginLeft: DEFAULT_SPACING
  },
  pillText: {
    fontSize: 10,
    color: gray
  }
})

export default function IconDataRow({
  label,
  tooltipBody,
  route,
  params,
  title,
  subtitle,
  editable,
  value,
  color,
  iconStyle,
  containerStyle,
  rightLabelStyle,
  leftLabelStyle,
  backgroundColor,
  divisor,
  duration,
  count
}) {
  const { Icon, icon, type } = iconStyle || {}
  const iconBackgroundColor = (iconStyle || {}).backgroundColor
  const iconColor = (iconStyle || {}).color
  const navigation = useNavigation()
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)

  return (
    <TouchableOpacity disabled={!route} style={[styles.container, containerStyle, { backgroundColor: backgroundColor || 'transparent', borderBottomWidth: backgroundColor ? 0 : 1, borderRadius: backgroundColor ? 10 : 0 }]} onPress={() => navigation.navigate('Modal', { screen: route, params: { ...{ title, subtitle, editable, divisor, duration, ...params } } })}>
      <View style={styles.iconAndLabelContainer}>
        {(Icon || icon) && (
          <CircleIcon
            Icon={Icon}
            icon={icon}
            type={type}
            style={[{ backgroundColor: iconBackgroundColor }, styles.icon]}
            color={iconColor}
            size={14}
          />
        )}

        { !tooltipBody && (
          <Text style={[styles.leftLabel, { color: color || gunmetal }, leftLabelStyle]}>
            {label}
          </Text>
        )}

        { tooltipBody
          && (
            <TooltippedText
              visible={tooltipIsVisible}
              setVisible={setTooltipIsVisible}
              anchorStyle={[styles.leftLabel, { color: color || gunmetal }]}
              anchorText={label}
              contentHeader={label}
              contentBody={tooltipBody}
            />
          )}

        { !isUndefined(count) && (
          <View style={styles.pill}>
            <Text style={styles.pillText}>{count}</Text>
          </View>
        )}
      </View>

      <Text style={[styles.rightLabel, { color: color || gunmetal }, rightLabelStyle]}>
        {value}
      </Text>

      <FontAwesome5 style={{ marginLeft: 12 }} name="chevron-right" size={18} color={route ? rgba(gray, 0.55) : 'transparent'} />
    </TouchableOpacity>
  )
}
