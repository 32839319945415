import { View, StyleSheet } from 'react-native'
import { DEFAULT_SPACING } from '../styles'
import TouchableOpacity from './TouchableOpacity'

const listItemStyle = StyleSheet.create({
  container: {
    borderRadius: 13,
    borderWidth: 1,
    borderColor: 'rgba(70, 90, 106, 0.12)',
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'row',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING
  }
})

export default function ListItem({ children, style, onLongPress, onPress }) {
  const Container = onPress ? TouchableOpacity : View

  return (
    <Container onLongPress={onLongPress} style={[listItemStyle.container, style]}>
      {children}
    </Container>
  )
}
