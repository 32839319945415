import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { currentProPlanSelector } from './selectors'

import ProAdequacyScoreScreen from './ProAdequacyScoreScreen'
import FreeAdequacyScoreScreen from './FreeAdequacyScoreScreen'

export default function AdequacyScoreScreen({ navigation, route }) {
  const currentProPlan = useSelector(currentProPlanSelector)
  const isPro = !isEmpty(currentProPlan)

  return isPro ? (
    <ProAdequacyScoreScreen navigation={navigation} route={route} />
  ) : (
    <FreeAdequacyScoreScreen navigation={navigation} route={route} />
  )
}
