import { useEffect, useRef } from 'react'
import { Easing } from 'react-native'

import { AnimatedCircularProgress } from 'react-native-circular-progress'

import PropTypes from 'prop-types'
import { red } from '../styles'

const BASE_TINT_COLOR = 'rgb(79, 214, 89)'

export default function CircularProgress({
  progress,
  style,
  size,
  width,
  fillFunction,
  tintColor,
  backgroundColor
}) {
  let circularProgress = useRef(null)

  const onRef = (ref) => { circularProgress = ref }

  useEffect(() => {
    if (circularProgress && progress) {
      circularProgress.reAnimate(0, progress, 500, Easing.quad)
    }
  }, [progress])

  return (
    <AnimatedCircularProgress
      style={style}
      ref={onRef}
      size={size}
      width={width}
      prefill={progress}
      fill={progress}
      rotation={0}
      lineCap="round"
      tintColor={tintColor || (progress >= 30 ? BASE_TINT_COLOR : red)}
      backgroundColor={backgroundColor}
    >
      {
        (fill) => (
          fillFunction(fill)
        )
      }
    </AnimatedCircularProgress>
  )
}

CircularProgress.defaultProps = {
  style: {},
  size: 136,
  width: 12,
  backgroundColor: 'rgba(48, 92, 105, 0.1)',
  tintColor: BASE_TINT_COLOR
}

CircularProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  style: PropTypes.object,
  size: PropTypes.number,
  width: PropTypes.number,
  fillFunction: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  tintColor: PropTypes.string
}
