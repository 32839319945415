import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Text, StyleSheet } from 'react-native'
import TouchableOpacity from './TouchableOpacity'

import { primary } from '../styles/colors'

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    flexDirection: 'row'
  },
  icon: {
    position: 'absolute',
    left: 0,
    top: 12
  },
  text: {
    flex: 2,
    fontSize: 16,
    color: 'rgb(66, 86, 103)',
    paddingVertical: 10,
  }
})

export default function SearchListItem({ checked = false, onPress, leftLabel }) {
  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      {checked && <FontAwesomeIcon icon="check" color={primary} size={14} style={styles.icon} />}
      <Text style={[styles.text, { marginLeft: checked ? 24 : 0 }]}>
        {leftLabel}
      </Text>
    </TouchableOpacity>
  )
}
