import { get } from 'http'
import { setOccupations } from './setOccupations'
import { setLoading } from './setLoading'

export const getOccupations = function (id) {
  return async (dispatch) => {
    try {
      // a location is needed
      if (id) {
        let result = null

        dispatch(setLoading({ loading: true }))

        result = await get(`location/${id}/occupations/`)
        dispatch(setOccupations(result.data, id));
        dispatch(setLoading({ loading: false }))
      }
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
