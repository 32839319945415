import { useLayoutEffect } from 'react'
import { View, Text, Image, ScrollView, StyleSheet, ImageBackground, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import * as WebBrowser from 'expo-web-browser'
import hapticFeedback from './components/hapticFeedback'
import TouchableOpacity from './components/TouchableOpacity'

import { DEFAULT_SPACING, white } from './styles'
import PageHeader from './components/PageHeader'

const basicStyles = {
  container: {
    position: 'relative',
    flex: 1
  },
  headerImageContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerImage: {
    height: 40,
    width: 180,
  },
  listWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  foolproofItem: {
    height: 200,
    borderRadius: 12,
    width: '46%',
    marginHorizontal: '2%',
    marginVertical: DEFAULT_SPACING,
    overflow: 'hidden'
  },
  foolproofImage: {
    height: 200,
    borderRadius: 12,
  },
  foolproofTitle: {
    fontWeight: '600',
    fontSize: 14,
    letterSpacing: 0.9,
    color: white,
    width: '75%',
    alignSelf: 'center',
    textAlign: 'center'
  },
  foolproofIcon: {
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: 50,
    marginBottom: DEFAULT_SPACING
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: { padding: 24, },
      headerImage: {
        height: 60,
        width: 240,
      },
      listWrapper: { marginHorizontal: -10, },
      foolproofItem: {
        width: 'calc(50% - 20px)',
        marginHorizontal: 10,
      },
      foolproofImage: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingHorizontal: 32,
      },
      foolproofIcon: {
        marginTop: 0,
        marginBottom: 0,
        marginRight: 24,
      },
      foolproofTitle: {
        fontSize: 24,
        width: 'fit-content',
        textAlign: 'left',
      },
    },
    [minSize(DEVICE_SIZES.LG)]: {
      container: { padding: 40, },
      headerImage: {
        height: 80,
        width: 360,
      },
      foolproofImage: { paddingHorizontal: 40, },
      foolproofIcon: { marginRight: 32, },
      foolproofTitle: { fontSize: 28, },
    },
  }
)

export default function FoolProofScreen({ route, navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const title = <View style={styles.headerImageContainer}><Image style={styles.headerImage} resizeMode="contain" source={require('./assets/foolproof/foolproof-logo.png')} /></View>
  const subTitle = 'Choose a topic you would like to learn more about with FoolProof!'

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: title,
      headerShown: isNotWeb
    })
  }, [navigation])

  const foolproofItems = [{
    title: 'Basic Budgeting',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/basic-budgeting.png'),
    link: 'https://www.foolproofme.org/solo/basic-budgeting'
  }, {
    title: 'Advanced Budgeting',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/advanced-budgeting.png'),
    link: 'https://www.foolproofme.org/solo/advanced-budgeting'
  }, {
    title: 'Debt Management',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/debt-management.png'),
    link: 'https://www.foolproofme.org/solo/debt-management'
  }, {
    title: 'Gambling',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/gambling.png'),
    link: 'https://www.foolproofme.org/solo/gambling'
  }, {
    title: 'Impulse Buying',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/impulse-buying.png'),
    link: 'https://www.foolproofme.org/solo/impulse-buying'
  }, {
    title: 'Value of Education',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/value-education.png'),
    link: 'https://www.foolproofme.org/solo/value-of-education'
  }, {
    title: 'Money and You',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/money-and-you.png'),
    link: 'https://www.foolproofme.org/solo/money-you'
  }, {
    title: 'Healthy Skeptic',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/healthy-skeptic.png'),
    link: 'https://www.foolproofme.org/solo/healthy-skeptic'
  }, {
    title: 'Cryptocurrencies',
    icon: 'dollar-sign',
    image: require('./assets/foolproof/cryptocurrencies.png'),
    link: 'https://www.foolproofme.org/solo/cryptocurrency'

  }]

  function handlePress(item) {
    hapticFeedback()
    WebBrowser.openBrowserAsync(item.link);
  }

  return (
    <View style={styles.container}>
      {!isNotWeb && (<PageHeader title={title} subTitle={subTitle} />)}
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.listWrapper}>
          {foolproofItems.map((item, index) => (
            <TouchableOpacity action={`FoolProof ${item.title}`} onPress={() => handlePress(item)} style={styles.foolproofItem}>
              <ImageBackground style={styles.foolproofImage} resizeMode="cover" source={item.image}>
                <FontAwesomeIcon style={styles.foolproofIcon} icon={item.icon} color="#86BF4D" size={isNotWeb ? 38 : 96} />
                <Text style={styles.foolproofTitle}>{item.title}</Text>
              </ImageBackground>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}
