import { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { View, Platform, StyleSheet, Image as RnImage } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import * as ImagePicker from 'expo-image-picker'

import { Image } from 'react-native-expo-image-cache'
import { patch, get } from 'http'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { first } from 'lodash'
import ImageSlideupDrawer from './ImageSlideupDrawer'
import uploadFile from '../../functions/uploadFile'
import { setCurrentUser, setLoading } from '../../actions'
import hapticFeedback from '../hapticFeedback'
import TouchableOpacity from '../TouchableOpacity'
import { white } from '../../styles/colors'

const basicStyles = {
  container: {
    position: 'relative',
    zIndex: 0
  },
  cameraContainer: {
    justifyContent: 'center',
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    top: 50,
    right: -5,
    zIndex: 100,
    justifyContent: 'center',
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    top: 50,
    right: -5,
    zIndex: 100,
    elevation: 100,
    position: 'absolute'
  },
  cameraIcon: {
    alignSelf: 'center',
    zIndex: 10000
  },
  profilePictureContainer: {
    shadowColor: 'rgba(0, 0, 0, 0.24)',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 11,
    shadowOpacity: 1,
    zIndex: 10,
    elevation: 10,
    borderWidth: 3,
    borderColor: white,
    overflow: 'hidden',
    borderRadius: 75 / 2,
    height: 75,
    width: 75,
    backgroundColor: white
  },
  profilePicture: {
    alignSelf: 'center',
    height: 111,
    alignSelf: 'center',
    height: 111,
    width: 111
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      profilePictureContainer: {
        width: 154,
        height: 154,
        borderRadius: 77,
        borderWidth: 0,
      },
      profilePicture: {
        height: 154,
        width: 154
      },
      cameraContainer: {
        top: 116,
        right: 0,
        width: 38,
        height: 38,
        borderRadius: 19,
      },
    },
  }
)

function askForImagePermission() {
  (async () => {
    if (Platform.OS !== 'web') {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!')
      }

      return await ImagePicker.getCameraPermissionsAsync()
    }
  })()
}

function arePropsEqual(prevProps, nextProps) {
  // We are caching this component because if we do not, the image will reload
  // every time the screen is loaded. This is because of the security parameters
  // that are on the url from AWS. If the base url doesn't change, we don't need to reload
  // the component and thus also reload the image.

  const nextCurrentUser = nextProps.currentUser
  const prevCurrentUser = prevProps.currentUser

  let nextUrl = ''
  let prevUrl = ''

  if (nextCurrentUser.profilePicture !== undefined) {
    nextUrl = nextCurrentUser.profile_picture?.url || ''
  }
  if (prevCurrentUser.profilePicture !== undefined) {
    prevUrl = nextCurrentUser.profile_picture?.url || ''
  }

  const splitNextUrl = first(nextUrl.split('?'))
  const splitPrevUrl = first(prevUrl.split('?'))

  return splitNextUrl === splitPrevUrl
}

function ProfilePicture({ currentUser }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const { profile_picture } = currentUser
  const { url } = profile_picture
  const patchUrl = `profiles/${currentUser.profile_id}/`
  const [visible, setVisible] = useState(false)

  function handlePress() {
    hapticFeedback()

    if (url) {
      setVisible(true)
    } else {
      askForImagePermissionAndTriggerUploadOf('profile_picture', false, patchUrl, dispatch)
    }
  }

  const actions = [
    {
      label: 'Edit Image',
      icon: 'image',
      type: 'fas',
      onPress: () => {
        askForImagePermissionAndTriggerUploadOf('profile_picture', false, patchUrl, dispatch)
      }
    }, {
      label: 'Remove Image',
      icon: 'trash',
      type: 'fas',
      danger: true,
      onPress: async () => {
        await patch(patchUrl, { profile_picture: null })

        const response = await get('me/')
        const user = response.data

        await dispatch(setCurrentUser(user))

        setVisible(false)
      }
    }
  ]

  async function askForImagePermissionAndTriggerUploadOf(type, allowsEdit, url, dispatch) {
    askForImagePermission()

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: allowsEdit,
      quality: 0.8
    })

    if (!result.cancelled) {
      // make sure slider is closed if a slider is up
      setVisible(false)

      dispatch(setLoading({ loading: true, fullScreen: true, loadingMessage: 'Uploading Image' }))

      await uploadFile(url, type, result)

      const response = await get('me/')
      const user = response.data

      await dispatch(setCurrentUser(user))

      dispatch(setLoading({ loading: false, loadingMessage: null }))
    }
  }

  const defaultPicture = require('../../assets/user/default-user.png')

  return (
    <>
      <View style={[styles.container]}>
        <TouchableOpacity style={styles.cameraContainer} onPress={handlePress}>
          <FontAwesomeIcon icon={['fas', 'camera']} style={styles.cameraIcon} fill="#283A49" size={12} />
        </TouchableOpacity>
        <TouchableOpacity onPress={handlePress} style={styles.profilePictureContainer}>
          <>
            {url
              && (
                <Image
                  transitionDuration={500}
                  key={url}
                  style={styles.profilePicture}
                  resizeMode="cover"
                  {...{ preview: { uri: url }, uri: url }}
                />
              )}
            {!url
              && (
                <RnImage
                  transitionDuration={500}
                  key={url}
                  style={styles.profilePicture}
                  resizeMode="contain"
                  source={defaultPicture}
                />
              )}
          </>
        </TouchableOpacity>
      </View>
      <ImageSlideupDrawer
        title="Profile"
        visible={visible}
        setVisible={setVisible}
        actions={actions}
      />
    </>
  )
}

export default memo(ProfilePicture, arePropsEqual)
