import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import SlideupDrawer from 'components/SlideupDrawer'
import PulseCircle from 'components/PulseCircle'
import { info, darkblue, gunmetal, white } from 'styles'
import { currentUserSelector } from 'selectors'
import Button from 'components/Button'
import ListOptionItem from 'components/ListOptionItem'
import HeaderLabel from 'components/HeaderLabel'
import formatCurrency from 'functions/formatCurrency'
import moment from 'moment'
import { DEFAULT_SPACING, primary } from '../../styles'
import Link from '../Link'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 220,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 0,
    paddingHorizontal: 20
  },
  image: {
    width: 110,
    height: 165,
    marginBottom: 20,
    alignSelf: 'center'
  },
  header: {
    fontWeight: '600',
    fontSize: 24,
    color: darkblue,
    textAlign: 'center',
    marginTop: DEFAULT_SPACING * 2,
    marginBottom: DEFAULT_SPACING
  },
  text: ({ additionalBottomMargin=false }) => ({
    fontSize: '500',
    fontSize: 16,
    color: gunmetal,
    textAlign: 'center',
    marginBottom: additionalBottomMargin ? DEFAULT_SPACING * 6 : DEFAULT_SPACING,
    letterSpacing: -0.66
  }),
  smallText: {
    fontSize: 14,
    letterSpacing: -0.583
  },
  button: { marginTop: 20 },
  transactionsContainer: {
    width: '100%',
  },
  listOptionItemContainer: {
    borderWidth: 0, 
    borderBottomWidth: 1
  },
  listOptionItemRightContainer: {
    alignItems: 'flex-end'
  },
  listOptionItemAmount: {
    color: primary,
    fontSize: 14
  },
  listOptionItemDate: {
    color: gunmetal,
    fontSize: 12
  }
})

function DrawerWithTransactions({ transactions }) {
  return (
    <>
      <Text style={styles.text({})}>
        We see you have contributed to your 
        Save First Contribution budget category — way to go!          
      </Text>

      <Text style={[styles.text({}), styles.smallText]}>
        Select any transaction you would like to 
        link towards progress with your goal.          
      </Text>        

      <View style={styles.transactionsContainer}>
        {transactions.map((transaction, index) => (
          <ListOptionItem 
            key={index}
            label={transaction.description}
            sublabel={'Save First Contribution'}
            requireCheckForChildren={false}
            containerStyle={styles.listOptionItemContainer}
          >
            <View style={styles.listOptionItemRightContainer}>
              <Text style={styles.listOptionItemAmount}>{formatCurrency(transaction.amount, { accurate: true, showDollarSign: true })}</Text>
              <Text style={styles.listOptionItemDate}>{moment().format('MMM DD, YYYY')}</Text>
            </View>
          </ListOptionItem>
        ))}   
      </View>  

      <Button style={styles.button} onPress={() => setVisible(false)}>
        Okay, got it!
      </Button>      
    </>
  )
}

function DrawerWithoutTransactions({ handleCancelPress, handleConfirmPress }) {
  return (
    <>
      <Text style={[styles.text({ additionalBottomMargin: DEFAULT_SPACING })]}>
        We don’t see any transactions for your Save First Contribution. 
        Would you like to add a transaction for a Roth IRA, 401(k) or other retirement 
        plan type this month?
      </Text>

      <Button onPress={handleConfirmPress}>
        Add Transaction
      </Button>

      <Button 
        color={info} 
        inverted 
        withBorder={false} 
        onPress={handleCancelPress}
      >
        No Thanks
      </Button>
    </>
  )
}

export default function FinPulseSlideupDrawer({ visible, setVisible, navigation }) {
  const currentUser = useSelector(currentUserSelector)
  const transactions = [{
    transactionId: '1234',
    amount: 100,
    description: 'Retirement',
    date: '2023-04-11'
  }, {
    transactionId: '123',
    amount: 1000,
    description: 'Retirement',
    date: '2023-04-11'
  }]

  // const transactions = []

  return (
    <SlideupDrawer closeEnabled={true} snapPointIndex={2} visible={visible} setVisible={setVisible}>
      <View style={styles.container}>
        <PulseCircle 
          size={80}
          scaleEnd={1.3}
          color={info}
          iconSize={36}
          iconColor={white}
          icon={'wave-pulse'}
          style={styles.pulseCircle} 
        />

        <HeaderLabel style={styles.header}>
          Hey {currentUser.first_name}!
        </HeaderLabel>

        {transactions.length > 0 ? (
          <DrawerWithTransactions transactions={transactions} />
        ) : (
          <DrawerWithoutTransactions
            handleCancelPress={() => setVisible(false)}
            handleConfirmPress={() => {
              setVisible(false)
              navigation.navigate('Modal', { screen: 'Transaction Form' })
            }}
          />
        )}

      </View>
    </SlideupDrawer>
  )
}
