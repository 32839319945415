import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import SlideupDrawer from '../SlideupDrawer'
import { darkblue, } from '../../styles/colors'
import RadioGroup from '../dashboard/RiskAssessment/RadioGroup'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 40,
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
  },
  body: { marginTop: 31, },
})

export default function FilterOptionsSlideupDrawer(
  { options, visible, setVisible, onPress, selected }
) {
  return (
    <SlideupDrawer
      snapPointIndex={1}
      scrollEnabled={false}
      visible={visible}
      setVisible={setVisible}
      snapPoints={[200, 400,]}
    >
      <View style={styles.container}>
        <TouchableOpacity onPress={onPress} style={{ position: 'absolute', right: 20, top: 0 }}>
          <FontAwesomeIcon icon={faCircleXmark} size={24} color="#8894A188" />
        </TouchableOpacity>
        <Text style={styles.header}>Filters</Text>

        <View style={styles.body}>
          <RadioGroup
            options={options}
            value={selected}
            gap={20}
            onPress={onPress}
          />
        </View>
      </View>
    </SlideupDrawer>
  )
}
