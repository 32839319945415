import { Buffer } from 'buffer'
import pako from 'pako'

const Gzip = {
  zip: (data) => Buffer.from(pako.deflate(Buffer.from(data, 'binary')), 'binary').toString('base64'),
  unzip: (base64) => Buffer.from(pako.inflate(new Uint8Array(Buffer.from(base64, 'base64'))), 'binary').toString('binary'),
}

const Base64 = {
  btoa: (data) => Buffer.from(data, 'binary').toString('base64'),
  atob: (data) => Buffer.from(data, 'base64').toString('binary'),
}

export { Gzip, Base64 }
