import { isEmpty } from 'lodash'
import { primaryProPlanSelector } from 'selectors'
import { useSelector } from 'react-redux'

import DebtRankForm from 'components/debtRank/DebtRankForm'
import ProDebtRankForm from 'components/proDebtRank/DebtRankForm'
import { useLayoutEffect } from 'react'

export default function MyDebtFormScreen({ navigation, route }) {
  const primaryProPlan = useSelector(primaryProPlanSelector)
  const { params } = route
  const { accountId } = params || {}

  useLayoutEffect(() => {
    navigation.setOptions({
      title: accountId ? 'Edit Debt' : 'Add New Debt',
    })
  }, [navigation])

  return isEmpty(primaryProPlan) ? 
    <DebtRankForm navigation={navigation} route={route} /> : 
      <ProDebtRankForm navigation={navigation} route={route} />
}
