import {
  Image,
  Platform,
  Text,
  TouchableOpacity,
  StyleSheet
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import FadeInView from '../FadeInView'
import Card from '../Card'
import { DEFAULT_SPACING } from '../../styles'
import hapticFeedback from '../hapticFeedback'

const basicStyles = {
  cardContainer: {
    marginVertical: DEFAULT_SPACING,
    borderRadius: 10
  },
  cardImage: {
    height: 150,
    minHeight: 150,
    flex: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  cardText: {
    fontSize: 16,
    color: 'rgb(66, 86, 103)',
    padding: DEFAULT_SPACING,
    minHeight: 56
  },
  cardWrapper: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    scrollViewContainer: { paddingHorizontal: 40 },
    scrollViewContentContainer: { paddingVertical: 30 },
    listWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 28
    },
    cardWrapper: { width: '33%' },
    cardContainer: { marginVertical: 5 },
    cardImage: {
      height: 230,
      minHeight: 230
    },
    cardText: {
      fontSize: 20,
      fontWeight: '500',
      minHeight: 93
    },
    listHeader: {
      fontSize: 36,
      fontWeight: '600'
    }
  }
})

function MilitaryOccupationalGroupCard({ name, image, onPress }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  const handlePress = () => {
    hapticFeedback()
    onPress()
  }

  return (
    <TouchableOpacity
      action={`Occupation Group: ${name}`}
      onPress={handlePress}
      style={styles.cardWrapper}
    >
      <FadeInView initialValue={0} toValue={1} duration={300}>
        <Card style={styles.cardContainer}>
          <Image source={{ uri: image.url }} style={styles.cardImage} />
          <Text style={styles.cardText}>{name}</Text>
        </Card>
      </FadeInView>
    </TouchableOpacity>
  )
}

export default MilitaryOccupationalGroupCard
