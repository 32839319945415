import { useState } from 'react'
import { Text, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import Breadcrumbs from './Breadcrumbs'
import TooltippedText from './TooltippedText'
import { DEFAULT_SPACING, info, secondary } from '../styles'

const useStyles = CreateResponsiveStyle(
  {
    header: { paddingBottom: 16, },
    title: {
      marginTop: 0,
      fontSize: 36,
      fontWeight: '600',
      color: '#283A49',
    },
    subTitle: {
      marginTop: 8,
      fontSize: 16,
      color: secondary,
    },
    tooltipLink: {
      color: info,
      marginTop: 8,
      fontSize: 12
    }
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      title: { marginTop: 18, },
      subTitle: {
        marginTop: 16,
        fontSize: 16,
        color: secondary,
      },
      tooltipLink: {
        color: info,
        marginTop: DEFAULT_SPACING,
        fontSize: 12
      }
    }
  }
)

function PageHeader({ title, subTitle, tooltip, mode = "light" }) {
  const styles = useStyles()
  const [tooltipVisible, setTooltopVisible] = useState(false)

  return (
    <View style={styles.header}>
      <Breadcrumbs mode={mode} />
      <Text style={styles.title}>{title}</Text>
      {subTitle && (<Text style={styles.subTitle}>{subTitle}</Text>)}
      {tooltip && (
        <TooltippedText
          position="bottom"
          visible={tooltipVisible}
          setVisible={setTooltopVisible}
          anchorStyle={styles.tooltipLink}
          anchorText={tooltip.label}
          contentHeader={tooltip.title}
          contentBody={tooltip.text}
        />
      )}
    </View>
  )
}

export default PageHeader
