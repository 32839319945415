import * as Yup from 'yup'

import {
  INVALID_AMOUNT,
  INVALID_INT,
  INVALID_MIN
} from './constants'

export const Fields = {
  realEstate: 'realestate',
  checking: 'checking',
  savings: 'savings',
  retirement: 'retirement',
  autos: 'autos',
  other: 'other',
  mortgage: 'mortgage',
  consumer: 'consumer',
  personal: 'personal',
  student: 'student',
  autoLoan: 'auto_loan',
  otherDebt: 'other_debt'
}

export const ValidationSchema = Yup.object().shape({
  realestate: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  checking: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  savings: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  retirement: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  autos: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  other: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  mortgage: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  consumer: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  personal: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  student: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  autoLoan: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  otherDebt: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`)

})

export const InitialValues = {
  assets: {
    [Fields.realEstate]: 0,
    [Fields.checking]: 0,
    [Fields.savings]: 0,
    [Fields.retirement]: 0,
    [Fields.autos]: 0,
    [Fields.other]: 0
  },
  liabilities: {
    [Fields.mortgage]: 0,
    [Fields.consumer]: 0,
    [Fields.personal]: 0,
    [Fields.student]: 0,
    [Fields.autoLoan]: 0,
    [Fields.otherDebt]: 0
  }
}
