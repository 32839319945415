import { addBranchEvent } from '../actions'

import getStore from '../getStore'

const troutwoodStore = getStore()

export default function handleParams({ params, error }) {
  const { dispatch } = troutwoodStore

  if (error) { return }

  // params is never null if error is null
  if (!params['+clicked_branch_link'] || params['+non_branch_link']) {
    // this is one of those responses you can ignore
    return
  }

  dispatch(addBranchEvent(params))
}
