import { post } from 'http'

export const autoLoanCalculation = async function (payload) {
  try {
    // allow for charting data
    payload.detail = true

    const result = await post('calculator/loan/', payload)

    if (result) {
      return result.data
    }
    return null
  } catch (e) {
    console.warn(e, e.response)
  }
}
