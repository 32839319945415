import {
  Modal as NativeModal,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0006',
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1000,
    top: 8,
    right: 8
  },
});

function Modal(props) {
  return (
    <NativeModal
      visible={props.visible}
      transparent
      onRequestClose={() => props.onClose()}
    >
      <View style={styles.modalContainer}>
        <View style={props.style}>
          <TouchableOpacity
            style={styles.closeButton}
            onPress={() => props.onClose()}
          >
            <FontAwesomeIcon icon={['far-solid', 'circle-xmark']} color="#66666652" size={24} />
          </TouchableOpacity>
          {props.children}
        </View>
      </View>
    </NativeModal>
  )
}

export default Modal;
