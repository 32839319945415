import { Platform, StyleSheet, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import CollegeHeader from './components/colleges/CollegeHeader'
import AdmissionsCard from './components/colleges/AdmissionsCard'
import DemographicsCard from './components/colleges/DemographicsCard'
import StudentDebtProfileCard from './components/colleges/StudentDebtProfileCard'
import TuitionAndCostCard from './components/colleges/TuitionAndCostCard'
import PriceByFamilyIncomeCard from './components/colleges/PriceByFamilyIncomeCard'
import EndowmentCard from './components/colleges/EndowmentCard'
import HappySearchFinn from './components/colleges/HappySearchFinn'
import { white, lightestgray } from './styles/colors'
import DetailsTabs from './components/colleges/DetailsTabs';

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: white,
  },
  collegeHeaderContainer: {
    height: 240,
    paddingTop: 24,
    borderTopColor: lightestgray,
    borderTopWidth: 1,
  },
  emptyContent: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: {
        flex: 1,
        backgroundColor: white,
        padding: 24,
        borderRadius: 8,
        overflow: 'auto',
      },
      collegeHeaderContainer: {
        height: 'fit-content',
        paddingTop: 0,
        paddingBottom: 0,
        border: 'none'
      },
      emptyContent: { paddingBottom: 32, }
    },
    [minSize(DEVICE_SIZES.LG)]: { container: { padding: 40, }, },
  }
)

function EmptyContent() {
  return (
    <View
      style={{
        paddingBottom: 32,
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <HappySearchFinn
        header="Search for a School/College"
        information={
          "Try typing the school or college you're interested going to."
        }
      />
    </View>
  )
}

export default function CollegeExploreScreen({ navigation, route }) {
  const { params } = route
  if (!params) return <EmptyContent />
  const { college } = params
  if (!college) return <EmptyContent />
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  const options = {
    Admissions: AdmissionsCard,
    Demographics: DemographicsCard,
    'Student Debt Profile': StudentDebtProfileCard,
    'Tuition & Cost': TuitionAndCostCard,
    'Net Price by Family Income': PriceByFamilyIncomeCard,
    Endowment: EndowmentCard
  }

  return (
    <View style={styles.container}>
      <View style={styles.collegeHeaderContainer}>
        <CollegeHeader college={college} />
      </View>
      <DetailsTabs options={options} college={college} />
    </View>
  )
}
