import { useState } from 'react'
import { View, StyleSheet, ScrollView, Text } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { map } from 'lodash'
import SlideupDrawer from '../SlideupDrawer'
import ListOptionItem from '../ListOptionItem'

import Button from '../Button'

import { foldersSelector } from '../../selectors'

import { saveCurrentPlan } from '../../actions'
import { darkblue } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 80
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING
  },
  bottomButtonStyle: {
    justifyContent: 'flex-end',
    padding: DEFAULT_SPACING
  },
})

function BottomButton({ onPress, disabled, children }) {
  return (
    <View style={styles.bottomButtonStyle}>
      <Button onPress={onPress} disabled={disabled}>{children}</Button>
    </View>
  )
}

export default function MoveToFolderSlideupDrawer({ visible, setVisible, plan }) {
  const [selected, setSelected] = useState(null)
  const folders = useSelector((state) => foldersSelector(state))
  const folderOptions = map(folders, (folder) => ({ value: folder.id, label: folder.name }))
  const dispatch = useDispatch()
  const marginTop = 150
  const height = 900

  function handleCheck(value) {
    if (value == selected) {
      setSelected(null)
    } else {
      setSelected(value)
    }
  }

  return (
    <SlideupDrawer snapPointIndex={1} marginTop={marginTop} height={height} visible={visible} setVisible={setVisible}>
      <View>
        <ScrollView style={styles.container}>
          <Text style={styles.header}>Move to Folder</Text>

          {map(folderOptions, (option) => (
            <ListOptionItem
              key={`${option.value}-list-option`}
              onCheck={(value) => handleCheck(value)}
              onPress={(value) => handleCheck(value)}
              checked={selected == option.value}
              {...option}
            />
          ))}
        </ScrollView>
        <BottomButton onPress={() => {
          dispatch(saveCurrentPlan({ folder: selected }))

          setVisible(false)
        }}
        >
          Submit
        </BottomButton>
      </View>
    </SlideupDrawer>
  )
}
