import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { antiFlashWhite, kombuGreen, white, rgba } from 'styles'
import { primary } from '../../styles'

const styles = StyleSheet.create({
  cardContainer: {
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 15,
    flexDirection: 'row',
    backgroundColor: rgba(kombuGreen, 0.5),
    justifyContent: 'space-between',
    marginBottom: 10
  },
  cardMain: { flex: 1 },
  cardHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: white
  },
  cardDescription: {
    marginTop: 8,
    color: white,
    paddingBottom: 15
  },
  navIcon: { marginLeft: 36 },
  estimatedTimeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10
  },
  estimatedTime: {
    color: white,
    fontSize: 12,
    marginLeft: 6,
    marginRight: 12
  },
  iconContainer: {
    alignItems: 'center'
  },
  lineItemContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  }
})

function PlanBuildOptionCard({ title, description, onPress, lineItems, estimatedTime }) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.cardContainer}>
        <View style={styles.cardMain}>
          <View style={styles.cardHeader}>
            <Text style={styles.cardTitle}>{title}</Text>

            <View style={styles.iconContainer}>
              <View style={styles.estimatedTimeContainer}>
                <FontAwesomeIcon icon={'clock'} size={12} color={rgba(antiFlashWhite, 0.75)} />
                <Text style={styles.estimatedTime}>{estimatedTime} min</Text>
                <FontAwesomeIcon
                  icon={faCircleChevronRight}
                  size={12}
                  color={rgba(antiFlashWhite, 0.75)}
                />
              </View>
            </View>
          </View>
          <Text style={styles.cardDescription}>{description}</Text>
          <View>
            {lineItems.map((item, index) => (
              <View style={styles.lineItemContainer} key={index}>
                <FontAwesomeIcon icon={'check'} size={14} color={primary} />
                <Text
                  key={index}
                  style={[styles.cardDescription, { paddingBottom: 0, marginLeft: 6 }]}
                >
                  {item}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

export default PlanBuildOptionCard
