import Button from './Button'
import { darkgrayblue, primary, white } from '../styles/colors'

export default function PillButton(props) {
  const {
    label,
    onPress,
    active,
    inverted,
    style,
    activeBackgroundColor,
    activeColor,
    inactiveBackgroundColor,
    inactiveColor,
    small
  } = props

  const pillStyle = {
    paddingVertical: small ? 2 : 8,
    paddingHorizontal: small ? 20 : 12,
    backgroundColor: active ? (activeBackgroundColor || primary) : (inactiveBackgroundColor || 'rgba(48, 92, 105, 0.19)'),
    borderRadius: 14,
    minHeight: small ? 22 : 30,
  };

  const titleStyle = {
    fontSize: 12,
    color: active ? (activeColor || white) : (inactiveColor || darkgrayblue)
  }

  return (
    <Button
      onPress={() => { if (onPress) onPress() }}
      style={[pillStyle, style]}
      titleStyle={titleStyle}
    >
      {label}
    </Button>
  )
}
