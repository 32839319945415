import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faBellSlash, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import SlideupDrawer from '../SlideupDrawer'
import { darkblue, gunmetal } from '../../styles/colors'
import RadioGroup from '../dashboard/RiskAssessment/RadioGroup'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingTop: 20,
    paddingBottom: 40
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    marginBottom: 6
  },
  label: { color: darkblue, fontSize: 16, marginLeft: 20, fontWeight: '600' }
})

export default function NotificationOptions({
  visible,
  setVisible,
  hideNotification,
  turnOffNotification
}) {
  const options = [
    {
      icon: faEyeSlash,
      label: 'Hide this notification',
      onPress: () => hideNotification()
    },
    {
      icon: faBellSlash,
      label: 'Turn off this type of notification',
      onPress: () => turnOffNotification()
    }
  ]
  return (
    <SlideupDrawer
      snapPointIndex={1}
      scrollEnabled={false}
      visible={visible}
      setVisible={setVisible}
      snapPoints={[100, 200]}
    >
      <View style={styles.container}>
        <TouchableOpacity
          onPress={setVisible}
          style={{ position: 'absolute', right: 20, top: 0 }}
        >
          <FontAwesomeIcon icon={faCircleXmark} size={24} color="#8894A188" />
        </TouchableOpacity>

        {options.map((option) => (
          <TouchableOpacity onPress={option.onPress} key={option.label}>
            <View style={styles.item}>
              <FontAwesomeIcon icon={option.icon} size={20} color={gunmetal} />
              <Text style={styles.label}>{option.label}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
    </SlideupDrawer>
  )
}
