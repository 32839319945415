import { get } from 'http'
import { setAccounts } from './setAccounts'

export const getAccounts = function () {
  return async (dispatch) => {
    try {
      const result = await get('accounts/')
      dispatch(setAccounts(result.data))
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
