import { useState, useEffect, useLayoutEffect } from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import { orderBy } from 'lodash'
import SearchInput from './components/SearchInput'
import OccupationGroupsList from './components/careers/OccupationGroupsList'
import LocationSwitcher from './components/careers/LocationSwitcher'
import { getOccupations, setCareerLocation } from './actions'
import { careerLocationSelector, occupationsSelector } from './selectors'
import { white } from './styles'
import CareerExploreSearchPageHeader from './components/careers/CollegeExploreSearchPageHeader'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: 'rgb(236, 241, 244)'
  },
  searchContainer: { backgroundColor: white },
  pageHeader: {
    padding: 40,
    paddingBottom: 0,
    backgroundColor: white,
  },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  { [minSize(DEVICE_SIZES.LG)]: {}, }
)

export default function CareerExploreOccupationGroupsScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const careerLocation = useSelector(careerLocationSelector)
  const occupationGroups = useSelector((state) => occupationsSelector(state, careerLocation.id))
  const [careerSearch, setCareerSearch] = useState(null)

  async function searchGroups(careerLocation) {
    try {
      dispatch(getOccupations(careerLocation.id))
    } catch (error) {
      console.error(error.response)
    }
  }

  useEffect(() => {
    searchGroups(careerLocation)
  }, [careerLocation])

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Explore Careers',
      headerShown: isNotWeb,
    });
  }, [navigation])

  const groups = orderBy(occupationGroups, ['name'])

  const handleMilitaryGroupPress = () => {
    navigation.navigate('Military Career Explore Occupations')
  }

  return (
    <View style={styles.container}>
      {!isNotWeb && (
        <View style={styles.pageHeader}>
          <CareerExploreSearchPageHeader navigation={navigation} />
        </View>
      )}
      <View style={{ flex: 1 }}>
        <View style={styles.searchContainer}>
          <SearchInput
            style={{ marginHorizontal: isNotWeb ? 12 : 40, }}
            includeMargin
            value={careerSearch}
            placeholder="Find Career"
            onChange={(value) => setCareerSearch(value || null)}
          />
        </View>
        <LocationSwitcher
          city={careerLocation}
          setCity={(loc) => dispatch(setCareerLocation(loc))}
        />

        <OccupationGroupsList
          careerSearch={careerSearch}
          navigation={navigation}
          groupOptions={groups}
          locationId={careerLocation.id}
          onGroupPress={(occupationGroup) => {
            navigation.navigate('Career Explore Occupations', { occupationGroup })
          }}
          onMilitaryGroupPress={handleMilitaryGroupPress}
          onOccupationPress={(occupation) => {
            navigation.navigate('Modal', { screen: occupation.object !== 'occupation-detail' ? 'Military Career Explore' : 'Career Explore', params: { occupation } })
          }}
        />

        {/* military section, filters with search, different set up */}
      </View>
    </View>
  )
}
