import * as Yup from 'yup'
import { REQUIRED } from '../constants'

export const Fields = {
  key: 'key',
  name: 'name',
  year: 'year',
  currentValue: 'current_value',
  rate: 'data_rate',
  isRecurring: 'data_is_recurring',
  yearEnd: 'data_year_end',
  periods: 'periods'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.name]: Yup.string().required(REQUIRED),
  [Fields.year]: Yup.number().nullable()
    .test('year', "Year of purchase cannot be greater than your plan's investment horizon", function (value) {
      const { periods } = this.parent
      if (!value) return true;
      return value < periods
    })
    .required(REQUIRED),
  [Fields.key]: Yup.string().required(REQUIRED),
  [Fields.currentValue]: Yup.number().required(REQUIRED).nullable(),
  [Fields.rate]: Yup.number().required(REQUIRED),
  [Fields.isRecurring]: Yup.boolean().required(REQUIRED),
  [Fields.yearEnd]: Yup.string().when(Fields.isRecurring, {
    is: true,
    then: Yup.string().required(REQUIRED),
    otherwise: Yup.string().notRequired(),
  }),
})

export const InitialValues = {
  [Fields.name]: '',
  [Fields.key]: 'COLLEGE_SAVE',
  [Fields.year]: '',
  [Fields.currentValue]: '',
  [Fields.rate]: '',
  [Fields.isRecurring]: true,
  [Fields.yearEnd]: '',
  [Fields.periods]: 0
}
