import { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { map, first } from 'lodash'
import Card from '../Card'
import HeaderLabel from '../HeaderLabel'
import DataRow from '../DataRow'
import ButtonGroup from '../ButtonGroup'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  container: { paddingHorizontal: DEFAULT_SPACING, },
  card: {
    shadowColor: 'rgba(0, 0, 0, 0.0)',
    padding: DEFAULT_SPACING,
    borderRadius: 12,
  },
  buttonGroup: { marginVertical: 12 }
})

export default function _Card({ title, tooltipBody, header, rows, rowGroups, rowGroupDefault }) {
  const [selected, setSelected] = useState(rowGroups ? rowGroupDefault || first(Object.keys(rowGroups)) : false)

  function lastItem(i, r) {
    return (i + 1) === r.length
  }

  function renderRows(rows) {
    return map(rows, (row, i) => <DataRow {...row} key={`${row.label}-${row.value}`} lastItem={lastItem(i, rows)} />)
  }

  return (
    <View style={styles.container}>
      <Card style={styles.card}>
        <HeaderLabel tooltipBody={tooltipBody}>{title}</HeaderLabel>
        {header}

        {/* If only rows are passed in, only render rows */}
        { rows && renderRows(rows) }

        {/* If row groups are passed in render the button group and rows */}
        { rowGroups && <ButtonGroup style={styles.buttonGroup} options={Object.keys(rowGroups)} selected={selected} onPress={setSelected} /> }
        { rowGroups && rowGroups[selected] && renderRows(rowGroups[selected]) }
      </Card>
    </View>
  )
}
