import { put, post } from 'http'
import { getLifeEvents } from './getLifeEvents'

function formatData(payload) {
  const data = { meta_data: {} }

  Object.keys(payload).forEach((key) => {
    if (key.match(/data_rate/) || (key.match(/financial_/) && !key.match(/financial_is_down_payment_amount/))) {
      payload[key] = parseFloat(payload[key])
    }

    if (key.match(/data_year_end/)) {
      if (payload[key] !== '') {
        payload[key] = parseInt(payload[key])
      } else {
        delete payload[key]
      }
    }

    if (key.match(/data_/)) {
      data.meta_data[key.replace('data_', '')] = payload[key]
    } else {
      data[key] = payload[key]
    }
  })

  Object.keys(payload).forEach((key) => {
    if (key.match(/financial_/)) {
      if (!data.meta_data.financial) {
        data.meta_data.financial = {}
      }

      data.meta_data.financial[key.replace('financial_', '')] = payload[key]
      delete data[key]
    } else {
      data[key] = payload[key]
    }
  })

  return data
}

export const saveLifeEvent = function (payload, planId) {
  return async (dispatch) => {
    try {
      payload.id ? await put(`api/v1/plan/${planId}/events/${payload.id}/`, formatData(payload))
        : await post(`api/v1/plan/${planId}/events/`, formatData(payload))

      dispatch(getLifeEvents(planId))
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
