import React from 'react'
import { Text, View, ActivityIndicator } from 'react-native'

import { DEFAULT_SPACING, white } from '../../styles'
import SaveButton from '../header/SaveButton'
import ListItemOption from './ListItemOption'

import moment from 'moment'
import { map } from 'lodash'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import PlanSummaryHeaderMinCard from './PlanSummaryHeaderMinCard'

const useStyles = CreateResponsiveStyle(
  {
    headerContainer: {
      paddingHorizontal: DEFAULT_SPACING,
    },
    titleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    planName: {
      fontSize: 36,
      fontWeight: 600,
      color: white,
    },
    planDate: {
      fontSize: 14,
      color: white,
    },
    summaryListContainer: {
      display: 'none',
      paddingVertical: 22,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    item: {
      width: 'calc(33.33% - 12px)',
      maxWidth: 350,
      marginRight: DEFAULT_SPACING,
    },
    summaryMinListContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: white,
      borderRadius: 10,
      width: 360,
      marginTop: 22,
    },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      headerContainer: { paddingHorizontal: 40, },
      summaryListContainer: { display: 'flex', },
      summaryMinListContainer: { display: 'none', },
    }
  }
)

export default function PlansSummaryHeader({
  loading,
  name,
  created,
  onPressSave,
  animatedValue,
  options,
  planSummaryOptions
}) {
  const styles = useStyles()
  const formattedCreated = created ? moment(created).format('MMMM DD, YYYY') : 'Unknown'

  return (
    <View style={styles.headerContainer}>
      <View>
        <View style={styles.titleContainer}>
          <Text style={styles.planName} numberOfLines={1}>{name || 'Plan Summary'}</Text>
          {loading ? <ActivityIndicator style={{ marginHorizontal: 12 }} color={white} size="small" /> : <SaveButton color={white} iconSize={16} onPress={() => onPressSave(true)} />}
        </View>
        <Text style={styles.planDate}>Created {formattedCreated}</Text>
      </View>
      <View style={styles.summaryListContainer}>
        {map(planSummaryOptions, (option) => (<ListItemOption style={styles.item} key={option.label} {...option} />))}
      </View>
      <View style={styles.summaryMinListContainer}>
        {map(options, (option) => (<PlanSummaryHeaderMinCard key={`card-${option.label}`} option={option} />))}
      </View>
    </View>
  )
}
