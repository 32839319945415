import { del } from 'http'
import handleError from '../functions/handleError'
import handleSuccess from '../functions/handleSuccess'

import { getPlans } from './getPlans'

export const DELETE_PLAN = 'DELETE_PLAN'

export const deletePlan = function (plan) {
  return async (dispatch) => {
    try {
      await del(`api/v1/plans/${plan.id}/`)

      dispatch({
        type: DELETE_PLAN,
        payload: plan
      })

      dispatch(getPlans())

      handleSuccess('Success!', 'Plan deleted')
    } catch (error) {
      handleError(error, 'Unable to Delete Plan')
    }
  }
}
