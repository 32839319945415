import { Text, View, StyleSheet, Image } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import hapticFeedback from './hapticFeedback'
import ListItem from './ListItem'
import TouchableOpacity from './TouchableOpacity'

import { white, lightestgray, lightgray, gray, darkblue } from '../styles'
import useImageAspectRatio from '../hooks/useAspectRatio'
import useCheckImage from '../hooks/useCheckImage'
import CircleIcon from './CircleIcon'

const styles = StyleSheet.create({
  title: { padding: 16 },
  label: {
    fontSize: 16,
    color: darkblue,
    fontWeight: '600'
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  sublabel: {
    fontSize: 12,
    color: gray
  },
  container: {
    backgroundColor: white,
    borderWidth: 1,
    borderColor: lightestgray,
    borderRadius: 10,
    flexDirection: 'column',
    width: '100%',
    alignItems: 'stretch',
    marginBottom: 8,
  },
  linkContainer: {
    justifyContent: 'flex-start',
    minHeight: 0
  },
  link: { textAlign: 'left' },
  row: { flexDirection: 'row', },
  image: {
    height: 60,
    width: 60,
    resizeMode: 'contain'
  },
  imageContainer: {
    width: 70,
    marginRight: 5,
    alignItems: 'center'
  },
  header: {
    fontWeight: '600',
    fontSize: 18,
  },
  subheader: {
    fontWeight: '500',
    fontSize: 16,
    color: 'rgb(109, 122, 125)'
  },
  link: {
    fontSize: 16,
    marginVertical: 12
  },
  icon: {
    height: 70,
    width: 70,
    borderRadius: 100 / 2,
  }
})

export default function CollegeListItem({
  college,
  subheader,
  value,
  navigatable,
  onPress,
}) {
  const aspectRatio = useImageAspectRatio(college.logo_url)
  const isValidImage = useCheckImage(college.logo_url)

  return (
    <TouchableOpacity
      action={`College List Item: ${college.name}`}
      onPress={() => {
        if (onPress) onPress(value)
        hapticFeedback()
      }}
    >

      <ListItem style={[styles.container]}>
        <View style={styles.row}>
          <View style={styles.imageContainer}>
            {isValidImage && <Image style={[styles.image, { aspectRatio: aspectRatio > 1 ? aspectRatio / Math.floor(aspectRatio) : aspectRatio }]} source={{ uri: college.logo_url }} />}
            {!isValidImage && <CircleIcon icon="building-columns" style={styles.icon} color="rgb(235, 136, 38)" size={50} />}
          </View>

          <View style={{ flex: 2 }}>
            <View style={styles.labelContainer}>
              <Text numberOfLines={3} style={[styles.header]}>
                {college.name}
              </Text>
            </View>
            {!subheader && college.city && college.state && (
              <Text style={styles.subheader}>
                {' '}
                {college.city}
                ,
                {' '}
                {college.state}
                {' '}
              </Text>
            )}
            {subheader && (
              <Text style={styles.subheader}>
                {' '}
                {subheader}
                {' '}
              </Text>
            )}
          </View>

          {navigatable && <FontAwesomeIcon style={{ alignSelf: 'center' }} icon={faChevronCircleRight} size={18} color={lightgray} />}
        </View>
      </ListItem>
    </TouchableOpacity>
  )
}
