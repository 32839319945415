import { put } from 'http'

function formattedPayload(payload) {
  return {
    assets: {
      realestate: payload.realestate,
      checking: payload.checking,
      savings: payload.savings,
      retirement: payload.retirement,
      autos: payload.autos,
      other: payload.other
    },
    liabilities: {
      mortgage: payload.mortgage,
      consumer: payload.consumer,
      personal: payload.personal,
      student: payload.student,
      auto_loan: payload.auto_loan,
      other_debt: payload.other_debt
    }
  }
}

export async function netWorthCalculator(payload) {
  try {
    const result = await put('calculator/net-worth/', formattedPayload(payload))

    if (result) {
      return result.data
    }
    return null
  } catch (e) {
    console.warn(e, e.response)
  }
}
