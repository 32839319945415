import { View, StyleSheet, Image } from 'react-native'
import { DEFAULT_SPACING, lightblue, white } from '../styles'
import PillLabel from './PillLabel'

const styles = StyleSheet.create({
  logo: {
    width: 260,
    height: 33,
    marginBottom: DEFAULT_SPACING
  },
  pillLabel: {
    backgroundColor: white,
    borderRadius: 20,
    paddingVertical: 3
  },
  pillLabelText: {
    color: lightblue,
    fontSize: 20,
    letterSpacing: 2,
    fontWeight: '800'
  },
  horizontalLine: {
    backgroundColor: white,
    height: 1,
    flex: 1
  },
  horizontalLineLeft: {
    marginRight: DEFAULT_SPACING * 3,
    marginLeft: 'auto'
  },
  horizontalLineRight: {
    marginLeft: DEFAULT_SPACING * 3,
    marginRight: 'auto'
  }
})

export default function IntroProHeader({ style }) {
  return (
    <View style={style}>
      <Image source={require('../assets/troutwood.png')} style={styles.logo} resizeMode="contain" />

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <View style={[styles.horizontalLine, styles.horizontalLineRight]} />
        <PillLabel
          title="PRO"
          width={100}
          style={styles.pillLabel}
          textStyle={styles.pillLabelText}
        />
        <View style={[styles.horizontalLine, styles.horizontalLineLeft]} />
      </View>
    </View>
  )
}
