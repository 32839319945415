import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { View, StyleSheet, Image } from 'react-native'
import { DEFAULT_SPACING, darkblue, gunmetal, info, white } from '../styles'

const styles = StyleSheet.create({
  container: { marginBottom: DEFAULT_SPACING, },
  image: {
    width: 100,
    height: 100
  },
  circle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: info,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: -6,
    right: -6,
  },
  largeCircle: {
    width: 46,
    height: 46,
    borderRadius: 22.5,
    backgroundColor: info,
    opacity: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: -9,
    right: -9,
  }
})

export default function ShareTroutwoodImage() {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require('../assets/logo2.png')}
        resizeMode="contain"
      />
      <View style={[styles.largeCircle, styles.bottomRight]} />

      <View style={[styles.circle, styles.bottomRight]}>
        <FontAwesomeIcon
          icon={['fas', 'share']}
          color={white}
          size={20}
        />
      </View>
    </View>
  )
}
