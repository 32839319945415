import { useState, useEffect } from 'react'

import CongratsSlideupDrawer from '../CongratsSlideupDrawer'

export default function _CongratsSlideupDrawer({ statusNumber }) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (statusNumber == '3' || statusNumber == '2') {
      setTimeout(() => { setVisible(true) }, 1000)
    }
  }, [statusNumber])

  return (
    <CongratsSlideupDrawer
      visible={visible}
      setVisible={setVisible}
      title={"You've earned the Emergency Shield!"}
      subtitle="Congratulations, you’ve earned the Emergency Shield! Not having an emergency fund can derail an entire investing/saving plan. Preparing in advance of the problem helps minimize the impact of an emergency."
      metadataKey="TROUTWOOD_CONGRADULATIONS_PREPAREDNESS_SHIELD"
    />
  )
}
