import { useState, useLayoutEffect, useEffect } from 'react'
import { Platform, View, StyleSheet, TouchableOpacity, Text, ScrollView } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronCircleRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import SearchInput from './components/SearchInput'

import { white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import CareerExploreSearchPageHeader from './components/careers/CollegeExploreSearchPageHeader'
import militaryOccupationSelector from './selectors/militaryOccupation'
import OccupationGroupCard from './components/careers/OccupationGroupCard'
import LocationSwitcher from './components/careers/LocationSwitcher'
import { careerLocationSelector } from './selectors'
import { setCareerLocation } from './actions'
import SearchedOccupations from './components/careers/SearchedOccupations'
import { CareerExplore } from './MilitaryCareerExploreScreen'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: white,
  },
  pageHeader: {
    padding: 40,
    paddingBottom: 0,
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'rgb(236, 241, 244)',
    paddingBottom: DEFAULT_SPACING * 3,
  },
  listWrapper: {
    width: '100%',
    height: '100%',
    paddingHorizontal: DEFAULT_SPACING,
  },
  details: {},
  occupationButton: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1
  },
  occupationName: {
    fontSize: 18,
    color: 'rgb(48, 64, 77)',
    fontWeight: '600',
    marginVertical: 15
  },
  arrowIcon: { opacity: 0.6 },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: DEFAULT_SPACING,
        paddingBottom: DEFAULT_SPACING * 3,
      },
      listWrapper: { width: '40%', paddingHorizontal: 40, },
      details: {
        width: '60%',
        height: '100%',
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

export function ListItem({ name, onPress }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.occupationButton, { borderBottomColor: 'rgb(235, 241, 244)' }]}
    >
      <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', backgroundColor: white, padding: DEFAULT_SPACING * 1.5 }}>
        <Text style={styles.occupationButtonText}>
          {name}
        </Text>
        <FontAwesomeIcon icon={faChevronCircleRight} style={styles.arrowIcon} color="rgb(133, 151, 165)" size={15} />
      </View>
    </TouchableOpacity>
  )
}

export default function MilitaryCareerExploreOccupationsScreen({ navigation, }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { militaryOccupations } = useSelector((state) => militaryOccupationSelector(state))
  const [careerSearch, setCareerSearch] = useState(null)
  const [groupKey, setGroupKey] = useState()
  const careerLocation = useSelector((state) => careerLocationSelector(state))
  const dispatch = useDispatch()
  const [militaryCareers, setMilitaryCareers] = useState([])
  const [career, setCareer] = useState()

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Explore Military Careers',
      headerShown: isNotWeb,
    });
  }, [navigation])

  useEffect(() => {
    const careers = []
    if (militaryOccupations) {
      Object.keys(militaryOccupations.careers).map((key) => {
        careers.push(...militaryOccupations.careers[key])
      })
      setMilitaryCareers(careers)
    }
  }, [militaryOccupations])

  const handleCareerPress = (occupation) => {
    if (isNotWeb) navigation.navigate('Modal', { screen: 'Military Career Explore', params: { occupation } })
    else setCareer(occupation)
  }

  return (
    <View style={styles.container}>
      {!isNotWeb && (
        <View style={styles.pageHeader}>
          <CareerExploreSearchPageHeader navigation={navigation} />
        </View>
      )}
      <SearchInput
        style={{ marginHorizontal: isNotWeb ? 12 : 40, }}
        includeMargin
        value={careerSearch}
        placeholder="Find Career"
        onChange={(value) => {
          setCareerSearch(value || null)
        }}
      />

      <LocationSwitcher
        city={careerLocation}
        setCity={(loc) => dispatch(setCareerLocation(loc))}
      />

      <View style={styles.mainContainer}>
        <View style={styles.listWrapper}>
          <ScrollView
            keyboardShouldPersistTaps="handled"
            keyboardDismissMode="on-drag"
          >
            {careerSearch && careerSearch.length > 3
              && (
                <ScrollView style={{ marginTop: 16, minHeight: 240, }}>
                  <SearchedOccupations
                    onOccupationPress={(career) => handleCareerPress(career)}
                    careerSearch={careerSearch}
                    groupOptions={[{ ...militaryOccupations, careers: groupKey ? militaryOccupations.careers[groupKey] : militaryCareers }]}
                  />
                </ScrollView>
              )}
            <View style={{ marginVertical: 15, flexDirection: 'row', alignItems: 'center' }}>
              <TouchableOpacity disabled={!groupKey} onPress={() => setGroupKey(null)}>
                <Text
                  style={[styles.occupationName, { marginVertical: 0, textDecorationLine: groupKey ? 'underline' : 'none', }]}
                >
                  {groupKey ? 'Military' : militaryOccupations.name}
                </Text>
              </TouchableOpacity>
              {groupKey && (
                <>
                  <FontAwesomeIcon icon={faChevronRight} color="#B9C5C9" style={{ marginHorizontal: 6 }} />
                  <Text
                    style={[styles.occupationName, { marginVertical: 0, opacity: 0.6, }]}
                  >
                    {groupKey}
                  </Text>
                </>
              )}
            </View>
            {militaryOccupations.image && <OccupationGroupCard image={militaryOccupations.image} />}
            <Text style={[styles.occupationName, { marginTop: 0 }]}>Branches</Text>
            <ScrollView
              style={{ width: '100%', height: '100%', borderRadius: 12, }}
              contentContainerStyle={{ borderRadius: 12, overflow: 'hidden', }}
            >
              {!groupKey ? Object.keys(militaryOccupations.careers).map((subGroupKey) => (
                <ListItem
                  key={subGroupKey}
                  name={subGroupKey}
                  onPress={() => setGroupKey(subGroupKey)}
                />
              )) : militaryOccupations.careers[groupKey].map((career) => (
                <ListItem
                  key={career.id}
                  name={career.rank}
                  onPress={() => handleCareerPress(career)}
                />
              ))}
            </ScrollView>
          </ScrollView>
        </View>
        {!isNotWeb && !!career && (
          <View style={styles.details}>
            <CareerExplore occupation={career} />
          </View>
        )}
      </View>
    </View>
  )
}
