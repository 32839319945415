import { useLayoutEffect } from 'react'
import { View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import CollegeExploreSearchPageHeader from './components/colleges/CollegeExploreSearchPageHeader'
import CollegeExploreSearchScreen from './CollegeExploreSearchScreen'
import CollegeExploreScreen from './CollegeExploreScreen'
import { lightestgray } from './styles'

const useStyles = CreateResponsiveStyle(
  {
    wrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      paddingHorizontal: 16,
      paddingVertical: 24,
      backgroundColor: lightestgray
    },
    main: {
      flexDirection: 'row',
      height: 'calc(100% - 114px)',
      width: 'calc(100% - 32px)'
    },
    left: {
      minWidth: 290,
      width: '35%'
    },
    right: {
      width: '65%',
      paddingLeft: 12
    }
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      wrapper: {
        paddingHorizontal: 40,
        paddingVertical: 40
      },
      main: {
        flexDirection: 'row',
        height: 'calc(100% - 148px)',
        width: 'calc(100% - 80px)'
      },
      left: {
        minWidth: 360,
        width: '25%'
      },
      right: {
        display: 'flex',
        width: '75%',
        paddingLeft: 24,
        maxWidth: 'calc(100vw - 440px)'
      }
    },
    [minSize(DEVICE_SIZES.XL)]: {
      left: {
        minWidth: 400,
        width: '25%'
      },
      right: {
        display: 'flex',
        width: '75%',
        paddingLeft: 40,
        maxWidth: 'calc(100vw - 480px)'
      }
    }
  }
)

function CollegeExploreSearchPage({ navigation, route }) {
  const styles = useStyles()

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  return (
    <View style={styles.wrapper}>
      <CollegeExploreSearchPageHeader navigation={navigation} />
      <View style={styles.main}>
        <View style={styles.left}>
          <CollegeExploreSearchScreen navigation={navigation} route={route} />
        </View>
        <View style={styles.right}>
          <CollegeExploreScreen navigation={navigation} route={route} />
        </View>
      </View>
    </View>
  )
}

export default CollegeExploreSearchPage
