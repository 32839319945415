import { useState, } from 'react'
import { useDispatch } from 'react-redux'

import NamePlanSlideupDrawer from './plans/NamePlanSlideupDrawer'
import MoveToFolderSlideupDrawer from './plans/MoveToFolderSlideupDrawer'
import handleError from '../functions/handleError'
import planNameOrOccupationName from '../functions/planNameOrOccupationName'
import DeleteSlideupDrawer from './DeleteSlideupDrawer'
import {
  setCurrentPlan,
  setCurrentProPlan,
  deletePlan
} from '../actions'
import CareerCardBase from './CareerCardBase'
import investmentSelection from '../functions/investmentSelection'

export default function CareerCard({ form, actionButton, vertical = true, primary, navigation, style = {} }) {
  const dispatch = useDispatch()
  const [nameDrawerVisible, setNameDrawerVisible] = useState(false)
  const [moveDrawerVisible, setMoveDrawerVisible] = useState(false)
  const [deleteDrawerVisible, setDeleteDrawerVisible] = useState(false)

  const {
    id,
    modified,
    city,
    periods,
    step,
    fixed_weight,
    projected_portfolio_value: amount,
    probability_of_success: confidenceInterval
  } = form
  const name = planNameOrOccupationName(form)

  const handleDelete = () => {
    setTimeout(() => {
      dispatch(setCurrentPlan(form))
      setDeleteDrawerVisible(true)
    }, 400)
  }

  const handleRename = () => {
    setTimeout(() => {
      dispatch(setCurrentPlan(form))
      setNameDrawerVisible(true)
    }, 400)
  }

  const handleEdit = () => {
    dispatch(setCurrentPlan(form))
    dispatch(setCurrentProPlan(null))
    navigation.navigate('Modal', { screen: 'Review', params: { title: 'Plan Builder', subtitle: 'Review', builder: true, useGradient: true, includeProgress: true } })
  }

  const handleView = () => {
    dispatch(setCurrentPlan(form))
    dispatch(setCurrentProPlan(null))
    navigation.navigate('Modal', { screen: 'Plan Summary' })
  }

  const commonProps = {
    key: `career-card-${id}-${modified}`,
    careerInfo: {
      id,
      modified,
      name,
      city,
      periods,
      investmentSelection: investmentSelection(fixed_weight),
      amount,
      confidenceInterval,
    },
    vertical,
    primary,
    onDelete: handleDelete,
    onRename: handleRename,
    actionButton,
    style,
  };


  return (
    <>
      {step < 3 ? (
        <CareerCardBase {...commonProps} onEdit={handleEdit} />
      ) : (
        <CareerCardBase {...commonProps} onView={handleView} />
      )}

      <MoveToFolderSlideupDrawer
        plan={form}
        visible={moveDrawerVisible}
        setVisible={setMoveDrawerVisible}
      />

      <NamePlanSlideupDrawer
        plan={form}
        visible={nameDrawerVisible}
        setVisible={setNameDrawerVisible}
      />

      <DeleteSlideupDrawer
        visible={deleteDrawerVisible}
        setVisible={setDeleteDrawerVisible}
        onPress={() => {
          try {
            dispatch(deletePlan(form))
            setDeleteDrawerVisible(false)
          } catch (e) {
            handleError(e, 'Unable to Delete Plan')
            setDeleteDrawerVisible(false)
          }
        }}
        header="Delete Plan"
        body="Are you sure you want to delete this plan?"
        buttonText="Delete Plan"
      />

    </>
  )
}
