import { View, StyleSheet, Text } from 'react-native'
import * as WebBrowser from 'expo-web-browser'
import { useNavigation } from '@react-navigation/native'
import SlideupDrawer from '../SlideupDrawer'
import { darkblue, gunmetal, info, primary, white } from '../../styles/colors'
import Link from '../Link'
import { DEFAULT_SPACING } from '../../styles'
import Pill from '../Pill'

const styles = StyleSheet.create({
  container: {
    padding: DEFAULT_SPACING,
    paddingTop: DEFAULT_SPACING * 4
  },
  title: {
    fontSize: 24,
    fontWeight: '700',
    color: darkblue,
    marginBottom: DEFAULT_SPACING
  },
  definition: {
    fontSize: 16,
    fontWeight: '400',
    color: gunmetal,
    marginBottom: DEFAULT_SPACING * 2
  },
  link: { marginRight: DEFAULT_SPACING * 2 },
  links: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  relatedTerms: {
    fontSize: 18,
    fontWeight: '700',
    color: darkblue,
    marginTop: DEFAULT_SPACING * 2,
    marginBottom: DEFAULT_SPACING
  },
  pillContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: DEFAULT_SPACING
  },
  pill: {
    backgroundColor: primary,
    marginBottom: DEFAULT_SPACING,
    marginRight: DEFAULT_SPACING,
    padding: 4,
    borderRadius: 50
  },
  pillText: { color: white }
})

function RelatedTerms({ terms, setTerm }) {
  return (
    <>
      <Text style={styles.relatedTerms}>Related Terms</Text>
      <View style={styles.pillContainer}>
        {terms.map((relatedTerm, index) => (
          <Pill
            style={styles.pill}
            textStyle={styles.pillText}
            key={index}
            onPress={() => {
              setTerm(relatedTerm)
            }}
          >
            {relatedTerm}
          </Pill>
        ))}
      </View>
    </>
  )
}

export function FinglishTerm({ term, setTerm, setVisible }) {
  const navigation = useNavigation()
  const { definition } = term

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{term.term}</Text>
      {definition && <Text style={styles.definition}>{definition}</Text>}

      <View style={styles.links}>
        {term.youtube_link && (
          <Link
            style={styles.link}
            iconSize={16}
            icon="video"
            type="fas"
            iconColor={info}
            onPress={() => {
              setVisible(false)
              const videoLink = term.youtube_link.split('&')[0]
              const videoId = videoLink.split('v=')[1]
              navigation.navigate('Video Player', { videoId })
            }}
          >
            Watch Video
          </Link>
        )}
        {term.investopedia_link && <Link style={styles.link} image={require('../../assets/investopedia.png')} onPress={() => WebBrowser.openBrowserAsync(term.investopedia_link)}>Investopedia</Link>}
        {term.balance_link && <Link style={styles.link} image={require('../../assets/thebalance.png')} onPress={() => WebBrowser.openBrowserAsync(term.balance_link)}>The Balance</Link>}
      </View>

      {term.related_terms && <RelatedTerms setTerm={setTerm} terms={term.related_terms} />}
    </View>
  )
}

export default function FinglishTermSlideupDrawer({ term, visible, setVisible, setTerm }) {
  let snapPointIndex = 0
  const { definition, related_terms } = term

  if (definition && definition.length > 400) {
    snapPointIndex = 2
  } else if (definition && definition.length > 200) {
    snapPointIndex = 1
  }

  if (related_terms && related_terms.length > 0) {
    snapPointIndex = 2
  }

  return (
    <SlideupDrawer scrollable snapPointIndex={snapPointIndex} visible={visible} setVisible={setVisible}>
      <FinglishTerm term={term} setTerm={setTerm} setVisible={setVisible} />
    </SlideupDrawer>
  )
}
