import * as Yup from 'yup'
import {
  INVALID_MAX,
  INVALID_MIN,
  REQUIRED
} from '../constants'

export const Fields = {
  key: 'key',
  name: 'name',
  year: 'year',
  currentValue: 'current_value',
  rate: 'data_rate',
  downPayment: 'financial_down_payment',
  isDownPaymentAmount: 'financial_is_down_payment_amount',
  interestRate: 'financial_interest_rate',
  years: 'financial_years',
  periods: 'periods'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.name]: Yup.string().required(REQUIRED),
  [Fields.year]: Yup.number().nullable()
    .test('year', "Year of purchase cannot be greater than your plan's investment horizon", function (value) {
      const { periods } = this.parent
      if (!value) return true;
      return value < periods
    })
    .required(REQUIRED),
  [Fields.key]: Yup.string().required(REQUIRED),
  [Fields.currentValue]: Yup.number()
    .min(0, INVALID_MIN)
    .max(1000000000, `${INVALID_MAX}$1,000,000,000`)
    .required(REQUIRED)
    .nullable(),
  [Fields.rate]: Yup.number().required(REQUIRED),
  [Fields.downPayment]: Yup.string().nullable().required(REQUIRED)
    .test('downPayment', 'Your home down payment cannot be greater than 100%', function (value) {
      const { financial_is_down_payment_amount } = this.parent
      if (financial_is_down_payment_amount) return true
      return value <= 100
    })
    .test('downPayment', 'Your down payment must be less than your home cost', function (value) {
      const { current_value, financial_is_down_payment_amount } = this.parent
      if (!current_value) return true
      if (!financial_is_down_payment_amount) return true
      return current_value >= value
    }),
  [Fields.isDownPaymentAmount]: Yup.boolean(),
  [Fields.interestRate]: Yup.number()
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100%`)
    .required(REQUIRED),
  [Fields.years]: Yup.number()
    .min(0, INVALID_MIN)
    .max(30, `${INVALID_MAX}30`)
    .required(REQUIRED),
})

export const InitialValues = {
  [Fields.name]: '',
  [Fields.key]: 'HOME_BUY',
  [Fields.year]: '',
  [Fields.currentValue]: '',
  [Fields.rate]: '',
  [Fields.downPayment]: '',
  [Fields.isDownPaymentAmount]: true,
  [Fields.interestRate]: '',
  [Fields.years]: '',
  [Fields.periods]: 0
}
