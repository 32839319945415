import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { map } from 'lodash'
import { get } from 'http'
import { useIsFocused, useNavigation } from '@react-navigation/native'

import {
  wellnessChecklistsSelector,
  completedWellnessChecklistsSelector,
  currentUserSelector,
  accountsSelector
} from '../selectors'

import Divider from './Divider'
import ProShield from './Shield'
import { setCurrentPlan } from '../actions'
import { DEFAULT_SPACING, success } from '../styles'

const styles = StyleSheet.create({
  container: { position: 'relative' },
  shieldContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 30
  },
  divider: {
    position: 'absolute',
    top: 40,
    left: 0,
    right: 0,
    zIndex: -1
  }
})

export default function ShieldContainer() {
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [shields, setShields] = useState([])
  const isFocused = useIsFocused()

  const currentUser = useSelector((state) => currentUserSelector(state))
  const primaryPlan = currentUser?.primary_plan
  const planButtonText = primaryPlan?.id ? 'Go to Plan' : 'Create Plan'
  const emergencyPreparednessText = primaryPlan?.id ? 'Go to Emergency Preparedness' : null
  const isFake = currentUser.is_fake

  const currentAccounts = useSelector((state) => accountsSelector(state))

  const newPlanOnPress = () => {
    const birth_date = currentUser.is_fake ? null : currentUser.birth_date
    dispatch(setCurrentPlan({ step: 0, device, birth_date }))
    navigation.navigate('Modal', { screen: 'Plan Builder Intro' })
  }

  const viewPlanOnPress = () => {
    dispatch(setCurrentPlan(currentUser.primary_plan))
    navigation.navigate('Modal', { screen: 'Plan Summary' })
  }

  const goToEmergencyPreparedness = () => {
    if (currentAccounts.length > 0) {
      navigation.navigate('Modal', { screen: 'Emergency Preparedness' })
    } else {
      navigation.navigate('Modal', { screen: 'Emergency Preparedness Intro' })
    }
  }

  const planButtonOnPress = primaryPlan?.id ? viewPlanOnPress : newPlanOnPress
  const emergencyPreparednessOnPress = primaryPlan?.id ? goToEmergencyPreparedness : null

  const wellnessItems = useSelector((state) => wellnessChecklistsSelector(state))
  const completedItems = useSelector((state) => completedWellnessChecklistsSelector(state))
  const ontrackScore = wellnessItems.length == completedItems.length ? 100 : 0

  async function fetchPrimaryPlan() {
    if (!currentUser) return
    if (isFake) return
    if (!currentUser.primary_plan) return
    if (!currentUser.primary_plan.id) return

    let adequacyScore = 0
    let likelihoodScore = 0
    let preparednessScore = 0

    try {
      const primaryPlanResponse = await get(`/api/v1/plans/${currentUser.primary_plan.id}/`)
      const plan = primaryPlanResponse.data
      if (plan) {
        if (plan.plan_adequacy) {
          if (plan.contribution_selection === 'PENSION')
            adequacyScore = plan?.plan_adequacy?.plan_adequacy?.essentials?.adequacy * 100
          else if (plan?.plan_adequacy?.plan_adequacy['5%'])
            adequacyScore = plan?.plan_adequacy?.plan_adequacy['5%']?.adequacy * 100
        }

        if (plan.probability_of_success) likelihoodScore = plan.probability_of_success
      }

      const emergencyPreparednessResponse = await get('/emergency-preparedness/')
      const preparedness = emergencyPreparednessResponse.data
      if (preparedness)
        preparednessScore =
          preparedness.status === 'LEVEL_2' || preparedness.status === 'LEVEL_3' ? 100 : 0
    } catch (e) {
      console.warn(e, e.response)
    } finally {
      setLoading(false)
      setShields([
        {
          key: 'plan-shield',
          label: 'Plan',
          value: !isFake && primaryPlan ? 100 : 0,
          tooltipHeader: 'Plan Shield',
          tooltip:
            'The Plan Shield is the easiest to earn but requires the most discipline to follow. Create and save a Primary Plan to earn yours.',
          onButtonPress: planButtonOnPress,
          buttonText: planButtonText,
          shieldIndex: !isFake && primaryPlan ? 2 : 0,
          shields: [
            require('../assets/shields/plan-shield-disabled.png'),
            require('../assets/shields/plan-shield-in-progress.png'),
            require('../assets/shields/plan-shield-default.png')
          ]
        },
        {
          key: 'likelihood-shield',
          label: 'Likelihood',
          value: !isFake && likelihoodScore ? likelihoodScore : 0,
          tooltipHeader: 'Likelihood Shield',
          tooltip:
            'The Likelihood Shield is earned when you have a >79% chance of achieving your goal based on your Primary Plan.',
          onButtonPress: planButtonOnPress,
          buttonText: planButtonText,
          shieldIndex: isFake ? 0 : likelihoodScore < 50 ? 0 : likelihoodScore <= 70 ? 1 : 2,
          shields: [
            require('../assets/shields/likelihood-shield-disabled.png'),
            require('../assets/shields/likelihood-shield-in-progress.png'),
            require('../assets/shields/likelihood-shield-default.png')
          ]
        },
        {
          key: 'adequacy-shield',
          label: 'Adequacy',
          value: !isFake && adequacyScore ? adequacyScore : 0,
          tooltipHeader: 'Adequacy Shield',
          tooltip:
            'The Adequacy Shield is earned when your plan answers "Yes" to the question, "Will I have enough?". Check out the Goal Adequacy card on your Plan Summary.',
          onButtonPress: planButtonOnPress,
          buttonText: planButtonText,
          shieldIndex: isFake ? 0 : adequacyScore < 50 ? 0 : adequacyScore <= 70 ? 1 : 2,
          shields: [
            require('../assets/shields/adequecy-shield-disabled.png'),
            require('../assets/shields/adequecy-shield-in-progress.png'),
            require('../assets/shields/adequecy-shield-default.png')
          ]
        },
        {
          key: 'emergency-shield',
          label: 'Emergency',
          value: !isFake && preparednessScore ? preparednessScore : 0,
          tooltipHeader: 'Emergency Shield',
          tooltip:
            "The Emergency Shield is earned through our Emergency Preparedness Dashboard Tool. If you are prepared for 6+ months, based on your primary plan's living essentials, you will earn the Emergency Shield.",
          onButtonPress: emergencyPreparednessOnPress,
          buttonText: emergencyPreparednessText,
          shieldIndex: isFake ? 0 : preparednessScore < 50 ? 0 : preparednessScore <= 70 ? 1 : 2,
          shields: [
            require('../assets/shields/emergency-shield-disabled.png'),
            require('../assets/shields/emergency-shield-in-progress.png'),
            require('../assets/shields/emergency-shield-default.png')
          ]
        },
        {
          key: 'on-track-shield',
          label: 'On Track',
          value: !isFake ? ontrackScore : 0,
          tooltipHeader: 'On Track Shield',
          tooltip:
            'The On Track Shield is earned if you are on pace to reach your financial goal, relative to your prior forecast.',
          shieldIndex: isFake ? 0 : ontrackScore < 50 ? 0 : ontrackScore <= 70 ? 1 : 2,
          tooltipHeader: 'On Track Shield',
          tooltip:
            'The On Track Shield is earned when you have completed each milestone within the Wellness Checklist.',
          onButtonPress: () => navigation.navigate('Modal', { screen: 'Wellness Checklist Intro' }),
          buttonText: 'Go to Wellness Checklist',
          shields: [
            require('../assets/shields/on-track-shield-disabled.png'),
            require('../assets/shields/on-track-shield-in-progress.png'),
            require('../assets/shields/on-track-shield-default.png')
          ]
        }
      ])
    }
  }

  useEffect(() => {
    if (isFocused) {
      setLoading(true)
      fetchPrimaryPlan()
    }
  }, [isFocused])

  if (loading)
    return (
      <ActivityIndicator
        style={{ paddingVertical: DEFAULT_SPACING * 3 }}
        color={success}
        size="small"
      />
    )

  return (
    <View style={styles.container}>
      <View style={styles.shieldContainer}>
        {map(shields, (shield) => (
          <View key={shield.key} style={{ flexDirection: 'column' }}>
            <ProShield {...shield} />
          </View>
        ))}
      </View>

      <Divider style={styles.divider} />
    </View>
  )
}
