import { View, StyleSheet, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { DEFAULT_SPACING, white } from '../../styles'
import PageHeader from '../PageHeader'
import ShareAndEmpowerCard from '../ShareAndEmpowerCard'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: white,
  },
  left: {},
  cardContainer: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: {
        paddingHorizontal: DEFAULT_SPACING * 4,
        paddingVertical: 56,
      },
      left: { width: '40%', maxWidth: 536, },
      cardContainer: {
        marginTop: DEFAULT_SPACING * 5,
        marginHorizontal: 'auto',
        paddingHorizontal: DEFAULT_SPACING * 2,
        paddingTop: 36,
        paddingBottom: 60,
        shadowColor: '#0000000F',
        shadowOffset: {
          width: 0,
          height: 10
        },
        shadowRadius: 28,
        borderWidth: 1,
        borderColor: '#ECF1F4',
        borderRadius: 12,
      }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function PageLayout({title, subTitle, main, }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View style={styles.container}>
      {!isNotWeb && (
        <PageHeader
          title={title}
          subTitle={subTitle}
        />)}
      {isNotWeb ? main : (
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.left}>{main}</View>
          <View style={styles.cardContainer}>
            <ShareAndEmpowerCard />
          </View>
        </View>
      )}
    </View>
  );
}
