import { StyleSheet } from 'react-native'
import Card from './Card'
import CardHeader from './CardHeader'

import formatPercent from '../../functions/formatPercent'

export default function DemographicsCard({ college }) {
  const header = (
    <CardHeader
      rightIcon="mars"
      rightHeader={college.ugds_men ? formatPercent(college.ugds_men * 100) : '-'}
      rightSubheader="Male"
      leftIcon="venus"
      leftHeader={college.ugds_women ? formatPercent(college.ugds_women * 100) : '-'}
      leftSubheader="Female"
    />
  )

  const rows = [{
    label: 'Asian',
    value: college.ugds_asian ? formatPercent(college.ugds_asian * 100) : '-'
  }, {
    label: 'American Indian/Alaska Native',
    value: college.ugds_aian ? formatPercent(college.ugds_aian * 100) : '-'
  }, {
    label: 'Black',
    value: college.ugds_black ? formatPercent(college.ugds_black * 100) : '-'
  }, {
    label: 'Native Hawaiian/Pacific Islander',
    value: college.ugds_nhpi ? formatPercent(college.ugds_nhpi * 100) : '-'
  }, {
    label: 'White',
    value: college.ugds_white ? formatPercent(college.ugds_white * 100) : '-'
  }, {
    label: 'Two or More',
    value: college.ugds_2mor ? formatPercent(college.ugds_2mor * 100) : '-'
  }, {
    label: 'Non-Resident Alien',
    value: college.ugds_nra ? formatPercent(college.ugds_nra * 100) : '-'
  }, {
    label: 'Unknown',
    value: college.ugds_unkn ? formatPercent(college.ugds_unkn * 100) : '-'
  }]

  return (
    <Card
      title="Demographics"
      header={header}
      rows={rows}
    />
  )
}
