import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { white } from '../styles/colors'
import hapticFeedback from './hapticFeedback'

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    borderRadius: 4
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2
  }
})

export default function Card({ style, onPress, children, shadow = false }) {
  const Container = onPress ? TouchableOpacity : View

  return (
    <Container
      onPress={() => {
        if (onPress) onPress()
        hapticFeedback()
      }}
      style={[styles.container, shadow ? styles.shadow : {}, style]}
    >
      {children}
    </Container>
  )
}
