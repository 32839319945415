import { useEffect, useState } from 'react'
import { Image, Text, StyleSheet } from 'react-native'

import NetInfo from '@react-native-community/netinfo'
import { darkblue, DEFAULT_SPACING, gunmetal } from './styles'
import Container from './components/Container'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    color: darkblue,
    fontSize: 24,
    fontWeight: '600',
    marginBottom: DEFAULT_SPACING
  },
  text: {
    color: gunmetal,
    width: '80%',
    textAlign: 'center'
  },
  image: {
    width: 300,
    height: 400,
    marginBottom: DEFAULT_SPACING
  }
})

export default function OfflineScreen({ children }) {
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      setIsConnected(state.isConnected);
    });
    return () => {
      unsubscribe()
    };
  }, []);

  return (
    isConnected ? children
      : (
        <Container style={styles.container}>
          <Image
            source={require('./assets/finn/offline.png')}
            style={styles.image}
            resizeMode="contain"
          />
          <Text style={styles.header}>Uh Oh!</Text>
          <Text style={styles.text}>It looks like you don't have an internet connection right now. Try reconnecting!</Text>
        </Container>
      )
  )
}
