import { View } from 'react-native'

export default function LibraryItemScreen({ navigation, route }) {
  // This View is only to ensure that the Web build actually builds
  // Otherwise it would use LibraryItemScreen.js which has the PDf
  // module which is not supported on the web
  return (
    <View />
  )
}
