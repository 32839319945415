import { del } from 'http'
import { getCurrentUser } from './getCurrentUser'
import { getPlans } from './getPlans'
import { getWellnessChecklists } from './getWellnessChecklists'
import { getPersonalMilestones } from './getPersonalMilestones'

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'

export const deleteAccount = function (id) {
  return async (dispatch) => {
    try {
      await del(`accounts/${id}/`)

      dispatch({
        type: DELETE_ACCOUNT,
        payload: id
      })

      dispatch(getCurrentUser())
      dispatch(getPlans())
      dispatch(getWellnessChecklists())
      dispatch(getPersonalMilestones())
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
