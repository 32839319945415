import { useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import AccountList from './AccountList'
import CircleIcon from './CircleIcon'

import {
  rgba,
  gunmetal,
  lightblue,
  white,
  darkblue,
  lightestgray
} from '../styles/colors'

import Card from './Card'
import AccordionList from './AccordionList'
import { DEFAULT_SPACING } from '../styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const styles = StyleSheet.create({
  card: { borderRadius: 0 },
  title: {
    fontSize: 16,
    color: darkblue,
    fontWeight: '600'
  },
  subtitle: {
    fontSize: 16,
    color: darkblue,
    fontWeight: '600',
    marginRight: 10
  },
  boldLinkText: {
    fontWeight: '600',
    fontSize: 12,
    color: lightblue,
    marginLeft: 18
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  rightContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  circleStyle: (expanded) => ({
    marginRight: 10,
    transform: [{ rotate: expanded ? '270deg' : '90deg' }]
  }),
  headerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING * 1.3,
    borderBottomWidth: 1,
    borderBottomColor: lightestgray
  },
  listStyle: (expanded) => ({ display: expanded ? 'flex' : 'none' }),
  addAccountButton: { height: 64, justifyContent: 'center' }
})

export default function Accordion({
  style,
  title,
  titleStyle = {},
  subtitleStyle = {},
  headerStyle = {},
  subtitle,
  children,
  label,
  data = [],
  onDelete,
  onEdit,
  onAdd,
  includeInlineChevron = false,
  disableBottomBorder = false,
  compressed = false,
  includeLeftChevron = false
}) {
  const [expanded, setExpanded] = useState(false)

  const onPressHeader = () => setExpanded(!expanded)

  return (
    <Card style={[style, styles.card]}>
      <TouchableOpacity
        testId="clickable"
        style={[styles.headerStyle, headerStyle]}
        onPress={onPressHeader}
      >
        <View style={styles.leftContainer}>
          {includeLeftChevron && (
            <FontAwesomeIcon
              color={darkblue}
              size={16}
              icon={expanded ? 'chevron-down' : 'chevron-right'}
              style={{ marginRight: 10 }}
            />
          )}

          <Text style={[styles.title, titleStyle]}>{title}</Text>
        </View>

        <View style={styles.rightContainer}>
          {subtitle && (
            <Text style={[styles.subtitle, subtitleStyle]}>{subtitle}</Text>
          )}

          {!includeInlineChevron && (
            <CircleIcon
              style={styles.circleStyle(expanded)}
              icon="chevron-right"
              color={white}
              backgroundColor={rgba(gunmetal, 0.2)}
              size={10}
              width={10}
              height={10}
            />
          )}
        </View>
      </TouchableOpacity>

      {children && expanded && children}

      {!children && data.length > 0 && expanded && (
        <AccordionList
          list={data}
          onDelete={onDelete}
          onEdit={onEdit}
          label={label}
          compressed={compressed}
          disableBottomBorder={disableBottomBorder}
        />
      )}

      {onAdd && expanded && (
        <TouchableOpacity style={styles.addAccountButton} onPress={onAdd}>
          <Text style={styles.boldLinkText}>+ Add {label}</Text>
        </TouchableOpacity>
      )}
    </Card>
  )
}

Accordion.defaultProps = {
  style: {},
  title: undefined,
  label: undefined,
  data: [],
  expandable: true,
  onEdit: undefined,
  onDelete: undefined
}

Accordion.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isAsset: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}
