import { View, ScrollView, StyleSheet } from 'react-native'

import Container from './components/Container'
import Header from './components/Header'
import ListOptionItem from './components/ListOptionItem'
import {
  DEFAULT_SPACING,
  lightestgray, rgba
} from './styles'

import { lifeEventOptions } from './constants'

const styles = StyleSheet.create({
  container: { backgroundColor: lightestgray },
  contentContainerStyle: { padding: DEFAULT_SPACING, }
})

function LifeEventOption({ label, value, icon, color, navigation, plan }) {
  return (
    <ListOptionItem
      onPress={() => navigation.navigate('Add Life Event', { type: value, label, color, icon, plan })}
      iconType="far"
      label={label}
      iconName={icon}
      iconColor={color}
      iconBackgroundColor={color ? rgba(color, 0.1) : null}
      navigatable
    />
  )
}

export default function AddLifeEventScreen({ navigation, route }) {
  const { plan } = route.params

  const options = Object.values(lifeEventOptions).sort((a, b) => a.label.localeCompare(b.label))

  return (
    <Container style={styles.container}>
      <Header text={'Choose an event to see how \nit will affect your finances'} />

      <ScrollView contentContainerStyle={styles.contentContainerStyle}>
        {options.map((option) => (
          <LifeEventOption key={option.value} {...option} navigation={navigation} plan={plan} />
        ))}
      </ScrollView>
    </Container>
  )
}
