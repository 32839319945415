import moment from 'moment'

export function calculateStreak(monthlyContributions=[]) {
  let streak = 0
  let filteredMonthlyContributions = [...monthlyContributions]
  const lastMonthlyContribution = filteredMonthlyContributions[filteredMonthlyContributions.length - 1]
  const today = moment().format('YYYY-MM')

  if (lastMonthlyContribution && lastMonthlyContribution.label === today && !lastMonthlyContribution.checked) {
    filteredMonthlyContributions.pop()
  }

  for (let i = filteredMonthlyContributions.length - 1; i >= 0; i--) {
    if (filteredMonthlyContributions[i].checked) {
      streak++
    } else {
      break
    }
  }  

  return streak || 0
}

export function convertMonthlyContributionsToHash(monthlyContributions=[]) {
  const today = moment().format('YYYY-MM')
  const calculatedMonthlyContributions = []
  const contributionDates = monthlyContributions.map(contribution => contribution.date)
  const firstContribution = monthlyContributions[0] || { date: moment().format('YYYY-MM') }
  let startDate = moment(firstContribution.date, 'YYYY-MM')
  let endDate = moment()

  while (startDate.isSameOrBefore(endDate, 'month')) {
    let date = startDate.format('YYYY-MM')
    calculatedMonthlyContributions.push({
      label: date,
      checked: contributionDates.includes(date),
      currentMonth: moment === today
    })
    startDate.add(1, 'months')
  }

  return calculatedMonthlyContributions
}
