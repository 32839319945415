import { useState, useLayoutEffect, Fragment } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import SearchInput from './components/SearchInput'
import OccupationGroupList from './components/careers/OccupationGroupList'

import { DEFAULT_SPACING, white } from './styles'

import BuilderWrapper from './components/plans/BuilderWrapper'
import { Career } from './CareerScreen'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: 'rgb(236, 241, 244)',
    paddingBottom: DEFAULT_SPACING * 3,
  },
  searchInput: {},
  listWrapper: {
    width: '100%',
    height: '100%',
  },
  details: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      searchInput: {
        width: '50%',
        marginTop: 12,
      },
      container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 18,
        marginHorizontal: -40,
        marginBottom: -40,
      },
      listWrapper: { width: '40%', },
      details: {
        width: '60%',
        height: '100%',
      },
    }
  }
)

export default function ExploreOccupationGroupScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const {
    occupationGroup,
    title,
    subtitle,
    builder,
    useGradient,
    includeProgress,
  } = route.params
  const [careerSearch, setCareerSearch] = useState(null)
  const [career, setCareer] = useState(null)

  useLayoutEffect(() => {
    if (builder || useGradient) {
      navigation.setOptions({
        headerShown: isNotWeb,
        headerTransparent: true,
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
        },
        headerTitleStyle: { color: white },
        headerTintColor: white,
        title: subtitle
      })
    } else {
      navigation.setOptions({
        headerShown: isNotWeb,
        title,
        subtitle,
      })
    }
  }, [title, subtitle, builder, useGradient])

  const Wrapper = (builder || useGradient || !isNotWeb) ? BuilderWrapper : Fragment

  return (
    <Wrapper includeProgress={includeProgress} option="Career">
      <SearchInput
        includeMargin={isNotWeb}
        inverse
        value={careerSearch}
        placeholder="Find Career"
        onChange={(value) => {
          setCareerSearch(value || null)
        }}
        style={styles.searchInput}
      />

      <View style={styles.container}>
        <View style={styles.listWrapper}>
          <OccupationGroupList
            occupationGroup={occupationGroup}
            careerSearch={careerSearch}
            onOccupationPress={(occupation) => {
              if (isNotWeb) navigation.navigate('Modal', { screen: 'Explore Career', params: { groupName: occupationGroup.name, occupation, builder, title, subtitle, useGradient, includeProgress, } })
              else {
                setCareer(occupation)
              }
            }}
          />
        </View>

        {!isNotWeb && !!career && (
          <View style={styles.details}>
            <Career builder={builder} title={title} occupation={career} navigation={navigation} />
          </View>
        )}
      </View>
    </Wrapper>
  )
}
