import { useState, useEffect, useRef } from 'react'
import { View, Text, Dimensions, StyleSheet, useWindowDimensions } from 'react-native'
import { get, post, del, put } from 'http'
import { map, each } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Input from '../Input'
import CurrencyInput from '../CurrencyInput'

import Link from '../Link'
import TouchableOpacity from '../TouchableOpacity'

import { currentPlanSelector } from '../../selectors'

import Screen from '../Screen'

import { saveCurrentPlan } from '../../actions'
import { lightblue, white } from '../../styles/colors'
import handleError from '../../functions/handleError'
import handleSuccess from '../../functions/handleSuccess'

import {
  ValidationSchema,
  InitialValues,
  Fields
} from '../../schemas/addOnSchema'

import Form from '../Form'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: white,
    paddingHorizontal: DEFAULT_SPACING
  },
  addOnsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  link: {
    marginVertical: 12,
    fontSize: 16,
    fontWeight: '600'
  },
  addOnButton: {
    borderRadius: 12,
    borderWidth: 1,
    width: '30%',
    marginVertical: 5,
    paddingHorizontal: 10,
  },
  addOnButtonText: {
    textAlign: 'center',
    fontSize: 12
  }
})

function AddOnForm({ data, onSubmit }) {
  const amountField = useRef()
  const initialValues = data || InitialValues

  return (
    <Form
      disableScroll
      buttonText="Save"
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        onSubmit(values)
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
        <View>
          <Input
            label="Name"
            name={Fields.name}
            errorMessage={errors[Fields.name]}
            touched={touched[Fields.name]}
            onChangeText={handleChange(Fields.name)}
            onBlur={handleBlur(Fields.name)}
            value={values[Fields.name]}
            returnKeyType="next"
            onSubmitEditing={() => amountField.current.focus()}
          />
          <CurrencyInput
            ref={amountField}
            type="money"
            unit="$"
            name={Fields.amount}
            value={values[Fields.amount]}
            touched={touched[Fields.amount]}
            errorMessage={errors[Fields.amount]}
            onChangeText={handleChange(Fields.amount)}
            label="Amount of Surplus to Dedicate (Monthly)"
            onSubmitEditing={handleSubmit}
          />
        </View>
      )}
    </Form>
  )
}

export default function AddOnsFormScreen({ route, navigation }) {
  const dispatch = useDispatch()
  const form = useSelector((state) => currentPlanSelector(state))

  const { title } = route.params

  useEffect(() => {
    navigation.setOptions({ title })
  })

  const [selected, setSelected] = useState(false)
  const defaultAddOns = {
    subscription: {},
    entertainment: {},
    pet: {},
    charity: {},
    hobby: {},
    custom: {}
  }
  const [addOns, setAddOns] = useState({ ...defaultAddOns, ...form.add_ons })

  const currentPlan = useSelector((state) => currentPlanSelector(state))

  async function fetchAndSetData(currentPlan) {
    try {
      const result = await get(`api/v1/plans/${currentPlan.id}/`)
      const initialAddOns = {}
      each(result.data.add_ons, (addOn) => {
        initialAddOns[addOn.category.toLowerCase()] = addOn
      })
      setAddOns(initialAddOns)
    } catch (error) {
      handleError(error, 'Unable to fetch Add ons')
    }
  }

  useEffect(() => {
    fetchAndSetData(currentPlan)
  }, [currentPlan])

  const addOnOptions = [{
    label: 'Subscription',
    icon: 'credit-card',
    type: 'fal'
  }, {
    label: 'Entertainment',
    icon: 'tv',
    type: 'fal'
  }, {
    label: 'Pet',
    icon: 'dog',
    type: 'fal'
  }, {
    label: 'Charity',
    icon: 'circle-heart',
    type: 'fal'
  }, {
    label: 'Hobby',
    icon: 'guitar',
    type: 'fal'
  }, {
    label: 'Custom',
    icon: 'star',
    type: 'fal'
  }]

  function AddOnOption({ label, type, icon }) {
    const { height } = useWindowDimensions()

    const iconSize = height > 850 ? 28 : 20

    function handlePress() {
      setSelected(label)
    }

    const addOn = addOns[label.toLowerCase()]
    const hasAmount = addOn?.amount
    const selectedOrHasAmount = (selected == label || hasAmount)

    const borderColor = selectedOrHasAmount ? lightblue : 'rgb(233, 238, 239)'
    const backgroundColor = selectedOrHasAmount ? 'rgba(52, 145, 247, 0.1)' : 'transparent'
    const paddingVertical = height > 850 ? 20 : 10

    const marginTop = height > 850 ? 16 : 10

    return (
      <TouchableOpacity
        onPress={handlePress}
        style={[
          styles.addOnButton,
          {
            borderColor,
            paddingVertical,
            backgroundColor
          }
        ]}
      >

        <FontAwesomeIcon
          icon={[type, icon]}
          style={{ alignSelf: 'center' }}
          color={lightblue}
          size={iconSize}
        />

        <Text style={[styles.addOnButtonText, { marginTop }]}>{label}</Text>
      </TouchableOpacity>
    )
  }

  function handleSubmit(values) {
    const payload = {
      id: values.id,
      amount: values.amount,
      name: values.name,
      plan: currentPlan.id,
      category: selected.toUpperCase()
    }

    try {
      payload.id ? put(`add-ons/${payload.id}/`, payload) : post('add-ons/', payload)

      dispatch(saveCurrentPlan({}))

      handleSuccess('Success!', 'Add-on saved successfully')

      navigation.pop()
    } catch (e) {
      handleError(e, 'Unable to save add-on')
    }
  }

  function handleClear() {
    const addOn = addOns[selected.toLowerCase()] || {}

    try {
      del(`add-ons/${addOn.id}/`)
      handleSuccess('Add-on Deleted!', 'Add-on successfully deleted.')
      dispatch(saveCurrentPlan({}))
      navigation.pop()
    } catch (e) {
      handleError(e, 'Error deleting add-on')
    }
  }

  return (
    <Screen style={styles.container} scrollViewStyle={{ flex: 1 }} safeAreaStyle={{ flex: 1 }} withPadding>
      <View style={styles.addOnsContainer}>
        {map(addOnOptions, (option) => <AddOnOption {...option} />)}
      </View>

      { selected && (
        <Link style={styles.link} onPress={handleClear}>
          Clear Add On
        </Link>
      )}

      <View style={{flex: 1}}>
        {map(Object.keys(defaultAddOns), (key) => {
          if (selected && selected.toLowerCase() == key) {
            return (
              <AddOnForm
                data={addOns[selected.toLowerCase()] || null}
                onSubmit={handleSubmit}
              />
            )
          }
        })}
      </View>
    </Screen>
  )
}
