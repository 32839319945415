import { View, ScrollView, Text, Image, StyleSheet, useWindowDimensions, } from 'react-native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import { map } from 'lodash'
import hapticFeedback from './components/hapticFeedback'
import ListOptionItem from './components/ListOptionItem'
import { darkblue, gunmetal, lightestgray, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import TouchableOpacity from './components/TouchableOpacity'

const styles = StyleSheet.create({
  container: { backgroundColor: white },
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 63,
    height: 63,
    borderRadius: 63 / 2,
    overflow: 'hidden'
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0,
    marginLeft: DEFAULT_SPACING
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5,
    marginLeft: DEFAULT_SPACING,
    marginTop: 4
  },
  helpText: {
    marginTop: DEFAULT_SPACING,
    fontWeight: '300',
    fontSize: 12,
    color: darkblue,
    fontStyle: 'italic'
  }
})

function VerticalOptionItem({ image, pdf, title, navigation }) {
  const window = useWindowDimensions()
  const width = window.width * 0.42

  return (
    <TouchableOpacity onPress={() => {
      hapticFeedback()
      // To LibraryBooks page :MM
      navigation.navigate('Modal', { screen: 'Library Book', params: { pdf, title, image } })
    }}
    >
      <Image source={image} resizeModal="contain" style={{ borderRadius: 6, width, height: 240 }} />
    </TouchableOpacity>
  )
}

function CircleImage({ image }) {
  return (
    <View>
      <View style={{
        position: 'relative',
        overflow: 'hidden',
        alignItems: 'flex-start',
        borderRadius: 6,
        width: 50,
        height: 50,
        borderRadius: 25,
        marginRight: DEFAULT_SPACING
      }}
      >
        <Image
          source={image}
          resizeMode="cover"
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </View>
    </View>
  )
}

function HorizontalOptionItem({ image, pdf, title, description, navigation }) {
  return (
    <ListOptionItem
      iconComponent={<CircleImage image={image} />}
      label={title}
      sublabel={description}
      navigatable
      onPress={() => {
        hapticFeedback()
        navigation.navigate('Modal', { screen: 'Library Item', params: { pdf, title } })
      }}
    />
  )
}

function HorizontalQuizOptionItem({ image, title, navigation }) {
  return (
    <ListOptionItem
      iconComponent={(
        <View style={{
          position: 'relative',
          overflow: 'hidden',
          alignItems: 'flex-start',
          borderRadius: 6,
          width: 50,
          height: 50,
          borderRadius: 25,
          marginRight: DEFAULT_SPACING,
          backgroundColor: lightestgray

        }}
        >
          <FontAwesomeIcon
            icon={faCommentDots}
            style={{
              fontSize: 0,
              position: 'relative',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '30%',
              transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }]
            }}
          />
        </View>
      )}
      label="Quiz"
      sublabel={(
        <Text>
          Test your knowledge with
          <Text style={{ fontWeight: 'bold' }}>
            {' '}
            {title}
            {' '}
          </Text>
          Quiz
        </Text>
      )}
      navigatable
      onPress={() => {
        hapticFeedback()
        navigation.navigate('Modal', { screen: 'Quiz', params: { title } })
      }}
    />
  )
}

export default function LibraryScreen({ navigation }) {
  const books = [{
    image: require('./assets/missing-semester.png'),
    pdf: 'https://tw-mobile-app-resources.s3.us-east-2.amazonaws.com/missing-semester.pdf',
    title: 'The Missing Semester',
  }, {
    image: require('./assets/missing-2nd-semester.png'),
    pdf: 'https://tw-mobile-app-resources.s3.us-east-2.amazonaws.com/missing-2nd-semester.pdf',
    title: 'The Missing 2nd Semester',
  }]

  const resources = [{
    image: require('./assets/tms-presentation.png'),
    pdf: 'https://tw-mobile-app-resources.s3.us-east-2.amazonaws.com/tms-presentation.pdf',
    title: 'Chapter Discussions',
    description: 'Chapter discussion questions designed to engage students',
  }, {
    image: require('./assets/tms-2-presentation.png'),
    pdf: 'https://tw-mobile-app-resources.s3.us-east-2.amazonaws.com/tms-2-presentation.pdf',
    title: 'Chapter Discussions',
    description: 'Chapter discussion questions designed to engage students',
  }]

  const titles = [{
    title: 'The Missing Semester',
    abbr: 'tms'
  },
  {
    title: 'The Missing Second Semester',
    abbr: 'tmss'
  }
  ]

  return (
    <View style={{ backgroundColor: lightestgray, position: 'relative' }}>
      <ScrollView
        contentContainerStyle={{ paddingBottom: 400 }}
        style={{ paddingTop: DEFAULT_SPACING, paddingHorizontal: DEFAULT_SPACING }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {map(books, (book) => <VerticalOptionItem {...book} navigation={navigation} key={book.title} />)}
        </View>

        <Text style={styles.helpText}>Books free for a limited time only.</Text>

        <Text style={{ marginTop: DEFAULT_SPACING, fontWeight: '600', fontSize: 18, color: darkblue }}>
          Resources
        </Text>

        <Text style={{ marginTop: '3%', fontWeight: '300', fontSize: 16, color: darkblue }}>
          {titles[0].title}
        </Text>

        <View style={{ marginTop: DEFAULT_SPACING, justifyContent: 'space-around' }}>
          <HorizontalOptionItem
            image={resources[0].image}
            pdf={resources[0].pdf}
            title={resources[0].title}
            description={resources[0].description}
            navigation={navigation}
          />

          <HorizontalQuizOptionItem
            image={resources[0].image}
            title={titles[0].title}
            navigation={navigation}
          />
        </View>

        <Text style={{ marginTop: '3%', fontWeight: '300', fontSize: 16, color: darkblue }}>
          {titles[1].title}
        </Text>

        <View style={{ marginTop: DEFAULT_SPACING, justifyContent: 'space-around' }}>
          <HorizontalOptionItem
            image={resources[1].image}
            pdf={resources[1].pdf}
            title={resources[1].title}
            description={resources[1].description}
            navigation={navigation}
          />

          <HorizontalQuizOptionItem
            image={resources[1].image}
            title={titles[1].title}
            navigation={navigation}
          />
        </View>
      </ScrollView>
    </View>
  );
}
