import { get } from 'http'
import { SET_WELLNESS_CHECKLISTS } from './setWellnessChecklist'

export const getWellnessChecklists = function () {
  return async (dispatch) => {
    try {
      const result = await get('api/financial-wellness-checklist/?page_size=100')

      dispatch({
        type: SET_WELLNESS_CHECKLISTS,
        payload: result.data
      })
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
