import { StyleSheet, ScrollView, View, useWindowDimensions, Platform } from 'react-native'

import { Table, Row, Col, Cell, TableWrapper } from 'react-native-table-component'

import { map } from 'lodash'
import { rgba, white, info } from '../../styles'

const styles = StyleSheet.create({
  wrapper: { flexDirection: 'row' },
  row: { height: 50 },
  title: {
    fontWeight: '600',
    fontSize: 12,
    color: 'rgb(40, 58, 73)',
    letterSpacing: 0,
    paddingVertical: 9,
    paddingHorizontal: 9
  },
  text: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    color: 'rgb(40, 58, 73)',
    letterSpacing: -0.5
  },
  tableBorder: {
    borderLeftWidth: 0.5,
    borderBottomWidth: 0.5,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderColor: 'rgb(221, 228, 230)'
  },
  even: {
    backgroundColor: white,
    borderColor: 'rgb(221, 228, 230)',
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0.5
  },
  odd: {
    backgroundColor: '#F8FBFC',
    borderColor: 'rgb(221, 228, 230)',
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0.5
  }
})

function calculateRow(value, formatter, suffix, prefix) {
  return (prefix || '') + (formatter ? formatter(value) : value) + (suffix || '')
}

function OutcomeTable({
  labels,
  data,
  prefixes = {},
  suffixes = {},
  formatters = {},
  scrollable,
  highlightedColumn,
  highlightedColor
}) {
  const isNotWeb = Platform.OS !== 'web'
  const width = useWindowDimensions().width / 4

  const tableData = [
    ...map(Object.keys(labels), (key) =>
      map(data, (row) => calculateRow(row[key], formatters[key], suffixes[key], prefixes[key]))
    )
  ]

  const tableTitle = Object.values(labels)

  const tableConfig = {
    tableTitle,
    tableData
  }

  const height = 50
  // needs to be less by .5 because of the top border applied to the cell
  const headerHeight = 49.5

  return (
    <Table>
      <TableWrapper style={[styles.wrapper]}>
        <Table
          style={{ width: isNotWeb ? width * 2 : '38%', marginTop: -0.5 }}
          borderStyle={styles.tableBorder}
        >
          {map(tableConfig.tableTitle, (title, index) => (
            <Col
              data={[title]}
              heightArr={[headerHeight]}
              textStyle={styles.title}
              style={index == 0 || index % 2 ? styles.even : styles.odd}
            />
          ))}
        </Table>

        <ScrollView scrollEnabled={scrollable} horizontal contentContainerStyle={{ width: '100%' }}>
          <Table style={{ flex: 1 }}>
            {map(tableConfig.tableData, (data, index) => (
              <View
                style={[
                  { flexDirection: 'row', width: isNotWeb ? width * data.length : '100%', height }
                ]}
              >
                {map(data, (rowData, rowIndex) => {
                  let backgroundColor = index == 0 || index % 2 ? white : '#F8FBFC'

                  if (rowIndex == highlightedColumn) {
                    backgroundColor =
                      index == 0 || index % 2
                        ? rgba(highlightedColor || info, 0.1)
                        : rgba(highlightedColor || info, 0.13)
                  }

                  return (
                    <Cell
                      key={rowIndex}
                      data={rowData}
                      width={isNotWeb ? width : '100%'}
                      height={height}
                      textStyle={[styles.text]}
                      style={[styles.tableBorder, { backgroundColor }]}
                    />
                  )
                })}
              </View>
            ))}
          </Table>
        </ScrollView>
      </TableWrapper>
    </Table>
  )
}

export default OutcomeTable
