// React
import { useEffect, useLayoutEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import Table from './Table'

import { info, primary, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

import formatCurrency from '../../functions/formatCurrency'
import formatPercent from '../../functions/formatPercent'

const styles = StyleSheet.create({
  resultsContainer: {
    padding: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING * 5,
    backgroundColor: primary,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    textAlign: 'center',
    fontSize: 14,
    color: white
  },
  moneyContainer: {
    backgroundColor: info,
    borderRadius: 12,
    paddingHorizontal: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING
  },
  moneyText: {
    textAlign: 'center',
    fontSize: 42,
    color: white,
    fontWeight: 'bold'
  },
})

export default function CarTable({ result, bestChoice, width = 0 }) {
  let assetA = result?.asset_a || {}
  let assetB = result?.asset_b || {}
  const paymentComparison = result?.result?.payment_comparison || {}
  const propertyCostComparison = result?.result?.property_cost_comparison || {}

  assetA = { ...assetA, ...(paymentComparison?.asset_a || {}), ...(propertyCostComparison.asset_a || {}) }
  assetB = { ...assetB, ...(paymentComparison?.asset_b || {}), ...(propertyCostComparison.asset_b || {}) }

  const assetAName = assetA.name || 'Car A'
  const assetBName = assetB.name || 'Car B'

  const labels = {
    asset_price: 'Value',
    down_payment: 'Down Payment',
    amount: 'Loan Amount',
    interest_rate: 'Interest Rate',
    months: 'Terms of Financing',
    depreciation_rate: 'Rate of Depreciation',
    interest: 'Interest',
    principal: 'Principal',
    total: 'Total Payment'
  }
  const formatters = {
    asset_price: formatCurrency,
    down_payment: formatCurrency,
    amount: formatCurrency,
    interest_rate: formatPercent,
    months: null,
    depreciation_rate: formatPercent,
    interest: formatCurrency,
    principal: formatCurrency,
    total: formatCurrency
  }

  const prefixes = {
    asset_price: '$',
    down_payment: '$',
    amount: '$',
    interest_rate: null,
    months: null,
    depreciation_rate: null,
    interest: '$',
    principal: '$',
    total: '$'
  }

  const suffixes = {
    asset_price: null,
    down_payment: null,
    amount: null,
    interest_rate: null,
    months: 'mo.',
    interest: null,
    principal: null,
    total: null
  }

  return (
    <Table
      labels={labels}
      highlightedColumn={bestChoice == 'A' ? 0 : 1}
      data={[assetA, assetB]}
      formatters={formatters}
      prefixes={prefixes}
      suffixes={suffixes}
      assetLabels={[assetAName, assetBName]}
      width={width}
    />
  );
}
