import { useEffect, useState } from 'react'
import { View, StyleSheet, Text, Platform } from 'react-native'

import { map } from 'lodash'

import { useSelector, useDispatch } from 'react-redux'

import { get } from 'http'

import { useIsFocused, useNavigation } from '@react-navigation/native'

import {
  wellnessChecklistsSelector,
  completedWellnessChecklistsSelector,
  currentUserSelector,
  accountsSelector,
  primaryProPlanSelector
} from '../selectors'

import Divider from './Divider'
import Shield from './Shield'

import { setCurrentPlan, setCurrentProPlan } from '../actions'

const styles = StyleSheet.create({
  container: { position: 'relative' },
  shieldContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 30
  },
  divider: {
    position: 'absolute',
    top: 40,
    left: 0,
    right: 0,
    zIndex: -1
  }
})

export default function ShieldContainer() {
  const [planAdequacy, setPlanAdequacy] = useState({})
  const [emergencyPrepardness, setEmergencypreparedness] = useState({})
  const [probabilityOfSuccess, setProbabilityOfSuccess] = useState(0)

  const navigation = useNavigation()
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => currentUserSelector(state))
  const isFocused = useIsFocused()

  const wellnessItems = useSelector((state) =>
    wellnessChecklistsSelector(state)
  )
  const completedItems = useSelector((state) =>
    completedWellnessChecklistsSelector(state)
  )
  const currentAccounts = useSelector((state) => accountsSelector(state))

  const primaryProPlan = useSelector(primaryProPlanSelector)

  const isFake = currentUser.is_fake
  const isWeb = Platform.OS === 'web'

  async function fetchPrimaryPlan() {
    if (!currentUser) return
    if (isFake) return
    if (!currentUser.primary_plan) return
    if (!currentUser.primary_plan.id) return

    try {
      const primaryPlanResponse = await get(
        `/api/v1/plans/${currentUser.primary_plan.id}/`
      )
      setPlanAdequacy(primaryPlanResponse.data.plan_adequacy)
      setProbabilityOfSuccess(primaryPlanResponse.data.probability_of_success)

      const emergencyPreparednessResponse = await get(
        '/emergency-preparedness/'
      )
      setEmergencypreparedness(emergencyPreparednessResponse.data)
    } catch (e) {
      console.warn(e, e.response)
    }
  }

  useEffect(() => {
    if (isFocused) fetchPrimaryPlan()
  }, [isFocused])

  const { shieldScores = [] } = primaryProPlan
  const likelihood = shieldScores.find((score) => score.type === 'LIKELIHOOD')
  const prepardness = shieldScores.find(
    (score) => score.type === 'PREPAREDNESS'
  )
  const adequacy = shieldScores.find((score) => score.type === 'ADEQUACY')
  const ontrack = shieldScores.find((score) => score.type === 'ONTRACK')

  const likelihoodScore = likelihood?.score || 0
  const prepardnessScore = prepardness?.score || 0
  const adequacyScore = adequacy?.score || 0
  const ontrackScore = ontrack?.score || 0

  const shields = [
    {
      key: 'plan-shield',
      label: 'Plan',
      value: !isFake && primaryProPlan ? 100 : 0,
      tooltipHeader: 'Plan Shield',
      tooltip:
        'The Plan Shield is the easiest to earn but requires the most discipline to follow. Create a PRO Plan to earn yours.',
      shieldIndex: !isFake && primaryProPlan ? 2 : 0,
      shields: [
        require('../assets/shields/plan-shield-disabled.png'),
        require('../assets/shields/plan-shield-in-progress.png'),
        require('../assets/shields/plan-shield-default.png')
      ]
    },
    {
      key: 'likelihood-shield',
      label: 'Likelihood',
      value: !isFake && likelihoodScore ? likelihoodScore : 0,
      tooltipHeader: 'Likelihood Shield',
      tooltip:
        'The Likelihood Shield is earned when you have a high chance of achieving your financial goal, based on a forecast of your net wealth.',
      shieldIndex: isFake
        ? 0
        : likelihoodScore < 50
        ? 0
        : likelihoodScore <= 70
        ? 1
        : 2,
      shields: [
        require('../assets/shields/likelihood-shield-disabled.png'),
        require('../assets/shields/likelihood-shield-in-progress.png'),
        require('../assets/shields/likelihood-shield-default.png')
      ]
    },
    {
      key: 'adequacy-shield',
      label: 'Adequacy',
      value: !isFake && adequacyScore ? adequacyScore : 0,
      tooltipHeader: 'Adequacy Shield',
      tooltip:
        'The Adequacy Shield is earned when your plan answers "Yes" to the question, "Is the financial goal I set for myself sufficient to cover my expenses after achieving financial freedom?"',
      shieldIndex: isFake
        ? 0
        : adequacyScore < 50
        ? 0
        : adequacyScore <= 70
        ? 1
        : 2,
      shields: [
        require('../assets/shields/adequecy-shield-disabled.png'),
        require('../assets/shields/adequecy-shield-in-progress.png'),
        require('../assets/shields/adequecy-shield-default.png')
      ]
    },
    {
      key: 'emergency-shield',
      label: 'Emergency',
      value: !isFake && prepardnessScore ? prepardnessScore : 0,
      tooltipHeader: 'Emergency Shield',
      tooltip:
        'The Emergency Shield is earned when your net short-term assets are sufficient to cover your projected non-discretionary spending over a blended 3, 6, and 12 month time period.',
      shieldIndex: isFake
        ? 0
        : prepardnessScore < 50
        ? 0
        : prepardnessScore <= 70
        ? 1
        : 2,
      shields: [
        require('../assets/shields/emergency-shield-disabled.png'),
        require('../assets/shields/emergency-shield-in-progress.png'),
        require('../assets/shields/emergency-shield-default.png')
      ]
    },
    {
      key: 'on-track-shield',
      label: 'On Track',
      value: !isFake ? ontrackScore : 0,
      tooltipHeader: 'On Track Shield',
      tooltip:
        'The On Track Shield is earned if you are on pace to reach your financial goal, relative to your prior forecast.',
      shieldIndex: isFake
        ? 0
        : ontrackScore < 50
        ? 0
        : ontrackScore <= 70
        ? 1
        : 2,
      shields: [
        require('../assets/shields/on-track-shield-disabled.png'),
        require('../assets/shields/on-track-shield-in-progress.png'),
        require('../assets/shields/on-track-shield-default.png')
      ]
    }
  ]

  return (
    <View style={styles.container}>
      <View style={styles.shieldContainer}>
        {map(shields, (shield, index) => (
          <View style={{ flexDirection: 'column' }}>
            <Shield {...shield} />
          </View>
        ))}
      </View>

      <Divider style={styles.divider} />
    </View>
  )
}
