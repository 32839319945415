import { useState } from 'react'
import { FlatList, Text, View, StyleSheet, useWindowDimensions, } from 'react-native'
import PropTypes from 'prop-types'
import { ListItem } from 'react-native-elements'
import { rgba, darkblue, primary, red, grayblue, DEFAULT_SPACING, lightestgray } from '../styles'
import formatCurrency from '../functions/formatCurrency'
import CircleIcon from './CircleIcon'
import { ASSET } from '../constants'
import SlideupDrawer from './SlideupDrawer'
import Link from './Link'
import { DraxProvider, DraxList } from 'react-native-drax'

const styles = StyleSheet.create({
  listContainerStyle: (compressed) => {
    const style = {
      borderRadius: 0,
      flexDirection: 'row',
      alignItems: 'center'
    }

    if (compressed) {
      style.borderBottomWidth = StyleSheet.hairlineWidth
      style.borderBottomColor = lightestgray
    } else {
      style.marginBottom = DEFAULT_SPACING
    }

    return style
  },
  circleIconStyle: { marginRight: DEFAULT_SPACING },
  detailsContainer: { flex: 2 },
  itemName: { fontWeight: '600', marginBottom: 3 },
  itemTotal: { flexDirection: 'row' },
  total: { color: grayblue, fontWeight: '500' },
  totalAmount: (color) => ({ color, fontWeight: '500' })
})

export default function AccountList({ 
  list, 
  onEditAccount, 
  onDeleteAccount, 
  onShowAccount,
  hideIcon, 
  compressed,
  draggable,
  loading,
  onDragEnd
}) {
  const window = useWindowDimensions()
  const marginTop = window.height - 400
  const [selectedAccount, setSelectedAccount] = useState()

  const showOptions = () => (!!selectedAccount)

  const onOptionsPress = (account) => () => {
    setSelectedAccount(account)
  }

  const clearSelectedAccount = () => setSelectedAccount(null)

  const onEditAccountPress = () => {
    onEditAccount(selectedAccount.accountId)
    clearSelectedAccount()
  }

  const onDeleteAccountPress = () => {
    onDeleteAccount(selectedAccount.accountId)
    clearSelectedAccount()
  }

  const renderItem = ({ item, drag }) => {
    const color = item.accountType === ASSET ? primary : red

    return (
      <ListItem 
        onPress={() => onShowAccount(item)} 
        onLongPress={drag} 
        containerStyle={styles.listContainerStyle(compressed)}
      >
        {
          !hideIcon && (
            <CircleIcon
              size={20}
              icon={item.accountType === ASSET ? 'dollar-sign' : 'hand-holding-dollar'}
              color={color}
              backgroundColor={rgba(color, 0.1)}
              style={styles.circleIconStyle}
            />
          )
        }

        <View style={styles.detailsContainer}>
          <Text testID="accountName" style={styles.itemName}>
            { loading ? '...' : item.name}
          </Text>
          <View style={styles.itemTotal}>
            <Text style={styles.total}>
              Total:
            </Text>
            <Text testID="accountValue" style={styles.totalAmount(color)}>
              { loading ? '...' : `$${formatCurrency(item.balance)}`}
            </Text>
          </View>
        </View>
      </ListItem>
    )
  }

  const Container = (draggable) ? DraxProvider : View
  const FlatListView = (draggable) ? DraxList : FlatList

  function handleReorder({ fromIndex, toIndex }) {
    onDragEnd(fromIndex, toIndex)
  } 

  return (
    <Container>
      <FlatListView
        keyExtractor={(_, index) => index.toString()}
        data={list}
        renderItem={!draggable ? renderItem : undefined}
        renderItemContent={draggable ? renderItem : undefined}
        onItemReorder={draggable ? handleReorder : undefined}
        snapbackDuration={200}
      />
      {
        (onEditAccount || onDeleteAccount) && (
          <SlideupDrawer
            title={selectedAccount && selectedAccount.name}
            visible={showOptions()}
            setVisible={clearSelectedAccount}
            marginTop={marginTop}
          >
            <View style={{ alignItems: 'flex-start', marginHorizontal: DEFAULT_SPACING }}>
              {
                onEditAccount && (
                  <Link
                    iconStyle={{ color: primary, marginVertical: DEFAULT_SPACING }}
                    style={{ color: darkblue, fontSize: 18, fontWeight: '500' }}
                    icon="pencil"
                    type="fas"
                    onPress={onEditAccountPress}
                  >
                    Edit
                  </Link>
                )
              }
              {
                onDeleteAccount && (
                  <Link
                    iconStyle={{ color: red, marginVertical: DEFAULT_SPACING }}
                    style={{ color: red, fontSize: 18, fontWeight: '500' }}
                    icon="trash"
                    type="fas"
                    onPress={onDeleteAccountPress}
                  >
                    Delete
                  </Link>
                )
              }
            </View>
          </SlideupDrawer>
        )
      }
    </Container>
  )
}

AccountList.defaultProps = {
  hideIcon: false,
  compressed: false,
  draggable: false,
  loading: false,
  onEditAccount: undefined,
  onDeleteAccount: undefined,
  onShowAccount: undefined,
  onDragEnd: undefined
}

AccountList.propTypes = {
  list: PropTypes.array.isRequired,
  onEditAccount: PropTypes.func,
  onDeleteAccount: PropTypes.func,
  onShowAccount: PropTypes.func.isRequired,
  hideIcon: PropTypes.bool,
  compressed: PropTypes.bool,
  loading: PropTypes.bool,
  draggable: PropTypes.bool,
  onDragEnd: PropTypes.func
}
