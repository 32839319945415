import { useLayoutEffect } from 'react'
import { reduce } from 'lodash'
import { StyleSheet, Text, View } from 'react-native'
import TooltippedIcon from './components/TooltippedIcon'
import { NAVIGATION_TYPE, SHORT_TERM_BS_ACCOUNT_TYPE } from './constants'
import { black, DEFAULT_SPACING, gunmetal, info, lightgray, white } from 'styles'
import YoutubePlayer from 'components/YoutubePlayer'
import HeaderLabel from 'components/HeaderLabel'
import BottomButton from 'components/BottomButton'

/* Styling */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white
  },
  bodyContainer: {
    flex: 1,
    paddingHorizontal: DEFAULT_SPACING,
  },
  header: {
    fontSize: 24,
    marginVertical: DEFAULT_SPACING * 4
  },
  text: {
    fontSize: 16,
    color: gunmetal,
  }
})

export default function CashWaterfallIntroScreen({ navigation, route }) {

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'What is Cash Flow Waterfall?'
    })
  }, [navigation])

  function handlePress() {
    navigation.navigate('Modal', { screen: 'Cash Flow Waterfall' })
  }

  return (
    <View style={styles.container}>
      <YoutubePlayer videoId="4hnynxiPjaw" />
      <View style={styles.bodyContainer}>
        <HeaderLabel style={styles.header}>Financial Institution Quality -- for you!</HeaderLabel>
        <Text style={styles.text}>
          The Cash Flow Waterfall is a strategic approach to managing extra cash, managing debt, 
          and better understanding your daily spending. Think of a Cash Flow Waterfall as a 
          strategic plan for your money. The term "waterfall" is used because it illustrates 
          the idea of money flowing down through a sequence of steps, just like water flows 
          down a series of steps in a real waterfall. Ultimately, the Cash Flow Waterfall 
          helps individuals make informed financial decisions, and effectively balance 
          short-term needs and long-term financial stability.
        </Text>
        <BottomButton onPress={handlePress} withMargin={true}>
          Continue
        </BottomButton>
      </View>
    </View>
  )
}

CashWaterfallIntroScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }

