import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_MAX,
  INVALID_MIN
} from './constants'

export const Fields = {
  name: 'name',
  amount: 'amount'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.name]: Yup.string()
    .nullable()
    .required(REQUIRED),
  [Fields.amount]: Yup.number()
    .min(0, INVALID_MIN)
    .max(100000, `${INVALID_MAX}$100,000`)
    .nullable()
    .required(REQUIRED)
})

export const InitialValues = {
  [Fields.name]: '',
  [Fields.amount]: ''
}
