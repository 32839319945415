import { StyleSheet, View, Text, Dimensions } from 'react-native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { LinearGradient } from 'expo-linear-gradient'
import HeaderLabel from '../HeaderLabel'
import Card from '../Card'
import ProgressBar from '../ProgressBar'

import { secondary } from '../../styles/colors'
import { DEFAULT_SPACING, white, rgba } from '../../styles'
import TouchableOpacity from '../TouchableOpacity'

import capitalize from '../../functions/capitalize'

const styles = StyleSheet.create({
  container: {
    marginHorizontal: DEFAULT_SPACING / 2,

    marginTop: DEFAULT_SPACING,
    width: '42%'
  },
  card: {
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING * 4,
    height: 220,
    margin: 10
  },
  headerLabel: {
    marginVertical: DEFAULT_SPACING,
    marginBottom: 10,
    fontSize: 15,
    position: 'relative',
    color: white
  },
  iconContainer: {
    alignItems: 'center',
    position: 'relative'
  },
  progressContainer: {
    position: 'absolute',
    bottom: 5,
    margin: 0,
    justifyContent: 'center',
    left: 5,
    right: 5
  },
  progressBarContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  progressText: {
    fontSize: 12,

    marginTop: 5,
    color: white
  },
  linearGradient: {
    width: '100%',

    position: 'absolute',
    zIndex: 0,
    height: '100%',
    borderRadius: 12
  },
})

export default function CategoryButton({ onPress, icon, description, color, backgroundColorGradientTop = white, backgroundColorGradientBottom = white, checklist, progressBarColor = white, progressBarBackgroundColor = rgba(white, 0.3) }) {

  const total = checklist.length
  const completed = checklist.filter(item => item.is_complete).length
  

  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <LinearGradient
        colors={[backgroundColorGradientTop, backgroundColorGradientBottom]}
        style={[styles.linearGradient]}
        locations={[0, 1]}
      />
      <Card style={[styles.card, { backgroundColor: 'transparent' }]}>
        <View style={styles.iconContainer}>
          <FontAwesomeIcon
            icon={['fas', icon]}
            color={rgba(white, 0.6)}

            size={70}
          />
        </View>
        <View style={styles.progressContainer}>
          <HeaderLabel style={styles.headerLabel}>
            {capitalize(description)}
          </HeaderLabel>
          <View style={styles.progressBarContainer}>
            <ProgressBar
              width="100%"
              percent={(completed / total) * 100}
              progressBarColor={progressBarColor}
              backgroundBarColor={progressBarBackgroundColor}

            />
          </View>
          <View>
            <Text style={styles.progressText}>
              {completed}
              {' '}
              of 
              {' '}
              {total}
              {' '}
              Completed
            </Text>
          </View>
        </View>
      </Card>
    </TouchableOpacity>
  )
}
