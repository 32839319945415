import { useEffect, useLayoutEffect, useState } from 'react'
import {
  ActivityIndicator,
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'http'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faBarsFilter } from '@fortawesome/pro-regular-svg-icons'
import { NOTIFICATION_BASE_URL } from '@env'
import { notificationsSelector } from './selectors'
import { DEFAULT_SPACING, darkblue, white } from './styles'
import NotificationListItem from './components/notification/NotificationListItem'
import { isToday } from './functions/notification'
import FilterOptionsSlideupDrawer from './components/notification/FilterOptionsSlideupDrawer'
import NotificationOptions from './components/notification/NotificationOptions'
import { getNotifications, setNotifications } from './actions'

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Messages', value: 'messages' },
  { label: 'Insights', value: 'insights' },
  { label: 'Badges', value: 'badges' },
  { label: 'Streaks', value: 'streaks' }
]

const styles = StyleSheet.create({
  container: { backgroundColor: white, height: '100%' },
  subTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: darkblue,
    paddingHorizontal: 15,
    paddingTop: 24,
    paddingBottom: 16
  },
  emptyContainer: {
    paddingHorizontal: 35,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center'
  },
  emptyImage: {
    width: '100%',
    height: 211
  },
  emptyTitle: {
    marginTop: 39,
    fontSize: 24,
    fontWeight: '700',
    color: darkblue
  },
  emptyDescription: {
    color: '#707A7D',
    textAlign: 'center',
    paddingHorizontal: 8,
    marginTop: 14
  }
})

function Loading() {
  return (
    <View style={{ paddingVertical: 20 }}>
      <ActivityIndicator animating size="large" />
    </View>
  )
}

function Empty() {
  return (
    <View style={styles.emptyContainer}>
      <Image
        source={require('./assets/notification-empty.png')}
        style={styles.emptyImage}
      />
      <Text style={styles.emptyTitle}>You're all caught up! 🌟</Text>
      <Text style={styles.emptyDescription}>
        At the moment, there are no new notifications for you. Meanwhile, feel
        free to explore other sections of our app or take a moment to relax.
        We'll make sure you don't miss anything important!
      </Text>
    </View>
  )
}

function NotificationsScreen({ navigation }) {
  const notifications = useSelector((state) => notificationsSelector(state))
  const [visibleFilterOptions, setVisibleFilterOptions] = useState(false)
  const [activeNotification, setActiveNotification] = useState()
  const [visibleNotificationMenu, setVisibleNotificationMenu] = useState(false)
  const [sortBy, setSortBy] = useState(filterOptions[0].value)
  const [filteredNotificationArray, setFilteredNotificationArray] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isFetchedAll, setIsFetchedAll] = useState(false)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity onPress={() => setVisibleFilterOptions(true)}>
          <FontAwesomeIcon
            icon={faBarsFilter}
            size={24}
            color={darkblue}
            style={{ marginRight: DEFAULT_SPACING }}
          />
        </TouchableOpacity>
      )
    })
  }, [navigation])

  const fetchNotification = async () => {
    setIsLoading(true)
    await dispatch(getNotifications())
    setIsLoading(false)
  }
  useEffect(() => {
    fetchNotification()
  }, [])

  const getFilteredNotifications = (notificationArr) => {
    const array = JSON.parse(JSON.stringify(notificationArr))
    let idx = array.findIndex((n) => isToday(n.createdAt))
    if (idx > -1) array[idx].isToday = true
    idx = array.findIndex((n) => !isToday(n.createdAt))
    if (idx > -1) array[idx].isOlder = true
    setFilteredNotificationArray(array)
  }

  useEffect(() => {
    getFilteredNotifications(notifications)
  }, [notifications])

  const handlePress = async (notification) => {
    navigation.navigate('Notification Details', { data: notification })
  }

  const handleMenuPress = (notification) => {
    setActiveNotification(notification)
  }
  const hideNotification = () => {
    setActiveNotification(null)
  }

  const turnOffNotification = () => {
    setActiveNotification(null)
  }

  useEffect(() => {
    setVisibleNotificationMenu(!!activeNotification)
  }, [activeNotification])

  const handleFilter = (value) => {
    setVisibleFilterOptions(false)
    setSortBy(value)
    getFilteredNotifications(
      notifications.filter((n) =>
        value !== 'all' ? n.filterType.toLowerCase() === value : true
      )
    )
  }

  const fetchData = async (previewNotifications) => {
    if (previewNotifications.length >= 10 && !isLoading && !isFetchedAll) {
      const lastNotificationId =
        notifications.length > 0
          ? notifications[notifications.length - 1].notificationId
          : null
      setIsLoading(true)
      const res = await get(
        `${NOTIFICATION_BASE_URL}/user/notifications${
          lastNotificationId
            ? `?last_notification_id=${lastNotificationId}`
            : ''
        }`
      )
      if (res.data.data.length < 10) setIsFetchedAll(true)
      else {
        dispatch(
          setNotifications(
            notifications.length > 0
              ? [...notifications, ...res.data.data]
              : res.data.data
          )
        )
      }
      setIsLoading(false)
    }
  }

  const renderItem = ({ item }) => (
    <>
      {(item.isToday || item.isOlder) && (
        <Text style={styles.subTitle}>{item.isToday ? 'Today' : 'Older'}</Text>
      )}
      <NotificationListItem
        data={item}
        onPress={() => handlePress(item)}
        onMenuPress={() => handleMenuPress(item)}
      />
    </>
  )

  return (
    <View style={styles.container}>
      {filteredNotificationArray.length > 0 && (
        <FlatList
          data={filteredNotificationArray}
          renderItem={renderItem}
          keyExtractor={(item) => item.notificationId}
          onEndReached={() => fetchData(notifications)}
          onEndReachedThreshold={0.5}
          refreshing={isLoading}
          onRefresh={fetchNotification}
        />
      )}
      {isLoading && <Loading />}
      {filteredNotificationArray.length === 0 && !isLoading && <Empty />}
      <FilterOptionsSlideupDrawer
        options={filterOptions}
        selected={sortBy}
        visible={visibleFilterOptions}
        setVisible={setVisibleFilterOptions}
        onPress={(value) => handleFilter(value)}
      />
      <NotificationOptions
        visible={visibleNotificationMenu}
        setVisible={() => {
          setActiveNotification(null)
        }}
        hideNotification={hideNotification}
        turnOffNotification={turnOffNotification}
      />
    </View>
  )
}

export default NotificationsScreen
