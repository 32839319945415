import { Text, View, StyleSheet, TouchableOpacity } from 'react-native'
import { primary, danger, rgba } from '../styles/colors'

const styles = StyleSheet.create({
  pill: {
    backgroundColor: rgba(primary, 0.1),
    borderRadius: 10
  },
  text: {
    fontSize: 12,
    color: primary,
    textAlign: 'center',
    letterSpacing: -0.5,
    fontSize: 14,
    color: primary,
    paddingHorizontal: 15,
    paddingVertical: 3
  }
})

export default function Pill({ children, small, square, style, textStyle, color, onPress }) {
  const containerStyles = [styles.pill]
  if (color) { containerStyles.push({ backgroundColor: rgba(color, 0.1) }) }
  containerStyles.push(style)

  const textStyles = [styles.text]
  if (color) { textStyles.push({ color }) }
  textStyles.push(textStyle)

  if (small) { textStyles.push({ fontSize: 12, paddingHorizontal: 10 }) }
  if (square) { 
    containerStyles.push({ borderRadius: 5, padding: 0 }) 
    textStyles.push({ paddingHorizontal: 6, fontWeight: '600'  })
  }

  const Container = onPress ? TouchableOpacity : View

  return (
    <Container onPress={onPress} style={containerStyles}>
      <Text style={textStyles}>
        {children}
      </Text>
    </Container>
  )
}
