import promiseMiddlware from 'redux-promise-middleware'
import thunkMiddleware from 'redux-thunk'
import asyncAwaitMiddleware from 'redux-async-await'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = () => require('../rootReducer').default; // eslint-disable-line

const createProductionStore = (middleware) => createStore(rootReducer(), applyMiddleware(...middleware));

const createDevStore = (middleware) => {
  const store = createStore(
    rootReducer(),
    composeWithDevTools(applyMiddleware(...middleware)),
  );

  if (module.hot) {
    // Enable hot module replacement for reducers
    module.hot.accept(() => {
      store.replaceReducer(rootReducer());
    });
  }

  return store;
};

export default () => {
  const middleware = [thunkMiddleware, promiseMiddlware, asyncAwaitMiddleware];

  return __DEV__ // eslint-disable-line
    ? createDevStore(middleware)
    : createProductionStore(middleware);
};
