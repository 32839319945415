import { each, map, filter } from 'lodash'
import { useState, useEffect } from 'react'
import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from 'react-native'
import { Row, Table, TableWrapper } from 'react-native-table-component'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import formatCurrency from '../functions/formatCurrency'
import { black, DEFAULT_SPACING, red, white } from '../styles'
import Link from './Link'
import { financialProjectionsSelector, currentUserSelector, budgetCategoriesSelector } from '../selectors'
import DateSliderRule from './DateSliderRule'
import { get } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { INCOME_TYPE } from '../constants/budgetItemConstants'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white
  },
  wrapper: { flexDirection: 'row' },
  row: { 
    height: 50,
    borderWidth: StyleSheet.hairlineWidth, 
    borderColor: '#F1F6F7'
  },
  text: {
    paddingVertical: 9,
    fontSize: 12,
    color: black,
    letterSpacing: -0.5,
    marginLeft: DEFAULT_SPACING,
  },
  boldedText: { fontWeight: '500' },
  expandableElement: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  indentedText: { paddingLeft: DEFAULT_SPACING },
  doubleIndentedText: { paddingLeft: DEFAULT_SPACING * 2 },
  indentedBoldText: {
    paddingLeft: DEFAULT_SPACING,
    fontWeight: '500'
  },
  surplusText: { color: '#41BF75' },
  deficitText: { color: red },
  activityIndicator: {
    position: 'absolute', 
    left: 0, 
    right: 0, 
    top: 0, 
    bottom: 0
  }
})

function getExpandableElement(text, childrenExist, style, expanded, setExpanded) {
  return (
    <View style={[styles.expandableElement]}>
      {childrenExist ? (
        <Link
          style={[styles.text, style]}
          icon={expanded ? 'caret-up' : 'caret-down'}
          type="fas"
          iconSize={12}
          iconRight
          iconColor={black}
          onPress={() => setExpanded(!expanded)}
        >
          {text}
        </Link>
      ) : (
        <Text style={[styles.text, style]}>{ text }</Text>        
      )}
    </View>
  )
}

function formatSurplusDeficit(income, expense) {
  const diff = income - expense
  if (diff > 0) {
    return (
      <Text style={[styles.text, styles.surplusText]}>
        +$
        {formatCurrency(diff)}
      </Text>
    )
  } if (diff === 0) {
    return (
      <Text style={styles.text}>
        $
        {formatCurrency(diff)}
      </Text>
    )
  }
  return (
    <Text style={[styles.text, styles.deficitText]}>
      -$
      {formatCurrency(Math.abs(diff))}
    </Text>
  )
}

function BudgetSubCategoryRow({ subCategory, budgetTrendSubCategories }) {
  const { label, key } = subCategory
  const budgetSubCategory = budgetTrendSubCategories.find((subCategory) => subCategory.budgetSubCategory === key)
  const { actual, projected } = budgetSubCategory

  return (
    <TableWrapper>
      <Row
        flexArr={[3, 1, 1]}
        data={[
          getExpandableElement(label, false, styles.doubleIndentedText),
          formatCurrency(projected, { showDollarSign: true }), 
          formatCurrency(actual, { showDollarSign: true })
        ]}
        style={styles.row}
        textStyle={styles.text}
      />
    </TableWrapper>      
  )
}

function BudgetCategoryRow({ category, budgetTrend }) {
  const [expanded, setExpanded] = useState(false)
  const { key, label, subCategories } = category
  const budgetTrendSubCategories = budgetTrend[key] || []

  const actual = budgetTrendSubCategories.reduce((acc, subcategory) => {
    return acc + subcategory.actual
  }, 0)

  const projected = budgetTrendSubCategories.reduce((acc, subcategory) => {
    return acc + subcategory.projected
  }, 0)

  return (
    <TableWrapper>
      <Row
        flexArr={[3, 1, 1]}
        data={[
          getExpandableElement(label, subCategories.length > 0, [styles.indentedText, styles.indentedBoldText], expanded, setExpanded),
          formatCurrency(projected, { showDollarSign: true }), 
          formatCurrency(actual, { showDollarSign: true })
        ]}
        style={styles.row}
        textStyle={styles.text}
      />

      { expanded ? (
        subCategories.length > 0 && (
          subCategories.map((subCategory) => (
            <BudgetSubCategoryRow 
              subCategory={subCategory} 
              budgetTrendSubCategories={budgetTrendSubCategories}
            />
          ))
        )     
      ) : <></>}
    </TableWrapper>    
  )
}


function calculateTotals(budgetTrent, incomeKeys) {
  let totalActualIncome = 0, totalProjectedIncome = 0;
  let totalActualExpenses = 0, totalProjectedExpenses = 0;
  
  Object.keys(budgetTrent).forEach(key => {
    const valueArray = budgetTrent[key];
    for (let i = 0; i < valueArray.length; i++) {
      if (incomeKeys.includes(key)) {
        totalActualIncome += valueArray[i].actual;
        totalProjectedIncome += valueArray[i].projected;
      } else { // expenses
        totalActualExpenses += valueArray[i].actual;
        totalProjectedExpenses += valueArray[i].projected;
      }
    }
  });
  
  return {
    totalActualIncome,
    totalProjectedIncome,
    totalActualExpenses,
    totalProjectedExpenses
  };
}


function BudgetTrendsTable({ planId }) {
  const today = moment()
  const [selectedDate, setSelectedDate] = useState(today)
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)
  const monthYearFormat = (date) => moment(date).format('MMM YYYY')

  const onDatePicked = (pickedDate) => {
    const newSelectedDate = moment(pickedDate)
    setSelectedDate(newSelectedDate)
  }

  const budgetCategories = useSelector(budgetCategoriesSelector)

  const projections = useSelector(financialProjectionsSelector)
  const filteredProjects = filter(projections, (projection) => (
    moment(projection.date).isSameOrAfter(moment(), 'month')
  ))

  const projectionDates = map(filteredProjects, (projection) => ({
    id: monthYearFormat(projection.date),
    title: monthYearFormat(projection.date),
    date: projection.date
  }))

  const currentUser = useSelector(currentUserSelector)
  const { id } = currentUser

  const [loading, setLoading] = useState(false)
  const [budgetTrend, setBudgetTrend] = useState({})

  const { 
    totalActualIncome,
    totalProjectedIncome,
    totalActualExpenses,
    totalProjectedExpenses
  } = calculateTotals(budgetTrend, [INCOME_TYPE])

  const surplusDeficitRow = [
    'Surplus/Deficit', 
    formatSurplusDeficit(totalProjectedIncome, totalProjectedExpenses), 
    formatSurplusDeficit(totalActualIncome, totalActualExpenses)
  ]


  async function fetchData(selectedDate) {
    try {
      setLoading(true)
      const startDate = selectedDate.startOf('month').format('YYYY-MM-DD')
      const endDate = selectedDate.endOf('month').format('YYYY-MM-DD')

      const response = await get(proApplicationEndpoints.getBudgetTrend(planId, id, startDate, endDate))
      const { data } = response 
      const monthData = data[endDate]
      setBudgetTrend(monthData)
      setLoading(false)
    } catch (error) {
      console.error(error, error.response)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(selectedDate)
  }, [selectedDate])

  return (
    <View style={[styles.container, { backgroundColor: white }]}>
      <DateSliderRule
        selectedDate={selectedDate}
        projectionDates={projectionDates}
        onDatePicked={onDatePicked}
        isDatePickerVisible={isDatePickerVisible}
        setIsDatePickerVisible={setIsDatePickerVisible}
      />

      { loading && (
        <ActivityIndicator
          animating={true}
          size="large"
          style={styles.activityIndicator}
        />
      )}

      <Table style={{ opacity: loading ? 0.25 : 1 }}>
        <TableWrapper style={styles.wrapper}>
          <ScrollView contentContainerStyle={{paddingBottom: 120}}>
            <Table>
              <Row
                flexArr={[3, 1, 1]}
                data={['', 'Projected', 'Actuals']}
                style={styles.row}
                textStyle={[styles.text, styles.boldedText]}
              />

              {map(budgetCategories, (category) => (
                <BudgetCategoryRow 
                  category={category}
                  budgetTrend={budgetTrend}
                />
              ))}

              <Row
                flexArr={[3, 1, 1]}
                data={surplusDeficitRow}
                style={styles.row}
                textStyle={{ ...styles.text, fontWeight: '500' }}
              />
            </Table>
          </ScrollView>
        </TableWrapper>
      </Table>
    </View>
  )
}

BudgetTrendsTable.propTypes = { 
  proBudget: PropTypes.object.isRequired,
  planId: PropTypes.number.isRequired
}

export default function BudgetTrendDetailsScreen({ route }) {
  const { params } = route
  const { planId } = params

  return (
    <BudgetTrendsTable 
      planId={planId}
    />
  )
}
