import { useState } from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'
import CircularProgress from './components/CircularProgress'

import Container from './components/Container'
import Link from './components/Link'
import Divider from './components/Divider'
import { DEFAULT_SPACING, gunmetal, info, rgba } from './styles'
import CircleIcon from './components/CircleIcon'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: DEFAULT_SPACING,
    alignItems: 'center'
  },
  scrollView: {
    alignItems: 'center',
    paddingBottom: 100
  },
  title: {
    fontSize: 28,
    fontWeight: '600',
    textAlign: 'center',
    marginVertical: DEFAULT_SPACING * 2,
    letterSpacing: -0.6
  },
  smallTitle: {
    fontSize: 24,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -0.6,
    width: '80%'
  },
  help: {
    fontSize: 16,
    textAlign: 'center',
    letterSpacing: -0.67,
    color: gunmetal,
    marginVertical: DEFAULT_SPACING * 2,
    lineHeight: 24
  },
  helpItem: {
    fontSize: 14,
    textAlign: 'left',
    letterSpacing: -0.67,
    color: gunmetal,
    marginHorizontal: DEFAULT_SPACING,
    lineHeight: 20
  },
  divider: {
    marginVertical: DEFAULT_SPACING * 2,
    width: '100%'
  },
  lineItemContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: DEFAULT_SPACING,
    alignItems: 'flex-start',
    width: '100%'
  },
  lineItemIcon: { marginRight: DEFAULT_SPACING },
  lineItemText: {
    fontSize: 18,
    color: info
  }
})

function LineItem({ label, icon }) {
  return (
    <View style={styles.lineItemContainer}>
      <CircleIcon
        icon={icon}
        size={14}
        style={{ marginRight: DEFAULT_SPACING }}
        color={info}
        backgroundColor={rgba(info, 0.1)}
      />
      <Text style={styles.lineItemText}>{label}</Text>
    </View>
  )
}

export default function LikelihoodScreen({ navigation, route }) {
  const { params } = route
  const { chanceColor, probabilityOfSuccess, pro, isPension } = params

  const [visible, setVisible] = useState(false)

  function progressContent(percent) {
    return (
      <Text style={{ fontWeight: '600', fontSize: 36 }}>
        {`${percent.toFixed(0)}%`}
      </Text>
    )
  }

  const options = pro
    ? [
        { label: 'Increase your Save First Goal', icon: 'arrow-up' },
        { label: 'Extend your Horizon date', icon: 'arrow-up-right-dots' },
        {
          label: 'Reduce your Expenses',
          icon: 'arrow-down-from-dotted-line'
        },
        {
          label: 'Adjust your Goal to a more\n achievable amount',
          icon: 'sliders-simple'
        }
      ]
    : []
  // : [
  //     { label: 'Increase goal contribution' },
  //     { label: 'Increase amount of years' }
  //   ]

  return (
    <Container style={styles.container}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollView}
      >
        <CircularProgress
          progress={probabilityOfSuccess}
          size={160}
          width={12}
          fillFunction={() => progressContent(probabilityOfSuccess)}
          tintColor={chanceColor}
        />

        <Text style={styles.title}>
          {`You have a ${probabilityOfSuccess}% chance of hitting your goal!`}
        </Text>

        <Link onPress={() => setVisible(!visible)}>
          {visible ? 'Collapse' : 'How is this calculated?'}
        </Link>

        {visible && (
          <Text style={styles.help}>
            {isPension ? (
              "By selecting a Pension, your likelihood score is set at 99%. Why not 100%? Pension plans are designed to offer assured income during retirement (once you qualify and meet the plan's criteria), but at Troutwood, we believe that nothing in life is guaranteed, hence the 1% margin. This score will remain constant unless you modify your investor profile. "
            ) : pro ? (
              'The Likelihood score reflects how likely you are to reach your Goal using this plan. It compares your projected savings and investments (your Estimated Value) with the target amount you want to reach by a certain date (your Goal by your Horizon Date). If your Estimated Value is greater than your Goal, your Likelihood score will be >50%. A score below 50% means you may need to:'
            ) : (
              <>
                <Text style={{ fontWeight: '600' }}>
                  The greatest risk to not achieving a financial goal, is not
                  contributing towards it.
                </Text>
                Your Likelihood Score considers factors such as the historical
                performance of your chosen investments, your planned
                contributions through your Save First Goal, and the amount of
                extra money (surplus) in your financial plan. To improve your
                Likelihood Score, try increasing your Surplus using a Life-hack!
              </>
            )}
          </Text>
        )}

        {options.length > 0 && (
          <>
            <Divider style={styles.divider} />

            <Text style={styles.smallTitle}>
              Want to increase your likelihood?
            </Text>

            <Text style={styles.help}>Try one of the following!</Text>

            {options.map((option, index) => (
              <LineItem key={index} {...option} />
            ))}
          </>
        )}
      </ScrollView>
    </Container>
  )
}
