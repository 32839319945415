import {
  Text,
  View,
  StyleSheet,
} from 'react-native'
import { darkblue, DEFAULT_SPACING, white } from '../../styles'
import Card from '../Card'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const styles = StyleSheet.create({
  planOptionCard: (backgroundColor) => ({
    justifyContent: 'center',
    paddingLeft: DEFAULT_SPACING * 2,
    padding: DEFAULT_SPACING,
    backgroundColor,
    flex: 1,
    height: '100%'
  }),
  planOptionLabel: {
    fontSize: 10,
    color: darkblue,
    marginLeft: 4,
    fontWeight: '600'
  },
  planOptionLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  planOptionValue: {
    fontSize: 20,
    fontWeight: '700'
  },
})

const PlanSummaryHeaderMinCard = ({ option }) => (
  <Card
    style={styles.planOptionCard(option.backgroundColor)}
    onPress={option.onPress}
  >
    <Text style={[styles.planOptionValue, { color: option.color }]}>
      {option.value}
    </Text>
    <View style={styles.planOptionLabelContainer}>
      <FontAwesomeIcon
        icon={option.icon}
        size={10}
        color={darkblue}
      />
      <Text style={styles.planOptionLabel}>{option.label}</Text>
    </View>
  </Card>
)

export default PlanSummaryHeaderMinCard
