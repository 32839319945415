import { useEffect, useState, useRef } from 'react'

import { map } from 'lodash'
import { get } from 'http'
import { StyleSheet } from 'react-native'
import SearchInput from '../SearchInput'
import FullScreenModal from '../FullScreenModal'
import Container from '../Container'
import ScrollingListContainer from '../ScrollingListContainer'
import SearchResultFinn from '../SearchResultFinn'
import SearchListItem from '../SearchListItem'

const styles = StyleSheet.create({ modalContainer: { padding: 0 } })

export default function CitySearch({ visible, setVisible, setCity }) {
  const [citySearch, setCitySearch] = useState()
  const [cityOptions, setCityOptions] = useState([])

  const searchInput = useRef()

  async function searchCities(value) {
    if (value && value.length >= 3) {
      try {
        const results = await get(`cities/?search=${value}`)
        setCityOptions(results.data)
      } catch (error) {
        console.error(error.response, ' -- error')
      }
    } else {
      setCityOptions([])
    }
  }

  useEffect(() => {
    searchCities(citySearch)
  }, [citySearch])

  useEffect(() => {
    if (searchInput.current) searchInput.current.focus()
  }, [searchInput.current, visible])

  return (
    <FullScreenModal closeable useTextClose scrollEnabled={false} style={styles.modalContainer} visible={visible} setVisible={setVisible}>
      <Container withPadding>
        <SearchInput
          ref={searchInput}
          value={citySearch}
          onChange={(value) => {
            setCitySearch(value || null)
          }}
          onCancel={() => {
            setCitySearch(null)
          }}
        />

        <ScrollingListContainer
          displayButton={false}
        >
          {map(cityOptions, (c) => (
            <SearchListItem
              key={c.id}
              onPress={() => {
                setCity(c)
                setVisible(false)
                setCitySearch(null)
                setCityOptions([])
              }}
              leftLabel={`${c.name}`}
            />
          ))}

          {cityOptions.length == 0 && citySearch && citySearch.length > 3
              && (
                <SearchResultFinn
                  image={require('../../assets/finn/question.png')}
                  title="No Results"
                  subtitle="Explore another location"
                />
              )}

          {cityOptions.length == 0 && !citySearch
              && (
                <SearchResultFinn
                  image={require('../../assets/finn/happy.png')}
                  title="Search for a Location"
                  subtitle="Try typing the city you live in now"
                />
              )}

        </ScrollingListContainer>
      </Container>
    </FullScreenModal>
  )
}
