import { useState, useEffect, useLayoutEffect } from 'react'
import { View, ScrollView, Text, StyleSheet, useWindowDimensions, } from 'react-native'
import { each, capitalize } from 'lodash'
import Card from './components/Card'
import TouchableOpacity from './components/TouchableOpacity'

import Question from './services/Question'

import hapticFeedback from './components/hapticFeedback'
import { darkgrayblue, lightestgray, primary, white, rgba } from './styles/colors'

export default function QuizScreen({ navigation, route }) {
  const {width: windowWidth, height: windowHeight} = useWindowDimensions()
  const { params } = route
  const { title, bookTitle } = params

  const [questions, setQuestions] = useState([])
  const [question, setQuestion] = useState(null)
  const [answers, setAnswers] = useState([])
  const [visible, setVisible] = useState(false)
  const [score, setScore] = useState(0)
  const [turn, setTurn] = useState(0)
  const [showQuestion, setShowQuestion] = useState(true)

  // We will probably have to write logic to get the quiz doc, get length, then set total turns :MM
  const TOTAL_TURNS = 3

  // const link = "https://s3.console.aws.amazon.com/s3/object/tw-mobile-app-resources?region=us-east-2&prefix=TMS_quiz.csv"

  function AnswerOption({ answer, correctAnswer, setAnswer, label, value, disabled }) {
    let backgroundColor = rgba(white, 0.9)

    /* TODO: the two if blocks can be merged */

    if (answer && value == correctAnswer) {
      backgroundColor = 'rgba(30,184,110,0.9)'
    } else if (answer && answer != correctAnswer && value != correctAnswer && answer == value) {
      backgroundColor = 'rgba(222,54,84,0.9)'
    } else if (answer) {
      backgroundColor = rgba(white, 0.9)
    }

    let textColor = '#212121'

    if (answer && value == correctAnswer) {
      textColor = rgba(white, 0.9)
    } else if (answer && answer != correctAnswer && value != correctAnswer && answer == value) {
      textColor = rgba(white, 0.9)
    } else if (answer) {
      textColor = '#212121'
    }

    return (
      <TouchableOpacity disabled={disabled} style={{ backgroundColor, borderRadius: 10, flexDirection: 'row', alignItems: 'center', marginBottom: 12, height: windowHeight / 9 }} onPress={() => setAnswer(value)}>
        <Text style={{ marginLeft: 'auto', marginRight: 'auto', fontSize: 20, color: textColor, fontWeight: 'bold' }}>{label}</Text>
      </TouchableOpacity>
    )
  }

  function QuestionModal({ question, setVisible, turn, totalTurns, setScore }) {
    const [answer, setAnswer] = useState(null)
    const [gameOver, setGameOver] = useState(false)
    let correctAnswer = null

    each(Object.keys(question), (key) => {
      if (question[key] == 'correct_answer') {
        correctAnswer = capitalize(key)
      }
    })

    useEffect(() => {
      if (answer) {
        setTimeout(() => {
          if (correctAnswer == answer) {
            setScore = () => {
              updateScore()
            }
            setScore()
          }
          setVisible(answer)
          setAnswer(null)
        }, 3000)
      }
    }, [answer])

    return (
      <Card style={styles.cardContainer}>
        <Text style={styles.question_number}>
          Question:
          {' '}
          {turn + 1}
          {' '}
          of
          {' '}
          {totalTurns}
        </Text>
        <Text style={styles.question}>{question.question}</Text>
        <View style={{ width: '90%', marginTop: 48, marginLeft: '5%', marginRight: 'auto' }}>

          {question.a && <AnswerOption disabled={answer} correctAnswer={correctAnswer} question={question} answer={answer} setAnswer={setAnswer} label={question[question.a]} value="A" /> }
          {question.b && <AnswerOption disabled={answer} correctAnswer={correctAnswer} question={question} answer={answer} setAnswer={setAnswer} label={question[question.b]} value="B" /> }
          {question.c && <AnswerOption disabled={answer} correctAnswer={correctAnswer} question={question} answer={answer} setAnswer={setAnswer} label={question[question.c]} value="C" /> }

        </View>
      </Card>
    )
  }

  function updateScore() {
    setScore(score + 1)
  }

  function fetchQuestionData(bookTitle) {
    const questionResponse = new Question()
    const result = questionResponse.fetch(bookTitle, TOTAL_TURNS)
    setQuestions(result)
    setQuestion(result[0])
  }

  useEffect(() => {
    console.warn('Starting Library Quiz, Loading Data!')
    if (bookTitle) {
      fetchQuestionData(bookTitle)
    } else {
      fetchQuestionData(title)
    }
  }, [])

  useEffect(() => {
    console.warn('Turn: ', turn, ' - Score: ', score)

    if (turn >= TOTAL_TURNS) {
      // Calculate Scoren then send to score screen :MM
      const PERCENT_CORRECT = Math.round(score / TOTAL_TURNS * 100)
      navigation.navigate('Modal', { screen: 'Quiz Score', params: { score, turn: TOTAL_TURNS, correct: PERCENT_CORRECT } })
    }
  }, [turn])

  function setAnswerAndVisible(answer) {
    hapticFeedback()

    if (turn >= TOTAL_TURNS) {
      console.warn('END OF QUIZ- NOT VISIBLE')
      // setVisible(false)
    } else {
      console.warn('SHOWING ANSWER')
      answers.push(answer)
      setAnswers(answers)
      setVisible(false)
      setShowQuestion(false)

      setQuestion(questions[turn + 1])
      setTurn(turn + 1)
    }
  }

  // Progress Bar :MM
  function Header({ turn }) {
    return (
      <View style={styles.headerProgressBarContainer}>
        <View style={[styles.headerProgressBarCompleted, { flex: turn }]} />
        <View style={[styles.headerProgressBarNotCompleted, { flex: TOTAL_TURNS - turn }]} />
      </View>
    )
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
    },
    cardContainer: {
      width: windowWidth,
      height: windowHeight,
      marginHorizontal: '0%',
      paddingVertical: '5%',
      backgroundColor: lightestgray
    },
    question: {
      fontSize: 30,
      textAlign: 'left',
      fontWeight: 'bold',
      paddingTop: '5%',
      color: '#212121',
      marginHorizontal: '5%'
    },
    question_number: {
      fontSize: 18,
      textAlign: 'left',
      fontWeight: 'bold',
      paddingTop: '5%',
      color: '#6b7b7f',
      marginLeft: '5%',
      marginRight: 'auto'
    },
    header: {
      // Progress Bar Component
      marginBottom: 12,
      fontSize: 24,
      color: darkgrayblue,
      letterSpacing: -0.369231,
      fontWeight: '600'
    },
    headerProgressBarContainer: {
      flex: 1,
      flexDirection: 'row',
      marginBottom: 7,
    },
    headerProgressBarCompleted: {
      height: 7,
      backgroundColor: primary,
      zIndex: 1000
    },
    headerProgressBarNotCompleted: {
      height: 7,
      backgroundColor: 'rgb(183, 197, 204)',
      zIndex: 1000
    },
    bookTitle: {
      top: -2,
      bottom: 0,
      right: 0,
      left: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '5%',
      fontWeight: 'bold',
      fontSize: 16

    },
    navItems: {
      backgroundColor: white,
      width: '100%'

    }
  })

  useLayoutEffect(() => {
    navigation.setOptions({ subtitle: title })
  }, [navigation, title])

  return (
    <View>
      <ScrollView>
        <View style={{ backgroundColor: lightestgray, position: 'relative' }}>
          <Header turn={turn} TOTAL_TURNS={TOTAL_TURNS} />
          <ScrollView style={{ paddingTop: 0, paddingHorizontal: 0 }}>
            {question
              ? (
                <QuestionModal
                  setVisible={(answer) => setAnswerAndVisible(answer)}
                  question={question}
                  setAnswers={setAnswers}
                  turn={turn}
                  totalTurns={TOTAL_TURNS}
                />
              )
              : <></>}
          </ScrollView>
        </View>
      </ScrollView>
    </View>

  )
}
