import { Animated, StyleSheet, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { grayblue } from '../styles/colors'

const styles = StyleSheet.create({
  iconCircle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 27,
    height: 27,
    borderRadius: 27 / 2
  },
  text: {
    fontSize: 10,
    letterSpacing: -0.416667,
    marginTop: 4
  }
})

export default function CircleIcon({ 
  icon, 
  type = 'far', 
  style={}, 
  size = 24, 
  color = grayblue, 
  backgroundColor, 
  label 
}) {
  
  const containerStyles = [styles.iconCircle]

  if (backgroundColor) {
    const backgroundStyles = StyleSheet.create({
      background: {
        backgroundColor,
        borderRadius: size,
        height: size * 2,
        width: size * 2
      }
    })

    containerStyles.push(backgroundStyles.background)
  }

  if (style) {
    containerStyles.push(style)
  }

  let iconSize = size

  if (label) {
    iconSize = size * 0.7
  }

  return (
    <Animated.View style={containerStyles}>
      <FontAwesomeIcon icon={[type, icon]} color={color} size={iconSize} />
      {label && <Text style={[styles.text, { color }]}>{label}</Text>}
    </Animated.View>
  )
}
