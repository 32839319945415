import { post, put } from 'http'
import { isNil } from 'lodash'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { currentUserSelector } from '../selectors'
import yearMonthDayOnly from '../functions/yearMonthDateOnly'
import { setBudgetItem } from './setBudgetItem'
import { getFinancialProjections } from './getFinancialProjections'

export function sanitizeBudgetItemPayload(budgetItem) {
  const sanitizedBudgetItem = { ...budgetItem }

  if (!isNil(budgetItem.periodicity)) {
    const { startDate, endDate } = budgetItem.periodicity
    sanitizedBudgetItem.periodicity = {
      ...budgetItem.periodicity,
      startDate: startDate ? yearMonthDayOnly(startDate) : '',
      endDate: endDate ? yearMonthDayOnly(endDate) : ''
    }
  }

  return sanitizedBudgetItem
}

// eslint-disable-next-line import/prefer-default-export
export const saveBudgetItem =
  (budgetItem, planId) => async (dispatch, getState) => {
    try {
      const { id: userId } = currentUserSelector(getState())

      const { budgetItemId } = budgetItem

      let result

      if (budgetItemId) {
        /* Update budget item */
        result = await put(
          proApplicationEndpoints.updateBudgetItem(
            planId,
            budgetItemId,
            userId
          ),
          sanitizeBudgetItemPayload(budgetItem)
        )
      } else {
        /* New budget item */
        result = await post(
          proApplicationEndpoints.createBudgetItem(planId, userId),
          sanitizeBudgetItemPayload(budgetItem)
        )
      }

      dispatch(setBudgetItem(result.data))
      dispatch(getFinancialProjections(planId))
    } catch (e) {
      console.warn(e, e.response)
    }
  }
