import AsyncStorage from '@react-native-async-storage/async-storage'
import { stateReducer } from '@truefit/redux-utils'
import {
  SET_ACCESS_TOKEN,
  CLEAR,
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  SET_AUTHORIZATION_CODE,
  AUTHORIZATION_CODE_LOCAL_STORAGE_KEY
} from '../actions'

const initialState = {}

export default stateReducer(initialState, {
  [SET_ACCESS_TOKEN]: (state, payload) => {
    AsyncStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, payload)
    return { ...state, accessToken: payload }
  },

  [SET_AUTHORIZATION_CODE]: (state, payload) => {
    AsyncStorage.setItem(AUTHORIZATION_CODE_LOCAL_STORAGE_KEY, JSON.stringify(payload))
    return { ...state, authorizationCode: payload }
  },

  [CLEAR]: (state) => {
    AsyncStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY)
    AsyncStorage.removeItem(AUTHORIZATION_CODE_LOCAL_STORAGE_KEY)

    return { ...initialState, currentEnvName: state.currentEnvName }
  },
})
