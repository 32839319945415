import { useEffect, useState } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import {
  VictoryArea,
  VictoryChart,
  VictoryLabel,
  VictoryPolarAxis,
  VictoryScatter
} from 'victory-native'
import { useSelector } from 'react-redux'

import { Divider } from 'react-native-elements'
import { DEFAULT_SPACING, success, white } from '../../../styles'
import FieldScore from './FieldScore'
import { riskAssessmentSelector } from '../../../selectors'

const styles = StyleSheet.create({
  container: { height: '100%', backgroundColor: white, paddingHorizontal: 15 },
  scoreGroup: { flexDirection: 'row' },
  scoreResultCard: {
    width: '50%',
    alignItems: 'center'
  },
  score: {
    fontSize: 38,
    paddingVertical: DEFAULT_SPACING,
    fontWeight: '700'
  },
  scoreLabel: { fontSize: 12, fontWeight: '600', marginTop: 4 },
  starSharpChart: { maxHeight: 400, transform: 'rotate(-90deg)' },
  retakeBtnWrapper: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: DEFAULT_SPACING * 6
  },
  retakeBtn: {
    fontSize: 14,
    color: '#3491F7'
  }
})

function ScoreResultCard({ value, label }) {
  return (
    <View style={styles.scoreResultCard}>
      <Text style={[styles.score, { color: success }]}>
        {`${(value * 1).toFixed(2) * 1}%`}
      </Text>
      <Text style={styles.scoreLabel}>{label}</Text>
    </View>
  )
}

function CustomLabel(props) {
  return <VictoryLabel {...props} angle="90" />
}

function RiskAssessmentScore({ navigation }) {
  const { assessScore } = useSelector((state) => riskAssessmentSelector(state))
  const [score, setScore] = useState({
    ability: 0,
    cognitive_bias_score: 0,
    emotional_bias_score: 0,
    star_diagram_scores: [0, 0, 0, 0, 0],
    willingness: 0
  })
  const [starData, setStarData] = useState([
    { angle: 0, x: 'A', y: 30 },
    { angle: 0, x: 'E', y: 50 },
    { angle: 0, x: 'D', y: 60 },
    { angle: 0, x: 'C', y: 40 },
    { angle: 0, x: 'B', y: 40 }
  ])
  const [labels, setLabels] = useState([
    { key: 'A', label: '' },
    { key: 'B', label: '' },
    { key: 'C', label: '' },
    { key: 'D', label: '' },
    { key: 'E', label: '' }
  ])
  const [levels, setLevels] = useState([
    {
      id: 'emotional_bias_score',
      title: 'Level of Emotional Biases Affecting Decision Making',
      description:
        'A lower score is generally a better indicator of how emotions can affect your overall finances.',
      score: [66]
    },
    {
      id: 'cognitive_bias_score',
      title: 'Level of Cognitive Biases Affecting Decision Making',
      description:
        'A lower score is generally a better indicator of how biases can affect your overall finances.',
      score: [90]
    }
  ])

  useEffect(() => {
    if (assessScore.ability && !assessScore.is_self_assessed) {
      setScore(assessScore)
      const newStarData = starData.map((point, index) => ({
        ...point,
        y: assessScore.star_diagram_scores[index].value * 5
      }))
      setStarData(newStarData)
      const newLabels = labels.map((label, index) => ({
        ...label,
        label: assessScore.star_diagram_scores[index].label
      }))
      setLabels(newLabels)
      const scoreResults = [...levels]
      scoreResults[0].score = [(assessScore.emotional_bias_score * 5 - 1) * 25]
      scoreResults[1].score = [(assessScore.cognitive_bias_score * 5 - 1) * 25]
      setLevels(scoreResults)
    }
  }, [assessScore])

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.scoreGroup}>
          <ScoreResultCard value={score.willingness} label="Willingness" />
          <ScoreResultCard value={score.ability} label="Ability" />
        </View>
        <View
          style={{ marginTop: DEFAULT_SPACING * 3, padding: DEFAULT_SPACING }}
        >
          <Divider />
        </View>
        <View style={{ alignItems: 'center' }}>
          <View style={styles.starSharpChart}>
            <VictoryChart polar>
              <VictoryPolarAxis
                dependentAxis
                style={{
                  axis: { stroke: 'none' },
                  tickLabels: { fill: 'none' },
                  grid: { stroke: '#ddd', strokeDasharray: '10, 0' }
                }}
              />
              <VictoryPolarAxis
                startAngle={90}
                labelPlacement="vertical"
                style={{ axis: { stroke: 'none' } }}
                tickLabelComponent={<CustomLabel />}
              />
              <VictoryArea
                data={starData}
                style={{ data: { fill: '#3491F766' } }}
              />
              <VictoryScatter
                data={starData}
                x="x"
                y="y"
                size={5}
                style={{
                  data: {
                    fill: '#3491F7',
                    stroke: white,
                    strokeWidth: 2
                  }
                }}
              />
            </VictoryChart>
          </View>
          <View style={{ paddingHorizontal: DEFAULT_SPACING }}>
            {labels.map(({ key, label }) => (
              <View style={{ flexDirection: 'row' }}>
                <Text fontWeight={600}>{key}</Text>
                <Text style={{ color: 'gray' }}>{` - ${label.replaceAll(
                  "'",
                  ''
                )}`}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ marginVertical: DEFAULT_SPACING * 4 }}>
          <Divider />
        </View>
        <View style={{ paddingBottom: DEFAULT_SPACING * 4 }}>
          {levels.map((score, index) => (
            <>
              <FieldScore
                key={score.title}
                title={score.title}
                description={score.description}
                score={score.score}
              />
              {index < levels.length - 1 && (
                <View style={{ marginVertical: DEFAULT_SPACING * 4 }}>
                  <Divider />
                </View>
              )}
            </>
          ))}
        </View>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate('Modal', { screen: 'Risk Profile' })
          }
        >
          <View style={styles.retakeBtnWrapper}>
            <Text style={styles.retakeBtn}>Retake Assessment</Text>
          </View>
        </TouchableOpacity>
      </ScrollView>
    </View>
  )
}

export default RiskAssessmentScore
