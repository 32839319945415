import { Platform, View, StyleSheet, } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { DEFAULT_SPACING } from '../styles'
import PillButton from './PillButton'

const basicStyles = {
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: DEFAULT_SPACING,
    width: 'auto',
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  mobileStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: {
        justifyContent: 'space-between',
        marginVertical: 20,
        alignSelf: 'flex-start',
        width: 240,
        marginHorizontal: 'auto',
        maxHeight: 30,
      }
    },
    [minSize(DEVICE_SIZES.LG)]: {},
  }
)

export default function PillGroup({
  options,
  activeColor,
  activeBackgroundColor,
  inactiveColor,
  inactiveBackgroundColor,
  style = {},
  small=false
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View style={[styles.container, style]}>
      {options.map((option, index) => (
        <PillButton
          key={index}
          activeColor={activeColor}
          activeBackgroundColor={activeBackgroundColor}
          inactiveColor={inactiveColor}
          inactiveBackgroundColor={inactiveBackgroundColor}
          small={small}
          {...option}
        />
      ))}
    </View>
  )
}
