// React
import { useLayoutEffect } from 'react'
import { View, StyleSheet } from 'react-native'

import { map, capitalize } from 'lodash'
import { darkblue, gunmetal, lightestgray, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

import HouseForm from './components/opportunityCost/HouseForm'
import CarForm from './components/opportunityCost/CarForm'
import CollegeForm from './components/opportunityCost/CollegeForm'

const styles = StyleSheet.create({
  container: { backgroundColor: white },
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 63,
    height: 63,
    borderRadius: 63 / 2,
    overflow: 'hidden'
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0,
    marginLeft: DEFAULT_SPACING
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5,
    marginLeft: DEFAULT_SPACING,
    marginTop: 4
  }
})

export default function OpportunityCostCalculatorScreen({ navigation, route }) {
  const FormScreens = {
    home: HouseForm,
    car: CarForm,
    college: CollegeForm
  }

  const FormScreen = FormScreens[route.params.type]

  useLayoutEffect(() => {
    navigation.setOptions({ subtitle: map(route.params.type.split(' '), capitalize).join(' ') })
  }, [navigation, route])

  return (
    <View style={{
      backgroundColor: lightestgray,
      position: 'relative',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}
    >

      <FormScreen
        navigation={navigation}
      />
    </View>
  );
}
