import { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import Draggable from 'react-native-draggable'
import { DEFAULT_SPACING, white } from '../../../styles'
import CircleButton, { POINT_RADIUS } from './CircleButton'
import AxisLabelGroup from './AxisLabelGroup'
import CenterAxisLabelGroup from './CenterAxisLabelGroup'

const HEADER_HEIGHT = 120
const CHART_BOARD_SPACE = DEFAULT_SPACING * 2
const CHART_BOARD_PADDING = DEFAULT_SPACING
const ABSOLUTE_SPACING = CHART_BOARD_SPACE + CHART_BOARD_PADDING

const styles = StyleSheet.create({
  container: { alignItems: 'center', padding: CHART_BOARD_SPACE, backgroundColor: white, },
  board: {
    paddingLeft: CHART_BOARD_PADDING,
    paddingBottom: CHART_BOARD_PADDING,
    position: 'relative',
    width: '100%',
    height: 400,
    backgroundColor: white,
    cursor: 'pointer',
  },
  bounder: {
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#97979744',
    width: '100%',
    height: '100%',
    paddingLeft: DEFAULT_SPACING * 2,
    paddingBottom: DEFAULT_SPACING * 2,
  },
})

function RiskProfileChart({ ability, willingness, onChange }) {
  const [bounds, setBounds] = useState({ x: 0, y: 0 })
  const [zeroPos, setZeroPos] = useState({ x: 0, y: 0 })

  const handleLayout = (event) => {
    const { width, height, x, y } = event.nativeEvent.layout
    setBounds({ x: width, y: height })
    setZeroPos({ x, y })
  }

  const handleDrag = (event, gesture) => {
    const { moveX, moveY } = gesture
    let posX = moveX < zeroPos.x + ABSOLUTE_SPACING
      ? 0 : moveX - (zeroPos.x + ABSOLUTE_SPACING)
    posX = moveX > bounds.x + zeroPos.x + ABSOLUTE_SPACING
      ? bounds.x : posX
    let posY = moveY < HEADER_HEIGHT + CHART_BOARD_SPACE
      ? bounds.y : bounds.y + HEADER_HEIGHT + CHART_BOARD_SPACE - moveY
    posY = moveY > bounds.y + HEADER_HEIGHT + CHART_BOARD_SPACE ? 0 : posY
    const ab = ((posY * 100) / bounds.y).toFixed(2) * 1
    const rw = ((posX * 100) / bounds.x).toFixed(2) * 1
    onChange({ ability: ab, willingness: rw })
  }

  const point = (
    <Draggable
      onDragRelease={(event, gesture) => handleDrag(event, gesture)}
      x={((willingness * 1) / 100) * bounds.x - POINT_RADIUS}
      y={(1 - (ability * 1) / 100) * bounds.y - POINT_RADIUS}
      minX={-POINT_RADIUS}
      maxX={bounds.x + POINT_RADIUS}
      minY={-POINT_RADIUS}
      maxY={bounds.y + POINT_RADIUS}
      isCircle
      renderSize={POINT_RADIUS * 2}
    >
      <CircleButton />
    </Draggable>
  )

  return (
    <View style={styles.container}>
      <View style={styles.board}>
        <View style={styles.bounder}>
          <AxisLabelGroup />
          <LinearGradient
            style={{ height: '100%', width: '100%', top: 0, zIndex: -1, opacity: 0.4, }}
            colors={['#EA472C', '#F2C046', '#AAFC9F', '#55B448']}
            start={[0, 1]}
            end={[1, 0]}
          />
          <CenterAxisLabelGroup />
          <View style={{ width: '100%', height: '100%', top: 0, position: 'absolute', left: DEFAULT_SPACING * 2 }} onLayout={(event) => handleLayout(event)}>
            {point}
          </View>
        </View>
      </View>
    </View>
  )
}

export default RiskProfileChart
