import { useRef, useLayoutEffect } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import HeaderLabel from './components/HeaderLabel'
import Button from './components/Button'
import CurrencyInput from './components/CurrencyInput'

import capitalize from './functions/capitalize'

import {
  ValidationSchema,
  Fields,
  InitialValues
} from './schemas/accountSchema'
import { saveAccount } from './actions'
import { darkblue, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: DEFAULT_SPACING
  },
  emergencyFinn: {
    height: 286,
    width: 286,
    marginBottom: 24
  },
  headerLabel: {
    marginBottom: 24,
    textAlign: 'center',
    fontSize: 16,
    letterSpacing: -0.48
  },
  text: {
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center'
  },
  expense: {
    fontWeight: '500',
    fontSize: 48,
    letterSpacing: 0,
    textAlign: 'center',
    color: darkblue,
    marginTop: 100
  },
  expenseLabel: {
    fontWeight: '500',
    fontSize: 14,
    letterSpacing: 0,
    textAlign: 'center',
    color: darkblue
  },
  bottomButtonStyle: {
    justifyContent: 'flex-end',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING,
    backgroundColor: white
  },
})

export default function EmergencyPreparednessAddSourceScreen({ route, navigation }) {
  const balanceField = useRef()

  const dispatch = useDispatch()
  const { params } = route
  const { id, balance, name, registration } = params

  let formattedName = name.replace(/_/g, ' ')
  formattedName = capitalize(formattedName)
  formattedName = formattedName.replace('Ira', 'IRA')

  useLayoutEffect(() => {
    navigation.setOptions({ title: id ? `Edit ${formattedName}` : 'Add Source', });
  }, [navigation, id, name])

  return (
    <Formik
      validationSchema={ValidationSchema}
      initialValues={id ? { id, balance, name, registration } : InitialValues}
      onSubmit={async (values) => {
        values.category = 'CURRENT'
        values.registration = registration
        dispatch(saveAccount(values))
        navigation.pop()
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched }) => (
        <View style={{ backgroundColor: white, flex: 1 }}>
          <HeaderLabel style={styles.headerLabel}>
            Monthly Expenses
          </HeaderLabel>
          { !id && (
            <Text style={styles.text}>
              First, let’s take a look at your budget
              projections. Based on your default plan, this is
              an estimate of your monthly expenses. This
              <Text style={{ fontWeight: '800' }}> does not include </Text>
              items such as taxes, savings goal or discretionary spending.
            </Text>
          )}
          <View style={styles.container}>
            <View>
              <CurrencyInput
                label="Balance"
                ref={balanceField}
                name={Fields.balance}
                value={values.balance}
                errorMessage={errors[Fields.balance]}
                touched={touched[Fields.balance]}
                onBlur={handleBlur('balance')}
                onChangeText={handleChange('balance')}
                onSubmitEditing={handleSubmit}
                clearable={false}
              />
            </View>
          </View>
          <View style={styles.bottomButtonStyle}>
            <Button disabled={isSubmitting} onPress={handleSubmit}>{ id ? 'Update' : 'Add' }</Button>
          </View>
        </View>
      )}
    </Formik>
  );
}
