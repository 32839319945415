import { StyleSheet } from 'react-native'
import Link from '../Link'
import { darkblue, primary } from '../../styles/colors'

const styles = StyleSheet.create({
  icon: { color: primary },
  link: {
    color: darkblue,
    fontSize: 18,
    fontWeight: '500',
    paddingLeft: 6
  },
  linkContainer: { marginVertical: 10 }
})

export default function AccountDrawerListItem({ onPress, label, icon }) {
  return (
    <Link
      iconStyle={styles.icon}
      containerStyle={styles.linkContainer}
      style={styles.link}
      iconColor={primary}
      icon={icon}
      onPress={() => {
        if (onPress) onPress()
      }}
    >
      {label}
    </Link>
  )
}
