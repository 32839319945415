import { StyleSheet } from 'react-native'
import CircleIcon from '../CircleIcon'

import { white } from '../../styles/colors'

const styles = StyleSheet.create({
  circleIcon: {
    borderWidth: 3,
    borderColor: white
  }
})

export default function FireIcon({ size, iconSize, color, style }) {
  const circleStyle = StyleSheet.create({
    circleIcon: {
      height: size,
      width: size,
      borderRadius: size / 2,
      backgroundColor: color
    }
  })

  return (
    <CircleIcon style={[styles.circleIcon, circleStyle.circleIcon, style]} icon="fire" color="white" size={iconSize} />
  )
}
