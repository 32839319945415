import { useLayoutEffect } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { useDispatch } from 'react-redux'
import Wrapper from './components/planBuilder/Wrapper'
import { white, rgba } from 'styles'
import PlanBuildOptionCard from './components/planBuilder/PlanBuildOptionCard'
import { setFastPlanModeData } from './actions'

const styles = StyleSheet.create({
  header: { paddingHorizontal: 28, paddingVertical: 76 },
  backButton: { marginLeft: -4 },
  title: {
    marginTop: 36,
    fontSize: 32,
    fontWeight: '600',
    color: white
  },
  description: {
    marginTop: 32,
    fontSize: 16,
    color: rgba(white, 0.75)
  },
  optionListWrapper: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 15
  },
  optionList: { marginTop: -40 }
})

export default function PlanBuilderIntroScreen({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const params = { title: 'Plan Builder', subtitle: 'Profile', builder: true }
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerTintColor: white,
      title: null
    })
  }, [navigation])

  const options = [
    {
      title: '⚡️ Simple',
      description:
        'Troutwood will build you a financial plan using national averages and choose the most popular inputs.',
      onPress: () => {
        dispatch(setFastPlanModeData({ fastMode: true }))
        navigation.navigate('Modal', { screen: 'Fast Edit Age' })
      },
      lineItems: ['Finance beginners', 'High school students'],
      estimatedTime: 1
    },
    {
      title: '🔍 Basic',
      description: 'Explore careers, investor profiles and goal options.',
      onPress: () => {
        dispatch(setFastPlanModeData({ fastMode: false }))
        navigation.navigate('Modal', { screen: 'Edit Profile', params })
      },
      lineItems: ['Young professionals', 'College Students'],
      estimatedTime: 5
    }
  ]

  return (
    <Wrapper gradientHeight={'100%'}>
      <View style={styles.header}>
        <Text style={styles.title}>
          Take control of your financial future with a plan of action.
        </Text>
        <Text style={styles.description}>
          Choose a plan builder experience that aligns best with your needs and preferences.
        </Text>
      </View>
      <View style={styles.optionListWrapper}>
        <View style={styles.optionList}>
          {options.map((option) => (
            <PlanBuildOptionCard key={option.title} {...option} />
          ))}
        </View>
      </View>
    </Wrapper>
  )
}
