import { useEffect, useLayoutEffect, useState } from 'react'
import { ScrollView, StyleSheet, View, Text } from 'react-native'
import { useSelector } from 'react-redux'
import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import BudgetItemRow from './components/BudgetItemRow'
import CircleIcon from './components/CircleIcon'
import formatCurrency from './functions/formatCurrency'
import { proBudgetSelector, budgetCategoriesSelector } from './selectors'
import {
  DEFAULT_SPACING,
  gunmetal,
  darkblue,
  white,
  rgba,
  lightgray,
  lightestgray
} from './styles'
import { GET_PERIDICITY_TEXT } from './constants/periodicityConstants'
import SlideupDrawer from './components/SlideupDrawer'
import TouchableOpacity from './components/TouchableOpacity'
import AddBudgetItemSlideupDrawer from 'components/AddBudgetItemSlideupDrawer'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white
  },
  iconContainer: {
    backgroundColor: white,
    alignItems: 'center'
  },
  scrollviewContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    backgroundColor: white
  },
  scrollviewContentContainer: { paddingVertical: 20 },
  addLabelContainer: {
    alignItems: 'flex-start',
    marginRight: DEFAULT_SPACING
  },
  budgetSubCategoryContainer: {
    borderBottomColor: 'rgb(241, 246, 247)',
    borderBottomWidth: 1
  },
  noItems: {
    color: gunmetal,
    fontSize: 12
  },
  budgetItemTitle: { fontSize: 14 },
  addNewItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING * 1.6,
    borderBottomWidth: 1,
    borderBottomColor: lightestgray,
    marginHorizontal: DEFAULT_SPACING
  },
  addItemNewText: {
    fontSize: 16,
    fontWeight: '500'
  }
})

function initializeData(
  budgetItems,
  budgetSubCategory,
  budgetCategory,
  icon,
  color
) {
  const items = budgetItems.filter(
    (item) => item.budgetSubCategory === budgetSubCategory.key
  )

  const data = map(items, (budgetItem) => {
    const {
      amount,
      periodicity: { frequency }
    } = budgetItem
    const periodicityText = GET_PERIDICITY_TEXT(frequency) || ''
    const subtitle = `$${formatCurrency(
      amount
    )} ${periodicityText.toLowerCase()}`

    return {
      label: budgetItem.name,
      title: budgetItem.name,
      subtitle,
      color: darkblue,
      route: 'Update Budget',
      params: {
        key: budgetSubCategory.key,
        budgetSubCategory: budgetSubCategory.key,
        budgetCategory: budgetCategory,
        budgetItem,
        icon,
        color
      }
    }
  })

  return data
}

function BudgetItemsList({ data }) {
  return map(data, (rowProps) => (
    <BudgetItemRow
      key={rowProps.label}
      titleStyle={styles.budgetItemTitle}
      {...rowProps}
    />
  ))
}

function BudgetSubCategoryItem({
  budgetSubCategory,
  budgetCategory,
  budgetItems,
  icon,
  color
}) {
  const data = initializeData(
    budgetItems,
    budgetSubCategory,
    budgetCategory,
    icon,
    color
  )

  return (
    <View style={styles.budgetSubCategoryContainer} key={budgetSubCategory.key}>
      <BudgetItemRow
        label={budgetSubCategory.label}
        title={budgetSubCategory.label}
        color={darkblue}
        editable={false}
      >
        {data.length > 0 && <BudgetItemsList data={data} />}
        {data.length === 0 && (
          <Text style={styles.noItems}>No items added</Text>
        )}
      </BudgetItemRow>
    </View>
  )
}

export default function BudgetItemsListScreen({ route, navigation }) {
  const { title, color, icon, key } = route.params
  const proBudget = useSelector(proBudgetSelector)
  const budgetCategories = useSelector(budgetCategoriesSelector)
  const budgetCategory = budgetCategories.find(
    (category) => category.key === key
  )
  const { subCategories } = budgetCategory
  const budgetItems = proBudget.budgetItemsByCategory[key].items

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    navigation.setOptions({ title })
  })

  const addNewItem = (subcategory) => {
    navigation.navigate('Modal', {
      screen: 'Update Budget',
      params: {
        title: subcategory.label,
        budgetSubCategory: subcategory.key,
        budgetCategory: key
      }
    })
  }

  useLayoutEffect(() => {
    // create an add button on the right side
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={styles.addLabelContainer}
          onPress={() => setVisible(true)}
        >
          <FontAwesomeIcon icon={['fas', 'plus']} size={20} color={darkblue} />
        </TouchableOpacity>
      )
    })
  }, [navigation])

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <CircleIcon
          size={25}
          icon={icon}
          color={color}
          backgroundColor={rgba(color, 0.1)}
        />
      </View>

      <ScrollView
        contentContainerStyle={styles.scrollviewContentContainer}
        style={styles.scrollviewContainer}
      >
        {subCategories &&
          subCategories.map((subcategory) => (
            <BudgetSubCategoryItem
              key={subcategory.key}
              budgetCategory={key}
              budgetSubCategory={subcategory}
              color={color}
              icon={icon}
              budgetItems={budgetItems}
            />
          ))}
      </ScrollView>

      <AddBudgetItemSlideupDrawer
        subCategories={subCategories}
        addNewItem={addNewItem}
        visible={visible}
        setVisible={setVisible}
      />
    </View>
  )
}
