import { Platform, View, Text, StyleSheet, Image } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { useNavigation } from '@react-navigation/native'
import Button from './Button'
import { DEFAULT_SPACING, darkblue, secondary, white } from '../styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const basicStyles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 96,
    height: 98
  },
  header: {
    fontSize: 16,
    lineHeight: 20,
    fontWeight: '700',
    color: darkblue,
  },
  subheaderContainer: {
    flexDirection: 'row',
    marginTop: 5,
  },
  subheader: {
    fontSize: 12,
    lineHeight: 13,
    color: secondary,
  },
  troutwood: {
    width: 90,
    height: 12,
    marginHorizontal: 4
  },
  button: {
    marginTop: 16,
    paddingTop: 10,
    paddingBottom: 6,
    alignSelf: 'center',
    paddingHorizontal: 20,
    borderRadius: 6,
    backgroundColor: '#3491F6',
  },
  buttonTxt: { color: white, marginLeft: 4, },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      image: {
        width: 120,
        height: 123,
      },
      header: {
        marginTop: DEFAULT_SPACING * 3,
        fontSize: 28,
        lineHeight: 34,
      },
      subheaderContainer: {
        marginTop: 10,
      },
      subheader: {
        fontSize: 18,
        lineHeight: 18,
      },
      troutwood: {
        height: 18,
        width: 134,
      },
      button: {
        marginTop: 40,
        paddingVertical: 12,
        paddingBottom: 10,
      },
      buttonTxt: {
        fontSize: 18,
        fontWeight: '600',
        marginLeft: 6,
      }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function ShareAndEmpowerCard() {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const navigation = useNavigation()

  function onShare() {
    navigation.navigate('Modal', { screen: 'Make An Impact' })
  }

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={require('../assets/logo2.png')}
        resizeMode="contain"
      />
      <Text style={styles.header}>Share & Empower</Text>
      <View style={styles.subheaderContainer}>
        <Text style={styles.subheader}>Share</Text>
        <Image
          style={styles.troutwood}
          source={require('../assets/troutwood-alt.png')}
          resizeMode="contain"
        />
        <Text style={styles.subheader}>with friends and family.</Text>
      </View>
      <Button onPress={onShare} style={styles.button} >
        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
          <FontAwesomeIcon
            icon={['fas', 'share']}
            color={white}
            size={isNotWeb ? 12 : 18}
          />
          <Text style={styles.buttonTxt}>Share</Text>
        </View>
      </Button>
    </View>
  )
}
