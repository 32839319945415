import { map } from 'lodash-es'
import { View, StyleSheet, Text } from 'react-native'
import SlideupDrawer from '../SlideupDrawer'
import ListOptionItem from '../ListOptionItem'
import { darkblue, gunmetal } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 80
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING
  },
  body: {
    marginVertical: DEFAULT_SPACING,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center'
  },
})

export default function GoalAdequacySlideupDrawer({ visible, options, setVisible, percent, setPercent }) {
  function closeDrawer() {
    setTimeout(() => {
      setVisible(false)
    }, 500)
  }

  const checkOptions = map(options, (option) => ({
    label: option,
    onCheck: () => {
      setPercent(option)
      closeDrawer()
    }
  }))

  return (
    <SlideupDrawer snapPointIndex={1} scrollEnabled={false} visible={visible} setVisible={setVisible}>
      <View disableScroll={false} style={styles.container}>
        <Text style={styles.header}>Spending Policy</Text>

        {map(checkOptions, (option) => <ListOptionItem checked={percent == option.label} {...option} />)}
      </View>
    </SlideupDrawer>
  )
}
