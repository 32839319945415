import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { white } from "../../styles";
import HeaderBackImage from "../HeaderBackImage";

const basicStyles = {
  container: { marginLeft: 'auto', },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: { marginLeft: 200, }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

const BackButton = ({ onPress }) => {
  const styles = isNotWeb ? mobileStyles : useStyles()
  return (<View style={styles.container}>
    <TouchableOpacity onPress={onPress}>
      <HeaderBackImage fill={white} />
    </TouchableOpacity>
  </View>)
}

export default BackButton
