import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_AMOUNT,
  INVALID_INT,
  INVALID_MIN,
  INVALID_PERCENT,
  INVALID_PERCENT_MAX
} from './constants'

export const Fields = {
  initialInvestment: 'initial_investment',
  rateOfReturn: 'rate_of_return',
  contributionAmount: 'contribution_amount',
  currentAge: 'current_age',
  retirementAge: 'retirement_age'
}

export const ValidationSchema = Yup.object().shape({
  initial_investment: Yup.number()
    .required(REQUIRED)
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`)
    .nullable(),
  rate_of_return: Yup.number()
    .min(0, INVALID_PERCENT)
    .max(100, INVALID_PERCENT_MAX)
    .required(REQUIRED)
    .positive(INVALID_MIN)
    .nullable(),
  contribution_amount: Yup.number()
    .required(REQUIRED)
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_AMOUNT)
    .max(1000000, `${INVALID_AMOUNT}1,000,000`)
    .nullable(),
  current_age: Yup.number()
    .required(REQUIRED)
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_AMOUNT)
    .max(100, `${INVALID_AMOUNT}100`)
    .nullable(),
  retirement_age: Yup.number()
    .required(REQUIRED)
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_AMOUNT)
    .max(130, `${INVALID_AMOUNT}130`)
    .moreThan(Yup.ref(Fields.currentAge))
    .nullable(),
})

export const InitialValues = {
  [Fields.initialInvestment]: '',
  [Fields.rateOfReturn]: '',
  [Fields.contributionAmount]: '',
  [Fields.currentAge]: '',
  [Fields.retirementAge]: ''
}
