import * as Yup from 'yup'

import {
  REQUIRED
} from './constants'

export const Fields = {
  enabled: 'enabled',
  periodicity: 'periodicity'
}

export const ValidationSchema = Yup.object().shape({
  enabled: Yup.boolean(),
  periodicity: Yup.string()
    .nullable(true)
    .required(REQUIRED)
})

export const InitialValues = {
  [Fields.enabled]: false,
  [Fields.periodicity]: null,
}
