import * as Yup from 'yup'

import {
  INVALID_AMOUNT,
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX,
  REQUIRED,
} from './constants'

export const Fields = {
  birth_date: 'birth_date',
  custom_career_name: 'custom_career_name',
  input_custom_career: 'input_custom_career',
  // custom_career_salary: 'custom_career_salary',
  city: 'city',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.city]: Yup.mixed().required(REQUIRED),
  [Fields.custom_career_name]: Yup.string().required(REQUIRED),
  // [Fields.custom_career_salary]: Yup.number().required(REQUIRED)
})

export const InitialValues = ({ 
  city, 
  custom_career_name, 
  birth_date, 
  // custom_career_salary, 
  input_custom_career
}) => {

  return {
    [Fields.city]: city,
    [Fields.custom_career_name]: custom_career_name,
    [Fields.birth_date]: birth_date,
    // [Fields.custom_career_salary]: custom_career_salary,
    [Fields.input_custom_career]: input_custom_career,
  }
}
