import { TouchableOpacity, } from "react-native"
import BottomTabItem from "./BottomTabItem"
import TabWrapper from "./TabWrapper"

const menu = [
  { label: 'My Dashboard', icon: 'grid-2', screen: "Dashboard" },
  { label: 'Plans', icon: 'cubes', screen: 'Plan Builder' },
  { label: 'Tools', icon: 'screwdriver-wrench', screen: 'Tools' },
]

const Sidebar = ({ navigation, }) => (
  <TabWrapper>
    {menu.map(({ label, icon, screen }, index) => (
      <TouchableOpacity
        onPress={() => navigation.navigate(screen)}
        key={index}
        disabled={false}
      >
        <BottomTabItem
          disabled={false}
          isFocused={false}
          label={label}
          tabBarIcon={icon}
        />
      </TouchableOpacity>
    ))}
  </TabWrapper>
)

export default Sidebar
