import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX
} from './constants'

export const Fields = {
  contribution_amount: 'contribution_amount',
  savings_goal: 'savings_goal',
  contribution_selection: 'contribution_selection'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.contribution_amount]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(3000000, `${INVALID_MAX}3,000,000`)
    .required(REQUIRED)
    .nullable()
})
export const ValidationSchemaCustom = Yup.object().shape({
  [Fields.savings_goal]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(1000000000, `${INVALID_MAX}1,000,000,000`)
    .required(REQUIRED)
    .nullable()
})
export const InitialValues = {
  [Fields.contribution_amount]: null,
  [Fields.savings_goal]: null,
  [Fields.contribution_selection]: null
}
