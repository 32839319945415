import { StyleSheet } from 'react-native'

import { useDispatch } from 'react-redux'
import { capitalize } from 'lodash'
import ListOptionItem from '../ListOptionItem'

import { saveWellnessChecklistItem } from '../../actions'

const styles = StyleSheet.create({ labelStyle: { fontSize: 12 } })


export default function CheckListItem({ item, onCheck, onPress, completed, goToWellnessPage }) {

  const dispatch = useDispatch()
  return (
    <ListOptionItem
      key={`financial-wellness-item-${item.id}`}
      labelStyle={styles.labelStyle}
      onCheck={() => {
        if (item.financial_wellness_item_id){
        dispatch(saveWellnessChecklistItem(item.financial_wellness_item_id, !item.is_complete))
        }
        if (onCheck) onCheck(item)
      }}
      onPress={() => {
        if (onPress) onPress(item)
      }}
      completed={completed}
      goToWellnessPage={() => {
        if (goToWellnessPage) goToWellnessPage(item)
      }}
      label={item.description.length > 45 ? `${item.description.substring(0, 45)}...` : item.description}
      checked={item.is_complete}
    />
  )
}
