import { StyleSheet, View } from 'react-native'
import { useDispatch } from 'react-redux'

import { map } from 'lodash'
import ListOptionItem from '../ListOptionItem'

import { lightgray, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  headerContainer: {
    margin: 10,
    position: 'relative'
  },
  divider: { borderBottomColor: lightgray },
  pillLabel: {
    backgroundColor: lightgray,
    alignSelf: 'center',
    position: 'absolute',
    top: -10
  },
  pillLabelText: { color: white },
  container: {
    marginHorizontal: DEFAULT_SPACING,
    position: 'relative'
  },
  labelStyle: { fontSize: 12 }

})

export default function MilestoneGroup({ milestoneList, completed, onPress, goToEditGoal }) {
  const dispatch = useDispatch()

  return (
    <View>
      <View style={styles.headerContainer} />

      <View style={styles.container}>
        {map(milestoneList, (item) => (
          <ListOptionItem
            key={`financial-wellness-item-${item.id}`}
            labelStyle={styles.labelStyle}
            onPress={() => {
              if (onPress) onPress(item)
            }}
            completed={completed}
            goToEditGoal={() => {
              if (goToEditGoal) goToEditGoal(item)
            }}
            label={item.description.length > 45 ? `${item.description.substring(0, 45)}...` : item.description}
            checked={item.is_complete === null ? false : item.is_complete}
          />
        ))}
      </View>
    </View>
  )
}
