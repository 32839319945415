import {
  KeyboardAvoidingView,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
  StyleSheet,
  Platform
} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { white } from '../styles/colors'

export const SCREEN_PADDING = 16

const styles = StyleSheet.create({
  rootContainer: { backgroundColor: 'transparent' },
  overlayContainer: {
    display: 'flex',
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.85,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingMessage: {
    fontSize: 25,
    color: white,
    opacity: 1,
  },
  safeAreaView: { backgroundColor: 'transparent', },
  scrollView: { backgroundColor: 'transparent' },
  keyboardAvoidingView: { backgroundColor: 'transparent' }
})

export default function Screen({
  children,
  disableScroll,
  keyboardShouldPersistTaps,
  loading,
  loadingMessage,
  includeExtra,
  style = {},
  safeAreaStyle = {},
  keyboardAvoidingViewStyles = {},
  scrollViewStyle = {}
}) {
  const isNotWeb = Platform.OS !== 'web'

  return (
    <View style={[styles.rootContainer, style]}>
      <SafeAreaView style={[styles.safeAreaView, safeAreaStyle]}>
        {disableScroll ? (
          <KeyboardAvoidingView
            behavior="padding"
            style={[styles.keyboardAvoidingView, keyboardAvoidingViewStyles]}
          >
            {children}
          </KeyboardAvoidingView>
        ) : isNotWeb ? (
          <KeyboardAwareScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            extraHeight={includeExtra ? 130 : 0}
            extraScrollHeight={includeExtra ? 130 : 0}
            contentContainerStyle={[styles.scrollView, scrollViewStyle]}
            keyboardShouldPersistTaps={keyboardShouldPersistTaps}
            style={[styles.keyboardAvoidingView, keyboardAvoidingViewStyles]}
          >
            {children}
          </KeyboardAwareScrollView>
        ) : (
          <View style={[{ flex: 1, overflow: 'auto' }, scrollViewStyle]}>
            {children}
          </View>
        )}
      </SafeAreaView>
      {loading && (
        <View style={styles.overlayContainer}>
          <ActivityIndicator size="large" />
          <Text style={styles.loadingMessage}>{loadingMessage}</Text>
        </View>
      )}
    </View>
  )
}
