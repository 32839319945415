import React from 'react';
import { Platform, View, Image, Text, StyleSheet } from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

const basicStyles = {
  groupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarContainer: {
    marginRight: -20,
    width: 88,
    height: 88,
    borderRadius: 44,
    backgroundColor: '#c5e4d8a0',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    width: 72,
    height: 72,
    borderRadius: 36,
  },
  overflowAvatar: {
    backgroundColor: '#dfd',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 8,
    borderColor: '#5F8EE801',
  },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      avatarContainer: {
        marginRight: -32,
        width: 118,
        height: 118,
        borderRadius: 59,
      },
      avatar: {
        width: 96,
        height: 96,
        borderRadius: 48,
      },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

const AvatarGroup = ({ avatars, maxVisible }) => {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const visibleAvatars = avatars.slice(0, maxVisible);
  const overflowCount = avatars.length - maxVisible;

  return (
    <View style={styles.groupContainer}>
      {visibleAvatars.map((avatarUri) => (
        <View key={avatarUri} style={styles.avatarContainer}>
          <Image source={{uri: avatarUri}} style={styles.avatar} />
        </View>
      ))}
      {overflowCount > 0 && (
        <View style={styles.avatarContainer}>
          <View style={[styles.avatar, styles.overflowAvatar]}>
            <Text>+{overflowCount}</Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default AvatarGroup;
