import { useState } from 'react'
import { ScrollView } from 'react-native-gesture-handler'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { find, map, reduce } from 'lodash'
import { StyleSheet, Text, View } from 'react-native'
import { bsAccountsSelector, financialProjectionsSelector } from './selectors'
import MyAccountToggle from './components/proDashboard/MyAccountToggle'
import NetWorthGraph from './components/NetWorthGraph'
import isSameYearMonth from './functions/isSameYearMonth'
import financialProjectionUtils from './functions/financialProjectionUtils'
import { DEFAULT_SPACING, orange, white } from './styles'
import DateSliderRule from './components/DateSliderRule'

const styles = StyleSheet.create({
  noProjectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: orange,
    padding: DEFAULT_SPACING
  },
  noProjectionsText: {
    color: white,
    flexWrap: 'wrap'
  }
})

function getProjectedAccounts(accounts, projectedAccounts) {
  return map(
    accounts,
    (account) => {
      const projectedAccount = find(
        projectedAccounts,
        (projAcc) => (projAcc.accountId === account.accountId)
      )

      return {
        ...account,
        balance: (projectedAccount || { balance: 0 }).balance
      }
    }
  )
}

function getNetWorthFromBSAccounts(bsAccountsData) {
  const assetsTotal = reduce(
    bsAccountsData.assetAccounts,
    (total, account) => (total + account.balance),
    0
  )
  const liabilitiesTotal = reduce(
    bsAccountsData.liabilityAccounts,
    (total, account) => (total + account.balance),
    0
  )
  return assetsTotal - liabilitiesTotal
}

export default function MyBalanceSheetScreen() {
  /* Fetch BS accounts */
  const bsAccountsData = useSelector(bsAccountsSelector)

  /* Fetch NW Information */
  const projections = useSelector(financialProjectionsSelector)

  const monthYearFormat = (date) => moment(date).format('MMM YYYY')

  const projectionDates = map(projections, (projection) => ({
    id: monthYearFormat(projection.date),
    title: monthYearFormat(projection.date),
    date: projection.date
  }))

  const {
    between: filterProjections,
    findAtDate: findProjection
  } = financialProjectionUtils(projections)

  const getEnrichedAccounts = (selectedProjection) => {
    const accounts = bsAccountsData
    accounts.assetAccounts = getProjectedAccounts(
      bsAccountsData.assetAccounts,
      selectedProjection.bsAccounts
    )
    accounts.liabilityAccounts = getProjectedAccounts(
      bsAccountsData.liabilityAccounts,
      selectedProjection.bsAccounts
    )
    return accounts
  }
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)
  const [selectedDate, setSelectedDate] = useState(moment())
  const [netWorth, setNetWorth] = useState(getNetWorthFromBSAccounts(bsAccountsData))
  const [currentProjection, setCurrentProjection] = useState(bsAccountsData)
  const [netWorthWindow, setNetWorthWindow] = useState(
    filterProjections(
      selectedDate,
      moment(selectedDate).add(6, 'months')
    )
  )
  const today = moment()

  const onDatePicked = (pickedDate) => {
    setIsDatePickerVisible(false)
    const newSelectedDate = moment(pickedDate)
    setSelectedDate(newSelectedDate)

    if (isSameYearMonth(newSelectedDate, today)) {
      setCurrentProjection(bsAccountsData)
      setNetWorth(getNetWorthFromBSAccounts(bsAccountsData))
      setNetWorthWindow(filterProjections(today, moment(newSelectedDate).add(3, 'months')))
    } else {
      const projection = findProjection(newSelectedDate)
      setCurrentProjection(getEnrichedAccounts(projection))
      setNetWorth(projection.netWorth)
      setNetWorthWindow(
        filterProjections(
          moment(newSelectedDate).subtract(3, 'months'),
          moment(newSelectedDate).add(3, 'months')
        )
      )
    }
  }

  const noProjectionsHeader = () => (
    <View style={styles.noProjectionsContainer}>
      <Text style={styles.noProjectionsText}>
        This page usually shows your future projected financials. But they haven't been generated yet. Come back to this page in sometime to see more.
      </Text>
    </View>
  )

  return (
    <ScrollView>
      {
        (projections.length > 0) && (
          <DateSliderRule
            selectedDate={selectedDate}
            projectionDates={projectionDates}
            onDatePicked={onDatePicked}
            isDatePickerVisible={isDatePickerVisible}
            setIsDatePickerVisible={setIsDatePickerVisible}
          />
        )
      }
      {
        (projections.length === 0) && (noProjectionsHeader())
      }
      <View>
        <NetWorthGraph
          data={netWorthWindow}
          selectedDate={selectedDate.toDate()}
          netWorthToday={netWorth}
          style={{ backgroundColor: white }}
        />
      </View>

      <MyAccountToggle bsAccounts={currentProjection} />
    </ScrollView>
  )
}

MyBalanceSheetScreen.propTypes = {}
