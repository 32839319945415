import { Platform, StyleSheet, Text } from 'react-native'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import {
  DEFAULT_SPACING,
  darkblue,
  info,
  lightestgray,
  rgba,
  white
} from '../styles'
import ToolsListItem from './tools/ToolsListItem'
import {
  accountsSelector,
  completedWellnessChecklistsSelector,
  finPulseSelector,
  wellnessChecklistsSelector,
  plansSelector
} from '../selectors'
import PulseCircle from './PulseCircle'
import {
  calculateStreak,
  convertMonthlyContributionsToHash
} from '../functions/finPulse'

const basicStyles = {
  toolsTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: darkblue,
    marginVertical: DEFAULT_SPACING * 2,
  },
}
const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      toolsTitle: {
        marginTop: 32,
        marginBottom: DEFAULT_SPACING,
        width: '100%',
      },
    },
    [minSize(DEVICE_SIZES.LG)]: {}
  }
)

function WellnessToolsList({
  navigation,
  isBeta,
  selfAssessed = false,
  blankAssessment = true,
  pro = false,
  status,
  monthsOfExpensesCovered
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const wellnessItems = useSelector((state) => wellnessChecklistsSelector(state))
  const completedItems = useSelector((state) => completedWellnessChecklistsSelector(state))
  const currentAccounts = useSelector((state) => accountsSelector(state))
  const plans = useSelector((state) => plansSelector(state))
  const finPulseStreaks = useSelector((state) => state.finPulseStreaks)
  const monthlyContributions = isEmpty(finPulseStreaks) ? [] : finPulseStreaks
  const finPulse = useSelector(finPulseSelector)
  const calculatedMonthlyContributions = convertMonthlyContributionsToHash(monthlyContributions)
  const pulseCircleHeader = calculateStreak(calculatedMonthlyContributions)
  const pulseCircleSubheader = pluralize('month', pulseCircleHeader, false)

  const wellnessTools = [
    {
      title: 'Wellness Checklist',
      tag:
        wellnessItems.length === completedItems.length
          ? 'On Track'
          : 'Do Better',
      icon: 'dove',
      iconColor: '#3491F7',
      iconSize: isNotWeb ? 30 : 43,
      iconBackgroundColor: rgba('#3491F7', 0.1),
      onPress: () => navigation.navigate('Modal', { screen: 'Wellness Checklist Intro' })
    },
    isBeta
      ? {
        title: 'Finmoji',
        subtitle: 'My financial personality',
        image: require('../assets/icons/finmoji-icon.png'),
        imageWidth: '65%',
        imageHeight: '45%',
        imageBackgroundColor: rgba('#3491F7', 0.1),
        onPress: () => navigation.navigate('Modal', { screen: 'My Finmoji' })
      }
      : null,
    {
      title: 'My Risk Profile',
      subtitle:
        'Your ability and willingness to accept risk shape your overall risk profile',
      icon: 'shapes',
      iconColor: '#3491F7',
      iconSize: isNotWeb ? 30 : 43,
      iconBackgroundColor: rgba('#3491F7', 0.1),
      onPress: () => navigation.navigate('Modal', {
        screen:
            selfAssessed || blankAssessment ? 'Risk Profile' : 'My Risk Profile'
      })
    },
    {
      title: 'Debt Ranking',
      subtitle: 'Rank your debt and create a cost-effective repayment strategy',
      icon: 'ranking-star',
      iconColor: '#3491F7',
      iconSize: isNotWeb ? 30 : 43,
      iconBackgroundColor: rgba('#3491F7', 0.1),
      onPress: () => navigation.navigate('Modal', { screen: 'My Debts' })
    },
    pro
      ? {
        title: 'FinPulse',
        subtitle:
            finPulse.enabled && monthlyContributions.length > 0
              ? `🔥 ${String(pulseCircleHeader)} ${pulseCircleSubheader}`
              : null,
        tag: 'On Track',
        subtitleStyle: {
          fontSize: 16,
          fontWeight: '600',
          color: darkblue
        },
        iconComponent: (
          <PulseCircle
            size={isNotWeb ? 60 : 86}
            color={finPulse.enabled ? info : lightestgray}
            backgroundColor={rgba(info, 0.1)}
            showIcon={finPulse.enabled && monthlyContributions.length > 0}
            disabled={!finPulse.enabled}
            resize
            icon="wave-pulse"
            iconColor={white}
            iconSize={isNotWeb ? 26 : 34}
          />
        ),

        onPress: () => navigation.navigate('Modal', { screen: 'FinPulse' })
      }
      : null,
    !pro && plans.length > 0
      ? {
        title: 'Emergency Preparedness',
        tag: `${status}: ${monthsOfExpensesCovered ?? '-'} Months`,
        icon: 'fire',
        iconColor: '#3491F7',
        iconSize: isNotWeb ? 30 : 43,
        iconBackgroundColor: rgba('#3491F7', 0.1),
        onPress: () => {
          if (currentAccounts.length > 0) {
            navigation.navigate('Modal', { screen: 'Emergency Preparedness' })
          } else {
            navigation.navigate('Modal', { screen: 'Emergency Preparedness Monthly Expenses' })
          }
        }
      }
      : null,
    {
      title: 'Start Investing',
      subtitle: 'Compare investment companies',
      icon: 'seedling',
      iconColor: '#7CBA82',
      iconSize: isNotWeb ? 30 : 43,
      iconBackgroundColor: rgba('#3491F7', 0.1),
      onPress: () => navigation.navigate('Modal', { screen: 'Start Investing Category' })
    }
  ]

  return (
    <>
      <Text style={styles.toolsTitle}>Wellness Tools</Text>
      {wellnessTools
        .filter((op) => !!op)
        .map((option) => (
          <ToolsListItem key={option.title} navigatable {...option} />
        ))}
    </>
  )
}

export default WellnessToolsList
