import { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { map, capitalize } from 'lodash'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import SlideupDrawer from '../SlideupDrawer'
import Button from '../Button'
import CurrencyInput from '../CurrencyInput'

import AccountDrawerListItem from './AccountDrawerListItem'
import { DEFAULT_SPACING } from '../../styles'

import {
  ValidationSchema,
  Fields,
  InitialValues
} from '../../schemas/accountSchema'

import { saveAccount } from '../../actions'

const styles = StyleSheet.create({
  input: {
    backgroundColor: 'rgba(242, 248, 250, 0.9)',
    marginBottom: 12
  }
})

export default function AddSourceDrawer({ sources, formVisible, setFormVisible, marginTop, height, visible, setVisible }) {
  const [registration, setRegistration] = useState(null)
  const dispatch = useDispatch()

  return (
    <SlideupDrawer
      snapPointIndex={1}
      title="Add Source"
      subtitle={formVisible ? 'Enter Amount' : 'Choose the type of source'}
      closeEnabled
      marginTop={marginTop}
      height={height}
      visible={visible}
      setVisible={setVisible}
      onClose={() => {
        // make sure the modal closes before changing the form
        setTimeout(() => {
          setFormVisible(false)
          setRegistration()
        }, 500)
      }}
    >
      {formVisible

        && (
          <Formik
            enableReinitialize
            validationSchema={ValidationSchema}
            initialValues={InitialValues}
            onSubmit={async (values) => {
              values.category = 'CURRENT'
              values.name = capitalize(registration)
              values.registration = registration

              dispatch(saveAccount(values))
              setFormVisible(false)
              setRegistration()
              setVisible(false)
            }}
          >
            {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched }) => (
              <View style={{ paddingHorizontal: DEFAULT_SPACING }}>

                <CurrencyInput
                  label="Balance"
                  name={Fields.balance}
                  value={values.balance}
                  errorMessage={errors[Fields.balance]}
                  touched={touched[Fields.balance]}
                  onBlur={handleBlur('balance')}
                  style={styles.input}
                  onChangeText={handleChange('balance')}
                  onSubmitEditing={handleSubmit}
                  clearable={false}
                />

                <Button disabled={isSubmitting} onPress={handleSubmit}>Add</Button>
              </View>
            )}
          </Formik>
        )}

      {!formVisible
        && (
          <View style={{ alignItems: 'flex-start', paddingHorizontal: DEFAULT_SPACING }}>
            {map(sources, (source) => (
              <AccountDrawerListItem
                onPress={() => {
                  setFormVisible(true)
                  setRegistration(source.registration)
                }}
                setVisible={setVisible}
                {...source}
              />
            ))}
          </View>
        )}
    </SlideupDrawer>
  )
}
