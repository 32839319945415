import { View } from "react-native"
import Breadcrumbs from "../Breadcrumbs"
import { DEFAULT_SPACING } from "../../styles"
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from "rn-responsive-styles"

const useStyles = CreateResponsiveStyle(
  {
    pageHeader: {
      paddingLeft: DEFAULT_SPACING,
      paddingTop: 46,
      paddingBottom: DEFAULT_SPACING,
    },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      pageHeader: {
        paddingLeft: 40,
      },
    }
  }
)

const PlanSummaryPageHeader = () => {
  const styles = useStyles()

  return (
    <View style={styles.pageHeader}>
      <Breadcrumbs mode='dark' />
    </View>
  )
}

export default PlanSummaryPageHeader
