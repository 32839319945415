import { View, StyleSheet, ActivityIndicator, Text } from 'react-native'

import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { loadingSelector } from './selectors'

import { grayblue, white } from './styles/colors'
import CircleGradient from './components/CircleGradient'

const styles = StyleSheet.create({
  container: { flex: 1 },
  overlayContainer: {
    backgroundColor: grayblue,
    display: 'flex',
    flex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.75,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  loadingMessage: {
    fontSize: 25,
    marginTop: 25,
    color: white,
    opacity: 1
  }
})

export default function LoadingScreen({ children }) {
  const {
    loading,
    fullScreen,
    useGradient = false,
    fullScreenOpacity,
    fullScreenIconComponent,
    fullScreenIcon,
    loadingMessage
  } = useSelector((state) => loadingSelector(state))

  const backgroundColor = grayblue
  const opacity = fullScreenOpacity || 0.75

  return (
    <>
      <View style={styles.container}>{children}</View>
      {loading && fullScreen && (
        <>
          {useGradient && <CircleGradient height={'100%'} />}
          <View
            style={[
              styles.overlayContainer,
              { backgroundColor: useGradient ? 'transparent' : backgroundColor, opacity }
            ]}
          >
            {!fullScreenIcon && !fullScreenIconComponent && (
              <ActivityIndicator size="large" color={white} />
            )}
            {fullScreenIcon && <FontAwesomeIcon icon={fullScreenIcon} color={white} size={120} />}
            {fullScreenIconComponent}
            <Text style={styles.loadingMessage}>{loadingMessage}</Text>
          </View>
        </>
      )}
    </>
  )
}
