import { Platform, View, Text, StyleSheet } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { info, primary, white, rgba, darkblue } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

import Divider from '../Divider'
import PillLabel from '../PillLabel'
import HeaderLabel from '../HeaderLabel'
import CircleInitial from '../CircleInitial'
import CircleIcon from '../CircleIcon'
import PillButton from '../PillButton'

import abbreviateCurrency from '../../functions/abbreviateCurrency'

const basicStyles = {
  resultsContainer: {
    padding: DEFAULT_SPACING * 2,
    backgroundColor: primary,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerLabel: {
    textAlign: 'center',
    color: white
  },
  headerSublabel: {
    textAlign: 'center',
    fontSize: 14,
    color: white
  },
  moneyText: {
    textAlign: 'center',
    fontSize: 42,
    color: white,
    fontWeight: 'bold'
  },
  bestPick: { backgroundColor: info },
  notBestPick: { backgroundColor: rgba(white, 0.2) },
  optionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  },
  pillOptionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    marginTop: DEFAULT_SPACING
  },
  circleText: {
    fontSize: 28,
    color: white,
    fontWeight: 'bold'
  },
  circleLabel: {
    fontSize: 22,
    fontWeight: 'bold',
    color: white,
    marginTop: DEFAULT_SPACING
  },
  headerOption: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleContainer: { position: 'relative', },
  check: {
    position: 'absolute',
    right: -2,
    bottom: 0
  },
  activeStyle: {
    backgroundColor: white,
    color: darkblue
  },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      resultsContainer: { borderRadius: 12, },
      headerLabel: { fontSize: 28, },
      headerSublabel: {
        fontSize: 16,
        marginTop: 4,
      },
      headerOption: { width: 97, },
      circleText: { fontSize: 36, },
      circleLabel: { fontSize: 42, },
      pillOptionContainer: {
        width: 'fit-content',
        marginTop: 36,
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

function HeaderOption({ assetLabel, assetAmount, isBestPick }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const styleForCircle = isBestPick ? styles.bestPick : styles.notBestPick

  return (
    <View style={styles.headerOption}>
      <View style={styles.circleContainer}>
        <CircleInitial style={styles.circleText} containerStyle={styleForCircle} initial={assetLabel} size={isNotWeb ? 50 : 75} />
        {isBestPick && <View style={styles.check}><CircleIcon size={9} backgroundColor={white} color={primary} type="fas" icon="check" /></View>}
      </View>
      {isBestPick && <Text style={styles.circleLabel}>{`$${abbreviateCurrency(assetAmount)}`}</Text>}
      {!isBestPick && <Text style={[styles.circleLabel, { color: rgba(white, 0) }]}>{`$${abbreviateCurrency(assetAmount)}`}</Text>}
    </View>
  )
}

export default function Header({ assetAAmount, assetBAmount, bestPick, pillOptions, pillValue, onPillPress, type }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View style={[styles.resultsContainer]}>
      <HeaderLabel style={styles.headerLabel}>
        {type.charAt(0).toUpperCase() + type.slice(1)}
        {' '}
        Opportunity Cost
      </HeaderLabel>
      <Text style={styles.headerSublabel}>
        over a
        {pillValue}
        {' '}
        year period
      </Text>

      <View style={styles.optionContainer}>
        <HeaderOption
          assetLabel="A"
          assetAmount={assetAAmount}
          isBestPick={bestPick === 'A'}
        />

        <View style={{ marginTop: isNotWeb ? 0 : -58, position: 'relative' }}>
          <Divider style={{ width: isNotWeb ? 90 : 104 }} />
          <PillLabel title="OR" width={isNotWeb ? 50 : 40} style={{ alignSelf: 'center', position: 'absolute', top: -10 }} />
        </View>

        <HeaderOption
          assetLabel="B"
          assetAmount={assetBAmount}
          isBestPick={bestPick === 'B'}
        />
      </View>

      <View style={styles.pillOptionContainer}>
        {pillOptions.map((option) => (
          <PillButton
            key={option.value}
            label={option.label}
            active={option.value === pillValue}
            inactiveStyle={styles.inactiveStyle}
            activeBackgroundColor={white}
            activeColor={darkblue}
            inactiveBackgroundColor={rgba(white, 0.2)}
            inactiveColor={white}
            style={{ marginHorizontal: 5, paddingHorizontal: isNotWeb ? 12 : 24, }}
            onPress={() => onPillPress(option.value)}
          />
        ))}
      </View>
    </View>
  )
}
