import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import {
  Animated,
  View,
  ScrollView,
  StyleSheet,
  Dimensions,
  Platform,
  useWindowDimensions
} from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { useIsFocused } from '@react-navigation/native'
import { find, last, isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'http'
import moment from 'moment'
import FinPulseSlideupDrawer from '../finPulse/FinPulseSlideupDrawer'
import CongratsFinPulseModal from '../finPulse/CongratsFinPulseModal'
import MyPlanCard from '../dashboard/MyPlanCard'
import {
  getBudgetItems,
  getAccounts,
  getBSAccounts,
  getCurrentUser,
  updateBSAccountPositions,
  getFinancialProjections,
  setCurrentProPlan
} from '../../actions'
import {
  plansSelector,
  primaryProPlanSelector,
  bsAccountsSelector,
  loadingSelector,
  proBudgetSelector,
  financialProjectionsSelector,
  riskAssessmentSelector,
  loansSelector
} from '../../selectors'
import Banner from '../dashboard/Banner'

import ListOptionItem from '../ListOptionItem'

import CongratsSlideupDrawer from '../emergency-preparedness/CongratsSlideupDrawer'

import AccountListDropdown from '../AccountListDropdown'
import MyAccountsOverview from './MyAccountsOverview'

import { ACCOUNTS_BY_CATEGORY } from '../../functions/groupBSAccountsByCategory'
import financialProjectionUtils from '../../functions/financialProjectionUtils'
import getProBudgetStats from '../../functions/getProBudgetStats'
import getProjectedBudgetItemsByCategory from '../../functions/getProjectedBudgetItemsByCategory'

import { gray, info, lightestgray, rgba } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

import HeaderLabel from '../HeaderLabel'
import { ASSET, LIABILITY } from '../../constants'
import WellnessToolsList from '../WellnessToolsList'
import Tout from '../dashboard/Tout'

const basicStyles = {
  scrollView: {
    zIndex: 200,
    elevation: 200,
    backgroundColor: lightestgray
  },
  divider: {
    borderBottomColor: gray,
    opacity: 0.2,
    margin: DEFAULT_SPACING * 2
  },
  listItem: { marginHorizontal: DEFAULT_SPACING },
  wellnessToolsList: {
    marginTop: DEFAULT_SPACING,
    paddingHorizontal: DEFAULT_SPACING
  },
  snapshotContainer: {
    marginHorizontal: DEFAULT_SPACING,
    marginTop: DEFAULT_SPACING
  },
  snapshotTitle: { marginBottom: DEFAULT_SPACING },
  snapshotItem: {},
  extraOptionList: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    scrollView: { paddingHorizontal: DEFAULT_SPACING },
    toolsTitle: {
      marginTop: 32,
      marginBottom: DEFAULT_SPACING,
      width: '100%'
    },
    wellnessToolsList: {
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    snapshotTitle: { width: '100%' },
    snapshotContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
    snapshotItem: { width: 'calc(50% - 6px)' },
    extraOptionList: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      marginHorizontal: DEFAULT_SPACING
    },
    listItem: { width: 'calc(50% - 6px)', marginHorizontal: 0 }
  },
  [minSize(DEVICE_SIZES.LG)]: { wellnessToolsList: { flexDirection: 'row' } }
})

export default function PostAuthScreen({ currentUser, navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const window = useWindowDimensions()
  const dispatch = useDispatch()
  const [freePlan, setFreePlan] = useState({})
  const [finPulseVisible, setFinPulseVisible] = useState(false)
  const [finPulseCongratsVisible, setFinPulseCongratsVisible] = useState(false)
  const primaryProPlan = useSelector(primaryProPlanSelector)
  const plans = useSelector((state) => plansSelector(state))
  const paddingTop = isNotWeb ? window.height / 8 : window.width < 1024 ? 450 : 572
  const isFocused = useIsFocused()
  const { assessScore } = useSelector((state) => riskAssessmentSelector(state))
  const loans = useSelector((state) => loansSelector(state))
  const totalLoanCost = loans.reduce((acc, loan) => acc + parseFloat(loan.balance), 0)

  // Pro Data
  const bsAccounts = useSelector(bsAccountsSelector)
  const { loading } = useSelector(loadingSelector)
  const budget = useSelector(proBudgetSelector)

  async function fetchFreePlan() {
    if (!primaryProPlan) return

    const { freePlanId } = primaryProPlan

    try {
      const relatedFreePlan = find(plans, (plan) => plan.id === freePlanId)
      const freePlanResponse = await get(`/api/v1/plans/${freePlanId}/`)
      setFreePlan({ ...relatedFreePlan, ...freePlanResponse.data })
    } catch (e) {
      console.warn(e, e.response)
    }
  }

  useEffect(() => {
    if (isFocused) dispatch(getCurrentUser())
    // setTimeout(() => {
    //   setFinPulseVisible(true)
    // }, 1000)
  }, [isFocused])

  useEffect(() => {
    if (isFocused) {
      fetchFreePlan()
      dispatch(getAccounts())
      dispatch(getBSAccounts())
      if (primaryProPlan) {
        dispatch(getBudgetItems(primaryProPlan.planId))
        dispatch(getFinancialProjections(primaryProPlan.planId))
      }
    }
  }, [currentUser, primaryProPlan, isFocused])

  let status = freePlan?.preparedness_level ? freePlan.preparedness_level : 'LEVEL_0'
  status = status.replace('_', ' ')

  const offset = useRef(new Animated.Value(0)).current

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  const onShowAccount = (account) => {
    navigation.navigate('Modal', {
      screen: 'View BS Account',
      params: { account }
    })
  }

  const onAddBSAccount = (type, category) => () => {
    const cat = ACCOUNTS_BY_CATEGORY.find((obj) => obj.label == category)

    navigation.navigate('Modal', {
      screen: 'Edit/Add BS Accounts',
      params: {
        accountType: type,
        category,
        categories: cat ? cat.values : null
      }
    })
  }

  const { assetAccounts } = bsAccounts
  const groupedAssets = {}

  ACCOUNTS_BY_CATEGORY.forEach((category) => {
    if (!groupedAssets[category.label]) groupedAssets[category.label] = []

    assetAccounts.forEach((account) => {
      if (category.values.includes(account.category)) {
        groupedAssets[category.label].push(account)
      }
    })
  })

  function onDragEnd(fromIndex, toIndex, accountType) {
    // Clone accounts
    let updatedLiabilityAccounts = [...bsAccounts.liabilityAccounts]
    const updatedGroupedAssets = { ...groupedAssets }

    // Select relevant data
    const accountsToUpdate =
      accountType === 'liability' ? updatedLiabilityAccounts : updatedGroupedAssets[accountType]

    // Move account
    const account = accountsToUpdate.splice(fromIndex, 1)[0]
    accountsToUpdate.splice(toIndex, 0, account)

    // Update relevant data
    if (accountType === 'liability') {
      updatedLiabilityAccounts = accountsToUpdate
    } else {
      updatedGroupedAssets[accountType] = accountsToUpdate
    }

    // Update accounts order
    const orderedAccounts = ACCOUNTS_BY_CATEGORY.reduce((acc, category) => {
      const accounts =
        category.label === accountType ? accountsToUpdate : updatedGroupedAssets[category.label]
      return acc.concat(accounts)
    }, updatedLiabilityAccounts)

    // Set new position
    const accountsWithPosition = orderedAccounts.map((account, index) => ({
      ...account,
      position: index
    }))

    dispatch(updateBSAccountPositions(accountsWithPosition))
  }

  const { goal } = primaryProPlan
  const { amount } = goal || {}

  const getProjectedBudget = (projection) => {
    if (isEmpty(projection)) {
      return {
        budgetItemsByCategory: {},
        total: 0,
        count: 0
      }
    }

    const projectedBudgetItemsByCategory = getProjectedBudgetItemsByCategory(projection, budget)

    return {
      budgetItemsByCategory: projectedBudgetItemsByCategory,
      ...getProBudgetStats(projectedBudgetItemsByCategory)
    }
  }

  const selectedDate = moment().format('YYYY-MM-DD')
  const projections = useSelector(financialProjectionsSelector)
  const { findAtDate: findProjection } = financialProjectionUtils(projections)
  const currentProjection = getProjectedBudget(findProjection(selectedDate))

  const budgetItemsByCategory = currentProjection.budgetItemsByCategory || {}
  const saveFirstContributionTarget = budgetItemsByCategory.SAVE_FIRST_CONTRIBUTION_TARGET || {
    total: 0
  }

  const monthlyContribution = saveFirstContributionTarget.total

  return (
    <>
      <View style={{ flex: 1, backgroundColor: lightestgray, position: 'relative' }}>
        <Banner
          currentUser={currentUser}
          animatedValue={offset}
          navigation={navigation}
          primaryPlan={primaryProPlan}
          pro
        />

        <ScrollView
          contentContainerStyle={{ paddingBottom: 200 }}
          scrollEventThrottle={16}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], {
            useNativeDriver: false
          })}
          style={[styles.scrollView, { paddingTop }]}
        >
          <View style={{ paddingTop: 400, padding: DEFAULT_SPACING }}>
            {freePlan && primaryProPlan && (
              <MyPlanCard
                monthlyContribution={monthlyContribution}
                goalAmount={amount}
                onPress={() => {
                  console.log('primaryProPlan', primaryProPlan)
                  dispatch(setCurrentProPlan(primaryProPlan))
                  navigation.navigate('Modal', { screen: 'Plan Summary' })
                }}
                pro
              />
            )}
          </View>

          <View style={styles.wellnessToolsList}>
            <WellnessToolsList
              pro
              navigation={navigation}
              isBeta={currentUser?.is_beta}
              status={status}
              selfAssessed={assessScore.is_self_assessed}
              blankAssessment={isEmpty(assessScore)}
              loansLength={loans.length}
              totalLoanCost={totalLoanCost}
            />
          </View>

          <View style={styles.snapshotContainer}>
            <HeaderLabel style={styles.snapshotTitle}>My Snapshot</HeaderLabel>

            <View style={styles.snapshotItem}>
              <MyAccountsOverview data={bsAccounts} />
            </View>
            <View style={styles.snapshotItem}>
              {Object.keys(groupedAssets).map((category) => (
                <AccountListDropdown
                  key={category}
                  style={{ marginBottom: DEFAULT_SPACING }}
                  accounts={groupedAssets[category]}
                  isAsset
                  onShowAccount={onShowAccount}
                  onAddAccount={onAddBSAccount(ASSET, category)}
                  category={category}
                  label={category}
                  loading={loading}
                  draggable
                  onDragEnd={(from, to) => {
                    onDragEnd(from, to, category)
                  }}
                />
              ))}

              <AccountListDropdown
                style={{ marginBottom: DEFAULT_SPACING }}
                accounts={bsAccounts.liabilityAccounts}
                isAsset={false}
                onShowAccount={onShowAccount}
                onAddAccount={onAddBSAccount(LIABILITY, null)}
                label="Debt & Loans"
                loading={loading}
                draggable
                onDragEnd={(from, to) => {
                  onDragEnd(from, to, 'liability')
                }}
              />
            </View>
          </View>

          <View style={styles.extraOptionList}>
            <ListOptionItem
              label="My Balance Sheet"
              sublabel="See the details of my current and projected financials"
              style={styles.listItem}
              iconName="file-invoice-dollar"
              iconColor={info}
              iconType="far"
              iconSize={30}
              iconBackgroundColor={rgba(info, 0.1)}
              onPress={() => {
                navigation.navigate('Modal', { screen: 'Balance Sheet Screen' })
              }}
              navigatable
            />

            <ListOptionItem
              label="Cash Waterfall"
              sublabel="Set up your accounts for advanced budgeting and forecasting!"
              style={styles.listItem}
              iconName="diagram-project"
              iconColor={info}
              iconType="far"
              iconSize={30}
              iconBackgroundColor={rgba(info, 0.1)}
              onPress={() => navigation.navigate('Modal', { screen: 'Cash Flow Waterfall Intro' })}
              navigatable
            />
          </View>
          <Tout />
        </ScrollView>

        <CongratsSlideupDrawer statusNumber={last(status.split(' '))} />
        <FinPulseSlideupDrawer
          visible={finPulseVisible}
          setVisible={setFinPulseVisible}
          navigation={navigation}
        />
      </View>
      <CongratsFinPulseModal
        visible={finPulseCongratsVisible}
        setVisible={setFinPulseCongratsVisible}
      />
    </>
  )
}
