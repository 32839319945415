import { get } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'

export const SET_BUDGET_CATEGORIES = 'SET_BUDGET_CATEGORIES'

// eslint-disable-next-line import/prefer-default-export
export const getBudgetCategories = () => async (dispatch) => {
  try {
    const result = await get(proApplicationEndpoints.getCategories())
    dispatch({
      type: SET_BUDGET_CATEGORIES,
      payload: result.data.budgetCategories,
    })
  } catch (e) {
    console.warn(e, e.response)
  }
}
