import * as Yup from 'yup'

import {
  INVALID_INT,
  INVALID_MIN,
  REQUIRED,
} from './constants'

export const Fields = {
  shortTermCashAccount: 'shortTermCashAccountId',
  shortTermDebtAccount: 'shortTermDebtAccountId',
  shortTermOverflowAccount: 'cashOverflowAccountId',
  minCashFlow: 'minCash',
  maxCashFlow: 'maxCash',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.shortTermCashAccount]: Yup.string(),
  [Fields.shortTermDebtAccount]: Yup.string(),
  [Fields.shortTermOverflowAccount]: Yup.string(),
  [Fields.minCashFlow]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT).required(REQUIRED),
  [Fields.maxCashFlow]: Yup.number()
    .when(Fields.minCashFlow, (minCashFlow, schema) => {
      if (!minCashFlow) return
      return schema.min(minCashFlow, 'The maximum value designated must be higher than the minimum value designated to be held in your Short Term Cash Management Account')
    })
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .required(REQUIRED)
})

export const InitialValues = (proPlan = {}) => {
  const { cashSweepLogic = {} } = proPlan

  return {
    [Fields.shortTermCashAccount]: cashSweepLogic[Fields.shortTermCashAccount] || '',
    [Fields.shortTermDebtAccount]: cashSweepLogic[Fields.shortTermDebtAccount] || '',
    [Fields.shortTermOverflowAccount]: cashSweepLogic[Fields.shortTermOverflowAccount] || '',
    [Fields.minCashFlow]: cashSweepLogic[Fields.minCashFlow] || 8000,
    [Fields.maxCashFlow]: cashSweepLogic[Fields.maxCashFlow] || 12000
  }
}
