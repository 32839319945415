import Input from '../Input'
import CurrencyInput from '../CurrencyInput'
import NumberInput from '../NumberInput'
import RadioGroup from '../RadioGroup'

import { DOWNPAYMENT_OPTIONS } from '../../constants'

export default function BuyCarForm({ Fields, touched, errors, handleChange, handleBlur, values, handleSubmit }) {
  return (
    <>
      <Input
        label="Name"
        name={Fields.name}
        touched={touched[Fields.name]}
        errorMessage={errors[Fields.name]}
        onChangeText={handleChange(Fields.name)}
        onBlur={handleBlur(Fields.name)}
        value={values[Fields.name]}
        returnKeyType="next"
      />

      <Input
        name={Fields.year}
        errorMessage={errors[Fields.year]}
        touched={touched[Fields.year]}
        onChangeText={handleChange(Fields.year)}
        onBlur={handleBlur(Fields.year)}
        value={values[Fields.year]}
        label="Years until Purchase"
        returnKeyType="next"
        keyboardType="number-pad"
        enablesReturnKeyAutomatically
      />

      <CurrencyInput
        prefix="$"
        label={"Expected Cost (in Today's Dollars)"}
        value={values[Fields.currentValue]}
        name={Fields.currentValue}
        touched={touched[Fields.currentValue]}
        errorMessage={errors[Fields.currentValue]}
        onChangeText={handleChange(Fields.currentValue)}
        precision={0}
      />

      <RadioGroup
        name={Fields.isDownPaymentAmount}
        errorMessage={errors[Fields.isDownPaymentAmount]}
        touched={touched[Fields.isDownPaymentAmount]}
        label="Down Payment Format"
        value={values[Fields.isDownPaymentAmount]}
        options={DOWNPAYMENT_OPTIONS}
        onPress={(value) => {
          handleChange(Fields.downPayment)({
            target: {
              name: Fields.downPayment,
              value: ''
            }
          })

          handleChange(Fields.isDownPaymentAmount)({
            target: {
              name: Fields.isDownPaymentAmount,
              value
            }
          })
        }}
      />

      { values[Fields.isDownPaymentAmount] ? (
        <CurrencyInput
          prefix="$"
          label="Down Payment ($)"
          value={values[Fields.downPayment]}
          name={Fields.downPayment}
          touched={touched[Fields.downPayment]}
          errorMessage={errors[Fields.downPayment]}
          onChangeText={handleChange(Fields.downPayment)}
          precision={0}
        />
      ) : (
        <NumberInput
          precision={3}
          suffixUnit="%"
          label="Down Payment (%)"
          value={values[Fields.downPayment]}
          name={Fields.downPayment}
          touched={touched[Fields.downPayment]}
          errorMessage={errors[Fields.downPayment]}
          onChangeText={handleChange(Fields.downPayment)}
          returnKeyType="next"
          keyboardType="number-pad"
        />
      )}

      <NumberInput
        precision={3}
        suffixUnit="%"
        name={Fields.interestRate}
        errorMessage={errors[Fields.interestRate]}
        touched={touched[Fields.interestRate]}
        onChangeText={handleChange(Fields.interestRate)}
        onBlur={handleBlur(Fields.interestRate)}
        value={values[Fields.interestRate]}
        label="Interest Rate (%) of Car Loan"
        returnKeyType="next"
        keyboardType="number-pad"
      />

      <NumberInput
        name={Fields.months}
        errorMessage={errors[Fields.months]}
        touched={touched[Fields.months]}
        onChangeText={handleChange(Fields.months)}
        onBlur={handleBlur(Fields.months)}
        value={values[Fields.months]}
        label="Auto Loan Terms (Months)"
        returnKeyType="next"
        keyboardType="number-pad"
        onSubmitEditing={handleSubmit}
      />
    </>
  )
}
