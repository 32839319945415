import { Platform, View, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { map } from 'lodash'
import { lightestgray, white } from '../styles/colors'
import { DEFAULT_SPACING } from '../styles'
import Button from './Button'

const basicStyles = {
  container: {
    flexDirection: 'row',
    backgroundColor: lightestgray,
    padding: 2,
    borderRadius: 10
  },
  title: { fontSize: 14 },
  button: {
    paddingVertical: DEFAULT_SPACING,
    flex: 1
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  mobileStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: { title: { fontSize: 18 }, },
    [minSize(DEVICE_SIZES.LG)]: {},
  }
)

function calculateStyleBasedOnIndex(length, index) {
  if (index == 0) {
    return {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  } if (index === length - 1) {
    return {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  }
  return {
    borderRadius: 0,
    borderWidth: 0
  }
}

function calculateBasedOnState(style, active, colorSelected, colorUnselected) {
  if (active) {
    return { ...style, backgroundColor: colorSelected, borderRadius: 10 }
  }
  return { ...style, backgroundColor: colorUnselected, borderRadius: 10 }
}

export default function ButtonGroup(props) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { options, onPress, selected, style, colorSelectedFont, colorUnselectedFont, colorSelected, colorUnselected, } = props
  const customColorSelectedFont = colorSelectedFont || '#283A49';
  const customColorUnselectedFont = colorUnselectedFont || 'rgba(40, 58, 73, 0.5)'
  const customColorSelected = colorSelected || white;
  const customColorUnselected = colorUnselected || lightestgray;
  return (
    <View style={[styles.container, style]}>
      {map(options, (option, index) => (
        <Button
          key={`button-group-${index}-${option}`}
          style={[
            calculateBasedOnState(calculateStyleBasedOnIndex(options.length, index), option === selected, customColorSelected, customColorUnselected),
            styles.button
          ]}
          onPress={() => onPress(option)}
          titleStyle={[styles.title, { color: option === selected ? customColorSelectedFont : customColorUnselectedFont }]}
        >
          {option}
        </Button>
      ))}
    </View>

  )
}
