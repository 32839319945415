import { Text, View, StyleSheet, Animated, useWindowDimensions, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import CircleIcon from '../CircleIcon'

import { white, DEFAULT_SPACING, rgba, grayblue, gunmetal, primary } from '../../styles'

import formatCurrency from '../../functions/formatCurrency'
import BottomButton from '../BottomButton'

const basicStyles = {
  container: {
    alignItems: 'center',
    height: 150,
    left: 0,
    right: 0,
    position: 'absolute',
    paddingBottom: DEFAULT_SPACING,
    top: 100,
    zIndex: 100
  },
  card: {
    position: 'absolute',
    zIndex: 1000,
    backgroundColor: white,
    padding: DEFAULT_SPACING,
    borderRadius: 10,
    shadowColor: 'rgba(0, 0, 0, 0.24)',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: 11,
    shadowOpacity: 1,
    elevation: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
    color: grayblue,
    marginTop: DEFAULT_SPACING
  },
  subheader: {
    textAlign: 'center',
    fontSize: 14,
    color: gunmetal
  },
  body: {
    textAlign: 'center',
    fontSize: 12,
    color: gunmetal,
    marginTop: DEFAULT_SPACING,
    marginBottom: 0,
    fontWeight: '500'
  },
  annualInvestmentContainer: {
    alignItems: 'center',
    marginVertical: DEFAULT_SPACING
  },
  label: {
    fontSize: 16,
    color: grayblue,
    marginTop: DEFAULT_SPACING,
    fontWeight: '500'
  },
  value: {
    textAlign: 'right',
    fontWeight: '600',
    fontSize: 24,
    color: primary,
    backgroundColor: rgba(primary, 0.1),
    paddingVertical: DEFAULT_SPACING / 2,
    paddingHorizontal: DEFAULT_SPACING,
    borderRadius: 6,
    marginTop: DEFAULT_SPACING / 2
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  mobileStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: { width: 360, height: 380, top: 70, },
      card: { height: 680, paddingTop: 30, paddingBottom: 80, justifyContent: 'flex-start', },
      header: { fontSize: 24, marginTop: 28, },
      subheader: { fontSize: 16, },
      body: { fontSize: 16, marginTop: 36, },
      annualInvestmentContainer: { marginTop: 66, }
    },
    [minSize(DEVICE_SIZES.LG)]: {},
  }
)

export default function InvestmentProfileCard({ label, sublabel, icon, color, loading, moreInformation, annualContributionAmount, animatedValue, onPress }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { width } = useWindowDimensions()
  const height = isNotWeb ? 170 : 680;
  
  const opacity = animatedValue.interpolate({
    inputRange: [0, height - 100],
    outputRange: [1, 0],
    extrapolate: 'clamp',
    useNativeDriver: true
  })
  const alternativeOpacity = animatedValue.interpolate({
    inputRange: [50, height - 100],
    outputRange: [0, 1],
    extrapolate: 'clamp',
    useNativeDriver: true
  })
  const display = animatedValue.interpolate({
    inputRange: [0, height - 110, height - 100],
    outputRange: [1, 1, 0],
    extrapolate: 'clamp',
    useNativeDriver: true
  })
  const animatedStyle = { transform: [{ scaleY: isNotWeb ? display : 1 }], };

  return (
    <>
      <Animated.View
        style={[
          styles.container, animatedStyle,
          {
            opacity: isNotWeb ? opacity : 1,
            display: 'flex',
            position: isNotWeb ? 'absolute' : 'relative',
          }]}
      >
        <View
          style={[styles.card, { width: isNotWeb ? (width - DEFAULT_SPACING * 6) : 360, }]}
        >
          <CircleIcon
            icon={icon}
            color={color}
            backgroundColor={rgba(color, 0.1)}
            size={isNotWeb ? 35 : 60}
          />
          <Text style={styles.header}>
            {label}
          </Text>
          <Text style={styles.subheader}>
            {sublabel}
          </Text>

          <Text style={styles.body}>
            {moreInformation}
          </Text>
          <View style={styles.annualInvestmentContainer}>
            <Text style={styles.label}>
              Annual Investment
            </Text>
            <Text style={styles.value}>
              $
              {loading ? '—' : formatCurrency(annualContributionAmount)}
            </Text>
          </View>
          {!isNotWeb && (
            <BottomButton
              withMargin
              onPress={onPress}
            >
              Choose this Investor Profile
            </BottomButton>
          )}
        </View>
      </Animated.View>

      {isNotWeb && (
        <Animated.View style={[styles.container, { opacity: alternativeOpacity, position: 'absolute' }]}>
          <View style={[styles.card, { width: width - DEFAULT_SPACING * 6, height: Platform.OS === 'android' ? 150 : 130, }]}>
            <View>
              <Text style={[styles.header, { width: '100%', justifyContent: 'center', textAlign: 'center' }]}>
                {label}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '100%' }}>

              <View>
                <CircleIcon
                  icon={icon}
                  color={color}
                  backgroundColor={rgba(color, 0.1)}
                  size={25}
                  style={{ top: 20 }}
                />
              </View>

              <View>
                <Text style={styles.label}>
                  Annual Investment
                </Text>
                <View>
                  <Text style={[styles.value, { justifyContent: 'center', textAlign: 'center' }]}>
                    $
                    {loading ? '—' : formatCurrency(annualContributionAmount)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Animated.View>
      )}
    </>
  )
}
