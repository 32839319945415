import { useState } from 'react'
import { Platform, Text, View, StyleSheet } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import hapticFeedback from './hapticFeedback'
import ListItem from './ListItem'
import Link from './Link'
import Modal from './Modal'
import CheckBox from './CheckBox'
import CircleIcon from './CircleIcon'
import TouchableOpacity from './TouchableOpacity'
import { DEFAULT_SPACING, white, lightestgray, lightgray, gray, darkblue } from '../styles'
import TooltippedText from './TooltippedText'

const basicStyles = {
  title: { padding: 16 },
  label: {
    fontSize: 16,
    color: darkblue,
    fontWeight: '600',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sublabel: {
    fontSize: 12,
    color: gray
  },
  container: {
    backgroundColor: white,
    borderWidth: 1,
    borderColor: lightestgray,
    borderRadius: 10,
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  icon: { marginRight: DEFAULT_SPACING, },
  checkBox: (checkLeft) => ({
    marginLeft: checkLeft ? 0 : DEFAULT_SPACING,
    marginRight: checkLeft ? DEFAULT_SPACING : 0,
  }),
  linkContainer: {
    justifyContent: 'flex-start',
    minHeight: 0
  },
  link: { textAlign: 'left' },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        padding: DEFAULT_SPACING * 2,
        marginBottom: 0,
      },
      row: { height: 60, },
      label: { fontSize: 22, },
      sublabel: { fontSize: 14, },
      icon: { marginRight: 40, },
    },
  }
)

export default function ListOptionItem({
  iconComponent,
  iconName,
  iconColor,
  iconBackgroundColor,
  iconSize,
  labelStyle,
  iconType = 'fas',
  label,
  sublabel,
  milestoneSubLabel,
  learnMoreValue,
  value,
  navigatable,
  onPress,
  onEdit,
  onCheck,
  checked,
  children,
  requireCheckForChildren = true,
  numberOfLines = 1,
  style = {},
  containerStyle = {},
  actionName,
  goToWellnessPage,
  completed = true,
  goToEditGoal,
  checkLeft = true

}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [visible, setVisible] = useState(false)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  return (
    <TouchableOpacity
      action={actionName}
      style={style}
      onPress={() => {
        if (onCheck) onCheck(value)
        if (onPress) onPress(value)
        hapticFeedback()
      }}
    >
      <ListItem style={[styles.container, containerStyle]}>
        <View style={styles.row}>
          {!iconComponent && iconName && iconBackgroundColor && (
            <CircleIcon style={styles.icon} size={iconSize || 24} type={iconType} icon={iconName} color={iconColor} backgroundColor={iconBackgroundColor} />
          )}
          {!iconComponent && iconName && !iconBackgroundColor && <FontAwesomeIcon style={styles.icon} icon={[iconType, iconName]} size={iconSize || 24} color={iconColor || lightgray} />}

          {iconComponent}
          {onCheck && checkLeft && <CheckBox style={styles.checkBox(checkLeft)} value={checked} onToggle={() => onCheck(value)} />}

          <View style={{ flex: 2 }}>
            <View style={styles.labelContainer}>
              {!learnMoreValue
                && (
                  <Text numberOfLines={numberOfLines} style={[styles.label, labelStyle]}>
                    {label}
                  </Text>
                )}

              {learnMoreValue
                && (
                  <TooltippedText
                    visible={tooltipVisible}
                    setVisible={setTooltipVisible}
                    anchorStyle={[styles.label, labelStyle]}
                    anchorText={label}
                    contentHeader={label}
                    contentBody={learnMoreValue}
                  />
                )}
            </View>

            {sublabel
              && (
                <Text style={styles.sublabel}>
                  {sublabel}
                </Text>
              )}

            {!onCheck && learnMoreValue
              && <Link containerStyle={styles.linkContainer} style={styles.link} onPress={() => setVisible(true)}>Learn More</Link>}

          </View>

          {!requireCheckForChildren && children && (
            <View style={{ flex: 1 }}>
              {children}
            </View>
          )}

          {navigatable
            && (
              <View style={{ width: 50 }}>
                <FontAwesomeIcon style={{ alignSelf: 'flex-end' }} icon="circle-chevron-right" size={18} color={lightgray} />
              </View>
            )}
          {completed === false && goToWellnessPage
            && (
              <TouchableOpacity style={[label ? {} : { marginBottom: 0, marginLeft: 10 }]} onPress={goToWellnessPage}>
                <FontAwesomeIcon style={{ alignSelf: 'flex-end' }} icon="lightbulb" size={iconSize || 15} color="#5F8EE8" />
              </TouchableOpacity>
            )}
          {goToEditGoal
            && (
              <TouchableOpacity style={[label ? {} : { marginBottom: 0, marginLeft: 10 }]} onPress={goToEditGoal}>
                <FontAwesomeIcon style={{ alignSelf: 'flex-end' }} icon="pencil" size={iconSize || 15} color="#5F8EE8" />
              </TouchableOpacity>
            )}

          {onEdit
            && (
              <View style={{ width: 50 }}>
                <FontAwesomeIcon style={{ alignSelf: 'flex-end' }} icon="ellipsis-vertical" size={18} color={lightgray} />
              </View>
            )}

          {onCheck && !checkLeft && <CheckBox style={styles.checkBox(checkLeft)} value={checked} onToggle={() => onCheck(value)} />}
        </View>
        {milestoneSubLabel
          && (
            <Text style={[styles.sublabel, { marginLeft: 36 }]}>
              {milestoneSubLabel}
            </Text>
          )}
        {requireCheckForChildren && checked && children
          && (
            <View style={[styles.row, { paddingTop: DEFAULT_SPACING }]}>
              <View style={{ flex: 2 }}>
                {children}
              </View>
            </View>
          )}

      </ListItem>

      {learnMoreValue
        && (
          <Modal closeable visible={visible} setVisible={setVisible}>
            <Text>{learnMoreValue}</Text>
          </Modal>
        )}
    </TouchableOpacity>
  )
}
