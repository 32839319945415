import { useEffect, useLayoutEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { get, post } from 'http'
import { useDispatch } from 'react-redux';
import { DEFAULT_SPACING, lightestgray, success, white } from '../../../styles'
import QuizCard from './QuizCard'
import ProgressBar from '../../ProgressBar'
import { getAssessScore } from '../../../actions';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: DEFAULT_SPACING * 2,
    height: '100%',
    backgroundColor: white,
  },
  progressBar: {
    borderRadius: 8,
    overflow: 'hidden',
    width: '100%',
  },
})

function RiskAssessmentWizard({ navigation }) {
  const [quezList, setQuezList] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [assessAnswers, setAssessAnswers] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    async function getQuez() {
      const res = await get('/api/v1/risk-assessment/questions/')
      if (res.data) setQuezList(res.data)
    }

    getQuez()
  }, [])

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: true, title: 'Risk Profile', })
  }, [navigation])

  const handleSelect = (val) => {
    if (quezList && quezList.length > 0) {
      const answers = [...assessAnswers]
      answers[quezList[activeStep].question_id] = val
      setAssessAnswers(answers)
    }
  }

  const handlePrev = () => {
    if (activeStep > 0) setActiveStep((prev) => (prev - 1))
  }
  const handleNext = async () => {
    if (activeStep + 1 < quezList.length) { setActiveStep((prev) => (prev + 1)) } else {
      const answers = assessAnswers.map(
        (answer, index) => (answer ? { question_id: index, answer } : null)
      ).filter((answer) => !!answer)
      const res = await post('/api/v1/risk-assessment/answer/', answers)
      if (res.data) {
        dispatch(getAssessScore())
        navigation.navigate('Modal', { screen: 'My Risk Profile' })
      }
    }
  }

  return (
    <View style={{ height: '100%' }}>
      {quezList && quezList.length > 0 && (
        <View style={styles.container}>
          <View style={styles.progressBar}>
            <ProgressBar
              width="100%"
              percent={(activeStep + 1) * 100 / quezList.length}
              progressBarColor={success}
              backgroundBarColor={lightestgray}
              height={8}
            />
          </View>
          <Text style={{ position: 'absolute', right: 20, top: 12 }}>
            {activeStep + 1}
            /
            {quezList.length}
          </Text>
          <QuizCard
            key={activeStep}
            data={quezList[activeStep]}
            value={assessAnswers[quezList[activeStep].question_id]}
            step={activeStep}
            goPrev={handlePrev}
            goNext={handleNext}
            onPress={handleSelect}
          />
        </View>
      )}
    </View>
  )
}

export default RiskAssessmentWizard
