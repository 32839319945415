import { useEffect, useState } from 'react'
import { View, Text, StyleSheet, Image, } from 'react-native'
import { VictoryArea, VictoryChart, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer, } from 'victory-native';
import { groupBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { rgba, DEFAULT_SPACING, secondary, white, gray, lightgray, darkblue, grayblue } from '../../styles'
import HeaderLabel from '../HeaderLabel'
import Link from '../Link'
import abbreviateCurrency from '../../functions/abbreviateCurrency'
import Legend from '../Legend'
import LittleMan from '../../assets/little-man.png'
import CircleIcon from '../CircleIcon'
import CircleNumber from '../CircleNumber'
import TouchableOpacity from '../TouchableOpacity'
import EventsSlideup from './EventsSlideup'
import { lifeEventOptions } from '../../constants'
import { getLifeEvents } from '../../actions'
import { lifeEventsSelector } from '../../selectors'

const info = '#3491F7'
const orange = '#E06A44'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: white,
    paddingVertical: DEFAULT_SPACING * 2,
    marginBottom: 100
  },
  sublabel: {
    textAlign: 'center',
    fontSize: 12,
    letterSpacing: -0.2,
    color: secondary
  }
})

const InitialImage = require('../../assets/life-event-empty-state.png')

function GroupedEvents({ year, plan, eventsFromGroup, height, widthBetweenOneYear, navigation }) {
  const [visible, setVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const circleRadius = 24
  const currentYear = new Date().getFullYear()

  const evt = eventsFromGroup[0]

  return (
    <>
      <View style={{ position: 'absolute', top: 0, left: widthBetweenOneYear * Number(year), width: 1, height, backgroundColor: '#ECF1F4' }} />

      <TouchableOpacity
        onPress={
          () => setVisible(true)
        }
        key={`${year}-group`}
        style={{ marginBottom: 20, top: height + 4, position: 'absolute', left: widthBetweenOneYear * Number(year) - circleRadius, }}
      >
        <View style={{ alignItems: 'center' }}>
          {eventsFromGroup.length > 1 ? (
            <CircleNumber number={eventsFromGroup.length} color={lifeEventOptions[evt.key].color} backgroundColor={rgba(lifeEventOptions[evt.key].color, 0.1)} />
          ) : (
            <CircleIcon icon={lifeEventOptions[evt.key].icon} color={lifeEventOptions[evt.key].color} backgroundColor={rgba(lifeEventOptions[evt.key].color, 0.1)} />
          )}
          <Text style={{ color: darkblue, fontSize: 10, marginTop: 2, }}>{Number(year) + currentYear}</Text>
        </View>
      </TouchableOpacity>

      <EventsSlideup plan={plan} navigation={navigation} visible={visible} setVisible={setVisible} events={eventsFromGroup} setModalVisible={setModalVisible} />
    </>
  )
}

const InitialLifeEventsSection = () => (
  <>
    <Text style={styles.sublabel}>
      See how life's big events will affect your
      {'\n'}
      financial situation
    </Text>
    <Image
      source={InitialImage}
      style={{ width: '100%', height: 140 }}
      resizeMode="contain"
    />
  </>
)


function LifeEventsSection({ width, events, plan, netWealth = [], returnData = [], navigation }) {
  const maxChartWrapperWidth = 1016
  const chartWrapperWidth = Math.min(width - 138, maxChartWrapperWidth)
  const chartWidth = chartWrapperWidth - 96

  const max = Math.max(...returnData, ...netWealth)
  const min = Math.min(...returnData, ...netWealth)
  const deltaY = - (min > 0 ? 0 : min)

  const data = { returns: [], impact: [] }
  const [selectedIndex, setSelectedIndex] = useState(-1)

  if (returnData.length == 0 && netWealth.length == 0) {
    return <InitialLifeEventsSection />
  }

  // current year
  const currentYear = new Date().getFullYear()

  // iterate over return data and assign the value to a timestamp of the first day of the year
  returnData.forEach((item, i) => {
    data.returns.push({
      x: currentYear + i,
      y: item + deltaY,
      title: 'Original Path',
      color: info,
    })
  })

  // iterate over impact data and assign the value to a timestamp of the first day of the year
  netWealth.forEach((item, i) => {
    data.impact.push({
      x: currentYear + i,
      y: item + deltaY,
      title: 'Event Path',
      color: orange,
    })
  })

  const groupByNumber = Math.floor(netWealth.length / 10)
  const groupedEvents = groupBy(events, (event) => Math.floor(event.year / groupByNumber) * groupByNumber)

  const formatYAxisLabel = (tick) => `$${abbreviateCurrency((tick + (min > 0 ? 0 : min)), 1)}`

  const handleChartMouseDown = (event) => {
    setSelectedIndex(((event.clientX / (chartWidth / (netWealth.length - 1))) - 1.4).toFixed(0) * 1);
  };

  return (
    <View style={{ flex: 1, alignItems: 'center' }}>
      <Legend
        sublabel="Estimated Value"
        options={[
          {
            color: info,
            text: 'Original Path',
            dotSize: 14,
            textStyle: {fontSize: 12, color: grayblue, marginTop: 3, marginLeft: 2, marginRight: 8, }
          },
          {
            color: orange,
            text: 'Event Path',
            dotSize: 14,
            textStyle: {fontSize: 12, color: grayblue, marginTop: 3, marginLeft: 2,}
          }
        ]}
        style={{ marginBottom: DEFAULT_SPACING, }}
      />
      <View style={{ paddingLeft: 40, marginTop: -40, }}>
        <VictoryChart
          width={chartWrapperWidth}
          height={300}
          containerComponent={
            <VictoryVoronoiContainer voronoiDimension="x"
              labels={({ datum }) => `${datum.title === 'Original Path' ? `Year: ${datum.x} \n` : ''}${datum.title}: ${(datum.y + (min < 0 ? min : 0)).toFixed(0)}`}
              labelComponent={<VictoryTooltip style={{ textAnchor: 'start', fill: gray, }} flyoutStyle={{ fill: "white" }} />}
            />
          }
          events={[{ target: 'parent', eventHandlers: { onMouseDown: handleChartMouseDown } }]}
        >
          <VictoryAxis
            style={{
              axis: { stroke: 'none' }, // Customize the axis line color
              tickLabels: { fontSize: 12, fill: gray }, // Customize the tick label style
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={formatYAxisLabel}
            style={{
              axis: { stroke: 'none' }, // Customize the axis line color
              tickLabels: { fontSize: 12, fill: gray }, // Customize the tick label style
              grid: { stroke: lightgray, strokeDasharray: '2, 4' }, // Customize the grid line style
            }}
          />
          <VictoryArea
            name='returns'
            data={data.returns}
            style={{
              data: {
                stroke: info, // Customize the line color
                strokeWidth: 2, // Increase the line width for bold appearance
                fill: 'url(#infoGradient)', // Customize the fill color and transparency
              },
            }} />
          <defs>
            <linearGradient id="infoGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor={info} stopOpacity={0.1} />
              <stop offset="100%" stopColor={info} stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <VictoryArea
            name='impact'
            data={data.impact}
            style={{
              data: {
                stroke: orange, // Customize the line color
                strokeWidth: 2, // Increase the line width for bold appearance
                fill: 'url(#orangeGradient)', // Customize the fill color and transparency
              },
            }} />
          <defs>
            <linearGradient id="orangeGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor={orange} stopOpacity={0.1} />
              <stop offset="100%" stopColor={orange} stopOpacity={0.1} />
            </linearGradient>
          </defs>
        </VictoryChart>
      </View>

      <View style={{ borderTopColor: '#ECF1F4', borderTopWidth: 1, width: chartWidth, top: -52, left: 20, position: 'relative', paddingHorizontal: DEFAULT_SPACING }}>
        <Image source={LittleMan} style={{ position: 'absolute', bottom: 0, left: 0, height: 34, width: 25 }} />
      </View>

      <View style={{ top: -28, width: chartWidth, height: 124, left: 20, }}>
        {Object.keys(groupedEvents).map((key, i) => {
          const eventsFromGroup = groupedEvents[key]
          const groupPosition = i % 3 == 0 ? 'short' : i % 3 == 1 ? 'medium' : 'tall'
          const height = groupPosition == 'short' ? 30 : groupPosition == 'medium' ? 60 : 120
          const widthBetweenOneYear = chartWidth / (netWealth.length - 1)

          return (
            <GroupedEvents
              key={key}
              year={key}
              eventsFromGroup={eventsFromGroup}
              height={height}
              widthBetweenOneYear={widthBetweenOneYear}
              currentYear={currentYear}
              navigation={navigation}
              plan={plan}
            />
          )
        })}
      </View>
    </View>
  )
}

export default function LifeEventCard({ navigation, plan, data, width }) {
  const dispatch = useDispatch()
  const events = useSelector((state) => lifeEventsSelector(state, plan.id))

  function handlePress() {
    navigation.navigate('Modal', { screen: 'Add Life Events', params: { plan } })
  }

  async function fetchData() {
    dispatch(getLifeEvents(plan.id))
  }

  useEffect(() => {
    fetchData()
  }, [plan])

  const { return_data, net_wealth } = data
  const { periods } = plan

  const maxContainerWidth = 1074
  const containerWidth = Math.min(width - 80, maxContainerWidth)

  return (
    <View style={[styles.container, {
      maxWidth: containerWidth,
      minWidth: containerWidth,
    }]}>
      <HeaderLabel>Life Events</HeaderLabel>
      {events.length > 0 ? <LifeEventsSection width={width} plan={plan} periods={periods} events={events} returnData={return_data} netWealth={net_wealth} navigation={navigation} /> : <InitialLifeEventsSection />}
      <Link style={{ fontSize: 16, }} onPress={handlePress}>+ Add Event</Link>
    </View>
  )
}
