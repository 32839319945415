import RadioInput from './RadioInput'

function RadioGroup({ options, value, gap, onPress }) {
  const handlePress = (val) => {
    if (value !== val) onPress(val)
  }

  return (
    <>
      {options.map((option) => (
        <RadioInput
          key={option.value}
          label={option.label}
          checked={option.value === value}
          onPress={() => handlePress(option.value)}
          size={18}
          gap={gap}
        />
      ))}
    </>
  )
}

export default RadioGroup
