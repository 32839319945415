import * as Yup from 'yup'

import { REQUIRED, } from './constants'

export const Fields = { passcode: 'passcode' }

export const ValidationSchema = Yup.object().shape({
  [Fields.passcode]: Yup.string()
    .required(REQUIRED)
    .min(6, 'Code must be 6 characters long')
    .nullable()
})

export const InitialValues = { [Fields.passcode]: null }
