import { useEffect, useState } from 'react'
import { View, ScrollView, Text, Platform, ImageBackground, StyleSheet, useWindowDimensions, } from 'react-native'

import { get } from 'http'

import { VictoryPie } from 'victory-native'

import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { FontAwesome5 } from '@expo/vector-icons'
import Card from './components/Card'
import PillButton from './components/PillButton'
import IconDataRow from './components/IconDataRow'

import formatCurrency from './functions/formatCurrency'
import hapticFeedback from './components/hapticFeedback'
import TouchableOpacity from './components/TouchableOpacity'

import { danger, darkblue, gray, grayblue, gunmetal, lightblue, lightestgray, rgba, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import { ANNUAL } from './constants'

const styles = StyleSheet.create({
  container: {
    backgroundColor: lightestgray,
    flex: 1
  },
  card: {
    padding: DEFAULT_SPACING,
    margin: 8,
    shadowColor: 'transparent',
    borderRadius: 6
  },
  title: {
    fontSize: 20,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '500'
  },
  cardtitle: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: 12
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'center',
    letterSpacing: -0.5
  },
  title: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'left',
    fontWeight: '500'
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'left',
    letterSpacing: -0.4
  },
  imageBackground: {
    height: 300,
    width: '100%',
    position: 'absolute',
    top: 0
  }
})

function IconDataTable({ result, duration }) {
  const medicalInsurance = (result.healthcare && result.healthcare[duration]) || 0
  const housingExpenses = (result.rent && result.rent[duration]) || 0
  const utilities = (result.utilities && result.utilities[duration]) || 0
  const autoExpenses = (result.auto && result.auto[duration]) || 0
  const food = (result.food && result.food[duration]) || 0
  const total = (medicalInsurance + housingExpenses + utilities + autoExpenses + food) || 0

  const data = [{
    label: 'Medical / Health Insurance',
    value: medicalInsurance ? `$${formatCurrency(medicalInsurance)}` : '—',
    title: 'Medical/Health Insurance',
    color: darkblue,
    iconStyle: {
      icon: 'heart',
      color: 'rgb(255, 61, 99)',
      backgroundColor: 'rgba(255, 61, 99, 0.1)'
    }
  }, {
    label: 'Housing',
    value: housingExpenses ? `$${formatCurrency(housingExpenses)}` : '—',
    color: darkblue,
    iconStyle: {
      icon: 'house-blank',
      color: 'rgb(66, 86, 103)',
      backgroundColor: 'rgba(66, 86, 103, 0.1)'
    }
  }, {
    label: 'Utilities',
    value: utilities ? `$${formatCurrency(utilities)}` : '—',
    color: darkblue,
    iconStyle: {
      icon: 'bolt',
      color: 'rgb(255, 103, 77)',
      backgroundColor: 'rgba(255, 103, 77, 0.1)'
    }
  }, {
    label: 'Transportation',
    value: autoExpenses ? `$${formatCurrency(autoExpenses)}` : '—',
    color: darkblue,
    iconStyle: {
      icon: 'car',
      color: 'rgb(118, 88, 227)',
      backgroundColor: 'rgba(118, 88, 227, 0.1)'
    }
  }, {
    label: 'Food',
    value: food ? `$${formatCurrency(food)}` : '—',
    color: darkblue,
    iconStyle: {
      icon: 'utensils',
      color: gunmetal,
      backgroundColor: rgba(gunmetal, 0.1)
    }
  }, {
    label: 'TOTAL',
    value: total ? `$${formatCurrency(total)}` : '—',
    color: darkblue,
    backgroundColor: white,
    containerStyle: {
      padding: 0,
      paddingVertical: DEFAULT_SPACING
    },
    rightLabelStyle: {
      fontSize: 14,
      fontWeight: '600'
    },
    leftLableStyle: {
      fontSize: 14,
      fontWeight: '600'
    }
  }]

  return map(data, (rowProps) => <IconDataRow containerStyle={[{ padding: 8 }, rowProps.containerStyle]} key={rowProps.label} {...rowProps} duration={duration} />)
}

function DataDonut({ result, duration }) {
  const window = useWindowDimensions()
  const medicalInsurance = (result.healthcare && result.healthcare[duration]) || 0
  const housingExpenses = (result.rent && result.rent[duration]) || 0
  const utilities = (result.utilities && result.utilities[duration]) || 0
  const autoExpenses = (result.auto && result.auto[duration]) || 0
  const food = (result.food && result.food[duration]) || 0

  const graphicData = [
    { y: medicalInsurance },
    { y: housingExpenses },
    { y: utilities },
    { y: autoExpenses },
    { y: food }
  ]

  const graphicLabels = [
    'Medical Insurance',
    'Housing Expenses',
    'Utilities',
    'Auto Expenses',
    'Food'
  ]

  const graphicColor = [
    'rgb(255, 61, 99)',
    'rgb(66, 86, 103)',
    'rgb(255, 103, 77)',
    'rgb(118, 88, 227)',
    gunmetal
  ]

  return (
    <View>
      <VictoryPie
        animate={{ easing: 'exp' }}
        data={graphicData}
        width={Platform.OS == 'web' ? 250 : window.width - 40}
        height={Platform.OS == 'web' ? 250 : window.width - 40}
        innerRadius={Platform.OS == 'web' ? 40 : 90}
        colorScale={graphicColor}
        labels={() => null}
      />
      <View style={{ position: 'absolute', alignItems: 'center', width: window.width - 40 }}>
        <Text style={{
          fontWeight: '500',
          fontSize: 24,
          letterSpacing: -1.03
        }}
        >
          {`$${formatCurrency(medicalInsurance + housingExpenses + utilities + autoExpenses + food)}`}

        </Text>
        <Text style={{
          fontWeight: '500',
          fontSize: 14,
          letterSpacing: 0
        }}
        >
          TOTAL

        </Text>
      </View>

      <View style={{ paddingHorizontal: DEFAULT_SPACING, flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        {map(graphicLabels, (label, index) => <PieLabel label={label} color={graphicColor[index]} />)}
      </View>
    </View>
  )
}

function PieLabel({ label, color }) {
  return (
    <View style={{ flexDirection: 'row', display: 'flex', width: '48%', marginBottom: 12 }}>
      <View style={{ marginRight: 10, backgroundColor: color, borderRadius: 15 / 2, height: 15, width: 15 }} />
      <Text style={{
        fontSize: 12,
        color: grayblue,
        letterSpacing: 0
      }}
      >
        {label}

      </Text>
    </View>
  )
}

export default function CostOfLivingScreen({ route, navigation }) {
  const { params } = route
  const { location } = params

  const [costOfLiving, setCostOfLiving] = useState({})
  const [isDonut, setIsDonut] = useState(false)
  const [duration, setDuration] = useState(ANNUAL)

  async function fetchCostOfLiving() {
    try {
      const response = await get(`/location/${location.id}/cost-of-living/`)
      setCostOfLiving(response.data)
    } catch (e) {
      console.warn(e, e.response)
    }
  }

  useEffect(() => {
    fetchCostOfLiving()
  }, [location])

  const medicalInsurance = (costOfLiving.healthcare && costOfLiving.healthcare.annual) || 0
  const housingExpenses = (costOfLiving.rent && costOfLiving.rent.annual) || 0
  const utilities = (costOfLiving.utilities && costOfLiving.utilities.annual) || 0
  const autoExpenses = (costOfLiving.auto && costOfLiving.auto.annual) || 0
  const food = (costOfLiving.food && costOfLiving.food.annual) || 0

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require('./assets/cost-of-living-bg.png')}
        resizeMode="cover"
        style={styles.imageBackground}
      />

      <ScrollView style={{ paddingHorizontal: 8, paddingTop: 12 }}>
        <Card style={[styles.card, { marginVertical: 50, padding: 15 }]}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FontAwesomeIcon icon={['fa', 'location-dot']} size={33} color={danger} />

            <View style={{ marginLeft: DEFAULT_SPACING }}>
              <Text style={{ color: darkblue, fontSize: 20, fontWeight: '500' }}>{location.name}</Text>
              <View style={{ flexDirection: 'row' }}>
                <Text style={{ color: gunmetal, fontSize: 16, fontWeight: '500', padding: 4 }}>Total Annual Cost</Text>
                <View style={{ backgroundColor: 'rgba(52, 145, 247, 0.1)', borderRadius: 12, padding: 4, paddingHorizontal: 8 }}>
                  <Text style={{ color: lightblue, fontSize: 16, fontWeight: '500' }}>{`$${formatCurrency(medicalInsurance + housingExpenses + utilities + autoExpenses + food)}`}</Text>
                </View>
              </View>
            </View>
          </View>
        </Card>

        <Card style={[styles.card, { paddingBottom: 30 }]}>
          <TouchableOpacity
            style={{ zIndex: 100, alignItems: 'center', justifyContent: 'center', backgroundColor: rgba(gray, 0.55), borderRadius: 30 / 2, width: 30, height: 30, position: 'absolute', top: 10, right: 10 }}
            onPress={() => {
              setIsDonut(!isDonut)
              hapticFeedback()
            }}
          >
            <FontAwesome5 name={isDonut ? 'list' : 'chart-pie'} size={12} color="white" />
          </TouchableOpacity>

          <Text style={[styles.cardtitle, { fontWeight: '600' }]}>Living Essentials</Text>
          <Text style={[styles.subtitle, { textAlign: 'center' }]}>The things you can’t live without: the median Cost of Living expenses for your selected location</Text>

          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly', marginTop: DEFAULT_SPACING, marginBottom: 40, alignSelf: 'center', width: 300 }}>
            <PillButton label="Weekly" inverted active={duration == 'weekly'} onPress={() => setDuration('weekly')} />
            <PillButton label="Monthly" inverted active={duration == 'monthly'} onPress={() => setDuration('monthly')} />
            <PillButton label="Annual" inverted active={duration == 'annual'} onPress={() => setDuration('annual')} />
          </View>

          { isDonut && <DataDonut result={costOfLiving} duration={duration} />}
          { !isDonut && <IconDataTable result={costOfLiving} duration={duration} />}
        </Card>
      </ScrollView>
    </View>
  )
}
