import { last } from 'lodash'
import { DEFAULT_CONFIG } from '../configure/config/config.default'

import { accessTokenSelector } from '../selectors'

import getStore from '../getStore'

const store = getStore()

function fileExtension(fileToUpload) {
  return last(fileToUpload.uri.split('.'))
}

export default async function uploadFile(endpoint, attr, fileToUpload) {
  const accessToken = accessTokenSelector(store.getState())

  const data = new FormData()
  let isBlob = false

  if (fileToUpload.uri.match('base64')) {
    const res = await fetch(fileToUpload.uri)
    const blob = await res.blob()
    const file = new File([blob], `${new Date().getTime()}.png`, { type: blob.type })
    data.append(attr, file)
    isBlob = true
  } else {
    data.append(attr, {
      uri: fileToUpload.uri,
      name: `${new Date().getTime()}.${fileExtension(fileToUpload)}`,
      type: 'multipart/form-data'
    })
  }

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${accessToken}`
  }

  if (!isBlob) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  await fetch(
    `${DEFAULT_CONFIG.baseUrl}${endpoint}`,
    {
      method: 'patch',
      body: data,
      headers
    }
  )
}
