import * as Yup from 'yup'

import {
  INVALID_AMOUNT,
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX,
  REQUIRED,
} from './constants'

export const Fields = {
  city: 'city',
  age: 'age',
  birth_date: 'birth_date',
  periods: 'periods',
  loan_balance: 'loan_balance',
  loan_rate: 'loan_rate',
  loan_period: 'loan_period',
  have_loans: 'have_loans',
  loan_national_average: 'loan_national_average',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.city]: Yup.object().required(REQUIRED),
  [Fields.age]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MIN)
    .max(95, `${INVALID_MAX}95`)
    .required(REQUIRED),
  [Fields.birth_date]: Yup.date().required(REQUIRED),
  [Fields.periods]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(95, `${INVALID_MAX}95`)
    .required(REQUIRED),
  [Fields.loan_balance]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(1000000, `${INVALID_MAX}1,000,000`)
    .nullable()
    .when('have_loans', {
      is: (have_loans) => have_loans,
      then: Yup.number().required(REQUIRED).typeError(REQUIRED)
    }),
  [Fields.loan_rate]: Yup.number().positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100%`)
    .nullable()
    .when('have_loans', {
      is: (have_loans) => have_loans,
      then: Yup.number().required(REQUIRED).typeError(REQUIRED)
    }),
  [Fields.loan_period]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(30, `${INVALID_MAX}30`)
    .nullable()
    .when('have_loans', {
      is: (have_loans) => have_loans,
      then: Yup.number().required(REQUIRED).typeError(REQUIRED)
    }),
  [Fields.have_loans]: Yup.boolean(),
  [Fields.loan_national_average]: Yup.boolean().nullable()
})

export const InitialValues = {
  [Fields.city]: null,
  [Fields.age]: null,
  [Fields.birth_date]: null,
  [Fields.periods]: null,
  [Fields.loan_balance]: null,
  [Fields.loan_rate]: null,
  [Fields.loan_period]: null,
  [Fields.have_loans]: false,
  [Fields.loan_national_average]: false
}
