import { useEffect, useLayoutEffect, useState } from 'react'
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'http'
import { map } from 'lodash'
import {
  DEFAULT_SPACING,
  black,
  darkblue,
  gunmetal,
  lightblue,
  white
} from '../styles'
import FastBuilderContainer from '../components/planBuilder/FastBuilderContainer'
import Form from '../components/Form'
import MockInput from '../components/MockInput'
import { currentPlanSelector } from '../selectors'
import { Fields, LocationValidationSchema } from '../schemas/fastPlanSchemas'
import { setFastPlanModeData } from '../actions'
import SearchInput from '../components/SearchInput'
import SearchListItem from '../components/SearchListItem'
import SearchResultFinn from '../components/SearchResultFinn'

const basicStyles = {
  container: { flex: 1 },
  title: {
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '500'
  },
  boldText: { fontWeight: 'bold' }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {},
  [minSize(DEVICE_SIZES.LG)]: {}
})

function EditLocationScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [visible, setVisible] = useState(true)
  const dispatch = useDispatch()
  const [citySearch, setCitySearch] = useState()
  const [cityOptions, setCityOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const form = useSelector((state) => currentPlanSelector(state))

  const { city, step } = form

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerTintColor: white,
      title: null
    })
  }, [])

  const handleLocation = (c) => {
    dispatch(setFastPlanModeData({ city: c }))
    navigation.navigate('Modal', { screen: 'Fast Edit Career' })
  }

  async function searchCities(value) {
    if (value && value.length >= 3) {
      try {
        setLoading(true)
        const results = await get(`cities/?search=${value}`)
        setCityOptions(results.data)
      } catch (error) {
        console.error(error.response, ' -- error')
      } finally {
        setLoading(false)
      }
    } else {
      setCityOptions([])
    }
  }

  useEffect(() => {
    searchCities(citySearch)
  }, [citySearch])

  return (
    <FastBuilderContainer currentPage="Location">
      <View style={styles.container}>
        <Form
          buttonText="Next"
          containerStyle={{ width: '100%' }}
          validationSchema={LocationValidationSchema}
          initialValues={{ city: city && city.name ? city.name : '' }}
          withPadding={false}
          onSubmit={async (values) => handleLocation(values.city)}
        >
          {({ setFieldTouched, setFieldValue, values, errors, touched }) => (
            <View style={{ width: '100%', paddingTop: 80 }}>
              <Text style={styles.title}>I plan to live in</Text>
              <View style={{ marginTop: DEFAULT_SPACING * 2 }}>
                {!visible && (
                  <MockInput
                    onPress={async () => {
                      setVisible(true)
                      setFieldTouched(Fields.city, true)
                    }}
                    label="City, State"
                    touched={touched[Fields.city]}
                    errorMessage={errors[Fields.city]}
                    value={
                      values.city && values.city.name ? values.city.name : ' '
                    }
                  />
                )}

                {visible && (
                  <>
                    <SearchInput
                      value={citySearch}
                      onChange={(value) => {
                        setCitySearch(value || null)
                      }}
                      onCancel={() => {
                        setCitySearch(null)
                      }}
                    />
                    {loading && (
                      <ActivityIndicator
                        style={{ marginHorizontal: 12 }}
                        color={black}
                        size="small"
                      />
                    )}
                    <ScrollView displayButton={false}>
                      {map(cityOptions, (c) => (
                        <SearchListItem
                          key={c.id}
                          onPress={() => {
                            setFieldValue(Fields.city, c)
                            setCitySearch(null)
                            setVisible(false)
                          }}
                          leftLabel={`${c.name}`}
                        />
                      ))}

                      {cityOptions.length === 0 &&
                        citySearch &&
                        citySearch.length > 3 && (
                          <SearchResultFinn
                            image={require('../assets/finn/question.png')}
                            title="No Results"
                            subtitle="Explore another location"
                          />
                        )}
                    </ScrollView>
                  </>
                )}
              </View>
              <View
                style={{
                  marginTop: Platform.OS !== 'android' ? 100 : 40,
                  paddingHorizontal: 56
                }}
              >
                <Text style={{ textAlign: 'center' }}>
                  <Text style={{ color: gunmetal }}>
                    Not sure? No problem — check out our{' '}
                  </Text>
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate('Modal', {
                        screen: 'Cost of Living Location Search'
                      })
                    }
                  >
                    <View
                      style={{ flexDirection: 'row', alignItems: 'baseline' }}
                    >
                      <Text style={{ color: lightblue, top: 4 }}>
                        Cost of Living Tool
                      </Text>
                    </View>
                  </TouchableOpacity>
                </Text>
              </View>
            </View>
          )}
        </Form>
      </View>
    </FastBuilderContainer>
  )
}

export default EditLocationScreen
