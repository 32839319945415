import { View, StyleSheet } from 'react-native'
import { DEFAULT_SPACING } from 'styles'

const styles = StyleSheet.create({
  divider: {
    borderBottomColor: 'rgb(241, 246, 247)',
    borderBottomWidth: 1,
    height: 1,
    marginVertical: DEFAULT_SPACING 
  }
})

export default function Divider({ style }) {
  return (
    <View style={[styles.divider, style]} />
  )
}
