import { stateReducer } from '@truefit/redux-utils'
import { each } from 'lodash'
import moment from 'moment/moment'
import { SET_TRANSACTIONS, SET_TRANSACTION, DELETE_TRANSACTION, CLEAR } from '../actions'

function processTransactions(previousTransactions, newTransactions) {
  // iterate over transactions
  // if a transaction is in the state array
  // update the transaction in the state array
  // else add the transaction to the state array

  each(newTransactions, (transaction) => {
    const index = previousTransactions.findIndex((item) => item.transactionId === transaction.transactionId)
    if (index > -1) {
      previousTransactions[index] = transaction
    } else {
      previousTransactions.push(transaction)
    }
  })

  return [...previousTransactions]
}

function getTransactionsByMonthAndYear(transactions) {
  const transactionsByMonthAndYear = {}

  each(transactions, (transaction) => {
    const { date } = transaction
    const key = moment(date).format('YYYY-MM')

    if (!transactionsByMonthAndYear[key]) {
      transactionsByMonthAndYear[key] = []
    }

    transactionsByMonthAndYear[key].push(transaction)
  })

  return transactionsByMonthAndYear
}

function getFormattedTransactions(previousTransactions, newTransactions) {
  const transactions = processTransactions(previousTransactions, newTransactions)

  return {
    transactions,
    transactionsByMonthAndYear: getTransactionsByMonthAndYear(transactions)
  }
}

const initialState = {
  transactions: [],
  transactionsByMonthAndYear: {}
}

export default stateReducer(initialState, {
  [SET_TRANSACTIONS]: (state, transactions) => ({ ...getFormattedTransactions(state.transactions, transactions) }),
  [SET_TRANSACTION]: (state, transaction) => ({ ...getFormattedTransactions(state.transactions, [transaction]) }),
  [DELETE_TRANSACTION]: (state, transaction) => {
    const withoutTransaction = state.transactions.filter((item) => item.transactionId !== transaction.transactionId)
    return { ...getFormattedTransactions(withoutTransaction, []) }
  },
  [CLEAR]: () => initialState
});
