import { getProBudgetTotal } from './getMonthlyProBudgetTotal'
import { forEach, reduce, find } from 'lodash'

export default function getProjectedBudgetItemsByCategory(
  projection,
  budget = {}
) {
  const newBudgetItemsByCategory = {}

  forEach(
    budget.budgetItemsByCategory,
    (budgetCategoryData, budgetCategory) => {
      const projectedItems = reduce(
        budgetCategoryData.items,
        (filteredItems, budgetItem) => {
          const projectedBudgetItem = find(
            projection.budgetItems,
            (projectedItem) =>
              projectedItem.budgetItemId === budgetItem.budgetItemId
          )

          if (projectedBudgetItem) {
            filteredItems.push({
              ...budgetItem,
              amount: projectedBudgetItem.amount,
              unprojectedAmount: budgetItem.amount
            })
          }

          return filteredItems
        },
        []
      )

      newBudgetItemsByCategory[budgetCategory] = {
        ...budgetCategoryData,
        items: projectedItems,
        count: projectedItems.length,
        total: getProBudgetTotal(projectedItems)
      }
    }
  )

  return newBudgetItemsByCategory
}
