import { Image, Platform, View, Text, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { DEFAULT_SPACING } from '../../styles'

const basicStyles = {
  container: { justifyContent: 'center' },
  troutwood: {
    width: 40,
    height: 60,
    resizeMode: 'contain',
    alignSelf: 'center',
    opacity: 0.3,
    marginBottom: DEFAULT_SPACING
  },
  text: {
    color: '#7B8B94',
    textAlign: 'center',
    fontSize: 12,
    letterSpacing: -0.1
  },
  boldText: { fontWeight: 'bold' }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  { [minSize(DEVICE_SIZES.LG)]: { container: { marginVertical: DEFAULT_SPACING * 2 } } }
)

export default function Tout() {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View style={styles.container}>
      <Image
        style={styles.troutwood}
        source={require('../../assets/logo.png')}
        resizeMode="cover"
      />

      <Text style={styles.text}>
        <Text style={styles.boldText}>Redefining Wealth</Text>
        {' '}
        as having enough money
        {'\n'}
        to spend time with people you
        <Text style={styles.boldText}>love</Text>
        {' '}
        in the places you
        <Text style={styles.boldText}>enjoy</Text>
        .
      </Text>
    </View>
  )
}
