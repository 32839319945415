import { useLayoutEffect, useState } from 'react'
import { View, StyleSheet, Text, useWindowDimensions } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import Form from './components/Form'

import Input from './components/Input'
import CurrencyInput from './components/CurrencyInput'
import NumberInput from './components/NumberInput'

import { Fields, ValidationSchema, InitialValues } from './schemas/proGoalSchema'
import DateTimePicker from './components/DateTimePicker'
import SquareCheckBox from './components/SquareCheckBox'

import { DEFAULT_SPACING } from './styles'

import { LinearGradient } from 'expo-linear-gradient'

import { currentProPlanSelector } from './selectors'

import { NAVIGATION_TYPE } from './constants'

import { info, white } from './styles'

import { saveProPlan, setCurrentProPlan } from './actions'
import handleError from './functions/handleError'
import CircleGradient from './components/CircleGradient'

const styles = StyleSheet.create({
  container: (height) => ({
    flex: 1,
    backgroundColor: white,
    height
  }),
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: DEFAULT_SPACING * 4
  },
  lead: {
    color: white,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center',
    marginTop: DEFAULT_SPACING * 6,
    marginBottom: DEFAULT_SPACING * 4,
    letterSpacing: -0.25
  },
  form: {
    paddingTop: DEFAULT_SPACING * 6,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    backgroundColor: white
  }
})

export default function UpgradePlanGoalScreen({ navigation, route }) {
  const window = useWindowDimensions()
  const { height } = window
  const currentProPlan = useSelector(currentProPlanSelector) || {}
  const initialValues = InitialValues(currentProPlan)
  const [checked, setChecked] = useState(currentProPlan.inflationRate === 3.0)
  const { params } = route
  const { buttonText } = params || {}
  const [isSubmitting, setIsSubmitting] = useState(false)

  const dispatch = useDispatch()

  const setDefault = (isChecked, setFieldValue) => {
    setChecked(isChecked)
    if (isChecked) {
      setFieldValue(Fields.inflationRate, 3.0)
    } else {
      setFieldValue(Fields.inflationRate, 0)
    }
  }

  const onSubmit = async (values) => {
    try {
      /* Update the goal information in the plan */
      const saveProPayload = {
        name: values[Fields.planName],
        goal: {
          horizonDate: values[Fields.horizonDate],
          amount: values[Fields.amount]
        },
        inflationRate: values[Fields.inflationRate]
      }

      const currentPlan = {
        ...currentProPlan,
        ...saveProPayload
      }

      setIsSubmitting(true)
      await dispatch(saveProPlan(currentPlan))
      await dispatch(setCurrentProPlan(currentPlan))
      setIsSubmitting(false)

      navigation.popToTop()
    } catch (e) {
      setIsSubmitting(false)
      handleError(e)
    }
  }

  useLayoutEffect(() => {
    // background color for header is transparent
    navigation.setOptions({
      headerStyle: {
        backgroundColor: 'transparent'
      },
      headerTransparent: true,
      headerTintColor: white,
      headerTitleStyle: {
        fontWeight: 'bold'
      }
    })
  }, [navigation])

  return (
    <View style={styles.container(height)}>
      <CircleGradient />
      <View style={styles.contentContainer}>
        <Text style={styles.lead}>
          Get ready to level up with your Pro plan — fill in the details below, and we'll use this
          information to supercharge your forecasting and budgeting capabilities!
        </Text>

        <Form
          validationSchema={ValidationSchema}
          initialValues={initialValues}
          buttonText={buttonText || 'Finish'}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          containerStyle={styles.form}
        >
          {({ setFieldValue, handleChange, values, errors, touched }) => (
            <>
              <Input
                label="Plan Name"
                name={Fields.planName}
                touched={touched[Fields.planName]}
                errorMessage={errors[Fields.planName]}
                onChangeText={handleChange(Fields.planName)}
                value={values[Fields.planName]}
              />

              <DateTimePicker
                name={Fields.horizonDate}
                errorMessage={errors[Fields.horizonDate]}
                touched={touched[Fields.horizonDate]}
                value={values[Fields.horizonDate]}
                onChange={(date) => {
                  setFieldValue(Fields.horizonDate, date)
                }}
                label="Horizon Date"
                display="spinner"
              />

              <CurrencyInput
                prefix="$"
                label="Goal Amount"
                name={Fields.amount}
                touched={touched[Fields.amount]}
                errorMessage={errors[Fields.amount]}
                onChangeText={handleChange(Fields.amount)}
                value={values[Fields.amount]}
              />

              <View style={{ width: 220, marginBottom: DEFAULT_SPACING * 2 }}>
                <SquareCheckBox
                  isChecked={checked}
                  rightText="Use Default Inflation Rate"
                  onClick={(checked) => {
                    setDefault(checked, setFieldValue)
                  }}
                />
              </View>

              <NumberInput
                suffixUnit="%"
                precision={2}
                label="Inflation Rate"
                name={Fields.inflationRate}
                touched={touched[Fields.inflationRate]}
                errorMessage={errors[Fields.inflationRate]}
                onChangeText={handleChange(Fields.inflationRate)}
                disabled={checked}
                value={values[Fields.inflationRate]}
              />
            </>
          )}
        </Form>
      </View>
    </View>
  )
}

UpgradePlanGoalScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
