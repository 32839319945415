import moment from 'moment'
import * as Yup from 'yup'
import { MONTHLY_PERIODICITY } from '../constants'

import { REQUIRED } from './constants'

export const Fields = {
  name: 'name',
  amount: 'amount',
  frequency: 'frequency',
  startDate: 'start_date',
  endDate: 'end_date',
  rate: 'rate',
  contributionAccountId: 'contribution_account_id',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.name]: Yup.string().required(REQUIRED),
  [Fields.amount]: Yup.number().positive().required(REQUIRED),
  [Fields.frequency]: Yup.string().required(REQUIRED),
  [Fields.startDate]: Yup.date().required(REQUIRED),
  [Fields.endDate]: Yup.date().required(REQUIRED),
})

export const InitialValues = (budgetItem) => {
  const today = moment()
  const todayPlus30Yrs = moment().add(30, 'years')

  const {
    name = '',
    amount = 0.0,
    periodicity: {
      frequency = MONTHLY_PERIODICITY,
      startDate = today,
      endDate = todayPlus30Yrs,
    } = {},
    advanced: {
      contributionAccountId = null
    } = {}
  } = budgetItem

  return {
    [Fields.name]: name,
    [Fields.amount]: amount,
    [Fields.frequency]: frequency,
    [Fields.startDate]: startDate,
    [Fields.endDate]: endDate,
    [Fields.contributionAccountId]: contributionAccountId ? 
      contributionAccountId : null
  }
}
