// import { get } from 'http'
import { get } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { setBSAccountCategories } from './setBSAccountCategories'

// eslint-disable-next-line import/prefer-default-export
export const getBSAccountCategories = () => async (dispatch) => {
  try {
    const { data: { accountCategories } } = await get(
      proApplicationEndpoints.getAccountCategories()
    )
    dispatch(setBSAccountCategories(accountCategories))
  } catch (e) {
    console.warn(e, e.response)
  }
}
