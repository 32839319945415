import { View, StyleSheet, Dimensions } from 'react-native'
import Link from './Link'
import SlideupDrawer from './SlideupDrawer'
import { darkblue, primary, red } from '../styles/colors'

import hapticFeedback from './hapticFeedback'

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    marginHorizontal: 20
  },
  edit: {
    color: darkblue,
    fontSize: 18,
    fontWeight: '500',
    marginVertical: 10,
    marginHorizontal: 10
  },
  editIcon: {
    color: primary,
    marginVertical: 10
  },
  delete: {
    color: red,
    fontSize: 18,
    fontWeight: '500',
    marginVertical: 10,
    marginHorizontal: 10
  },
  deleteIcon: {
    color: red,
    marginVertical: 10
  }
})

export default function ActionsDrawer({ title, visible, setVisible, onDelete, onEdit }) {
  return (
    <SlideupDrawer title={title} visible={visible} setVisible={setVisible}>
      <View style={styles.container}>
        <Link
          iconStyle={styles.editIcon}
          style={styles.edit}
          icon="pencil"
          type="fas"
          onPress={() => {
            hapticFeedback()
            onEdit()
            setVisible(false)
          }}
        >
          Edit
        </Link>

        <Link
          iconStyle={styles.deleteIcon}
          style={styles.delete}
          icon="trash"
          type="fas"
          onPress={async () => {
            hapticFeedback()
            await onDelete()
            setVisible(false)
          }}
        >
          Delete
        </Link>
      </View>
    </SlideupDrawer>
  )
}
