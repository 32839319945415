import { post } from 'http'

import moment from 'moment'
import handleError from '../functions/handleError'
import handleSuccess from '../functions/handleSuccess'

import { saveMetaData } from './saveMetaData'
import { login } from './login'

export const register = function (payload) {
  return async (dispatch) => {
    try {
      if (payload.birth_date) {
        payload.birth_date = moment(payload.birth_date).format('YYYY-MM-DD')
      }

      // this could contain the device id that is null
      if (!payload.device_id) delete payload.device_id

      await post('rest-auth/registration/', payload)
      await dispatch(login({ email: payload.email, password: payload.password1 }))

      const clickWrapMetadataKey = 'TROUTWOOD_CLICKWRAP'
      await dispatch(saveMetaData({ [clickWrapMetadataKey]: true }))

      handleSuccess('Registration Complete', 'Please check your email for a verification link.')
    } catch (e) {
      handleError(e, 'Oops. Looks like something went wrong.')

      throw e
    }
  }
}
