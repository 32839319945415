import { useEffect, useState, useRef } from 'react'
import { StyleSheet, Platform, View, ActivityIndicator } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import {
  createStackNavigator,
  TransitionSpecs,
  HeaderStyleInterpolators
} from '@react-navigation/stack'

import { Amplify } from 'aws-amplify'

import * as Sentry from '@sentry/react-native'
import { configureFontAwesomePro } from 'react-native-fontawesome-pro'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { Provider } from 'react-redux'
import { gestureHandlerRootHOC } from 'react-native-gesture-handler'
import CustomHeader from './components/CustomHeader'

import { darkblue, info } from './styles/colors'

import InAppAlert from './components/InAppAlert'

import LoadingScreen from './LoadingScreen'
import OfflineScreen from './OfflineScreen'
import ForcedAppUpdateScreen from './ForcedAppUpdateScreen'

import awsconfig from './src/aws-exports'

import { setLoading, clear, login, getAccessToken } from './actions'

import GetStartedScreen from './GetStartedScreen'
import PlanBuilderIntroScreen from './PlanBuilderIntroScreen'
import GoalLockin from './GoalLockin'
import ReviewPlanScreen from './ReviewPlanScreen'
import PlanSummaryScreen from './PlanSummaryScreen'
import CareersScreen from './CareersScreen'
import OccupationGroupScreen from './OccupationGroupScreen'
import CareerScreen from './CareerScreen'
import SavedCareersScreen from './SavedCareersScreen'
import ComparisonToolScreen from './ComparisonToolScreen'
import FinglishDictionaryScreen from './FinglishDictionaryScreen'
import HowToDictionaryScreen from './HowToDictionaryScreen'
import VideoPlayerScreen from './VideoPlayerScreen'

import InvestmentOptionsScreen from './InvestmentOptionsScreen'
import InvestmentProfileScreen from './InvestmentProfileScreen'
import StartInvestingCategoryScreen from './StartInvestingCategoryScreen'
import StartInvestingCarouselScreen from './StartInvestingCarouselScreen'

import MyDebtScreen from './MyDebtScreen'
import MyDebtSummaryScreen from './MyDebtSummaryScreen'
import MyDebtFormScreen from './MyDebtFormScreen'

import FoolProofScreen from './FoolProofScreen'

import LibraryScreen from './LibraryScreen'
import LibraryItemScreen from './LibraryItemScreen'
import LibraryBooksScreen from './LibraryBooksScreen'
import LibraryBookScreen from './LibraryBookScreen'
import QuizScreen from './QuizScreen'
import LibraryQuizScoreScreen from './LibraryQuizScoreScreen'

import AskTheAuthorScreen from './AskTheAuthorScreen'

import CostOfLivingLocationScreen from './CostOfLivingLocationScreen'
import CostOfLivingLocationSearchScreen from './CostOfLivingLocationSearchScreen'
import CostOfLivingScreen from './CostOfLivingScreen'

import LoginScreen from './LoginScreen'
import RegistrationScreen from './RegistrationScreen'
import ResetPasswordScreen from './ResetPasswordScreen'
import ResetPasswordSuccessfulScreen from './ResetPasswordSuccessfulScreen'

import WellnessChecklistIntroScreen from './WellnessChecklistIntroScreen'
import WellnessChecklistToolkitScreen from './WellnessChecklistToolkitScreen'
import WellnessCheckScreen from './WellnessCheckScreen'

import MilestoneScreen from './MilestoneScreen'

import EmergencyPreparednessIntroScreen from './EmergencyPreparednessIntroScreen'
import EmergencyPreparednessMonthlyExpensesScreen from './EmergencyPreparednessMonthlyExpensesScreen'
import EmergencyPreparednessEmergencyFundsScreen from './EmergencyPreparednessEmergencyFundsScreen'
import EmergencyPreparednessAddSourceScreen from './EmergencyPreparednessAddSourceScreen'
import EmergencyPreparednessFinishScreen from './EmergencyPreparednessFinishScreen'
import EmergencyPreparednessListScreen from './EmergencyPreparednessListScreen'

// Career Explore Expansion
import CareerExploreIntroScreen from './CareerExploreIntroScreen'
import CareerExploreLocationSearchScreen from './CareerExploreLocationSearchScreen'
import CareerExploreOccupationGroupsScreen from './CareerExploreOccupationGroupsScreen'
import CareerExploreOccupationsScreen from './CareerExploreOccupationsScreen'
import CareerExploreScreen from './CareerExploreScreen'

// College Explore
import CollegeExploreIntroScreen from './CollegeExploreIntroScreen'
import CollegeExploreSearchScreen from './CollegeExploreSearchScreen'
import CollegeExploreScreen from './CollegeExploreScreen'
import CollegeExploreFilterScreen from './CollegeExploreFilterScreen'

import OpportunityCostIntroScreen from './OpportunityCostIntroScreen'
import OpportunityCostScreen from './OpportunityCostScreen'
import OpportunityCostCalculatorScreen from './OpportunityCostCalculatorScreen'
import OpportunityCostResultScreen from './OpportunityCostResultScreen'

import LoanCalculatorIntroScreen from './LoanCalculatorIntroScreen'
import LoanCalculatorSelectionScreen from './LoanCalculatorSelectionScreen'
import LoanCalculatorScreen from './LoanCalculatorScreen'
import LoanCalculatorResultsScreen from './LoanCalculatorResultsScreen'
import MillionaireCalculatorIntroScreen from './MillionaireCalculatorIntroScreen'
import MillionaireCalculatorScreen from './MillionaireCalculatorScreen'
import FutureValueCalculatorIntroScreen from './FutureValueCalculatorIntroScreen'
import FutureValueCalculatorScreen from './FutureValueCalculatorScreen'
import NetWorthCalculatorIntroScreen from './NetWorthCalculatorIntroScreen'
import NetWorthCalculatorScreen from './NetWorthCalculatorScreen'

import AddLifeEventsScreen from './AddLifeEventsScreen'
import AddLifeEventScreen from './AddLifeEventScreen'

import SurplusScreen from './SurplusScreen'

import LikelihoodScreen from './LikelihoodScreen'
import EstimatedValueScreen from './EstimatedValueScreen'
import AdequacyScoreScreen from './AdequacyScoreScreen'

import UserOptionsMenuScreen from './UserOptionsMenuScreen'
import EditProfileScreen from './EditProfileScreen'
import EditPasswordScreen from './EditPasswordScreen'
import JoinTeamScreen from './JoinTeamScreen'

import MakeAnImpactScreen from './MakeAnImpactScreen'

import EditProPlanDetailsScreen from './EditProPlanDetailsScreen'

import HeaderBackImage from './components/HeaderBackImage'
import CloseLeftButton from './components/CloseLeftButton'

import { TaxesTable } from './components/DemographicForms'

import GoalForm from './components/plans/GoalForm'

import LocationForm from './components/plans/LocationForm'
import ProfileForm from './components/plans/ProfileForm'
import StudentLoanForm from './components/plans/StudentLoanForm'
import MedicalInsuranceForm from './components/plans/MedicalInsuranceForm'
import HousingForm from './components/plans/HousingForm'
import UtilitiesForm from './components/plans/UtilitiesForm'
import TransportationForm from './components/plans/TransportationForm'
import FoodForm from './components/plans/FoodForm'
import CareerForm from './components/plans/CareerForm'
import LifeHacksForm from './components/plans/LifeHacksForm'
import AddOnsFormScreen from './components/plans/AddOnsFormScreen'
import EditAddOnFormScreen from './components/plans/EditAddOnFormScreen'

import JoinTeamWelcomeScreen from './JoinTeamWelcomeScreen'
import JoinTeamInterestsScreen from './JoinTeamInterestsScreen'
import JoinTeamWellnessChecklistScreen from './JoinTeamWellnessChecklistScreen'
import JoinTeamSuccessScreen from './JoinTeamSuccessScreen'

import TabbedStack from './stacks/TabbedStack'

import CreateBudgetIntroScreen from './CreateBudgetIntroScreen'
import CreateBudgetScreen from './CreateBudgetScreen'
import BudgetItemsListScreen from './BudgetItemsListScreen'
import UpdateBudgetItemScreen from './UpdateBudgetItemScreen'
import CreateBudgetSuccessScreen from './CreateBudgetSuccessScreen'
import UpgradePlanIntroScreen from './UpgradePlanIntroScreen'
import UpgradePlanAddAccounts from './UpgradePlanAddAccounts'
import ViewBSAccountScreen from './ViewBSAccountScreen'
import EditAddBSAccountScreen from './EditAddBSAccountScreen'
import UpgradePlanGoalScreen from './UpgradePlanGoalScreen'
import CashWaterfallIntroScreen from './CashWaterfallIntroScreen'
import CashWaterfallScreen from './CashWaterfallScreen'
import CashWaterfallFormScreen from './CashWaterfallFormScreen'
import MyBalanceSheetScreen from './MyBalanceSheetScreen'
import BudgetingScreen from './BudgetingScreen'
import BudgetCategoryDetailsScreen from './BudgetCategoryDetailsScreen'
import BudgetSubcategoryDetailsScreen from './BudgetSubcategoryDetailsScreen'
import BudgetSubcategoryTransactionsScreen from './BudgetSubcategoryTransactionsScreen'
import BudgetTrendDetailsScreen from './components/BudgetTrendDetailsScreen'
import TransactionFormScreen from './TransactionFormScreen'
import TransactionsScreen from './TransactionsScreen'

import setUpBranch from './branch/setUpBranch'
import useSentry from './hooks/useSentry'

import usePinpoint, { trackNavigation } from './hooks/usePinpoint'

import CollegeExploreSearchPage from './CollegeExploreSearchPage'
import CalculatorsScreen from './CalculatorsScreen'
import TWToolsScreen from './TWToolsScreen'

import MyFinmojiScreen from './MyFinmojiScreen'
import FinmojiLibraryScreen from './FinmojiLibraryScreen'
import FinmojiScreen from './FinmojiScreen'

import FinPulseScreen from './FinPulseScreen'
import FinPulseSettingsScreen from './FinPulseSettingsScreen'

import getStore from './getStore'
import RiskProfile from './components/dashboard/RiskAssessment/RiskProfile'
import RiskAssessmentWizard from './components/dashboard/RiskAssessment/RiskAssessmentWizard'
import RiskAssessmentScore from './components/dashboard/RiskAssessment/RiskAssessmentScore'
import MilitaryCareerExploreOccupationsScreen from './MilitaryCareerExploreOccupationsScreen'
import MilitaryCareerExploreScreen from './MilitaryCareerExploreScreen'
import MilitaryOccupationGroupScreen from './MilitaryOccupationGroupScreen'
import MilitaryCareerScreen from './MilitaryCareerScreen'
import EditAgeScreen from './fastPlanBuilder/EditAgeScreen'
import EditLocationScreen from './fastPlanBuilder/EditLocationScreen'
import EditCareerScreen from './fastPlanBuilder/EditCareerScreen'
import NotificationsScreen from './NotificationsScreen'
import NotificationDetails from './NotificationDetailsScreen'
import PensionScreen from './PensionScreen'
import MyTeamsScreen from './MyTeamsScreen'

const troutwoodStore = getStore()

const styles = StyleSheet.create({
  headerStyle: {
    height: 120,
    elevation: 0,
    shadowOpacity: 0
  },
  headerTitleStyle: {
    fontSize: 20,
    color: darkblue,
    textAlign: 'center'
  },
  headerSubtitleStyle: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: '400',
    letterSpacing: -0.48,
    marginTop: 2
  }
})

const options = {
  headerBackTitleVisible: false,
  headerBackImage: HeaderBackImage,
  header: (props) => <CustomHeader {...props} />
}

const FadeTransition = {
  // disable swiping on web
  gestureEnabled: Platform.OS !== 'web',
  gestureDirection: 'horizontal',
  transitionSpec: {
    open: TransitionSpecs.TransitionIOSSpec,
    close: TransitionSpecs.TransitionIOSSpec,
    useNativeDriver: true
  },
  headerStyleInterpolator: HeaderStyleInterpolators.forFade,
  cardStyleInterpolator: ({ current }) => ({
    cardStyle: {
      opacity: current.progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
      })
    }
  })
}

configureFontAwesomePro()

const fadeOptions = {
  headerTransparent: true,
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0
  },
  headerTintColor: 'transparent',
  ...FadeTransition
}

const closeLeft = {
  headerLeft: (style) => <CloseLeftButton closeButtonTextStyle={{ color: style.color || info }} />
}

const noHeader = { headerShown: false }
const closeRight = { closeRight: true }

const ModalStack = createStackNavigator()
const RootStack = createStackNavigator()

function ModalStackScreen() {
  return (
    <ModalStack.Navigator
      headerMode="float"
      screenOptions={{
        ...styles,
        ...{ gestureResponseDistance: { vertical: 20, horizontal: 20 } }
      }}
    >
      <ModalStack.Screen
        name="Comparison Tool"
        component={ComparisonToolScreen}
        options={{ ...options, ...closeLeft, ...{ title: 'Comparison Tool' } }}
      />
      <ModalStack.Screen
        name="Saved Careers"
        component={SavedCareersScreen}
        options={{
          ...options,
          ...closeLeft,
          ...{ title: 'Compare Saved Plans' }
        }}
      />

      <ModalStack.Screen
        name="Start Investing Category"
        component={StartInvestingCategoryScreen}
        options={{ ...options, ...closeRight, ...{ title: 'Start Investing' } }}
      />
      <ModalStack.Screen
        name="Start Investing Carousel"
        component={StartInvestingCarouselScreen}
        options={{ ...options, ...closeRight, ...{ title: 'Start Investing' } }}
      />

      {/* Plan Builder Screens */}
      <ModalStack.Screen
        name="Plan Builder Intro"
        component={PlanBuilderIntroScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen name="Edit Profile" component={ProfileForm} options={{ ...options }} />
      <ModalStack.Screen name="Edit Location" component={LocationForm} options={{ ...options }} />
      {/* Fast Plan Builder Screens */}
      <ModalStack.Screen name="Fast Edit Age" component={EditAgeScreen} options={{ ...options }} />
      <ModalStack.Screen
        name="Fast Edit Location"
        component={EditLocationScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Fast Edit Career"
        component={EditCareerScreen}
        options={{ ...options }}
      />

      <ModalStack.Screen name="Edit Goal" component={GoalForm} options={{ ...options }} />
      <ModalStack.Screen name="Edit Career" component={CareersScreen} options={{ ...options }} />
      <ModalStack.Screen name="Custom Career" component={CareerForm} options={{ ...options }} />
      <ModalStack.Screen
        name="Explore Occupation Group"
        component={OccupationGroupScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Explore Military Occupation Group"
        component={MilitaryOccupationGroupScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen name="Explore Career" component={CareerScreen} options={{ ...options }} />
      <ModalStack.Screen
        name="Explore Military Career"
        component={MilitaryCareerScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Investment Options"
        component={InvestmentOptionsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Investment Profile"
        component={InvestmentProfileScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen name="Custom Goal" component={GoalForm} options={{ ...options }} />
      <ModalStack.Screen name="Goal Lockin" component={GoalLockin} options={{ ...options }} />
      <ModalStack.Screen
        name="Review"
        component={ReviewPlanScreen}
        options={{ ...options, ...closeLeft }}
      />
      <ModalStack.Screen name="Pension" component={PensionScreen} options={{ ...options }} />

      <ModalStack.Screen
        name="My Debts"
        component={MyDebtScreen}
        options={{ ...options, ...closeLeft }}
      />
      <ModalStack.Screen
        name="My Debts Summary"
        component={MyDebtSummaryScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="My Debts Form"
        component={MyDebtFormScreen}
        options={{ ...options, ...{ title: 'Add New Debt' } }}
      />

      <ModalStack.Screen
        name="Plan Summary"
        component={PlanSummaryScreen}
        options={{ ...options, ...closeLeft, ...{ title: 'My Plan Summary' } }}
      />

      <ModalStack.Screen
        name="Finglish Dictionary"
        component={FinglishDictionaryScreen}
        options={{ ...options, ...{ title: 'Finglish Dictionary' } }}
      />
      <ModalStack.Screen
        name="How To Dictionary"
        component={HowToDictionaryScreen}
        options={{ ...options, ...closeRight, ...{ title: 'How To' } }}
      />
      <ModalStack.Screen
        name="Video Player"
        component={VideoPlayerScreen}
        options={{ ...options, ...closeRight }}
      />

      {/* Upgrade Plan to Pro  */}
      <ModalStack.Screen
        name="Upgrade Plan Intro"
        component={UpgradePlanIntroScreen}
        options={{ ...options, ...closeLeft }}
      />
      <ModalStack.Screen
        name="Upgrade Plan Add Accounts"
        component={UpgradePlanAddAccounts}
        options={{ ...options, ...{ title: 'Welcome', subtitle: 'Accounts' } }}
      />
      <ModalStack.Screen
        name="View BS Account"
        component={ViewBSAccountScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Edit/Add BS Accounts"
        component={EditAddBSAccountScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Upgrade Plan Add Goal"
        component={UpgradePlanGoalScreen}
        options={{ ...options, ...{ title: 'Pro Plan' } }}
      />

      <ModalStack.Screen
        name="Cash Flow Waterfall Intro"
        component={CashWaterfallIntroScreen}
        options={{ ...options, ...closeLeft }}
      />
      <ModalStack.Screen
        name="Cash Flow Waterfall"
        component={CashWaterfallScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Cash Flow Waterfall Form"
        component={CashWaterfallFormScreen}
        options={{ ...options }}
      />

      {/* Pro Screens */}
      <ModalStack.Screen
        name="Balance Sheet Screen"
        component={MyBalanceSheetScreen}
        options={{ ...options, ...{ title: 'My Balance Sheet' } }}
      />
      <ModalStack.Screen
        name="Budgeting Screen"
        component={BudgetingScreen}
        options={{ ...options, ...{ title: 'Budgeting' } }}
      />
      <ModalStack.Screen
        name="Budget Category Details"
        component={BudgetCategoryDetailsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Budget Subcategory Details"
        component={BudgetSubcategoryDetailsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Budget Subcategory Transactions"
        component={BudgetSubcategoryTransactionsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Transaction Form"
        component={TransactionFormScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Edit Pro Plan Details"
        component={EditProPlanDetailsScreen}
        options={{ ...options, ...{ title: 'Pro Plan' } }}
      />
      <ModalStack.Screen
        name="Transactions"
        component={TransactionsScreen}
        options={{ ...options, ...{ title: 'Transactions' } }}
      />

      {/* Career Explore  */}
      <ModalStack.Screen
        name="Career Explore Intro"
        component={CareerExploreIntroScreen}
        options={{ ...closeLeft, ...options }}
      />
      <ModalStack.Screen
        name="Career Explore Location Search"
        component={CareerExploreLocationSearchScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Career Explore Occupation Groups"
        component={CareerExploreOccupationGroupsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Career Explore Occupations"
        component={CareerExploreOccupationsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Military Career Explore Occupations"
        component={MilitaryCareerExploreOccupationsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Career Explore"
        component={CareerExploreScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Military Career Explore"
        component={MilitaryCareerExploreScreen}
        options={{ ...options }}
      />

      {/* College Explore */}
      <ModalStack.Screen
        name="College Explore Intro"
        component={CollegeExploreIntroScreen}
        options={{ ...closeLeft, ...options }}
      />

      <ModalStack.Screen
        name="College Explore Search"
        component={CollegeExploreSearchScreen}
        options={{ ...closeRight, ...options }}
      />
      <ModalStack.Screen
        name="College Explore"
        component={CollegeExploreScreen}
        options={{ ...closeRight, ...options }}
      />
      <ModalStack.Screen
        name="College Explore Filter"
        component={CollegeExploreFilterScreen}
        options={{ ...closeRight, ...options }}
      />
      <ModalStack.Screen
        name="College Explore Page"
        component={CollegeExploreSearchPage}
        options={{ ...closeLeft, ...options }}
      />

      <ModalStack.Screen
        name="Surplus"
        component={SurplusScreen}
        options={{ ...options, ...closeLeft }}
      />
      <ModalStack.Screen
        name="Edit Life Hacks"
        component={LifeHacksForm}
        options={{ ...closeLeft }}
      />
      <ModalStack.Screen
        name="Edit Add Ons"
        component={AddOnsFormScreen}
        options={{ ...options, ...closeLeft }}
      />
      <ModalStack.Screen
        name="Edit Add On"
        component={EditAddOnFormScreen}
        options={{ ...options }}
      />

      <ModalStack.Screen
        name="Taxes Breakdown"
        component={TaxesTable}
        options={{ ...closeLeft, ...{ title: 'Taxes Breakdown' } }}
      />
      <ModalStack.Screen
        name="Edit Student Loans"
        component={StudentLoanForm}
        options={{ ...closeLeft }}
      />
      <ModalStack.Screen
        name="Edit Medical Insurance"
        component={MedicalInsuranceForm}
        options={{ ...closeLeft }}
      />
      <ModalStack.Screen name="Edit Housing" component={HousingForm} options={{ ...closeLeft }} />
      <ModalStack.Screen
        name="Edit Utilities"
        component={UtilitiesForm}
        options={{ ...closeLeft }}
      />
      <ModalStack.Screen
        name="Edit Transportation"
        component={TransportationForm}
        options={{ ...closeLeft }}
      />
      <ModalStack.Screen name="Edit Food" component={FoodForm} options={{ ...closeLeft }} />

      <ModalStack.Screen name="Pre Auth Registration" component={RegistrationScreen} />
      <ModalStack.Screen name="Pre Auth Login" component={LoginScreen} />

      <ModalStack.Screen name="Reset Password" component={ResetPasswordScreen} />
      <ModalStack.Screen
        name="Reset Password Successful"
        component={ResetPasswordSuccessfulScreen}
      />

      <ModalStack.Screen
        name="Library"
        component={LibraryScreen}
        options={{ ...options, ...closeRight }}
      />
      <ModalStack.Screen
        name="Library Item"
        component={LibraryItemScreen}
        options={{ ...options, ...closeRight }}
      />
      <ModalStack.Screen
        name="Library Books"
        component={LibraryBooksScreen}
        options={{ ...options, ...closeRight }}
      />
      <ModalStack.Screen
        name="Library Book"
        component={LibraryBookScreen}
        options={{ ...options, ...closeRight }}
      />
      <ModalStack.Screen
        name="Quiz"
        component={QuizScreen}
        options={{ ...options, ...closeRight }}
      />
      <ModalStack.Screen
        name="Author"
        component={AskTheAuthorScreen}
        options={{ ...options, ...closeRight }}
      />
      <ModalStack.Screen
        name="Quiz Score"
        component={LibraryQuizScoreScreen}
        options={{ ...{ title: 'Your Results' } }}
      />

      <ModalStack.Screen
        name="Cost of Living Location"
        component={CostOfLivingLocationScreen}
        options={{ ...options, ...closeLeft, ...{ title: 'Cost of Living' } }}
      />
      <ModalStack.Screen
        name="Cost of Living Location Search"
        component={CostOfLivingLocationSearchScreen}
        options={{ ...closeLeft }}
      />
      <ModalStack.Screen
        name="Cost of Living"
        component={CostOfLivingScreen}
        options={{ ...closeRight }}
      />

      <ModalStack.Screen
        name="Wellness Checklist Intro"
        component={WellnessChecklistIntroScreen}
        options={{
          ...closeLeft,
          ...options,
          ...{ title: null, headerTransparent: true }
        }}
      />
      <ModalStack.Screen
        name="Wellness Checklist Toolkit"
        component={WellnessChecklistToolkitScreen}
        options={{
          ...options,
          ...closeLeft,
          ...{ title: 'Wellness Checklist' }
        }}
      />
      <ModalStack.Screen
        name="Wellness Checklist"
        component={WellnessCheckScreen}
        options={{
          ...options,
          ...closeLeft,
          ...{ title: 'Wellness Checklist' }
        }}
      />
      <ModalStack.Screen
        name="Milestones"
        component={MilestoneScreen}
        options={{ ...options, ...closeLeft, ...{ title: 'Milestones' } }}
      />

      <ModalStack.Screen
        name="Emergency Preparedness Intro"
        component={EmergencyPreparednessIntroScreen}
        options={{ ...options, ...{ title: null, headerTransparent: true } }}
      />
      <ModalStack.Screen
        name="Emergency Preparedness"
        component={EmergencyPreparednessListScreen}
        options={{ ...options, ...{ title: 'Emergency Preparedness' } }}
      />
      <ModalStack.Screen
        name="Emergency Preparedness Monthly Expenses"
        component={EmergencyPreparednessMonthlyExpensesScreen}
        options={{ ...options, ...{ title: 'Emergency Preparedness' } }}
      />
      <ModalStack.Screen
        name="Emergency Preparedness Emergency Funds"
        component={EmergencyPreparednessEmergencyFundsScreen}
        options={{ ...options, ...{ title: 'Emergency Preparedness' } }}
      />
      <ModalStack.Screen
        name="Emergency Preparedness Source Form"
        component={EmergencyPreparednessAddSourceScreen}
        options={{ ...options, ...{ title: 'Add Source' } }}
      />
      <ModalStack.Screen
        name="Emergency Preparedness Emergency Finish"
        component={EmergencyPreparednessFinishScreen}
        options={{ ...options, ...{ title: 'Emergency Preparedness' } }}
      />

      <ModalStack.Screen
        name="Calculators"
        component={CalculatorsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Troutwood Tools"
        component={TWToolsScreen}
        options={{ ...options }}
      />

      <ModalStack.Screen
        name="Opportunity Cost Intro"
        component={OpportunityCostIntroScreen}
        options={{ ...closeLeft, ...{ title: null, headerTransparent: true } }}
      />
      <ModalStack.Screen
        name="Opportunity Cost"
        component={OpportunityCostScreen}
        options={{
          ...options,
          ...closeRight,
          ...{ title: 'Opportunity Cost' }
        }}
      />
      <ModalStack.Screen
        name="Opportunity Cost Calculator"
        component={OpportunityCostCalculatorScreen}
        options={{
          ...options,
          ...closeRight,
          ...{ title: 'Opportunity Cost' }
        }}
      />
      <ModalStack.Screen
        name="Opportunity Cost Result"
        component={OpportunityCostResultScreen}
        options={{
          ...options,
          ...closeRight,
          ...{ title: 'Opportunity Cost' }
        }}
      />

      <ModalStack.Screen
        name="Loan Calculator Intro"
        component={LoanCalculatorIntroScreen}
        options={{ ...closeLeft, ...{ title: null, headerTransparent: true } }}
      />
      <ModalStack.Screen
        name="Loan Calculator Selection"
        component={LoanCalculatorSelectionScreen}
        options={{ ...options, ...closeRight, ...{ title: 'Loan Calculator' } }}
      />
      <ModalStack.Screen
        name="Loan Calculator"
        component={LoanCalculatorScreen}
        options={{ ...options, ...closeRight, ...{ title: 'Loan Calculator' } }}
      />
      <ModalStack.Screen
        name="Loan Calculator Results"
        component={LoanCalculatorResultsScreen}
        options={{ ...options, ...closeRight, ...{ title: 'Loan Calculator' } }}
      />
      <ModalStack.Screen
        name="Millionaire Calculator Intro"
        component={MillionaireCalculatorIntroScreen}
        options={{ ...options, ...{ title: 'Millionaire Calculator' } }}
      />
      <ModalStack.Screen
        name="Millionaire Calculator"
        component={MillionaireCalculatorScreen}
        options={{
          ...options,
          ...closeRight,
          ...{ title: 'Millionaire Calculator' }
        }}
      />

      <ModalStack.Screen
        name="Future Value Calculator Intro"
        component={FutureValueCalculatorIntroScreen}
        options={{
          ...options,
          ...closeRight,
          ...{ title: 'Future Value Calculator' }
        }}
      />
      <ModalStack.Screen
        name="Future Value Calculator"
        component={FutureValueCalculatorScreen}
        options={{
          ...options,
          ...closeRight,
          ...{ title: 'Future Value Calculator' }
        }}
      />

      <ModalStack.Screen
        name="Net Worth Calculator Intro"
        component={NetWorthCalculatorIntroScreen}
        options={{
          ...options,
          ...closeRight,
          ...{ title: 'Net Worth Calculator' }
        }}
      />
      <ModalStack.Screen
        name="Net Worth Calculator"
        component={NetWorthCalculatorScreen}
        options={{
          ...options,
          ...closeRight,
          ...{ title: 'Net Worth Calculator' }
        }}
      />

      <ModalStack.Screen
        name="User Options Menu"
        component={UserOptionsMenuScreen}
        options={{ ...options, ...{ title: 'More' } }}
      />
      <ModalStack.Screen
        name="Edit User Profile"
        component={EditProfileScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Edit Password"
        component={EditPasswordScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen name="Join Team" component={JoinTeamScreen} options={{ ...options }} />

      <ModalStack.Screen name="My Teams" component={MyTeamsScreen} options={{ ...options }} />

      {/*  Budget */}
      <ModalStack.Screen
        name="Create Budget Intro"
        component={CreateBudgetIntroScreen}
        options={{ ...options, ...noHeader }}
      />
      <ModalStack.Screen
        name="Create Budget"
        component={CreateBudgetScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Budget Items List"
        component={BudgetItemsListScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Update Budget"
        component={UpdateBudgetItemScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Create Budget Success"
        component={CreateBudgetSuccessScreen}
        options={{ ...options, ...closeLeft, ...noHeader }}
      />

      <ModalStack.Screen
        name="Budget Trends Details"
        component={BudgetTrendDetailsScreen}
        options={{ ...options, ...{ title: 'Budget Forecast & Trends' } }}
      />

      <ModalStack.Screen name="FoolProof" component={FoolProofScreen} options={{ ...options }} />

      {/* Join Team */}
      <ModalStack.Screen
        name="Join Team Welcome"
        component={JoinTeamWelcomeScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Join Team Interests"
        component={JoinTeamInterestsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Join Team Wellness Checklist"
        component={JoinTeamWellnessChecklistScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Join Team Success"
        component={JoinTeamSuccessScreen}
        options={{ ...options }}
      />

      {/* Life Events */}
      <ModalStack.Screen
        name="Add Life Events"
        component={AddLifeEventsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Add Life Event"
        component={AddLifeEventScreen}
        options={{ ...options }}
      />

      <ModalStack.Screen name="Likelihood" component={LikelihoodScreen} options={{ ...options }} />
      <ModalStack.Screen name="Risk Profile" component={RiskProfile} options={{ ...options }} />
      <ModalStack.Screen
        name="Risk Assessment"
        component={RiskAssessmentWizard}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="My Risk Profile"
        component={RiskAssessmentScore}
        options={{ ...closeRight, ...options }}
      />
      <ModalStack.Screen
        name="Estimated Value"
        component={EstimatedValueScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Adequacy Score"
        component={AdequacyScoreScreen}
        options={{ ...options }}
      />

      <ModalStack.Screen
        name="Make An Impact"
        component={MakeAnImpactScreen}
        options={{ ...options, ...{ title: 'Share' } }}
      />

      {/* Finmoji */}
      <ModalStack.Screen name="My Finmoji" component={MyFinmojiScreen} options={{ ...options }} />
      <ModalStack.Screen
        name="Finmoji Library"
        component={FinmojiLibraryScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen name="Finmoji" component={FinmojiScreen} options={{ ...options }} />

      {/* FinPulse */}
      <ModalStack.Screen name="FinPulse" component={FinPulseScreen} options={{ ...options }} />
      <ModalStack.Screen
        name="FinPulse Settings"
        component={FinPulseSettingsScreen}
        options={{ ...options }}
      />

      {/* Notifications */}
      <ModalStack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{ ...options }}
      />
      <ModalStack.Screen
        name="Notification Details"
        component={NotificationDetails}
        options={{ ...options }}
      />
    </ModalStack.Navigator>
  )
}

function LoginStackScreen({ navigation }) {
  const LoginStack = createStackNavigator()

  return (
    <LoginStack.Navigator headerMode="float" screenOptions={{ ...styles }}>
      <LoginStack.Screen
        name="Get Started"
        component={GetStartedScreen}
        options={{ ...options, headerShown: false, ...fadeOptions }}
      />
      <LoginStack.Screen name="Login" component={LoginScreen} options={{ ...options }} />
      <LoginStack.Screen
        name="Registration"
        component={RegistrationScreen}
        options={{ ...options }}
      />
    </LoginStack.Navigator>
  )
}

function App() {
  useEffect(() => {
    Amplify.configure(awsconfig)
  })

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

  async function boot() {
    const token = await getAccessToken()

    troutwoodStore.dispatch(setLoading({ loading: true }))

    if (token) {
      try {
        await troutwoodStore.dispatch(login({ token }))
        setInitialRoute('Main')
      } catch (error) {
        troutwoodStore.dispatch(clear())
        await troutwoodStore.dispatch(setLoading({ loading: false }))
        setInitialRoute('Login')
      }
    } else {
      troutwoodStore.dispatch(setLoading({ loading: false }))
      setInitialRoute('Login')
    }
  }

  useEffect(() => {
    boot()
    setUpBranch()
  }, [])

  useSentry()
  usePinpoint(troutwoodStore)

  const routeNameRef = useRef()
  const navigationRef = useRef()

  const [initialRoute, setInitialRoute] = useState(null)

  return (
    <Provider store={troutwoodStore}>
      <InAppAlert />

      <LoadingScreen>
        <NavigationContainer
          ref={navigationRef}
          onReady={() => {
            const currentRoute = navigationRef.current.getCurrentRoute()
            const currentRouteName = currentRoute?.name
            routeNameRef.current = currentRouteName
          }}
          onStateChange={() => {
            const previousRouteName = routeNameRef.current
            const currentRoute = navigationRef.current.getCurrentRoute()
            const currentRouteName = currentRoute?.name
            const { currentUser } = troutwoodStore.getState()

            if (previousRouteName !== currentRouteName) {
              trackNavigation(currentRouteName, currentUser)
            }

            // Save the current route name for later comparison
            routeNameRef.current = currentRouteName
          }}
        >
          <OfflineScreen>
            <ForcedAppUpdateScreen>
              <BottomSheetModalProvider>
                {initialRoute ? (
                  <RootStack.Navigator
                    initialRouteName={initialRoute}
                    mode="modal"
                    headerMode="none"
                  >
                    <RootStack.Screen
                      name="Login"
                      component={LoginStackScreen}
                      options={{ animationEnabled: false }}
                    />
                    <RootStack.Screen name="Modal" component={ModalStackScreen} />
                    <RootStack.Screen
                      name="Main"
                      component={TabbedStack}
                      options={{ animationEnabled: false }}
                    />
                  </RootStack.Navigator>
                ) : (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <ActivityIndicator size={'large'} />
                  </View>
                )}
              </BottomSheetModalProvider>
            </ForcedAppUpdateScreen>
          </OfflineScreen>
        </NavigationContainer>
      </LoadingScreen>
    </Provider>
  )
}

export default Sentry.wrap(gestureHandlerRootHOC(App))
