import { useState, useEffect, useRef } from 'react'
import { StyleSheet, Animated, View, Text, } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { DEFAULT_SPACING, white, antiFlashWhite } from '../styles'
import LottieView from './lottieView/lottieView'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginRight: DEFAULT_SPACING
  },
  pulse: (disabled) => ({
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: disabled ? null : '#000',
    shadowOffset: disabled
      ? null
      : {
        width: 0,
        height: 2
      },
    shadowOpacity: disabled ? null : 0.25,
    shadowRadius: disabled ? null : 3.84,
    elevation: 5
  }),
  circle: (size, backgroundColor) => ({
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: size,
    height: size,
    borderRadius: size / 2,
    backgroundColor
  }),
  header: {
    marginTop: -3,
    fontSize: 15,
    fontWeight: '600',
    color: white
  },
  subheader: {
    fontSize: 6,
    color: white,
    letterSpacing: -0.45,
    marginTop: -3
  }
})

function HeaderAndSubheader({
  header,
  subheader,
  headerStyle = {},
  subheaderStyle = {}
}) {
  return (
    <>
      <Text style={[styles.header, headerStyle]}>{header}</Text>
      <Text style={[styles.subheader, subheaderStyle]}>{subheader}</Text>
    </>
  )
}

function Icon({ icon, color, size, type }) {
  return <FontAwesomeIcon icon={icon} color={color} size={size} type={type} />
}

export default function PulseCircle({
  size = 35,
  scaleEnd = 1.2,
  color = antiFlashWhite,
  header,
  subheader,
  icon,
  iconColor,
  iconSize = 26,
  iconType = 'fas',
  style = {},
  headerStyle = {},
  subheaderStyle = {},
  disabled = false,
  resize = false
}) {
  const scaleAnim = useRef(new Animated.Value(0.8)).current
  const fadeAnim = useRef(new Animated.Value(0.8)).current
  const [intervalId, setIntervalId] = useState(null)

  const pulseAnimation = () => {
    scaleAnim.setValue(0.8) // reset the scale
    fadeAnim.setValue(0.8) // reset the opacity

    // animate scale and opacity
    Animated.parallel([
      Animated.timing(scaleAnim, {
        toValue: scaleEnd,
        duration: 2000,
        useNativeDriver: true
      }),
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 2000,
        useNativeDriver: true
      })
    ]).start()
  }

  useEffect(() => {
    if (!disabled) {
      clearInterval(intervalId)

      // make sure to run it because
      // setInterval will only run after 3 seconds
      pulseAnimation()

      const newIntervalId = setInterval(() => {
        pulseAnimation()
      }, 3000)

      setIntervalId(newIntervalId)
    } else {
      clearInterval(intervalId)
    }
  }, [disabled])

  const animatedStyles = {
    backgroundColor: color,
    opacity: fadeAnim,
    transform: [{ scale: scaleAnim }],
    width: size,
    height: size,
    borderRadius: size / 2
  }

  // disabling should increase the size since we are not animating
  // we only want this to appear if you specify to resize
  const sizeWithDisabled = disabled && resize ? size * 1.2 : size

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.pulse(disabled), animatedStyles, style]} />
      <View
        style={styles.circle(sizeWithDisabled - sizeWithDisabled * 0.2, color)}
      >
        {icon && (icon !== 'wave-pulse' || disabled) && (
          <Icon icon={icon} color={iconColor} size={iconSize} type={iconType} />
        )}

        {icon && icon == 'wave-pulse' && !disabled && (
          <LottieView
            autoPlay
            style={{
              width: size * 1.2,
              height: size * 1.2
            }}
            // Find more Lottie files at https://lottiefiles.com/featured
            source={require('../assets/finpulse.json')}
          />
        )}

        {header && subheader && (
          <HeaderAndSubheader
            header={header}
            subheader={subheader}
            headerStyle={headerStyle}
            subheaderStyle={subheaderStyle}
          />
        )}
      </View>
    </View>
  )
}
