import axios from 'axios'
import { setAppConfig } from './setAppConfig'
import { APP_CONFIG_URL, APP_CONFIG_KEY, APP_CONFIG_ENV } from '@env'

// eslint-disable-next-line import/prefer-default-export
export const getAppConfig = () => async (dispatch, getState) => {
  try {
    const data = {
      configuration_name: 'FeatureFlags',
      environment_name: APP_CONFIG_ENV
    }

    const headers = {
      'Content-Type': 'application/json',
      accept: 'application/json',
      'x-api-key': APP_CONFIG_KEY
    }
    const response = await axios.post(APP_CONFIG_URL, data, { headers })
    dispatch(setAppConfig(response.data))
  } catch (e) {
    console.warn(e, e.response)
  }
}
