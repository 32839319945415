import { useEffect, useLayoutEffect, useState } from 'react'
import { Text, StyleSheet, Image, Platform, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { first } from 'lodash'

import useImageAspectRatio from './hooks/useAspectRatio'
import { darkblue, gunmetal, DEFAULT_SPACING, } from './styles'
import Container from './components/Container'
import SquareCheckBox from './components/SquareCheckBox'
import BackButton from './components/teamOnboarding/BackButton'
import Button from './components/Button'

const basicStyles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  organizationLogo: {
    width: 260,
    marginVertical: DEFAULT_SPACING * 2
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    lineHeight: 33,
    color: darkblue,
    textAlign: 'center',
    marginVertical: DEFAULT_SPACING * 2
  },
  body: {
    marginBottom: DEFAULT_SPACING * 4,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center',
    zIndex: 100,
    fontSize: 16
  },
  buttonWrapper: {
    width: '100%',
    position: 'absolute',
    alignItems: 'center',
    bottom: 24,
  },
  button: { marginHorizontal: 20, },
  planTxt: { width: '100%', paddingBottom: 40, alignItems: 'center', }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      buttonWrapper: { bottom: 54, },
      button: {
        width: 345,
        marginHorizontal: 'auto',
      },
      planTxt: { paddingBottom: 120, }
    }
  },
)

const isNotWeb = Platform.OS !== 'web'

export default function JoinOrganizationWelcomeScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [selected, setSelected] = useState(false)

  const { invitation } = route.params
  const {
    organization_logo: organizationLogo,
    organization_name: organizationName,
    recipient_name: recipientName,
  } = invitation

  const firstName = first(recipientName.split(' ')) || 'Friend'

  useEffect(() => {
    navigation.setOptions({ title: 'Join Team' })
  }, [])

  const aspectRatio = useImageAspectRatio(organizationLogo)

  function handlePress() {
    const invitationParams = { ...invitation, is_graduated_student: selected }

    navigation.navigate('Modal', { screen: 'Join Team Interests', params: { invitation: invitationParams } })
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: null,
      headerTransparent: true,
      headerLeft: () => (<BackButton onPress={() => navigation.pop()} />)
    })
  }, [navigation])

  return (
    <Container card={true} style={[styles.container, { marginTop: isNotWeb ? 160 : 'auto' }]}>
      {organizationLogo && (
        <Image
          style={[styles.organizationLogo, { aspectRatio }]}
          source={{ uri: organizationLogo }}
          resizeMode="contain"
        />
      )}
      {!organizationLogo && <Text style={styles.header}>{organizationName}</Text>}
      <Text style={styles.header}>
        Welcome,
        {` ${firstName}`}
        !
      </Text>
      <Text style={styles.body}>
        It's your financial future. Own it.
        {'\n'}
        Let's get started.
      </Text>

      <View style={styles.buttonWrapper}>
        <Button
          style={styles.button}
          onPress={handlePress}
        >
          Continue
        </Button>
      </View>
    </Container>
  )
}
