import { View } from 'react-native'

import { useSelector } from 'react-redux'
import { currentUserSelector } from '../../selectors'

import HeaderButton from './HeaderButton'
import { grayblue } from '../../styles/colors'

export default function SaveButton({ onPress, color = grayblue, iconSize = 20 }) {
  const currentUser = useSelector((state) => currentUserSelector(state))

  if (currentUser && !currentUser.is_fake) {
    return (
      <HeaderButton
        onPress={onPress}
        icon="pencil"
        iconColor={color}
        iconSize={iconSize}
      />
    )
  }
  return <View />
}
