import { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { primary } from '../styles/colors'
import TouchableOpacity from './TouchableOpacity'

function ToggleMultiSelectDropdown({ onTouch, checked, ...props }) {
  return (
    <TouchableOpacity onPress={onTouch} hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }} {...props}>
      <FontAwesomeIcon icon={checked ? 'square-check' : ['far', 'square']} fill={checked ? primary : 'gray'} />
    </TouchableOpacity>
  )
}

export default memo(ToggleMultiSelectDropdown)
