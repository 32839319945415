import { StyleSheet, Text } from 'react-native'
import { white } from '../../../styles'

const styles = StyleSheet.create({
  axisLabel: {
    position: 'absolute',
    backgroundColor: white,
  },
  yAxisTop: {
    left: -12,
    top: 0,
    paddingBottom: 8,
  },
  xAxisTop: {
    right: 0,
    bottom: -12,
    paddingBottom: 4,
  },
  zeroLabel: {
    bottom: -12,
    left: -12,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
})
function AxisLabelGroup() {
  return (
    <>
      <Text style={[styles.axisLabel, styles.yAxisTop]}>100%</Text>
      <Text style={[styles.axisLabel, styles.xAxisTop]}>100%</Text>
      <Text style={[styles.axisLabel, styles.zeroLabel]}>0</Text>
    </>
  )
}

export default AxisLabelGroup
