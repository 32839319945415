import { useEffect, useState } from 'react'
import { Text, View, StyleSheet } from 'react-native'

import { each, orderBy } from 'lodash'
import Occupations from './Occupations'

import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  // what should go here...
})

function matchesSearch(string, careerSearch) {
  return string.toLowerCase().match(careerSearch.toLowerCase())
}

export default function SearchedOccupations({ groupOptions, careerSearch, onOccupationPress }) {
  const [careers, setCareers] = useState([])

  useEffect(() => {
    const careerList = []
    each(groupOptions, (group) => {
      each(group.careers, (career) => {
        const nameMatch = matchesSearch(career.name, careerSearch)
        const termMatch = matchesSearch(career.tags_string || '', careerSearch)
        const descriptionMatch = matchesSearch(career.description || '', careerSearch)

        if (nameMatch || termMatch || descriptionMatch) {
          careerList.push(career)
        }
      })
    })
    setCareers(orderBy(careerList || [], ['name']))
  }, [careerSearch])

  if (careers.length === 0) {
    return (
      <View>
        <Text>
          {`No Results for ${careerSearch}. Keep exploring.`}
        </Text>
      </View>
    )
  }

  return (
    <View>
      <Text>
        {`Results for ${careerSearch}:`}
      </Text>
      <Occupations onOccupationPress={onOccupationPress} careers={careers} />
      <Text style={{ marginVertical: DEFAULT_SPACING }}>Or continue exploring:</Text>
    </View>
  )
}
