import { useState } from 'react'
import { ActivityIndicator, View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import AccountList from './AccountList'
import CircleIcon from './CircleIcon'

import { rgba, gunmetal, lightblue, white, lightgray } from '../styles/colors'

import Card from './Card'
import AccountsListHeader from './AccountsListHeader'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  container: {
    position: 'relative'
  },
  activityIndicator: {
    position: 'absolute', 
    top: 0, 
    bottom: 0, 
    left: 0, 
    right: 0
  },
  card: (loading) => ({ 
    borderRadius: 12,
    opacity: loading ? 0.5 : 1
  }),
  boldLinkText: {
    fontWeight: '400',
    fontSize: 16,
    color: lightblue,
    marginLeft: 18
  },
  circleStyle: (expanded) => ({
    marginRight: 10,
    transform: [{ rotate: expanded ? '270deg' : '90deg' }]
  }),
  headerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  listStyle: (expanded) => ({ display: expanded ? 'flex' : 'none' }),
  addAccountButton: { height: 64, justifyContent: 'center' }
})

export default function AccountListDropdown({
  style,
  isAsset,
  label,
  accounts,
  expandable,
  loading,
  onShowAccount,
  onAddAccount,
  onEditAccount,
  onDeleteAccount,
  draggable,
  onDragEnd
}) {
  const [expanded, setExpanded] = useState(!expandable)

  const onPressHeader = () => (expandable && setExpanded(!expanded))

  return (
    <View style={styles.container}>
      { loading && (
        <ActivityIndicator 
          animating={loading} 
          size="large" 
          color={gunmetal} 
          style={styles.activityIndicator}
        />)
      }

      <Card style={[style, styles.card(loading)]}>
        <TouchableOpacity
          testId="clickable"
          style={styles.headerStyle}
          onPress={onPressHeader}
        >
          <AccountsListHeader label={label} isAsset={isAsset} accounts={accounts} onPress={onPressHeader} />

          {expandable && (
            <CircleIcon
              style={styles.circleStyle(expanded)}
              icon="chevron-right"
              color={white}
              backgroundColor={lightgray}
              size={10}
              width={10}
              height={10}
            />
          )}

        </TouchableOpacity>
        
        {expanded && (
          <AccountList
            list={accounts}
            onShowAccount={onShowAccount}
            hideIcon
            compressed
            loading={loading}
            draggable={draggable}
            onDragEnd={onDragEnd}
          />
        )}
        {
          onAddAccount && (
            <View testId="viewableList" style={styles.listStyle(expanded)}>
              <TouchableOpacity style={styles.addAccountButton} onPress={onAddAccount}>
                <Text style={styles.boldLinkText}>+ Add New {label}</Text>
              </TouchableOpacity>
            </View>
          )
        }
      </Card>
    </View>
  )
}

AccountListDropdown.defaultProps = {
  style: {},
  expandable: true,
  draggable: false,
  loading: false,
  onShowAccount: undefined,
  onAddAccount: undefined,
  onEditAccount: undefined,
  onDeleteAccount: undefined,
  onDragEnd: undefined
}

AccountListDropdown.propTypes = {
  style: PropTypes.object,
  isAsset: PropTypes.bool.isRequired,
  draggable: PropTypes.bool,
  loading: PropTypes.bool,
  accounts: PropTypes.array.isRequired,
  expandable: PropTypes.bool,
  onShowAccount: PropTypes.func.isRequired,
  onAddAccount: PropTypes.func,
  onEditAccount: PropTypes.func,
  onDeleteAccount: PropTypes.func,
  onDragEnd: PropTypes.func
}
