import { useState, useEffect } from 'react'
import { Text, View, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'

import { Table, Row, Col, TableWrapper } from 'react-native-table-component'

import { map } from 'lodash'
import { get } from 'http'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { darkblue, darkgrayblue, gunmetal, lightblue, primary, rgba, white } from './styles/colors'

import TouchableOpacity from './components/TouchableOpacity'

import formatCurrency from './functions/formatCurrency'
import investmentSelection from './functions/investmentSelection'

import occupationName from './functions/occupationName'

import investmentType from './functions/investmentType'
import computeAge from './functions/computeAge'
import handleError from './functions/handleError'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white
  },
  scrollContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 10
  },
  careerLabel: {
    letterSpacing: -0.37,
    fontSize: 24,
    textAlign: 'center',
    color: darkgrayblue,
    fontWeight: '600',
    marginBottom: 10
  },
  locationLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: gunmetal,
    marginBottom: 44
  },
  label: {
    fontSize: 14,
    color: gunmetal,
    marginBottom: 9
  },
  value: {
    fontSize: 16,
    fontWeight: '400',
    color: gunmetal
  },
  salary: {
    fontSize: 32,
    fontWeight: '700',
    color: darkgrayblue,
    marginBottom: 27
  },
  description: {
    letterSpacing: -0.6,
    fontSize: 14.4,
    color: 'rgb(66, 86, 103)',
    marginBottom: 9
  },
  wrapper: { flexDirection: 'row' },
  row: { height: 50 },
  head: { height: 80 },
  title: {
    fontWeight: '600',
    fontSize: 12,
    color: darkgrayblue,
    letterSpacing: 0,
    paddingVertical: 9,
    paddingHorizontal: 9,
  },
  titleLabel: { fontWeight: '300' },
  titleText: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    fontWeight: '400'
  },
  text: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    color: darkgrayblue,
    letterSpacing: -0.5
  },
  table: { width: 170 },
  tableBorder: {
    borderWidth: 0.5,
    borderColor: 'rgb(221, 228, 230)'
  },
  scrollViewContainer: { flex: 2 },
  caretUp: {
    position: 'absolute',
    top: 10,
    left: 80
  },
  caretDown: {
    position: 'absolute',
    top: 10,
    left: 80
  },
  tableHeaderContainer: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    width: 130
  },
  tableHeaderCareer: {
    fontWeight: '700',
    fontSize: 14,
    color: lightblue
  },
  tableHeaderLocation: {
    fontSize: 10,
    color: darkblue,
    paddingTop: 10
  }
})

function TableHeaderCareer(props) {
  const form = props || {}
  const occupation = form ? occupationName(form) : ''
  const city = form.city || {}

  return (
    <View style={styles.tableHeaderContainer}>
      <Text style={styles.tableHeaderCareer}>{occupation && occupation.length > 24 ? `${occupation.substring(0, 24)}...` : occupation}</Text>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <FontAwesomeIcon icon={['fas', 'location-dot']} style={{ marginTop: 10, marginRight: 2 }} size={12} color={darkgrayblue} />

        <Text style={styles.tableHeaderLocation}>
          {city.name}
        </Text>
      </View>
    </View>
  )
}

function taxes(result) {
  return result.taxes ? result.taxes.annual.total : 0
}

function studentLoans(result) {
  return result.student_loan_payment ? result.student_loan_payment.cost.annual : 0
}

function medicalInsurance(result) {
  return result.healthcare_cost ? result.healthcare_cost.cost.annual : 0
}

function housingExpenses(result) {
  return result.housing_cost ? result.housing_cost.cost.annual : 0
}

function utilities(result) {
  return result.utilities_cost ? result.utilities_cost.cost.annual : 0
}

function transportationExpenses(result) {
  return result.transportation_cost ? result.transportation_cost.cost.annual : 0
}

function food(result) {
  return result.food_cost ? result.food_cost.cost.annual : 0
}

function essentials(result) {
  return taxes(result) + studentLoans(result) + medicalInsurance(result)
    + housingExpenses(result) + utilities(result) + transportationExpenses(result) + food(result)
}

function surplus(result) {
  return result.surplus && result.surplus.annual || 0
}

function grossIncome(result) {
  return result.gross_income ? result.gross_income.annual : 0
}

export default function ComparisonToolScreen({ route }) {
  const window = useWindowDimensions()
  const [expanded, setExpanded] = useState(false)
  const [compares, setCompares] = useState(route.params.compares)

  async function fetchAndSetData() {
    try {
      const promises = map(compares, (compare) => get(`api/v1/plans/${compare.id}/`))

      Promise.all(promises).then((newCompares) => {
        const values = map(newCompares, (compare, idx) => {
          const { data } = compare
          return { ...compares[idx], ...data }
        })
        setCompares(values)
      });
    } catch (error) {
      handleError(error, 'Unable to fetch comparison data')
    }
  }

  useEffect(() => {
    fetchAndSetData()
  }, map(compares, 'id'))

  const tableData = [
    map(compares, (form) => <Text style={[styles.text, { color: primary }]}>{`$${formatCurrency(form.annual_contribution_amount)}`}</Text>),
    map(compares, (form) => <Text style={styles.text}>{`${(form.probability_of_success).toFixed(0)}%`}</Text>), // goal likihod
    map(compares, (form) => investmentType(form)),
    map(compares, (form) => investmentSelection(form.investment_selection)),
    map(compares, (form) => form.periods),
    map(compares, (form) => computeAge(form.birth_date)),
    map(compares, (form) => (grossIncome(form) > 0 ? `$${formatCurrency(grossIncome(form))}` : '—')),
    map(compares, (form) => (essentials(form) > 0 ? `$${formatCurrency(essentials(form))}` : '—'))
  ]

  const tableTitle = [
    '',
    'My Save First Goal',
    'Goal Likelihood',
    'Investor Profile',
    'Investor Selection',
    'Years to Goal',
    'Age',
    'Salary',
    <TouchableOpacity onPress={() => setExpanded(!expanded)}>
      <Text style={styles.title}>
        Essentials
      </Text>
      {expanded ? <FontAwesomeIcon name="caret-up" style={styles.caretUp} color="#425667" size={12} /> : <FontAwesomeIcon icon="caret-down" style={styles.caretDown} color="#425667" size={12} />}
    </TouchableOpacity>
  ]

  if (expanded) {
    tableData.push(map(compares, (form) => (taxes(form) > 0 ? `$${formatCurrency(taxes(form))}` : '—')))
    tableData.push(map(compares, (form) => (studentLoans(form) > 0 ? `$${formatCurrency(studentLoans(form))}` : '—')))
    tableData.push(map(compares, (form) => (medicalInsurance(form) > 0 ? `$${formatCurrency(medicalInsurance(form))}` : '—')))
    tableData.push(map(compares, (form) => (housingExpenses(form) > 0 ? `$${formatCurrency(housingExpenses(form))}` : '—')))
    tableData.push(map(compares, (form) => (utilities(form) > 0 ? `$${formatCurrency(utilities(form))}` : '—')))
    tableData.push(map(compares, (form) => (transportationExpenses(form) > 0 ? `$${formatCurrency(transportationExpenses(form))}` : '—')))
    tableData.push(map(compares, (form) => (food(form) > 0 ? `$${formatCurrency(food(form))}` : '—')))

    tableTitle.push(<View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 9 }}>
      <FontAwesomeIcon icon={['far', 'chart-pie']} size={12} color="rgb(87,187,227)" />
      <Text style={[styles.title, styles.titleLabel, { paddingHorizontal: 4 }]}>Taxes</Text>
                    </View>)
    tableTitle.push(<View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 9 }}>
      <FontAwesomeIcon icon={['far', 'graduation-cap']} size={12} color="rgb(255, 201, 77)" />
      <Text style={[styles.title, styles.titleLabel, { paddingHorizontal: 4 }]}>Student Loan Payments</Text>
                    </View>)
    tableTitle.push(<View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 9 }}>
      <FontAwesomeIcon icon={['far', 'heart']} size={12} color="rgb(255, 61, 99)" />
      <Text style={[styles.title, styles.titleLabel, { paddingHorizontal: 4 }]}>Medical/Health Insurance</Text>
                    </View>)
    tableTitle.push(<View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 9 }}>
      <FontAwesomeIcon icon={['far', 'house']} size={12} color="rgb(66, 86, 103)" />
      <Text style={[styles.title, styles.titleLabel, { paddingHorizontal: 4 }]}>Housing</Text>
                    </View>)
    tableTitle.push(<View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 9 }}>
      <FontAwesomeIcon icon={['far', 'bolt']} size={12} color="rgb(255, 103, 77)" />
      <Text style={[styles.title, styles.titleLabel, { paddingHorizontal: 4 }]}>Utilities</Text>
                    </View>)
    tableTitle.push(<View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 9 }}>
      <FontAwesomeIcon icon={['far', 'car']} size={12} color="rgb(118, 88, 227)" />
      <Text style={[styles.title, styles.titleLabel, { paddingHorizontal: 4 }]}>Transportation</Text>
                    </View>)
    tableTitle.push(<View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 9 }}>
      <FontAwesomeIcon icon={['far', 'utensils']} size={12} color={gunmetal} />
      <Text style={[styles.title, styles.titleLabel, { paddingHorizontal: 4 }]}>Food</Text>
                    </View>)
  }

  tableData.push(map(compares, (form) => <Text style={[styles.text, { color: primary }]}>{`$${formatCurrency(surplus(form))}`}</Text>))
  tableTitle.push('Surplus')

  const tableConfig = {
    tableHead: map(compares, (form) => <TableHeaderCareer {...form} />),
    tableTitle,
    tableData
  }

  return (
    <View style={styles.container}>
      <Table>
        <ScrollView>
          <TableWrapper style={[styles.wrapper]}>
            <Table style={[styles.table, { minHeight: window.height }]} borderStyle={styles.tableBorder}>
              <Col data={tableConfig.tableTitle} heightArr={[80].concat(map(tableTitle, () => 50))} textStyle={styles.title} />
            </Table>
            <ScrollView horizontal style={[styles.scrollViewContainer, { minHeight: window.height }]}>
              <Table style={{ flex: 1 }} borderStyle={styles.tableBorder}>
                <Row data={tableConfig.tableHead} widthArr={map(compares, (form) => 130)} style={styles.head} textStyle={styles.titleText} />
                {map(tableConfig.tableData, (data, index) => {
                  let backgroundColor = (index % 2) == 0 ? 'rgba(52, 145, 247, 0.05)' : 'white'
                  backgroundColor = index == 0 ? rgba(primary, 0.1) : backgroundColor
                  return (
                    <Row
                      widthArr={map(compares, (form) => 130)}
                      data={data}
                      style={[styles.row, { backgroundColor }]}
                      textStyle={styles.text}
                    />
                  )
                })}
              </Table>
            </ScrollView>
          </TableWrapper>
        </ScrollView>
      </Table>
    </View>

  )
}
