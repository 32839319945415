import * as Yup from 'yup'

import {
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX
} from './constants'

export const Fields = {
  custom_healthcare_cost: 'custom_healthcare_cost',
  healthcare_selection: 'healthcare_selection'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.custom_healthcare_cost]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(10000, `${INVALID_MAX}10,000`)
    .nullable()
})

export const InitialValues = {
  [Fields.custom_healthcare_cost]: null,
  [Fields.healthcare_selection]: 'SILVER'
}
