import { View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

const useStyles = CreateResponsiveStyle(
  {
    headerWrapper: {
      paddingHorizontal: 24,
      paddingTop: 40,
    }
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      headerWrapper: {
        paddingTop: 0,
        paddingHorizontal: 0,
      },
    }
  }
)

const ToolsHeaderWrapper = ({ children }) => {
  const styles = useStyles()
  return (<View style={styles.headerWrapper}>{children}</View>)
}

export default ToolsHeaderWrapper
