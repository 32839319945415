import { shuffle, take, each } from 'lodash'

export default class Question {
  fetch(title, totalTurns) {
    const TheMissingSemesterJSON = require('../assets/QuizData/TheMissingSemesterQuizData.json')
    const TheMissingSecondSemesterJSON = require('../assets/QuizData/TheMissingSecondSemesterQuizData.json')

    let data;
    const questions = [];
    if (title == 'The Missing Semester') {
      data = (TheMissingSemesterJSON);
    } else {
      data = (TheMissingSecondSemesterJSON);
    }

    const shuffledQuestions = take(shuffle(data), totalTurns)

    each(shuffledQuestions, (question) => {
      let variables = ['correct_answer', 'alternative_a']
      if (question.alternative_b) {
        variables.push('alternative_b')
      }
      variables = shuffle(variables)
      question.a = variables[0]
      question.b = variables[1]
      question.c = variables[2]

      questions.push(question)
    })

    return questions;
  }
}
