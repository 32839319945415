import * as Yup from 'yup'

import {
  INVALID_INT,
  INVALID_MAX,
  INVALID_MIN,
  REQUIRED,
} from './constants'

export const Fields = {
  planName: 'name',
  horizonDate: 'horizonDate',
  amount: 'amount',
  inflationRate: 'inflationRate',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.planName]: Yup.string().required(REQUIRED),
  [Fields.horizonDate]: Yup.date().required(REQUIRED),
  [Fields.amount]: Yup.number()
    .min(1, INVALID_MIN)
    .max(999999999, `${INVALID_MAX}$999,999,999`)
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .required(REQUIRED)
    .nullable(),
  [Fields.inflationRate]: Yup.number()
    .min(0, INVALID_MIN)
    .max(100, INVALID_MAX)
    .required(REQUIRED)
    .nullable(),
})

export const InitialValues = ({
  name: planName,
  goal: {
    amount = 0,
    horizonDate
  } = {},
  inflationRate
} = {}) => ({
  [Fields.planName]: planName || '',
  [Fields.horizonDate]: horizonDate || {},
  [Fields.amount]: amount || null,
  [Fields.inflationRate]: inflationRate || 3.0,
})
