import { useRef, useLayoutEffect } from 'react'
import { Platform, View, Text, StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { useNavigationState } from '@react-navigation/native'
import { first } from 'lodash'

import Link from 'components/Link'
import Input from 'components/Input'
import Container from 'components/Container'
import Form from 'components/Form'
import HeaderLabel from 'components/HeaderLabel'

import { ValidationSchema, Fields, InitialValues } from './schemas/loginSchema'
import { login } from './actions'
import CloseButton from 'components/CloseButton'
import { DEFAULT_SPACING, gunmetal, white } from './styles'
import BackButton from './components/teamOnboarding/BackButton'

const basicStyles = {
  headerLabel: {
    marginBottom: 12,
    textAlign: 'center',
    width: '100%'
  },
  subtext: {
    fontSize: 16,
    marginBottom: 24,
    color: gunmetal,
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center'
  },
  container: { flex: 1, },
  button: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      headerLabel: {
        fontWeight: '600',
        fontSize: 38,
        lineHeight: 46,
      },
      subtext: {
        fontSize: 18,
        lineHeight: 22,
        fontWeight: '500',
      },
      container: {
        paddingHorizontal: 115,
      },
      button: {
        maxWidth: 345,
        marginHorizontal: 'auto',
        marginBottom: DEFAULT_SPACING * 2,
      },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function LoginScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const passwordField = useRef(null)
  const routes = useNavigationState((state) => state.routes)
  const firstRoute = first(routes)

  useLayoutEffect(() => {
    navigation.setOptions({
      title: null,
      headerTransparent: true,
      headerLeft: () =>
        firstRoute.name == 'Get Started' ? (
          <BackButton onPress={() => navigation.pop()} />
        ) : (
          <CloseButton color={white} onPress={() => navigation.pop()} />
        )
    })
  }, [navigation])

  return (
    <Container card={true}>
      <Form
        buttonText={'Log In'}
        buttonContainerStyle={styles.button}
        containerStyle={styles.container}
        validationSchema={ValidationSchema}
        initialValues={InitialValues}
        onSubmit={async (values, actions) => {
          try {
            await dispatch(login(values))

            // if the routes contain "Pre Auth Login"
            // then we need to pop that route off the stack
            // otherwise we can just reset the stack to main

            if (routes.find((route) => route.name == 'Pre Auth Login')) {
              navigation.pop()
            } else {
              navigation.reset({
                key: null,
                index: 0,
                routes: [{ name: 'Main' }]
              })
            }
          } catch (e) {
            console.error(e.response)
            actions.setFieldError(Fields.email, 'Invalid email or password')
          }
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched }) => (
          <>
            <HeaderLabel style={styles.headerLabel}>Log In</HeaderLabel>
            <Text style={styles.subtext}>
              Welcome back! Log in to your account to continue building your financial plan.
            </Text>

            <View>
              <Input
                name={Fields.email}
                errorMessage={errors[Fields.email]}
                touched={touched[Fields.email]}
                onChangeText={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                label={'Email Address'}
                secureTextEntry={false}
                returnKeyType={'next'}
                onSubmitEditing={() => passwordField.current.focus()}
              />

              <Input
                ref={passwordField}
                name={Fields.password}
                errorMessage={errors[Fields.password]}
                touched={touched[Fields.password]}
                onChangeText={handleChange('password')}
                onBlur={handleBlur('password')}
                value={values.password}
                label={'Password'}
                secureTextEntry={true}
                onSubmitEditing={() => handleSubmit()}
              />
            </View>

            <Link
              disabled={isSubmitting}
              onPress={() => navigation.navigate('Modal', { screen: 'Reset Password' })}
            >
              Reset Password
            </Link>
          </>
        )}
      </Form>
    </Container>
  )
}
