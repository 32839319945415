import { map, compact } from 'lodash'

import moment from 'moment'
import { primary, lotion } from '../../styles'

import formatCurrency from '../../functions/formatCurrency'

import PlanSummaryCard from './PlanSummaryCard'

import DataRow from '../DataRow'

import occupationName from '../../functions/occupationName'
import computeAge from '../../functions/computeAge'
import formatPercent from '../../functions/formatPercent'

function DataTable({
  loading,
  currentProPlan,
  currentPlan,
  freePlan,
  totalIncome,
  totalExpenses,
  total
}) {
  const { goal, inflationRate } = currentProPlan
  const { amount, horizonDate } = goal || {}

  const occupation = occupationName(currentPlan)
  const salary = (freePlan.gross_income && freePlan.gross_income['annual']) || 0

  const fontColor = total >= 0 ? primary : 'rgb(222, 54, 84)'

  const data = [
    {
      label: 'Age',
      value: loading ? '—' : computeAge(currentPlan.birth_date),
      params: {
        buttonText: 'Update',
        custom_career_name: occupation,
        input_custom_career: true,
        birth_date: currentPlan.birth_date,
        custom_career_salary: salary,
        city: currentPlan.city,
        result: freePlan
      },
      route: 'Edit Pro Plan Details'
    },
    {
      label: 'Goal Amount',
      value: loading ? '—' : `$${formatCurrency(amount || 0)}`,
      color: primary,
      params: { buttonText: 'Update' },
      route: 'Upgrade Plan Add Goal'
    },
    {
      label: 'Career',
      value: loading
        ? '—'
        : occupation && occupation.length > 16
          ? `${occupation.substring(0, 16)}...`
          : occupation,
      params: {
        buttonText: 'Update',
        custom_career_name: occupation,
        input_custom_career: true,
        birth_date: currentPlan.birth_date,
        custom_career_salary: salary,
        city: currentPlan.city,
        result: freePlan
      },
      route: 'Edit Pro Plan Details'
    },
    {
      label: 'Location',
      value: loading ? '—' : currentPlan.city ? currentPlan.city.name : 'N/A',
      params: {
        buttonText: 'Update',
        custom_career_name: occupation,
        input_custom_career: true,
        birth_date: currentPlan.birth_date,
        custom_career_salary: salary,
        city: currentPlan.city,
        result: freePlan
      },
      route: 'Edit Pro Plan Details'
    },
    {
      label: 'Horizon Date',
      value: loading ? '—' : moment(horizonDate).format('MMM DD, YYYY'),
      params: { buttonText: 'Update' },
      route: 'Upgrade Plan Add Goal'
    },
    {
      label: 'Inflation Rate',
      value: loading ? '—' : formatPercent(inflationRate),
      params: { buttonText: 'Update' },
      route: 'Upgrade Plan Add Goal'
    },
    {
      label: 'Monthly Income',
      value: loading ? '—' : `$${formatCurrency(totalIncome)}`,
      style: { backgroundColor: lotion },
      route: 'Create Budget',
      params: { title: 'Edit Budget', update: true }
    },
    {
      label: 'Monthly Expenses',
      value: loading ? '—' : `$${formatCurrency(totalExpenses)}`,
      style: { backgroundColor: lotion },
      route: 'Create Budget',
      params: { title: 'Edit Budget', update: true }
    },
    {
      label: loading || total > 0 ? 'Monthly Surplus' : 'Monthly Deficit',
      value: loading ? '—' : `$${formatCurrency(total)}`,
      color: fontColor,
      style: { backgroundColor: lotion },
      route: 'Create Budget',
      params: { title: 'Edit Budget', update: true }
    }
  ]

  return map(compact(data), (rowProps, index) => (
    <DataRow
      key={`${rowProps.label}-${currentProPlan.planId}`}
      {...rowProps}
      index={index}
      rightLabelStyle={{ textAlign: 'right' }}
    />
  ))
}

export default function ProPlanDetailsCard({
  loading,
  currentProPlan,
  currentPlan,
  freePlan,
  totalIncome,
  totalExpenses,
  total
}) {
  return (
    <PlanSummaryCard
      title="Plan Details"
      subtitle="Tap on a row to customize or edit an item."
    >
      <DataTable
        loading={loading}
        currentProPlan={currentProPlan}
        currentPlan={currentPlan}
        freePlan={freePlan}
        totalIncome={totalIncome}
        totalExpenses={totalExpenses}
        total={total}
      />
    </PlanSummaryCard>
  )
}
