import { useLayoutEffect } from 'react'

import IntroScreen from './components/IntroScreen'

export default function CostOfLivingLocationScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  return (
    <IntroScreen
      title="Cost of Living Tool"
      subtitle="Explore the average Cost of Living essentials across different cities in the US."
      image={require('./assets/cost-of-living-intro.png')}
      buttonText="Explore Locations"
      navigation={navigation}
      navigationScreen="Cost of Living Location"
      onPress={() => {
        navigation.navigate('Modal', { screen: 'Cost of Living Location Search' })
      }}
    />
  )
}
