import { useState, useLayoutEffect, useEffect, Fragment } from 'react'
import { Image, StyleSheet, View, Text, Platform } from 'react-native'
import { useNavigationState } from '@react-navigation/native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { useDispatch, useSelector } from 'react-redux'
import HeaderLabel from './components/HeaderLabel'
import Card from './components/Card'

import formatCurrency from './functions/formatCurrency'

import { grayblue, gunmetal, primary, white } from './styles/colors'

import Form from './components/Form'
import timeout from './functions/timeout'
import BuilderWrapper from './components/plans/BuilderWrapper'
import { currentPlanSelector, fastPlanModeDataSelector } from './selectors'
import { InitialValues } from './schemas/incomeSchema'
import { saveCurrentPlan, saveFastPlan, setLoading } from './actions'
import { DEFAULT_SPACING } from './styles'
import useImageAspectRatio from './hooks/useAspectRatio'
import InputSelect from './components/InputSelect'
import LoadingIcon from './components/planBuilder/LoadingIcon'

const basicStyles = {
  container: {
    backgroundColor: 'rgb(236, 241, 244)',
    flex: 1
  },
  headerContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingTop: DEFAULT_SPACING,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgb(236, 241, 244)'
  },
  insignia: { width: 24, height: 24, marginRight: DEFAULT_SPACING },
  cardContainer: { marginHorizontal: DEFAULT_SPACING },
  descriptionContainer: {
    padding: DEFAULT_SPACING,
    backgroundColor: white
  },
  scrollviewContentContainer: { paddingVertical: DEFAULT_SPACING },
  card: {
    padding: DEFAULT_SPACING,
    borderRadius: 12,
    flex: 1,
    marginBottom: 12
  },
  cardParallel: {
    width: '48%',
    flex: 0
  },
  rowParallel: { justifyContent: 'space-between' },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  cardRow: {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  cardIcon: { marginRight: 12 },
  image: {
    marginVertical: DEFAULT_SPACING,
    width: '100%'
  },
  label: {
    fontSize: 14,
    color: 'rgb(109, 122, 125)',
    marginBottom: 6
  },
  value: {
    fontSize: 24,
    fontWeight: '600',
    color: grayblue
  },
  headerLabel: {
    fontSize: 18,
    color: 'rgb(51, 63, 75)'
  },
  locationLabel: {
    fontSize: 16,
    color: gunmetal
  },
  buttonContainer: {
    position: 'relative',
    bottom: 0,
    marginBottom: 0,
    backgroundColor: 'rgb(236, 241, 244)',
    height: 70
  },
  listHeader: { paddingVertical: DEFAULT_SPACING }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    headerContainer: {
      marginBottom: DEFAULT_SPACING,
      paddingTop: 0,
      paddingHorizontal: 0
    },
    container: { paddingRight: 40 },
    listHeader: {
      fontSize: 36,
      fontWeight: '600',
      paddingVertical: 0
    },
    cardContainer: { marginHorizontal: 0 },
    descriptionContainer: { borderRadius: 12 },
    buttonContainer: { marginHorizontal: 0 }
  }
})

const isNotWeb = Platform.OS !== 'web'

function CardRow({ label, value, Icon }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <View style={[styles.row, styles.cardRow]}>
        {Icon && <Icon style={styles.cardIcon} fill={grayblue} height={20} width={20} />}
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  )
}

export function Career({ occupation, title, builder, buttonText, navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const form = useSelector((state) => currentPlanSelector(state))
  const navigationState = useNavigationState((state) => state)
  const dispatch = useDispatch()
  const { id, description, name, insignia, seal } = occupation
  const [options, setOptions] = useState([])
  const [year, setYear] = useState('0')
  const aspectRatio = useImageAspectRatio(seal)

  const {
    birthday: birth_date,
    city,
    fastMode
  } = useSelector((state) => fastPlanModeDataSelector(state))

  const handleYearChange = (val) => {
    setYear(val)
  }

  useEffect(() => {
    const yearList = Object.keys(occupation)
      .filter((key) => {
        const parsedValue = parseInt(key, 10)
        return !Number.isNaN(parsedValue)
      })
      .filter((y) => occupation[y.toString()] > 0)
    if (yearList.length > 0) {
      setOptions(yearList)
    }
  }, [occupation])

  const handleSubmit = async () => {
    if (fastMode) {
      try {
        dispatch(
          setLoading({
            loading: true,
            fullScreen: true,
            loadingMessage: 'Building your financial plan...',
            useGradient: true,
            fullScreenOpacity: 1,
            fullScreenIconComponent: <LoadingIcon />
          })
        )

        dispatch(
          saveFastPlan({
            birth_date,
            city_id: city.id,
            occupation_id: id,
            step: 3
          })
        )

        setTimeout(() => {
          navigation.reset({
            index: 1,
            routes: [
              {
                name: 'Main',
                state: {
                  index: 0,
                  routes: [{ name: 'Plan Builder' }]
                }
              },
              {
                name: 'Modal',
                state: {
                  routes: [{ name: 'Plan Summary' }]
                }
              }
            ]
          })

          dispatch(
            setLoading({
              loading: false,
              fullScreen: false,
              loadingMessage: null,
              useGradient: null,
              fullScreenOpacity: null,
              fullScreenIconComponent: null
            })
          )
        }, 2000)
      } catch (error) {
        handleError(error, 'Oops. Looks like something went wrong.')

        dispatch(
          setLoading({
            loading: false,
            fullScreen: false,
            loadingMessage: null,
            useGradient: null,
            fullScreenOpacity: null,
            fullScreenIconComponent: null
          })
        )
      }
    } else {
      await timeout(600)

      const index = navigationState.routes.findIndex((route) => route.name == 'Plan Summary')

      if (builder) {
        navigation.navigate('Modal', {
          screen: 'Investment Options',
          params: { title, subtitle: 'Goal', builder }
        })
      } else {
        navigation.pop(navigationState.routes.length - index - 1)
      }

      dispatch(
        saveCurrentPlan({
          occupation: { id },
          experience_level: year,
          custom_career_salary: null,
          custom_career_name: null,
          input_custom_career: false,
          step: form.step === 1 ? 2 : form.step
        })
      )
    }
  }

  return (
    <>
      <View style={styles.headerContainer}>
        {insignia !== '' && <Image source={{ uri: insignia }} style={styles.insignia} />}
        <HeaderLabel style={styles.listHeader}>{name}</HeaderLabel>
      </View>
      <View style={styles.container}>
        <Form
          containerStyle={{
            backgroundColor: 'transparent',
            marginVertical: isNotWeb ? DEFAULT_SPACING : 0
          }}
          validationSchema={null}
          initialValues={InitialValues}
          withPadding={false}
          buttonText={buttonText || 'Choose this Career'}
          buttonContainerStyle={styles.buttonContainer}
          onSubmit={async (values) => handleSubmit()}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <View>
              <View style={styles.cardContainer}>
                <Card style={styles.card}>
                  <InputSelect
                    placeholder="--"
                    label="Years of Services"
                    value={year}
                    options={options.map((y) => ({ label: y, value: y }))}
                    onChangeText={(evt) => {
                      handleYearChange(evt.target.value)
                    }}
                    onSubmitEditing={(value) => {
                      handleYearChange(value)
                    }}
                  />
                  <CardRow
                    label="National Average Salary"
                    value={`$ ${formatCurrency(occupation[year])}`}
                  />
                </Card>
              </View>
              <View style={styles.descriptionContainer}>
                <HeaderLabel style={[styles.headerLabel, { marginBottom: 8 }]}>
                  Description
                </HeaderLabel>
                <Text>{description || '-'}</Text>
                {seal && <Image style={[styles.image, { aspectRatio }]} source={{ uri: seal }} />}
              </View>
            </View>
          )}
        </Form>
      </View>
    </>
  )
}

export default function MilitaryCareerScreen({ navigation, route }) {
  const { params } = route
  const { builder, useGradient, includeProgress } = params

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      title: 'Explore Careers'
    })
  }, [navigation])

  useLayoutEffect(() => {
    if (builder || useGradient) {
      navigation.setOptions({
        headerShown: true,
        headerTransparent: true,
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0
        },
        headerTitleStyle: { color: white },
        headerTintColor: white,
        title: 'Explore Careers'
      })
    } else {
      navigation.setOptions({
        headerShown: true,
        title: 'Explore Careers'
      })
    }
  }, [builder, useGradient])

  const Wrapper = useGradient || builder ? BuilderWrapper : Fragment

  return (
    <Wrapper includeProgress={includeProgress} option="Career">
      <Career {...params} navigation={navigation} />
    </Wrapper>
  )
}
