import { StyleSheet, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { white } from '../../../styles'

export const POINT_RADIUS = 16

const styles = StyleSheet.create({
  cursor: { width: POINT_RADIUS * 2, height: POINT_RADIUS * 2, shadowColor: 'black', backgroundColor: white, borderRadius: POINT_RADIUS, },
  shadowProp: {
    shadowColor: '#1C232A',
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.6,
    shadowRadius: POINT_RADIUS,
  },
  circle: {
    height: '100%',
    width: '100%',
    top: 0,
    zIndex: -1,
    opacity: 0.4,
    borderRadius: POINT_RADIUS,
    overflow: 'hidden',
    borderColor: '#CDD2D6',
    borderWidth: 1,
  }
})

function CircleButton() {
  return (
    <View style={[styles.cursor, styles.shadowProp]}>
      <LinearGradient
        style={styles.circle}
        colors={['#D9D9D9', '#ffffff']}
        start={[0, 1]}
        end={[0, 0]}
      />
    </View>
  )
}

export default CircleButton
