import { useSelector } from 'react-redux'
import { currentUserSelector } from './selectors'
import PostAuthScreen from './components/proDashboard/PostAuthScreen'
import { NAVIGATION_TYPE } from './constants'

export default function ProDashboardScreen({ navigation }) {
  const currentUser = useSelector(currentUserSelector)

  return (
    <PostAuthScreen currentUser={currentUser} navigation={navigation} />
  )
}

ProDashboardScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
