import { post } from 'http'

export async function millionaireCalculator(payload) {
  try {
    const result = await post('calculator/millionaire/', payload)

    if (result) {
      return result.data
    }
    return null
  } catch (e) {
    console.warn(e, e.response)
  }
}
