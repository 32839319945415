import { useState, useEffect, useLayoutEffect } from 'react'
import { StyleSheet, Text, TouchableOpacity, ScrollView, Image, View, Platform, useWindowDimensions } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import { get } from 'http'
import { forEach } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Container from './components/Container'
import Card from './components/Card'
import { DEFAULT_SPACING, lightestgray, secondary, white } from './styles'

import SearchInput from './components/SearchInput'
import FinglishTermSlideupDrawer, { FinglishTerm } from './components/finglishDictionary/FinglishTermSlideupDrawer'
import ToolsHeaderWrapper from './components/tools/ToolsHeaderWrapper'
import PageHeader from './components/PageHeader'

const basicStyles = {
  container: { backgroundColor: lightestgray },
  card: {
    marginTop: DEFAULT_SPACING * 16,
    marginHorizontal: DEFAULT_SPACING,
    borderRadius: 12
  },
  lineItem: {
    borderBottomWidth: 1,
    borderBottomColor: lightestgray,
    paddingVertical: DEFAULT_SPACING * 2,
    paddingHorizontal: DEFAULT_SPACING * 2
  },
  term: {
    fontSize: 18,
    fontWeight: '500',
    marginBottom: DEFAULT_SPACING / 2
  },
  searchContainer: { marginHorizontal: DEFAULT_SPACING, },
  definition: {
    fontSize: 14,
    color: secondary
  },
  image: {
    width: 150,
    height: 150,
    alignSelf: 'center',
    marginTop: -DEFAULT_SPACING * 4,
    marginBottom: DEFAULT_SPACING * 2
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: 400
  },
  emptyImage: {
    width: 200,
    height: 200,
    alignSelf: 'center'
  },
  emptyText: {
    fontSize: 18,
    fontWeight: '500',
    margin: DEFAULT_SPACING
  },
  contentContainerStyle: { paddingBottom: DEFAULT_SPACING * 32 },
  main: {},
  left: {},
  right: {},
  scrollView: {},
  closeButton: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        height: '100%',
        padding: 40,
        overflow: 'hidden',
        paddingBottom: 20,
      },
      main: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 'calc(100% - 128px)',
        overflow: 'hidden',
      },
      left: { width: '38%', },
      right: {
        marginTop: 64,
        width: '60%',
        backgroundColor: 'white',
        borderRadius: 12,
        paddingHorizontal: 30,
      },
      card: {
        marginTop: 64,
        marginHorizontal: 0,
      },
      scrollView: { height: 'calc(100vh - 470px)' },
      contentContainerStyle: {
        overflow: 'auto',
        paddingBottom: DEFAULT_SPACING * 2,
      },
      closeButton: {
        position: 'absolute',
        zIndex: 1,
        top: 8,
        right: 8
      },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

function FinglishLineItem({ term, definition, onPress }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const truncatedDefinition = definition.length > 100 ? `${definition.substring(0, 100)}...` : definition

  return (
    <TouchableOpacity onPress={onPress} style={styles.lineItem}>
      <Text style={styles.term}>{term}</Text>
      <Text style={styles.definition}>{truncatedDefinition}</Text>
    </TouchableOpacity>
  )
}

export default function FinglishDictionaryScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [terms, setTerms] = useState([])
  const [search, setSearch] = useState()
  const [visible, setVisible] = useState(false)
  const [selectedTerm, setSelectedTerm] = useState({})
  const fullWidth = useWindowDimensions().width
  const title = 'Finglish Dictionary'

  async function fetchTerms() {
    const response = await get('api/v1/financial-terms/')
    const { data } = response

    forEach(data, (term) => {
      term.sanitizedTerm = term.term.toLowerCase()
    })

    setTerms(data.sort((a, b) => a.term.localeCompare(b.term)))
  }

  useEffect(() => {
    fetchTerms()
  }, [])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb || fullWidth < 993,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0,
        height: 140
      },
      subtitle: 'Finance to English'
    })
  }, [navigation])

  const sanitizedSearch = search ? search.toLowerCase() : ''
  const filteredTerms = search ? terms.filter((term) => term.sanitizedTerm.includes(sanitizedSearch))
    : terms

  const searchSection = (
    <Card style={styles.card}>
      <Image
        style={styles.image}
        source={require('./assets/finn/book.png')}
        resizeMode="contain"
      />

      <View style={styles.searchContainer}>
        <SearchInput
          value={search}
          placeholder="Search for a term"
          onChange={setSearch}
        />
      </View>

      <ScrollView
        style={styles.scrollView}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.contentContainerStyle}
      >
        {filteredTerms.map((term, i) => (
          <FinglishLineItem
            onPress={() => {
              setSelectedTerm(term)
              setVisible(true)
            }}
            key={`${term.term}-${i}`}
            {...term}
          />
        ))}

        {filteredTerms.length === 0 && (
          <View style={styles.emptyContainer}>
            <Image
              style={styles.emptyImage}
              source={require('./assets/finn/question.png')}
              resizeMode="contain"
            />
            <Text style={styles.emptyText}>No results found</Text>
          </View>
        )}
      </ScrollView>
    </Card>
  )

  return (
    <Container style={styles.container}>

      {(isNotWeb || fullWidth < 993) ? (
        <>
          {searchSection}
          <FinglishTermSlideupDrawer
            visible={visible}
            setVisible={setVisible}
            term={selectedTerm}
            setTerm={(termToBeSet) => {
              const newTerm = terms.find((term) => term.term.trim() === termToBeSet.trim())
              setSelectedTerm(newTerm)
            }}
          />
        </>
      ) : (
        <>
          <ToolsHeaderWrapper>
            <PageHeader
              title={title}
              subTitle="Finance to English"
            />
          </ToolsHeaderWrapper>
          <View style={styles.main}>
            <View style={styles.left}>
              {searchSection}
            </View>
            {visible && (
              <View style={styles.right}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => setVisible(false)}
                >
                  <FontAwesomeIcon icon={['far-solid', 'circle-xmark']} color="#66666652" size={24} />
                </TouchableOpacity>
                <FinglishTerm
                  setVisible={setVisible}
                  term={selectedTerm}
                  setTerm={(termToBeSet) => {
                    const newTerm = terms.find((term) => term.term.trim() === termToBeSet.trim())
                    setSelectedTerm(newTerm)
                  }}
                />
              </View>
            )}
          </View>
        </>
      )}
    </Container>
  )
}
