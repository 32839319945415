import { Platform, Text, View, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { map, orderBy } from 'lodash'
import Occupation from './Occupation'
import OccupationGroupCard from './OccupationGroupCard'

import { white } from '../../styles/colors'

const basicStyles = {
  occupationName: {
    fontSize: 18,
    color: 'rgb(48, 64, 77)',
    fontWeight: '600',
    marginBottom: 15
  },
  occupationRow: {
    backgroundColor: white,
    borderRadius: 10
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    occupationRow: { paddingRight: 40 },
    occupationName: {
      fontSize: 36,
      fontWeight: '600'
    }
  }
})

export default function Occupations({
  name,
  image,
  careers,
  onOccupationPress
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const careersList = orderBy(careers, ['name'])

  return (
    <>
      <Text style={styles.occupationName}>{name}</Text>
      {image && <OccupationGroupCard image={image} />}

      <View style={styles.occupationRow}>
        {map(careersList, (career, index) => (
          <Occupation
            key={career.id}
            index={index}
            onOccupationPress={onOccupationPress}
            careerCount={career.length}
            {...career}
          />
        ))}
      </View>
    </>
  )
}
