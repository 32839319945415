import { put } from 'http'
import { map } from 'lodash'
import handleError from '../functions/handleError'
import { currentUserSelector, proPlansSelector } from '../selectors'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { setProPlans } from './setProPlans'

const UNWANTED_KEYS = ['userId', 'createdAt', 'updatedAt']

export function sanitizePayload(cashSweepLogic) {
  const sanitizedPayload = { ...cashSweepLogic }

  for (const key in UNWANTED_KEYS) {
    if (sanitizePayload[key]) {
      delete sanitizePayload[key]
    }
  }

  return sanitizedPayload
}

// eslint-disable-next-line import/prefer-default-export
export const saveCashSweepLogic = (cashSweepLogic) => async (dispatch, getState) => {
  const currentState = getState()
  const { id: userId } = currentUserSelector(currentState)
  const currentProPlans = proPlansSelector(currentState)

  try {
    // Update the plan
    // The update API doesn't return anything but the acknowledgement
    await put(
      proApplicationEndpoints.updateCashSweepLogic(userId),
      sanitizePayload(cashSweepLogic)
    )

    // Cash sweep logic is currently housed in the plan object
    // TODO: We should have a seperate reducer for cash sweep logic
    const updatedProPlans = map(currentProPlans, (proPlan) => ({
      ...proPlan,
      cashSweepLogic: {
        ...proPlan.cashSweepLogic,
        ...cashSweepLogic
      },
    }))

    dispatch(setProPlans(updatedProPlans))
  } catch (error) {
    console.error(error, error.response, error.response.body)
    handleError(error, 'Oops. Looks like something went wrong.')
  }
}
