import { useState, memo, useMemo } from 'react'
import { isEmpty, find } from 'lodash'
import { View, FlatList, Text, TextInput, ScrollView } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import ToggleMultiSelectDropdown from './ToggleMultiSelectDropdown'
import { primary } from '../styles/colors'
import TouchableOpacity from './TouchableOpacity'

const hitSlop = { top: 14, bottom: 14, left: 14, right: 14 }

const kOptionsHeight = { width: '100%', maxHeight: 180 }

const kOptionListViewStyle = {
  width: '100%',
  alignItems: 'center',
  paddingVertical: 4,
}

const renderItemStyle = {
  flex: 1,
  paddingLeft: 6
}

function SelectBox({
  labelStyle,
  containerStyle,
  inputFilterContainerStyle,
  inputFilterStyle,
  optionsLabelStyle,
  optionContainerStyle,
  multiOptionContainerStyle,
  multiOptionsLabelStyle,
  multiListEmptyLabelStyle,
  listEmptyLabelStyle,
  selectedItemStyle,
  listEmptyText = 'No results found',
  ...props
}) {
  const [inputValue, setInputValue] = useState('')

  const [showOptions, setShowOptions] = useState(false)

  function renderLabel(item) {
    const kOptionsLabelStyle = {
      fontSize: 16,
      color: 'rgba(60, 60, 67, 0.6)',
      ...optionsLabelStyle,
    }
    return <Text style={kOptionsLabelStyle}>{item}</Text>
  }

  function renderItem({ item }) {
    const { isMulti, onChange, onMultiSelect, selectedValues } = props
    const kOptionContainerStyle = {
      borderColor: '#dadada',
      borderBottomWidth: 1,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      background: '#fff',
      paddingVertical: 12,
      paddingRight: 10,
      justifyContent: 'space-between',
      ...optionContainerStyle,
    }
    return (
      <View style={kOptionContainerStyle}>
        <>
          <ToggleMultiSelectDropdown
            iconColor={toggleIconColor}
            checked={selectedValues.some((i) => item.id === i.id)}
            onTouch={onPressMultiItem()}
          />
          <TouchableOpacity hitSlop={hitSlop} style={renderItemStyle} onPress={onPressMultiItem()}>
            {renderLabel(item.item)}
          </TouchableOpacity>

        </>

      </View>
    )

    function onPressMultiItem() {
      return (e) => (onMultiSelect ? onMultiSelect(item) : null)
    }

    function onPressItem() {
      return (e) => {
        setShowOptions(false)
        return onChange ? onChange(item) : null
      }
    }
  }

  function renderGroupItem({ item }) {
    const { onTapClose, options } = props
    const label = find(options, (o) => o.id === item.id)
    const kMultiOptionContainerStyle = {
      flexDirection: 'row',
      borderRadius: 20,
      paddingVertical: 5,
      paddingRight: 5,
      paddingLeft: 10,
      marginRight: 4,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: primary,
      flexGrow: 1,
      ...multiOptionContainerStyle,
    }
    const kMultiOptionsLabelStyle = {
      fontSize: 15,
      color: '#fff',
      ...multiOptionsLabelStyle,
    }
    return (
      <View style={kMultiOptionContainerStyle}>
        <Text style={kMultiOptionsLabelStyle}>{label.item}</Text>
        <TouchableOpacity style={{ marginLeft: 15 }} hitSlop={hitSlop} onPress={onPressItem()}>
          <FontAwesomeIcon icon="xmark" fill="#285238" size={21} />
        </TouchableOpacity>
      </View>
    )

    function onPressItem() {
      return (e) => (onTapClose ? onTapClose(item) : null)
    }
  }
  const {
    selectIcon,
    label,
    inputPlaceholder = 'Select ...',
    hideInputFilter,
    width = '100%',
    isMulti,
    options,
    value,
    selectedValues,
    arrowIconColor = primary,
    searchIconColor = primary,
    toggleIconColor = primary,
    searchInputProps,
    multiSelectInputFieldProps,
    listOptionProps = {},
  } = props
  const filteredSuggestions = useMemo(
    () => options.filter((suggestion) => suggestion.item.toLowerCase().indexOf(inputValue.toLowerCase()) > -1),
    [inputValue, options]
  )

  function multiListEmptyComponent() {
    const kMultiListEmptyLabelStyle = {
      fontSize: 18,
      marginTop: 2 * 2,
      fontWeight: '500',
      color: 'rgb(133, 151, 165)',
      ...multiListEmptyLabelStyle,
    }
    return (
      <TouchableOpacity
        width="100%"
        style={{ flexGrow: 1, width: '100%' }}
        hitSlop={hitSlop}
        onPress={onPressShowOptions()}
      >
        <Text style={kMultiListEmptyLabelStyle}>{inputPlaceholder}</Text>
      </TouchableOpacity>
    )
  }

  function optionListEmpty() {
    const kListEmptyLabelStyle = {
      fontSize: 17,
      color: 'rgba(60, 60, 67, 0.6)',
      ...listEmptyLabelStyle,
    }
    return (
      <View style={kOptionListViewStyle}>
        <Text style={kListEmptyLabelStyle}>{listEmptyText}</Text>
      </View>
    )
  }
  const kLabelStyle = {
    color: 'rgb(66, 86, 103)',
    fontSize: 12,
    paddingBottom: 4,
    ...labelStyle,
  }

  const kContainerStyle = {
    flexDirection: 'column',
    backgroundColor: 'rgba(242, 248, 250, 0.9)',
    padding: 2 * 5,
    borderRadius: 2 * 3,
    ...containerStyle
  }

  const kListContainerStyle = {
    paddingRight: 20,
    flexGrow: 1,
  }

  return (
    <View style={{ width }}>
      <View style={kContainerStyle}>
        <Text style={kLabelStyle}>{label}</Text>
        <View style={{ flexDirection: 'row' }}>
          <View style={kListContainerStyle}>
            <FlatList
              data={selectedValues}
              extraData={{ inputValue, showOptions }}
              keyExtractor={keyExtractor()}
              renderItem={renderGroupItem}
              horizontal
              ListEmptyComponent={multiListEmptyComponent}
              {...multiSelectInputFieldProps}
            />

          </View>
          <TouchableOpacity onPress={onPressShowOptions()} hitSlop={hitSlop}>
            {selectIcon || <FontAwesomeIcon icon={showOptions ? 'caret-down' : 'caret-up'} fill={arrowIconColor} />}
          </TouchableOpacity>
        </View>
      </View>
      {/* Options wrapper */}
      {showOptions && (
        <FlatList
          data={filteredSuggestions || options}
          extraData={options}
          keyExtractor={keyExtractor()}
          renderItem={renderItem}
          numColumns={1}
          horizontal={false}
          initialNumToRender={5}
          maxToRenderPerBatch={20}
          windowSize={10}
          ListEmptyComponent={optionListEmpty}
          style={[kOptionsHeight, listOptionProps.style]}
          ListHeaderComponent={HeaderComponent()}
          {...listOptionProps}
        />
      )}
    </View>
  )

  function keyExtractor() {
    return (o) => `${o.id}-${Math.random()}`
  }

  function kSelectedItemStyle() {
    return {
      fontSize: 17,
      color: isEmpty(value.item) ? 'rgba(60, 60, 67, 0.3)' : '#000',
      ...selectedItemStyle,
    }
  }

  function HeaderComponent() {
    const kInputFilterContainerStyle = {
      width: '100%',
      borderBottomWidth: 1,
      borderBottomColor: '#ddd',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...inputFilterContainerStyle,
    }
    const kInputFilterStyle = {
      paddingVertical: 14,
      paddingRight: 8,
      color: '#000',
      fontSize: 12,
      flexGrow: 1,
      ...inputFilterStyle,
    }
    return (
      <>
        {!hideInputFilter && (
          <View style={kInputFilterContainerStyle}>
            <TextInput
              autoFocus
              value={inputValue}
              placeholder={inputPlaceholder}
              onChangeText={onChangeText()}
              style={kInputFilterStyle}
              placeholderTextColor="#000"
              {...searchInputProps}
            />
            <FontAwesomeIcon icon="magnifying-glass" fill={searchIconColor} />
          </View>
        )}
        <ScrollView keyboardShouldPersistTaps="always" />
      </>
    )

    function onChangeText() {
      return (value) => setInputValue(value)
    }
  }

  function onPressShowOptions() {
    return () => setShowOptions(!showOptions)
  }
}

export default memo(SelectBox)
