import { View, Text, StyleSheet } from 'react-native'
import HeaderLabel from '../HeaderLabel'
import ProBudgetItem from '../ProBudgetItem'
import { DEFAULT_SPACING, primary } from 'styles'
import formatCurrency from 'functions/formatCurrency'
import BudgetBreakdownForMonth from 'components/budgeting/BudgetBreakdownForMonth'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: DEFAULT_SPACING
  },
  onTrackContainer: {
    paddingVertical: DEFAULT_SPACING * 2,
    paddingHorizontal: DEFAULT_SPACING
  }
})

export default function MonthCongradulations({
  remainingAmount,
  actual,
  totalExpenses,
  header,
  isIncome,
  isSavings
}) {
  return (
    <>
      <View style={styles.onTrackContainer}>
        <HeaderLabel style={{ marginBottom: DEFAULT_SPACING }}>
          {header}
        </HeaderLabel>

        {isIncome || isSavings ? (
          <Text>
            You have{' '}
            <Text style={{ fontWeight: '600', color: primary }}>
              {isIncome ? 'earned' : 'saved'}{' '}
              {formatCurrency(actual, { showDollarSign: true })}
            </Text>{' '}
            this month.
          </Text>
        ) : remainingAmount > 0 ? (
          <Text>
            You are <Text style={{ fontWeight: '600' }}>on track</Text> for this
            month. Great Work!
          </Text>
        ) : (
          <Text>
            You are <Text style={{ fontWeight: '600' }}>over budget</Text> for
            this month. Consider adjusting your budget.
          </Text>
        )}

        <ProBudgetItem
          onPress={false}
          title={null}
          budgetAmount={totalExpenses}
          actualAmount={actual}
          isIncome={isIncome || isSavings}
        />

        <BudgetBreakdownForMonth
          budgetAmount={totalExpenses}
          actualAmount={actual}
          withoutMargin={true}
          withoutPadding={true}
          isIncome={isIncome || isSavings}
        />
      </View>
    </>
  )
}
