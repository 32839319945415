import { useEffect, useState } from 'react'
import { Image, Text, StyleSheet, Platform, Linking } from 'react-native'

import { get } from 'http'
import { useSelector } from 'react-redux'
import { getVersion } from 'react-native-device-info'
import { darkblue, DEFAULT_SPACING, gunmetal } from './styles'
import Container from './components/Container'
import Button from './components/Button'

import { currentUserSelector } from './selectors'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    color: darkblue,
    fontSize: 24,
    fontWeight: '600',
    marginBottom: DEFAULT_SPACING
  },
  text: {
    color: gunmetal,
    width: '80%',
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING * 2
  },
  button: { marginHorizontal: DEFAULT_SPACING },
  image: {
    width: 300,
    height: 400,
    marginBottom: DEFAULT_SPACING
  }
})

export default function OfflineScreen({ children }) {
  const currentUser = useSelector((state) => currentUserSelector(state))
  const [latestVersion, setLatestVersion] = useState({})

  async function fetchLatestVersion() {
    try {
      const response = await get('api/v1/app-version/latest/')
      return setLatestVersion(response.data)
    } catch (e) {
      console.warn(e, e.response)
    }
  }

  useEffect(() => {
    if (currentUser && currentUser.id) fetchLatestVersion()
  }, [currentUser])

  function onPress() {
    if (Platform.OS === 'ios') {
      Linking.openURL('https://apps.apple.com/us/app/troutwood/id1530619297')
    } else if (Platform.OS === 'android') {
      Linking.openURL(
        'https://play.google.com/store/apps/details?id=com.troutwood.sfp&hl=en_US&gl=US'
      )
    }
  }

  const version =
    Platform.OS == 'ios'
      ? latestVersion.apple_version
      : latestVersion.android_version
  const forceUserToUpdate =
    !latestVersion.force_update && getVersion() !== version

  return forceUserToUpdate ? (
    children
  ) : (
    <Container style={styles.container}>
      <Image
        source={require('./assets/finn/update.png')}
        style={styles.image}
        resizeMode="contain"
      />
      <Text style={styles.header}>Uh Oh!</Text>
      <Text style={styles.text}>
        It looks like you need to update the app. Try download the latest
        update!
      </Text>
      <Button style={styles.button} onPress={onPress}>
        Download from Store
      </Button>
    </Container>
  )
}
