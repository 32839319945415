import { StyleSheet, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { DEFAULT_SPACING, lightgray, lightestgray } from 'styles'
import SlideupDrawer from 'components/SlideupDrawer'
import TouchableOpacity from 'components/TouchableOpacity'

const styles = StyleSheet.create({
  addNewItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING * 1.6,
    borderBottomWidth: 1,
    borderBottomColor: lightestgray,
    marginHorizontal: DEFAULT_SPACING
  },
  addItemNewText: {
    fontSize: 16,
    fontWeight: '500'
  }
})

export default function AddBudgetItemSlideupDrawer({
  subCategories,
  visible,
  setVisible,
  addNewItem
}) {
  return (
    <SlideupDrawer
      snapPointIndex={subCategories.length > 4 ? 3 : 1}
      scrollable
      title="Add New Item"
      visible={visible}
      setVisible={setVisible}
      closeEnabled
    >
      {subCategories &&
        subCategories.map((subcategory) => (
          <TouchableOpacity
            style={styles.addNewItemContainer}
            onPress={() => {
              setVisible(false)
              addNewItem(subcategory)
            }}
          >
            <Text style={styles.addItemNewText}>{subcategory.label}</Text>
            <FontAwesomeIcon
              icon="circle-chevron-right"
              size={20}
              color={lightgray}
            />
          </TouchableOpacity>
        ))}
    </SlideupDrawer>
  )
}
