import { del } from 'http';
import proApplicationEndpoints from '../api/proApplicationEndpoints';

export const DELETE_TRANSACTION = 'DELETE_TRANSACTION'

// eslint-disable-next-line import/prefer-default-export
export const deleteTransaction = (userId, transactionId) => async (dispatch) => {
  try {
    await del(
      proApplicationEndpoints.deleteTransaction(userId, transactionId)
    )

    dispatch({
      type: DELETE_TRANSACTION,
      payload: { transactionId }
    })
  } catch (e) {
    console.warn(e, e.response)
  }
}
