import { useEffect } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import RadioGroup from './RadioGroup'
import SliderOptionGroup from './SliderOptionGroup'
import YesNoOptionGroup from './YesNoOptionGroup'
import InputOption from './InputOption'
import { DEFAULT_SPACING, success } from '../../../styles'
import Button from '../../Button'

const UI_TYPE = {
  RADIO: 'radio',
  SLIDER: 'slider',
  YES_NO: 'yes/no',
  INPUT: 'input'
}

const styles = StyleSheet.create({
  title: {
    fontSize: 22,
    fontWeight: '600',
    textAlign: 'center',
    marginVertical: 38
  },
  container: {
    width: '100%',
    height: '100%'
  },

  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: DEFAULT_SPACING,
    paddingBottom: DEFAULT_SPACING * 2
  },
  nextBtn: { paddingHorizontal: DEFAULT_SPACING * 5, marginLeft: 'auto' }
})

function QuizCard({ data, value, onPress, step, goPrev, goNext }) {
  useEffect(() => {
    if (data.ui_type === UI_TYPE.SLIDER && !value) onPress(1)
  }, [data])

  return (
    <>
      <ScrollView style={styles.container}>
        <Text style={styles.title}>{data.question}</Text>
        {data.ui_type === UI_TYPE.RADIO && (
          <RadioGroup
            options={data.options.map((text, index) => ({
              label: text,
              value: (index * 4) / (data.options.length - 1) + 1
            }))}
            value={value}
            onPress={onPress}
          />
        )}
        {data.ui_type === UI_TYPE.SLIDER && (
          <SliderOptionGroup
            options={data.options.map((text, index) => ({
              label: text,
              value: (index * 4) / (data.options.length - 1) + 1
            }))}
            value={value}
            onChange={onPress}
          />
        )}
        {data.ui_type === UI_TYPE.YES_NO && (
          <YesNoOptionGroup
            options={data.options.map((text, index) => ({
              label: text,
              value: (index * 4) / (data.options.length - 1) + 1
            }))}
            value={value}
            onPress={onPress}
          />
        )}
        {data.ui_type === UI_TYPE.INPUT && (
          <InputOption
            max={data.no_of_options}
            value={value}
            onChangeText={onPress}
          />
        )}
      </ScrollView>

      <View style={styles.buttonGroup}>
        {step > 0 && (
          <TouchableOpacity onPress={goPrev}>
            <View>
              <FontAwesomeIcon size={26} icon={['fas', 'arrow-left']} />
            </View>
          </TouchableOpacity>
        )}
        <Button
          disabled={!value}
          color={success}
          style={styles.nextBtn}
          onPress={goNext}
        >
          Next
        </Button>
      </View>
    </>
  )
}

export default QuizCard
