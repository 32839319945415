import { map, compact } from 'lodash'

import pluralize from 'pluralize'
import InvestmentCard from './InvestmentCard'

import computeAge from '../functions/computeAge'
import formatCurrency from '../functions/formatCurrency'
import formatPercent from '../functions/formatPercent'
import occupationName from '../functions/occupationName'
import investmentType from '../functions/investmentType'

export default function InvestmentCards({ plan, dispatch, navigation, form }) {
  const salary = plan.gross_income && plan.gross_income.annual || 0
  const cards = [{
    title: 'Plan Builder',
    subtitle: 'Profile',
    label: 'Profile',
    icon: 'circle-user',
    type: 'fas',
    route: 'Edit Profile',
    visible: true,
    key: 'profile',
    rowGroups: compact([
      {
        label: null,
        rows: [
          {
            label: 'Age',
            value: plan.birth_date ? computeAge(plan.birth_date) : '—',
            key: 'age'
          }, {
            label: 'Years to Save',
            value: plan.periods || '—',
            key: 'years-to-save'
          }, {
            label: 'City, State',
            value: plan.city ? plan.city.name : '—',
            key: 'city-state'
          }
        ]
      },
      plan.have_loans ? {
        label: 'Student Loans',
        rows: [
          {
            label: 'Amount',
            value: plan.loan_balance ? `$${formatCurrency(plan.loan_balance)}` : '—',
            key: 'loan-amount'
          }, {
            label: 'Interest Rate',
            value: plan.loan_rate ? formatPercent(parseFloat(plan.loan_rate)) : '—',
            key: 'loan-interest-rate'
          }, {
            label: 'Term',
            value: plan.loan_period > 0 ? `${plan.loan_period} ${pluralize('Periods', plan.loan_period)}` : '—',
            key: 'loan-term'
          }
        ]
      } : null
    ])
  }, {
    title: 'Plan Builder',
    subtitle: 'Explore Careers',
    label: 'Career',
    icon: 'briefcase',
    type: 'fas',
    route: 'Custom Career',
    visible: plan.step >= 1,
    key: 'career-builder',
    rowGroups: [
      {
        label: null,
        rows: [
          {
            label: 'Career',
            value: occupationName(plan),
            key: 'career'
          },
          {
            label: 'Salary',
            value: salary > 0 ? `$${formatCurrency(salary)}` : '—',
            key: 'salary'
          }
        ]
      }
    ]
  }, {
    title: 'Plan Builder',
    subtitle: 'Goal',
    label: 'Goal',
    icon: 'piggy-bank',
    type: 'fas',
    route: 'Investment Options',
    key: 'investment-builder',
    visible: plan.step >= 2,
    rowGroups: [
      {
        label: null,
        rows: [
          {
            label: 'Investor Profile',
            value: investmentType(plan),
            key: 'investor'
          },
          {
            label: 'Goal Amount',
            value: plan.savings_goal != null ? `$${formatCurrency(plan.savings_goal)}` : '-',
            key: 'goal'
          }
        ]
      }
    ]
  }]

  return map(cards, (card) => <InvestmentCard id={plan.id} dispatch={dispatch} navigation={navigation} {...card} />)
}
