import { useState } from 'react'
import { FlatList, Text, View, StyleSheet, TouchableOpacity, Dimensions, useWindowDimensions } from 'react-native'
import PropTypes from 'prop-types'
import { ListItem } from 'react-native-elements'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { darkblue, primary, red, lightgray, DEFAULT_SPACING, lightestgray, secondary } from '../styles'
import formatCurrency from '../functions/formatCurrency'
import CircleIcon from './CircleIcon'
import { INCOME_TYPE } from '../constants/budgetItemConstants'
import SlideupDrawer from './SlideupDrawer'
import Link from './Link'

const styles = StyleSheet.create({
  listContainerStyle: (compressed, disableBottomBorder) => {
    const style = {
      borderRadius: 12,
      flexDirection: 'row',
      flex: 1,
      alignItems: 'center',
      borderBottomColor: lightestgray,
      borderBottomWidth: 1
    }

    if (compressed) {
      style.borderBottomWidth = StyleSheet.hairlineWidth
      style.borderBottomColor = lightestgray
    } else {
      style.marginBottom = DEFAULT_SPACING
    }

    if(disableBottomBorder) {
      style.borderBottomWidth = 0
    }

    return style
  },
  circleIconStyle: { marginRight: DEFAULT_SPACING / 4 },
  detailsContainer: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  itemName: { fontWeight: '600', marginBottom: 6 },
  itemDescription: { fontSize: 14, color: secondary, marginBottom: 3 },
  itemTotal: { flexDirection: 'row' },
  totalAmount: (color) => ({ color, fontWeight: '500' })
})

export default function AccordionList({ list, onEdit, onDelete, label, hideIcon, compressed, disableBottomBorder=false }) {
  const window = useWindowDimensions()
  const marginTop = window.height - 400
  const [selected, setSelected] = useState()

  const showOptions = () => (!!selected)

  const onOptionsPress = (item) => () => {
    setSelected(item)
  }

  const clearSelected = () => setSelected(null)

  const onEditPress = () => {
    onEdit(selected)
    clearSelected()
    setSelected(false)
  }

  const onDeletePress = () => {
    onDelete(selected)
    clearSelected()
    setSelected(false)
  }

  const renderItem = ({ item }) => {

    const color = item.budgetCategory === INCOME_TYPE ? primary : red
    const { displayAmountColor } = item

    let description = item.description
    description = description && description.length > 24 ? `${description.substring(0, 24)}...` : description

    let name = item.name 
    name = name && name.length > 24 ? `${name.substring(0, 24)}...` : name

    return (
      <ListItem containerStyle={styles.listContainerStyle(compressed, disableBottomBorder)}>
        {
          !hideIcon && (
            <CircleIcon
              size={20}
              icon={item.iconProps.icon}
              color={item.iconProps.color}
              backgroundColor={item.iconProps.backgroundColor}
              style={styles.circleIconStyle}
            />
          )
        }

        <View style={styles.detailsContainer}>
          <View>
            <Text testID="accountName" style={styles.itemName}>
              {name}
            </Text>
            <Text style={styles.itemDescription}>
              {description}
            </Text>
          </View>
          <View style={styles.itemTotal}>
            <Text testID="accountValue" style={{ color: displayAmountColor ? color : null }}>
              {formatCurrency(item.amount, { accurate: true, showDollarSign: true })}
            </Text>
          </View>
        </View>

        {
          (onEdit || onDelete) && (
            <TouchableOpacity onPress={onOptionsPress(item)}>
              <FontAwesomeIcon testID="additionalOptionsIcon" icon={['far', 'ellipsis']} size={20} color={lightgray} />
            </TouchableOpacity>
          )
        }
      </ListItem>
    )
  }

  return (
    <View>
      <FlatList
        keyExtractor={(_, index) => index.toString()}
        data={list}
        renderItem={renderItem}
      />
      {
        (onEdit || onDelete) && (
          <SlideupDrawer
            title={selected && selected.name}
            visible={showOptions()}
            setVisible={clearSelected}
            marginTop={marginTop}
          >
            <View style={{ alignItems: 'flex-start', marginHorizontal: DEFAULT_SPACING }}>
              {
                onEdit && (
                  <Link
                    iconStyle={{ color: primary, marginVertical: DEFAULT_SPACING }}
                    style={{ color: darkblue, fontSize: 18, fontWeight: '500' }}
                    icon="pencil"
                    type="fas"
                    onPress={onEditPress}
                  >
                    Edit
                    {' '}
                    {label}
                  </Link>
                )
              }
              {
                onDelete && (
                  <Link
                    iconStyle={{ color: red, marginVertical: DEFAULT_SPACING }}
                    style={{ color: darkblue, fontSize: 18, fontWeight: '500' }}
                    icon="trash"
                    type="fas"
                    onPress={onDeletePress}
                  >
                    Delete
                    {' '}
                    {label}
                  </Link>
                )
              }
            </View>
          </SlideupDrawer>
        )
      }
    </View>
  )
}

AccordionList.defaultProps = {
  hideIcon: false,
  compressed: false,
  onEdit: undefined,
  onDelete: undefined,
  label: undefined
}

AccordionList.propTypes = {
  list: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  hideIcon: PropTypes.bool,
  compressed: PropTypes.bool,
  label: PropTypes.string
}
