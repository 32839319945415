import { useLayoutEffect } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { FlatList } from 'react-native-gesture-handler'
import { budgetCategoriesSelector } from './selectors'
import { DEFAULT_SPACING, gunmetal, orange, secondary, white } from './styles'
import formatCurrency from './functions/formatCurrency'
import { ListItem } from 'react-native-elements'

const styles = StyleSheet.create({
  noProjectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: orange,
    padding: DEFAULT_SPACING
  },
  noProjectionsText: {
    color: white,
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: DEFAULT_SPACING
  },
  budgetItemHeader: {
    fontSize: 14,
    fontWeight: '600'
  },
  targetBudget: {
    color: gunmetal,
    fontSize: 10,
    marginBottom: DEFAULT_SPACING * 2,
    marginRight: 30
  },
  thisMonth: {
    color: secondary,
    fontSize: 10
  }
})

export default function BudgetSubCategoryTransactionsScreen({ navigation, route }) {
  const { params } = route
  const { budgetSubCategoryKey, transactions } = params


  const renderBudgetSubCategoryData = ({ item: transaction }) => {
    return (
      <ListItem containerStyle={styles.listContainerStyle}>
        <View style={styles.detailsContainer}>
          <View>
            <Text testID="accountName" style={styles.itemName}>
              {transaction.name}
            </Text>
            <Text style={styles.itemDescription}>
              {transaction.description}
            </Text>
          </View>
          <View style={styles.itemTotal}>
            <Text testID="accountValue" style={{ color: displayAmountColor ? color : null }}>
              {formatCurrency(item.amount, { accurate: true, showDollarSign: true })}
            </Text>
          </View>
        </View>
      </ListItem>
    )
  }

  const budgetCategories = useSelector(budgetCategoriesSelector)
  const budgetCategory = budgetCategories.map((category) => category.subCategories).flat()
                                         .find((subCategory) => subCategory.key == budgetSubCategoryKey)


  useLayoutEffect(() => {
    navigation.setOptions({ headerTitle: budgetSubCategory.label, })
  }, [navigation])


  return (
    <View style={styles.container}>
      <FlatList
        data={transactions}
        renderItem={renderBudgetSubCategoryData}
      />
    </View>
  )
}

BudgetSubCategoryTransactionsScreen.propTypes = {}
