import { Text, View } from 'react-native'
import { CreateResponsiveStyle } from 'rn-responsive-styles'
import { useNavigation, useNavigationState } from "@react-navigation/native"
import { DEFAULT_SPACING, secondary } from '../styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const useStyles = CreateResponsiveStyle({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  navLink: {
    marginLeft: 4,
    fontSize: 16,
    fontWeight: '600',
    color: secondary,
    paddingBottom: DEFAULT_SPACING,
  },
  navLinkActive: {
    fontSize: 16,
    fontWeight: '600',
    color: '#4F8FEF',
    paddingBottom: DEFAULT_SPACING,
  },
  divider: {
    paddingHorizontal: 12,
    color: secondary,
    paddingBottom: DEFAULT_SPACING,
    opacity: 0.4
  },
})

function Breadcrumb({ index, label, mode, }) {
  const styles = useStyles()
  const navigation = useNavigation()

  const handlePress = () => {
    if (index === -1) navigation.navigate('My Dashboard')
    else
      for (let i = 0; i < index; i++) {
        navigation.goBack()
      }
  }

  return (
    <>
      {index !== 0 ? (
        <Text
          style={[styles.navLinkActive, mode === 'dark' && { color: '#fff' }]}
          onPress={handlePress}
        >
          {label}
        </Text>
      ) : (
        <Text style={[styles.navLink, mode === 'dark' && { color: '#fff8' }]}>{label}</Text>
      )}
    </>
  )
}

function Breadcrumbs({ mode = 'light' }) {
  const styles = useStyles()
  const navigationState = useNavigationState((state) => state);

  return (
    <View style={styles.wrapper}>
      {navigationState.routes.map((nav, index) => (
        <View key={index} style={{ flexDirection: 'row' }}>
          {index === 0 && (<>
            <Breadcrumb index={- 1} label={'My Dashboard'} mode={mode} />
            <Text style={[styles.divider, mode === 'dark' && { color: '#fff4' }]}>/</Text>
          </>)}
          <Breadcrumb index={navigationState.routes.length - index - 1} label={nav.name} mode={mode} />

          {index !== navigationState.routes.length - 1 && (
            <Text style={[styles.divider, mode === 'dark' && { color: '#fff4' }]}>/</Text>
          )}
        </View>
      ))}
    </View>
  )
}

export default Breadcrumbs
