import AsyncStorage from '@react-native-async-storage/async-storage'

/*
 * Download gzipped content from a URI and store it as a base64-encoded string in AsyncStorage.
 */
export default async function downloadProjections(planFilePath, uri) {
  try {
    const response = await fetch(uri)
    const blob = await response.blob()

    // Convert the Blob to a base64-encoded string
    const base64Data = await blobToBase64(blob)

    // Store the base64-encoded string in AsyncStorage
    await AsyncStorage.setItem(planFilePath, base64Data)

    return planFilePath
  } catch (error) {
    console.error('Error downloading or storing data', error)
  }
}

// Helper function to convert Blob to base64-encoded string
async function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64data = reader.result.split(',')[1]
      resolve(base64data)
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(blob)
  })
}
