import { View, Text, StyleSheet, Image, Platform } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { darkblue, info } from '../../styles/colors'
import Link from '../Link'
import FadeInView from '../FadeInView'
import Button from '../Button'
import Card from '../Card'

import ExploreCard from './ExploreCard'

import { DEFAULT_SPACING } from '../../styles'

const basicStyles = {
  container: {
    marginTop: 40,
    marginHorizontal: DEFAULT_SPACING,
    marginBottom: 30,
    paddingTop: 310
  },
  cardContainer: {
    marginBottom: DEFAULT_SPACING,
    marginTop: DEFAULT_SPACING,
    borderRadius: 10,
    paddingHorizontal: DEFAULT_SPACING,
    paddingTop: DEFAULT_SPACING * 4,
    width: '100%',
    alignItems: 'center'
  },
  cardImage: {
    height: 200,
    width: 250,
    marginTop: -10
  },
  cardTitle: {
    textAlign: 'center',
    fontWeight: '600',
    marginTop: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING * 2,
    fontSize: 28,
    letterSpacing: -0.37,
    color: darkblue
  },
  scrollviewContainer: {
    paddingVertical: DEFAULT_SPACING,
    backgroundColor: 'rgb(235, 241, 244)',
    marginHorizontal: 10
  },
  lineItemContainer: {
    alignItems: 'flex-start',
    width: '60%',
    alignSelf: 'center'
  },
  createBtn: {},
  buttonTitle: { fontSize: 18 },
  loginLink: {
    alignSelf: 'center',
    marginBottom: DEFAULT_SPACING,
    fontWeight: '500',
    fontSize: 14,
    marginLeft: DEFAULT_SPACING
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.SM)]: {
    container: {
      marginTop: -56,
      paddingTop: 0
    },
    cardContainer: { marginTop: DEFAULT_SPACING * 2 },
    createBtn: {
      marginTop: 32,
      marginHorizontal: 'auto',
      width: 310
    }
  },
  [minSize(DEVICE_SIZES.MD)]: { cardContainer: { width: '49%' } },
  [minSize(DEVICE_SIZES.LG)]: { container: { marginHorizontal: DEFAULT_SPACING * 2 } }
})

function LineItem({ title }) {
  return (
    <View style={{ flexDirection: 'row', marginBottom: DEFAULT_SPACING }}>
      <View style={{ marginRight: 12 }}>
        <FontAwesomeIcon icon={['far', 'circle-check']} color={info} size={16} />
      </View>

      <Text
        style={{
          fontWeight: '600',
          fontSize: 16,
          color: darkblue,
          letterSpacing: 0
        }}
      >
        {title}
      </Text>
    </View>
  )
}

const lineItems = [
  { title: 'Live Your Plan' },
  { title: 'Track Your Progress' },
  { title: 'Independent and Conflict Free' }
]

export default function PreAuthScreen({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const image = require('../../assets/tools-journey.png')

  return (
    <FadeInView style={styles.container} initialValue={0} toValue={1} delay={200} duration={300}>
      <ExploreCard navigation={navigation} />

      <Card style={styles.cardContainer}>
        <Image style={styles.cardImage} resizeMode="contain" source={image} />
        <View>
          <Text style={styles.cardTitle}>
            Get Started On Your {'\n'}
            Financial Journey!
          </Text>
        </View>
        <View style={styles.lineItemContainer}>
          {map(lineItems, (lineItem) => (
            <LineItem {...lineItem} />
          ))}
        </View>

        <Button
          style={styles.createBtn}
          titleStyle={styles.buttonTitle}
          onPress={() => navigation.navigate('Modal', { screen: 'Pre Auth Registration' })}
        >
          Create your FREE Account
        </Button>

        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'center',
            marginVertical: DEFAULT_SPACING
          }}
        >
          <Text>Already have an account?</Text>
          <Link
            style={styles.loginLink}
            onPress={() => navigation.navigate('Modal', { screen: 'Pre Auth Login' })}
          >
            Log In
          </Link>
        </View>
      </Card>
    </FadeInView>
  )
}
