import { useRef, useLayoutEffect } from 'react'
import { View, StyleSheet, Platform } from 'react-native'

// Components
import NumberInput from './components/NumberInput'
import Container from './components/Container'
import Form from './components/Form'
import CircleIcon from './components/CircleIcon'

import { primary, white } from './styles/colors'
import {
  ValidationSchema,
  Fields,
  InitialAutoValues,
  InitialMortgageValues,
  InitialStudentLoanValues
} from './schemas/loanCalculatorSchema'
import { mortgageLoanCalculation } from './actions/getMortgageLoanCalculation'
import { autoLoanCalculation } from './actions/getAutoLoanCalculation'
import { studentLoanCalculation } from './actions/getStudentLoanCalculation'

import { DEFAULT_SPACING } from './styles'

import CurrencyInput from './components/CurrencyInput'
import Input from './components/Input'
import handleError from './functions/handleError'

const styles = StyleSheet.create({
  resultsContainer: {
    backgroundColor: primary,
    flex: 0,
    padding: '6%',
    paddingBottom: '8%',
  },
  headerText: {
    textAlign: 'center',
    paddingTop: 10,
    fontSize: 14,
    color: white
  },
  moneyText: {
    textAlign: 'center',
    padding: 2,
    fontSize: 28,
    color: white,
    fontWeight: 'bold'
  },
  titleText: {
    textAlign: 'center',
    fontSize: 18,
    paddingTop: '5%'
  },
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 124,
    height: 124,
    borderRadius: 124 / 2,
    overflow: 'hidden'
  }
})

async function fetchCalculatorResults(type, payload) {
  if (type == 'Mortgage') {
    return await mortgageLoanCalculation(payload)
  } if (type == 'Auto') {
    return await autoLoanCalculation(payload)
  } if (type == 'Student Loans') {
    return await studentLoanCalculation(payload)
  }
}

function CircleAndIcon({ size, icon }) {
  return (
    <View style={{ alignItems: 'center', paddingVertical: DEFAULT_SPACING * 3 }}>
      <CircleIcon size={size} backgroundColor="rgb(255,234,248)" color="rgb(249,51,177)" icon={icon} />
    </View>
  )
}

const InitialValues = {
  Auto: InitialAutoValues,
  Mortgage: InitialMortgageValues,
  'Student Loans': InitialStudentLoanValues
}

const icons = {
  Auto: 'car',
  Mortgage: 'house',
  'Student Loans': 'building-columns'
}

const labelsForAmount = {
  Auto: 'Auto Loan Amount',
  Mortgage: 'Mortgage Loan Amount',
  'Student Loans': 'Student Loan Amount'
}

const labelsForPeriod = {
  Auto: 'Auto Loan Period (Months)',
  Mortgage: 'Mortgage Loan Period (Years)',
  'Student Loans': 'Student Loan Period (Years)'
}

export function LoanCalculatorForm({ title, amount, interest_rate, periods, navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const periodField = useRef()
  const rateField = useRef()

  return (
    <Form
      withPadding={false}
      buttonText="Calculate"
      enableReinitialize
      validationSchema={ValidationSchema}
      initialValues={InitialValues[title]}
      onSubmit={async (values) => {
        try {
          const query = {
            amount: values.amount,
            interest_rate: values.percent
          }

          if (title == 'Mortgage' || title == 'Student Loans') {
            query.years = values.period
          } else {
            query.months = values.period
          }

          const output = await fetchCalculatorResults(title, query)
          const { period, amount } = values
          const interestRate = output.interest_rate

          navigation.navigate(
            'Modal',
            {
              screen: isNotWeb ? 'Loan Calculator Results' : 'Loan Calculator Selection',
              params: { output, title, period, amount, interestRate }
            }
          )
        } catch (e) {
          handleError(e, 'Unable to calculate loan')
        }
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched }) => (

        <View style={{ flex: 1 }}>
          <View style={{ padding: DEFAULT_SPACING }}>
            <CurrencyInput
              type="money"
              unit="$"
              name={Fields.amount}
              errorMessage={errors[Fields.amount]}
              touched={touched[Fields.amount]}
              onChangeText={handleChange('amount')}
              onBlur={handleBlur('amount')}
              value={values.amount}
              label={labelsForAmount[title]}
              secureTextEntry={false}
              returnKeyType="next"
              placeholder={amount}
              keyboardType="number-pad"
              autoComplete="off"
              caretHidden
              enablesReturnKeyAutomatically
              onSubmitEditing={() => rateField.current.focus()}
            />

            <NumberInput
              type="money"
              precision={3}
              suffixUnit="%"
              ref={rateField}
              name={Fields.percent}
              errorMessage={errors[Fields.percent]}
              touched={touched[Fields.percent]}
              onChangeText={handleChange('percent')}
              onBlur={handleBlur('percent')}
              value={values.percent}
              label="Interest Rate (%)"
              secureTextEntry={false}
              returnKeyType="next"
              placeholder={interest_rate}
              keyboardType="number-pad"
              autoComplete="off"
              caretHidden
              enablesReturnKeyAutomatically
              onSubmitEditing={() => periodField.current.focus()}
            />

            <Input
              ref={periodField}
              name={Fields.period}
              errorMessage={errors[Fields.period]}
              touched={touched[Fields.period]}
              onChangeText={handleChange('period')}
              onBlur={handleBlur('period')}
              value={values.period}
              label={labelsForPeriod[title]}
              placeholder={periods}
              keyboardType="decimal-pad"
              onSubmitEditing={handleSubmit}
            />
          </View>
        </View>
      )}
    </Form>
  )
}

export default function LoanCalculatorScreen({ navigation, route }) {
  const { params } = route
  const { title } = params

  useLayoutEffect(() => {
    navigation.setOptions({ subtitle: title })
  }, [navigation, title])

  return (
    <Container>
      <View style={{ flex: 1 }}>
        <CircleAndIcon size={46} icon={icons[title]} />
        <LoanCalculatorForm {...params} navigation={navigation} />
      </View>
    </Container>
  );
}
