import { useLayoutEffect } from 'react'
import { StyleSheet } from 'react-native'
import IntroScreen from './components/IntroScreen'

const styles = StyleSheet.create({
  mockInput: {
    width: '80%',
    marginVertical: 24
  },
  finn: { width: '85%' }
})

export default function CollegeExploreIntroScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  return (
    <IntroScreen
      title="Explore Colleges"
      subtitle={'Explore demographics, tuition costs\n and more!'}
      image={require('./assets/college-explore-intro.png')}
      buttonText="Explore Colleges"
      finnStyle={styles.finn}
      onPress={() => {
        navigation.navigate('Modal', { screen: 'College Explore Search' })
      }}
    />
  )
}
