import { useEffect, useState } from 'react'
import { Animated } from 'react-native'
import { isUndefined, isArray, flatten } from 'lodash'

export default function FadeInView(props) {
  const { delay, toValue, duration, onFinish, style, children, initialValue } = props
  const [fadeAnim] = useState(new Animated.Value(initialValue || 0))

  useEffect(() => {
    Animated.timing(
      fadeAnim,
      {
        useNativeDriver: true,
        delay: isUndefined(delay) ? 400 : delay,
        toValue: isUndefined(toValue) ? 1 : toValue,
        duration: isUndefined(duration) ? 800 : duration
      }
    ).start(onFinish);
  }, [])

  const animatedStyle = isArray(style) ? flatten(style) : style

  return (
    <Animated.View
      style={[animatedStyle, { opacity: fadeAnim }]}
    >
      {children}
    </Animated.View>
  );
}
