import { Text, View, ScrollView, StyleSheet, Platform } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { LinearGradient } from 'expo-linear-gradient'

import { DEFAULT_SPACING, primaryGradientEnd, primaryGradientStart } from '../../styles'
import TouchableOpacity from '../TouchableOpacity'
import Breadcrumbs from '../Breadcrumbs'
import CircleGradient from '../CircleGradient'

const basicStyles = {
  container: { flex: 1, position: 'relative' },
  gradientBgContainer: {
    width: '100%',
    height: 190,
    position: 'relative'
  },
  gradientBg: {
    position: 'relative',
    height: '100%',
    width: '100%',
    zIndex: -1
  },
  main: {},
  left: {},
  title: {
    fontSize: 36,
    fontWeight: '600',
    color: 'white',
    width: '100%',
    paddingLeft: 40
  },
  backBtnContainer: { width: '100%', paddingLeft: 40, paddingVertical: 24 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    gradientBgContainer: {
      width: '100%',
      height: '100%',
      position: 'absolute'
    },
    main: { width: '100%', height: '100%', flexDirection: 'row' },
    left: { width: 440, alignItems: 'center', paddingBottom: DEFAULT_SPACING * 2 },
  }
})

const isNotWeb = Platform.OS !== 'web'

const InvestorProfileLayout = ({ navigation, route, investmentProfileCard, details }) => {
  const styles = isNotWeb ? mobileStyles : useStyles()
  
  return (
    <View style={styles.container}>
      <View style={styles.gradientBgContainer}>
        <CircleGradient height={'100%'} />
      </View>
      {isNotWeb && (
        <>
          {investmentProfileCard}
          {details}
        </>
      )}
      {!isNotWeb && (
        <View style={styles.main}>
          <View style={styles.left}>
            <View style={styles.backBtnContainer}>
              <TouchableOpacity onPress={() => navigation.pop()}>
                <FontAwesomeIcon icon={faArrowLeft} color="white" size={32} />
              </TouchableOpacity>
            </View>
            <Text style={styles.title}>Investor Profile</Text>
            <ScrollView
              style={{ width: '100%' }}
              contentContainerStyle={{ paddingBottom: 100, paddingLeft: 40 }}
            >
              {investmentProfileCard}
            </ScrollView>
          </View>
          {details}
        </View>
      )}
    </View>
  )
}

export default InvestorProfileLayout
