import { useState } from 'react'
import { View, ScrollView, StyleSheet, Text, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { useSelector } from 'react-redux'
import { forEach, last } from 'lodash'
import { Defs, LinearGradient, Stop } from 'react-native-svg'
import moment from 'moment'
import {
  VictoryChart,
  VictoryGroup,
  VictoryAxis,
  VictoryArea,
  VictoryTheme,
  VictoryLine
} from 'victory-native'

import Container from './components/Container'
import formatCurrency from './functions/formatCurrency'
import PillGroup from './components/PillGroup'
import { DEFAULT_SPACING, black, gunmetal, primary } from './styles'
import Divider from './components/Divider'
import { financialProjectionsSelector, bsAccountsSelector } from './selectors'
import abbreviateNumber from './functions/abbreviateNumber'
import { ACCOUNTS_BY_CATEGORY } from './functions/groupBSAccountsByCategory'
import Legend from 'components/Legend'

const basicStyles = {
  container: { flex: 1 },
  textContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingTop: DEFAULT_SPACING * 2
  },
  title: {
    fontSize: 48,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -0.6
  },
  smallTitle: {
    fontSize: 24,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -0.6,
    width: '80%',
    alignSelf: 'center',
    marginTop: DEFAULT_SPACING * 2
  },
  help: {
    fontSize: 16,
    textAlign: 'center',
    letterSpacing: -0.67,
    color: gunmetal,
    marginVertical: DEFAULT_SPACING * 2,
    lineHeight: 24
  },
  darkHelp: {
    color: black,
    marginVertical: 0
  },
  divider: {
    marginVertical: DEFAULT_SPACING * 2,
    width: '100%'
  },
  smallText: {
    textAlign: 'center',
    fontSize: 8,
    marginTop: 12
  },
  chartContainer: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      chartContainer: {
        alignSelf: 'center',
        width: '40vh',
        height: '40vh'
      },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

const ALL = 'all'
const CASH = 'cash'
const INVESTMENTS = 'investments'

const colors = [
  '#57B575',
  '#F7CB64',
  '#5F6A4A',
  '#755DDB',
  '#4F8FEF',
  '#EB5168',
  '#ED7157',
  '#6D7A7D',
  '#D453A9',
  '#2C3A48',
  '#24525C',
  '#67D0FA',
  '#8BD016'
]

function CashOrInvestmentChart({ data, bsAccountHash }) {

  const formatData = (data) => {
    return data.map((d) => ({
      x: new Date(d.date),
      y: d.value
    }))
  }

  const accountKeys = Object.keys(data)
  const legendData = accountKeys.map((key, index) => ({
    text: bsAccountHash[key] ? bsAccountHash[key].name : 'Unknown',
    color: colors[index % colors.length]
  }))

  return (
    <View>
      <VictoryChart theme={VictoryTheme.material} scale={{ x: 'time' }}>
        {accountKeys.map((key) => (
          <VictoryLine
            key={key}
            data={formatData(data[key])}
            style={{
              data: { stroke: colors[accountKeys.indexOf(key)], strokeWidth: 2 }
            }}
          />
        ))}
        <VictoryAxis
          tickCount={5}
          tickFormat={(y) => {
            return moment(y).format('YYYY')
          }}
        />

        <VictoryAxis
          dependentAxis
          tickFormat={(value) => `$${abbreviateNumber(value)}`}
        />
      </VictoryChart>
      <Legend options={legendData} />
    </View>
  )
}

function AllChart({ data }) {
  const formattedData = data.map((d) => {
    return {
      x: new Date(d.date),
      y: d.value
    }
  })

  const values = formattedData.map((d) => d.y)

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      scale={{ x: 'time', y: 'linear' }}
      domain={{
        y: [Math.min(...values) - 1000, Math.max(...values) + 1000]
      }}
    >
      <Defs>
        <LinearGradient id="primary" x1="0%" y1="0%" x2="0%" y2="100%">
          <Stop offset="0" stopColor={primary} stopOpacity={0.4} />
          <Stop offset="1" stopColor={primary} stopOpacity={0.1} />
        </LinearGradient>
      </Defs>

      <VictoryAxis
        tickCount={5}
        tickFormat={(y) => {
          return moment(y).format('YYYY')
        }}
      />

      <VictoryAxis
        dependentAxis
        tickFormat={(value) => `$${abbreviateNumber(value)}`}
      />

      <VictoryGroup offset={0}>
        <VictoryArea
          data={formattedData}
          style={{
            data: { fill: 'url(#primary)', stroke: primary, strokeWidth: 2 }
          }}
        />
      </VictoryGroup>
    </VictoryChart>
  )
}

export default function ProEstimatedValueScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [type, setType] = useState(ALL)

  const options = [
    {
      label: 'All',
      inverted: true,
      active: type === ALL,
      onPress: () => setType(ALL)
    },
    {
      label: 'Cash',
      inverted: true,
      active: type === CASH,
      onPress: () => setType(CASH)
    },
    {
      label: 'Investments',
      inverted: true,
      active: type === INVESTMENTS,
      onPress: () => setType(INVESTMENTS)
    }
  ]

  const bsAccountsData = useSelector(bsAccountsSelector)
  const projections = useSelector(financialProjectionsSelector).filter(
    (projection) => projection.date
  )

  const all = []
  const cash = {}
  const investments = {}

  let accountHash = {}

  bsAccountsData.assetAccounts.forEach((account) => {
    accountHash[account.accountId] = {
      accountType: account.accountType,
      name: account.name,
      category: account.category
    }
  })

  forEach(projections, (projection) => {
    all.push({ date: projection.date, value: projection.netWorth })

    forEach(projection.bsAccounts, (account) => {
      const accountHashValue = accountHash[account.accountId]

      if (!accountHashValue) {
        return
      }

      const category = ACCOUNTS_BY_CATEGORY.find((category) =>
        category.values.includes(accountHashValue.category)
      )

      console.log('category', category)

      if (category.type === 'CASH') {
        // in cash object, add to existing value
        if (!cash[account.accountId]) {
          cash[account.accountId] = []
        }

        cash[account.accountId].push({
          date: projection.date,
          value: account.balance
        })
      } else if (category.type == 'INVESTMENT') {
        if (!investments[account.accountId]) {
          investments[account.accountId] = []
        }

        investments[account.accountId].push({
          date: projection.date,
          value: account.balance
        })
      }
    })
  })

  const lastOfAll = last(all)
  const estimatedValue = lastOfAll ? lastOfAll.value : 0

  return (
    <Container style={styles.container}>
      <ScrollView
        contentContainerStyle={{ paddingBottom: 100 }}
        showsVerticalScrollIndicator={false}
      >
        <View>
          <Text style={styles.title}>
            {formatCurrency(estimatedValue, { showDollarSign: true })}
          </Text>

          <PillGroup
            style={{ width: 240, alignSelf: 'center' }}
            options={options}
          />
        </View>

        <View style={styles.chartContainer}>
          {type === ALL && <AllChart data={all} />}
          {type === CASH && (
            <CashOrInvestmentChart data={cash} bsAccountHash={accountHash} />
          )}
          {type === INVESTMENTS && (
            <CashOrInvestmentChart
              data={investments}
              bsAccountHash={accountHash}
            />
          )}
        </View>

        <Divider style={styles.divider} />

        <View>
          <Text style={styles.smallTitle}>Estimated Future Value</Text>
          <Text style={styles.help}>
            Your estimated value shows the path towards achieving your goal! The
            calculation models the potential growth of your savings and
            investments over time, based on your assumed rate of return.
          </Text>
          <Text style={styles.help}>
            There are two key ingredients to make your money grow:
          </Text>

          <Text style={[styles.help, styles.darkHelp]}>1). Time</Text>

          <Text style={[styles.help, styles.darkHelp]}>2.) Investing</Text>

          <Text style={styles.help}>Save early, save often, safe first.</Text>

          <Text style={styles.smallText}>
            *Graph for illustrative purposes only and not intended as an
            investment recommendation.
          </Text>
        </View>
      </ScrollView>
    </Container>
  )
}
