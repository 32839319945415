import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX
} from './constants'

export const Fields = {
  periods: 'periods',
  salary: 'salary',
  multiplier: 'multiplier',
  years_retire: 'years_retire'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.periods]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MIN)
    .max(100, `${INVALID_MAX}100`)
    .required(REQUIRED)
    .nullable(),
  [Fields.salary]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MIN)
    .max(3000000, `${INVALID_MAX}3,000,000`)
    .required(REQUIRED)
    .nullable(),
  [Fields.multiplier]: Yup.number().positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100`)
    .required(REQUIRED)
    .nullable(),
  [Fields.years_retire]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MIN)
    .max(3000000, `${INVALID_MAX}100`)
    .required(REQUIRED)
    .nullable(),
})
