import { useLayoutEffect, useRef, useState } from 'react'
import { View, StyleSheet, Text, useWindowDimensions } from 'react-native'
import { useDispatch } from 'react-redux'
import Form from './components/Form'

import Input from './components/Input'
import MockInput from './components/MockInput'

import { Fields, ValidationSchema, InitialValues } from './schemas/proEditProfileSchema'

import { DEFAULT_SPACING } from './styles'

import { NAVIGATION_TYPE } from './constants'
import { white } from './styles'

import CitySearch from './components/colleges/CitySearch'

import timeout from './functions/timeout'

import { saveCurrentPlan } from './actions'
import handleError from './functions/handleError'
import moment from 'moment'
import CircleGradient from './components/CircleGradient'

const styles = StyleSheet.create({
  container: (height) => ({
    flex: 1,
    backgroundColor: white,
    height
  }),
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: DEFAULT_SPACING * 4
  },
  lead: {
    color: white,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center',
    marginTop: DEFAULT_SPACING * 6,
    marginBottom: DEFAULT_SPACING * 4,
    letterSpacing: -0.25
  },
  form: {
    paddingTop: DEFAULT_SPACING * 6,
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    backgroundColor: white
  }
})

export default function EditProPlanDetailsScreen({ navigation, route }) {
  const { params } = route
  const {
    buttonText,
    custom_career_name,
    input_custom_career,
    birth_date,
    custom_career_salary,
    city,
    result
  } = params || {}

  const initialValues = InitialValues({
    custom_career_name,
    input_custom_career,
    birth_date,
    custom_career_salary,
    city
  })

  const [visible, setVisible] = useState(false)

  const dispatch = useDispatch()
  const window = useWindowDimensions()
  const height = window.height

  const onSubmit = async (values) => {
    try {
      dispatch(
        saveCurrentPlan({
          ...result,
          ...values,
          custom_career_salary: custom_career_salary,
          city_id: values.city.id,
          step: 3
        })
      )

      navigation.popToTop()
    } catch (e) {
      handleError(e)
    }
  }

  useLayoutEffect(() => {
    // background color for header is transparent
    navigation.setOptions({
      headerStyle: {
        backgroundColor: 'transparent'
      },
      headerTransparent: true,
      headerTintColor: white,
      headerTitleStyle: {
        fontWeight: 'bold'
      }
    })
  }, [navigation])

  const annualIncomeField = useRef()

  return (
    <View style={styles.container(height)}>
      <CircleGradient />

      <View style={styles.contentContainer}>
        <Text style={styles.lead}>Update your Pro Plan details</Text>

        <Form
          validationSchema={ValidationSchema}
          initialValues={initialValues}
          buttonText={buttonText}
          onSubmit={onSubmit}
          containerStyle={styles.form}
        >
          {({ setFieldValue, handleChange, handleSubmit, values, errors, touched }) => (
            <>
              <MockInput
                label="Birth Date"
                value={values.birth_date ? moment(values.birth_date).format('MM/DD/YYYY') : null}
              />

              <MockInput
                onPress={async () => {
                  setVisible(true)

                  await timeout(600)

                  setFieldTouched(Fields.city, true)
                }}
                label="City, State"
                touched={touched[Fields.city]}
                errorMessage={errors[Fields.city]}
                value={values.city && values.city.name ? values.city.name : ' '}
              />

              <Input
                label="Job Title"
                value={values.custom_career_name}
                name={Fields.custom_career_name}
                touched={touched[Fields.custom_career_name]}
                errorMessage={errors[Fields.custom_career_name]}
                onChangeText={handleChange(Fields.custom_career_name)}
                onSubmitEditing={() => annualIncomeField.current.focus()}
                returnKeyType="next"
              />

              {/* <CurrencyInput
                ref={annualIncomeField}
                prefix="$"
                label="Annual Income"
                value={values.custom_career_salary}
                name={Fields.custom_career_salary}
                touched={touched[Fields.custom_career_salary]}
                errorMessage={errors[Fields.custom_career_salary]}
                onChangeText={handleChange(Fields.custom_career_salary)}
                precision={0}
                onSubmitEditing={handleSubmit}
              /> */}

              <CitySearch
                visible={visible}
                setVisible={setVisible}
                city={values.city}
                setCity={(l) => {
                  setFieldValue(Fields.city, l)
                }}
              />
            </>
          )}
        </Form>
      </View>
    </View>
  )
}

EditProPlanDetailsScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
