import { useState, useEffect, useLayoutEffect } from 'react'
import { View, ScrollView, SafeAreaView, Image, Text, StyleSheet, Platform } from 'react-native'
import { Table, Row, Col, TableWrapper } from 'react-native-table-component'

import { useSelector } from 'react-redux'

import { FontAwesome5 } from '@expo/vector-icons'

import { get } from 'http'
import { map, times, first } from 'lodash'
import ListItem from './ListItem'
import TouchableOpacity from './TouchableOpacity'

import formatCurrency from '../functions/formatCurrency'

import { currentPlanSelector } from '../selectors'

import { darkblue, darkgrayblue, gray, grayblue, gunmetal, white } from '../styles/colors'
import handleError from '../functions/handleError'

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'left',
    fontWeight: '500'
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'left',
    letterSpacing: -0.4
  },
  shadow: {
    ...Platform.select({
      ios: {
        shadowColor: 'rgba(0, 0, 0, 0.24)',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowRadius: 11,
        shadowOpacity: 1
      },
      web: {
        shadowColor: 'rgba(0, 0, 0, 0.24)',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowRadius: 11,
        shadowOpacity: 1
      },
      android: {
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.24)'
      }
    })
  },
  container: {
    flex: 1,
    backgroundColor: white
  },
  scrollContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 10
  },
  careerLabel: {
    letterSpacing: -0.37,
    fontSize: 24,
    textAlign: 'center',
    color: darkgrayblue,
    fontWeight: '600',
    marginBottom: 10
  },
  locationLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: gunmetal,
    marginBottom: 44
  },
  label: {
    fontSize: 14,
    color: gunmetal,
    marginBottom: 9
  },
  value: {
    fontSize: 16,
    fontWeight: '400',
    color: gunmetal
  },
  salary: {
    fontSize: 32,
    fontWeight: '700',
    color: darkgrayblue,
    marginBottom: 27
  },
  description: {
    letterSpacing: -0.6,
    fontSize: 14.4,
    color: 'rgb(66, 86, 103)',
    marginBottom: 9
  },
  wrapper: { flexDirection: 'row' },
  row: { height: 50 },
  head: { height: 80 },
  title: {
    fontWeight: '600',
    fontSize: 12,
    color: grayblue,
    letterSpacing: 0,
    paddingVertical: 9,
    paddingHorizontal: 9,
  },
  titleContainer: {},
  titleText: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    fontWeight: '400'
  },
  text: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    color: gray,
    letterSpacing: -0.5
  },
})

export function InvestmentForm({ route, navigation }) {
  const { params } = route
  const { title, subtitle, builder } = params

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      subtitle
    })
  }, [navigation, title])

  const options = [
    {
      label: 'Invest',
      sublabel: 'S&P 500, 401k, Roth IRA and more',
      value: 'S&P 500',
      image: require('../assets/investment-chart.png'),
      navigationOptions: { screen: 'Investment Options', params: { title, subtitle, builder, investment_selection: 'SP500' } }
    }, {
      label: 'Save',
      sublabel: 'Savings Account, piggy bank, \ncash under the mattress',
      value: 'Cash',
      image: require('../assets/piggy-bank.png'),
      navigationOptions: { screen: 'Edit Goal', params: { title, subtitle: 'Custom Goal', builder, investment_selection: 'CASH' } }
    }
  ]

  function InvestmentOption({ label, sublabel, image, navigationOptions }) {
    return (
      <TouchableOpacity
        action={`Plan Builder: ${label}`}
        onPress={() => {
          navigation.navigate('Modal', navigationOptions)
        }}
      >
        <ListItem style={{ flexDirection: 'column', backgroundColor: white }}>
          <View>
            <Image
              style={{ width: 200, height: 160, marginBottom: 20, alignSelf: 'center' }}
              resizeMode="contain"
              source={image}
            />
          </View>

          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
            <View>
              <Text style={{ fontSize: 18, color: 'rgb(66, 86, 103)', fontWeight: '600' }}>{label}</Text>
              <Text style={{ fontSize: 12, color: gray }}>{sublabel}</Text>
            </View>

            <View style={{ alignSelf: 'center' }}>
              <FontAwesome5 style={{ alignSelf: 'flex-end' }} name="chevron-circle-right" size={18} color="rgb(223, 230, 236)" />
            </View>
          </View>
        </ListItem>
      </TouchableOpacity>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'rgb(235, 241, 244)' }}>
      <ScrollView style={{ flex: 1, padding: 20 }}>
        {map(options, (row) => <InvestmentOption key={row.label} {...row} />)}
      </ScrollView>
    </SafeAreaView>
  )
}

function federalTaxes(result, duration) {
  return result.taxes ? result.taxes[duration || 'annual'].federal : 0
}

function stateTaxes(result, duration) {
  return result.taxes ? result.taxes[duration || 'annual'].state : 0
}

function socialSecurityTaxes(result, duration) {
  return result.taxes ? result.taxes[duration || 'annual'].social_security : 0
}

function medicareTaxes(result, duration) {
  return result.taxes ? result.taxes[duration || 'annual'].medicare : 0
}

function grossIncome(result, duration) {
  return result.gross_income ? result.gross_income[duration || 'annual'] : 0
}

export function TaxesTable({ route }) {

  const { duration } = route.params
  const currentPlan = useSelector((state) => currentPlanSelector(state))

  const [result, setResult] = useState({})

  const wage = grossIncome(result, duration)

  const tableData = [
    [`$${formatCurrency(federalTaxes(result, duration))}`, `${((federalTaxes(result, duration) / wage) * 100).toFixed(2)}%`],
    [`$${formatCurrency(stateTaxes(result, duration))}`, `${((stateTaxes(result, duration) / wage) * 100).toFixed(2)}%`],
    [`$${formatCurrency(socialSecurityTaxes(result, duration))}`, '6.20%'],
    [`$${formatCurrency(medicareTaxes(result, duration))}`, '1.45%']
  ]

  const tableTitle = [
    'Federal Taxes',
    'State Taxes',
    'Social Security',
    'Medicare'
  ]

  const tableConfig = {
    tableHead: ['', ''],
    tableTitle,
    tableData
  }

  async function fetchAndSetData(currentPlan) {
    try {
      const result = await get(`api/v1/plans/${currentPlan.id}/`)

      setResult({ ...currentPlan, ...result.data })
    } catch (error) {
      handleError(error, 'Unable to fetch plan')
    }
  }

  useEffect(() => {
    fetchAndSetData(currentPlan)
  }, [currentPlan])

  return (
    <View style={[styles.container, { backgroundColor: white }]}>
      <Table>
        <TableWrapper style={[styles.wrapper]}>
          <Table style={{ width: '40%', height: 5000 }} borderStyle={{ borderWidth: 1, borderColor: '#F1F6F7' }}>
            <Col data={tableConfig.tableTitle} style={styles.titleContainer} heightArr={[].concat(map(tableTitle, () => 50))} textStyle={styles.title} />
          </Table>
          <ScrollView horizontal style={{ flex: 2, height: 5000, backgroundColor: 'rgba(	235, 241, 244, 0.3)' }}>
            <Table style={{ flex: 1 }} borderStyle={{ borderWidth: 1, borderColor: '#F1F6F7' }}>
              {map(tableConfig.tableData, (data) => <Row widthArr={times(first(tableData).length, (form) => 110)} data={data} style={styles.row} textStyle={styles.text} />)}
            </Table>
          </ScrollView>
        </TableWrapper>
      </Table>
    </View>
  )
}
