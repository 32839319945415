import * as Yup from 'yup'

import { REQUIRED, INVALID_EMAIL } from './constants'

export const Fields = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
  birthDate: 'birth_date'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.firstName]: Yup.string().required(REQUIRED),
  [Fields.lastName]: Yup.string().required(REQUIRED),
  [Fields.email]: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED),
  [Fields.birthDate]: Yup.date()
    .required(REQUIRED)
})

export const InitialValues = {
  [Fields.firstName]: '',
  [Fields.lastName]: '',
  [Fields.email]: '',
  [Fields.password]: '',
  [Fields.birthDate]: ''
}
