export const MONTHLY_PERIODICITY = 'MONTHLY'
export const DAILY_PERIODICITY = 'DAILY'
export const WEEKLY_PERIODICITY = 'WEEKLY'
export const BI_WEEKLY_PERIODICITY = 'BI_WEEKLY'
export const SEMI_MONTHLY_PERIODICITY = 'SEMI_MONTHLY'
export const YEARLY_PERIODICITY = 'YEARLY'

export const PERIODICITY_VALUES = [
  DAILY_PERIODICITY,
  WEEKLY_PERIODICITY,
  BI_WEEKLY_PERIODICITY,
  SEMI_MONTHLY_PERIODICITY,
  MONTHLY_PERIODICITY,
  YEARLY_PERIODICITY
]

export const GET_PERIDICITY_TEXT = (periodicity) => {
  switch (periodicity) {
  case DAILY_PERIODICITY:
    return 'Daily'
  case WEEKLY_PERIODICITY:
    return 'Weekly'
  case BI_WEEKLY_PERIODICITY:
    return 'Bi-Weekly'
  case SEMI_MONTHLY_PERIODICITY:
    return 'Semi-Monthly'
  case YEARLY_PERIODICITY:
    return 'Yearly'
  case MONTHLY_PERIODICITY:
  default:
    return 'Monthly'
  }
}
