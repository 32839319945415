import { View, Text, StyleSheet } from 'react-native'
import Link from '../Link'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingHorizontal: DEFAULT_SPACING
  },
  label: {
    fontWeight: '400',
    fontSize: 12,
  }
})

export default function FilterBanner({ numFilters, onPress }) {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>
        {' '}
        {numFilters}
        {' '}
        {numFilters > 1 ? 'Filters' : 'Filter'}
        {' '}
        Applied
      </Text>
      <Link onPress={onPress}>Clear All</Link>
    </View>
  )
}
