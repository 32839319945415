import { useEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { useSelector } from 'react-redux'
import { LinearGradient } from 'expo-linear-gradient'
import { map, sum } from 'lodash'
import { get } from 'http'
import HeaderLabel from './components/HeaderLabel'
import Button from './components/Button'

import { accountsSelector } from './selectors'

import formatCurrency from './functions/formatCurrency'

import FireIcon from './components/emergency-preparedness/FireIcon'

import { darkblue, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    padding: DEFAULT_SPACING
  },
  resultContainer: {
    flex: 1,
    alignItems: 'center',
    padding: DEFAULT_SPACING,
    justifyContent: 'center'
  },
  emergencyFinn: {
    height: 286,
    width: 286,
    marginBottom: 24
  },
  headerLabel: {
    marginBottom: 24,
    textAlign: 'center',
    fontSize: 16,
    letterSpacing: -0.48
  },
  text: {
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center'
  },
  expense: {
    fontWeight: '500',
    fontSize: 48,
    letterSpacing: 0,
    textAlign: 'center',
    color: darkblue,
    marginTop: 100
  },
  expenseLabel: {
    fontWeight: '500',
    fontSize: 14,
    letterSpacing: 0,
    textAlign: 'center',
    color: darkblue
  },
  bottomButtonStyle: {
    justifyContent: 'flex-end',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING,
    backgroundColor: white
  }
})

function calculateTotal(currentAccounts) {
  return sum(map(currentAccounts, (s) => Number(s.balance)))
}

export default function EmergencyPreparednessFinishScreen({ navigation }) {
  const currentAccounts = useSelector((state) => accountsSelector(state))
  const [emergencyPreparedness, setEmergencyPreparedness] = useState({})

  async function fetchEmergencyPreparedness() {
    const result = await get('emergency-preparedness/')
    setEmergencyPreparedness(result.data)
  }

  useEffect(() => {
    fetchEmergencyPreparedness()
  }, [currentAccounts])

  let status = emergencyPreparedness.status || 'Level 1'
  status = status.replace('_', ' ').replace('LEVEL', 'Level')

  const statusNumber = status[status.length - 1]

  let circleColor = 'white'
  let circleDistance = 0

  if (statusNumber == '1') {
    circleColor = '#F2285B'
    circleDistance = '5%'
  } else if (statusNumber == '2') {
    circleColor = '#FBBD29'
    circleDistance = '50%'
  } else if (statusNumber == '3') {
    circleColor = '#43CF62'
    circleDistance = '95%'
  }

  return (
    <View style={{ backgroundColor: white, flex: 1 }}>
      <HeaderLabel style={styles.headerLabel}>
        Emergency Preparedness
      </HeaderLabel>
      <Text style={styles.text}>
        Awesome work! You have enough money to cover
        your monthly expenses for
        <Text style={{ fontWeight: '800' }}>
          {' '}
          {emergencyPreparedness.months_of_expenses_covered || 0}
          {' '}
          months.
          {' '}
        </Text>
        Keep saving to level up and be more secure!
      </Text>
      <View style={styles.container}>
        <LinearGradient
          colors={['#43CF62', '#FBBD29', '#F2285B']}
          style={{ height: 8, width: '100%', borderRadius: 5, marginBottom: 6 }}
          start={[1, 1]}
          end={[0, 1]}
        />

        <FireIcon style={{ position: 'absolute', left: circleDistance }} color={circleColor} size={40} iconSize={20} />

        <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 12 }}>
          <View style={{ flex: 1 }}>
            <HeaderLabel style={{ textAlign: 'left', fontSize: 14 }}>Level 1</HeaderLabel>
            <Text style={{ textAlign: 'left', fontSize: 10 }}>3 Months</Text>
          </View>
          <View style={{ flex: 1 }}>
            <HeaderLabel style={{ textAlign: 'center', fontSize: 14 }}>Level 2</HeaderLabel>
            <Text style={{ textAlign: 'center', fontSize: 10 }}>6 Months</Text>
          </View>
          <View style={{ flex: 1 }}>
            <HeaderLabel style={{ textAlign: 'right', fontSize: 14 }}>Level 3</HeaderLabel>
            <Text style={{ textAlign: 'right', fontSize: 10 }}>9 Months</Text>
          </View>
        </View>

        <View style={styles.resultContainer}>
          <FireIcon color={circleColor} size={110} iconSize={60} />

          <Text style={{
            fontWeight: '600',
            fontSize: 48,
            color: darkblue,
            letterSpacing: 0,
            textAlign: 'center'
          }}
          >
            $
            {formatCurrency(calculateTotal(currentAccounts))}
          </Text>
          <Text style={{
            fontWeight: '600',
            fontSize: 16,
            color: darkblue,
            letterSpacing: 0,
            textAlign: 'center'
          }}
          >
            {status || 'Level 1'}
            {' '}
            •
            {emergencyPreparedness.months_of_expenses_covered || 0}
            {' '}
            Months
          </Text>
        </View>
      </View>
      <View style={styles.bottomButtonStyle}>
        <Button onPress={() => {
          navigation.popToTop()
          navigation.pop()
        }}
        >
          Finish
        </Button>
      </View>
    </View>
  );
}
