import { View, Text, StyleSheet } from 'react-native'

import lightenDarkenColor from '../functions/lightenDarkenColor'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    borderRadius: 25,
    height: 50,
    width: 50
  },
  initial: {
    fontSize: 24,
    fontWeight: '600',
    textAlign: 'center'
  }
})

export default function Avatar({ initial, color }) {
  return (
    <View style={[styles.container, { backgroundColor: color ? lightenDarkenColor(color, 160) : 'transparent' }]}>
      <Text style={[styles.initial, { color }]}>{initial}</Text>
    </View>
  )
}
