import { View, StyleSheet, Text } from 'react-native'
import SlideupDrawer from '../SlideupDrawer'
import { darkblue, gunmetal } from '../../styles/colors'
import RadioGroup from '../RadioGroup'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 80
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center'
  },
  body: {
    marginVertical: 20,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center'
  },
})

export default function SearchOptionsSlideupDrawer({ visible, setVisible, onPress, options, selected }) {
  const marginTop = 200
  const height = 500

  return (
    <SlideupDrawer snapPointIndex={1} scrollEnabled={false} marginTop={marginTop} height={height} visible={visible} setVisible={setVisible}>
      <View style={styles.container}>
        <Text style={styles.header}>Sort By</Text>

        <RadioGroup
          options={options.map(({ label, value }) => ({ leftText: label, value }))}
          value={selected}
          onPress={onPress}
          size={26}
        />
      </View>
    </SlideupDrawer>
  )
}
