import { useEffect, useState } from 'react'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, map } from 'lodash'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import {
  loadingSelector,
  currentUserSelector,
  primaryProPlanSelector,
  pendingInvitationsSelector,
  branchEventsSelector
} from './selectors'

import FreeDashboardScreen from './FreeDashboardScreen'
import ProDashboardScreen from './ProDashboardScreen'

import IntroModal from './components/dashboard/IntroModal'
import OnboardingModal from './components/dashboard/OnboardingModal'
import FadeInView from './components/FadeInView'
import Success from './components/teamOnboarding/Success'
import Modal from './components/Modal'
import { white } from './styles'
import AsyncStorage from '@react-native-async-storage/async-storage'

const basicStyles = {
  modal: {
    borderRadius: 20,
    overflow: 'hidden',
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      modal: {
        width: 416,
        height: '80%',
        maxHeight: 497,
        marginHorizontal: 'auto',
        marginVertical: 'auto',
        borderRadius: 12,
        backgroundColor: white,
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

function formatInvitations(invitations) {
  return map(invitations, (invitation) => ({
    key: invitation.key,
    organization_logo: invitation.organization.logo_url,
    organization_name: invitation.organization.name,
    recipient_name: `${invitation.first_name} ${invitation.last_name}`
  }))
}

export default function DashboardScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { width, height } = useWindowDimensions()
  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState(false)
  const [invitation, setInvitation] = useState()

  useEffect(() => {
    const getInvitation = async () => {
      const invitationStr = await AsyncStorage.getItem('invitation')
      setInvitation(invitationStr)
    }
    getInvitation()
  }, [])

  const primaryProPlan = useSelector(primaryProPlanSelector)
  const currentUser = useSelector(currentUserSelector)
  const { metadata } = currentUser
  const introMetadataKey = 'TROUTWOOD_INTRO'
  const clickWrapMetadataKey = 'TROUTWOOD_CLICKWRAP'

  const SelectedDashboardScreen = !isEmpty(primaryProPlan)
    ? ProDashboardScreen
    : FreeDashboardScreen

  const branchEvents = useSelector((state) => branchEventsSelector(state, 'invite'))
  const invitations = formatInvitations(
    useSelector((state) => pendingInvitationsSelector(state))
  )
  const { loading } = useSelector((state) => loadingSelector(state))

  const pendingInvitations = [...branchEvents, ...invitations]

  useEffect(() => {
    if (invitation) {
      setIsShow(true)

      setTimeout(() => {
        AsyncStorage.removeItem('invitation')
      }, 1000);
    }
  }, [invitation])

  return (
    <>
      {!loading
        && currentUser.id
        && !currentUser.is_fake
        && metadata[clickWrapMetadataKey] && (
          <IntroModal metadataKey={introMetadataKey} />
        )}
      {!loading
        && currentUser.id
        && !currentUser.is_fake
        && metadata[introMetadataKey]
        && pendingInvitations.length > 0 && (
          <OnboardingModal
            pendingInvitations={pendingInvitations}
            dispatch={dispatch}
            navigation={navigation}
          />
        )}

      <FadeInView style={{ flex: 1, }}>
        <SelectedDashboardScreen route={route} navigation={navigation} />
      </FadeInView>
      <Modal
        visible={isShow}
        onClose={() => setIsShow(false)}
        style={styles.modal}
      >
        <Success
          navigation={navigation}
          invitation={invitation && JSON.parse(invitation)}
          onClose={() => setIsShow(false)}
        /></Modal>
    </>
  )
}
