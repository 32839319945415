import VideoList from './components/VideoList'

export default function HowToDictionaryScreen({ navigation }) {
  return (
    <VideoList
      navigation={navigation}
      playlistId="PLHH90evyBwnLQdnEidnDbV6pEvfOz6Vqy"
      route="Video Player"
    />
  )
}
