import { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { map } from 'lodash'
import Container from '../Container'
import SearchInput from '../SearchInput'
import ScrollingListContainer from '../ScrollingListContainer'
import SearchResultFinn from '../SearchResultFinn'
import SearchListItem from '../SearchListItem'

import { searchLocation } from '../../api/locations'

import { saveCurrentPlan } from '../../actions'

export default function LocationForm({ navigation, route }) {
  const dispatch = useDispatch()

  const { params } = route
  const { callback } = params

  const [locationSearch, setLocationSearch] = useState(null)
  const [location, setLocation] = useState()

  const [locationOptions, setLocationOptions] = useState([])

  async function searchLocationAPI(value) {
    if (value.length >= 3) {
      try {
        const locationResults = await searchLocation(value)
        setLocationOptions(locationResults.data)
      } catch (error) {
        console.error(error.response, ' -- error')
      }
    } else {
      setLocationOptions([])
    }
  }

  useEffect(() => {
    if (locationSearch) searchLocationAPI(locationSearch)
  }, [locationSearch])

  return (
    <Container withPadding>
      <SearchInput
        clearable
        icon="magnifying-glass"
        value={locationSearch}
        onChange={(value) => {
          setLocationSearch(value || null)
        }}
      />

      <ScrollingListContainer
        displayButton={false}
      >

        {map(locationOptions, (loc) => (
          <SearchListItem
            key={loc.id}
            onPress={() => {
              navigation.pop()

              if (callback) {
                callback({ city: loc })
              } else {
                dispatch(saveCurrentPlan({ city_id: loc.id }))
              }
            }}
            checked={location && loc.id == location.id}
            leftLabel={`${loc.name}`}
          />
        ))}

        {locationOptions.length == 0 && locationSearch && locationSearch.length > 3
          && (
            <SearchResultFinn
              image={require('../../assets/finn/question.png')}
              title="No Results"
              subtitle="Explore another location"
            />
          )}

        {locationOptions.length == 0 && !locationSearch
          && (
            <SearchResultFinn
              image={require('../../assets/finn/happy.png')}
              title="Search for a Location"
              subtitle="Try typing the city you live in now"
            />
          )}
      </ScrollingListContainer>
    </Container>
  )
}
