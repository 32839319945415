import { useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import SlideupDrawer from 'components/SlideupDrawer'
import Carousel from 'components/Carousel'
import FadeInView from 'components/FadeInView'
import {
  GenericSlide,
  GenericImageSlide
} from 'components/GetStartedSlides'
import { info, DEFAULT_SPACING } from 'styles'
import Button from '../Button'
import { useDispatch } from 'react-redux'
import { saveMetaData } from 'actions'

const styles = StyleSheet.create({
  container: {
    paddingVertical: 80,
    flex: 1,
    height: '100%'
  },
  fadeInContainer: {
    paddingHorizontal: DEFAULT_SPACING
  },
  button: {
    marginHorizontal: DEFAULT_SPACING
  }
})

export default function ProTutorialSlideupDrawer({ visible, setVisible, metadata }) {
  const metadataKey = 'TROUTWOOD_PRO_TUTORIAL'
  const [page, setPage] = useState(0)
  const dispatch = useDispatch()

  const slides = [
    <GenericSlide header={`👋\nWelcome to your \nPro Dashboard`} auxText={'Congratulations! You now have access to financial institution quality modeling at your fingertips. We will walk you through a few new things you now have access to.'} />,
    <GenericSlide header={`Wellness Shields`} auxText={'Your wellness shields represent your overall financial health. Earn and maintain these green shields to reach your goal!'} />,
    <GenericSlide header={`Accounts`} auxText={'You can now add or connect your banks, credit cards, loans and more to get a more personalized financial forecast!'} />,
    <GenericSlide header={`Cash Flow Waterfall`} auxText={'Get financial institution quality forecasting, budgeting and trends with our Cash Flow Waterfall.* \n\n* In order to provide advanced forecasting, a few accounts are needed. We’ve created these for you and you can edit this at any time under the cash waterfall section.'} />,
    <GenericSlide header={`FinPulse`} auxText={'FinPulse answers the question “Am I on Track?”. Use this tool to help you be held accountable to contributing towards your savings goal. How long can your streak go?'} />,
    <GenericSlide header={`Live Your Plan`} auxText={'Now it’s time to live your plan and achieve your financial goals!'} />
  ]

  const images = [
    <GenericImageSlide style={{resizeMode: 'cover'}} key="welcome" image={require('../../assets/pro-tutorial-1.png')} />,
    <GenericImageSlide key="wellness-shield" image={require('../../assets/pro-tutorial-2.png')} />,
    <GenericImageSlide style={{width: '60%'}} key="accounts" image={require('../../assets/pro-tutorial-3.png')} />,
    <GenericImageSlide key="cash-flow-waterfall" image={require('../../assets/pro-tutorial-4.png')} />,
    <GenericImageSlide key="finpulse" image={require('../../assets/pro-tutorial-5.png')} />,
    <GenericImageSlide style={{width: '30%'}} key="live-your-plan" image={require('../../assets/pro-tutorial-6.png')} />
  ]

  const imageComponent = images[page]
  const lastSlide = page === images.length - 1

  return (
    <SlideupDrawer snapPointIndex={4} scrollEnabled={false} visible={visible} setVisible={setVisible}>
      <View style={styles.container}>

        {imageComponent}

        <FadeInView
          style={{ position: 'absolute', bottom: Platform.OS == 'web' ? 50 : 20 }}
          delay={50}
          duration={400}
        >
          <Carousel
            height={250}
            onSwipe={setPage}
            data={slides}
            page={page}
          />

          <Button onPress={() => {
            if (lastSlide) {
              setVisible(false)
              dispatch(saveMetaData({ [metadataKey]: !metadata[metadataKey] }))
            } else {
              setPage(page + 1)
            }
          }} style={styles.button} color={info}>
            { lastSlide ? 'Get Started' : 'Next' }
          </Button>
        </FadeInView>
      </View>      
    </SlideupDrawer>
  )
}
