import { useLayoutEffect } from 'react'
import { reduce } from 'lodash'
import { StyleSheet, Text, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import ListOptionItem from 'components/ListOptionItem'
import CurrencyInput from './components/CurrencyInput'
import Form from './components/Form'
import { NAVIGATION_TYPE, SHORT_TERM_BS_ACCOUNT_TYPE } from './constants'
import { Fields, ValidationSchema, InitialValues } from './schemas/proCashWaterfallSchema'
import { bsAccountsSelector, proPlansSelector } from './selectors'
import { DEFAULT_SPACING, rgba } from './styles'
import { ACCOUNTS_BY_CATEGORY, getCategoryColor } from './functions/groupBSAccountsByCategory'
import { saveCashSweepLogic } from './actions/saveCashSweepLogic'

/* Styling */
const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    marginBottom: DEFAULT_SPACING / 2
  },
  scrollView: (enableMinAndMax) => ({
    marginBottom: DEFAULT_SPACING * 4,
    height: enableMinAndMax ? 240 : 'auto'
  })
})

/** Helper Functions
 *  Defined outside component because it's better defined here
 *  That way React is not tracking another object inside the component
*/
const getShortTermOptions = (accounts) => [...reduce(
  accounts,
  (options, account) => {
    if (account.liquidityType === SHORT_TERM_BS_ACCOUNT_TYPE) {
      options.push(account)
    }
    return options
  },
  []
)]
/* End Helper functions */

export default function CashWaterfallFormScreen({ navigation, route }) {
  const { assetAccounts: assets, liabilityAccounts: liabilities } = useSelector(bsAccountsSelector)
  // const currentProPlan = useSelector(currentProPlanSelector)
  // find primary plan
  const proPlans = useSelector(proPlansSelector)
  const currentProPlan = proPlans.find((plan) => plan.isPrimary)

  const dispatch = useDispatch()
  const { params } = route
  const { enableMinAndMax, isAsset, title, field } = params || {}

  const onSubmit = async (values) => {
    const currentCashSweepLogic = {}

    currentCashSweepLogic[field] = values[field]

    if (enableMinAndMax) {
      currentCashSweepLogic[Fields.minCashFlow] = values[Fields.minCashFlow]
      currentCashSweepLogic[Fields.maxCashFlow] = values[Fields.maxCashFlow]
    }

    await (dispatch(saveCashSweepLogic(currentCashSweepLogic)))

    navigation.pop()
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: title,
      headerTitleStyle: { fontSize: 16 },
    })
  }, [navigation])

  const options = isAsset ? getShortTermOptions(assets) : getShortTermOptions(liabilities)
  options.forEach((option) => {
    const { category } = option
    const { icon } = ACCOUNTS_BY_CATEGORY.find((account) => account.values.includes(category)) || {}
    // get color
    const color = getCategoryColor(option.accountType, option.category)
    option.icon = icon
    option.color = color
  })

  return (
    <Form
      validationSchema={ValidationSchema}
      initialValues={InitialValues(currentProPlan)}
      buttonText="Save"
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        handleChange,
        values,
        errors,
        touched
      }) => (
        <>
          <ScrollView style={styles.scrollView(enableMinAndMax)}>
            {options.map((option) => (
              <ListOptionItem
                key={option.accountId}
                label={option.name}
                value={option.accountId}
                iconName={option.icon}
                iconColor={option.color}
                iconBackgroundColor={rgba(option.color, 0.1)}
                checked={values[field] === option.accountId}
                onCheck={(v) => setFieldValue(field, v)}
                onPress={() => setFieldValue(field, option.accountId)}
                containerStyle={{ borderWidth: 0, borderBottomWidth: 1 }}
                checkLeft={false}
                iconSize={16}
              />
            ))}
          </ScrollView>

          { enableMinAndMax && (
            <>
              <Text style={styles.label}>
                Enter the minimum value you expect to have in this account
              </Text>

              <CurrencyInput
                label="Minimum Value"
                name={Fields.minCashFlow}
                touched={touched[Fields.minCashFlow]}
                errorMessage={errors[Fields.minCashFlow]}
                onChangeText={handleChange(Fields.minCashFlow)}
                value={values[Fields.minCashFlow]}
              />

              <Text style={styles.label}>
                Enter the maximum value you expect to have in this account
              </Text>

              <CurrencyInput
                label="Maximum Value"
                name={Fields.maxCashFlow}
                touched={touched[Fields.maxCashFlow]}
                errorMessage={errors[Fields.maxCashFlow]}
                onChangeText={handleChange(Fields.maxCashFlow)}
                value={values[Fields.maxCashFlow]}
              />
            </>
          )}
        </>
      )}
    </Form>
  )
}

CashWaterfallFormScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
