import { Animated, Easing } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

export default function FontAwesomeSpin({ opposite, color, icon, size }) {
  const spinValue = new Animated.Value(0)

  Animated.loop(
    Animated.timing(
      spinValue,
      {
        toValue: 1,
        duration: 1000,
        easing: Easing.linear,
        useNativeDriver: true
      }
    )
  ).start()

  const inputRange = [0, 1]
  const outputRange = opposite ? ['360deg', '0deg'] : ['0deg', '360deg']
  const rotate = spinValue.interpolate({ inputRange, outputRange })

  return (
    <Animated.View style={{ transform: [{ rotate }] }}>
      <FontAwesomeIcon color={color} icon={icon} size={size} />
    </Animated.View>
  )
}
