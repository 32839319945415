import { Linking } from 'react-native'
import BottomButton from '../BottomButton'
import { DEFAULT_SPACING } from '../../styles'

import { APP_LINK, APP_LINK_TITLE } from '../../constants'

export default function ShareButton({ selected }) {
  return (
    <BottomButton
      style={{ paddingHorizontal: DEFAULT_SPACING }}
      onPress={() => {
        Linking.openURL(`mailto:?subject=${APP_LINK_TITLE}&body=${selected} ${APP_LINK}`)
      }}
    >
      Share
    </BottomButton>
  )
}
