import { stateReducer } from '@truefit/redux-utils'
import {
  GET_ASSESS_SCORE_FAILURE,
  GET_ASSESS_SCORE_REQUEST,
  GET_ASSESS_SCORE_SUCCESS,
  SET_ASSESS_SCORE_FAILURE,
  SET_ASSESS_SCORE_REQUEST,
  SET_ASSESS_SCORE_SUCCESS,
  CLEAR
} from '../actions';

const initialState = {
  assessScore: {},
  loading: false,
  setting: false,
  error: null,
}

export default stateReducer(initialState, {
  [GET_ASSESS_SCORE_REQUEST]: (state) => ({ ...state, loading: true, error: null }),
  [GET_ASSESS_SCORE_SUCCESS]: (state, payload) => (
    { ...state, assessScore: payload, loading: false, error: null }),
  [GET_ASSESS_SCORE_FAILURE]: (state, payload) => ({ ...state, loading: false, error: payload }),
  [SET_ASSESS_SCORE_REQUEST]: (state) => ({ ...state, setting: true, error: null }),
  [SET_ASSESS_SCORE_SUCCESS]: (state, payload) => (
    { ...state, assessScore: payload, setting: false, error: null }),
  [SET_ASSESS_SCORE_FAILURE]: (state, payload) => ({ ...state, setting: false, error: payload }),
  [CLEAR]: () => initialState
});
