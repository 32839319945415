import { View, Text, StyleSheet } from 'react-native'
import { darkblue, lightestgray } from '../styles/colors'

const styles = StyleSheet.create({
  quickActionContainer: {
    padding: 4,
    borderRadius: 8,
    backgroundColor: lightestgray,
    justifyContent: 'center',
    alignItems: 'center'
  },
  quickActionText: {
    fontWeight: '500',
    fontSize: 10,
    color: darkblue,
    letterSpacing: 1.92,
    textTransform: 'uppercase'
  }
})

export default function PillLabel({ title, width, style = {}, textStyle = {} }) {
  return (
    <View style={[styles.quickActionContainer, style, { width: width || 140 }]}>
      <Text style={[styles.quickActionText, textStyle]}>{title}</Text>
    </View>
  )
}
