import { useEffect, useRef } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import ConfettiCannon from 'react-native-confetti-cannon'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import SlideupDrawer from './SlideupDrawer'
import Button from './Button'

import { darkblue, gunmetal, primary } from '../styles/colors'
import { saveMetaData } from '../actions'

import { currentUserSelector } from '../selectors'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 220,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 0,
    paddingHorizontal: 20
  },
  image: {
    width: 110,
    height: 165,
    marginBottom: 20,
    alignSelf: 'center'
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    zIndex: 100
  },
  body: {
    marginVertical: 20,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center',
    zIndex: 100
  },
  button: { marginTop: 20 }
})

export default function CongratsSlideupDrawer({ marginTop = 200, iconHeight = 150, iconWidth = 150, title, subtitle, buttonText, visible, setVisible, metadataKey, onClose, Icon, icon }) {
  const explosion = useRef()
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => currentUserSelector(state))
  const { metadata } = currentUser
  const visibleBasedOnMetaData = !metadataKey || metadata && !metadata[metadataKey]

  useEffect(() => {
    if (visible && visibleBasedOnMetaData) {
      explosion?.current?.start()
    }
  }, [visible, title, metadata, explosion])

  function handleClose() {
    setVisible(false)

    // Make this call after the modal animation finishes
    // otherwise it will be choppy
    setTimeout(() => {
      if (metadataKey) dispatch(saveMetaData({ [metadataKey]: true }))
      if (onClose) onClose()
    }, 1000)
  }

  return (
    <SlideupDrawer snapPointIndex={1} visible={visible && visibleBasedOnMetaData} setVisible={handleClose}>
      <View style={styles.container}>
        { (visible && visibleBasedOnMetaData)
          && (
            <ConfettiCannon
              count={200}
              explosionSpeed={0}
              origin={{ x: 0, y: 0 }}
              autoStart
              ref={explosion}
              fadeOut
            />
          )}

        { icon && <FontAwesomeIcon icon={icon} color={primary} size={iconHeight} /> }
        { !icon && <FontAwesomeIcon icon="shield-halved" color={primary} size={iconHeight} /> }

        <Text style={styles.header}>{title}</Text>
        <Text style={styles.body}>{subtitle}</Text>

        <Button onPress={handleClose} style={styles.button}>{ buttonText || 'Got it!'}</Button>
      </View>
    </SlideupDrawer>
  )
}
