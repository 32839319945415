import { useState, useEffect } from 'react'
import { Text, View, Platform, StyleSheet, useWindowDimensions } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { FontAwesome5 } from '@expo/vector-icons'
import { VictoryPie } from 'victory-native'
import { map } from 'lodash'
import PlanSummaryCard from './PlanSummaryCard'

import hapticFeedback from '../hapticFeedback'
import IconDataRow from '../IconDataRow'
import TouchableOpacity from '../TouchableOpacity'
import Modal from '../Modal'
import HeaderLabel from '../HeaderLabel'

import formatCurrency from '../../functions/formatCurrency'

import { grayblue, gunmetal, info, primary, rgba, white } from '../../styles/colors'

import { DEFAULT_SPACING } from '../../styles'
import PillGroup from '../PillGroup'
import { MONTHLY, WEEKLY } from '../../constants'
import formatPercent from '../../functions/formatPercent'
import Link from '../Link'

import { PENSION } from '../../constants'

const basicStyles = {
  disclaimer: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'left',
    letterSpacing: -0.4
  },
  modal: {},
  modalHeader: { fontSize: 18 },
  modalText: {
    fontSize: 12,
    color: gunmetal
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    modal: {
      backgroundColor: white,
      borderRadius: 12,
      width: 400,
      height: 150,
      paddingHorizontal: 20,
      paddingVertical: 30
    }
  }
})

const isNotWeb = Platform.OS !== 'web'

function DataDonut({ data, navigation, divisor, duration }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const window = useWindowDimensions()
  const dataWithoutTotal = data.filter((d) => d.label !== 'TOTAL')

  const graphicData = map(dataWithoutTotal, (value) => ({ y: value.rawValue }))

  const graphicLabels = map(dataWithoutTotal, (value) => value.label)

  const graphicColor = map(dataWithoutTotal, (value) => value.iconStyle.color)

  const graphicEditLabelAndIcon = map(dataWithoutTotal, (value) => {
    if (value.label === 'Taxes') {
      return { label: 'View', icon: 'globe' }
    }
    return { label: 'Edit', icon: 'pencil' }
  })

  const total = dataWithoutTotal.reduce((acc, curr) => acc + curr.rawValue, 0)

  const defaultData = [
    { y: 10 },
    { y: 10 },
    { y: 10 },
    { y: 10 },
    { y: 10 },
    { y: 10 },
    { y: 10 },
    { y: 10 }
  ]

  const [chartData, setChartData] = useState(defaultData)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [visible, setVisible] = useState(false)

  function handlePress(index) {
    setSelectedIndex(index)
    setVisible(true)
  }

  function handleEditPress() {
    const item = dataWithoutTotal[selectedIndex]
    const { route, params, title, subtitle, editable } = item

    navigation.navigate('Modal', {
      screen: route,
      params: { ...{ title, subtitle, editable, divisor, duration }, ...params }
    })

    setVisible(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setChartData(graphicData)
    }, 500)
  }, [data])

  const selectedLabel = graphicLabels[selectedIndex]
  const selectedData = dataWithoutTotal[selectedIndex]
  const selectedEditLabelAndIcon = graphicEditLabelAndIcon[selectedIndex]

  return (
    <View>
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <VictoryPie
          animate={{ easing: 'exp' }}
          data={chartData}
          width={isNotWeb ? window.width - 40 : 270}
          height={isNotWeb ? window.width - 40 : 270}
          colorScale={graphicColor}
          innerRadius={isNotWeb ? 90 : 55}
          labels={() => null}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onPressIn: () => [
                  {
                    target: 'data',
                    mutation: (props) => {
                      const { index } = props
                      handlePress(index)
                      return null
                    }
                  }
                ],
                onClick: () => [
                  {
                    target: 'data',
                    mutation: (props) => {
                      const { index } = props
                      handlePress(index)
                      return null
                    }
                  }
                ]
              }
            }
          ]}
        />

        <View
          style={{
            position: 'absolute',
            alignItems: 'center'
          }}
        >
          <Text
            style={{
              fontWeight: '500',
              fontSize: 24,
              letterSpacing: -1.03
            }}
          >
            {`$${formatCurrency(total)}`}
          </Text>
          <Text
            style={{
              fontWeight: '500',
              fontSize: 14,
              letterSpacing: 0
            }}
          >
            TOTAL
          </Text>
        </View>
      </View>

      <View
        style={{
          paddingHorizontal: DEFAULT_SPACING,
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        {map(graphicLabels, (label, index) => (
          <PieLabel
            label={label}
            color={graphicColor[index]}
            onPress={() => {
              handlePress(index)
            }}
          />
        ))}
      </View>

      <Modal
        visible={visible}
        setVisible={setVisible}
        closeable
        containerStyle={{ width: '80%' }}
        onClose={() => setVisible(false)}
        style={styles.modal}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: DEFAULT_SPACING
          }}
        >
          <HeaderLabel style={styles.modalHeader}>{selectedLabel}</HeaderLabel>
          <HeaderLabel style={styles.modalHeader}>
            ${formatCurrency(selectedData.rawValue)}
          </HeaderLabel>
        </View>

        <View style={{ paddingBottom: DEFAULT_SPACING, paddingHorizontal: DEFAULT_SPACING }}>
          <Text style={styles.modalText}>
            {`${formatPercent((selectedData.rawValue / total) * 100)} of your budget`}
          </Text>

          {selectedData.editable && (
            <Link
              containerStyle={{ justifyContent: 'flex-start' }}
              onPress={handleEditPress}
              iconColor={info}
              iconSize={12}
              icon={selectedEditLabelAndIcon.icon}
            >
              {selectedEditLabelAndIcon.label}
            </Link>
          )}
        </View>
      </Modal>
    </View>
  )
}

function PieLabel({ label, color, onPress }) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{ flexDirection: 'row', display: 'flex', width: '48%', marginBottom: DEFAULT_SPACING }}
    >
      <View
        style={{
          marginRight: DEFAULT_SPACING,
          backgroundColor: color,
          borderRadius: 15 / 2,
          height: 15,
          width: 15
        }}
      />
      <Text
        style={{
          fontSize: 12,
          color: grayblue,
          letterSpacing: 0
        }}
      >
        {label}
      </Text>
    </TouchableOpacity>
  )
}

function IconDataTable({ data, duration, divisor }) {
  return (
    <View>
      {map(data, (rowProps) => (
        <IconDataRow key={rowProps.label} {...rowProps} duration={duration} divisor={divisor} />
      ))}
    </View>
  )
}

export default function LivingEssentialsCard({
  loading,
  result,
  duration,
  setDuration,
  navigation
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [isDonut, setIsDonut] = useState(false)

  const options = [
    {
      label: 'Weekly',
      inverted: true,
      active: duration === 'weekly',
      onPress: () => setDuration('weekly')
    },
    {
      label: 'Monthly',
      inverted: true,
      active: duration === 'monthly',
      onPress: () => setDuration('monthly')
    },
    {
      label: 'Annually',
      inverted: true,
      active: duration === 'annual',
      onPress: () => setDuration('annual')
    }
  ]

  let divisor = 1
  if (duration == MONTHLY) {
    divisor = 12
  } else if (duration == WEEKLY) {
    divisor = 52
  }

  const annualGoal = (result.annual_contribution_amount || 0) / divisor
  const taxes = (result.taxes && result.taxes[duration] && result.taxes[duration].total) || 0
  const studentLoans =
    (result.student_loan_payment && result.student_loan_payment.cost[duration]) || 0

  const medicalInsurance =
    (result.healthcare_cost &&
      result.healthcare_cost.cost &&
      result.healthcare_cost.cost[duration]) ||
    0
  const housingExpenses =
    (result.housing_cost && result.housing_cost.cost && result.housing_cost.cost[duration]) || 0
  const utilities =
    (result.utilities_cost && result.utilities_cost.cost && result.utilities_cost.cost[duration]) ||
    0
  const autoExpenses =
    (result.transportation_cost &&
      result.transportation_cost.cost &&
      result.transportation_cost.cost[duration]) ||
    0
  const food = (result.food_cost && result.food_cost.cost && result.food_cost.cost[duration]) || 0

  const data = [
    {
      label: 'My Save First Goal',
      tooltipBody:
        result.contribution_selection === PENSION
          ? "This amount reflects the employer-set percentage of an employee's salary, automatically deducted each pay cycle for pension contributions. The specified percentage might differ depending on the plan's guidelines and employer policies. If the plan lacks a mandatory contribution, the value could be $0."
          : 'Saving is the first item in a well built budget.  This is the estimated amount you should save each month to stay on track towards achieving your goal(s). Let your saving dictate your spending, not the opposite.',
      value: loading ? '—' : `$${formatCurrency(annualGoal)}`,
      rawValue: annualGoal,
      color: primary,
      backgroundColor: 'rgba(30, 184, 110, 0.05)',
      iconStyle: {
        icon: 'chart-line',
        color: primary,
        backgroundColor: 'rgba(70, 186, 122, 0.1)'
      }
    },
    {
      label: 'Taxes',
      value: loading ? '—' : `$${formatCurrency(taxes)}`,
      rawValue: taxes,
      route: 'Taxes Breakdown',
      title: 'Taxes Breakdown',
      subtitle: 'View how taxes are computed',
      editable: true,
      iconStyle: {
        icon: 'chart-pie',
        color: 'rgb(87,187,227)',
        backgroundColor: 'rgba(87,187,227, 0.1)'
      }
    },
    {
      label: 'Student Loans',
      value: loading ? '—' : `$${formatCurrency(studentLoans)}`,
      rawValue: studentLoans,
      route: 'Edit Student Loans',
      title: 'Student Loans',
      params: {
        loan_balance: result.loan_balance,
        loan_rate: result.loan_rate,
        loan_period: result.loan_period,
        have_loans: result.have_loans,
        loan_national_average: result.loan_national_average
      },
      subtitle: 'Add an estimate of your student loans',
      editable: true,
      iconStyle: {
        icon: 'graduation-cap',
        color: 'rgb(255, 201, 77)',
        backgroundColor: 'rgba(255, 201, 77, 0.1)'
      }
    },
    {
      label: 'Medical Insurance',
      value: loading ? '—' : `$${formatCurrency(medicalInsurance)}`,
      rawValue: medicalInsurance,
      route: 'Edit Medical Insurance',
      params: {
        custom_healthcare_cost: result.custom_healthcare_cost,
        healthcare_selection: result.healthcare_selection
      },
      title: 'Medical/Health Insurance',
      subtitle: 'Add an estimate of your medical/health insurance',
      editable: true,
      iconStyle: {
        icon: 'heart',
        color: 'rgb(255, 61, 99)',
        backgroundColor: 'rgba(255, 61, 99, 0.1)'
      }
    },
    {
      label: 'Housing',
      value: loading ? '—' : `$${formatCurrency(housingExpenses)}`,
      rawValue: housingExpenses,
      route: 'Edit Housing',
      params: { custom_housing_cost: result.custom_housing_cost },
      title: 'Housing',
      subtitle: 'Add an estimate of your housing',
      editable: true,
      iconStyle: {
        icon: 'house',
        color: 'rgb(66, 86, 103)',
        backgroundColor: 'rgba(66, 86, 103, 0.1)'
      }
    },
    {
      label: 'Utilities',
      value: loading ? '—' : `$${formatCurrency(utilities)}`,
      rawValue: utilities,
      route: 'Edit Utilities',
      params: { custom_utilities_cost: result.custom_utilities_cost },
      title: 'Utilities',
      subtitle: 'Add an estimate of your utility costs',
      editable: true,
      iconStyle: {
        icon: 'bolt',
        color: 'rgb(255, 103, 77)',
        backgroundColor: 'rgba(255, 103, 77, 0.1)'
      }
    },
    {
      label: 'Transportation',
      value: loading ? '—' : `$${formatCurrency(autoExpenses)}`,
      rawValue: autoExpenses,
      route: 'Edit Transportation',
      params: { custom_auto_cost: result.custom_auto_cost },
      title: 'Transportation',
      subtitle: 'Add an estimate of your transportation costs',
      editable: true,
      iconStyle: {
        icon: 'car',
        color: 'rgb(118, 88, 227)',
        backgroundColor: 'rgba(118, 88, 227, 0.1)'
      }
    },
    {
      label: 'Food',
      value: loading ? '—' : `$${formatCurrency(food)}`,
      rawValue: food,
      route: 'Edit Food',
      params: { custom_food_cost: result.custom_food_cost },
      title: 'Food',
      subtitle: 'Add an estimate of your food costs',
      editable: true,
      iconStyle: {
        icon: 'utensils',
        color: gunmetal,
        backgroundColor: rgba(gunmetal, 0.1)
      }
    },
    {
      label: 'TOTAL',
      value: loading
        ? '—'
        : `$${formatCurrency(
            annualGoal +
              taxes +
              medicalInsurance +
              housingExpenses +
              utilities +
              autoExpenses +
              food +
              studentLoans
          )}`
    }
  ]

  return (
    <PlanSummaryCard
      title="Living Essentials"
      subtitle="The things you can’t live without: the median Cost of Living expenses for your selected location"
    >
      <TouchableOpacity
        style={{
          zIndex: 100,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(133, 151, 165, 0.55)',
          borderRadius: 30 / 2,
          width: 30,
          height: 30,
          position: 'absolute',
          top: 10,
          right: 10
        }}
        onPress={() => {
          setIsDonut(!isDonut)
          hapticFeedback()
        }}
      >
        <FontAwesome5 name={isDonut ? 'list' : 'chart-pie'} size={12} color="white" />
      </TouchableOpacity>

      <PillGroup options={options} />

      {isDonut && (
        <DataDonut
          data={data}
          navigation={navigation}
          loading={loading}
          duration={duration}
          divisor={divisor}
        />
      )}
      {!isDonut && (
        <IconDataTable
          data={data}
          navigation={navigation}
          loading={loading}
          duration={duration}
          divisor={divisor}
        />
      )}

      <Text style={[styles.disclaimer, { fontSize: 10, marginTop: 24 }]}>
        *Source: United States Bureau of Labor Statistics latest figures
      </Text>
    </PlanSummaryCard>
  )
}
