import { View, Text, StyleSheet } from 'react-native'
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme } from 'victory-native'
import formatCurrency from 'functions/formatCurrency'
import Legend from 'components/Legend'
import { darkblue, gunmetal } from '../../styles'

const styles = StyleSheet.create({
  buttonContainer: { 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },  
  header: {
    fontSize: 28,
    color: darkblue,
    fontWeight: '600',
    marginTop: 12
  }, 
  subheader: {
    fontSize: 14, 
    color: gunmetal
  }
})

export default function NetTotalBarChart({ data }) {
  const legendOptions = data.map((account) => ({
    text: account.label,
    color: account.color,
    dotSize: 14,
    textStyle: {
      fontSize: 12,
      color: darkblue
    }
  }))

  const netTotal = data.find((account) => account.label === 'Net Total')
  const netTotalValue = netTotal ? netTotal.absVal : 0  

  return (
    <View style={styles.barChartContainer}>
      <View style={styles.headerContainer}>
        <Text style={styles.header}>{formatCurrency(netTotalValue, { showDollarSign: true })}</Text>
        <Text style={styles.subheader}>NET WORTH</Text>
      </View>

      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={80}
        padding={{ top: 40, bottom: 40, left: 0, right: 70 }}
        height={300}
      >
        <VictoryAxis
          dependentAxis
          tickFormat={(t) => 
            formatCurrency(t, { showDollarSign: true })
          }
        />
        <VictoryBar
          horizontal
          data={data}
          x="label"
          y="absVal"
          barRatio={0.9}
          labelComponent={<></>}
          style={{
            data: {
              fill: ({ datum }) => datum.color
            }
          }}
        />
      </VictoryChart>

      <Legend 
        options={legendOptions.reverse()}
      />
    </View>      
  )
}
