import { stateReducer } from '@truefit/redux-utils'
import { SET_LIFE_EVENTS, CLEAR } from '../actions'

const initialState = {}

export default stateReducer(initialState, {
  [SET_LIFE_EVENTS]: (state, payload) => {
    const { planId, events } = payload

    if (!state[planId]) state[planId] = []
    state[planId] = events
    return { ...state }
  },
  [CLEAR]: () => initialState
});
