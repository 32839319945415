import { get } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { setFinPulsePreferences } from './setFinPulsePreferences'

// eslint-disable-next-line import/prefer-default-export
export const getFinPulsePreferences = () => async (dispatch) => {
  try {
    const { data } = await get(proApplicationEndpoints.getFinPulsePreferences())
    dispatch(setFinPulsePreferences(data))
  } catch (e) {
    console.warn(e, e.response)
  }
}
