import { useEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { useDispatch, useSelector } from 'react-redux'
import { get } from 'http'
import HeaderLabel from './components/HeaderLabel'
import Button from './components/Button'
import InformationBlock from './components/InformationBlock'

import { primaryPlanSelector } from './selectors'

import formatCurrency from './functions/formatCurrency'

import { setLoading } from './actions'

import { darkblue, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    padding: DEFAULT_SPACING
  },
  emergencyFinn: {
    height: 286,
    width: 286,
    marginBottom: 24
  },
  headerLabel: {
    marginBottom: 24,
    textAlign: 'center',
    fontSize: 16,
    letterSpacing: -0.48
  },
  text: {
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center'
  },
  expense: {
    fontWeight: '500',
    fontSize: 48,
    letterSpacing: 0,
    textAlign: 'center',
    color: darkblue,
    marginTop: 100
  },
  expenseLabel: {
    fontWeight: '500',
    fontSize: 14,
    letterSpacing: 0,
    textAlign: 'center',
    color: darkblue
  },
  bottomButtonStyle: {
    justifyContent: 'flex-end',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING,
    backgroundColor: white
  }
})

export default function EmergencyPreparednessMonthlyExpensesScreen({ route, navigation }) {
  const dispatch = useDispatch()
  const primaryPlan = useSelector((state) => primaryPlanSelector(state))

  const [result, setResult] = useState({})

  async function fetchAndSetData() {
    dispatch(setLoading({ loading: true }))
    const response = await get(`api/v1/plans/${primaryPlan.id}/`)
    setResult({ ...primaryPlan, ...response.data })
    dispatch(setLoading({ loading: false }))
  }

  useEffect(() => {
    if (primaryPlan) fetchAndSetData(primaryPlan)
  }, [primaryPlan])

  const duration = 'annual'

  const studentLoans = (result.student_loan_payment && result.student_loan_payment.cost[duration]) || 0

  const medicalInsurance = (result.healthcare_cost && result.healthcare_cost.cost && result.healthcare_cost.cost[duration]) || 0
  const housingExpenses = (result.housing_cost && result.housing_cost.cost && result.housing_cost.cost[duration]) || 0
  const utilities = (result.utilities_cost && result.utilities_cost.cost && result.utilities_cost.cost[duration]) || 0
  const autoExpenses = (result.transportation_cost && result.transportation_cost.cost && result.transportation_cost.cost[duration]) || 0
  const food = (result.food_cost && result.food_cost.cost && result.food_cost.cost[duration]) || 0

  const totalExpense = food + utilities + studentLoans + medicalInsurance
    + autoExpenses + housingExpenses

  return (
    <View style={{ backgroundColor: white, flex: 1 }}>
      <HeaderLabel style={styles.headerLabel}>
        Monthly Expenses
      </HeaderLabel>
      <Text style={styles.text}>
        First, let’s take a look at your budget
        projections. Based on your default plan, this is
        an estimate of your monthly expenses. This
        <Text style={{ fontWeight: '800' }}> does not include </Text>
        items such as taxes, savings goal or discretionary spending.
      </Text>
      <View style={styles.container}>
        <Text style={styles.expense}>
          $
          {formatCurrency(totalExpense / 12)}
        </Text>
        <Text style={styles.expenseLabel}>Monthly Expenses</Text>
        <InformationBlock text="We love saving and investing but it’s hard to do if you are out of work and need to make ends meet!" />
      </View>
      <View style={styles.bottomButtonStyle}>
        <Button onPress={() => {
          navigation.navigate('Modal', { screen: 'Emergency Preparedness Emergency Funds' })
        }}
        >
          Next
        </Button>
      </View>
    </View>
  );
}
