import { isNull, isUndefined, each, sortBy } from 'lodash'
import axios from 'axios'

const MAX_RESULTS = 50

async function getPlaylistByPage(playlistId, pageToken) {
  let url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=${MAX_RESULTS}&playlistId=${playlistId}&key=AIzaSyAy5Mkun2RcEkJ-mLBwwk43PKr0MaKkIcs`
  if (pageToken) {
    url += `&pageToken=${pageToken}`
  }
  const response = await axios.get(url)
  const { items } = response.data
  const { nextPageToken } = response.data
  return { items, nextPageToken: nextPageToken || null }
}

export async function getPlaylist(playlistId) {
  try {
    let items = []
    let nextPageToken

    while (isUndefined(nextPageToken) || !isNull(nextPageToken)) {
      const result = await getPlaylistByPage(playlistId, nextPageToken)
      nextPageToken = result.nextPageToken
      items = [...items, ...result.items]
    }

    return items.reverse()
  } catch (e) {
    console.warn(e, e.response)
  }
}
