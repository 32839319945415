import { useEffect, useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { useIsFocused } from '@react-navigation/native'
import { find, isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'http'
import DashboardBlock from './DashboardBlock'

import MyPlanCard from './MyPlanCard'
import ExploreCard from './ExploreCard'
import ListOptionItem from '../ListOptionItem'

import {
  plansSelector,
  accountsSelector,
  loansSelector,
  wellnessChecklistsSelector,
  completedWellnessChecklistsSelector,
  riskAssessmentSelector
} from '../../selectors'

import { getAccounts, getCurrentUser, setCurrentPlan, setCurrentProPlan } from '../../actions'

import { gray, primary, rgba } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

import formatCurrency from '../../functions/formatCurrency'
import WellnessToolsList from '../WellnessToolsList'

const basicStyles = {
  container: { padding: DEFAULT_SPACING, paddingTop: 420 },
  item: {},
  divider: {
    borderBottomColor: gray,
    opacity: 0.2,
    margin: DEFAULT_SPACING * 2
  },
  listItem: { marginHorizontal: DEFAULT_SPACING }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.SM)]: { container: { paddingTop: 0 } },
  [minSize(DEVICE_SIZES.LG)]: {
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingHorizontal: DEFAULT_SPACING
    },
    item: {
      width: '32.5%',
      justifyContent: 'space-between'
    },
    listItem: { marginHorizontal: 0 }
  }
})

function findPrimaryPlan(plans) {
  const primaryPlan = find(plans, (plan) => plan.is_primary_plan)
  return primaryPlan
}

export default function PostAuthScreen({ navigation, currentUser, pro = false }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const [primaryPlan, setPrimaryPlan] = useState({})
  const plans = useSelector((state) => plansSelector(state))
  const currentAccounts = useSelector((state) => accountsSelector(state))
  const loans = useSelector((state) => loansSelector(state))
  const totalLoanCost = loans.reduce((acc, loan) => acc + parseFloat(loan.balance), 0)
  const isFocused = useIsFocused()
  const { assessScore } = useSelector((state) => riskAssessmentSelector(state))

  const [emergencyPrepardness, setEmergencypreparedness] = useState({})

  async function fetchPrimaryPlan() {
    if (!currentUser) return
    if (!currentUser.primary_plan) return
    if (!currentUser.primary_plan.id) return

    try {
      const primaryPlanResponse = await get(`/api/v1/plans/${currentUser.primary_plan.id}/`)
      const plan = findPrimaryPlan(plans)

      setPrimaryPlan({ ...plan, ...primaryPlanResponse.data })

      const emergencyPreparednessResponse = await get('/emergency-preparedness/')
      setEmergencypreparedness(emergencyPreparednessResponse.data)
    } catch (e) {
      console.warn(e, e.response)
    }
  }

  useEffect(() => {
    if (isFocused) {
      fetchPrimaryPlan()
      dispatch(getCurrentUser())
    }
  }, [isFocused])

  useEffect(() => {
    dispatch(getAccounts())
  }, [currentUser])

  let circleColor = 'white'

  if (emergencyPrepardness.status === 'LEVEL_2') {
    circleColor = '#FBBD29'
  } else if (emergencyPrepardness.status === 'LEVEL_3') {
    circleColor = '#43CF62'
  } else {
    circleColor = '#F2285B'
  }

  let status = emergencyPrepardness.status ? emergencyPrepardness.status : 'LEVEL_0'
  status = status.replace('_', ' ')
  const { months_of_expenses_covered } = emergencyPrepardness

  const wellnessItems = useSelector((state) => wellnessChecklistsSelector(state))
  const completedItems = useSelector((state) => completedWellnessChecklistsSelector(state))

  const careerCard = (
    <MyPlanCard
      navigation={navigation}
      monthlyContribution={primaryPlan.annual_contribution_amount / 12}
      goalAmount={primaryPlan.savings_goal}
      onPress={() => {
        dispatch(setCurrentPlan(primaryPlan))
        dispatch(setCurrentProPlan(null))
        navigation.navigate('Modal', { screen: 'Plan Summary' })
      }}
    />
  )

  const wellnessCheck = (
    <DashboardBlock
      label="Wellness Check"
      statusLabel={wellnessItems.length == completedItems.length ? 'On Track' : 'Do Better'}
      icon="dove"
      backgroundColor="rgba(143, 114, 244, 0.1)"
      color="rgb(143, 114, 244)"
      onPress={() => navigation.navigate('Modal', { screen: 'Wellness Checklist Intro' })}
    />
  )

  const emergencyPreparedness = (
    <DashboardBlock
      label="Emergency Preparedness"
      statusLabel={`${status || 'LEVEL 0'}`}
      icon="fire"
      backgroundColor={circleColor}
      color="white"
      onPress={() => {
        if (currentAccounts.length > 0) {
          navigation.navigate('Modal', { screen: 'Emergency Preparedness' })
        } else {
          navigation.navigate('Modal', { screen: 'Emergency Preparedness Intro' })
        }
      }}
    />
  )

  const debtRanking = (
    <ListOptionItem
      label="Debt Ranking"
      sublabel={
        loans.length > 0
          ? `Total Debt: $${formatCurrency(totalLoanCost)}`
          : 'Rank your debt and create a cost-effective repayment strategy'
      }
      style={styles.listItem}
      iconName="ranking-star"
      iconColor={primary}
      iconType="fas"
      iconSize={isNotWeb ? 30 : 43}
      iconBackgroundColor={rgba(primary, 0.1)}
      onPress={() => navigation.navigate('Modal', { screen: 'My Debts' })}
      navigatable
    />
  )

  return (
    <View style={styles.container}>
      {primaryPlan.id && careerCard}
      <WellnessToolsList
        navigation={navigation}
        isBeta={currentUser?.is_beta}
        selfAssessed={assessScore.is_self_assessed}
        blankAssessment={isEmpty(assessScore)}
        loansLength={loans.length}
        totalLoanCost={totalLoanCost}
        status={status}
        monthsOfExpensesCovered={months_of_expenses_covered}
      />
      <ExploreCard navigation={navigation} />
    </View>
  )
}
