import { View, StyleSheet, Platform, Text } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

import { primary, white } from '../styles/colors'

import hapticFeedback from './hapticFeedback'
import CircleCheckBox from './CircleCheckBox'
import TouchableOpacity from './TouchableOpacity'

import { DEFAULT_SPACING, red } from '../styles'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: DEFAULT_SPACING,
    width: '100%'
  },
  checkContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    flex: 1,
    marginLeft: DEFAULT_SPACING
  },
  error: {
    color: red,
    marginBottom: 12,
    fontSize: 12
  },
  check: {
    color: white,
    fontWeight: '600',
    position: 'absolute',
    ...Platform.select({
      native: {
        shadowColor: 'rgba(40, 41, 61, 0.24)',
        shadowOffset: {
          width: 0,
          height: 1
        },
        shadowRadius: 3,
        shadowOpacity: 1
      },
    })
  }
});

export default function _CheckBox(props) {
  const { checked, value, label, onToggle, style, touched, errorMessage } = props

  function handleToggle() {
    onToggle(!checked)
    hapticFeedback()
  }

  return (
    <>
      <TouchableOpacity style={[styles.container, label ? {} : { width: 'auto', marginBottom: 0 }]} onPress={handleToggle}>
        <View style={[styles.checkContainer, style]}>
          <CircleCheckBox
            checked={value}
            filterColor="#e9eced"
            outerColor="#e9eced"
            innerColor={primary}
            innerSize={24}
            outerSize={24}
            filterSize={40}
            onPress={handleToggle}
          />
          {value && <Ionicons name="ios-checkmark" size={20} color="white" style={styles.check} />}
        </View>
        { label && <Text style={styles.label}>{label}</Text> }
      </TouchableOpacity>
      { touched && errorMessage && <Text style={styles.error}>{errorMessage}</Text> }
    </>
  )
}
