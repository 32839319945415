import { useLayoutEffect } from 'react'
import { Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { isEmpty } from "lodash"
import { post } from 'http'

import { Fields, InitialValues, ValidationSchema } from "./schemas/joinTeamSchema"
import { currentUserSelector } from "./selectors"
import Form from './components/Form'
import CodeInput from './components/CodeInput'
import { darkblue, info, secondary, white } from "./styles"
import AvatarGroup from './components/teamOnboarding/AvatarGroup'
import Container from './components/Container'
import BackButton from './components/teamOnboarding/BackButton'

const basicStyles = {
  groups: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 36,
  },
  group: {
    width: 88,
    height: 88,
    borderRadius: 44,
    backgroundColor: '#5F8EE880',
    justifyContent: 'center',
    alignItems: 'center'
  },
  groupIcon: {
    width: 72,
    height: 72,
    borderRadius: 36,
    backgroundColor: white,
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: { marginTop: 44, alignItems: 'center', justifyContent: 'center', },
  title: {
    fontSize: 28,
    lineHeight: 33,
    fontWeight: '600',
    textAlign: 'center',
    color: darkblue,
  },
  description: {
    textAlign: 'center',
    maxWidth: 288,
    fontSize: 16,
    lineHeight: 19,
    marginTop: 16,
    fontWeight: '500',
    color: secondary,
  },
  footer: { alignItems: 'center', paddingTop: 56, },
  footerTxt: { fontSize: 16, fontWeight: '500', lineHeight: 19, color: secondary, },
  skipTxt: { fontSize: 19, fontWeight: '600', lineHeight: 21, marginTop: 8, color: info },
  button: {},
  container: { flex: 1 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      group: {
        width: 118,
        height: 118,
        borderRadius: 59,
      },
      groupIcon: {
        width: 96,
        height: 96,
        borderRadius: 48,
      },
      header: { marginTop: 32, },
      title: {
        fontSize: 38,
        lineHeight: 45,
      },
      description: { fontSize: 18, lineHeight: 21, marginTop: 14, },
      footer: { paddingTop: 24, paddingBottom: 0, },
      button: {
        maxWidth: 345,
        marginHorizontal: 'auto',
        marginBottom: 34
      },
      container: { maxHeight: 526, overflow: 'auto', }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function JoinTeamScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const currentUser = useSelector((state) => currentUserSelector(state))
  const sponsors = !isEmpty(currentUser.sponsors) ? currentUser.sponsors : []
  const isOnboarding = route.params && route.params.isOnboarding

  useLayoutEffect(() => {
    navigation.setOptions({
      title: null,
      headerTransparent: true,
      headerLeft: () => (<BackButton onPress={() => navigation.pop()} />)
    })
  }, [navigation])

  const handlePress = () => {
    navigation.reset({
      key: null,
      index: 0,
      routes: [{ name: 'Main' }],
    })
  }

  return (
    <Container card={true} style={{ marginTop: isNotWeb ? 160 : 'auto' }}>
      <Form
        withPadding={false}
        buttonText={isOnboarding ? "Continue" : 'Join Team'}
        buttonContainerStyle={styles.button}
        enableReinitialize
        validationSchema={ValidationSchema}
        initialValues={InitialValues}
        onSubmit={async (values, actions) => {
          try {
            const response = await post('api/v1/premium/organization/join/', values)

            const invitation = {
              organization_logo: response.data.organization.logo_url,
              organization_name: response.data.organization.name,
              recipient_name: `${currentUser.first_name} ${currentUser.last_name}`,
              passcode: values.passcode
            }

            if (response.data.connected) {
              actions.setFieldError(Fields.passcode, 'You are already connected to this team')
            } else {
              navigation.navigate('Modal', { screen: 'Join Team Welcome', params: { invitation } })
            }
          } catch (e) {
            actions.setFieldError(Fields.passcode, e.response.data.passcode)
          }
        }}
      >{({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
        <View style={styles.container}>
          <View style={styles.groups}>
            <AvatarGroup avatars={sponsors.map(({ logo_url }) => logo_url)} maxVisible={2} />
            <View style={styles.group}>
              <View style={styles.groupIcon}>
                <FontAwesomeIcon icon={['fas', 'people-group']} size={42} color={darkblue} />
              </View>
            </View>
          </View>
          <View style={styles.header}>
            <Text style={styles.title}>Join Team</Text>
            <Text style={styles.description}>Enter your team's passcode to join your sponsored organization.</Text>
            <CodeInput
              name={Fields.passcode}
              errorMessage={errors[Fields.passcode]}
              touched={touched[Fields.passcode]}
              onChangeText={handleChange('passcode')}
              onBlur={handleBlur('code')}
              value={values.passcode}
              onSubmitEditing={() => handleSubmit()}
            />
          </View>
          {isOnboarding && (<View style={styles.footer}>
            <Text style={styles.footerTxt}>Don't have this one? You can add this later.</Text>
            <TouchableOpacity onPress={handlePress}>
              <Text style={styles.skipTxt}>Skip This Step</Text>
            </TouchableOpacity>
          </View>)}
        </View>
      )}
      </Form>
    </Container>
  )
}
