import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import Modal from './Modal.web'

const useStyles = CreateResponsiveStyle(
  {
    modal: {
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      padding: 24,
      borderRadius: 8,
      resizeMode: true,
    },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      modal: {
        width: '60%',
        height: 'auto',
        maxWidth: 600,
        maxHeight: 'calc(100vh - 80px)',
      },
    },
  }
)

function SlideupDrawer({ visible, setVisible, children }) {
  const styles = useStyles()

  return (
    <Modal
      visible={visible}
      onClose={() => setVisible(false)}
      style={styles.modal}
    >
      {children}
    </Modal>
  )
}

export default SlideupDrawer
