import { View, StyleSheet, Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import Container from 'components/Container'
import NumberInput from 'components/NumberInput'
import CurrencyInput from 'components/CurrencyInput'
import Form from 'components/Form'
import RadioGroup from 'components/RadioGroup'

import { ValidationSchema, Fields, InitialValues } from 'schemas/loanSchema'

import { saveLoan, deleteLoan } from 'actions'

import { DEFAULT_SPACING, white, darkblue, red } from 'styles'

const basicStyles = {
  container: {
    paddingHorizontal: DEFAULT_SPACING,
    backgroundColor: white,
    flex: 1
  },
  header: {
    fontWeight: '600',
    fontSize: 20,
    color: darkblue,
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING
  },
  button: { marginTop: DEFAULT_SPACING },
  deleteButton: {
    backgroundColor: red,
    marginTop: 24
  },
  input: {
    backgroundColor: 'rgba(242, 248, 250, 0.9)',
    marginBottom: 12
  },
  buttonGroup: { display: 'flex' },
  cancelButton: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    container: {
      paddingTop: 0,
      paddingHorizontal: 16
    },
    header: {
      textAlign: 'left',
      fontSize: 28
    },
    buttonGroup: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: DEFAULT_SPACING
    },
    button: { width: 210 },
    cancelButton: {
      color: '#4F8FF0',
      fontSize: 18
    }
  }
})

export default function DebtRankForm({ navigation, route }) {
  const { params } = route
  const { debt } = params || { debt: {} }
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()

  const typeOptions = [
    { leftText: 'Federal Student Loan', value: 'FEDERAL' },
    { leftText: 'Federal Student Loan', value: 'FEDERAL' },
    { leftText: 'Private Student Loan', value: 'PRIVATE' },
    { leftText: 'Mortgage', value: 'MORTGAGE' },
    { leftText: 'Auto', value: 'AUTO' },
    { leftText: 'Credit Card', value: 'CREDIT_CARD' },
    { leftText: 'Personal Loan', value: 'PERSONAL' }
  ]

  async function onNegativeButtonPress() {
    await dispatch(deleteLoan(debt))
    navigation.popToTop()
  }

  return (
    <Container card={true} style={styles.container}>
      <Form
        validationSchema={ValidationSchema}
        withPadding
        initialValues={debt.id ? debt : InitialValues}
        buttonText={debt.id ? 'Update Account' : 'Add Account'}
        negativeButtonText={debt.id ? 'Remove Account' : null}
        onNegativeButtonPress={debt.id ? onNegativeButtonPress : null}
        onSubmit={async (values) => {
          await dispatch(saveLoan(values))
          navigation.popToTop()
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, touched, errors }) => (
          <View style={{ flex: 1 }}>
            <RadioGroup
              name={Fields.category}
              errorMessage={errors[Fields.category]}
              touched={touched[Fields.category]}
              label="Loan Type"
              value={values[Fields.category]}
              options={typeOptions}
              onPress={(value) => {
                if (value) {
                  handleChange(Fields.category)(value)
                }
              }}
            />

            <CurrencyInput
              type="money"
              unit="$"
              name={Fields.balance}
              errorMessage={errors[Fields.balance]}
              touched={touched[Fields.balance]}
              onChangeText={handleChange(Fields.balance)}
              onBlur={handleBlur(Fields.balance)}
              value={values[Fields.balance]}
              label="Loan Balance"
              keyboardType="number-pad"
              returnKeyType="next"
              autoComplete="off"
              // onSubmitEditing={() => rateField.current.focus()}
            />

            <NumberInput
              precision={3}
              suffixUnit="%"
              // ref={rateField}
              name={Fields.interestRate}
              errorMessage={errors[Fields.interestRate]}
              touched={touched[Fields.interestRate]}
              onChangeText={handleChange(Fields.interestRate)}
              onBlur={handleBlur(Fields.interestRate)}
              value={values[Fields.interestRate]}
              label="Interest Rate (%)"
              returnKeyType="next"
              keyboardType="number-pad"
              // onSubmitEditing={() => periodField.current.focus()}
            />
          </View>
        )}
      </Form>
    </Container>
  )
}
