import { View, ScrollView, Image, useWindowDimensions, } from 'react-native'
import { map } from 'lodash'
import hapticFeedback from './components/hapticFeedback'
import { lightestgray } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import TouchableOpacity from './components/TouchableOpacity'

function VerticalOptionItem({ image, pdf, title, navigation, img_url }) {
  const window = useWindowDimensions()
  const width = window.width * 0.42

  return (
    <TouchableOpacity onPress={() => {
      hapticFeedback()
      navigation.navigate('Modal', { screen: 'Library Book', params: { pdf, title, img_url } })
      // navigation.navigate('Modal', { screen: 'Library Item', params: { pdf, title }})
    }}
    >
      <Image source={image} resizeModal="contain" style={{ borderRadius: 6, width, height: 240 }} />
    </TouchableOpacity>
  )
}

export default function LibraryBooksScreen({ navigation }) {
  const books = [{
    img_url: './assets/missing-semester.png',
    image: require('./assets/missing-semester.png'),
    // pdf: 'https://troutwood-static-files.s3.us-east-2.amazonaws.com/missing-semester.epub',
    pdf: 'https://troutwood-static-files.s3.us-east-2.amazonaws.com/missing-semester.pdf',
    title: 'The Missing Semester',
  }, {
    img_url: './assets/missing-2nd-semester.png',
    image: require('./assets/missing-2nd-semester.png'),
    // pdf: 'https://troutwood-static-files.s3.us-east-2.amazonaws.com/missing-second-semester.epub',
    pdf: 'https://troutwood-static-files.s3.us-east-2.amazonaws.com/missing-2nd-semester.pdf',
    title: 'The Missing 2nd Semester',
  }]

  return (
    <View style={{ backgroundColor: lightestgray, position: 'relative' }}>
      <ScrollView style={{ paddingTop: DEFAULT_SPACING, paddingHorizontal: DEFAULT_SPACING }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {map(books, (book) => <VerticalOptionItem {...book} navigation={navigation} key={book.title} />)}
        </View>

        {/* This is here to ensure that the bottom of the list isn't cutoff */}
        <View style={{ height: 400 }} />
      </ScrollView>
    </View>
  );
}
