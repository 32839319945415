import { useState, useLayoutEffect } from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import { groupBy, capitalize } from 'lodash'
import { LinearGradient } from 'expo-linear-gradient'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import Link from './components/Link'

import CategoryGroup from './components/wellness/CategoryGroup'
import CompletedCategoryGroup from './components/wellness/CompletedCategoryGroup'

import CongratsSlideupDrawer from './components/CongratsSlideupDrawer'
import WellnessQuizWizard from './components/wellness/WellnessQuizWizard'

import { wellnessChecklistsSelector, currentUserSelector } from './selectors'

import { saveMetaData } from './actions'
import { DEFAULT_SPACING, lightestgray, white, rgba } from './styles'
import GetWellnessSlideupDrawer from './components/GetWellnessSlideupDrawer'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: lightestgray
  },
  contentContainer: { paddingBottom: 60 },
  subtitle: {
    color: white,
    textAlign: 'center',
    width: 200,
    justifyContent: 'center',
    marginTop: 10,
    fontWeight: '700',
    paddingBottom: DEFAULT_SPACING * 2
  },
  linearGradient: {
    width: '100%',
    position: 'absolute',
    zIndex: 0,
    height: '100%'
  },
  headerLabel: {
    marginVertical: DEFAULT_SPACING,
    marginBottom: 10,
    fontSize: 15,
    position: 'relative',
    color: white
  },
  editButton: {
    color: '#4F8FEF',
    paddingRight: 10
  },
  headerCompleted: {
    fontSize: 20,
    color: '#2F3946',
    textAlign: 'left',
    margin: 10,
    fontWeight: '600'
  },
  iconContainer: {
    alignItems: 'center',
    position: 'relative'
  },
  progressContainer: {
    bottom: 5,
    margin: 0,
    justifyContent: 'center',
    left: 5,
    right: 5
  }
})

export default function WellnessCheckScreen({ navigation, route }) {
  const dispatch = useDispatch()
  const { category } = route.params

  const wellnessChecklist = useSelector((state) =>
    wellnessChecklistsSelector(state)
  )
  const groupedWellnessChecklist = groupBy(
    wellnessChecklist,
    'category_description'
  )
  const currentUser = useSelector((state) => currentUserSelector(state))
  const { metadata } = currentUser

  const metadataKey = `TROUTWOOD_WELLNESS_WIZARD_${category.description.toUpperCase()}_COMPLETED`

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: !!metadata[metadataKey],
      title: capitalize(category.description),

      headerStyle: {
        height: 120,
        elevation: 0,
        shadowOpacity: 0,
        backgroundColor: category.backgroundColorGradientTop
      },
      headerTitleStyle: {
        fontSize: 24,
        color: white,
        textAlign: 'justify',
        marginTop: -10
      },
      headerSubtitleStyle: {
        fontSize: 14,
        textAlign: 'center',
        fontWeight: '400',
        letterSpacing: -0.48,
        marginTop: 2,
        color: white
      }
    })
  }, [navigation, metadata])
  const [visible, setVisible] = useState(true)
  const [currentGetWellness, setCurrentGetWellness] = useState(null)

  const [
    currentWellnessChecklistItemPopupText,
    setCurrentWellnessChecklistItemPopupText
  ] = useState(null)
  const completeWellnessChecklist = groupedWellnessChecklist[
    category.description
  ].filter((base) => base.is_complete === true)
  const incompleteWellnessChecklist = groupedWellnessChecklist[
    category.description
  ].filter((base) => base.is_complete !== true)

  if (metadata[metadataKey]) {
    return (
      <View style={{ flex: 1 }}>
        <LinearGradient
          colors={[
            category.backgroundColorGradientTop,
            category.backgroundColorGradientBottom
          ]}
          style={[styles.linearGradient]}
          locations={[0, 1]}
        />
        <View style={styles.iconContainer}>
          <FontAwesomeIcon
            icon={['fas', category.icon]}
            color={rgba(white, 0.6)}
            size={90}
          />
        </View>

        <View style={styles.progressContainer}>
          <View style={[styles.iconContainer, { marginTop: 30 }]}>
            <FontAwesomeIcon icon={faQuoteRight} color={white} size={15} />
            <Text style={[styles.subtitle]}>
              Check off any items that you have completed.
            </Text>
          </View>
        </View>

        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        >
          <CategoryGroup
            onCheck={(item) => {
              setCurrentWellnessChecklistItemPopupText(
                !item.is_complete ? item.popup_text : null
              )
            }}
            completed={false}
            key={`category-group-${category.description}`}
            category={category}
            goToWellnessPage={(item) => {
              setCurrentGetWellness(
                item.description && item.interconnectivity_prompting
                  ? {
                      description: item.description,
                      interconnectivity_navigation:
                        item.interconnectivity_navigation,
                      interconnectivity_prompting:
                        item.interconnectivity_prompting
                    }
                  : null
              )
            }}
            wellnessChecklist={incompleteWellnessChecklist}
          />
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={styles.headerCompleted}>Completed</Text>
            <Link
              style={styles.editButton}
              onPress={() => {
                setVisible(!visible)
              }}
            >
              <Text style={{ color: '#4F8FEF', marginLeft: 25 }}>
                {visible ? 'Edit' : 'Done'}
              </Text>
            </Link>
          </View>

          {visible && (
            <CompletedCategoryGroup
              onCheck={(item) => {
                setCurrentWellnessChecklistItemPopupText(
                  !item.is_complete ? item.popup_text : null
                )
              }}
              key={`category-group-${category.description}`}
              category={category}
              wellnessChecklist={completeWellnessChecklist}
            />
          )}
          {visible === false && (
            <CategoryGroup
              onCheck={(item) => {
                setCurrentWellnessChecklistItemPopupText(
                  !item.is_complete ? item.popup_text : null
                )
              }}
              completed
              key={`category-group-${category.description}`}
              category={category}
              wellnessChecklist={completeWellnessChecklist}
            />
          )}
        </ScrollView>

        <CongratsSlideupDrawer
          visible={currentWellnessChecklistItemPopupText}
          setVisible={() => setCurrentWellnessChecklistItemPopupText(null)}
          title="Way to go!"
          subtitle={currentWellnessChecklistItemPopupText}
          metadataKey={null}
          icon="check"
          iconWidth={100}
          iconHeight={100}
        />
        <GetWellnessSlideupDrawer
          visible={currentGetWellness}
          setVisible={() => setCurrentGetWellness(null)}
          currentGetWellness={currentGetWellness}
          metadataKey={null}
          currentUser={currentUser}
          navigation={navigation}
        />
      </View>
    )
  }
  return (
    <WellnessQuizWizard
      wellnessChecklist={groupedWellnessChecklist[category.description]}
      category={category}
      navigation={navigation}
      onFinish={() => {
        dispatch(saveMetaData({ [metadataKey]: true }))
      }}
    />
  )
}
