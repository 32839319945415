import { useLayoutEffect } from 'react'

import { post } from 'http'
import { StyleSheet, Text } from 'react-native'
import { gunmetal, white } from 'styles'

import { useNavigationState } from '@react-navigation/native'
import { first } from 'lodash'
import Input from 'components/Input'
import HeaderBackImage from 'components/HeaderBackImage'
import Container from 'components/Container'
import Form from 'components/Form'
import CloseButton from 'components/CloseButton'
import TouchableOpacity from 'components/TouchableOpacity'
import HeaderLabel from 'components/HeaderLabel'

import { ValidationSchema, Fields, InitialValues } from './schemas/resetPasswordSchema'

import handleError from './functions/handleError'

const styles = StyleSheet.create({
  headerLabel: {
    marginBottom: 12,
    textAlign: 'center',
    width: '100%'
  },
  subtext: {
    fontSize: 16,
    marginBottom: 24,
    color: gunmetal,
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center'
  }
})

export default function LoginScreen({ navigation }) {
  const routes = useNavigationState((state) => state.routes)
  const firstRoute = first(routes)

  useLayoutEffect(() => {
    navigation.setOptions({
      title: null,
      headerTransparent: true,
      headerLeft: () =>
        firstRoute.name == 'Get Started' ? (
          <TouchableOpacity onPress={() => navigation.pop()}>
            <HeaderBackImage fill={white} />
          </TouchableOpacity>
        ) : (
          <CloseButton color={white} onPress={() => navigation.pop()} />
        )
    })
  }, [navigation])

  return (
    <Container card={true}>
      <Form
        buttonText="Request Reset Password"
        validationSchema={ValidationSchema}
        initialValues={InitialValues}
        onSubmit={async (values, actions) => {
          try {
            await post('rest-auth/password/reset/', values)

            navigation.navigate('Modal', { screen: 'Reset Password Successful' })
          } catch (e) {
            handleError(e, 'Oops! Something went wrong.')
            actions.setFieldError(Fields.email, 'Invalid email or password')
          }
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <HeaderLabel style={styles.headerLabel}>Reset Password</HeaderLabel>
            <Text style={styles.subtext}>
              Please enter your email address and we will send you a link to reset your password.
            </Text>

            <Input
              name={Fields.email}
              errorMessage={errors[Fields.email]}
              touched={touched[Fields.email]}
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              label="Email Address"
              secureTextEntry={false}
              returnKeyType="done"
              onSubmitEditing={handleSubmit}
            />
          </>
        )}
      </Form>
    </Container>
  )
}
