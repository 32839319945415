import { StyleSheet, View } from 'react-native'
import { darkJungleGreen } from '../styles'

const styles = StyleSheet.create({
  container: {
    height: '100%',
    shadowOffset: {
      width: 0,
      height: -1
    },
    left: 0,
    shadowOpacity: 0.4,
    shadowRadius: 8.0,
    backgroundColor: darkJungleGreen,
    elevation: 10,
    width: 80,
    zIndex: 10,
  }
})

export const TabWrapper = ({ style, children }) => (
  <View style={[styles.container, style]}>
    <View>{children}</View>
  </View>
)

export default TabWrapper
