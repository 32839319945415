import { StyleSheet, View } from 'react-native'

import { map } from 'lodash'
import ExpandableItem from './ExpandableItem'

import Divider from '../Divider'

import { lightgray, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  headerContainer: {
    margin: 10,
    position: 'relative'
  },
  divider: { borderBottomColor: '#DDE4E9', marginBottom: 10, marginTop: 10 },
  pillLabel: {
    backgroundColor: lightgray,
    alignSelf: 'center',
    position: 'absolute',
    top: -10
  },
  pillLabelText: { color: white },
  container: {
    marginHorizontal: DEFAULT_SPACING,
    position: 'relative'
  }
})

export default function CompletedCategoryGroup({ onCheck, category, wellnessChecklist }) {
  return (
    <View>
      <View style={styles.headerContainer} />

      <View style={styles.container}>
        {map(wellnessChecklist, (item) => (
          <>
            <ExpandableItem
              title={item.description}
              content={item.popup_text}
            />

            <Divider style={[styles.divider]} />

          </>
        ))}
      </View>
    </View>
  )
}
