import { del } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import handleError from '../functions/handleError'
import handleSuccess from '../functions/handleSuccess'
import { currentUserSelector } from '../selectors'

import { getPlans } from './getPlans'

export const DELETE_PRO_PLAN = 'DELETE_PRO_PLAN'

export const deleteProPlan = (planId) => async (dispatch, getState) => {
  try {
    const { id: userId } = currentUserSelector(getState())
    await del(proApplicationEndpoints.deletePlan(userId, planId))

    dispatch({
      type: DELETE_PRO_PLAN,
      payload: planId
    })

    dispatch(getPlans())

    handleSuccess('Success!', 'Plan deleted')
  } catch (error) {
    handleError(error, 'Unable to Delete Plan')
  }
}
