import { put } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import handleError from '../functions/handleError'
import { currentUserSelector } from '../selectors'
import { setBSAccounts } from './setBSAccounts'
import { setLoading } from './setLoading'

// eslint-disable-next-line import/prefer-default-export
export const updateBSAccountPositions = (accounts) => async (dispatch, getState) => {
  try {
    dispatch(setLoading({ loading: true  }))

    let result
    const state = getState()
    const { id: userId } = currentUserSelector(state)

    const sanitizedAccounts = accounts.map((account) => ({
      accountId: account.accountId,
      position: account.position
    }))

    result = await put(
      proApplicationEndpoints.updateAccounts(userId),
      sanitizedAccounts
    )

    const { data: newAccounts = [] } = result || []

    dispatch(setBSAccounts({ accounts: newAccounts }))
    dispatch(setLoading({ loading: false }))
  } catch (e) {
    console.warn(e?.response?.data)
    handleError(e)
    dispatch(setLoading({ loading: false }))
  }
}
