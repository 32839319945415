import { useLayoutEffect } from 'react'
import {
  View,
  StyleSheet,
  Text,
  Image,
  Platform,
  useWindowDimensions,
} from 'react-native'

import Confetti from 'react-native-confetti'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import Container from './components/Container'
import BottomButton from './components/BottomButton'
import hapticFeedback from './components/hapticFeedback'
import TouchableOpacity from './components/TouchableOpacity'
import { darkblue, DEFAULT_SPACING } from './styles'
import CloseButton from './components/CloseButton'

const styles = StyleSheet.create({
  headerMessage: {
    fontSize: 32,
    marginTop: 5,
    color: '#283a48',
    opacity: 1,
    textAlign: 'center',
    marginBottom: '2%',
    fontWeight: 'bold'
  },
  percentScoreText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    color: darkblue
  },
  scoreText: {
    textAlign: 'center',
    fontSize: 20,
    color: 'gray',
    width: '90%',
    marginLeft: '5%',
    marginTop: '1%'
  },
  percentText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 95
  },
  confetti: {
    position: 'absolute',
    display: 'flex',
    flex: 1,
    zIndex: 0,
    height: 300,
    overflow: 'hidden'
  },
  confettiMask: {
    zIndex: 100,
    height: 300
  },
  percentContainer: { marginTop: 100 },
  bottomButton: { marginHorizontal: DEFAULT_SPACING },
})

export default function LibraryQuizScoreScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const {width} = useWindowDimensions()
  const { params } = route
  const { turn, score, correct } = params
  const totalTurns = turn
  const isWeb = Platform.OS === 'web'

  useLayoutEffect(() => {
    if (!isWeb && this._confettiView) {
      this._confettiView.startConfetti()
    }

    navigation.setOptions({
      headerLeft: () => (
        <CloseButton onPress={() => {
          navigation.popToTop()
          navigation.pop()
        }}
        />
      ),
      headerRight: () => (
        <TouchableOpacity
          style={{ right: '150%', transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }] }}
          onPress={() => {
            navigation.navigate('Modal', { screen: 'Library' })
            hapticFeedback()
          }}
        >
          <FontAwesomeIcon icon={faRedo} />
        </TouchableOpacity>
      )
    })
  }, [navigation])

  const handleFinish = () => {
    if (isNotWeb) navigation.navigate('Modal', { screen: 'Library' })
    else navigation.navigate('Tools')
  }

  return (
    <Container style={styles.container}>
      <View style={styles.confetti}>
        <Image style={[styles.confettiMask, { width }]} resizeMode="cover" source={require('./assets/gradient.png')} />
        {!isWeb && <Confetti confettiCount={1000} duration={10000} ref={(node) => this._confettiView = node} untilStopped={false} timeout={0} />}
      </View>
      <View style={{ flex: 1 }}>
        <View style={styles.percentContainer}>
          <Text style={styles.percentText}>
            {correct}
            {' '}
            %
          </Text>
          <Text style={styles.percentScoreText}>Percent Correct </Text>
        </View>
        <View>
          <Text style={styles.headerMessage}>Way to go! </Text>
          <Text style={styles.scoreText}>
            {`You completed ${totalTurns} questions`}
          </Text>
          <Text style={styles.scoreText}>
            and answered
            {score}
            {' '}
            correctly!
          </Text>
        </View>
      </View>
      <BottomButton
        style={styles.bottomButton}
        onPress={handleFinish}
      >
        Finish
      </BottomButton>
    </Container>
  )
}
