import { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'
import { get } from 'http'
import SlideupDrawer from './SlideupDrawer'
import Button from './Button'

import { plansSelector } from '../selectors'
import { darkblue, gunmetal } from '../styles/colors'
import { setCurrentPlan } from '../actions'
import { pagesToNavigationWellness } from '../constants'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingBottom: 220,
    height: '100%',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 20
  },

  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    zIndex: 100
  },
  body: {
    marginVertical: 20,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'left',
    zIndex: 100
  },
  button: { marginTop: 20 }
})
function findPrimaryPlan(plans) {
  const primaryPlan = find(plans, (plan) => plan.is_primary_plan)
  return primaryPlan
}
export default function GetWellnessSlideupDrawer({
  visible,
  setVisible,
  onClose,
  screenToNavigate,
  currentGetWellness,
  navigation,
  currentUser
}) {
  const dispatch = useDispatch()
  const plans = useSelector((state) => plansSelector(state))

  const [primaryPlan, setPrimaryPlan] = useState({})

  async function fetchPrimaryPlan() {
    if (!currentUser) return
    if (!currentUser.primary_plan) return
    if (!currentUser.primary_plan.id) return

    try {
      const primaryPlanResponse = await get(
        `/api/v1/plans/${currentUser.primary_plan.id}/`
      )
      const plan = findPrimaryPlan(plans)
      setPrimaryPlan({ ...plan, ...primaryPlanResponse.data })
    } catch (e) {
      console.warn(e, e.response)
    }
  }
  useEffect(() => {
    fetchPrimaryPlan()
  }, [currentUser])

  function handleClose() {
    setVisible(false)
    setTimeout(() => {
      if (onClose) onClose()
    }, 1000)
  }
  function handlePress() {
    setVisible(false)
    if (currentGetWellness && currentGetWellness.interconnectivity_navigation) {
      const discovery = pagesToNavigationWellness.find(
        (option) =>
          option.value === currentGetWellness.interconnectivity_navigation
      )
      if (discovery.route === 'Plan Summary') {
        dispatch(setCurrentPlan(primaryPlan))
      }
      navigation.navigate('Modal', {
        screen: discovery ? discovery.route : null
      })
    }

    // Make this call after the modal animation finishes
    // otherwise it will be choppy
    setTimeout(() => {
      if (onClose) onClose()
    }, 1000)
  }
  return (
    <SlideupDrawer
      snapPointIndex={1}
      visible={visible}
      setVisible={handleClose}
    >
      <View style={styles.container}>
        <Text style={styles.header}>
          {currentGetWellness && currentGetWellness.description
            ? currentGetWellness.description
            : ''}
        </Text>
        <Text style={styles.body}>
          {currentGetWellness && currentGetWellness.interconnectivity_prompting
            ? currentGetWellness.interconnectivity_prompting
            : ''}
        </Text>

        {currentGetWellness &&
          currentGetWellness.interconnectivity_navigation && (
            <Button onPress={handlePress} style={styles.button}>
              {currentGetWellness.interconnectivity_navigation}
            </Button>
          )}
      </View>
    </SlideupDrawer>
  )
}
