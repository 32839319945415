import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { darkblue, gunmetal, lightblue, white } from '../../styles'
import { getTimeDistance } from '../../functions/notification'

const styles = StyleSheet.create({
  wrapper: { borderBottomWidth: 2, borderColor: '#00000009' },
  read: { backgroundColor: white },
  unRead: { backgroundColor: '#5F8EE819' },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 12
  },
  imageContainer: {
    position: 'relative',
    shadowColor: '#3A424B',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 11 },
    shadowRadius: 11
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: white
  },
  iconWrapper: {
    position: 'absolute',
    right: -4,
    bottom: -4,
    backgroundColor: lightblue,
    width: 18,
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 9
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  sender: {
    fontSize: 16,
    fontWeight: '600',
    color: darkblue
  },
  time: {
    fontSize: 10,
    color: gunmetal,
    marginLeft: 4
  },
  description: {
    fontSize: 14,
    color: gunmetal,
    marginTop: 4
  }
})

function NotificationListItem({ data, onPress, onMenuPress }) {
  return (
    <View
      style={[styles.wrapper, data.readStatus ? styles.read : styles.unRead]}
    >
      <TouchableOpacity onPress={() => onPress()}>
        <View style={styles.container}>
          <View style={styles.imageContainer}>
            <Image
              style={styles.image}
              source={require('../../assets/logo.png')}
              resizeMode="contain"
            />
            <View style={styles.iconWrapper}>
              {data.context.icon.iconType === 'FONT_AWESOME' ? (
                <FontAwesomeIcon
                  icon={data.context.icon.fontAwesome.icon}
                  color={data.context.icon.fontAwesome.color}
                  size={10}
                />
              ) : (
                <Image source={data.context.icon.imageUrl} />
              )}
            </View>
          </View>
          <View style={{ marginLeft: 16, flex: 1 }}>
            <View style={styles.title}>
              <Text style={styles.sender}>{data.sender}</Text>
              <Text style={styles.time}>
                {'• '}
                {getTimeDistance(data.createdAt)}
              </Text>
            </View>
            <Text style={styles.description}>{data.context.title}</Text>
          </View>
          {/* <TouchableOpacity onPress={() => onMenuPress()}>
            <FontAwesomeIcon icon="ellipsis" />
          </TouchableOpacity> */}
        </View>
      </TouchableOpacity>
    </View>
  )
}

export default NotificationListItem
