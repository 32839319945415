import { each } from 'lodash'
import {
  MONTHLY_PERIODICITY,
  DAILY_PERIODICITY,
  WEEKLY_PERIODICITY,
  BI_WEEKLY_PERIODICITY,
  SEMI_MONTHLY_PERIODICITY,
  YEARLY_PERIODICITY
} from '../constants'

/**
 * Input: budgetItemsForCategory = [{
 *  periodicity: one of DAILY, WEEKLY, BIWEEKLY, MONTHLY,...,
 *  amount: 1000
 * }]
 *
 * Output: total amount for the month
*/
export function getProBudgetTotal(budgetItems) {
  let total = 0;

  each(budgetItems, (budgetItem) => {
    const { amount } =  budgetItem 
    total += amount
  })

  return total
}


export default function getMonthlyProBudgetTotal(budgetItems) {
  let total = 0

  each(budgetItems, (budgetItem) => {
    const { periodicity } = budgetItem
    const { frequency } = periodicity

    const { amount } = budgetItem
    switch (frequency) {
    case MONTHLY_PERIODICITY:
      total += amount
      break
    case DAILY_PERIODICITY:
      total += amount * 30
      break
    case WEEKLY_PERIODICITY:
      total += amount * 4
      break
    case BI_WEEKLY_PERIODICITY:
      total += amount * 2
      break
    case SEMI_MONTHLY_PERIODICITY:
      total += amount * 2
      break
    case YEARLY_PERIODICITY:
      total += amount / 12
      break
    default:
      total += amount
    }
  })

  return total
}
