import { LinearGradient } from 'expo-linear-gradient';
import { Text, View, StyleSheet, Image } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTriangle, } from '@fortawesome/pro-duotone-svg-icons';
import { DEFAULT_SPACING, success, white } from '../../../styles';

const styles = StyleSheet.create({
  container: { paddingVertical: DEFAULT_SPACING * 2, marginHorizontal: DEFAULT_SPACING * 2, },
  sliderLabel: { fontSize: 12, },
  scoreContainer: {
    minWidth: 36,
    overflow: 'visible',
    alignItems: 'center',
    width: 'auto',
    position: 'absolute',
    top: -8,
    marginLeft: -18,
  },
  scoreLabel: { fontSize: 12, color: success, },
  icon: { width: 22, height: 22, marginTop: 4, }
});

const colors = ['#316CF6', '#82FB86', '#FDF66C', '#EB4D36',]

function GradientSliderResult({ value }) {
  return (
    <View style={styles.container}>
      <View style={{ justifyContent: 'space-between', flexDirection: 'row', marginBottom: -8, }}>
        <Text style={[styles.sliderLabel, { marginLeft: -14 }]}>1</Text>
        <Text style={[styles.sliderLabel, { marginRight: -14 }]}>5</Text>
      </View>
      <LinearGradient
        colors={colors}
        style={{ height: 4, width: '100%', borderRadius: 5, marginBottom: 6 }}
        start={[0, 1]}
        end={[1, 1]}
      />
      <View style={{ justifyContent: 'space-between', flexDirection: 'row', }}>
        <Text style={styles.sliderLabel}>Low</Text>
        <Text style={styles.sliderLabel}>High</Text>
      </View>
      {value.map((val) => (
        <View style={[styles.scoreContainer, { left: `${val}%`, }]}>
          <View style={{
            backgroundColor: `${colors[((val / 25) - 1.5).toFixed(0) * 1]}25`,
            paddingHorizontal: 6,
            paddingVertical: 3,
            borderRadius: 4
          }}
          >
            <Text style={styles.scoreLabel}>
              {`${((val / 25) + 1).toFixed(2)}`}
            </Text>
          </View>
          <Image source={require('../../../assets/icons/triangle-icon.png')} style={styles.icon} />
        </View>
      ))}
    </View>
  );
}

export default GradientSliderResult;
