import { useEffect, useRef } from 'react'
import { View, StyleSheet } from 'react-native'
import { del, put } from 'http'
import { useDispatch } from 'react-redux'
import Input from '../Input'
import CurrencyInput from '../CurrencyInput'

import Link from '../Link'

import Container from '../Container'
import CircleIcon from '../CircleIcon'

import { saveCurrentPlan } from '../../actions'
import { danger, info, rgba, DEFAULT_SPACING } from '../../styles'

import handleError from '../../functions/handleError'
import handleSuccess from '../../functions/handleSuccess'

import {
  ValidationSchema,
  InitialValues,
  Fields
} from '../../schemas/addOnSchema'

import Form from '../Form'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
    paddingTop: 40
  },
  circleIconContainr: {
    alignItems: 'center',
    marginVertical: DEFAULT_SPACING * 2
  },
  addOnsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  link: {
    marginVertical: 12,
    fontSize: 16,
    fontWeight: '600',
    color: danger
  },
  addOnButton: {
    borderRadius: 12,
    borderWidth: 1,
    width: '30%',
    marginVertical: 5,
    paddingHorizontal: 10,
  },
  addOnButtonText: {
    textAlign: 'center',
    fontSize: 12
  }
})

function AddOnForm({ data, header, onSubmit, handleClear }) {
  const amountField = useRef()
  const initialValues = data || InitialValues

  return (
    <Form
      buttonText="Save"
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        onSubmit(values)
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
        <>
          {header}

          <Input
            label="Name"
            name={Fields.name}
            errorMessage={errors[Fields.name]}
            touched={touched[Fields.name]}
            onChangeText={handleChange(Fields.name)}
            onBlur={handleBlur(Fields.name)}
            value={values[Fields.name]}
            returnKeyType="next"
            onSubmitEditing={() => amountField.current.focus()}
          />
          <CurrencyInput
            ref={amountField}
            type="money"
            unit="$"
            name={Fields.amount}
            value={values[Fields.amount]}
            touched={touched[Fields.amount]}
            errorMessage={errors[Fields.amount]}
            onChangeText={handleChange(Fields.amount)}
            label="Amount of Surplus to Dedicate (Monthly)"
            onSubmitEditing={handleSubmit}
          />

          <Link style={styles.link} onPress={handleClear}>
            Delete Add On
          </Link>
        </>
      )}
    </Form>
  )
}

export default function EditAddOnFormScreen({ route, navigation }) {
  const dispatch = useDispatch()

  const { params } = route
  const { addOn } = params

  useEffect(() => {
    navigation.setOptions({ title: 'Edit Add On' })
  }, [navigation])

  const addOnOptions = [{
    label: 'Subscription',
    icon: 'credit-card',
    type: 'fal'
  }, {
    label: 'Entertainment',
    icon: 'tv',
    type: 'fal'
  }, {
    label: 'Pet',
    icon: 'dog',
    type: 'fal'
  }, {
    label: 'Charity',
    icon: 'circle-heart',
    type: 'fal'
  }, {
    label: 'Hobby',
    icon: 'guitar',
    type: 'fal'
  }, {
    label: 'Custom',
    icon: 'star',
    type: 'fal'
  }]

  function handleSubmit(values) {
    const payload = {
      id: addOn.id,
      amount: values.amount,
      name: values.name,
      plan: addOn.id,
      category: addOn.category
    }

    try {
      put(`add-ons/${payload.id}/`, payload)

      dispatch(saveCurrentPlan({}))

      handleSuccess('Success!', 'Add-on saved successfully')

      navigation.goBack()
    } catch (e) {
      handleError(e, 'Unable to save add-on')
    }
  }

  function handleClear() {
    try {
      del(`add-ons/${addOn.id}/`)
      handleSuccess('Add-on Deleted!', 'Add-on successfully deleted.')
      dispatch(saveCurrentPlan({}))
      navigation.goBack()
    } catch (e) {
      handleError(e, 'Error deleting add-on')
    }
  }

  const currentAddOn = addOnOptions.find((option) => option.label.toLowerCase() === addOn.category.toLowerCase())

  return (
    <Container style={styles.container} withPadding>
      <AddOnForm
        data={addOn}
        onSubmit={handleSubmit}
        handleClear={handleClear}
        header={(
          <View style={styles.circleIconContainr}>
            <CircleIcon
              icon={currentAddOn.icon}
              type={currentAddOn.type}
              size={40}
              color={info}
              backgroundColor={rgba(info, 0.2)}
            />
          </View>
        )}
      />
    </Container>
  )
}
