import { View, Text, StyleSheet, Platform } from 'react-native'
import { map } from 'lodash'

import SquareCheckBox from './SquareCheckBox'
import CircleIcon from './CircleIcon'
import TouchableOpacity from './TouchableOpacity'

import { darkblue, DEFAULT_SPACING, lightestgray, rgba } from '../styles'

const styles = StyleSheet.create({
  container: {},
  option: {
    borderBottomWidth: 1,
    borderBottomColor: lightestgray,
    paddingVertical: DEFAULT_SPACING,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightText: {
    color: darkblue,
    fontWeight: '600',
    marginRight: 20,
    fontSize: 18
  },
  leftText: {
    fontWeight: '500',
    color: darkblue,
    fontSize: 18,
    lineHeight: 21,
    width: '80%',
  }
})

const isNotWeb = Platform.OS !== 'web'

export default function CheckboxList({ options, value, onChange, size = 20, variant }) {

  return (
    <View style={styles.container}>
      {map(options, (option, index) => (
        <TouchableOpacity key={index} onPress={() => onChange(option.value)} style={[styles.row, styles.option]}>

          {(option.icon || option.leftText)
            && (
              <View style={[styles.row, { flex: 1 }]}>
                {option.icon
                  && (
                    <CircleIcon
                      color={option.color}
                      icon={option.icon}
                      size={isNotWeb ? 16 : 19}
                      backgroundColor={rgba(option.color, 0.1)}
                    />
                  )}

                {option.leftText && <Text style={[styles.leftText, {marginLeft: option.icon ? 20 : 0}]}>{option.leftText}</Text>}
              </View>
            )}

          <SquareCheckBox
            key={index}
            isChecked={value.includes(option.value)}
            onPress={() => onChange(option.value)}
            size={size}
            variant={variant}
          />

          {options.rightText
            && (
              <View style={styles.row}>
                <Text style={styles.rightText}>{options.rightText}</Text>
              </View>
            )}
        </TouchableOpacity>
      ))}
    </View>
  )
}
