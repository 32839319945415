import { Platform, View, Text, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import { darkblue, gunmetal, antiFlashWhite, white } from '../../styles/colors'
import CircleIcon from '../CircleIcon'
import hapticFeedback from '../hapticFeedback'
import TouchableOpacity from '../TouchableOpacity'

const basicStyles = {
  touchable: {
    backgroundColor: white,
    borderRadius: 12,
    width: '49%',
    marginBottom: 5,
    paddingHorizontal: 10,
    paddingVertical: 30
  },
  icon: {
    width: 66,
    height: 66,
    borderRadius: 66 / 2,
  },
  label: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: 0,
    fontWeight: '600',
    marginTop: 10,
    textAlign: 'center'
  },
  statusContainer: { marginBottom: 4 },
  status: {
    textAlign: 'center',
    fontSize: 14,
    textAlign: 'center',
    fontSize: 14,
    color: darkblue,
    fontWeight: '600'
  },
  cardContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      touchable: {
        width: '100%',
        marginBottom: 0,
        paddingHorizontal: 30,
      },
      cardContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
      },
      icon: {
        marginRight: 40,
        width: 86,
        height: 86,
        borderRadius: 43,
      },
      content: { alignItems: 'flex-start' },
      label: { fontSize: 14, },
      status: { fontSize: 22, }
    },
  }
)

export default function DashboardBlock({ disabled, label, statusLabel, Icon, icon, onPress, backgroundColor, color }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  function handleOnPress() {
    if (onPress) {
      onPress()
      hapticFeedback()
    }
  }

  return (
    <TouchableOpacity onPress={handleOnPress} disabled={disabled || !onPress} style={[styles.touchable, styles]}>
      <View style={styles.cardContainer}>
        <CircleIcon
          Icon={Icon}
          icon={icon}
          style={[styles.icon, { backgroundColor: disabled ? '#F9F9F9' : backgroundColor }]}
          color={disabled ? antiFlashWhite : color}
          size={isNotWeb ? 28 : 48}
        />

        <View style={styles.content}>
          <Text style={styles.label}>{label}</Text>
          <View style={styles.statusContainer}>
            <Text style={styles.status}>{!disabled ? statusLabel : 'N/A'}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}
