import { Platform, Text, View, TouchableOpacity, StyleSheet } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import hapticFeedback from '../hapticFeedback'
import ListItem from '../ListItem'
import { DEFAULT_SPACING, white, lightestgray, lightgray, gray, darkblue } from '../../styles'
import Link from '../Link'

const basicStyles = {
  title: { padding: 16 },
  label: {
    fontSize: 20,
    color: darkblue,
    fontWeight: '600',
    marginBottom: DEFAULT_SPACING / 3
  },
  labelContainer: { flexDirection: 'row' },
  sublabel: {
    fontSize: 12,
    color: gray
  },
  container: {
    backgroundColor: white,
    borderWidth: 1,
    borderColor: lightestgray,
    borderRadius: 10,
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  icon: { marginRight: DEFAULT_SPACING, },
  linkContainer: {
    justifyContent: 'flex-start',
    minHeight: 0
  },
  link: { textAlign: 'left' },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  leftComponent: {
    width: 120,
    marginRight: DEFAULT_SPACING
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      row: { flexDirection: 'column' },
      leftComponent: {
        width: 72,
      }
    },
    [minSize(DEVICE_SIZES.LG)]: {
      row: { flexDirection: 'row' },
      leftComponent: {
        marginRight: DEFAULT_SPACING,
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function ListOptionItem({
  labelStyle,
  label,
  sublabel,
  value,
  onPress,
  numberOfLines = 1,
  style = {},
  leftComponent,
  disabled,
  isPension = false,
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  const handlePress = () => {
    if (onPress) onPress(value)
    hapticFeedback()
  }

  return (
    <TouchableOpacity style={style} disabled={disabled} onPress={handlePress}>
      <ListItem style={styles.container}>
        <View style={styles.row}>
          <View style={styles.leftComponent}>{leftComponent}</View>
          <View style={{ flex: 2, alignItems: 'flex-start' }}>
            <View style={styles.labelContainer}>
              <Text numberOfLines={numberOfLines} style={[styles.label, labelStyle]}>
                {label}
              </Text>
            </View>

            {sublabel}

            {!disabled && isNotWeb && (
              <Link onPress={handlePress}>
                {isPension ? 'Future Value' : 'Learn More'}
              </Link>
            )}
          </View>
        </View>
      </ListItem>
    </TouchableOpacity>
  )
}
