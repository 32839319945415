import { post } from 'http'
import { setWellnessChecklistItem } from './setWellnessChecklistItem'
import { getCurrentUser } from './getCurrentUser'

export const saveWellnessChecklistItem = function (financial_wellness_item_id, is_complete) {
  return async (dispatch) => {
    try {
      const result = await post('api/financial-wellness-checklist/', { financial_wellness_item_id, is_complete })
      dispatch(setWellnessChecklistItem(result.data))
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
