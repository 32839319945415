import { post } from 'http'

export async function futureValueCalculator(payload) {
  try {
    const clonedPayload = JSON.parse(JSON.stringify(payload))

    if (clonedPayload.contribution_frequency == '') {
      clonedPayload.contribution_frequency = 'A'
    }

    const result = await post('calculator/future/', clonedPayload)

    if (result) {
      return result.data
    }
    return null
  } catch (e) {
    console.warn(e, e.response)
  }
}
