import _ from 'lodash'
import { Platform } from 'react-native'
import { BASE_CONFIG } from './config.base'

const IOS = 'ios'
const BaseAddress = Platform.OS === IOS ? 'https://localhost' : 'https://127.0.0.1'

export const DEV_CONFIG = _.merge({}, BASE_CONFIG, {
  envName: 'Dev',
  crashlyticsCollectionEnabled: false,
  baseUrl:
    Platform.OS === IOS
      ? `${BaseAddress}:3000/api/v1/`
      : `${BaseAddress}:3000/api/v1/`,
  baseAddress: 'localhost'
});
