import { PRO_BASE_URL } from '@env'

import { forOwn } from 'lodash'

const addQueryParams = (url = '', params = {}) => {
  const processedParams = []

  forOwn(params, (value, key) => {
    processedParams.push(`${key}=${value}`)
  })

  return [url, processedParams.join('&')].join('?')
}

const bsAccountBaseUrl = `${PRO_BASE_URL}/account`
const bsAccountsBaseUrl = `${PRO_BASE_URL}/accounts`
const bsAccountEndpoints = {
  createAccount: (userId) => addQueryParams(bsAccountBaseUrl, { userId }),
  // Note the bsAccount(s) i.e. the plural form
  createAccounts: (userId) => addQueryParams(bsAccountsBaseUrl, { userId }),
  // Note the bsAccount(s) i.e. the plural form
  getAccounts: (userId) => addQueryParams(bsAccountsBaseUrl, { userId }),
  getAccount: (userId, accountId) =>
    addQueryParams([bsAccountBaseUrl, accountId].join('/'), { userId }),
  updateAccount: (userId, accountId) =>
    addQueryParams([bsAccountBaseUrl, accountId].join('/'), { userId }),
  updateAccounts: (userId) => addQueryParams(bsAccountsBaseUrl, { userId }),
  deleteAccount: (userId, accountId) =>
    addQueryParams([bsAccountBaseUrl, accountId].join('/'), { userId }),
  getAccountCategories: () => [bsAccountBaseUrl, 'categories'].join('/')
}

const transactionsBaseUrl = `${PRO_BASE_URL}/transactions`
const transactionBaseUrl = `${PRO_BASE_URL}/transaction`
const transactionEndpoints = {
  getTransactions: (userId, startDate, endDate) =>
    addQueryParams(transactionsBaseUrl, {
      userId,
      startDate: startDate,
      endDate: endDate
    }),
  getTransaction: (userId, transactionId) =>
    addQueryParams([transactionBaseUrl, transactionId].join('/'), { userId }),
  createTransaction: (userId) => addQueryParams(transactionBaseUrl, { userId }),
  updateTransaction: (userId, transactionId) =>
    addQueryParams([transactionBaseUrl, transactionId].join('/'), { userId }),
  deleteTransaction: (userId, transactionId) =>
    addQueryParams([transactionBaseUrl, transactionId].join('/'), { userId })
}

const plansBaseUrl = `${PRO_BASE_URL}/plans`
const planBaseUrl = `${PRO_BASE_URL}/plan`
const planEndpoints = {
  getPlans: (userId) => addQueryParams(plansBaseUrl, { userId }),
  getPlan: (userId, planId) =>
    addQueryParams([planBaseUrl, planId].join('/'), { userId }),
  createPlan: (userId) => addQueryParams(planBaseUrl, { userId }),
  createPlanFromFree: (userId) =>
    addQueryParams([planBaseUrl, 'upgrade-free-plan'].join('/'), { userId }),
  updatePlan: (userId, planId) =>
    addQueryParams([planBaseUrl, planId].join('/'), { userId }),
  deletePlan: (userId, planId) =>
    addQueryParams([planBaseUrl, planId].join('/'), { userId }),
  activatePlan: (userId, planId) =>
    addQueryParams([planBaseUrl, planId, 'activate'].join('/'), { userId }),
  deactivatePlan: (userId, planId) =>
    addQueryParams([planBaseUrl, planId, 'deactivate'].join('/'), { userId })
}

const budgetPlanBaseUrl = (planId) => `${PRO_BASE_URL}/plan/${planId}/budget`
const budgetPlanEndpoints = {
  getBudgetTrend: (planId, userId, startDate, endDate) =>
    addQueryParams([budgetPlanBaseUrl(planId), 'trends'].join('/'), {
      userId,
      startDate,
      endDate
    }),
  getBudgetItems: (planId, userId) =>
    addQueryParams(budgetPlanBaseUrl(planId), { userId }),
  createBudgetItems: (planId, userId) =>
    addQueryParams([budgetPlanBaseUrl(planId), 'batch'].join('/'), { userId }),
  getBudgetItem: (planId, budgetItemId, userId) =>
    addQueryParams([budgetPlanBaseUrl(planId), budgetItemId].join('/'), {
      userId
    }),
  createBudgetItem: (planId, userId) =>
    addQueryParams(budgetPlanBaseUrl(planId), { userId }),
  updateBudgetItem: (planId, budgetItemId, userId) =>
    addQueryParams([budgetPlanBaseUrl(planId), budgetItemId].join('/'), {
      userId
    }),
  deleteBudgetItem: (planId, budgetItemId, userId) =>
    addQueryParams([budgetPlanBaseUrl(planId), budgetItemId].join('/'), {
      userId
    })
}

const budgetBaseUrl = `${PRO_BASE_URL}/budget`
const budgetEndpoints = {
  getCategories: () => [budgetBaseUrl, 'categories'].join('/')
}

const authorizeBaseUrl = `${PRO_BASE_URL}/authorize`
const authorizeEndpoints = {
  authorize: () => authorizeBaseUrl
}

const projectionsBaseUrl = (planId) =>
  `${PRO_BASE_URL}/projection-info/plan/${planId}`
const projectionEndpoints = {
  getProjection: (userId, planId) =>
    addQueryParams(projectionsBaseUrl(planId), { userId })
}

const cashSweepLogicBaseUrl = `${PRO_BASE_URL}/cash-sweep`
const cashSweepLogicEndpoints = {
  updateCashSweepLogic: (userId) =>
    addQueryParams(cashSweepLogicBaseUrl, { userId })
}

const finPulseBaseUrl = `${PRO_BASE_URL}/streaks/finpulse`
const finPulseEndpoints = {
  getFinPulsePreferences: () => [finPulseBaseUrl, 'preferences'].join('/'),
  updateFinPulsePreferences: () => [finPulseBaseUrl, 'preferences'].join('/'),
  getFinPulseStreaks: (startDate, endDate) =>
    addQueryParams(finPulseBaseUrl, { startDate, endDate })
}

const debtRank = `${PRO_BASE_URL}/debt-rank`
const debtRankEndpoints = {
  getDebtRank: () => debtRank
}

export default {
  ...bsAccountEndpoints,
  ...transactionEndpoints,
  ...planEndpoints,
  ...budgetPlanEndpoints,
  ...budgetEndpoints,
  ...projectionEndpoints,
  ...cashSweepLogicEndpoints,
  ...finPulseEndpoints,
  ...authorizeEndpoints,
  ...debtRankEndpoints
}
