import { del } from 'http'
import { getUniqueId } from 'react-native-device-info'
import handleError from '../functions/handleError'

export const deleteDevice = function () {
  return async () => {
    try {
      await del(`api/users/devices/${getUniqueId()}/`)
    } catch (error) {
      console.error(error)
    }
  }
}
