import { stateReducer } from '@truefit/redux-utils'
import { CLEAR, CLEAR_PENDING_INVITATION, SET_PENDING_INVITATIONS } from '../actions'

const initialState = []

export default stateReducer(initialState, {
  [SET_PENDING_INVITATIONS]: (_, payload) => payload,
  [CLEAR_PENDING_INVITATION]: (state, payload) => state.filter((event) => event.key !== payload.key),
  [CLEAR]: () => initialState
});
