import { useState } from 'react'
import { View, Text, Dimensions, ScrollView, StyleSheet } from 'react-native'

import { map, sum } from 'lodash'
import { useSelector } from 'react-redux'
import HeaderLabel from './components/HeaderLabel'
import Button from './components/Button'
import SlideupDrawer from './components/SlideupDrawer'

import { accountsSelector } from './selectors'

import AccountDrawerListItem from './components/emergency-preparedness/AccountDrawerListItem'
import AccountListItem from './components/emergency-preparedness/AccountListItem'
import formatCurrency from './functions/formatCurrency'
import { darkblue, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import AddSourceDrawer from './components/emergency-preparedness/AddSourceDrawer'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: DEFAULT_SPACING
  },
  headerLabel: {
    marginBottom: 24,
    textAlign: 'center',
    fontSize: 16,
    letterSpacing: -0.48
  },
  text: {
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center'
  },
  bottomButtonStyle: {
    justifyContent: 'flex-end',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING,
    backgroundColor: white
  },
  invertedButton: { marginBottom: 12 }
})

const sources = [{
  label: 'Checking',
  icon: 'money-check',
  registration: 'CHECKING'
}, {
  label: 'Savings',
  icon: 'piggy-bank',
  registration: 'SAVINGS'
}, {
  label: 'Money Market',
  icon: 'piggy-bank',
  registration: 'MONEY_MARKET'
}, {
  label: 'Taxable',
  icon: 'piggy-bank',
  registration: 'CHECKING'
}, {
  label: 'Roth IRA',
  icon: 'piggy-bank',
  registration: 'ROTH_IRA'
}]

function calculateTotal(currentAccounts) {
  return sum(map(currentAccounts, (s) => Number(s.balance)))
}

export default function EmergencyPreparednessEmergencyFundsScreen({ navigation }) {
  const marginTop = 150
  const height = 900

  const [visible, setVisible] = useState(false)
  const [formVisible, setFormVisible] = useState(false)
  const [registration, setRegistration] = useState()

  const currentAccounts = useSelector((state) => accountsSelector(state))

  return (
    <View style={{ backgroundColor: white, flex: 1 }}>
      <HeaderLabel style={styles.headerLabel}>
        Monthly Expenses
      </HeaderLabel>
      <Text style={styles.text}>
        To be prepared, we need to know your sources of funds in the event of an emergency. Funds that are easily accessible (such as checkings or savings accounts) and major assets (house or car) are typical sources. Add your items below.
      </Text>
      <ScrollView style={styles.container}>
        {map(currentAccounts, (account) => <AccountListItem navigation={navigation} sources={sources} {...account} />)}
        {currentAccounts.length > 0
          && (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 12 }}>
              <Text style={{
                fontWeight: '600',
                fontSize: 16,
                color: darkblue,
                letterSpacing: 0
              }}
              >
                Total
              </Text>

              <HeaderLabel style={{
                fontWeight: '600',
                fontSize: 16,
                color: darkblue,
                letterSpacing: 0,
                marginLeft: 25
              }}
              >
                $
                {formatCurrency(calculateTotal(currentAccounts))}
              </HeaderLabel>

            </View>
          )}
      </ScrollView>
      <View style={styles.bottomButtonStyle}>
        <Button onPress={() => setVisible(true)} style={styles.invertedButton} inverted>+ Add Source</Button>
        <Button
          disabled={currentAccounts.length == 0}
          onPress={() => {
            navigation.navigate('Modal', { screen: 'Emergency Preparedness Emergency Finish' })
          }}
        >
          Next
        </Button>
      </View>

      <AddSourceDrawer
        sources={sources}
        visible={visible}
        setVisible={setVisible}
        marginTop={marginTop}
        height={height}
        formVisible={formVisible}
        setFormVisible={setFormVisible}
      />
    </View>
  );
}
