import { each } from 'lodash';

/**
 *
 * INPUT: budgetItemsByCategory = {
 *    "INCOME": {
 *        name: "Income",
 *        categoryId: "0",
 *        items: [],
 *        count: 1,
 *        total: $5000
 *    },
 *    "HOUSING": {...}
 * }
 *
 * Output: {
 *  total: Monthly total (income - expenses) for all categories,
 *  count: The total budget items i.e. the sum of the `count` fields from each group
 * }
 */
export default function getProBudgetStats(budgetItemsByCategory) {
  let total = 0
  let totalExpenses = 0
  let count = 0

  each(budgetItemsByCategory, (categoryObject, key) => {
    if (key === 'INCOME') {
      total += categoryObject.total;
    } else {
      totalExpenses += categoryObject.total
      total -= categoryObject.total;
    }

    count += categoryObject.count
  })

  return { total, totalExpenses, count }
}
