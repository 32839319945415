import { useLayoutEffect, useState, useEffect } from 'react'
import { View, StyleSheet, Text, Image, ScrollView } from 'react-native'
import PulseCircle from './components/PulseCircle'
import {
  primary,
  white,
  info,
  gunmetal,
  DEFAULT_SPACING,
  darkblue,
  lightestgray,
  gray
} from './styles'
import HeaderLabel from './components/HeaderLabel'
import Divider from './components/Divider'
import ProgressBar from './components/ProgressBar'
import CircleIcon from './components/CircleIcon'
import Link from './components/Link'
import TouchableOpacity from './components/TouchableOpacity'
import GoalContribution from './components/finPulse/GoalContribution'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import {
  currentUserSelector,
  finPulseSelector,
  transactionsSelector,
  primaryProPlanSelector,
  budgetCategoriesSelector
} from './selectors'
import { getTransactions, setLoading, getFinPulseStreaks } from './actions'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'
import {
  calculateStreak,
  convertMonthlyContributionsToHash
} from './functions/finPulse'

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    flex: 1,
    paddingTop: DEFAULT_SPACING * 2
  },
  pulseCircleContainer: {
    paddingBottom: DEFAULT_SPACING * 2
  },
  contentContainerStyle: {
    paddingBottom: DEFAULT_SPACING * 6
  },
  header: {
    marginBottom: DEFAULT_SPACING * 2,
    textAlign: 'center',
    paddingHorizontal: DEFAULT_SPACING
  },
  text: {
    color: gunmetal,
    fontSize: 16,
    textAlign: 'center',
    paddingHorizontal: DEFAULT_SPACING
  },
  divider: {
    marginVertical: DEFAULT_SPACING * 2,
    marginHorizontal: DEFAULT_SPACING
  },
  progressContainer: {
    position: 'relative',
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: DEFAULT_SPACING * 2
  },
  circleTouchable: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: DEFAULT_SPACING * 2
  },
  circleIcon: {
    borderWidth: 2,
    borderColor: white
  },
  pulseCircleHeader: {
    fontSize: 60,
    marginTop: -10
  },
  pulseCircleSubheader: {
    fontSize: 10,
    marginTop: -6
  },
  tooltip: (percent) => ({
    position: 'absolute',
    bottom: -30,
    left: `${percent}%`,
    transform: [{ translateX: -5 }],
    padding: 6,
    borderRadius: 6,
    backgroundColor: darkblue,
    zIndex: 1,
    width: 55,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }),
  caret: (percent) => ({
    position: 'absolute',
    bottom: -8,
    left: `${percent}%`,
    transform: [{ translateX: 20 }],
    width: 0,
    height: 0,
    borderLeftWidth: 5,
    borderLeftColor: 'transparent',
    borderRightWidth: 5,
    borderRightColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: darkblue,
    zIndex: 1
  }),
  tooltipText: {
    color: 'white',
    fontSize: 12
  },
  linkContainer: (hasTransaction) => ({
    alignSelf: hasTransaction ? 'flex-start' : 'center',
    marginHorizontal: DEFAULT_SPACING
  })
})

function PulseCircleWithIconOrText({ header, subheader, showIcon, disabled }) {
  return (
    <PulseCircle
      header={showIcon ? null : String(header)}
      subheader={showIcon ? null : subheader}
      size={180}
      scaleEnd={1.2}
      color={disabled ? lightestgray : info}
      iconSize={80}
      iconColor={white}
      icon={showIcon ? 'wave-pulse' : null}
      style={styles.pulseCircle}
      headerStyle={styles.pulseCircleHeader}
      subheaderStyle={styles.pulseCircleSubheader}
      disabled={disabled}
    />
  )
}

function NoStateGoalContribution() {
  return (
    <>
      <HeaderLabel style={styles.header}>Goal Contribution Streak</HeaderLabel>

      <Text style={styles.text}>
        Make saving and investing a habit by building and maintaining your
        contribution streak.
      </Text>

      <Image
        source={require('./assets/finpulse-empty-state.png')}
        style={{ width: '100%', height: 200 }}
        resizeMode="contain"
      />
    </>
  )
}

export default function FinPulseScreen({ navigation }) {
  const currentUser = useSelector(currentUserSelector)
  const finPulse = useSelector(finPulseSelector)
  const finPulseStreaks = useSelector((state) => state.finPulseStreaks)
  const dispatch = useDispatch()
  const transactions = useSelector(transactionsSelector)

  const budgetCategories = useSelector(budgetCategoriesSelector)
  const budgetCategory = budgetCategories.find(
    (category) => category.key === 'SAVE_FIRST_CONTRIBUTION_TARGET'
  )

  useEffect(() => {
    const startDate = moment()
      .subtract(4, 'months')
      .startOf('month')
      .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    dispatch(getTransactions(currentUser.id, startDate, endDate))
  }, [currentUser])

  useEffect(() => {
    async function fetchFinPulseStreaks() {
      try {
        await dispatch(setLoading(true))
        await dispatch(getFinPulseStreaks())
        await dispatch(setLoading(false))
      } catch (err) {
        await dispatch(setLoading(false))
        console.warn(err)
        handleError(err, 'Failed to fetch FinPulse streaks')
      }
    }

    if (finPulse.enabled) {
      fetchFinPulseStreaks()
    }
  }, [finPulse])

  function handleTransactionsPress() {
    navigation.navigate('Modal', {
      screen: 'Transaction Form',
      params: { budgetCategory }
    })
  }

  function handleNavigationPress() {
    navigation.navigate('Modal', { screen: 'FinPulse Settings' })
  }

  useLayoutEffect(() => {
    // set the header title to FinPulse but also
    // add a gear icon to the right of the header
    // (it should be a button)
    navigation.setOptions({
      title: 'FinPulse',
      headerRight: () => (
        <TouchableOpacity
          onPress={handleNavigationPress}
          style={{ marginRight: DEFAULT_SPACING }}
        >
          <FontAwesomeIcon icon={['fas', 'gear']} size={20} color={darkblue} />
        </TouchableOpacity>
      )
    })
  }, [navigation])

  const monthlyContributions = isEmpty(finPulseStreaks) ? [] : finPulseStreaks
  const calculatedMonthlyContributions =
    convertMonthlyContributionsToHash(monthlyContributions)
  const pulseCircleHeader = calculateStreak(calculatedMonthlyContributions)
  const pulseCircleSubheader = pluralize('month', pulseCircleHeader, false)

  const primaryProPlan = useSelector(primaryProPlanSelector)
  const { shieldScores } = primaryProPlan
  const ontrackShieldScore = shieldScores.find(
    (shieldScore) => shieldScore.type === 'ONTRACK'
  ) || { score: 0 }

  const [tooltipVisible, setTooltipVisible] = useState(false)

  const percent = finPulse.enabled ? ontrackShieldScore.score : 0
  let tooltipPercent = percent

  if (percent >= 100 - DEFAULT_SPACING) {
    tooltipPercent = 100 - 6
  }

  const filteredTransactions = transactions.filter(
    (transaction) =>
      transaction.budgetCategory === 'SAVE_FIRST_CONTRIBUTION_TARGET'
  )

  return (
    <ScrollView
      contentContainerStyle={styles.contentContainerStyle}
      style={styles.container}
    >
      <View style={styles.pulseCircleContainer}>
        <PulseCircleWithIconOrText
          header={pulseCircleHeader}
          subheader={pulseCircleSubheader}
          showIcon={monthlyContributions.length === 0}
          disabled={!finPulse.enabled}
        />
      </View>

      <Text style={styles.text}>
        Your FinPulse score is calculated by tracking progress towards your long
        term savings goal, and answers the question “Am I on track?”.
      </Text>

      <Divider style={styles.divider} />

      {monthlyContributions.length === 0 ? (
        <NoStateGoalContribution />
      ) : (
        <GoalContribution
          streakLength={pulseCircleHeader}
          transactions={filteredTransactions}
          monthlyContributions={calculatedMonthlyContributions}
        />
      )}

      <Link
        containerStyle={styles.linkContainer(monthlyContributions.length)}
        onPress={handleTransactionsPress}
      >
        + Add New Transaction
      </Link>

      <Divider style={styles.divider} />

      <HeaderLabel style={styles.header}>Goal Progress</HeaderLabel>

      <Text style={styles.text}>
        Achieving big goals takes small steps and steady progress.
      </Text>

      <View style={styles.progressContainer}>
        <ProgressBar gradient percent={percent} />

        <TouchableOpacity
          style={styles.circleTouchable}
          onPress={() => setTooltipVisible(!tooltipVisible)}
        >
          <CircleIcon
            size={20}
            style={styles.circleIcon}
            color={white}
            backgroundColor={finPulse.enabled ? primary : gray}
            icon={'bullseye-arrow'}
          />
        </TouchableOpacity>

        {tooltipVisible && (
          <>
            <View style={styles.caret(tooltipPercent)} />
            <View style={styles.tooltip(tooltipPercent)}>
              <FontAwesomeIcon
                size={14}
                icon={'person-walking'}
                color={'white'}
              />
              <Text style={styles.tooltipText}>{Math.round(percent)}%</Text>
            </View>
          </>
        )}
      </View>
    </ScrollView>
  )
}
