import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useEffect, useRef } from 'react'
import { StyleSheet, View, Text, FlatList, useWindowDimensions } from 'react-native'
import PropTypes from 'prop-types'
import { findIndex, isEmpty, isNil, map, range, debounce } from 'lodash'
import Svg, { G, Path } from 'react-native-svg'
import { danger, DEFAULT_SPACING, lightblue, lightgray, white } from '../styles'

const DEFAULT_ITEMS_PER_SLIDE = 5

const styles = StyleSheet.create({})

function Triangle({ style }) {
  return (
    <Svg style={style} width="25.5809328px" height="25.9620508px" viewBox="0 0 25.5809328 25.9620508">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G id="Budget-Trend-Details" transform="translate(-174.909814, -136.750000)" fill="#FFFFFF" stroke="#E0E6E7">
          <Path d="M189.495335,140.907164 L199.785521,161.868654 C200.272275,162.86019 199.863068,164.058581 198.871532,164.545335 C198.597241,164.679987 198.295745,164.75 197.990186,164.75 L177.409814,164.75 C176.305245,164.75 175.409814,163.854569 175.409814,162.75 C175.409814,162.444441 175.479827,162.142944 175.614479,161.868654 L185.904665,140.907164 C186.391419,139.915628 187.58981,139.506421 188.581346,139.993175 C188.978775,140.188277 189.300234,140.509736 189.495335,140.907164 Z" id="Triangle" transform="translate(187.700000, 151.000000) scale(1, -1) translate(-187.700000, -151.000000) " />
        </G>
      </G>
    </Svg>
  )
}

export default function SlideRule({ selectedItemId, items, itemsPerSlide, onSlideEnd, style }) {
  const actualItemsPerSlide = (itemsPerSlide % 2 === 0) ? DEFAULT_ITEMS_PER_SLIDE : itemsPerSlide
  const middleIndex = Math.floor(actualItemsPerSlide / 2)
  const sliderRef = useRef()

  const { width: screenWidth } = useWindowDimensions()

  const itemsInterval = screenWidth / actualItemsPerSlide

  const renderItem = ({ item }) => (
    <View style={{
      alignItems: 'center',
      width: itemsInterval
    }}
    >
      <Text adjustsFontSizeToFit numberOfLines={1} style={{ color: lightgray }}>|</Text>
      <Text adjustsFontSizeToFit style={{ fontSize: 10, marginTop: DEFAULT_SPACING / 2 }}>{item.title}</Text>
    </View>
  )

  const data = [
    ...map(range((actualItemsPerSlide - 1) / 2), () => ({ title: '' })),
    ...items
  ]

  const scrollToItem = () => {
    if (!isNil(selectedItemId) && !isNil(sliderRef) && !isNil(sliderRef.current) && (typeof sliderRef.current.scrollToIndex === 'function')) {
      const index = findIndex(items, ({ id }) => id === selectedItemId)
      if (index >= 0) {
        sliderRef.current.scrollToIndex({ index,  animated: true })
      } else {
        sliderRef.current.scrollToIndex({ index: 0, animated: true })
      }
    }
  }

  const handleScrollToIndexFailed = (info) => {
    const wait = new Promise((resolve) => setTimeout(resolve, 500));
    wait.then(() => {
      if (sliderRef.current) {
        sliderRef.current.scrollToIndex({ index: info.index, animated: true });
      }
    });
  };

  useEffect(() => {
    if (selectedItemId) scrollToItem()
  }, [selectedItemId])

  const debouncedScrollEnd = useRef(debounce((index) => {
    const item = items[index]
    onSlideEnd(item)
  }, 200)).current;

  const handleMomentumScrollEnd = (event) => {
    const index = Math.round(event.nativeEvent.contentOffset.x / itemsInterval);
    debouncedScrollEnd(index);
  };

  return (
    <View style={[{
      alignItems: 'center',
      paddingVertical: DEFAULT_SPACING,
    }, style]}
    >
      <FlatList
        ref={sliderRef}
        horizontal
        data={data}
        renderItem={renderItem}
        decelerationRate="fast"
        scrollEventThrottle={16}
        contentContainerStyle={{
          borderTopWidth: 2,
          borderColor: lightblue,
          paddingTop: 8
        }}
        showsHorizontalScrollIndicator={false}
        initialNumToRender={actualItemsPerSlide}
        snapToInterval={itemsInterval}
        snapToAlignment="center"
        onMomentumScrollEnd={handleMomentumScrollEnd}
        onScrollToIndexFailed={handleScrollToIndexFailed}
        getItemLayout={(data, index) => (
          { length: itemsInterval, offset: itemsInterval * index, index }
        )}
      />
      <Triangle
        style={{ position: 'absolute' }}
      />
    </View>
  )
}

SlideRule.defaultProps = {
  style: {},
  itemsPerSlide: DEFAULT_ITEMS_PER_SLIDE,
  onSlideEnd: () => {}
}

SlideRule.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string.isRequired })
  ).isRequired,
  itemsPerSlide: PropTypes.number,
  onSlideEnd: PropTypes.func,
  style: PropTypes.object
}
