import { useState, useLayoutEffect, useEffect } from 'react'
import { StyleSheet, View, Text, ScrollView, Platform, Image } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { each } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import HeaderLabel from './components/HeaderLabel'
import Card from './components/Card'
import InputSelect from './components/InputSelect'

import { grayblue, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import { careerLocationSelector, occupationsSelector } from './selectors'
import formatCurrency from './functions/formatCurrency'
import LocationSwitcher from './components/careers/LocationSwitcher'
import { setCareerLocation } from './actions'
import useImageAspectRatio from './hooks/useAspectRatio'

const basicStyles = {
  container: {
    backgroundColor: 'rgb(236, 241, 244)',
    flex: 1
  },
  headerContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING,
    flexDirection: 'row',
    alignItems: 'center'
  },
  insignia: { width: 24, height: 24, marginRight: DEFAULT_SPACING },
  cardContainer: { paddingHorizontal: DEFAULT_SPACING },
  descriptionContainer: {
    padding: DEFAULT_SPACING,
    backgroundColor: white
  },
  scrollviewContentContainer: { paddingVertical: DEFAULT_SPACING },
  card: {
    paddingHorizontal: 10,
    paddingTop: 17,
    paddingBottom: 23,
    borderRadius: 12,
    flex: 1,
    marginBottom: 12
  },
  cardParallel: {
    width: '48%',
    flex: 0
  },
  rowParallel: { justifyContent: 'space-between' },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  cardRow: {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  cardIcon: { marginRight: 12 },
  image: {
    marginVertical: DEFAULT_SPACING,
    width: '100%'
  },
  label: {
    fontSize: 14,
    color: 'rgb(109, 122, 125)',
    marginBottom: 6
  },
  value: {
    fontSize: 24,
    fontWeight: '600',
    color: grayblue
  },
  headerLabel: {
    fontSize: 18,
    color: 'rgb(51, 63, 75)'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    scrollviewContentContainer: { paddingRight: 36 },
    headerContainer: { marginBottom: 0 },
    container: { paddingRight: 40 },
    listHeader: {
      fontSize: 36,
      fontWeight: '600',
      paddingVertical: 0
    },
    cardContainer: { paddingHorizontal: 0 },
    headerLabel: { fontSize: 36 }
  }
})

const isNotWeb = Platform.OS !== 'web'

function CardRow({ label, value }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <View style={[styles.row, styles.cardRow]}>
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  )
}

export function CareerExplore({ occupation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [year, setYear] = useState('0')
  const aspectRatio = useImageAspectRatio(occupation?.seal)
  const [options, setOptions] = useState([])

  const handleYearChange = (val) => {
    setYear(val)
  }

  useEffect(() => {
    const yearList = Object.keys(occupation)
      .filter((key) => {
        const parsedValue = parseInt(key, 10)
        return !Number.isNaN(parsedValue)
      })
      .filter((y) => occupation[y.toString()] > 0)
    if (yearList.length > 0) {
      setOptions(yearList)
    }
  }, [occupation])

  return (
    <ScrollView contentContainerStyle={styles.scrollviewContentContainer}>
      <View style={styles.headerContainer}>
        {occupation.insignia !== '' && (
          <Image source={{ uri: occupation.insignia }} style={styles.insignia} />
        )}
        <HeaderLabel style={styles.headerLabel}>{occupation.name}</HeaderLabel>
      </View>

      <View style={styles.cardContainer}>
        <Card style={styles.card}>
          <InputSelect
            label="Years of Services"
            placeholder="--"
            value={year}
            options={options.map((y) => ({ label: y, value: y }))}
            onChangeText={(evt) => {
              handleYearChange(evt.target.value)
            }}
            onSubmitEditing={(value) => {
              handleYearChange(value)
            }}
          />
          <CardRow
            label="National Average Salary"
            value={`$ ${formatCurrency(occupation[year])}`}
          />
        </Card>
      </View>

      <View style={styles.cardContainer}>
        <Card style={styles.card}>
          <CardRow label="Education Level" value="Diploma" />
        </Card>
      </View>
      <View style={styles.descriptionContainer}>
        <HeaderLabel style={[styles.headerLabel, { marginBottom: 8 }]}>Description</HeaderLabel>
        <Text>{occupation.description || '-'}</Text>
        {occupation.seal && (
          <Image style={[styles.image, { aspectRatio }]} source={{ uri: occupation.seal }} />
        )}
      </View>
    </ScrollView>
  )
}

export default function MilitaryCareerExploreScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const careerLocation = useSelector((state) => careerLocationSelector(state))

  const { params } = route
  const { occ_code } = params.occupation
  const [occupation, setOccupation] = useState(params.occupation)
  const dispatch = useDispatch()
  const occupationGroups = useSelector((state) => occupationsSelector(state, careerLocation.id))

  useEffect(() => {
    each(occupationGroups, (occGroup) => {
      each(occGroup.careers, (occ) => {
        if (occ.occ_code == occ_code) {
          setOccupation(occ)
        }
      })
    })
  }, [occupationGroups])

  useLayoutEffect(() => {
    navigation.setOptions({ title: 'Explore Careers' })
  }, [navigation])

  return (
    <View style={styles.container}>
      <LocationSwitcher city={careerLocation} setCity={(loc) => dispatch(setCareerLocation(loc))} />
      <CareerExplore occupation={occupation} />
    </View>
  )
}
