import { View, ScrollView, Text, Image, StyleSheet, useWindowDimensions, } from 'react-native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPencilAlt, faComments } from '@fortawesome/free-solid-svg-icons'
import ListOptionItem from './components/ListOptionItem'
import hapticFeedback from './components/hapticFeedback'
import Button from './components/Button'
import TouchableOpacity from './components/TouchableOpacity'

import LibraryData from './src/LibraryData'
import { black, darkblue, lightestgray, rgba, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

function VerticalOptionItem({ image, pdf, title, navigation }) {
  const window = useWindowDimensions()
  const width = window.width * 0.42

  return (
    <TouchableOpacity
      action={`Read Library Item: ${title}`}
      onPress={() => {
        hapticFeedback()
        navigation.navigate('Modal', { screen: 'Library Item', params: { pdf, title } })
      }}
    >
      <Image
        source={image}
        resizeModal="contain"
        style={{
          borderRadius: 5,
          width,
          height: 240,
          marginLeft: '35%',
          shadowColor: rgba(black, 0.9),
          shadowOffset: {
            width: 2,
            height: 2
          },
          shadowRadius: 100,
          borderWidth: 1,
          borderColor: white
        }}
      />
    </TouchableOpacity>
  )
}

function CircleImage({ image }) {
  return (
    <View>
      <View style={{
        position: 'relative',
        overflow: 'hidden',
        alignItems: 'flex-start',
        borderRadius: 6,
        width: 50,
        height: 50,
        borderRadius: 25,
        marginRight: DEFAULT_SPACING
      }}
      >
        <Image
          source={image}
          resizeMode="cover"
          style={{
            width: '100%',
            height: '100%'
          }}
        />
      </View>
    </View>
  )
}

function HorizontalBookOptionItem({ image, title, navigation, description, bookTitle }) {
  let useIcon; let useTitle; let
    useNav = null
  const authorNav = () => navigation.navigate('Modal', { screen: 'Author' })
  const quizNav = () => navigation.navigate('Modal', { screen: 'Quiz', params: { bookTitle } })

  // SET TITLE AND ICON BASED ON TITLE PROP :MM
  if (title == 'Quiz') {
    useIcon = faPencilAlt
    useTitle = 'Quiz'
    useNav = quizNav
  } else {
    useIcon = faComments
    useTitle = 'Ask The Author'
    useNav = authorNav
  }

  // SET NAV OBJECT PROP BASED ON TITLE
  const setNav = (title) => {
    if (title == 'Quiz') {
      quizNav()
    } else {
      authorNav()
    }
  }

  return (
    <ListOptionItem
      iconComponent={(
        <View>
          <View style={{
            position: 'relative',
            overflow: 'hidden',
            alignItems: 'flex-start',
            borderRadius: 6,
            width: 50,
            height: 50,
            borderRadius: 25,
            marginRight: DEFAULT_SPACING,
            backgroundColor: lightestgray
          }}
          >
            <FontAwesomeIcon
              icon={useIcon}
              style={{
                fontSize: 0,
                position: 'relative',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '30%',
                transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }]
              }}
            />
          </View>
        </View>
      )}
      label={useTitle}
      sublabel={description}
      navigatable
      onPress={() => {
        hapticFeedback()
        setNav(useTitle)
      }}
    />
  )
}

function HorizontalOptionItem({ image, pdf, title, description, navigation, bookTitle }) {
  return (
    <ListOptionItem
      iconComponent={<CircleImage image={image} />}
      label={title}
      sublabel={description}
      navigatable
      onPress={() => {
        hapticFeedback()
        if (title == 'Quiz') {
          navigation.navigate('Modal', { screen: 'Quiz', params: { title, bookTitle } })
        } else if (title == 'Ask the Author') {
          navigation.navigate('Modal', { screen: 'Author' })
        } else {
          navigation.navigate('Modal', { screen: 'Library Item', params: { pdf, title } })
        }
      }}
    />
  )
}

export default function LibraryBookScreen({ navigation, route }) {
  const { params } = route
  const { title, pdf } = params

  let bookData;
  let resourceData;

  if (title == 'The Missing Semester') {
    bookData = LibraryData.books.tms;
    resourceData = LibraryData.resources.tms;
  } else {
    bookData = LibraryData.books.tmss;
    resourceData = LibraryData.resources.tmss;
  }

  const { image } = bookData;
  const bookTitle = bookData.title;
  const books = [{
    image,
    pdf,
    title,
  }]

  return (
    <View style={{ backgroundColor: lightestgray, position: 'relative' }}>
      <ScrollView style={{ padding: DEFAULT_SPACING }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <VerticalOptionItem
            // Book Image :MM
            image={image}
            pdf={pdf}
            title={title}
            navigation={navigation}
          />
        </View>
        <View>
          <Button
            // Book 'Read Now' button :MM
            style={{ marginTop: '10%', marginBottom: '5%' }}
            onPress={() => {
              navigation.navigate('Modal', { screen: 'Library Item', params: { pdf, title } })
            }}
          >
            Start Reading
          </Button>
        </View>
        <View style={{ marginTop: 0, justifyContent: 'space-around' }}>
          <Text style={{ marginTop: DEFAULT_SPACING, marginBottom: '5%', fontWeight: 'bold', fontSize: 20, color: darkblue }}>
            Resources
          </Text>
          <HorizontalOptionItem
            // Discussion :MM
            image={resourceData.image}
            pdf={resourceData.pdf}
            title={resourceData.title}
            description={resourceData.description}
            navigation={navigation}
          />
          <HorizontalBookOptionItem
            // Quiz :MM
            image={resourceData.image}
            pdf={resourceData.pdf}
            title={LibraryData.navigationText.quiz.title}
            description={(
              <Text>
                {LibraryData.navigationText.quiz.pretext}
                <Text style={{ fontWeight: 'bold' }}>{ bookData.title }</Text>
                {LibraryData.navigationText.quiz.posttext}
              </Text>
            )}
            navigation={navigation}
            bookTitle={bookData.title}
          />
          <HorizontalBookOptionItem
            // Ask The Author :MM
            image={resourceData.image}
            pdf={resourceData.pdf}
            title={LibraryData.navigationText.ask.title}
            description={LibraryData.navigationText.ask.description}
            navigation={navigation}
          />
        </View>
        {/* This is here to ensure that the bottom of the list isn't cutoff */}
        <View style={{ height: 400 }} />
      </ScrollView>
    </View>
  );
}
