import { get } from 'http';
import proApplicationEndpoints from '../api/proApplicationEndpoints';
import { currentUserSelector } from '../selectors';
import budgetCategoriesSelector from '../selectors/budgetCategoriesSelector';
import { setBudgetItems } from './setBudgetItems';

// eslint-disable-next-line import/prefer-default-export
export const getBudgetItems = (planId) => async (dispatch, getState) => {
  try {
    const state = getState()
    const { id: userId } = currentUserSelector(state)
    const budgetCategories = budgetCategoriesSelector(state)
    const { data: result } = await get(proApplicationEndpoints.getBudgetItems(planId, userId)) 
    dispatch(setBudgetItems({ budgetItems: result, budgetCategories }))
  } catch (e) {
    console.warn(e, e.response)
  }
}
