import { useEffect } from 'react'
import * as Sentry from '@sentry/react-native'
import { SENTRY_ENABLED, DSN } from '@env'

export default function useSentry() {
  const sentryEnabled = SENTRY_ENABLED === 'true'

  useEffect(() => {
    if (sentryEnabled) {
      Sentry.init({
        dsn: DSN,
        enableInExpoDevelopment: false,
        enableNative: true,
        tracesSampleRate: 1.0
      })
    }
  }, []);
}
