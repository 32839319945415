const formatNumber = (n) => String(n).padStart(2, '0')

export default function generateBirthday(age) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  // Adding 1 because months are zero-based
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  const birthYear = currentYear - age;
  // Subtracting 1 from currentMonth to account for zero-based months
  const birthDate = `${birthYear}-${formatNumber(currentMonth)}-${formatNumber(currentDay)}`

  return birthDate;
}
