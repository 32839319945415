import { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'

import { useDispatch } from 'react-redux'
import NumberInput from '../NumberInput'
import Switch from '../Switch'

import Form from '../Form'
import InformationBlock from '../InformationBlock'

import timeout from '../../functions/timeout'

import { saveCurrentPlan } from '../../actions'

import {
  ValidationSchema,
  Fields,
  InitialValues
} from '../../schemas/studenLoanSchema'
import CurrencyInput from '../CurrencyInput'
import Input from '../Input'

const styles = StyleSheet.create({ container: { flex: 1 } })

export default function StudentLoanForm({ route, navigation }) {
  const dispatch = useDispatch()

  const {
    title,
    loan_balance,
    loan_rate,
    loan_period,
    have_loans
  } = route.params

  const planValues = {
    loan_balance,
    loan_rate,
    loan_period,
    have_loans
  }

  useEffect(() => {
    navigation.setOptions({ title })
  })

  return (
    <View style={styles.container}>
      <Form
        validationSchema={ValidationSchema}
        initialValues={have_loans ? planValues : InitialValues}
        onSubmit={async (values) => {
          await timeout(600)

          navigation.pop()

          dispatch(saveCurrentPlan({
            have_loans: values.have_loans,
            loan_balance: values.have_loans && values.loan_balance ? values.loan_balance : null,
            loan_rate: values.have_loans && values.loan_rate ? values.loan_rate : null,
            loan_period: values.have_loans && values.loan_period ? values.loan_period : null
          }))
        }}
      >
        {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <Switch
              onPress={() => {
                setFieldValue('loan_balance', null)
                setFieldValue('loan_period', null)
                setFieldValue('loan_rate', null)
                setFieldValue('loan_national_average', null)
                setFieldValue('have_loans', !values.have_loans)
              }}
              value={values.have_loans}
              label="Do you have or plan to have student loans?"
            />

            {values.have_loans
              && (
                <View>
                  <CurrencyInput
                    type="money"
                    unit="$"
                    label="Total Balance"
                    name={Fields.loan_balance}
                    value={values.loan_balance}
                    touched={touched[Fields.loan_balance]}
                    errorMessage={errors[Fields.loan_balance]}
                    onChangeText={handleChange(Fields.loan_balance)}
                  />

                  <NumberInput
                    suffixUnit="%"
                    label="Average Interest Rate (Annual)"
                    name={Fields.loan_rate}
                    value={values.loan_rate}
                    touched={touched[Fields.loan_rate]}
                    errorMessage={errors[Fields.loan_rate]}
                    onChangeText={(v) => setFieldValue(Fields.loan_rate, v)}
                  />

                  <Input
                    keyboardType="numeric"
                    label="Loan Term (Years)"
                    name={Fields.loan_period}
                    value={values.loan_period}
                    touched={touched[Fields.loan_period]}
                    errorMessage={errors[Fields.loan_period]}
                    onChangeText={(v) => setFieldValue(Fields.loan_period, v)}
                  />

                  <Switch
                    label="Use National Averages"
                    value={values.loan_national_average}
                    onPress={() => {
                      if (!values.loan_national_average) {
                        setFieldValue(Fields.loan_balance, 30000)
                        setFieldValue(Fields.loan_rate, '6.00')
                        setFieldValue(Fields.loan_period, '15')
                      } else {
                        setFieldValue(Fields.loan_balance, null)
                        setFieldValue(Fields.loan_rate, null)
                        setFieldValue(Fields.loan_period, null)
                      }

                      setFieldValue(Fields.loan_national_average, !values.loan_national_average)
                    }}
                  />

                  <InformationBlock
                    text="Don’t know your student loan information? That’s okay! The average student borrower graduates with approximately $30,000 in loans at a rate of 6%."
                  />
                </View>
              )}
          </>
        )}
      </Form>
    </View>
  )
}
