// React
import { useEffect, useLayoutEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import Table from './Table'

import { info, primary, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

import formatCurrency from '../../functions/formatCurrency'
import formatPercent from '../../functions/formatPercent'

const styles = StyleSheet.create({
  resultsContainer: {
    padding: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING * 5,
    backgroundColor: primary,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    textAlign: 'center',
    fontSize: 14,
    color: white
  },
  moneyContainer: {
    backgroundColor: info,
    borderRadius: 12,
    paddingHorizontal: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING
  },
  moneyText: {
    textAlign: 'center',
    fontSize: 42,
    color: white,
    fontWeight: 'bold'
  },
})

export default function HouseTable({ result, bestChoice, width }) {
  let assetA = result?.asset_a || {}
  let assetB = result?.asset_b || {}
  const paymentComparison = result?.result?.payment_comparison || {}
  const propertyCostComparison = result?.result?.property_cost_comparison || {}

  assetA = { ...assetA, ...(paymentComparison?.asset_a || {}), ...(propertyCostComparison.asset_a || {}) }
  assetB = { ...assetB, ...(paymentComparison?.asset_b || {}), ...(propertyCostComparison.asset_b || {}) }

  const assetAName = assetA.name || 'Home A'
  const assetBName = assetB.name || 'Home B'

  assetA.down_payment_percent = (assetA.down_payment / assetA.asset_price) * 100
  assetB.down_payment_percent = (assetB.down_payment / assetB.asset_price) * 100

  const labels = {
    asset_price: 'Value',
    down_payment: 'Down Payment',
    down_payment_percent: 'Down Payment (%)',
    amount: 'Loan Amount',
    interest_rate: 'Interest Rate',
    years: 'Terms of Financing',
    appreciation_rate: 'Rate of Appreciation',
    property_tax_rate: 'Property Tax Rate',
    interest: 'Interest',
    principal: 'Principal',
    total: 'Total Payment',
    taxes: 'Taxes',
    insurance: 'Insurance',
    hoa: 'HOA (Per Month)',
    piti: 'PITI'
  }
  const formatters = {
    asset_price: formatCurrency,
    down_payment: formatCurrency,
    down_payment_percent: formatPercent,
    amount: formatCurrency,
    interest_rate: formatPercent,
    years: null,
    appreciation_rate: formatPercent,
    property_tax_rate: formatPercent,
    interest: formatCurrency,
    principal: formatCurrency,
    total: formatCurrency,
    taxes: formatCurrency,
    insurance: formatCurrency,
    hoa: formatCurrency,
    piti: formatCurrency
  }

  const prefixes = {
    asset_price: '$',
    down_payment: '$',
    down_payment_percent: null,
    amount: '$',
    interest_rate: null,
    years: null,
    appreciation_rate: null,
    property_tax_rate: null,
    interest: '$',
    principal: '$',
    total: '$',
    taxes: '$',
    insurance: '$',
    hoa: '$',
    piti: '$'
  }

  const suffixes = {
    asset_price: null,
    down_payment: null,
    down_payment_percent: null,
    amount: null,
    interest_rate: null,
    years: 'yrs.',
    appreciation_rate: null,
    property_tax_rate: null,
    interest: null,
    principal: null,
    total: null,
    taxes: null,
    insurance: null,
    hoa: null,
    piti: null
  }

  return (
    <Table
      labels={labels}
      highlightedColumn={bestChoice == 'A' ? 0 : 1}
      data={[assetA, assetB]}
      formatters={formatters}
      prefixes={prefixes}
      suffixes={suffixes}
      assetLabels={[assetAName, assetBName]}
      width={width}
    />
  );
}
