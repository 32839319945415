import * as Yup from 'yup'

import { REQUIRED } from './constants'

export const Fields = {
  message: 'message',
  email: 'email'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.email]: Yup.string()
    .required(REQUIRED),
  [Fields.message]: Yup.string()
    .required(REQUIRED)
})

export const InitialValues = {
  [Fields.message]: '',
  [Fields.email]: ''
}
