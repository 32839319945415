import { useEffect, useState } from 'react'
import { TouchableOpacity, View, ScrollView, Text, StyleSheet, Platform } from 'react-native'

import { map, filter, orderBy, first } from 'lodash'
import Container from './Container'
import SearchInput from './SearchInput'

import { darkblue, lightestgray, white } from '../styles/colors'

import YoutubeTile from './YoutubeTile'

import { getPlaylist } from '../actions'

const styles = StyleSheet.create({
  container: {
    backgroundColor: lightestgray,
    position: 'relative'
  },
  listContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  scrollViewContainer: { paddingBottom: 200 }
})

export default function HowToDictionaryScreen({ playlistId, navigation, route }) {
  const [youtubeVideos, setYoutubeVideos] = useState([])
  const [youtubeSearch, setYoutubeSearch] = useState(null)

  async function fetchYoutubeVideos() {
    const items = await getPlaylist(playlistId)
    map(items, (item) => {
      let { title } = item.snippet
      title = title.replace(/What are |What is a |What is |What is an |/, '')
      item.key = title ? title.toUpperCase() : null
    })
    setYoutubeVideos(orderBy(items, 'key'))
  }

  useEffect(() => {
    fetchYoutubeVideos()
  }, [])

  const filteredYoutubeVideos = filter(youtubeVideos, (video) => {
    const { snippet } = video
    const { title } = snippet

    if (youtubeSearch == null || title.toLowerCase().match(youtubeSearch.toLowerCase())) {
      return true
    }
    return false
  })

  return (
    <Container style={styles.container}>
      <SearchInput
        includeMargin
        inverse
        placeholder={`Explore ${youtubeVideos.length} videos`}
        value={youtubeSearch}
        onChange={(value) => {
          setYoutubeSearch(value || null)
        }}
      />

      <ScrollView contentContainerStyle={styles.scrollViewContainer}>
        <View style={styles.listContainer}>
          {map(filteredYoutubeVideos, (tool) => <YoutubeTile route={route} {...tool} navigation={navigation} />)}
        </View>
      </ScrollView>
    </Container>
  );
}
