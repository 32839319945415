import { isEmpty, isNil, map, each, find } from 'lodash'
import { useLayoutEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { saveTransaction } from './actions'
import CurrencyInput from './components/CurrencyInput'
import DateTimePicker from './components/DateTimePicker'
import Form from './components/Form'
import Input from './components/Input'
import InputSelect from './components/InputSelect'
import {
  Fields,
  InitialValues,
  ValidationSchema
} from './schemas/transactionSchema'
import { currentUserSelector, budgetCategoriesSelector } from './selectors'
import { DEFAULT_SPACING, info, white } from './styles'

const styles = StyleSheet.create({
  container: {
    backgroundColor: white,
    paddingHorizontal: 12,
    position: 'relative'
  },
  iconContainer: {
    backgroundColor: white,
    alignItems: 'center',
    marginBottom: 2 * DEFAULT_SPACING
  },
  addLabelContainer: {
    alignItems: 'flex-start',
    marginLeft: 12
  },
  addLabel: {
    color: info,
    alignContent: 'stretch'
  },
  inputField: { marginBottom: DEFAULT_SPACING },
  button: { marginBottom: DEFAULT_SPACING }
})

function getBudgetCategoryOptions(allCategories) {
  return allCategories.map((cat) => {
    return {
      ...cat,
      value: cat.key
    }
  })
}

function getBudgetSubCategoryOptions(subCategories = []) {
  const subCategoryOptions = []

  each(subCategories, (item) => {
    subCategoryOptions.push({
      value: item.key,
      label: item.label
    })
  })

  return subCategoryOptions
}

function getBudgetSubCategories(budgetCategories = [], key) {
  const selectedBudgetCategory = find(
    budgetCategories,
    (cat) => cat.key === key
  )
  return selectedBudgetCategory
    ? getBudgetSubCategoryOptions(selectedBudgetCategory.subCategories)
    : []
}

export default function TransactionFormScreen({ route, navigation }) {
  const { params = {} } = route
  const {
    transaction = {},
    budgetCategory = {},
    budgetSubCategory = {}
  } = params

  const existingItem =
    !isEmpty(transaction) && !isNil(transaction.transactionId)

  const { id: userId } = useSelector(currentUserSelector)

  const dispatch = useDispatch()
  const handleSubmit = async (values) => {
    const transactionReq = {
      userId,
      name: values[Fields.name],
      amount: values[Fields.amount],
      date: values[Fields.date],
      budgetSubCategory: values[Fields.budgetSubCategory]
    }

    if (existingItem) {
      transactionReq.transactionId = transaction.transactionId
    }

    await dispatch(saveTransaction(transactionReq))
    navigation.goBack()
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: existingItem ? 'Edit Transaction' : 'New Transaction'
    })
  })

  const initialValues = InitialValues(
    transaction,
    budgetCategory,
    budgetSubCategory
  )

  const budgetCategories = useSelector(budgetCategoriesSelector)
  const budgetCategoryOptions = getBudgetCategoryOptions(budgetCategories)

  return (
    <Form
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      buttonText={existingItem ? 'Save' : 'Submit'}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, handleChange, values, errors, touched }) => {
        const budgetSubCategories = getBudgetSubCategories(
          budgetCategories,
          values[Fields.budgetCategory]
        )

        return (
          <View style={{ flex: 1 }}>
            <View style={styles.container}>
              <Input
                name={Fields.name}
                errorMessage={errors[Fields.name]}
                touched={touched[Fields.name]}
                style={styles.inputField}
                onChangeText={handleChange(Fields.name)}
                label="Name"
                value={values[Fields.name]}
              />

              <CurrencyInput
                name={Fields.amount}
                errorMessage={errors[Fields.amount]}
                touched={touched[Fields.amount]}
                style={styles.inputField}
                onChangeText={handleChange(Fields.amount)}
                label="Amount"
                value={values[Fields.amount]}
                precision={2}
              />

              <DateTimePicker
                name={Fields.date}
                errorMessage={errors[Fields.date]}
                touched={touched[Fields.date]}
                onChange={(v) => setFieldValue(Fields.date, v)}
                label="Date of Transaction"
                value={values[Fields.date]}
                display="spinner"
              />

              <InputSelect
                label="Budget Category"
                options={['', ...budgetCategoryOptions]}
                value={values[Fields.budgetCategory]}
                touched={touched[Fields.budgetCategory]}
                errorMessage={errors[Fields.budgetCategory]}
                onSubmitEditing={(v) => {
                  if (!v) return

                  setFieldValue(Fields.budgetCategory, v)
                  // set the first by default
                  const budgetSubCategories = getBudgetSubCategories(
                    budgetCategories,
                    v
                  )
                  setFieldValue(
                    Fields.budgetSubCategory,
                    budgetSubCategories[0].value
                  )
                }}
              />

              {values[Fields.budgetCategory] && (
                <InputSelect
                  label="Budget Sub Category"
                  small={true}
                  options={budgetSubCategories}
                  value={values[Fields.budgetSubCategory]}
                  touched={touched[Fields.budgetSubCategory]}
                  errorMessage={errors[Fields.budgetSubCategory]}
                  onSubmitEditing={(v) => {
                    if (!v) return
                    setFieldValue(Fields.budgetSubCategory, v)
                  }}
                />
              )}
            </View>
          </View>
        )
      }}
    </Form>
  )
}
