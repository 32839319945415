import { Fragment, useLayoutEffect, useRef } from 'react'
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions
} from 'react-native'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigationState } from '@react-navigation/native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import Form from '../Form'
import CurrencyInput from '../CurrencyInput'
import Input from '../Input'
import Button from '../Button'
import Card from '../Card'

import timeout from '../../functions/timeout'

import BuilderWrapper from './BuilderWrapper'

import { saveCurrentPlan } from '../../actions'

import { ValidationSchema, Fields } from '../../schemas/customCareerSchema'

import { currentPlanSelector } from '../../selectors'
import { black, gunmetal, info, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'
import HeaderLabel from '../HeaderLabel'

const basicStyles = {
  container: { flex: 1 },
  form: { backgroundColor: 'transparent' },
  image: {
    width: '100%',
    height: 100
  },
  card: {
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING * 2,
    borderRadius: 10
  },
  header: {
    textAlign: 'center',
    fontSize: 20,
    marginBottom: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING
  },
  subheader: {
    marginBottom: DEFAULT_SPACING * 2,
    color: gunmetal,
    textAlign: 'center',
    alignSelf: 'center'
  },
  text: {
    marginVertical: 8,
    color: gunmetal,
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center'
  },
  content: {},
  button: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    card: {
      width: 360,
      marginRight: 20,
      paddingTop: DEFAULT_SPACING * 4,
      paddingBottom: DEFAULT_SPACING * 3,
      marginTop: 0,
      justifyContent: 'space-between'
    },
    content: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    image: {
      width: '100%',
      height: 180
    },
    header: { fontSize: 24, marginBottom: 29 },
    subheader: { marginBottom: 100 },
    button: {
      marginTop: 'auto',
      paddingTop: 68,
      marginHorizontal: '20%'
    },
    text: { fontSize: 18, fontWeight: '500', marginBottom: 69 }
  },
  [minSize(DEVICE_SIZES.LG)]: { card: { width: 400 } }
})

export default function CareerForm({ route, navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()

  const form = useSelector((state) => currentPlanSelector(state))
  const navigationState = useNavigationState((state) => state)

  const annualIncomeField = useRef()

  const { title, subtitle, builder, useGradient, includeProgress, buttonText } =
    route.params

  const { step, custom_career_salary, custom_career_name } = form

  const planValues = {
    custom_career_salary,
    custom_career_name
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: builder || useGradient,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      headerTintColor: builder || useGradient ? white : black,
      title: null
    })
  }, [title, subtitle])

  const Wrapper = builder || useGradient ? BuilderWrapper : Fragment

  const window = useWindowDimensions()
  const { height } = window
  const showImage = height > 700

  return (
    <Wrapper
      style={styles.container}
      includeProgress={includeProgress}
      option="Career"
    >
      <Form
        showSubmitButton={false}
        containerStyle={styles.form}
        buttonText={buttonText}
        validationSchema={ValidationSchema}
        initialValues={planValues}
        withPadding={isNotWeb}
        onSubmit={async (values) => {
          await timeout(600)

          const index = navigationState.routes.findIndex(
            (route) => route.name == 'Plan Summary'
          )

          if (builder) {
            navigation.navigate('Modal', {
              screen: 'Investment Options',
              params: {
                title: 'Plan Builder',
                subtitle: 'Goal',
                builder,
                includeProgress,
                useGradient
              }
            })
          } else {
            navigation.pop(navigationState.routes.length - index - 1)
          }

          dispatch(
            saveCurrentPlan({
              custom_career_salary: values.custom_career_salary,
              custom_career_name: values.custom_career_name,
              occupation: null,
              input_custom_career: true,
              step: step === 1 ? 2 : step
            })
          )
        }}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          isSubmitting
        }) => (
          <View style={styles.content}>
            <Card style={styles.card}>
              <HeaderLabel style={styles.header}>Explore Careers</HeaderLabel>
              {showImage && (
                <Image
                  style={styles.image}
                  source={require('../../assets/career-explore.png')}
                  resizeMode="contain"
                />
              )}
              <Text style={styles.text}>
                Location-driven salary data for over{' '}
                <Text style={{ fontWeight: isNotWeb ? 400 : 600 }}>
                  900 careers
                </Text>{' '}
                and{' '}
                <Text style={{ fontWeight: isNotWeb ? 400 : 600 }}>
                  2,300 US locations
                </Text>
                !
              </Text>

              <Button
                style={styles.button}
                color={info}
                onPress={() => {
                  const params = {
                    title: 'Plan Builder',
                    subtitle: 'Explore Careers',
                    builder,
                    includeProgress,
                    useGradient
                  }
                  navigation.navigate('Modal', {
                    screen: 'Edit Career',
                    params
                  })
                }}
              >
                Search
              </Button>
            </Card>

            <Card style={styles.card}>
              <HeaderLabel style={styles.header}>
                Already Have a Career?
              </HeaderLabel>
              <Text style={styles.subheader}>
                Enter your title and expected annual income.
              </Text>
              <View>
                <Input
                  label="Job Title"
                  value={values.custom_career_name}
                  name={Fields.custom_career_name}
                  touched={touched[Fields.custom_career_name]}
                  errorMessage={errors[Fields.custom_career_name]}
                  onChangeText={handleChange(Fields.custom_career_name)}
                  onSubmitEditing={() => annualIncomeField.current.focus()}
                  returnKeyType="next"
                />

                <CurrencyInput
                  ref={annualIncomeField}
                  prefix="$"
                  label="Annual Income"
                  value={values.custom_career_salary}
                  name={Fields.custom_career_salary}
                  touched={touched[Fields.custom_career_salary]}
                  errorMessage={errors[Fields.custom_career_salary]}
                  onChangeText={handleChange(Fields.custom_career_salary)}
                  precision={0}
                  onSubmitEditing={handleSubmit}
                />
              </View>

              <Button
                style={styles.button}
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
                onPress={handleSubmit}
              >
                Continue
              </Button>
            </Card>
          </View>
        )}
      </Form>
    </Wrapper>
  )
}
