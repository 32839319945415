import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
  Animated
} from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import * as WebBrowser from 'expo-web-browser'

import HeaderLabel from './components/HeaderLabel'
import Carousel from './components/Carousel'
import FeatureCard from './components/tools/FeatureCard'

import { lightestgray, rgba, purple, gunmetal, info, white } from './styles/colors'
import { DEFAULT_SPACING, secondary } from './styles'
import ToolsListItem from './components/tools/ToolsListItem'
import CircleGradient from './components/CircleGradient'
import { useLayoutEffect } from 'react'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: lightestgray,
    position: 'relative'
  },
  navLabel: {
    paddingTop: 40,
    paddingHorizontal: 12
  },
  headerLabel: {
    marginVertical: DEFAULT_SPACING * 2,
    alignSelf: 'center',
    color: white,
    paddingTop: 60
  },
  carousel: {},
  featuredTools: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
    paddingBottom: 20
  },
  sectionsGroup: { paddingHorizontal: DEFAULT_SPACING }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
    container: {
      paddingHorizontal: 24,
      overflow: 'auto',
      paddingBottom: 40,
      left: 80,
      width: 'calc(100% - 80px)'
    },
    sectionsGroup: { paddingHorizontal: 0 }
  },
  [minSize(DEVICE_SIZES.LG)]: {
    container: {
      paddingHorizontal: 0,
      overflow: 'auto',
      paddingBottom: 100,
      width: '100%',
      left: 0
    },
    navLabel: {
      fontSize: 16,
      fontWeight: '600',
      color: secondary,
      paddingHorizontal: 0
    },
    headerLabel: {
      marginVertical: 32,
      alignSelf: 'flex-start',
      fontSize: 36,
      zIndex: 1000,
      paddingLeft: 144
    },
    carousel: { display: 'none' },
    featuredTools: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      overflow: 'auto',
      paddingBottom: 20,
      paddingHorizontal: 96,
      paddingLeft: 144
    },
    sectionsGroup: {
      marginTop: 80,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingHorizontal: 96,
      paddingLeft: 144
    }
  }
})

function ToolsScreen({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const window = useWindowDimensions()
  const { width } = window

  let sections = [
    {
      title: 'Calculators',
      subtitle: 'A powerful suite of financial calculators',
      icon: 'calculator-simple',
      iconColor: purple,
      iconBackgroundColor: rgba(purple, 0.1),
      onPress: () => navigation.navigate('Modal', { screen: 'Calculators' })
    },
    {
      icon: 'magnifying-glass-dollar',
      iconColor: '#0f1f84',
      iconBackgroundColor: rgba('#0f1f84', 0.1),
      title: 'Career Explore',
      subtitle: 'Explore careers in your area',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Career Explore Intro' })
      }
    },
    {
      icon: 'building-columns',
      iconColor: '#F2C064',
      iconBackgroundColor: rgba('#F2C064', 0.1),
      title: 'College Explore',
      subtitle: 'Explore colleges',
      onPress: () => {
        navigation.navigate('Modal', {
          screen: isNotWeb ? 'College Explore Intro' : 'College Explore Page'
        })
      }
    },
    {
      icon: 'location-question',
      iconColor: '#CD5969',
      iconBackgroundColor: rgba('#CD5969', 0.1),
      title: 'Cost of Living',
      subtitle: 'Compare cost of living',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Cost of Living Location' })
      }
    },
    {
      icon: 'filter-circle-dollar',
      iconColor: info,
      iconBackgroundColor: rgba(info, 0.1),
      title: 'Debt Ranking',
      subtitle: 'Rank your debts',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'My Debts' })
      }
    },
    {
      icon: 'books',
      iconColor: '#3EF2C0',
      iconBackgroundColor: rgba('#3EF2C0', 0.1),
      title: 'Finglish Dictionary',
      subtitle: 'Learn financial terms',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Finglish Dictionary' })
      }
    },
    {
      icon: 'hammer',
      iconColor: '#E4665E',
      iconBackgroundColor: rgba('#E4665E', 0.1),
      title: 'How To',
      subtitle: 'Learn about features in the Troutwood app',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'How To Dictionary' })
      }
    },
    isNotWeb
      ? {
          icon: 'book',
          iconColor: '#172E17',
          iconBackgroundColor: rgba('#172E17', 0.1),
          title: 'Library',
          subtitle: 'Read the Troutwood Library',
          onPress: () => {
            navigation.navigate('Modal', { screen: 'Library' })
          }
        }
      : null,
    {
      icon: 'right-left',
      iconColor: gunmetal,
      iconBackgroundColor: rgba(gunmetal, 0.1),
      title: 'Plan Comparison',
      subtitle: 'Compare two plans',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Saved Careers' })
      }
    },
    {
      icon: 'seedling',
      iconColor: '#2BD3FF',
      iconBackgroundColor: rgba('#2BD3FF', 0.1),
      title: 'Start Investing',
      subtitle: 'Learn where to start investing',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Start Investing Category' })
      }
    },
    {
      image: require('./assets/foolproof/foolproof-icon.png'),
      title: 'Fool Proof',
      subtitle: 'Interactive financial literacy modules',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'FoolProof' })
      }
    },
    {
      title: require('./assets/troutwood-alt.png'),
      subtitle: "Explore more of Troutwood's Products",
      image: require('./assets/logo.png'),
      imageBackgroundColor: lightestgray,
      onPress: () => navigation.navigate('Modal', { screen: 'Troutwood Tools' })
    }
  ]

  // remove nulls
  sections = sections.filter((s) => s)

  const defaultTools = [
    {
      title: require('./assets/feature/foolproof-logo.png'),
      subtitle: 'Budget Basics',
      image: require('./assets/feature/basic-budgeting.png'),
      buttonText: 'Get Started',
      onPress: () => {
        WebBrowser.openBrowserAsync('https://www.foolproofme.org/solo/basic-budgeting')
      }
    },
    {
      title: require('./assets/feature/foolproof-logo.png'),
      subtitle: 'Advanced Budgeting',
      image: require('./assets/feature/advanced-budgeting.png'),
      buttonText: 'Get Started',
      onPress: () => {
        WebBrowser.openBrowserAsync('https://www.foolproofme.org/solo/advanced-budgeting')
      }
    },
    {
      title: 'The Missing Semester Micro Quiz',
      subtitle: 'Investing and Time are a powerful combination',
      image: require('./assets/feature/micro-quiz.png'),
      buttonText: 'Take Quiz',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Quiz', params: { title: 'The Missing Semester' } })
      }
    },
    {
      title: 'Finglish',
      subtitle: 'Finance to English translation',
      image: require('./assets/feature/finglish.png'),
      buttonText: 'Browse Terms',
      onPress: () => {
        navigation.navigate('Modal', { screen: 'Finglish Dictionary' })
      }
    }
  ]

  const featuredTools = isNotWeb
    ? [
        {
          title: 'The Missing Semester',
          subtitle: 'An award-winning book in your library',
          image: require('./assets/feature/missing-semester.png'),
          buttonText: 'Library',
          onPress: () => {
            navigation.navigate('Modal', {
              screen: 'Library Item',
              params: {
                img_url: './assets/missing-semester.png',
                image: require('./assets/missing-semester.png'),
                pdf: 'https://troutwood-static-files.s3.us-east-2.amazonaws.com/missing-semester.pdf',
                title: 'The Missing Semester'
              }
            })
          }
        },
        ...defaultTools
      ]
    : defaultTools

  const slides = featuredTools.map((tool, i) => (
    <FeatureCard {...tool} key={`${tool.title}-${i}`} />
  ))

  const content = (
    <>
      {isNotWeb ? (
        <Carousel itemWidth={width} data={slides} height={400} />
      ) : (
        <View style={styles.featuredTools} horizontal>
          {slides}
        </View>
      )}
      <View style={styles.sectionsGroup}>
        {sections.map((section, i) => (
          <ToolsListItem key={i} {...section} action={section.title} />
        ))}
      </View>
    </>
  )

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  return (
    <View style={styles.container}>
      <Animated.View>
        <CircleGradient />
      </Animated.View>

      <HeaderLabel style={styles.headerLabel}>✨Featured Tools✨</HeaderLabel>

      {isNotWeb ? (
        <ScrollView contentContainerStyle={{ paddingBottom: 100 }}>{content}</ScrollView>
      ) : (
        content
      )}
    </View>
  )
}

export default ToolsScreen
