import { View } from 'react-native'
import FontAwesomeSpin from '../FontAwesomeSpin'
import { white } from '../../styles'

export default function LoadingIcon() {
  return (
    <>
      <View style={{ marginLeft: -20 }}>
        <FontAwesomeSpin opposite icon="gear" color={white} />
      </View>
      <View>
        <FontAwesomeSpin icon="gear" color={white} size={40} />
      </View>
      <View style={{ marginLeft: -20 }}>
        <FontAwesomeSpin opposite icon="gear" color={white} />
      </View>
    </>
  )
}
