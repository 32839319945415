/* eslint-disable sort-imports */
import { combineReducers } from 'redux'
import currentUser from './currentUser'
import currentPlan from './currentPlan'
import plans from './plans'
import notifications from './notifications'
import tokens from './tokens'
import loading from './loading'
import wellnessChecklists from './wellnessChecklists'
import personalMilestones from './personalMilestones'

import accounts from './accounts'
import occupations from './occupations'
import careerLocation from './careerLocation'
import bsAccountCategories from './bsAccountCategories'
import bsAccounts from './bsAccounts'
import budgetCategories from './budgetCategories'
import proBudget from './proBudget'
import financialProjections from './financialProjections'
import proPlans from './proPlans'
import currentProPlan from './currentProPlan'
import loans from './loans'
import branchEvents from './branchEvents'
import pendingInvitations from './pendingInvitations'
import lifeEvents from './lifeEvents'
import transactions from './transactions'
import appConfig from './appConfig'
import riskAssessment from './riskAssessment'
import militaryOccupation from './militaryOccupation'
import finPulse from './finPulse'
import finPulseStreaks from './finPulseStreaks'
import fastPlanModeData from './fastPlanModeData'

export default combineReducers({
  currentUser,
  currentPlan,
  tokens,
  loading,
  plans,
  notifications,
  wellnessChecklists,
  personalMilestones,
  accounts,
  occupations,
  careerLocation,
  bsAccountCategories,
  bsAccounts,
  budgetCategories,
  proBudget,
  financialProjections,
  proPlans,
  currentProPlan,
  loans,
  branchEvents,
  pendingInvitations,
  lifeEvents,
  transactions,
  appConfig,
  riskAssessment,
  militaryOccupation,
  finPulse,
  finPulseStreaks,
  fastPlanModeData,
})
