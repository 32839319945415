import { View, Text, Image, StyleSheet } from 'react-native'

import HeaderLabel from './components/HeaderLabel'
import Button from './components/Button'
import { DEFAULT_SPACING } from './styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  emergencyFinn: {
    height: 286,
    width: 286,
    marginBottom: 24
  },
  headerLabel: {
    marginBottom: 24,
    textAlign: 'center'
  },
  text: {
    width: '80%',
    textAlign: 'center'
  },
  bottomButtonStyle: {
    justifyContent: 'flex-end',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING
  }
})

export default function EmergencyPreparednessIntroScreen({ route, navigation }) {
  return (
    <>
      <View style={styles.container}>
        <Image resizeMode="cover" style={styles.emergencyFinn} source={require('./assets/finn/emergency-finn.png')} />
        <HeaderLabel style={styles.headerLabel}>
          Emergency Preparedness
        </HeaderLabel>
        <Text style={styles.text}>
          Sometimes life doesn't go as planned.
          An unexpected emergency can derail an entire
          save first plan. If you prepare you'll be
          ready for just about anything. Use our simple
          tool to help calculate and be prepared for the
          unexpected!
        </Text>
      </View>
      <View style={styles.bottomButtonStyle}>
        <Button onPress={() => navigation.navigate('Modal', { screen: 'Emergency Preparedness Monthly Expenses' })}>
          Get Started
        </Button>
      </View>
    </>
  );
}
