import { get } from 'http'

export const GET_MILITARY_OCCUPATION_REQUEST = 'GET_MILITARY_OCCUPATION_REQUEST'
export const GET_MILITARY_OCCUPATION_SUCCESS = 'GET_MILITARY_OCCUPATION_SUCCESS'
export const GET_MILITARY_OCCUPATION_FAILURE = 'GET_MILITARY_OCCUPATION_FAILURE'

export const getMilitaryOccupationsRequest = () => ({ type: GET_MILITARY_OCCUPATION_REQUEST, })

export const getMilitaryOccupationsSuccess = (data) => ({
  type: GET_MILITARY_OCCUPATION_SUCCESS,
  payload: data,
})

export const getMilitaryOccupationsFailure = (error) => ({
  type: GET_MILITARY_OCCUPATION_FAILURE,
  payload: error,
})

export const getMilitaryOccupations = () => async (dispatch) => {
  dispatch(getMilitaryOccupationsRequest())

  try {
    const res = await get('api/v1/occupations/military/')
    dispatch(getMilitaryOccupationsSuccess(res.data))
  } catch (error) {
    dispatch(getMilitaryOccupationsFailure(error.message))
  }
}
