import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Divider } from 'react-native-elements'
import { white } from '../../../styles'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#DADDDD',
    borderWidth: 1,
    borderColor: '#c9cccc',
    borderRadius: 12,
    height: 72,
    overflow: 'hidden'
  },
  option: {
    textAlign: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    padding: 20,
    fontWeight: '600',
    fontSize: 18
  }
})

function YesNoOptionGroup({ options, value, onPress }) {
  return (
    <View style={styles.container}>
      {options.map((option, index) => (
        <>
          <View
            key={option.value}
            style={{
              width: `${100 / options.length}%`,
              backgroundColor:
                value === option.value ? '#5F8EE8' : 'transparent'
            }}
          >
            <TouchableOpacity onPress={() => onPress(option.value)}>
              <Text
                style={[
                  styles.option,
                  { color: value === option.value ? white : '#707A7D' }
                ]}
              >
                {option.label}
              </Text>
            </TouchableOpacity>
          </View>
          {index < options.length - 1 && <Divider orientation="vertical" />}
        </>
      ))}
    </View>
  )
}

export default YesNoOptionGroup
