import Flag from './Flag'

const PrimaryPlanFlag = ({ left = true, pro = false }) => (
  <Flag
    left={left}
    source={
      pro ?
        require('../assets/pro-banner.png') :
        require('../assets/default-banner.png')
    }
  />
)

export default PrimaryPlanFlag
