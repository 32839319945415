import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX,
  INVALID_MUST_BE_GREATER_THAN_ZERO
} from '../constants'

export const Fields = {
  totalDollarsAvailable: 'total_dollars_available',
  assetAName: 'asset_a_name',
  assetADownPayment: 'asset_a_down_payment',
  assetAAssetPrice: 'asset_a_asset_price',
  assetAInterestRate: 'asset_a_interest_rate',
  assetAMonths: 'asset_a_months',
  assetBName: 'asset_b_name',
  assetBDownPayment: 'asset_b_down_payment',
  assetBAssetPrice: 'asset_b_asset_price',
  assetBInterestRate: 'asset_b_interest_rate',
  assetBMonths: 'asset_b_months'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.totalDollarsAvailable]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetAName]: Yup.string()
    .nullable(),
  [Fields.assetADownPayment]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .test(Fields.assetADownPayment, 'Down Payment cannot be greater than loan amount', function (value) {
      const { asset_a_asset_price } = this.parent
      if (!value) return true;
      return value <= asset_a_asset_price
    })
    .test(Fields.assetADownPayment, 'Down Payment cannot be greater than total dollars available', function (value) {
      const { total_dollars_available } = this.parent
      if (!value) return true;
      return value <= total_dollars_available
    })
    .required(REQUIRED),
  [Fields.assetAAssetPrice]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MUST_BE_GREATER_THAN_ZERO)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetAInterestRate]: Yup.number()
    .positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetAMonths]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(240, `${INVALID_MAX}240`)
    .nullable()
    .required(REQUIRED),

  [Fields.assetBName]: Yup.string()
    .nullable(),
  [Fields.assetBDownPayment]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .test(Fields.assetBDownPayment, 'Down Payment cannot be greater than loan amount', function (value) {
      const { asset_b_asset_price } = this.parent
      if (!value) return true;
      return value <= asset_b_asset_price
    })
    .test(Fields.assetBDownPayment, 'Down Payment cannot be greater than total dollars available', function (value) {
      const { total_dollars_available } = this.parent
      if (!value) return true;
      return value <= total_dollars_available
    })
    .required(REQUIRED),
  [Fields.assetBAssetPrice]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MUST_BE_GREATER_THAN_ZERO)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetBInterestRate]: Yup.number()
    .positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetBMonths]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(240, `${INVALID_MAX}240`)
    .nullable()
    .required(REQUIRED)
})

export const InitialValues = {
  [Fields.totalDollarsAvailable]: '0',
  [Fields.assetAAssetPrice]: '150000',
  [Fields.assetADownPayment]: '10000',
  [Fields.assetAInterestRate]: '3',
  [Fields.assetAMonths]: '84',
  [Fields.assetBAssetPrice]: '100000',
  [Fields.assetBDownPayment]: '10000',
  [Fields.assetBInterestRate]: '5',
  [Fields.assetBMonths]: '20',
}
