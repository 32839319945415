import { StyleSheet, Text, View } from 'react-native'
import { DEFAULT_SPACING, gunmetal, primary, red, white } from 'styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import moment from 'moment'
import { info, lightestgray } from '../styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: white,
    marginLeft: DEFAULT_SPACING,
    width: 95,
    height: 95,
    elevation: 5,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: lightestgray
  },
  calendarHeader: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: info,
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 28
  },
  label: {
    fontSize: 12,
    color: white
  },
  body: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 6,
  },
})

export default function CalendarItem({ label, children, style={} })  {
  return (
    <View style={[styles.container, style]}>
      <View style={styles.calendarHeader}>
        <Text style={styles.label}>
          {label}
        </Text>
      </View>
      <View style={styles.body}>
        {children}
      </View>
    </View>
  )
}
