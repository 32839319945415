import { filter, find, map } from 'lodash'
import { View, Text } from 'react-native'
import { Defs, LinearGradient, Stop } from 'react-native-svg'
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
  VictoryScatter,
  VictoryTheme
} from 'victory-native'
import PropTypes from 'prop-types'
import formatCurrency from '../functions/formatCurrency'
import {
  darkblue,
  DEFAULT_SPACING,
  lightblue,
  pink,
  secondary
} from '../styles'

function processSingleDate(date) {
  const newDate = date
  newDate.setDate(1)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

function processData(data) {
  return map(data, (item) => {
    const { date, netWorth } = item

    return {
      date: processSingleDate(date),
      netWorth: netWorth || 0
    }
  })
}

/**
 *
 * @param {*} processedData Receives array of objects containing date and netWorth
 * @returns Dates from the above array for the X-axis values
 */
function getDates(processedData) {
  const dates = map(processedData, 'date')
  return dates
}

/**
 * Receives array of objects containing date and netWorth
 * @returns netWorth from the above array for the Y-axis values
 */
function getNetWorthValues(processedData) {
  const nwValues = map(processedData, 'netWorth')
  return nwValues
}

/**
 * Returns all dates and net worth values for the 'ACTUALS' portion of the graph,
 * i.e., where date < today
 */
function getActuals(processedData, currentDate) {
  return filter(processedData, (item) => item.date <= currentDate)
}

/*
 * Returns all dates and net worth values for the 'PROJECTIONS' portion of the graph,
 * i.e., where date > today
 */
function getProjected(processedData, currentDate) {
  return filter(processedData, (item) => item.date >= currentDate)
}

/**
 * @returns Data point (date and netWorth) for the selected date
 */
function getSelectedDateInfo(processedData, processedSelectedDate) {
  const selected = find(processedData, { date: processedSelectedDate })
  return selected
}

/**
 * Expected data example:
 * const data = [{
    date: new Date(2022, 7, 4, 12, 12, 12, 12),
    netWorth: 50000
  },
  {
    date: new Date(2022, 8),
    netWorth: 55000
  },
  {
    date: new Date(2022, 9),
    netWorth: 55300
  },
  {
    date: new Date(2022, 10),
    netWorth: 55000
  },
  {
    date: new Date(2022, 11),
    netWorth: 55500
  },
  {
    date: new Date(2023, 0),
    netWorth: 56060
  },
  {
    date: new Date(2023, 1),
    netWorth: 57000
  }]
 * */
export default function NetWorthGraph({
  data,
  selectedDate,
  netWorthToday,
  style
}) {
  if (!data || data.length <= 0) {
    return
  }

  const processedData = processData(data)
  const processedSelectedDate = processSingleDate(selectedDate)
  const currentDate = processSingleDate(new Date())

  const dates = getDates(processedData)
  const netWorthValues = getNetWorthValues(processedData)
  const selectedDateInfo = getSelectedDateInfo(
    processedData,
    processedSelectedDate
  )
  const actuals = getActuals(processedData, currentDate)
  const projected = getProjected(processedData, currentDate)

  const actualsExist = actuals.length !== 0
  const projectedExist = projected.length !== 0
  const selectedExists = selectedDateInfo !== undefined

  const yAxisPadding = 1000

  return (
    <View style={[{ padding: DEFAULT_SPACING }, style]}>
      <Text style={{ color: secondary }}>My Net Worth</Text>
      <Text style={{ fontSize: 28, color: darkblue }}>
        ${formatCurrency(netWorthToday)}
      </Text>

      <VictoryChart
        theme={VictoryTheme.material}
        scale={{ x: 'time', y: 'linear' }}
        domain={{
          y: [
            Math.min(...netWorthValues) - yAxisPadding,
            Math.max(...netWorthValues) + yAxisPadding
          ]
        }}
      >
        {/* These defs are used in the gradients below */}
        <Defs>
          <LinearGradient id="blue" x1="0%" y1="0%" x2="0%" y2="100%">
            <Stop offset="0" stopColor={lightblue} stopOpacity={0.8} />
            <Stop offset="1" stopColor={lightblue} stopOpacity={0} />
          </LinearGradient>
          <LinearGradient id="pink" x1="0%" y1="0%" x2="0%" y2="100%">
            <Stop offset="0" stopColor={pink} stopOpacity={0.8} />
            <Stop offset="1" stopColor={pink} stopOpacity={0} />
          </LinearGradient>
        </Defs>

        <VictoryLegend
          orientation="horizontal"
          gutter={20}
          colorScale={[lightblue, pink]}
          data={[
            { name: 'Actual', symbol: { type: 'minus' } },
            { name: 'Projected', symbol: { type: 'minus' } }
          ]}
        />

        <VictoryGroup offset={0}>
          {actualsExist && (
            <VictoryArea
              style={{
                data: { fill: 'url(#blue)', stroke: lightblue, strokeWidth: 2 }
              }}
              data={actuals}
              x="date"
              y="netWorth"
            />
          )}
          {projectedExist && (
            <VictoryArea
              style={{
                data: { fill: 'url(#pink)', stroke: pink, strokeWidth: 2 }
              }}
              data={projected}
              x="date"
              y="netWorth"
            />
          )}
          {selectedExists && (
            <VictoryScatter
              style={{ data: { fill: lightblue } }}
              size={7}
              data={[selectedDateInfo]}
              x="date"
              y="netWorth"
            />
          )}
          <VictoryAxis
            tickValues={dates}
            tickFormat={(t) =>
              `${t.toLocaleString('default', {
                month: 'short',
                year: '2-digit'
              })}`
            }
            gridComponent={<></>}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(t) => `${Math.round(t / 1000)}k`}
          />
        </VictoryGroup>
      </VictoryChart>
    </View>
  )
}

NetWorthGraph.defaultProps = { style: {} }

NetWorthGraph.propTypes = {
  data: PropTypes.array.isRequired,
  selectedDate: PropTypes.object.isRequired,
  netWorthToday: PropTypes.number.isRequired,
  style: PropTypes.object
}
