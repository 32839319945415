function formatNegativeCurrency(value) {
  if(value[0] === '-') {
    return `-$${value.slice(1)}`
  } else {
    return `$${value}`
  }
}

export default function formatCurrency(value, { accurate=false, showDollarSign=false } = {}) {
  // if accurate, it should return a string with 2 decimal places
  // if not accurate, it should return a string with no decimal places
  // this needs to remove commas and dollar signs

  if (value === undefined || value === null) {
    return '-'
  }

  const valueString = value.toString()
  const valueStringWithoutCommas = valueString.replace(/,/g, '')
  const valueStringWithoutCommasAndDollarSign = valueStringWithoutCommas.replace('$', '')

  const valueNumber = Number(valueStringWithoutCommasAndDollarSign)

  // the value should be formatted with commas if required
  const valueStringWithCommas = accurate ? 
    valueNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 
      valueNumber.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })

  // the value should be formatted with a dollar sign if required
  const valueStringWithDollarSign = showDollarSign ? formatNegativeCurrency(valueStringWithCommas) : valueStringWithCommas

  return valueStringWithDollarSign
}
