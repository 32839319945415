import { useState } from 'react'
import { TouchableOpacity, Text } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import hapticFeedback from './hapticFeedback'
import Modal from './Modal'
import { gray, lightgray, rgba } from '../styles'

export default function TooltippedIcon({ children, style, iconSize, iconStyle }) {
  const [visible, setVisible] = useState(false)
  const onPress = () => {
    setVisible(true)
    hapticFeedback()
  }

  return (
    <TouchableOpacity
      style={style}
      onPress={onPress}
    >
      <FontAwesomeIcon icon={['fas', 'circle-info']} size={iconSize} style={iconStyle} color={lightgray} />

      <Modal closeable visible={visible} setVisible={setVisible}>
        {children}
      </Modal>
    </TouchableOpacity>
  )
}

TooltippedIcon.defaultProps = {
  children: null,
  style: {},
  iconStyle: {},
  iconSize: 30
}

TooltippedIcon.propTypes = {
  children: PropTypes.object,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  iconSize: PropTypes.number
}
