export const REQUIRED = 'Required'
export const INVALID_NUMBER = 'Invalid number'
export const INVALID_MIN = 'Invalid Minimum - Must be greater than 0'
export const INVALID_MUST_BE_GREATER_THAN_ZERO = 'Invalid Minimum - Must be greater than 0'
export const INVALID_MAX = 'Invalid Maximum - Must be less than '
export const INVALID_INT = 'Invalid Format - Must be a whole number'
export const INVALID_AMOUNT = 'Invalid Format (ex: 200)'
export const INVALID_EMAIL = 'Invalid Format (ex: name@website.com)'
export const INVALID_PERCENT = 'Invalid Format - (ex: 3.97)'
export const INVALID_PERCENT_MAX = 'Invalid Format - Must be less than or equal to 100'
export const INVALID_PERCENT_MIN = 'Invalid Format - Must be greater than or equal to '
export const INVALID_PERIOD = 'Invalid Format (ex: 30)'
export const INVALID_PERIOD_30_MAX = 'Invalid Format - Must be less than or equal to 30'
export const INVALID_PERIOD_84_MAX = 'Invalid Format - Must be less than or equal to 84'

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\.])[A-Za-z\d@$!%*#?&\.]{9,}$/
