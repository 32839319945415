import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { StyleSheet } from 'react-native'
import hapticFeedback from './hapticFeedback'
import TouchableOpacity from './TouchableOpacity'

const useStyles = CreateResponsiveStyle({
  icon: {
    paddingLeft: 70,
    marginVertical: 12,
  }
}, { [minSize(DEVICE_SIZES.LG)]: { icon: { paddingLeft: 0, }, }, })

export default function FilterButton({ onPress, iconColor, iconSize, style = {} }) {
  const styles = useStyles()
  return (
    <TouchableOpacity
      style={style}
      onPress={() => {
        hapticFeedback()
        if (onPress) onPress()
      }}
    >
      <FontAwesomeIcon
        icon={['fas', 'sliders']}
        color={iconColor}
        size={iconSize}
        style={styles.icon}
      />
    </TouchableOpacity>
  )
}
