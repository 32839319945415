import { useEffect, useLayoutEffect, useState } from 'react'
import {
  Image,
  Platform,
  View,
  ScrollView,
  Text,
  StyleSheet
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  faChevronCircleDown,
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons'
import hapticFeedback from './components/hapticFeedback'
import CircleIcon from './components/CircleIcon'
import TouchableOpacity from './components/TouchableOpacity'

import { darkblue, gunmetal, lightestgray, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import PageHeader from './components/PageHeader'
import { LoanCalculatorForm } from './LoanCalculatorScreen'
import { LoanCalculatorResults } from './LoanCalculatorResultsScreen'
import ToolsHeaderWrapper from './components/tools/ToolsHeaderWrapper'

const basicStyles = {
  wrapper: {
    backgroundColor: lightestgray,
    position: 'relative',
    flex: 1
  },
  main: {},
  container: {
    borderRadius: 13,
    backgroundColor: white,
    borderWidth: 1,
    borderColor: 'rgba(70, 90, 106, 0.12)',
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'row',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING
  },
  mainContainer: { paddingBottom: 100 },
  listContainer: {
    marginTop: DEFAULT_SPACING,
    justifyContent: 'space-around'
  },
  contentWrapper: { marginBottom: DEFAULT_SPACING },
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 63,
    height: 63,
    borderRadius: 63 / 2,
    overflow: 'hidden'
  },
  icon: {
    width: 60,
    height: 60,
    borderRadius: 60
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0,
    marginLeft: DEFAULT_SPACING
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5,
    marginLeft: DEFAULT_SPACING,
    marginTop: 4
  },
  right: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
    wrapper: {
      padding: 0,
      overflow: 'auto'
    },
    main: {
      overflowX: 'auto',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      paddingHorizontal: 24
    },
    mainContainer: {
      minWidth: 960,
      flexDirection: 'row',
      paddingBottom: 24,
      alignItems: 'flex-start'
    },
    listContainer: {
      width: '33%',
      maxWidth: 420,
      marginTop: 0
    },
    container: {
      marginBottom: 0,
      borderRadius: 0,
      borderColor: white
    },
    contentWrapper: {
      borderRadius: 13,
      overflow: 'hidden'
    },
    right: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      marginLeft: 24,
      padding: 40,
      backgroundColor: white,
      borderRadius: 12
    },
    icon: {
      width: 86,
      height: 86
    },
    title: { fontSize: 22 },
    subtitle: {
      fontSize: 16,
      fontWeight: '600'
    }
  },
  [minSize(DEVICE_SIZES.LG)]: {
    wrapper: { padding: 40 },
    main: { paddingHorizontal: 0 }
  }
})

const isNotWeb = Platform.OS !== 'web'

function ListOptionItem({
  icon,
  iconColor,
  iconBackgroundColor,
  params,
  title,
  subtitle,
  onPress,
  activeCalculator,
  navigation
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [isShowForm, setIsShowForm] = useState(title === activeCalculator)

  useEffect(() => {
    setIsShowForm(title === activeCalculator)
  }, [activeCalculator])

  return (
    <View style={styles.contentWrapper}>
      <TouchableOpacity
        onPress={() => {
          if (title !== activeCalculator || isNotWeb) onPress()
          else setIsShowForm((prev) => !prev)
          if (isNotWeb) hapticFeedback()
        }}
      >
        <View style={styles.container}>
          {icon && (
            <CircleIcon
              style={styles.icon}
              size={isNotWeb ? 30 : 34}
              backgroundColor="rgb(255,234,248)"
              color="rgb(249,51,177)"
              icon={icon}
              iconColor={iconColor}
              iconBackgroundColor={iconBackgroundColor}
            />
          )}
          <View style={{ flex: 2 }}>
            <View style={{ flexDirection: 'row' }}>
              <Text numberOfLines={1} style={styles.title}>
                {title}
              </Text>
            </View>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>
          <View style={{ width: 50 }}>
            <FontAwesomeIcon
              style={{ alignSelf: 'flex-end' }}
              icon={isShowForm ? faChevronCircleDown : faChevronCircleRight}
              size={18}
              color="rgb(223, 230, 236)"
            />
          </View>
        </View>
      </TouchableOpacity>
      {!isNotWeb && isShowForm && (
        <LoanCalculatorForm {...params} navigation={navigation} />
      )}
    </View>
  )
}

export default function LoanCalculatorSelectionScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { params } = route
  const title = 'Loan Calculator'
  const autoParams = {
    title: 'Auto',
    amount: '$20,000',
    interest_rate: '5.00%',
    periods: '60'
  }
  const mortgageParams = {
    title: 'Mortgage',
    amount: '$200,000',
    interest_rate: '3.92%',
    periods: '30'
  }
  const studentParams = {
    title: 'Student Loans',
    amount: '$30,000',
    interest_rate: '6.00%',
    periods: '15'
  }
  const [activeCalculator, setActiveCalculator] = useState('Auto Loan')

  const categories = [
    {
      icon: 'car',
      title: 'Auto Loan',
      subtitle: 'Calculate the costs for the auto loan',
      iconBackgroundColor: 'pink',
      params: autoParams,
      onPress: () => {
        if (isNotWeb)
          navigation.navigate('Modal', {
            screen: 'Loan Calculator',
            params: autoParams
          })
        else setActiveCalculator('Auto Loan')
      }
    },
    {
      icon: 'house',
      title: 'Mortgage',
      subtitle: 'Calculate the costs for the home purchase',
      iconBackgroundColor: 'pink',
      params: mortgageParams,
      onPress: () => {
        if (isNotWeb)
          navigation.navigate('Modal', {
            screen: 'Loan Calculator',
            params: mortgageParams
          })
        else setActiveCalculator('Mortgage')
      }
    },
    {
      icon: 'building-columns',
      title: 'Student Loans',
      subtitle: 'Calculate the costs for student loans',
      iconBackgroundColor: 'pink',
      params: studentParams,
      onPress: () => {
        if (isNotWeb)
          navigation.navigate('Modal', {
            screen: 'Loan Calculator',
            params: studentParams
          })
        else setActiveCalculator('Student Loans')
      }
    }
  ]

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: isNotWeb })
  }, [navigation])

  return (
    <View style={styles.wrapper}>
      {!isNotWeb && (
        <ToolsHeaderWrapper>
          <PageHeader
            title={title}
            subTitle="Use our suite of loan calculators to explore costs of mortgages, auto loans and student loans."
          />
        </ToolsHeaderWrapper>
      )}
      <View style={styles.main}>
        <ScrollView
          contentContainerStyle={styles.mainContainer}
          style={{
            padding: isNotWeb ? DEFAULT_SPACING : 0,
            minWidth: isNotWeb ? 0 : 960
          }}
        >
          <View style={styles.listContainer}>
            {map(categories, (category) => (
              <ListOptionItem
                {...category}
                navigation={navigation}
                key={category.title}
                navigatable
                activeCalculator={activeCalculator}
              />
            ))}
          </View>
          {!isNotWeb && (
            <View style={styles.right}>
              {params ? (
                <LoanCalculatorResults {...params} />
              ) : (
                <Image
                  source={require('./assets/loan-calculator-intro.png')}
                  style={{ width: '60%', maxWidth: 500, height: '100%' }}
                  resizeMode="contain"
                />
              )}
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  )
}
