import { StyleSheet, View } from 'react-native'

import { map } from 'lodash'
import CheckListItem from './ChecklistItem'

import Divider from '../Divider'
import PillLabel from '../PillLabel'

import { lightgray, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  headerContainer: {
    margin: 10,
    position: 'relative'
  },
  divider: { borderBottomColor: lightgray },
  pillLabel: {
    backgroundColor: lightgray,
    alignSelf: 'center',
    position: 'absolute',
    top: -10
  },
  pillLabelText: { color: white },
  container: {
    marginHorizontal: DEFAULT_SPACING,
    position: 'relative'
  }
})


export default function CategoryGroup({ onCheck, category, wellnessChecklist, completed, goToWellnessPage, handleInfo }) {

  return (
    <View>
      <View style={styles.headerContainer} />

      <View style={styles.container}>

        {map(wellnessChecklist, (item) => <CheckListItem onCheck={onCheck} completed={completed} goToWellnessPage={goToWellnessPage} handleInfo={handleInfo} item={item} />)}
      </View>
    </View>
  )
}
