import { useLayoutEffect, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import { useDispatch, useSelector } from 'react-redux'
import Input from './components/Input'
import Form from './components/Form'
import Container from './components/Container'
import DateTimePicker from './components/DateTimePicker'
import TouchableOpacity from './components/TouchableOpacity'

import DeleteSlideupDrawer from './components/DeleteSlideupDrawer'

import { currentUserSelector } from './selectors'

import { saveCurrentUser, logout } from './actions'

import {
  ValidationSchema,
  Fields
} from './schemas/userSchema'
import Link from './components/Link'

const styles = StyleSheet.create({
  linkContainer: {
    justifyContent: 'flex-start',
    marginTop: 30
  },
  link: {
    color: 'rgb(222, 54, 84)',
    fontSize: 16,
    fontWeight: '600'
  }
})

export default function EditProfileScreen({ route, navigation }) {
  const dispatch = useDispatch()
  const emailField = useRef(null)
  const lastNameField = useRef(null)

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)
  const [visible, setVisible] = useState(false)

  const currentUser = useSelector((state) => currentUserSelector(state))

  useLayoutEffect(() => {
    navigation.setOptions({ title: 'Edit Profile' })
  }, [navigation])

  function handleDeleteAccountPress() {
    setVisible(true)
  }

  async function deleteAccount() {
    try {
      await dispatch(saveCurrentUser(currentUser, { is_active: false }))

      dispatch(logout())

      navigation.reset({
        key: null,
        index: 0,
        routes: [{ name: 'Login' }]
      })
    } catch (e) {
      console.error(e)
      actions.setFieldError(Fields.email, 'Unable to delete account');
    }
  }

  return (
    <Container style={styles.container}>
      <Form
        buttonText="Update"
        validationSchema={ValidationSchema}
        initialValues={currentUser}
        onSubmit={async (values, actions) => {
          try {
            const { first_name, last_name, email, birth_date, password } = values

            await dispatch(saveCurrentUser(currentUser, { first_name, last_name, email, birth_date, password, is_active: true }))

            navigation.reset({
              key: null,
              index: 0,
              routes: [{ name: 'Main' }],
            })

            navigation.reset({
              key: null,
              index: 0,
              routes: [{ name: 'Dashboard' }],
            })
          } catch (e) {
            if (e.response && e.response.data && e.response.data.password) {
              actions.setFieldError(Fields.password, e.response.data.password.join(', '));
            } else {
              actions.setFieldError(Fields.email, 'Unable to create a user with that email');
            }
          }
        }}
      >
        {({ handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting, values, errors, touched }) => (
          <>
            <Input
              name={Fields.firstName}
              errorMessage={errors[Fields.firstName]}
              touched={touched[Fields.firstName]}
              onChangeText={handleChange(Fields.firstName)}
              onBlur={handleBlur(Fields.firstName)}
              value={values[Fields.firstName]}
              label="First Name"
              returnKeyType="next"
              onSubmitEditing={() => lastNameField.current.focus()}
            />

            <Input
              ref={lastNameField}
              name={Fields.lastName}
              errorMessage={errors[Fields.lastName]}
              touched={touched[Fields.lastName]}
              onChangeText={handleChange(Fields.lastName)}
              onBlur={handleBlur(Fields.lastName)}
              value={values[Fields.lastName]}
              label="Last Name"
              returnKeyType="next"
              onSubmitEditing={() => emailField.current.focus()}
            />

            <Input
              ref={emailField}
              name={Fields.email}
              errorMessage={errors[Fields.email]}
              touched={touched[Fields.email]}
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              label="Email Address"
            />

            <DateTimePicker
              label="Date Of Birth"
              value={values[Fields.birthDate]}
              errorMessage={errors[Fields.birthDate]}
              touched={touched[Fields.birthDate]}
              onChangeText={handleChange(Fields.birthDate)}
              onBlur={handleBlur(Fields.birthDate)}
              onChange={(date) => {
                setFieldValue(Fields.birthDate, date)
              }}
            />

            <Link onPress={handleDeleteAccountPress} style={styles.link} containerStyle={styles.linkContainer}>
              Delete My Account
            </Link>
          </>
        )}
      </Form>

      <DeleteSlideupDrawer
        visible={visible}
        setVisible={setVisible}
        onPress={deleteAccount}
        header="Delete Account"
        body="We are sad to see you go."
        buttonText="Delete Account"
      />
    </Container>
  );
}
