import { View, Image, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  image: {
    height: 200,
    width: '100%',
    marginBottom: 30,
    borderRadius: 10
  }
})

export default function OccupationGroupCard({ image }) {
  return (
    <Image
      source={{ uri: image.url }}
      style={styles.image}
    />
  )
}
