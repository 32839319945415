import { post } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import {
  budgetCategoriesSelector,
  currentUserSelector,
  proPlansSelector
} from '../selectors'
import { setCurrentProPlan } from './setCurrentProPlan'
import { assignPrimaryProPlan } from './assignPrimaryProPlan'
import { setBudgetItems } from './setBudgetItems'
import { setProPlan } from './setProPlan'
import handleError from '../functions/handleError'

// eslint-disable-next-line import/prefer-default-export
export const createProPlanFromFree = (freePlan) => async (dispatch, getState) => {
  try {
    const state = getState()
    const { id: userId } = currentUserSelector(state)
    const budgetCategories = budgetCategoriesSelector(state)
    const proPlans = proPlansSelector(state)
    const proPlansMarkedAsPrimary = proPlans.filter(
      (proPlan) => proPlan.isPrimary
    )

    /* Create plan */
    const proPlanResult = await post(
      proApplicationEndpoints.createPlanFromFree(userId),
      { freePlanId: freePlan.id }
    )

    const proPlan = proPlanResult.data.plan
    const proBudgetItems = proPlanResult.data.budget_items

    /* Store created plan in Store */
    dispatch(setProPlan({ plan: proPlan }))

    // ensure that if the plan is primary, it gets assigned primary
    // if there are no primary pro plans, mark this one as primary
    if (freePlan.is_primary_plan) {
      dispatch(assignPrimaryProPlan(proPlan, true))
    } else if (proPlansMarkedAsPrimary.length === 0) {
      dispatch(assignPrimaryProPlan(proPlan, true))
    }

    /*
      Set this plan as the current pro plan (important for budgeting)
      That's why we wait for it to be set
    */
    await dispatch(
      setCurrentProPlan(
        proPlan
      )
    )

    dispatch(
      setBudgetItems({ budgetItems: proBudgetItems, budgetCategories })
    )
  } catch (e) {
    console.warn(e, e?.response, e?.response?.data)
    handleError(e)
  }
}
