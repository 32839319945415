import { useLayoutEffect } from 'react'
import { reduce } from 'lodash'
import { StyleSheet, Text, View } from 'react-native'
import { black, DEFAULT_SPACING, gunmetal, primary, red, lightgray, white } from 'styles'
import { useSelector } from 'react-redux'
import {
  proPlansSelector,
  bsAccountsSelector
} from 'selectors'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import CircleIcon from 'components/CircleIcon'
import TouchableOpacity from 'components/TouchableOpacity'
import { ASSET, LIABILITY, NAVIGATION_TYPE } from './constants'
import TooltippedIcon from './components/TooltippedIcon'
import { info, lightestgray, rgba } from './styles'
import { Fields } from './schemas/proCashWaterfallSchema'
import { bsAccountSelector, primaryProPlanSelector } from './selectors'

/* Styling */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white,
    paddingHorizontal: DEFAULT_SPACING
  },
  box: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: lightestgray,
    padding: DEFAULT_SPACING / 2,
    width: 100,
    height: 100,
  },
  boxIconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boxContainer: {
    width: 100,
    alignItems: 'center',
  },
  boxTitle: {
    fontSize: 12,
    marginTop: DEFAULT_SPACING / 2,
  },
  line: (includeLine) => ({
    height: 50,
    width: 6,
    backgroundColor: includeLine ? lightestgray : 'transparent',
    borderRadius: 6,
    marginVertical: 6
  }),
  optionContainer: { flexDirection: 'row', },
  option: {
    height: 100,
    paddingLeft: DEFAULT_SPACING * 2,
    flex: 4
  },
  optionTitle: {
    fontSize: 16,
    fontWeight: '600',
    width: '80%',
    marginBottom: DEFAULT_SPACING / 2
  },
  optionSubtitle: {
    fontSize: 12,
    color: gunmetal,
    width: '80%'
  }
})

function CashFlowAccount({ account, title, subtitle, onPress, isAsset = false, includeLine = false }) {
  return (
    <View style={styles.optionContainer}>
      <TouchableOpacity onPress={onPress} style={styles.boxContainer}>
        <View style={styles.box}>
          <View style={styles.boxIconContainer}>
            <CircleIcon
              size={8}
              color={isAsset ? primary : red}
              backgroundColor={isAsset ? rgba(primary, 0.1) : rgba(red, 0.1)}
              icon={isAsset ? 'dollar-sign' : 'hand-holding-dollar'}
            />
            <FontAwesomeIcon color={info} size={12} icon="pencil" />
          </View>
          <Text style={styles.boxTitle}>{account?.name || 'Unset'}</Text>
        </View>

        <View style={styles.line(includeLine)} />
      </TouchableOpacity>
      <View style={styles.option}>
        <Text style={styles.optionTitle}>{title}</Text>
        <Text style={styles.optionSubtitle}>{subtitle}</Text>
      </View>
    </View>
  )
}

export default function CashWaterfallScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({ headerTitle: 'Cash Flow Waterfall' })
  }, [navigation])

  const primaryProPlan = useSelector(primaryProPlanSelector)
  const { cashSweepLogic } = primaryProPlan || {}

  const shortTermCashManagementAccount = useSelector(
    (state) => bsAccountSelector(state, cashSweepLogic.shortTermCashAccountId, ASSET)
  )
  const shortTermCashOverflowAccount = useSelector(
    (state) => bsAccountSelector(state, cashSweepLogic.cashOverflowAccountId, ASSET)
  )
  const shortTermDebtAccount = useSelector(
    (state) => bsAccountSelector(state, cashSweepLogic.shortTermDebtAccountId, LIABILITY)
  )

  function handlePress(option) {
    navigation.navigate('Modal', {
      screen: 'Cash Flow Waterfall Form',
      params: {
        title: option.title,
        enableMinAndMax: option.enableMinAndMax,
        isAsset: option.isAsset,
        field: option.field
      }
    })
  }

  const options = [
    {
      title: 'Short Term Cash Management Account',
      subtitle: 'Where you want short term cash to flow from',
      includeLine: true,
      enableMinAndMax: true,
      account: shortTermCashManagementAccount,
      isAsset: true,
      field: Fields.shortTermCashAccount
    }, {
      title: 'Short Term Cash Overflow Account',
      subtitle: 'Where you want ANY left over money to go',
      account: shortTermCashOverflowAccount,
      isAsset: true,
      field: Fields.shortTermOverflowAccount
    }, {
      title: 'Short Term Debt Management Account',
      subtitle: 'Where you want your short term debt to come from',
      account: shortTermDebtAccount,
      field: Fields.shortTermDebtAccount
    }
  ]

  return (
    <View style={styles.container}>
      {options.map((option, index) => (
        <CashFlowAccount
          key={index}
          {...option}
          onPress={() => handlePress(option)}
        />
      ))}
    </View>
  )
}

CashWaterfallScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
