import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { FlatList, ScrollView } from 'react-native-gesture-handler'
import moment from 'moment'
import { filter, find, forEach, has, isEmpty, map, reduce } from 'lodash'
import BudgetBreakdownCard from './BudgetBreakdownCard'
import financialProjectionUtils from '../../functions/financialProjectionUtils'
import getProjectedBudgetItemsByCategory from '../../functions/getProjectedBudgetItemsByCategory'
import {
  proBudgetSelector,
  financialProjectionsSelector,
  transactionsByMonthAndYearSelector,
  budgetCategoriesSelector
} from '../../selectors'
import ProBudgetItem from '../ProBudgetItem'
import {
  darkgrayblue,
  darkblue,
  DEFAULT_SPACING,
  gunmetal,
  lightestgray,
  lightgray,
  orange,
  secondary,
  white,
  rgba
} from '../../styles'
import Card from '../Card'
import getProBudgetStats from '../../functions/getProBudgetStats'
import { getProBudgetTotal } from '../../functions/getMonthlyProBudgetTotal'
import DateSliderRule from '../DateSliderRule'

import RecentTransactionsCard from './RecentTransactionsCard'

const styles = StyleSheet.create({
  noProjectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: orange,
    padding: DEFAULT_SPACING
  },
  noProjectionsText: {
    color: white,
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: DEFAULT_SPACING
  },
  budgetItemHeader: {
    fontSize: 14,
    fontWeight: '600'
  },
  targetBudget: {
    color: gunmetal,
    fontSize: 10,
    marginBottom: DEFAULT_SPACING * 2,
    marginRight: 30
  },
  thisMonth: {
    color: secondary,
    fontSize: 12
  },
  smallButton: {
    paddingVertical: 8,
    width: 180,
    borderRadius: 8,
    alignSelf: 'center'
  },
  smallButtonText: {
    fontSize: 14
  },
  circleIconStyle: {
    marginRight: DEFAULT_SPACING
  }
})

export default function BudgetingItemsSection({ navigation }) {
  /* Fetch budget items and categories */
  const budget = useSelector(proBudgetSelector)
  const transactionsByMonthAndYear = useSelector(
    transactionsByMonthAndYearSelector
  )
  const budgetCategories = useSelector(budgetCategoriesSelector)

  /* Fetch NW Information */
  const projections = useSelector(financialProjectionsSelector)

  const monthYearFormat = (date) => moment(date).format('MMM YYYY')

  const filteredProjects = filter(projections, (projection) => {
    return moment(projection.date).isSameOrAfter(moment(), 'month')
  })

  const projectionDates = map(filteredProjects, (projection) => ({
    id: monthYearFormat(projection.date),
    title: monthYearFormat(projection.date),
    date: projection.date
  }))

  const sumTransactions = (key, transactions) => {
    let actualTotal = 0
    let filteredTransactions = []

    if (key) {
      forEach(transactions, (transaction) => {
        if (transaction.budgetCategory == key) {
          actualTotal += transaction.amount
          filteredTransactions.push(transaction)
        }
      })
    } else {
      forEach(transactions, (transaction) => {
        if (transaction.budgetCategory !== 'INCOME') {
          actualTotal += transaction.amount
          filteredTransactions.push(transaction)
        }
      })
    }

    return { actualTotal, filteredTransactions }
  }

  const getProjectedBudget = (projection) => {
    if (isEmpty(projection)) {
      return {
        budgetItemsByCategory: {},
        total: 0,
        count: 0
      }
    }

    const projectedBudgetItemsByCategory = getProjectedBudgetItemsByCategory(
      projection,
      budget
    )
    return {
      budgetItemsByCategory: projectedBudgetItemsByCategory,
      ...getProBudgetStats(projectedBudgetItemsByCategory)
    }
  }
  /* State variables based on date */
  const { findAtDate: findProjection } = financialProjectionUtils(projections)

  const today = moment()
  const [selectedDate, setSelectedDate] = useState(today)
  const [currentProjection, setCurrentProjection] = useState(
    getProjectedBudget(findProjection(selectedDate))
  )
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)

  const monthYear = selectedDate.format('YYYY-MM')
  const transactions = transactionsByMonthAndYear[monthYear] || []

  const onDatePicked = (pickedDate) => {
    const newSelectedDate = moment(pickedDate)
    setSelectedDate(newSelectedDate)

    setCurrentProjection(getProjectedBudget(findProjection(newSelectedDate)))
  }

  /* Render BudgetItem in Flatlist */

  const renderBudgetCategoryData = ({ item: budgetCategory }) => {
    if (!has(currentProjection.budgetItemsByCategory, budgetCategory)) {
      return null
    }

    const { name, total, icon, color } =
      currentProjection.budgetItemsByCategory[budgetCategory]
    const { actualTotal, filteredTransactions } = sumTransactions(
      budgetCategory,
      transactions
    )

    return (
      <BudgetBreakdownCard
        title={name}
        budgetAmount={total}
        actualAmount={actualTotal}
        iconProps={{
          size: 14,
          icon,
          color,
          backgroundColor: rgba(color, 0.1)
        }}
        onPress={() => {
          navigation.navigate('Modal', {
            screen: 'Budget Category Details',
            params: {
              budgetCategoryKey: budgetCategory,
              transactions: filteredTransactions,
              selectedDate
            }
          })
        }}
        style={{
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderColor: lightgray,
          marginBottom: DEFAULT_SPACING
        }}
      />
    )
  }

  const noProjectionsHeader = () => (
    <View style={styles.noProjectionsContainer}>
      <Text style={styles.noProjectionsText}>
        This page usually shows your future projected financials. But they
        haven't been generated yet. Come back to this page in sometime to see
        more.
      </Text>
    </View>
  )

  const { actualTotal } = sumTransactions(null, transactions)
  const keys = budgetCategories.map((category) => category.key)

  return (
    <View>
      {projections.length > 0 && (
        <DateSliderRule
          selectedDate={selectedDate}
          projectionDates={projectionDates}
          onDatePicked={onDatePicked}
          isDatePickerVisible={isDatePickerVisible}
          setIsDatePickerVisible={setIsDatePickerVisible}
        />
      )}

      {projections.length === 0 && noProjectionsHeader()}

      <ScrollView
        style={{ backgroundColor: lightestgray }}
        contentContainerStyle={{ paddingBottom: 200 }}
      >
        <RecentTransactionsCard
          transactions={transactions}
          budgetCategories={budgetCategories}
          navigation={navigation}
        />

        <Card style={{ padding: DEFAULT_SPACING }}>
          <ProBudgetItem
            onPress={() => {}}
            title={`${monthYearFormat(selectedDate)} Budget`}
            titleStyle={{
              fontSize: 16,
              color: darkgrayblue,
              fontWeight: '600'
            }}
            budgetAmount={currentProjection.totalExpenses}
            actualAmount={actualTotal}
          />

          <BudgetBreakDownForMonth
            budgetAmount={currentProjection.totalExpenses}
            actualAmount={actualTotal}
          />

          <FlatList data={keys} renderItem={renderBudgetCategoryData} />
        </Card>
      </ScrollView>
    </View>
  )
}

BudgetingItemsSection.propTypes = {}
