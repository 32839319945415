import { find, has, reduce } from 'lodash'
import { gray } from '../styles'
import { ASSET } from '../constants'

const CASH = 'CASH'
const SAVING = 'SAVING'
const MONEY_MARKET = 'MONEY_MARKET'
const CHECKING = 'CHECKING'
const TAXABLE_BROKERAGE = 'TAXABLE_BROKERAGE'
const VEHICLE = 'VEHICLE'
const REAL_ESTATE = 'REAL_ESTATE'
const PRETAX_TRADITIONAL_401K = 'PRETAX_TRADITIONAL_401K'
const ROTH_401K = 'ROTH_401K'
const TRADITIONAL_IRA = 'TRADITIONAL_IRA'
const ROTH_IRA = 'ROTH_IRA'
const EDUCATIONAL_SAVINGS_529 = 'EDUCATIONAL_SAVINGS_529'
const DEFINED_BENEFIT_PENSION = 'DEFINED_BENEFIT_PENSION'
const COVERDELL = 'COVERDELL'
const OTHER = 'OTHER'

const BS_ACC_CAT_COLOR_MAP = {
  ASSET: {
    CASH: '#57B575',
    SAVING: '#F7CB64',
    MONEY_MARKET: '#5F6A4A',
    CHECKING: '#755DDB',
    TAXABLE_BROKERAGE: '#4F8FEF',
    VEHICLE: '#EB5168',
    REAL_ESTATE: '#ED7157',
    PRETAX_TRADITIONAL_401K: '#6D7A7D',
    ROTH_401K: '#D453A9',
    TRADITIONAL_IRA: '#2C3A48',
    ROTH_IRA: '#24525C',
    EDUCATIONAL_SAVINGS_529: '#67D0FA',
    COVERDELL: '#F7C9D3',
    DEFINED_BENEFIT_PENSION: '#8BD016',
    OTHER: '#CCE2CB'
  },
  LIABILITY: {
    CREDIT_CARD: '#57B575',
    STUDENT_LOAN: '#F7CB64',
    BUSINESS_LOAN: '#5F6A4A',
    MORTGAGE_LOAN: '#4F8FEF',
    VEHICLE_LOAN: '#EB5168',
    HOME_EQUITY_LOAN: '#ED7157',
    DEBT_CONSOLIDATION_LOAN: '#6D7A7D',
    PAYDAY_LOAN: '#D453A9',
    BNPL_LOAN: '#2C3A48'
  }
}

export const ACCOUNTS_BY_CATEGORY = [
  {
    label: 'Cash',
    values: [CASH, SAVING, MONEY_MARKET, CHECKING, OTHER],
    icon: 'dollar-sign',
    type: 'CASH'
  },
  {
    label: 'Property',
    values: [VEHICLE, REAL_ESTATE],
    icon: 'garage-car',
    type: 'PROPERTY'
  },
  {
    label: 'Investments',
    values: [
      TAXABLE_BROKERAGE,
      PRETAX_TRADITIONAL_401K,
      ROTH_401K,
      TRADITIONAL_IRA,
      ROTH_IRA,
      EDUCATIONAL_SAVINGS_529,
      DEFINED_BENEFIT_PENSION,
      COVERDELL
    ],
    icon: 'chart-line',
    type: 'INVESTMENT'
  }
]

export function getCategoryColor(accountType, accountCategory) {
  if (
    has(BS_ACC_CAT_COLOR_MAP, accountType) &&
    has(BS_ACC_CAT_COLOR_MAP[accountType], accountCategory)
  ) {
    return BS_ACC_CAT_COLOR_MAP[accountType][accountCategory]
  }

  return gray
}

export function groupBSAccountsByAssetOrLiabilities(
  accounts,
  allBsAccountCategories
) {
  let assetAmount = 0
  let liabilityAmount = 0

  accounts.forEach((account) => {
    const { accountType } = account

    if (accountType === ASSET) {
      assetAmount += account.balance
    } else {
      liabilityAmount -= account.balance
    }
  })

  return [
    {
      label: 'Assets',
      val: assetAmount,
      absVal: Math.abs(assetAmount),
      color: '#57B575'
    },
    {
      label: 'Liabilities',
      val: liabilityAmount,
      absVal: Math.abs(liabilityAmount),
      color: '#EB5168'
    },
    {
      label: 'Net Total',
      val: assetAmount + liabilityAmount,
      absVal: Math.abs(assetAmount + liabilityAmount),
      color: '#72B9DF'
    }
  ].reverse()
}

export default function groupBSAccountsByCategory(
  accounts,
  allBSAccountCategories
) {
  /**
   * NOTE: The definition of this function as a closure is intentional
   * The allBSAccountCategories list is large so we try to minimize passing it around
   * */
  const accountsByCategoryReducer = (accountsByCategory, account) => {
    const { category: categoryId, accountType } = account
    const currentAccountsByCategory = accountsByCategory

    if (!has(accountsByCategory, categoryId)) {
      const category = find(
        allBSAccountCategories,
        (cat) => cat.key === categoryId && cat.type === accountType
      )

      currentAccountsByCategory[categoryId] = {
        label: (category && category.label) || 'Unknown',
        val: account.balance,
        color: getCategoryColor(accountType, categoryId)
      }
    } else {
      currentAccountsByCategory[categoryId].val += account.balance
    }

    return currentAccountsByCategory
  }

  const accountsByCategory = reduce(accounts, accountsByCategoryReducer, {})

  return Object.values(accountsByCategory)
}
