import Input from '../Input'
import CurrencyInput from '../CurrencyInput'

export default function WeddingForm({ Fields, touched, errors, handleChange, handleBlur, values, handleSubmit }) {
  return (
    <>
      <Input
        label="Name"
        name={Fields.name}
        touched={touched[Fields.name]}
        errorMessage={errors[Fields.name]}
        onChangeText={handleChange(Fields.name)}
        onBlur={handleBlur(Fields.name)}
        value={values[Fields.name]}
        returnKeyType="next"
      />

      <Input
        name={Fields.year}
        errorMessage={errors[Fields.year]}
        touched={touched[Fields.year]}
        onChangeText={handleChange(Fields.year)}
        onBlur={handleBlur(Fields.year)}
        value={values[Fields.year]}
        label="Years until Purchase"
        returnKeyType="next"
        keyboardType="number-pad"
        enablesReturnKeyAutomatically
      />

      <CurrencyInput
        prefix="$"
        label={"Today's Value"}
        value={values[Fields.currentValue]}
        name={Fields.currentValue}
        touched={touched[Fields.currentValue]}
        errorMessage={errors[Fields.currentValue]}
        onChangeText={handleChange(Fields.currentValue)}
        precision={0}
        onSubmitEditing={handleSubmit}
      />
    </>
  )
}
