// React
import { useEffect, useLayoutEffect, useState } from 'react'
import { Image, Platform, View, Text, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

//Components
import hapticFeedback from './components/hapticFeedback'
import CircleIcon from './components/CircleIcon'
import Container from './components/Container'
import Screen from './components/Screen'

// 3Rd party
import { map } from 'lodash'
import {
  darkblue,
  gunmetal,
  lightestgray,
  purple,
  rgba,
  secondary,
  white
} from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import HeaderLabel from './components/HeaderLabel'
import TouchableOpacity from './components/TouchableOpacity'
import PageHeader from './components/PageHeader'
import {
  faChevronCircleDown,
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons'
import CarForm from './components/opportunityCost/CarForm'
import CollegeForm from './components/opportunityCost/CollegeForm'
import HouseForm from './components/opportunityCost/HouseForm'
import { OpportunityCostResults } from './OpportunityCostResultScreen'
import ToolsHeaderWrapper from './components/tools/ToolsHeaderWrapper'

const basicStyles = {
  wrapper: {
    backgroundColor: lightestgray
  },
  screen: {
    paddingHorizontal: DEFAULT_SPACING
  },
  header: {
    backgroundColor: white,
    justifyContent: 'center',
    paddingVertical: DEFAULT_SPACING * 2
  },
  headerLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: DEFAULT_SPACING
  },
  headerText: {
    textAlign: 'center',
    color: secondary
  },
  listItem: {
    backgroundColor: white
  },
  contentWrapper: {
    marginBottom: DEFAULT_SPACING
    // width: '100%',
  },
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 63,
    height: 63,
    borderRadius: 63 / 2,
    overflow: 'hidden'
  },
  icon: {
    width: 60,
    height: 60,
    borderRadius: 60
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0,
    marginLeft: DEFAULT_SPACING
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5,
    marginLeft: DEFAULT_SPACING,
    marginTop: 4
  },
  listContainer: {
    marginTop: DEFAULT_SPACING,
    justifyContent: 'space-around'
  },
  container: {
    borderRadius: 13,
    backgroundColor: white,
    borderWidth: 1,
    borderColor: 'rgba(70, 90, 106, 0.12)',
    alignItems: 'center',
    flexDirection: 'row',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING
    // width: '100%',
    // backgroundColor: 'blue'
  },
  right: {},
  listAndFormContainer: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
    screen: {
      overflow: 'auto'
    },
    right: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      marginLeft: 24,
      padding: 40,
      backgroundColor: white,
      borderRadius: 12
    },
    listContainer: {
      marginTop: 0,
      width: 420
    }
  },
  [minSize(DEVICE_SIZES.LG)]: {
    wrapper: {
      padding: 40,
      overflow: 'auto'
    },
    screen: {
      paddingHorizontal: 0
    },
    listContainer: {
      marginTop: 0,
      maxWidth: 420
    },
    contentWrapper: {
      borderRadius: 13,
      overflow: 'hidden',
      backgroundColor: '#dddd'
    },
    container: {
      marginBottom: 0,
      borderRadius: 0,
      borderColor: white
    },
    icon: {
      width: 86,
      height: 86
    },
    title: {
      fontSize: 22
    },
    subtitle: {
      fontSize: 16,
      fontWeight: '600'
    },
    listAndFormContainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: 12,
      minWidth: 1024
    }
  }
})

const isNotWeb = Platform.OS !== 'web'

const FormScreens = {
  home: HouseForm,
  car: CarForm,
  college: CollegeForm
}

function ListOptionItem({
  actionName,
  icon,
  iconColor,
  iconBackgroundColor,
  title,
  subtitle,
  onPress,
  type,
  activeCalculator,
  navigation
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [isShowForm, setIsShowForm] = useState(title === activeCalculator)
  const FormScreen = FormScreens[type]

  useEffect(() => {
    setIsShowForm(title === activeCalculator)
  }, [activeCalculator])

  return (
    <View style={styles.contentWrapper}>
      <TouchableOpacity
        action={actionName}
        onPress={() => {
          if (title !== activeCalculator) onPress()
          else setIsShowForm((prev) => !prev)

          hapticFeedback()
        }}
      >
        <View style={styles.container}>
          <CircleIcon
            style={styles.icon}
            size={isNotWeb ? 30 : 34}
            icon={icon}
            color={iconColor}
            backgroundColor={iconBackgroundColor}
          />
          <View style={{ flex: 2 }}>
            <View style={{ flexDirection: 'row' }}>
              <Text numberOfLines={1} style={styles.title}>
                {title}
              </Text>
            </View>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>
          <View style={{ width: 50 }}>
            <FontAwesomeIcon
              style={{ alignSelf: 'flex-end' }}
              icon={isShowForm ? faChevronCircleDown : faChevronCircleRight}
              size={18}
              color="rgb(223, 230, 236)"
            />
          </View>
        </View>
      </TouchableOpacity>
      {!isNotWeb && isShowForm && <FormScreen navigation={navigation} />}
    </View>
  )
}

export default function OpportunityCostScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { params } = route
  const title = 'Opportunity Cost'
  const [activeCalculator, setActiveCalculator] = useState('car')
  const carParams = { type: 'car' }
  const collegeParams = { type: 'college' }
  const homeParams = { type: 'home' }

  const calculators = [
    {
      icon: 'car',
      title: 'Car',
      subtitle:
        'Estimate the opportunity cost of purchasing one car vs another',
      iconColor: purple,
      iconBackgroundColor: rgba(purple, 0.1),
      type: 'car',
      onPress: () => {
        if (isNotWeb)
          navigation.navigate('Modal', {
            screen: 'Opportunity Cost Calculator',
            params: carParams
          })
        else setActiveCalculator('Car')
      }
    },
    {
      icon: 'graduation-cap',
      title: 'College',
      subtitle:
        'Estimate the opportunity cost of attending one school vs another',
      iconColor: purple,
      type: 'college',
      iconBackgroundColor: rgba(purple, 0.1),
      onPress: () => {
        if (isNotWeb)
          navigation.navigate('Modal', {
            screen: 'Opportunity Cost Calculator',
            params: collegeParams
          })
        else setActiveCalculator('College')
      }
    },
    {
      icon: 'house',
      title: 'Home',
      subtitle:
        'Estimate the opportunity cost of purchasing one home vs another',
      iconColor: purple,
      type: 'home',
      iconBackgroundColor: rgba(purple, 0.1),
      onPress: () => {
        if (isNotWeb)
          navigation.navigate('Modal', {
            screen: 'Opportunity Cost Calculator',
            params: homeParams
          })
        else setActiveCalculator('Home')
      }
    }
  ]

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb
    })
  }, [navigation])

  return (
    <Container style={styles.wrapper}>
      {!isNotWeb && (
        <ToolsHeaderWrapper>
          <PageHeader
            title={title}
            subTitle="Our financial choices have consequences. Each time we make a choice, we give up opportunities associated with different decisions.
            This tool will help estimate the financial opportunity cost of making one decisions vs. another."
          />
        </ToolsHeaderWrapper>
      )}
      {isNotWeb && (
        <View style={styles.header}>
          <HeaderLabel style={styles.headerLabel}>
            Our financial choices have consequences.
          </HeaderLabel>
          <Text style={styles.headerText}>
            Each time we make a choice, we give up opportunities associated with
            different decisions. This tool will help estimate the financial
            opportunity cost of making one decision vs. another.
          </Text>
        </View>
      )}
      <Screen style={styles.screen}>
        <View style={styles.listAndFormContainer}>
          <View style={styles.listContainer}>
            {map(calculators, (calculator) => (
              <ListOptionItem
                {...calculator}
                navigation={navigation}
                action={calculator.title}
                key={calculator.title}
                navigatable={true}
                activeCalculator={activeCalculator}
              />
            ))}
          </View>
          {!isNotWeb && (
            <View style={styles.right}>
              {params ? (
                <OpportunityCostResults params={params} />
              ) : (
                <Image
                  source={require('./assets/opp-cost-intro.png')}
                  style={{ width: '60%', maxWidth: 500, height: '100%' }}
                  resizeMode={'contain'}
                />
              )}
            </View>
          )}
        </View>
      </Screen>
    </Container>
  )
}
