import { get } from 'http'
import { setCurrentUser } from './setCurrentUser'

export const getCurrentUser = function () {
  return async (dispatch) => {
    try {
      const identityResponse = await get('me/')
      const user = identityResponse.data

      dispatch(setCurrentUser(user))
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
