import { post } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { currentUserSelector } from '../selectors'
import { setBudgetItem } from './setBudgetItem'
import { sanitizeBudgetItemPayload } from './saveBudgetItem'

// eslint-disable-next-line import/prefer-default-export
export const saveBudgetItems = (budgetItems, planId) => async (dispatch, getState) => {
  try {
    const { id: userId } = currentUserSelector(getState())

    const sanitizedBudgetItems = budgetItems.map(sanitizeBudgetItemPayload)
    sanitizedBudgetItems.forEach((budgetItem) => {
      budgetItem.planId = planId
      budgetItem.userId = userId
    })

    const result = await post(
      proApplicationEndpoints.createBudgetItems(planId, userId),
      sanitizedBudgetItems
    )

    result.data.forEach((budgetItem) => {
      dispatch(setBudgetItem(budgetItem))
    })
  } catch (e) {
    console.warn(e, e.response)
  }
}
