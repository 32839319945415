import { useLayoutEffect } from 'react'
import { Text, View, StyleSheet } from 'react-native'

import { useNavigationState } from '@react-navigation/native'
import { first } from 'lodash'
import BottomButton from 'components/BottomButton'
import Container from 'components/Container'
import CloseButton from 'components/CloseButton'
import HeaderBackImage from 'components/HeaderBackImage'
import TouchableOpacity from 'components/TouchableOpacity'
import HeaderLabel from 'components/HeaderLabel'
import { white, gunmetal } from './styles'

const styles = StyleSheet.create({
  bottomButton: {
    marginHorizontal: 12
  },
  headerLabel: {
    marginBottom: 12,
    textAlign: 'center',
    width: '100%'
  },
  subtext: {
    fontSize: 16,
    marginBottom: 24,
    color: gunmetal,
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center'
  }
})

export default function ResetPasswordSucessfulScreen({ navigation }) {
  const routes = useNavigationState((state) => state.routes)
  const firstRoute = first(routes)

  useLayoutEffect(() => {
    navigation.setOptions({
      title: null,
      headerTransparent: true,
      headerLeft: () =>
        firstRoute.name == 'Get Started' ? (
          <TouchableOpacity onPress={() => navigation.pop()}>
            <HeaderBackImage fill={white} />
          </TouchableOpacity>
        ) : (
          <CloseButton color={white} onPress={() => navigation.pop()} />
        )
    })
  }, [navigation])

  return (
    <Container card={true}>
      <HeaderLabel style={styles.headerLabel}>Reset Password Success!</HeaderLabel>
      <Text style={styles.subtext}>
        An email was sent to you with instructions on how to reset your password.
      </Text>

      <BottomButton
        style={styles.bottomButton}
        onPress={() => {
          navigation.popToTop()
          navigation.pop()
        }}
      >
        Okay
      </BottomButton>
    </Container>
  )
}
