import { Text, View, StyleSheet } from 'react-native'

import { DEFAULT_SPACING, secondary, white } from '../styles'

const styles = StyleSheet.create({
  header: {
    backgroundColor: white,
    alignItems: 'center',
    paddingBottom: DEFAULT_SPACING * 2
  },
  text: {
    fontSize: 16,
    color: secondary,
    textAlign: 'center'
  }
})

export default function Header({ text, children, style = {}, textStyle = {} }) {
  return (
    <View style={[styles.header, style]}>
      <Text style={[styles.text, textStyle]}>{text}</Text>
      {children}
    </View>
  )
}
