import { StyleSheet } from 'react-native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import hapticFeedback from './hapticFeedback'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  closeButton: {
    fontSize: 20,
    marginHorizontal: 12,
    paddingTop: 20,
    zIndex: 1000
  },
  closeButtonContainer: { zIndex: 1001 }
})

export default function AddRightButton({ onPress, color='black' }) {
  function handlePress() {
    hapticFeedback()
    if (onPress) onPress()
  }

  return (
    <TouchableOpacity style={styles.closeButtonContainer} onPress={() => handlePress()}>
      <FontAwesomeIcon style={styles.closeButton} color= {color} icon="plus" />
    </TouchableOpacity>
  )
}
