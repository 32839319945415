// mark for review
const investmentSelections = {
  SP500: 'S&P 500',
  CASH: 'Cash',
  0: '100% Stocks',
  20: '80% Stocks / 20% Bonds',
  40: '60% Stocks / 40% Bonds',
  50: '50% Stocks / 50% Bonds',
  60: '40% Stocks / 60% Bonds',
  80: '20% Stocks / 80% Bonds',
  100: '100% Bonds',
  CUSTOM_GOAL: {
    label: 'Custom',
    sublabel: 'Add any investments you\'d like to contribute to for your goal!',
    value: 'CUSTOM_GOAL',
    icon: 'star',
    color: '#ffffff',
    route: 'Custom Goal'
  },
  DOLLAR_A_DAY: 'Dollar per Day',
  MILLIONAIRE_MAKER: 'Millionaire Maker'
}

export default function investmentSelection(attr) {
  return investmentSelections[attr]
}
