import * as Yup from 'yup'

import {
  INVALID_AMOUNT,
  INVALID_INT,
  INVALID_MIN,
  REQUIRED,
} from './constants'

export const Fields = {
  loan_balance: 'loan_balance',
  loan_rate: 'loan_rate',
  loan_period: 'loan_period',
  have_loans: 'have_loans',
  loan_national_average: 'loan_national_average',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.loan_balance]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .nullable()
    .when('have_loans', {
      is: (have_loans) => have_loans,
      then: Yup.number().required(REQUIRED).typeError(REQUIRED)
    }),
  [Fields.loan_rate]: Yup.number().positive(INVALID_MIN)
    .min(0, INVALID_AMOUNT)
    .nullable()
    .when('have_loans', {
      is: (have_loans) => have_loans,
      then: Yup.number().required(REQUIRED).typeError(REQUIRED)
    }),
  [Fields.loan_period]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .nullable()
    .when('have_loans', {
      is: (have_loans) => have_loans,
      then: Yup.number().required(REQUIRED).typeError(REQUIRED)
    }),
  [Fields.have_loans]: Yup.boolean(),
  [Fields.loan_national_average]: Yup.boolean().nullable()
})

export const InitialValues = {
  [Fields.loan_balance]: null,
  [Fields.loan_rate]: null,
  [Fields.loan_period]: null,
  [Fields.have_loans]: false,
  [Fields.loan_national_average]: false
}
