import { useState } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import Card from '../Card'
import { DEFAULT_SPACING, white } from '../../styles'
import HeaderTitleAndSubtitle from './HeaderTitleAndSubtitle'

const basicStyles = {
  card: {
    padding: 20,
    margin: DEFAULT_SPACING,
    shadowColor: 'transparent',
    borderRadius: 6,
    backgroundColor: white,
    position: 'relative',
    paddingTop: DEFAULT_SPACING * 2,
    top: -20
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      card: {
        margin: 0,
        top: 0,
        borderRadius: 16,
        width: 'calc(33.33% - 12.67px)',
        maxWidth: 350,
        marginRight: DEFAULT_SPACING,
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function PlanSummaryCard({
  title,
  subtitle,
  titleTooltip,
  children,
  style
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)

  return (
    <Card style={[styles.card, style]}>
      <HeaderTitleAndSubtitle
        title={title}
        subtitle={subtitle}
        titleTooltip={titleTooltip}
        tooltipIsVisible={tooltipIsVisible}
        setTooltipIsVisible={setTooltipIsVisible}
      />
      {children}
    </Card>
  )
}
