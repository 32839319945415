import { useState, useEffect, useLayoutEffect } from 'react'
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import { map } from 'lodash'
import Header from 'components/Header'
import Pill from 'components/Pill'
import TooltippedText from 'components/TooltippedText'
import ListOptionItem from 'components/ListOptionItem'
import BottomButton from 'components/BottomButton'
import DebtRankSlideupDrawer from 'components/debtRank/DebtRankSlideupDrawer'
import DebtRankingPageHeader from 'components/debtRank/DebtRankingPageHeader'

import formatCurrency from 'functions/formatCurrency'
import formatPercent from 'functions/formatPercent'

import {
  loadingSelector,
  loansSelector
} from 'selectors'

import { DEFAULT_SPACING, info, primary, white, rgba, darkblue, red, lightestgray, secondary } from 'styles'
import ToolsHeaderWrapper from 'components/tools/ToolsHeaderWrapper'

const basicStyles = {
  container: { flex: 1 },
  headerText: {
    textAlign: 'center',
    padding: DEFAULT_SPACING
  },
  subtitle: { fontSize: 12 },
  link: { marginBottom: DEFAULT_SPACING },
  linkLeft: { justifyContent: 'flex-start' },
  iconStyle: { color: info },
  tooltipLink: {
    color: info,
    marginTop: DEFAULT_SPACING,
    fontSize: 12
  },
  finn: { height: 200, },
  scrollView: {
    paddingVertical: DEFAULT_SPACING,
    paddingBottom: DEFAULT_SPACING * 5
  },
  debtContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING * 2,
  },
  addButton: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        padding: 40,
        overflow: 'auto',
        backgroundColor: lightestgray,
        paddingRight: '50%',
      },
      scrollView: {
        paddingBottom: DEFAULT_SPACING,
        height: 'calc(100% - 60px)',
        overflow: 'auto',
      },
      addButton: {
        width: 240,
        left: 20,
      }
    }
  }
)

const typeOptions = [
  { label: 'Federal Student Loan', value: 'FEDERAL' },
  { label: 'Private Student Loan', value: 'PRIVATE' },
  { label: 'Mortgage', value: 'MORTGAGE' },
  { label: 'Auto', value: 'AUTO' },
  { label: 'Credit Card', value: 'CREDIT_CARD' },
  { label: 'Personal Loan', value: 'PERSONAL' },
]

const typeIcons = {
  FEDERAL: 'graduation-cap',
  PRIVATE: 'graduation-cap',
  MORTGAGE: 'house',
  AUTO: 'car',
  CREDIT_CARD: 'credit-card',
  PERSONAL: 'money-bill'
}

function DebtItem({ debt, index, last, onPress }) {
  const [visible, setVisible] = useState(false)
  const { balance, category, interest_rate } = debt

  const name = typeOptions.find((option) => option.value === category).label
  const icon = typeIcons[category]
  const color = icon == 'credit-card' ? red : info

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ backgroundColor: rgba(primary, 0.3), borderRadius: 35, height: 35, width: 35, justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ backgroundColor: primary, borderRadius: 25, height: 25, width: 25, justifyContent: 'center', alignItems: 'center' }}>
            {index == 0
              && <FontAwesomeIcon size={14} color={white} icon="crosshairs" />}
            {index != 0 && <Text style={{ fontSize: 16, color: white }}>{index + 1}</Text>}
          </View>
        </View>
        {!last && <FontAwesomeIcon style={{ position: 'absolute', bottom: -10 }} size={18} color="#D0D9DE" icon="arrow-down" />}
      </View>

      <View style={{ flex: 1, marginLeft: DEFAULT_SPACING }}>
        <ListOptionItem
          label={name}
          iconName={icon}
          iconColor={color}
          iconType="far"
          checked
          onPress={onPress}
          onEdit={onPress}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 36 }}>
            <Pill small color={darkblue}>
              {formatPercent(parseFloat(interest_rate))}
            </Pill>

            <Text style={{ fontSize: 12, color: secondary, marginLeft: DEFAULT_SPACING }}>
              $
              {formatCurrency(balance)}
            </Text>
          </View>
        </ListOptionItem>
      </View>

      <DebtRankSlideupDrawer
        visible={visible}
        setVisible={setVisible}
        debt={debt}
      />
    </View>
  )
}

export default function DebtRank({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [tooltipVisible, setTooltopVisible] = useState(false)

  let loans = useSelector((state) => loansSelector(state))
  loans = loans.sort((a, b) => a.interest_rate - b.interest_rate).reverse()

  const loading = useSelector((state) => loadingSelector(state))

  useLayoutEffect(() => {
    navigation.setOptions({ 
      headerShown: isNotWeb 
    })
  }, [navigation, loans])

  if (loading.loading) {
    return (
      <View>
        <ActivityIndicator />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {isNotWeb ? (
        <Header
          textStyle={styles.headerText}
          text="If you can make more than the minimum payment, this is the repayment order that could save you the most money."
        >

          <TooltippedText
            position="bottom"
            visible={tooltipVisible}
            setVisible={setTooltopVisible}
            anchorStyle={styles.tooltipLink}
            anchorText="Learn more"
            contentHeader="Learn more about minumum payments"
            contentBody={"Remember, you must make payments (at least the minimum due) for each debt you own. Use this strategy to focus your extra money on paying more than the minimum due on your highest interest rate loan first. After you've paid off the first balance, move to the next one until you are debt free!"}
          />
        </Header>
      ) : (
        <ToolsHeaderWrapper>
          <DebtRankingPageHeader navigation={navigation} />
        </ToolsHeaderWrapper>
      )}
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.debtContainer}>
          {map(loans, (debt, index) => (
            <DebtItem
              index={index}
              key={`${debt.balance}-${index}`}
              debt={debt}
              last={index === loans.length - 1}
              onPress={() => {
                navigation.navigate('Modal', { screen: 'My Debts Form', params: { debt } })
              }}
            />
          ))}
        </View>
      </ScrollView>

      <BottomButton
        withMargin
        onPress={() => {
          navigation.navigate('Modal', { screen: 'My Debts Form' })
        }}
      >
        Add New Loan
      </BottomButton>
    </View>
  )
}
