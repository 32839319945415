import { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import PropTypes from 'prop-types'
import { has, reduce, find } from 'lodash'
import { useSelector } from 'react-redux'
import { primary, DEFAULT_SPACING, darkblue } from '../../styles'
import Card from '../Card'
import DataDonut from '../DataDonut'
import { ASSET, LIABILITY } from '../../constants'
import { bsAccountCategoriesSelector } from '../../selectors'
import groupBSAccountsByCategory, { groupBSAccountsByAssetOrLiabilities } from '../../functions/groupBSAccountsByCategory'
import PillGroup from '../PillGroup'
import NetTotalBarChart from './NetTotalBarChart'

const styles = StyleSheet.create({
  buttonTitle: { fontSize: 14 },
  header: {
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 20,
  },
  cardContainer: {
    padding: 19,
    borderRadius: 12,
    marginBottom: DEFAULT_SPACING
  },
  barChartContainer: {
    width: '100%'
  }
})

export default function MyAccountsOverview({ data, style }) {
  const [currentOverview, setCurrentOverview] = useState(ASSET)
  const allAccountCats = useSelector(bsAccountCategoriesSelector)
  const assetsByCategory = groupBSAccountsByCategory(data.assetAccounts, allAccountCats)
  const liabilitiesByCategory = groupBSAccountsByCategory(data.liabilityAccounts, allAccountCats)
  const assetsAndLiabilities = groupBSAccountsByAssetOrLiabilities([...data.assetAccounts, ...data.liabilityAccounts])

  const ALL = 'ALL'
  
  return (
    <Card style={[styles.cardContainer, style]}>
      <Text style={styles.header}>My Account Overview</Text>

      <PillGroup
        style={styles.buttonContainer}
        options={[
          {
            label: 'All',
            onPress: () => setCurrentOverview(ALL),
            active: currentOverview === ALL,
            style: { flex: 1, marginHorizontal: DEFAULT_SPACING / 2 }
          },
          {
            label: 'Assets',
            onPress: () => setCurrentOverview(ASSET),
            active: currentOverview === ASSET,
            style: { flex: 1, marginHorizontal: DEFAULT_SPACING / 2 }
          },
          {
            label: 'Liabilities',
            onPress: () => setCurrentOverview(LIABILITY),
            active: currentOverview === LIABILITY,
            style: { flex: 1, marginHorizontal: DEFAULT_SPACING / 2 }
          },
        ]}
      />

      { currentOverview === ALL ? (
        <NetTotalBarChart data={assetsAndLiabilities} />  
      ) : (
        <DataDonut
          innerRadius={80}
          height={300}
          chartData={
            currentOverview === ASSET ? assetsByCategory : liabilitiesByCategory
          }
        />
      )}
    </Card>
  )
}

MyAccountsOverview.defaultProps = { style: {} }

MyAccountsOverview.propTypes = {
  data: PropTypes.shape({
    assetAccounts: PropTypes.array.isRequired,
    liabilityAccounts: PropTypes.array.isRequired,
  }).isRequired,
  style: PropTypes.object
}
