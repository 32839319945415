import { View, Text, StyleSheet } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import abbreviateCurrency from '../../functions/abbreviateCurrency'
import {
  primaryGradientEnd,
  primaryGradientStart,
  rgba,
  white
} from '../../styles'

const styles = StyleSheet.create({
  badgeBorderContainer: {
    backgroundColor: rgba(primaryGradientEnd, 0.2),
    borderRadius: 108 / 2,
    height: 108,
    width: 108,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
  badgeGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: 88 / 2,
    height: 88,
    width: 88,
    zIndex: -1,
  },
  badgeContainer: {
    position: 'relative',
    borderRadius: 88 / 2,
    height: 88,
    width: 88,
    alignItems: 'center',
    justifyContent: 'center'
  },
  badgeText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: white
  },
  badgeSubText: {
    fontSize: 10,
    color: white
  },
  badgeSubTextSmall: {
    fontSize: 8,
    color: white
  }
})

export default function SavingsBadge({ number }) {
  return (
    <View style={styles.badgeBorderContainer}>
      <View style={styles.badgeContainer}>
        <LinearGradient
          colors={[primaryGradientStart, primaryGradientEnd]}
          style={styles.badgeGradient}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
        />
        <Text style={styles.badgeText}>
          $
          {abbreviateCurrency(number, 1)}
        </Text>
        <Text style={styles.badgeSubText}>Save & Invest</Text>
        <Text style={styles.badgeSubTextSmall}>per month</Text>
      </View>
    </View>
  )
}
