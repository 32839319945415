import { useState } from 'react'
import { StyleSheet } from 'react-native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Card from '../Card'
import HeaderLabel from '../HeaderLabel'

import { DEFAULT_SPACING, lightgray } from '../../styles'
import TouchableOpacity from '../TouchableOpacity'

const styles = StyleSheet.create({
  card: {
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingTop: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING,
    borderRadius: 12,
    shadowColor: 'transparent'
  },
  headerLabel: {
    fontSize: 18,
    fontWeight: '600'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: DEFAULT_SPACING * 2,
  }
})

export default function _Card({ children, header, defaultVisibility }) {
  const [visible, setVisible] = useState(defaultVisibility || (!header))

  return (
    <Card style={styles.card}>
      {header
        && (
          <TouchableOpacity onPress={() => setVisible(!visible)} style={styles.row}>
            <HeaderLabel style={styles.headerLabel}>{header}</HeaderLabel>
            <FontAwesomeIcon color={lightgray} icon={visible ? 'circle-chevron-up' : 'circle-chevron-down'} />
          </TouchableOpacity>
        )}

      { visible && children }
    </Card>
  )
}
