import { useEffect } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { put } from 'http'
import { NOTIFICATION_BASE_URL } from '@env'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { darkblue, gunmetal, lightblue, white } from './styles'
import { getTimeDistance } from './functions/notification'
import BottomButton from './components/BottomButton'
import { notificationsSelector } from './selectors'
import { setNotifications } from './actions/setNotifications'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 28,
    height: '100%',
    backgroundColor: white
  },
  imageContainer: {
    width: 82,
    shadowColor: '#3A424B',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 17 },
    shadowRadius: 11
  },
  image: {
    width: 78,
    height: 78,
    borderRadius: 39,
    backgroundColor: white
  },
  iconWrapper: {
    position: 'absolute',
    right: -2,
    bottom: -4,
    backgroundColor: lightblue,
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 14
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16
  },
  sender: {
    fontSize: 20,
    fontWeight: '600',
    color: darkblue
  },
  time: {
    fontSize: 14,
    color: gunmetal,
    marginLeft: 4,
    fontWeight: '500'
  },
  description: {
    marginTop: 19,
    color: gunmetal,
    fontSize: 14
  }
})

function NotificationDetails({ navigation, route }) {
  const { params } = route
  const { data } = params
  const notifications = useSelector((state) => notificationsSelector(state))
  const dispatch = useDispatch()

  const handlePress = () => {
    navigation.navigate(data.context.actions[0].screenName)
  }

  const updateNotification = async () => {
    const idx = notifications.findIndex(
      (n) => n.notificationId === data.notificationId
    )
    const temp = [...notifications]
    temp[idx].readStatus = true
    try {
      put(
        `${NOTIFICATION_BASE_URL}/user/notifications?notification_id=${data.notificationId}`,
        { read_status: true }
      )
      dispatch(setNotifications(temp))
    } catch (e) {
      console.log('error is occurred', e)
    }
  }

  useEffect(() => {
    if (!data.readStatus) {
      updateNotification()
    }
  }, [data])

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          style={styles.image}
          source={require('./assets/logo.png')}
          resizeMode="contain"
        />
        <View style={styles.iconWrapper}>
          {data.context.icon.iconType === 'FONT_AWESOME' ? (
            <FontAwesomeIcon
              icon={data.context.icon.fontAwesome.icon}
              color={data.context.icon.fontAwesome.color}
              size={12}
            />
          ) : (
            <Image source={data.context.icon.imageUrl} />
          )}
        </View>
      </View>
      <View style={styles.title}>
        <Text style={styles.sender}>{data.sender}</Text>
        <Text style={styles.time}>
          {'• '}
          {getTimeDistance(data.createdAt)}
        </Text>
      </View>
      <Text style={styles.description}>{data.context.description}</Text>
      <BottomButton onPress={handlePress} withMargin>
        {`Go to ${data.context.actions[0].screenName}`}
      </BottomButton>
    </View>
  )
}

export default NotificationDetails
