import { View, StyleSheet } from 'react-native'
import { map } from 'lodash'
import SlideupDrawer from '../SlideupDrawer'
import Link from '../Link'
import hapticFeedback from '../hapticFeedback'
import { darkblue, danger as dangerColor, primary, darkgrayblue } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  container: {},
  button: {
    marginTop: DEFAULT_SPACING,
    backgroundColor: dangerColor
  },
  slideRowIcon: { marginLeft: 40 },
  slideRow: {
    color: darkblue,
    fontSize: 18,
    fontWeight: '500',
    width: '100%',
    paddingLeft: 10
  },
  slideRowContainerStyle: {
    borderBottomColor: '#F2F6F7',
    borderBottomWidth: 1,
    paddingVertical: DEFAULT_SPACING,
    marginHorizontal: DEFAULT_SPACING
  }
})

function SlideRow({ label, danger, onPress, icon, type }) {
  return (
    <Link
      iconStyle={{ ...styles.slideRowIcon, ...{ color: danger ? dangerColor : primary } }}
      containerStyle={styles.slideRowContainerStyle}
      style={[styles.slideRow, { color: danger ? dangerColor : darkgrayblue }]}
      icon={icon}
      type={type}
      onPress={() => {
        hapticFeedback()
        if (onPress) onPress()
      }}
    >
      {label}

    </Link>
  )
}

export default function ImageSlideupDrawer({ visible, setVisible, title, actions }) {
  const marginTop = '80%'
  const height = '20%'

  return (
    <SlideupDrawer snapPointIndex={0} title={title} scrollEnabled={false} marginTop={marginTop} height={height} visible={visible} setVisible={setVisible}>
      <View style={styles.container}>
        {map(actions, (action, index) => <SlideRow {...action} key={`slide-row-${index}`} index={index} />)}
      </View>
    </SlideupDrawer>
  )
}
