import { useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView, useWindowDimensions, Text } from 'react-native'

import { get } from 'http'

import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Header from './components/Header'
import Link from './components/Link'
import ListOptionItem from './components/ListOptionItem'

import Table from './components/opportunityCost/Table'

import formatCurrency from './functions/formatCurrency'
import formatPercent from './functions/formatPercent'

import { DEFAULT_SPACING, primary, white, info } from './styles'

const styles = StyleSheet.create({
  headerText: {
    textAlign: 'center',
    padding: DEFAULT_SPACING
  },
  link: { marginBottom: DEFAULT_SPACING },
  container: { flex: 1 },
  tableContainer: { backgroundColor: white },
  debtContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING * 2,
  },
  scrollView: {
    paddingVertical: DEFAULT_SPACING,
    paddingBottom: DEFAULT_SPACING * 5
  }
})

function DebtItem({ debt, index, last }) {
  const { name, balance, type } = debt

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ backgroundColor: primary, borderRadius: 30, height: 30, width: 30, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: 18, color: white }}>{index + 1}</Text>
        </View>
        { !last && <FontAwesomeIcon style={{ position: 'absolute', bottom: -10 }} size={18} color="#D0D9DE" icon="arrow-down" /> }
      </View>

      <View style={{ flex: 1, marginLeft: DEFAULT_SPACING }}>
        <ListOptionItem
          label={name}
          sublabel={`$${formatCurrency(balance)}`}
          iconName={type === 'term' ? 'calendar-check' : 'arrows-rotate'}
          iconColor={info}
          iconType="far"
        />
      </View>
    </View>
  )
}

export default function MyDebtsSummaryScreen({ navigation }) {
  const window = useWindowDimensions()
  const { height } = window

  const [myDebt, setMyDebt] = useState([])

  async function fetchMyDebt() {
    const response = await get('/rank-my-debt/')
    setMyDebt(response.data)
  }

  useEffect(() => {
    fetchMyDebt()
  }, [])

  const labels = {
    type: 'Type of Loan',
    unpaid_principal_balance: 'Unpaid Principal Balance',
    annual_rate: 'Annual Rate (APR)',
    minimum_due: 'Minimum Due',
    payoff_date: 'Payoff Date',
    months_to_pay_off: 'Months to Pay Off',
    total_interest_paid: 'Total Interest'
  }
  const formatters = {
    type: null,
    unpaid_principal_balance: formatCurrency,
    annual_rate: formatPercent,
    minimum_due: formatCurrency,
    payoff_date: null,
    months_to_pay_off: null,
    total_interest_paid: formatCurrency
  }

  const prefixes = {
    type: null,
    unpaid_principal_balance: '$',
    annual_rate: null,
    minimum_due: '$',
    payoff_date: null,
    months_to_pay_off: null,
    total_interest_paid: '$'
  }

  const suffixes = {
    type: null,
    unpaid_principal_balance: null,
    annual_rate: '%',
    minimum_due: null,
    payoff_date: null,
    months_to_pay_off: null,
    total_interest_paid: null
  }

  const debtLabels = map(myDebt, 'name')

  return (
    <View style={styles.container}>
      <Header
        textStyle={styles.headerText}
        text="Below is a summary of your deby ranked and in what order you should pay off each loan to save the most money!"
      >
        <Link style={styles.link}>Have some extra cash to put towards your debt?</Link>
      </Header>
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <View style={styles.debtContainer}>
            {map(myDebt, (debt, index) => (
              <DebtItem
                key={`${debt.name}-${index}`}
                debt={debt}
                index={index}
                last={index === myDebt.length - 1}
                navigation={navigation}
              />
            ))}
          </View>
          <View style={styles.tableContainer}>
            <Table
              labels={labels}
              data={myDebt}
              formatters={formatters}
              prefixes={prefixes}
              suffixes={suffixes}
              assetLabels={debtLabels}
              scrollable
            />
          </View>
        </ScrollView>
      </View>
    </View>
  )
}
