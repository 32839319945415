import { StyleSheet, Text, View } from 'react-native'

import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Link from '../Link'

import { assignPrimaryProPlan } from '../../actions'

import SlideupDrawer from '../SlideupDrawer'
import Button from '../Button'
import { darkgrayblue, DEFAULT_SPACING, secondary } from '../../styles'

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    color: darkgrayblue,
    fontWeight: '700',
    marginTop: 68,
    marginBottom: DEFAULT_SPACING,
  },
  bodyText: { color: secondary, textAlign: 'center' },
  bodyContainer: { alignItems: 'center' },
  buttonContainer: { width: '100%', marginBottom: DEFAULT_SPACING * 2 },
  container: { alignItems: 'center', marginHorizontal: DEFAULT_SPACING, height: '100%', justifyContent: 'space-between' },
  cancelText: { fontSize: 18 }
})

export default function SetPrimaryPlanSlideupDrawer({
  plan: currentPlan,
  onClose,
  ...slideUpDraweProps
}) {
  const dispatch = useDispatch()

  const assignPrimaryPlan = async () => {
    await dispatch(assignPrimaryProPlan(currentPlan, true))
    onClose()
  }

  return (
    <SlideupDrawer
      {...slideUpDraweProps}
      onClose={onClose}
      snapPointIndex={3}
    >
      <View style={styles.container}>
        <View style={styles.bodyContainer}>
          <Text style={styles.title}>Assign Active Plan</Text>
          <Text style={styles.bodyText}>
            Make this your active plan and start reaching your financial goals!
          </Text>
        </View>
        <View style={styles.buttonContainer}>
          <Link onPress={onClose} style={styles.cancelText}>Cancel</Link>
          <Button onPress={assignPrimaryPlan}>Assign Active Plan</Button>
        </View>
      </View>
    </SlideupDrawer>
  )
}

SetPrimaryPlanSlideupDrawer.propTypes = {
  plan: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}
