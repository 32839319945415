import { grayblue, gunmetal, orange, orangeRed, primary, purple, red, skyblue, warning } from '../styles'

export const INCOME_TYPE = 'INCOME'
// export const TAXES_TYPE = 'TAXES'
// export const STUDENT_LOAN_PAYMENTS_TYPE = 'STUDENT_LOAN_PAYMENTS'
// export const MEDICAL_HEALTH_INSURANCE_TYPE = 'MEDICAL_HEALTH_INSURANCE'
// export const HOUSING_TYPE = 'HOUSING'
// export const UTILITIES_TYPE = 'UTILITIES'
// export const TRANSPORTATION_TYPE = 'TRANSPORTATION'
// export const FOOD_TYPE = 'FOOD'
// export const MISC_TYPE = 'MISC'

// export const EXPENSE_TYPES = [
//   MY_SAVE_FIRST_GOAL_TYPE,
//   TAXES_TYPE,
//   STUDENT_LOAN_PAYMENTS_TYPE,
//   MEDICAL_HEALTH_INSURANCE_TYPE,
//   HOUSING_TYPE,
//   UTILITIES_TYPE,
//   TRANSPORTATION_TYPE,
//   FOOD_TYPE,
//   MISC_TYPE,
// ]

// export const EXPENSE_TYPE_COLORS = {
//   [INCOME_TYPE]: primary,
//   [MY_SAVE_FIRST_GOAL_TYPE]: primary,
//   [TAXES_TYPE]: skyblue,
//   [STUDENT_LOAN_PAYMENTS_TYPE]: warning,
//   [MEDICAL_HEALTH_INSURANCE_TYPE]: red,
//   [HOUSING_TYPE]: grayblue,
//   [UTILITIES_TYPE]: orangeRed,
//   [TRANSPORTATION_TYPE]: purple,
//   [FOOD_TYPE]: gunmetal,
//   [MISC_TYPE]: orange
// }

// export const EXPENSE_TYPE_ICONS = {
//   [INCOME_TYPE]: 'dollar-sign',
//   [MY_SAVE_FIRST_GOAL_TYPE]: 'chart-line',
//   [TAXES_TYPE]: 'chart-pie',
//   [STUDENT_LOAN_PAYMENTS_TYPE]: 'graduation-cap',
//   [MEDICAL_HEALTH_INSURANCE_TYPE]: 'heart',
//   [HOUSING_TYPE]: 'house',
//   [UTILITIES_TYPE]: 'bolt',
//   [TRANSPORTATION_TYPE]: 'car',
//   [FOOD_TYPE]: 'utensils',
//   [MISC_TYPE]: 'star'
// }

// export const ALL_BUDGET_ITEM_TYPES = [
//   INCOME_TYPE,
//   ...EXPENSE_TYPES
// ]
