import { Platform, StyleSheet, Text } from "react-native";
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { darkblue } from "../../styles";

const basicStyles = {
  title: {
    fontSize: 28,
    fontWeight: '600',
    lineHeight: 34,
    color: darkblue,
    paddingBottom: 16,
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      title: { fontSize: 38, lineHeight: 46, paddingBottom: 14, }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

const Title = () => {
  const styles = isNotWeb ? mobileStyles : useStyles()
  return (
    <Text style={styles.title}>Join Team</Text>
  )
}

export default Title
