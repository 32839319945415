import { useLayoutEffect, useState } from 'react'

import { Platform, StyleSheet, Text, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { xorBy } from 'lodash'
import Form from './components/Form'
import MultiSlider from './components/MultiSlider'
import Divider from './components/Divider'
import SquareCheckBox from './components/SquareCheckBox'
import InputSelect from './components/InputSelect'
import HeaderLabel from './components/HeaderLabel'
import SelectBox from './components/SelectBox'

import Container from './components/Container'

import {
  Fields,
  InitialValues,
  locationOptions,
  SATmin,
  SATmax,
  ACTmin,
  ACTmax,
  TuitionMin,
  TuitionMax,
  MedianDebtMin,
  MedianDebtMax,
  AdmissionRateMin,
  AdmissionRateMax,
  EnrollmentMin,
  EnrollmentMax,
  EndowmentMin,
  EndowmentMax,
  ValidationSchema
} from './schemas/CollegeExploreFilterSchema'
import CurrencyInput from './components/CurrencyInput'
import TooltippedText from './components/TooltippedText'
import RangePicker from './components/RangePicker'

import { DEFAULT_SPACING } from './styles'
import { black, darkblue, grayblue, primary, secondary } from './styles/colors'

const qs = require('qs')

const basicStyles = {
  container: {
    padding: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  textCon: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  header: {
    marginVertical: DEFAULT_SPACING,
    fontWeight: '600',
    fontSize: 18,
    letterSpacing: 0,
    color: darkblue
  },
  tabHeader: {
    marginVertical: 0,
    fontWeight: '500',
    color: secondary,
    borderLeftWidth: 2,
    borderLeftColor: '#0000',
    paddingLeft: 18,
    paddingVertical: 6
  },
  activeHeader: {
    marginVertical: 0,
    fontWeight: '500',
    borderLeftWidth: 2,
    paddingLeft: 18,
    paddingVertical: 6,
    borderLeftColor: '#67BC7C',
    color: darkblue
  },
  subheader: {
    fontWeight: '500',
    fontSize: 16,
    marginBottom: DEFAULT_SPACING,
    color: grayblue
  },
  multisliderContainer: { alignItems: 'center' },
  center: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: DEFAULT_SPACING
  },
  divider: { marginVertical: DEFAULT_SPACING },
  inputStyle: {
    height: 65,
    marginBottom: 20
  },
  clear: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  description: {
    textAlign: 'center',
    paddingBottom: 10
  },
  leftLabel: {
    fontSize: 14,
    fontWeight: '600',
    color: grayblue,
    letterSpacing: 0
  },
  modalHeader: {
    fontSize: 28,
    fontWeight: '600',
    padding: 20
  },
  formContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  buttonGroup: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: { multisliderContainer: { paddingBottom: 12 } }
})

export default function CollegeExploreFilterScreen({ navigation, onClose }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [tab, setTab] = useState('location')

  const DefaultDebtRateOptions = [
    {
      label: '',
      value: ''
    },
    {
      label: '<3%',
      value: '0.03'
    },
    {
      label: '<5%',
      value: '0.05'
    },
    {
      label: '<10%',
      value: '0.10'
    },
    {
      label: '<15%',
      value: '0.15'
    }
  ]

  const [studentDebtTooltipIsVisible, setStudentDebtTooltipIsVisible] =
    useState(false)
  const [endowmentTooltipIsVisible, setEndowmentTooltipIsVisible] =
    useState(false)
  const [medianEarningsTooltipIsVisible, setMedianEarningsTooltipIsVisible] =
    useState(false)

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      title: 'Explore Colleges'
    })
  }, [navigation])

  function onMultiChange(values, handleChange) {
    return (item) => {
      handleChange({
        target: {
          name: Fields.listOfObjectStates,
          value: xorBy(values[Fields.listOfObjectStates], [item], 'id')
        }
      })
    }
  }

  const tabs = [
    {
      key: 'location',
      label: 'Location'
    },
    {
      key: 'admissions',
      label: 'Admissions'
    },
    {
      key: 'tuitionAndCost',
      label: 'Tuition & Cost'
    },
    {
      key: 'studentDebtProfile',
      label: 'Student Debt Profile',
      tooltip: () => (
        <TooltippedText
          visible={studentDebtTooltipIsVisible}
          setVisible={setStudentDebtTooltipIsVisible}
          anchorStyle={styles.header}
          anchorText="Student Debt Profile"
          contentHeader="Student Debt Profile"
          contentBody={
            "Helps you understand students' ability to pay back their loans (default rate) and how much debt they graduate with (median debt)."
          }
        />
      )
    },
    {
      key: 'endowment',
      label: 'Endowment',
      tooltip: () => (
        <TooltippedText
          visible={endowmentTooltipIsVisible}
          setVisible={setEndowmentTooltipIsVisible}
          anchorStyle={styles.header}
          anchorText="Endowment"
          contentHeader="Endowment"
          contentBody={
            "Troutwood shows Endowment data because it is a measure of a school's overall financial resources. This is especially true for private institutions. Schools with large endowments can afford to be more generous with financial aid grants and scholarships, often resulting in favorable Net Cost for students. Endowment size can also be a reflection on the strength and engagement of a school's alumni network, its ability to build and maintain programs and facilities, as well as its ability to attract top talent."
          }
        />
      )
    },
    {
      key: 'postGraduationOutcomes',
      label: 'Post Graduation Outcomes'
    }
  ]

  const handleSubmit = async (values, { resetForm }) => {
    let listOfStates = []
    const q = '/api/v1/colleges/search/?'
    const keys = Object.keys(InitialValues)
    const filters = []
    const filtersToExclude = [
      'outOfState',
      'inState',
      'sixYears',
      'eightYears',
      'tenYears',
      'median_Earnings',
      'listOfObjectStates'
    ]
    const defaultedValues = [
      'sat',
      'act',
      'tuition',
      'admission_rate',
      'enrollment'
    ]
    const clonedValues = { ...values }

    keys.map((key) => {
      if (
        InitialValues[key] != clonedValues[key] ||
        defaultedValues.includes(key)
      ) {
        if (!filtersToExclude.includes(key)) {
          filters.push(key)
        }
      }
    })

    clonedValues.admission_rate = clonedValues.admission_rate.map(
      (val) => val / 100
    )

    let query =
      q +
      qs.stringify(clonedValues, {
        encode: false,
        indices: false,
        arrayFormat: 'comma',
        filter: filters
      })

    if (clonedValues[Fields.listOfObjectStates].length != 0) {
      listOfStates = clonedValues[Fields.listOfObjectStates].map(
        (val) => val.id
      )
      query +=
        query == q
          ? `state=${listOfStates.join(',')}`
          : `&state=${listOfStates.join(',')}`
    }

    if (values[Fields.studentDebtRate] != '') {
      query += '&graduate_default_rate__gt=0'
    }

    resetForm({ InitialValues })
    if (Platform.OS !== 'web') {
      navigation.navigate('Modal', {
        screen: 'College Explore Search',
        params: { query }
      })
    } else {
      navigation.navigate('Modal', {
        screen: 'College Explore Page',
        params: { query }
      })
      onClose()
    }
  }

  const onClear = (e) => {
    if (Platform.OS !== 'web') {
      navigation.navigate('Modal', {
        screen: 'College Explore Search',
        params: { query: '/api/v1/colleges/search/?' }
      })
    } else {
      navigation.navigate('Modal', {
        screen: 'College Explore Page',
        params: { query: '/api/v1/colleges/search/?' }
      })
      onClose()
    }
  }

  return (
    <Container withPadding styles={styles.container}>
      {!isNotWeb && <Text style={styles.modalHeader}>Filters</Text>}
      <Form
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={ValidationSchema}
        buttonText="Apply Filters"
        onSubmit={handleSubmit}
        negativeButtonText="Clear All Filters"
        onNegativeButtonPress={onClear}
      >
        {({ handleChange, handleBlur, values, resetForm, errors, touched }) => (
          <View style={styles.formContent}>
            {!isNotWeb && (
              <View style={{ width: '40%' }}>
                {tabs.map((t, index) => (
                  <Text
                    key={t.key}
                    style={
                      t.key === tab ? styles.activeHeader : styles.tabHeader
                    }
                    onPress={() => setTab(t.key)}
                  >
                    {t.label}
                  </Text>
                ))}
              </View>
            )}
            <View style={{ width: !isNotWeb ? '55%' : '100%' }}>
              {(tab === 'location' || isNotWeb) && (
                <>
                  {isNotWeb && (
                    <HeaderLabel style={styles.header}>Location</HeaderLabel>
                  )}
                  <View style={{ width: '100%' }}>
                    <SelectBox
                      label="State"
                      options={locationOptions}
                      selectedValues={values[Fields.listOfObjectStates]}
                      onMultiSelect={onMultiChange(values, handleChange)}
                      onTapClose={onMultiChange(values, handleChange)}
                      isMulti
                      selectIcon={
                        <FontAwesomeIcon icon="caret-down" color={black} />
                      }
                      containerStyle={styles.selectBoxContainerStyle}
                      multiOptionContainerStyle={{ backgroundColor: primary }}
                      searchIconColor={primary}
                      inputFilterContainerStyle={{
                        backgroundColor: 'transparent'
                      }}
                    />
                  </View>
                </>
              )}
              {(tab === 'admissions' || isNotWeb) && (
                <>
                  {isNotWeb && (
                    <HeaderLabel style={styles.header}>Admission</HeaderLabel>
                  )}

                  <HeaderLabel style={styles.subheader}>
                    Admissions Rate
                  </HeaderLabel>

                  <View style={styles.multisliderContainer}>
                    {!isNotWeb ? (
                      <RangePicker
                        min={AdmissionRateMin}
                        max={AdmissionRateMax}
                        values={values[Fields.admissionsRate]}
                        onValuesChange={(values) => {
                          handleChange({
                            target: {
                              name: Fields.admissionsRate,
                              value: values
                            }
                          })
                        }}
                      />
                    ) : (
                      <MultiSlider
                        values={values[Fields.admissionsRate]}
                        min={AdmissionRateMin}
                        max={AdmissionRateMax}
                        showSteps
                        allowOverlap={false}
                        step={5}
                        enableLabel
                        percentage
                        minMarkerOverlapStepDistance={2}
                        onValuesChange={(values) => {
                          handleChange({
                            target: {
                              name: Fields.admissionsRate,
                              value: values
                            }
                          })
                        }}
                      />
                    )}
                  </View>

                  <HeaderLabel style={styles.subheader}>Enrollment</HeaderLabel>

                  <View style={[styles.multisliderContainer]}>
                    {!isNotWeb ? (
                      <RangePicker
                        values={values[Fields.enrollment]}
                        min={EnrollmentMin}
                        max={EnrollmentMax}
                        onValuesChange={(values) => {
                          handleChange({
                            target: { name: Fields.enrollment, value: values }
                          })
                        }}
                      />
                    ) : (
                      <MultiSlider
                        values={values[Fields.enrollment]}
                        min={EnrollmentMin}
                        max={EnrollmentMax}
                        showSteps
                        allowOverlap={false}
                        step={10000}
                        enableLabel
                        minMarkerOverlapStepDistance={2}
                        onValuesChange={(values) => {
                          handleChange({
                            target: { name: Fields.enrollment, value: values }
                          })
                        }}
                      />
                    )}
                  </View>

                  <Divider style={styles.divider} />

                  <Text style={styles.subheader}>SAT Range</Text>

                  <View style={styles.multisliderContainer}>
                    {!isNotWeb ? (
                      <RangePicker
                        values={values[Fields.sat]}
                        min={SATmin}
                        max={SATmax}
                        onValuesChange={(values) => {
                          handleChange({
                            target: { name: Fields.sat, value: values }
                          })
                        }}
                      />
                    ) : (
                      <MultiSlider
                        values={values[Fields.sat]}
                        min={SATmin}
                        max={SATmax}
                        showSteps
                        allowOverlap={false}
                        step={10}
                        enableLabel
                        minMarkerOverlapStepDistance={2}
                        onValuesChange={(values) => {
                          handleChange({
                            target: { name: Fields.sat, value: values }
                          })
                        }}
                      />
                    )}
                  </View>

                  {isNotWeb && (
                    <View style={styles.textCon}>
                      <Text>{SATmin}</Text>
                      <Text>{SATmax}</Text>
                    </View>
                  )}

                  <Text style={styles.subheader}>ACT Range</Text>
                  <View style={styles.multisliderContainer}>
                    {!isNotWeb ? (
                      <RangePicker
                        values={values[Fields.act]}
                        min={ACTmin}
                        max={ACTmax}
                        onValuesChange={(values) => {
                          handleChange({
                            target: { name: Fields.act, value: values }
                          })
                        }}
                      />
                    ) : (
                      <MultiSlider
                        values={values[Fields.act]}
                        min={ACTmin}
                        max={ACTmax}
                        step={1}
                        enableLabel
                        minMarkerOverlapStepDistance={2}
                        onValuesChange={(values) => {
                          handleChange({
                            target: { name: Fields.act, value: values }
                          })
                        }}
                      />
                    )}
                  </View>
                  {isNotWeb && (
                    <View style={styles.textCon}>
                      <Text>{ACTmin}</Text>
                      <Text>{ACTmax}</Text>
                    </View>
                  )}
                </>
              )}
              {(tab === 'tuitionAndCost' || isNotWeb) && (
                <>
                  {isNotWeb && (
                    <HeaderLabel style={styles.header}>
                      Tuition and Cost
                    </HeaderLabel>
                  )}

                  <Text style={styles.subheader}>Average Net Cost</Text>

                  <View style={styles.center}>
                    <SquareCheckBox
                      isChecked={values[Fields.inState]}
                      rightText="In State"
                      onClick={(checked) => {
                        handleChange({
                          target: {
                            name: Fields.inState,
                            value: !values[Fields.inState]
                          }
                        })
                        checked
                          ? handleChange({
                              target: {
                                name: Fields.tuitionfee_in,
                                value: values[Fields.tuition]
                              }
                            })
                          : handleChange({
                              target: {
                                name: Fields.tuitionfee_in,
                                value: ''
                              }
                            })
                      }}
                    />
                    <SquareCheckBox
                      isChecked={values[Fields.outOfState]}
                      rightText="Out of State"
                      onClick={(checked) => {
                        handleChange({
                          target: { name: Fields.outOfState, value: checked }
                        })
                        checked
                          ? handleChange({
                              target: {
                                name: Fields.tuitionfee_out,
                                value: values[Fields.tuition]
                              }
                            })
                          : handleChange({
                              target: {
                                name: Fields.tuitionfee_out,
                                value: ''
                              }
                            })
                      }}
                    />
                  </View>

                  <View style={styles.multisliderContainer}>
                    {!isNotWeb ? (
                      <RangePicker
                        values={values[Fields.tuition]}
                        min={TuitionMin}
                        max={TuitionMax}
                        onValuesChange={(vals) => {
                          handleChange({
                            target: { name: Fields.tuition, value: vals }
                          })
                          values[Fields.inState]
                            ? handleChange({
                                target: {
                                  name: Fields.tuitionfee_in,
                                  value: vals
                                }
                              })
                            : handleChange({
                                target: {
                                  name: Fields.tuitionfee_int,
                                  value: ''
                                }
                              })
                          values[Fields.outOfState]
                            ? handleChange({
                                target: {
                                  name: Fields.tuitionfee_out,
                                  value: vals
                                }
                              })
                            : handleChange({
                                target: {
                                  name: Fields.tuitionfee_out,
                                  value: ''
                                }
                              })
                        }}
                      />
                    ) : (
                      <MultiSlider
                        values={values[Fields.tuition]}
                        min={TuitionMin}
                        max={TuitionMax}
                        step={1000}
                        enableLabel
                        currency
                        minMarkerOverlapStepDistance={2}
                        onValuesChange={(vals) => {
                          handleChange({
                            target: { name: Fields.tuition, value: vals }
                          })
                          values[Fields.inState]
                            ? handleChange({
                                target: {
                                  name: Fields.tuitionfee_in,
                                  value: vals
                                }
                              })
                            : handleChange({
                                target: {
                                  name: Fields.tuitionfee_int,
                                  value: ''
                                }
                              })
                          values[Fields.outOfState]
                            ? handleChange({
                                target: {
                                  name: Fields.tuitionfee_out,
                                  value: vals
                                }
                              })
                            : handleChange({
                                target: {
                                  name: Fields.tuitionfee_out,
                                  value: ''
                                }
                              })
                        }}
                      />
                    )}
                  </View>

                  {isNotWeb && (
                    <View style={styles.textCon}>
                      <Text>$0K</Text>
                      <Text>$80K</Text>
                    </View>
                  )}
                </>
              )}
              {(tab === 'studentDebtProfile' || isNotWeb) && (
                <>
                  {isNotWeb && (
                    <TooltippedText
                      visible={studentDebtTooltipIsVisible}
                      setVisible={setStudentDebtTooltipIsVisible}
                      anchorStyle={styles.header}
                      anchorText="Student Debt Profile"
                      contentHeader="Student Debt Profile"
                      contentBody={
                        "Helps you understand students' ability to pay back their loans (default rate) and how much debt they graduate with (median debt)."
                      }
                    />
                  )}
                  <InputSelect
                    label="Default Rate"
                    value={values[Fields.studentDebtRate]}
                    options={DefaultDebtRateOptions}
                    onSubmitEditing={handleChange(Fields.studentDebtRate)}
                    style={styles.inputStyle}
                  />

                  <HeaderLabel style={styles.subheader}>
                    Median Debt
                  </HeaderLabel>

                  <View style={styles.multisliderContainer}>
                    {!isNotWeb ? (
                      <RangePicker
                        values={values[Fields.medianDebt]}
                        min={MedianDebtMin}
                        max={MedianDebtMax}
                        onValuesChange={(vals) => {
                          handleChange({
                            target: { name: Fields.medianDebt, value: vals }
                          })
                        }}
                      />
                    ) : (
                      <MultiSlider
                        values={values[Fields.medianDebt]}
                        min={MedianDebtMin}
                        max={MedianDebtMax}
                        step={5000}
                        enableLabel
                        currency
                        minMarkerOverlapStepDistance={2}
                        onValuesChange={(vals) => {
                          handleChange({
                            target: { name: Fields.medianDebt, value: vals }
                          })
                        }}
                      />
                    )}
                  </View>
                </>
              )}
              {(tab === 'endowment' || isNotWeb) && (
                <>
                  <HeaderLabel style={styles.subheader}>Endowment</HeaderLabel>

                  <View style={styles.multisliderContainer}>
                    {!isNotWeb ? (
                      <RangePicker
                        values={values[Fields.endowment]}
                        min={EndowmentMin}
                        max={EndowmentMax}
                        onValuesChange={(vals) => {
                          handleChange({
                            target: { name: Fields.endowment, value: vals }
                          })
                        }}
                      />
                    ) : (
                      <MultiSlider
                        values={values[Fields.endowment]}
                        min={EndowmentMin}
                        max={EndowmentMax}
                        step={500000000}
                        enableLabel
                        currency
                        minMarkerOverlapStepDistance={2}
                        onValuesChange={(vals) => {
                          handleChange({
                            target: { name: Fields.endowment, value: vals }
                          })
                        }}
                      />
                    )}
                  </View>
                </>
              )}
              {(tab === 'postGraduationOutcomes' || isNotWeb) && (
                <>
                  <View style={{ flexDirection: 'row' }}>
                    <TooltippedText
                      visible={medianEarningsTooltipIsVisible}
                      setVisible={setMedianEarningsTooltipIsVisible}
                      anchorStyle={styles.subheader}
                      anchorText="Median Earnings After Graduation"
                      contentHeader="Median Earnings After Graduation"
                      contentBody="Median Earnings After Graduation data may provide a ballpark estimate of annual compensation graduates of a school may expect to earn following completion of their degrees. Not all schools provide this data, and it should not be considered perfect information, but it is a reference point that many students and families ask to see."
                    />
                  </View>

                  <View style={styles.center}>
                    <SquareCheckBox
                      isChecked={values[Fields.sixYears]}
                      rightText="6 Years"
                      onClick={(checked) => {
                        handleChange({
                          target: { name: Fields.sixYears, value: checked }
                        })
                        handleChange({
                          target: {
                            name: Fields.medianEarnings6,
                            value: checked ? values[Fields.medianEarnings] : ''
                          }
                        })
                      }}
                    />
                    <SquareCheckBox
                      isChecked={values[Fields.eightYears]}
                      rightText="8 Years"
                      onClick={(checked) => {
                        handleChange({
                          target: { name: Fields.eightYears, value: checked }
                        })
                        handleChange({
                          target: {
                            name: Fields.medianEarnings8,
                            value: checked ? values[Fields.medianEarnings] : ''
                          }
                        })
                      }}
                    />

                    <SquareCheckBox
                      isChecked={values[Fields.tenYears]}
                      rightText="10 Years"
                      onClick={(checked) => {
                        handleChange({
                          target: { name: Fields.tenYears, value: checked }
                        })
                        handleChange({
                          target: {
                            name: Fields.medianEarnings10,
                            value: checked ? values[Fields.medianEarnings] : ''
                          }
                        })
                      }}
                    />
                  </View>

                  <CurrencyInput
                    type="money"
                    unit="$"
                    name={Fields.medianEarnings}
                    onBlur={handleBlur(Fields.medianEarnings)}
                    value={values[Fields.medianEarnings]}
                    label="How much do you expect to earn post-grad?"
                    returnKeyType="next"
                    enablesReturnKeyAutomatically
                    placeholder="$"
                    minValue={0}
                    keyboardType="number-pad"
                    autoComplete="off"
                    caretHidden
                    errorMessage={errors[Fields.medianEarnings]}
                    touched={touched[Fields.medianEarnings]}
                    onChangeText={(val) => {
                      const vals = val.target.value
                      handleChange({
                        target: {
                          name: Fields.medianEarnings6,
                          value: values[Fields.sixYears] ? vals : ''
                        }
                      })
                      handleChange({
                        target: {
                          name: Fields.medianEarnings8,
                          value: values[Fields.eightYears] ? vals : ''
                        }
                      })
                      handleChange({
                        target: {
                          name: Fields.medianEarnings10,
                          value: values[Fields.tenYears] ? vals : ''
                        }
                      })
                      handleChange({
                        target: { name: Fields.medianEarnings, value: vals }
                      })
                    }}
                  />
                </>
              )}
            </View>
          </View>
        )}
      </Form>
    </Container>
  )
}
