import { useState, useEffect } from 'react'
import { View, Text, StyleSheet, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { each, isNull } from 'lodash'
import {
  DurationValues,
  TermPreferenceValues,
  DURATION_LONG_TERM,
  DURATION_SHORT_TERM,
  AMORTIZATION_TYPE_REVOLVING_LOAN,
  AMORTIZATION_TYPE_TERM_LOAN,
  RevolvingOptions,
  Fields,
  InitialValues,
  ValidationSchema
} from 'schemas/balanceSheetSchema'
import InputSelect from './components/InputSelect'
import Input from './components/Input'
import NumberInput from './components/NumberInput'
import ButtonGroup from './components/ButtonGroup'
import Divider from './components/Divider'
import DateTimePicker from './components/DateTimePicker'
import { lightestgray, red, white, DEFAULT_SPACING } from './styles'
import Form from './components/Form'
import { saveBSAccount, deleteBSAccount } from './actions'
import {
  ASSET,
  LIABILITY,
  LONG_TERM_BS_ACCOUNT_TYPE,
  NAVIGATION_TYPE,
  SHORT_TERM_BS_ACCOUNT_TYPE
} from './constants'
import {
  bsAccountCategoriesSelector,
  bsAccountSelector,
  bsAccountsSelector,
  primaryProPlanSelector
} from './selectors'
import CurrencyInput from './components/CurrencyInput'
import SlideupDrawer from './components/SlideupDrawer'
import BSAccountForm from './components/budgeting/BSAccountForm'

const styles = StyleSheet.create({
  container: { backgroundColor: white },
  contentContainer: {
    height: '100%',
    justifyContent: 'space-between'
  },
  footerContainer: { marginBottom: 20 },
  body: {
    borderTopWidth: 1,
    borderColor: lightestgray,
    justifyContent: 'space-evenly'
  },
  accountDurationLabel: {
    textDecorationLine: 'underline',
    marginBottom: 8
  },
  accountDurationContainer: { marginBottom: 20 },
  asterisk: { color: red },
  modalContainer: { paddingHorizontal: DEFAULT_SPACING }
})

const labels = {
  [ASSET]: {
    categorySelectorLabel: 'Asset Category',
    accountDurationLabel: 'Asset Type'
  },
  [LIABILITY]: {
    categorySelectorLabel: 'Liability Category',
    accountDurationLabel: 'Liability Type'
  }
}

function getApplicableCategories(allCategories, categories, accountType) {
  const applicableCategories = []

  if (categories && categories.length > 0) {
    each(categories, (cat) => {
      const category = allCategories.find((c) => c.key === cat)
      if (category && category.type === accountType) {
        applicableCategories.push({
          ...category,
          value: category.key
        })
      }
    })
  } else {
    each(allCategories, (cat) => {
      if (cat.type === accountType) {
        applicableCategories.push({
          ...cat,
          value: cat.key
        })
      }
    })
  }

  return applicableCategories
}

function getInitialValues(bsAccount) {
  const baseValues = {
    [Fields.name]: bsAccount[Fields.name],
    [Fields.value]: bsAccount[Fields.value],
    [Fields.rateOfReturn]: bsAccount[Fields.rateOfReturn],
    [Fields.duration]:
      bsAccount[Fields.duration] === SHORT_TERM_BS_ACCOUNT_TYPE
        ? DURATION_SHORT_TERM
        : DURATION_LONG_TERM,
    [Fields.categoryId]: bsAccount[Fields.categoryId],
    [Fields.amortizationType]: bsAccount?.advanced?.amortization?.type
      ? bsAccount?.advanced?.amortization?.type
      : null
  }

  if (bsAccount?.advanced?.amortization?.type === AMORTIZATION_TYPE_TERM_LOAN) {
    return {
      ...baseValues,
      [Fields.termOriginalLoanAmount]:
        bsAccount?.advanced?.amortization?.conditions?.[
          Fields.termOriginalLoanAmount
        ],
      [Fields.termMonths]:
        bsAccount?.advanced?.amortization?.conditions?.[Fields.termMonths],
      [Fields.termEndDate]:
        bsAccount?.advanced?.amortization?.conditions?.[Fields.termEndDate],
      [Fields.termExtraPaymentPreference]:
        bsAccount?.advanced?.amortization?.conditions?.[
          Fields.termExtraPaymentPreference
        ]
    }
  }
  if (
    bsAccount?.advanced?.amortization?.type === AMORTIZATION_TYPE_REVOLVING_LOAN
  ) {
    return {
      ...baseValues,
      [Fields.revolvingMinPaymentAmount]:
        bsAccount?.advanced?.amortization?.conditions?.[
          Fields.revolvingMinPaymentAmount
        ],
      [Fields.revolvingMinPaymentPct]:
        bsAccount?.advanced?.amortization?.conditions?.[
          Fields.revolvingMinPaymentPct
        ],
      [Fields.revolvingPaymentDayOfMonth]:
        bsAccount?.advanced?.amortization?.conditions?.[
          Fields.revolvingPaymentDayOfMonth
        ]
    }
  }
  return baseValues
}

export default function EditAddBSAccountScreen({ navigation, route }) {
  const {
    params: { accountType, accountId, categories }
  } = route
  const [visible, setVisible] = useState(false)

  const bsAccount = accountId
    ? useSelector((state) => bsAccountSelector(state, accountId, accountType))
    : null

  const initialValues = bsAccount ? getInitialValues(bsAccount) : InitialValues

  const dispatch = useDispatch()

  const allCategories = useSelector(bsAccountCategoriesSelector)
  const applicableCategories = getApplicableCategories(
    allCategories,
    categories,
    accountType
  )
  const { assetAccounts, liabilityAccounts } = useSelector(bsAccountsSelector)
  const bsAccounts = [...assetAccounts, ...liabilityAccounts]

  const primaryProPlan = useSelector(primaryProPlanSelector)
  const { cashSweepLogic } = primaryProPlan || {}

  const labelsPack = labels[accountType]

  const onDelete = async () => {
    navigation.popToTop()
    navigation.pop()
    dispatch(deleteBSAccount({ accountId, accountType }))
  }

  const onDeletePopup = () => {
    setVisible(true)
  }

  const onSave = async (values) => {
    const position =
      bsAccount && bsAccount.position ? bsAccount.position : bsAccounts.length

    const amortizationType = values[Fields.amortizationType]

    const conditions = {
      [Fields.termOriginalLoanAmount]:
        amortizationType == AMORTIZATION_TYPE_TERM_LOAN
          ? values[Fields.termOriginalLoanAmount]
          : undefined,
      [Fields.termMonths]:
        amortizationType == AMORTIZATION_TYPE_TERM_LOAN
          ? Number(values[Fields.termMonths])
          : undefined,
      [Fields.termEndDate]:
        amortizationType == AMORTIZATION_TYPE_TERM_LOAN
          ? values[Fields.termEndDate]
          : undefined,
      [Fields.termExtraPaymentPreference]:
        amortizationType == AMORTIZATION_TYPE_TERM_LOAN
          ? values[Fields.termExtraPaymentPreference]
          : undefined,
      [Fields.revolvingMinPaymentAmount]:
        amortizationType == AMORTIZATION_TYPE_REVOLVING_LOAN
          ? values[Fields.revolvingMinPaymentAmount]
          : undefined,
      [Fields.revolvingMinPaymentPct]:
        amortizationType == AMORTIZATION_TYPE_REVOLVING_LOAN
          ? parseFloat(values[Fields.revolvingMinPaymentPct])
          : undefined,
      [Fields.revolvingPaymentDayOfMonth]:
        amortizationType == AMORTIZATION_TYPE_REVOLVING_LOAN
          ? Number(values[Fields.revolvingPaymentDayOfMonth])
          : undefined
    }

    const amortization = { type: amortizationType, conditions }
    const advanced = { amortization }

    const payload = {
      accountId,
      accountType,
      position,
      ...values,
      [Fields.duration]:
        values[Fields.duration] === DURATION_SHORT_TERM
          ? SHORT_TERM_BS_ACCOUNT_TYPE
          : LONG_TERM_BS_ACCOUNT_TYPE
    }

    // Because the form for some reason returns a string
    payload.defaultRate = parseFloat(payload.defaultRate)

    if (amortizationType) {
      payload.advanced = advanced
    }

    try {
      await dispatch(saveBSAccount(payload))
      navigation.goBack()
    } catch (error) {
      handleError(error)
    }
  }

  const possibleIds = cashSweepLogic
    ? [
        cashSweepLogic.shortTermCashAccountId,
        cashSweepLogic.cashOverflowAccountId,
        cashSweepLogic.shortTermDebtAccountId
      ]
    : []

  const includedInPossibleIds = possibleIds.includes(accountId)

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
    >
      <Form
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={onSave}
        onNegativeButtonPress={
          accountId && !includedInPossibleIds
            ? onDelete
            : includedInPossibleIds
            ? onDeletePopup
            : undefined
        }
        disabled
      >
        {({
          setFieldValue,
          handleChange,
          handleBlur,
          touched,
          values,
          errors
        }) => {
          useEffect(() => {
            if (accountType === LIABILITY && values[Fields.categoryId]) {
              if (
                values[Fields.revolvingMinPaymentAmount] ||
                values[Fields.revolvingMinPaymentPct] ||
                values[Fields.revolvingPaymentDayOfMonth]
              ) {
                setFieldValue(
                  Fields.amortizationType,
                  AMORTIZATION_TYPE_REVOLVING_LOAN
                )
              } else if (
                values[Fields.termOriginalLoanAmount] ||
                values[Fields.termMonths] ||
                values[Fields.termEndDate] ||
                values[Fields.termExtraPaymentPreference]
              ) {
                setFieldValue(
                  Fields.amortizationType,
                  AMORTIZATION_TYPE_TERM_LOAN
                )
              } else {
                setFieldValue(Fields.amortizationType, null)
              }
            } else {
              setFieldValue(Fields.amortizationType, null)
            }
          }, [values])

          return (
            <BSAccountForm
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              accountType={accountType}
              applicableCategories={applicableCategories}
              allCategories={allCategories}
              labelsPack={labelsPack}
              Fields={Fields}
            />
          )
        }}
      </Form>

      <SlideupDrawer
        title={'Cannot delete account'}
        snapPointIndex={1}
        visible={visible}
        setVisible={setVisible}
      >
        <View style={styles.modalContainer}>
          <Text>
            This account is currently designated as a Cashflow Waterfall
            account. Please re-designate a different account as a Cashflow
            Waterfall account in this account’s place before attempting to
            delete this account.
          </Text>
        </View>
      </SlideupDrawer>
    </ScrollView>
  )
}

EditAddBSAccountScreen.propTypes = {
  accountType: PropTypes.oneOf([ASSET, LIABILITY]).isRequired,
  navigation: NAVIGATION_TYPE.isRequired,
  route: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired
}
