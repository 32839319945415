import { Platform, View, ScrollView, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { DEFAULT_SPACING, lightestgray } from '../styles'

import TopTabBar from './tabbed-view/TopTabBar'

const basicStyles = {
  container: {
    position: 'relative',
    flex: 1,
    backgroundColor: lightestgray,
    paddingTop: 60
  },
  topTabBarStyle: { height: 60, },
  scrollview: { paddingVertical: DEFAULT_SPACING, },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        backgroundColor: 'none',
        overflow: 'auto',
      },
      topTabBarStyle: { height: 80, },
    },
  }
)

export default function TabbedView({
  option,
  setOption,
  options,
  totalWidth,
  params,
  containerStyle = {},
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const topTabBarOptions = Object.keys(options)
  const CurrentView = options[option]

  return (
    <View style={[styles.container, containerStyle]}>
      <TopTabBar
        topTabBarStyle={styles.topTabBarStyle}
        option={option}
        options={topTabBarOptions}
        scrollable
        setOption={setOption}
        totalWidth={totalWidth}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollview}
      >
        <CurrentView {...params} />
      </ScrollView>
    </View>
  )
}
