import { useLayoutEffect, useState } from 'react'
import { View, ScrollView, Text, StyleSheet, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import ListOptionItem from './components/ListOptionItem'
import hapticFeedback from './components/hapticFeedback'
import Switch from './components/Switch'
import CircleIcon from './components/CircleIcon'
import BottomButton from './components/BottomButton'
import Container from './components/Container'
import { saveMetaData } from './actions'
import { currentUserSelector } from './selectors'
import {
  darkblue,
  gray,
  skyblue,
  gunmetal,
  lightestgray,
  primary,
  rgba,
  white,
  secondary,
  lightblue
} from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import Modal from './components/Modal'
import ToolsHeaderWrapper from './components/tools/ToolsHeaderWrapper'
import PageHeader from './components/PageHeader'
import CarouselCardItem from './components/CarouselCardItem'
import data from './assets/data/startInvestingMissionData'
import { fixTitle } from './StartInvestingCarouselScreen'

const categories = [
  {
    icon: 'piggy-bank',
    title: 'Open Roth IRA',
    value: 'roth-ira'
  },
  {
    icon: 'chart-line',
    title: 'Open a Trading Account',
    value: 'trading'
  },
  {
    icon: 'arrow-right-arrow-left',
    title: 'Transfer 401k',
    value: 'transfer-401k'
  },
  {
    icon: 'arrow-right-arrow-left',
    title: 'Transfer IRA',
    value: 'transfer-ira'
  },
  {
    icon: 'coins',
    title: 'S&P 500 ETF',
    value: 'etf'
  },
  {
    icon: 'bullseye',
    title: 'Target Date Fund',
    value: 'target-date'
  },
  {
    icon: 'door-open',
    title: 'Open Custodial Account',
    value: 'custodial'
  }
]

const metadataKey = 'TROUTWOOD_START_INVESTING_SHOW_SETUP'

const basicStyles = {
  container: {
    backgroundColor: lightestgray,
    position: 'relative',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  main: {
    width: '100%',
    flex: 1,
    justifyContent: 'space-between'
  },
  left: {
    width: '39%',
    height: '100%'
  },
  mainList: { paddingVertical: 40, paddingHorizontal: 20 },
  right: {
    width: '60%',
    height: '100%'
  },
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 63,
    height: 63,
    borderRadius: 63 / 2,
    overflow: 'hidden'
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0,
    marginLeft: 20
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5,
    marginLeft: 20,
    marginTop: 4
  },
  disclosure: {
    fontSize: 12,
    color: gray,
    fontStyle: 'italic',
    width: '80%',
    justifyContent: 'center',
    textAlign: 'center',
    alignSelf: 'center',
    paddingTop: 40,
    opacity: 0.5
  },
  scrollViewContainer: { padding: DEFAULT_SPACING },
  dontShowContainer: {
    width: '80%',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: DEFAULT_SPACING
  },
  modal: {
    backgroundColor: white,
    maxWidth: 500,
    height: '80%',
    maxHeight: 600,
    borderRadius: 12,
    paddingHorizontal: 40,
    paddingVertical: 32
  },
  modalTitle: {
    color: darkblue,
    fontSize: 28,
    fontWeight: '600'
  },
  description: {
    fontSize: 16,
    letterSpacing: -0.4,
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING
  },
  listContainer: { width: '100%', alignSelf: 'center', left: '10%' },
  listItemTitle: {
    fontWeight: '600',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    container: { padding: 40 },
    scrollViewContainer: { padding: 0 },
    description: {
      color: secondary,
      textAlign: 'left',
      width: '100%',
      marginTop: 23
    },
    listContainer: {
      left: 0,
      alignSelf: 'flex-start'
    },
    main: { marginTop: 16, flexDirection: 'row' },
    mainList: { paddingVertical: 0, paddingHorizontal: 0 }
  }
})

const isNotWeb = Platform.OS !== 'web'

function HorizontalOptionItem({
  icon,
  title,
  value,
  navigation,
  activeTab,
  onClick
}) {

  return (
    <ListOptionItem
      containerStyle={{
        marginBottom: 10,
        opacity: isNotWeb || value === activeTab.value ? 1 : 0.6
      }}
      iconComponent={
        <CircleIcon
          style={{ marginRight: DEFAULT_SPACING }}
          icon={icon}
          color={skyblue}
          backgroundColor={rgba(skyblue, 0.1)}
        />
      }
      label={title}
      navigatable
      onPress={() => {
        hapticFeedback()
        if (isNotWeb)
          navigation.navigate('Modal', {
            screen: 'Start Investing Carousel',
            params: { title, value }
          })
        else onClick(value)
      }}
    />
  )
}

function LineItem({ title, subtitle }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View style={{ flexDirection: 'row', marginBottom: 20 }}>
      <View>
        <FontAwesomeIcon
          icon="check"
          color={isNotWeb ? primary : lightblue}
          size={16}
        />
      </View>
      <View style={{ marginLeft: DEFAULT_SPACING }}>
        <Text style={styles.listItemTitle}>
          {title} {!isNotWeb && `(${subtitle})`}
        </Text>
        {isNotWeb && (
          <Text
            style={{
              fontSize: 14,
              color: gunmetal,
              letterSpacing: 0
            }}
          >
            {subtitle}
          </Text>
        )}
      </View>
    </View>
  )
}

export default function StartInvestingCategoryScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [isEnabled, setIsEnabled] = useState(false)
  const [tempDoNotShow, setTempDoNotShow] = useState(false)
  const [activeTab, setActiveTab] = useState(categories[0])
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => currentUserSelector(state))
  const { metadata } = currentUser

  const toggleSwitch = () => setIsEnabled((state) => !state)

  const lineItems = [
    { title: 'Social Security Number' },
    {
      title: 'Under 18?',
      subtitle: `You may need a parent or guardian ${'\n'}and social security number`
    },
    { title: 'Bank Account' },
    {
      title: 'Earned Income',
      subtitle: 'For Roth IRA only'
    }
  ]

  const title = 'Start Investing'

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: isNotWeb })
  }, [navigation])

  const content = (
    <>
      <ScrollView
        scrollEnabled={isNotWeb}
        contentContainerStyle={{ paddingBottom: DEFAULT_SPACING * 4 }}
        style={styles.scrollViewContainer}
      >
        {!isNotWeb && <Text style={styles.modalTitle}>{title}</Text>}
        <Text style={styles.description}>
          {
            "The hardest part is getting started! \n Let's make sure you have everything you will need!"
          }
        </Text>

        <View style={styles.listContainer}>
          {map(lineItems, (lineItem) => (
            <LineItem {...lineItem} key={lineItem.title} />
          ))}
        </View>

        <Text style={styles.disclosure}>
          The investment companies you are about to see have been selected by
          Troutwood for you to learn more, compare to one another, and gain
          confidence.
        </Text>

        <View style={styles.dontShowContainer}>
          <Switch
            value={isEnabled}
            onPress={toggleSwitch}
            label={"Don't show this again"}
          />
        </View>
      </ScrollView>

      <BottomButton
        withMargin
        onPress={() => {
          navigation.navigate('Modal', { screen: 'Start Investing Category' }),
            dispatch(saveMetaData({ [metadataKey]: isEnabled })),
            setTempDoNotShow(true)
        }}
      >
        Get Started
      </BottomButton>
    </>
  )

  const mainList = (
    <ScrollView style={styles.mainList}>
      <View>
        {map(categories, (category) => (
          <HorizontalOptionItem
            {...category}
            navigation={navigation}
            key={category.value}
            activeTab={activeTab}
            onClick={() => setActiveTab(category)}
          />
        ))}
      </View>
    </ScrollView>
  )

  if (metadata[metadataKey] || tempDoNotShow || !isNotWeb) {
    return (
      <>
        <View style={styles.container}>
          {!isNotWeb && (
            <ToolsHeaderWrapper>
              <PageHeader
                title={title}
                subTitle="Below are conflict free investment provider recommendations from Troutwood. We do not get paid by these providers,
but believe they are a pretty good place to learn more and get started."
              />
            </ToolsHeaderWrapper>
          )}
          {isNotWeb ? (
            <View>{mainList}</View>
          ) : (
            <View style={styles.main}>
              <View style={styles.left}>{mainList}</View>
              <View style={styles.right}>
                <ScrollView>
                  {data.map((item) => (
                    <CarouselCardItem
                      {...item}
                      item={item}
                      value={activeTab.value}
                      title={fixTitle(activeTab.title)}
                    />
                  ))}
                </ScrollView>
              </View>
            </View>
          )}
        </View>

        <Modal
          closeable
          visible={!metadata[metadataKey] && !tempDoNotShow}
          onClose={() => setTempDoNotShow(true)}
          style={styles.modal}
        >
          {content}
        </Modal>
      </>
    )
  }

  return <Container>{content}</Container>
}
