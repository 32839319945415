import { StyleSheet, View } from 'react-native'

import BudgetingItemsSection from './components/budgeting/BudgetingItemsSection'
import { DEFAULT_SPACING, white } from './styles'
import { useSelector, useDispatch } from 'react-redux'

import {
  currentUserSelector
} from './selectors'

const styles = StyleSheet.create({
  container: { flex: 1 },
  pillGroup: {
    width: 250,
    marginBottom: 20
  },
  pill: { width: 100 },
  pillGroupContainer: {
    alignItems: 'center',
    backgroundColor: white,
    paddingBottom: DEFAULT_SPACING * 2
  }
})

export default function BudgetingScreen({ navigation }) {
  return (
    <View style={styles.container}>
      <BudgetingItemsSection 
        navigation={navigation} 
      />
    </View>
  )
}

BudgetingScreen.propTypes = {}
