import { stateReducer } from '@truefit/redux-utils'
import { SET_FIN_PULSE_STREAKS, CLEAR } from '../actions'

const initialState = []

console.log("SET_FIN_PULSE_STREAKS", SET_FIN_PULSE_STREAKS)

export default stateReducer(initialState, {
  [SET_FIN_PULSE_STREAKS]: (_, payload) => {
    return [...payload]
  },
  [CLEAR]: () => initialState
});
