import { useEffect, useState } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { VictoryPie } from 'victory-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPencilAlt } from '@fortawesome/pro-duotone-svg-icons'

import Link from '../Link'
import {
  DEFAULT_SPACING,
  darkblue,
  grayblue,
  primary,
  secondary
} from '../../styles'
import differentAllocationsDescriptions from './differentAllocationsDescriptions'
import hapticFeedback from '../hapticFeedback'

const basicStyles = {
  container: {
    flexDirection: 'row',
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: '#E7ECED',
    borderRadius: 10
  },
  left: {
    alignItems: 'center',
    width: 160,
    paddingTop: 16
  },
  tip: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    display: 'flex',
    marginTop: 8
  },
  right: { textAlign: 'left', padding: 16, paddingLeft: 8, flex: 1 },
  header: { fontWeight: '500' },
  description: { marginTop: 4, color: secondary },
  editButton: { color: '#4F8FEF' },
  triangleLeft: {
    width: 0,
    height: 0,
    zIndex: 10,
    borderLeftWidth: 21,
    borderLeftColor: 'transparent',
    borderBottomWidth: 21,
    borderBottomColor: '#E7ECED',
    borderRightWidth: 21,
    borderRightColor: 'transparent'
  },
  boldText: { fontWeight: 'bold', color: darkblue },
  innerTriangle: {
    position: 'relative',
    top: 2,
    left: -20,
    width: 0,
    zIndex: 4,
    height: 0,
    borderLeftWidth: 20,
    borderLeftColor: 'transparent',
    borderBottomWidth: 20,
    borderBottomColor: 'white',
    borderRightWidth: 20,
    borderRightColor: 'transparent'
  },
  editBtnIcon: {
    fontSize: 0,
    color: '#4F8FEF',
    transform: [{ scaleX: 0.9 }, { scaleY: 0.9 }]
  },
  editBtnTxt: { color: '#4F8FF0', marginLeft: 4 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    left: { width: 178 },
    tip: { marginTop: 15 },
    header: { fontSize: 22 },
    description: { marginTop: 8, fontSize: 16, fontWeight: '500' },
    triangleLeft: {
      marginTop: 14,
      borderLeftWidth: 12,
      borderBottomWidth: 12,
      borderRightWidth: 12
    },
    innerTriangle: {
      left: -11,
      borderLeftWidth: 11,
      borderBottomWidth: 11,
      borderRightWidth: 11
    },
    editBtnIcon: { transform: [{ scaleX: 1.1 }, { scaleY: 1.1 }] },
    editBtnTxt: { fontSize: 18, marginLeft: 8 }
  },
  [minSize(DEVICE_SIZES.LG)]: { editButton: { marginTop: DEFAULT_SPACING } }
})

function PieLabel({ label, color }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        display: 'flex',
        marginBottom: DEFAULT_SPACING,
        marginLeft: 10
      }}
    >
      <View
        style={{
          marginRight: 10,
          backgroundColor: color,
          borderRadius: 15 / 2,
          height: 15,
          width: 15
        }}
      />
      <Text
        style={{
          fontSize: 12,
          color: grayblue,
          letterSpacing: 0
        }}
      >
        {label}
      </Text>
    </View>
  )
}

function DataDonut({ data: donutData, selected, onShow }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [bondPlanTypeLetter, setBondPlanTypeLetter] = useState('A')
  const defaultData = [{ y: 50 }, { y: 50 }]
  const [data, setData] = useState(defaultData)

  useEffect(() => {
    setData([{ y: donutData.result.stocks }, { y: donutData.result.bonds }])
    let letter = 'A'
    const { bonds } = donutData.result
    if (bonds < 20) {
      letter = 'A'
    } else if (bonds < 40) {
      letter = 'B'
    } else if (bonds < 50) {
      letter = 'C'
    } else if (bonds < 60) {
      letter = 'D'
    } else if (bonds < 80) {
      letter = 'E'
    } else if (bonds < 100) {
      letter = 'F'
    } else {
      letter = 'G'
    }
    setBondPlanTypeLetter(letter)
  }, [donutData])
  const graphicLabels = ['Stocks', 'Bonds']

  const graphicColor = [primary, 'rgb(118, 88, 227)']

  function triangleLeftCaratPosition() {
    switch (selected) {
      case 'All Stocks':
        return 10
      case 'Stock/Bond Split':
        return 45
      case 'All Bonds':
        return 80
      default:
        return 10
    }
  }

  return (
    <>
      <View
        style={[
          styles.triangleLeft,
          { left: `${triangleLeftCaratPosition()}%` }
        ]}
      >
        <View style={styles.innerTriangle} />
      </View>
      <View style={styles.container}>
        <View style={styles.left}>
          <View
            style={{
              width: isNotWeb ? 112 : 118,
              height: isNotWeb ? 112 : 118
            }}
          >
            <VictoryPie
              data={data}
              width={isNotWeb ? 112 : 118}
              height={isNotWeb ? 112 : 118}
              colorScale={graphicColor}
              radius={isNotWeb ? 56 : 59}
              innerRadius={isNotWeb ? 45 : 48}
              labels={() => null}
            />
          </View>
          <View style={styles.tip}>
            {graphicLabels.map((label, index) => (
              <PieLabel label={label} color={graphicColor[index]} />
            ))}
          </View>
        </View>
        <View style={styles.right}>
          <Text style={styles.header}>{donutData.header}</Text>
          <Text style={styles.description}>
            This{' '}
            <Text style={styles.boldText}>{`${
              differentAllocationsDescriptions[`bond${bondPlanTypeLetter}`]
                .boldSection
            }`}</Text>{' '}
            {`${
              differentAllocationsDescriptions[`bond${bondPlanTypeLetter}`]
                .description
            }`}
          </Text>
          {selected === 'Stock/Bond Split' && (
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 8,
                marginBottom: -8
              }}
            >
              <Link
                style={styles.editButton}
                onPress={() => {
                  hapticFeedback()
                  onShow()
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <FontAwesomeIcon
                    style={styles.editBtnIcon}
                    icon={faPencilAlt}
                  />
                  <Text style={styles.editBtnTxt}>Edit</Text>
                </View>
              </Link>
            </View>
          )}
        </View>
      </View>
    </>
  )
}

export default DataDonut
