import { StyleSheet, View, Text, Picker } from 'react-native'
import { white, red } from '../styles/colors'

import InputWrapper from './InputWrapper'

const styles = StyleSheet.create({
  input: {
    color: 'rgb(66, 86, 103)',
    fontSize: 18,
    marginTop: 2 * 2,
    fontWeight: '500',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderColor: 'transparent'
  }
})

export default function InputSelect(props) {
  const { style, options, value, name, onSubmitEditing, onChangeText } = props

  return (
    <InputWrapper {...props}>
      <Picker
        style={[styles.input, style]}
        selectedValue={value}
        onValueChange={(val) => {
          if (onSubmitEditing) onSubmitEditing(val)
          if (onChangeText) onChangeText({ target: { name, value: val } })
        }}
      >
        {options.map((option, index) => (
          <Picker.Item key={index} label={option.label} value={option.value} />
        ))}
      </Picker>
    </InputWrapper>
  )
}
