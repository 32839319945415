import { StyleSheet, View, ScrollView } from 'react-native'
import moment from 'moment'
import { groupBy, has, each, find } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { currentUserSelector, budgetCategoriesSelector } from '../../selectors'
import HeaderLabel from '../HeaderLabel'
import { DEFAULT_SPACING, orange, rgba, white } from '../../styles'
import Card from '../Card'
import Link from '../Link'
import { deleteTransaction } from '../../actions'
import Accordion from '../Accordion'
import formatCurrency from '../../functions/formatCurrency'
import addColorAndIconToTransactions from '../../functions/addColorAndIconToTransactions'

const styles = StyleSheet.create({
  noProjectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: orange,
    padding: DEFAULT_SPACING
  },
  noProjectionsText: {
    color: white,
    flexWrap: 'wrap'
  }
})

function getTransactionsByTransactionDate(transactions) {
  const groupedTransactionsByDate = {}
  // order transactions newest to oldest
  const orderedTransactionsByDate = transactions.sort((a, b) => moment(b.date).diff(moment(a.date)))
  each(orderedTransactionsByDate, (transaction) => {
    const transactionDate = moment(transaction.date).format('MMMM DD, YYYY')
    if (has(groupedTransactionsByDate, transactionDate)) {
      groupedTransactionsByDate[transactionDate].push(transaction)
    } else {
      groupedTransactionsByDate[transactionDate] = [transaction]
    }
  })
  return groupedTransactionsByDate
}

export default function TransactionsSection({ navigation, transactions, budgetCategory, budgetSubCategory }) {
  const dispatch = useDispatch()
  const currentUser = useSelector(currentUserSelector)
  const budgetCategories = useSelector(budgetCategoriesSelector)

  function handleAddTransactionPress() {
    navigation.navigate('Modal', { screen: 'Transaction Form', params: { budgetCategory, budgetSubCategory } })
  }

  function onEditPress(transaction) {
    const newTransaction = { ...transaction }
    newTransaction.amount = Math.abs(newTransaction.amount)
    navigation.navigate('Modal', { screen: 'Transaction Form', params: { transaction: newTransaction } })
  }

  function onDeletePress(transaction) {
    dispatch(deleteTransaction(currentUser.id, transaction.transactionId))
  }

  const coloredTransactions = addColorAndIconToTransactions(budgetCategories, transactions)
  const groupedByTransactionDate = getTransactionsByTransactionDate(coloredTransactions)

  return (
    <ScrollView>
      <Card style={{
        paddingHorizontal: 0,
        paddingTop: DEFAULT_SPACING,
        marginBottom: DEFAULT_SPACING * 2,
      }}
      >
        <View style={{ paddingHorizontal: DEFAULT_SPACING, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <HeaderLabel style={{ marginBottom: DEFAULT_SPACING }}>
            Recent Transactions
          </HeaderLabel>

          <Link onPress={handleAddTransactionPress} style={{ marginBottom: DEFAULT_SPACING }}>
            Add Transactions
          </Link>
        </View>

        {Object.keys(groupedByTransactionDate).map((key) => {
        
          let total = 0
          groupedByTransactionDate[key].forEach((transaction) => {
            total += transaction.amount
          })

          return (
            <Accordion
              title={key}
              subtitle={formatCurrency(total, { accurate: true, showDollarSign: true})}
              label="Transaction"
              data={groupedByTransactionDate[key]}
              onEdit={onEditPress}
              onDelete={onDeletePress}
            />            
          )
        })}
      </Card>
    </ScrollView>
  )
}

TransactionsSection.propTypes = {}
