import { Platform, Text, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faFilePlus } from '@fortawesome/pro-duotone-svg-icons'

import Card from '../Card'
import hapticFeedback from '../hapticFeedback'
import { DEFAULT_SPACING, info } from '../../styles'

const basicStyles = {
  container: {
    borderColor: info,
    borderWidth: 1,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    width: '49%',
    marginVertical: 10,
    paddingHorizontal: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING,
    borderRadius: 12,
  },
  text: {
    color: info,
    marginTop: DEFAULT_SPACING,
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        width: 'calc(33.33% - 12px)',
        marginRight: DEFAULT_SPACING,
      }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function CareerCardPlaceholderButton({ onPress }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  function handlePress() {
    hapticFeedback()
    if (onPress) onPress()
  }

  return (
    <Card style={styles.container} onPress={handlePress}>
      <FontAwesomeIcon icon={faFilePlus} size={70} color={info} />
      <Text style={styles.text}>Create New Plan</Text>
    </Card>
  )
}
