// React
import { useLayoutEffect } from 'react'
import { Platform, ScrollView, StyleSheet, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

// 3Rd party
import { map } from 'lodash'
import { DEFAULT_SPACING, lightestgray } from '../../styles'
import PageHeader from '../PageHeader'
import ToolsListItem from './ToolsListItem'
import ToolsHeaderWrapper from './ToolsHeaderWrapper'

const basicStyles = {
  container: {
    backgroundColor: lightestgray,
    position: 'relative',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  main: {
    paddingTop: DEFAULT_SPACING,
    paddingHorizontal: DEFAULT_SPACING
  },
  sectionsGroup: { paddingHorizontal: DEFAULT_SPACING },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: { padding: 40, },
      main: {
        paddingTop: 32,
        paddingHorizontal: 0,
      },
      sectionsGroup: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        paddingHorizontal: 0,
      },
    }
  }
)

function MoreTools({ navigation, sections, title }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      title,
    })
  }, [navigation])

  return (
    <View style={styles.container}>
      {!isNotWeb && (<ToolsHeaderWrapper><PageHeader title={title} /></ToolsHeaderWrapper>)}
      <ScrollView style={styles.main}>
        <View style={styles.sectionsGroup}>
          {map(sections, (section) => <ToolsListItem {...section} action={section.title} navigation={navigation} key={section.title} navigatable />)}
        </View>
        {/* This is here to ensure that the bottom of the list isn't cutoff */}
        {isNotWeb && (<View style={{ height: 400 }} />)}
      </ScrollView>
    </View>
  );
}

export default MoreTools
