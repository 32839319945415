import * as Yup from 'yup'
import { REQUIRED } from '../constants'

export const Fields = {
  key: 'key',
  name: 'name',
  year: 'year',
  currentValue: 'current_value',
  periods: 'periods'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.name]: Yup.string().required(REQUIRED),
  [Fields.year]: Yup.number().nullable()
    .test('year', "Year of purchase cannot be greater than your plan's investment horizon", function (value) {
      const { periods } = this.parent
      if (!value) return true;
      return value < periods
    })
    .required(REQUIRED),
  [Fields.key]: Yup.string().required(REQUIRED),
  [Fields.currentValue]: Yup.number().required(REQUIRED).nullable(),
})

export const InitialValues = {
  [Fields.name]: '',
  [Fields.key]: 'PET',
  [Fields.year]: '',
  [Fields.currentValue]: '',
  [Fields.periods]: 0
}
