import { useLayoutEffect, useState } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { map } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { post, put } from 'http'

import { onboardingWellnessChecklistsSelector, } from './selectors'
import { saveWellnessChecklistItem } from './actions'

import Container from './components/Container'
import CheckboxList from './components/CheckboxList'
import BottomButton from './components/BottomButton'
import Header from './components/Header'
import InformationBlock from './components/InformationBlock'
import handleError from './functions/handleError'
import BackButton from './components/teamOnboarding/BackButton'
import Title from './components/teamOnboarding/Title'
import Button from './components/Button'
import { useNavigation } from '@react-navigation/native'
import AsyncStorage from '@react-native-async-storage/async-storage'

const basicStyles = {
  container: { alignItems: 'center', marginTop: 160, },
  headerTextStyle: { textAlign: 'center', },
  checkboxListContainer: {
    paddingHorizontal: 20,
    width: '100%'
  },
  buttonWrapper: { width: '100%', },
  button: { marginHorizontal: 20, },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      headerTextStyle: { fontSize: 18, lineHeight: 21, fontWeight: '500' },
      checkboxListContainer: { maxHeight: 412, paddingHorizontal: 48 },
      buttonWrapper: {
        position: 'absolute',
        alignItems: 'center',
        bottom: 54,
      },
      button: {
        width: 345,
        marginHorizontal: 'auto',
      },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function JoinOrganizationWellnessChecklistScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const wellnessChecklists = useSelector((state) => onboardingWellnessChecklistsSelector(state))
  const completedWellnessChecklist = wellnessChecklists.filter((wellnessChecklist) => wellnessChecklist.is_complete)
  // collect id of completed wellness checklist array
  const completedWellnessChecklistSelected = map(completedWellnessChecklist, 'wizard_description')
  const [selected, setSelected] = useState(completedWellnessChecklistSelected)
  const { invitation } = route.params
  const [isSubmitting, setIsSubmitting] = useState(false)

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: null,
      headerTransparent: true,
      headerLeft: () => (<BackButton onPress={() => navigation.pop()} />)
    })
  }, [navigation])

  async function handlePress() {
    try {
      setIsSubmitting(true)
      const key = invitation.invitation_key || invitation.key
      const { passcode } = invitation

      if (passcode) {
        await post('api/v1/premium/organization/join/', { join: true, passcode })
      } else {
        await put(`api/v1/premium/invitation/connect/${key}`)
        // const invitationData = invitationResponse.data || {}
        // const { organization } = invitationData || {}
        // const { survey } = organization || {}
        // const id = 1
      }

      const id = 1

      const surveyBody = {
        questions: {
          interests: invitation.interests,
          is_graduated_student: invitation.is_graduated_student
        }
      }

      await post(`api/v1/educational/survey/${id}/`, surveyBody)
      setIsSubmitting(false)

      if (isNotWeb) navigation.navigate('Modal', { screen: 'Join Team Success', params: { invitation } })
      else {
        await AsyncStorage.setItem('invitation', JSON.stringify(invitation))
        setTimeout(() => {
          navigation.reset({
            key: null,
            index: 0,
            routes: [{ name: 'Main' }],
          })
        }, 500);
      }
    } catch (error) {
      setIsSubmitting(false)
      handleError(error, 'Error')
    }
  }

  function handleSelect(value) {
    const newSelected = selected.includes(value)
      ? selected.filter((item) => item !== value) : [...selected, value]

    setSelected(newSelected)

    // find the wellness checklist item that matches the value
    const wellnessChecklistItem = wellnessChecklists.find((wellnessChecklist) => wellnessChecklist.wizard_description === value)

    dispatch(saveWellnessChecklistItem(wellnessChecklistItem.financial_wellness_item_id, !wellnessChecklistItem.is_complete))
  }

  const options = wellnessChecklists.map((item) => ({
    leftText: item.wizard_description,
    value: item.wizard_description
  }))

  return (
    <Container card={true} style={[styles.container, { marginTop: isNotWeb ? 160 : 'auto' }]}>
      <Title />
      <Header
        textStyle={styles.headerTextStyle}
        text={'Get a head start on your Wellness Checklist.\nCheck all that apply.'}
      />

      <View style={styles.checkboxListContainer}>
        <CheckboxList
          options={options}
          value={selected}
          onChange={handleSelect}
          size={26}
          variant='circle'
        />

        <InformationBlock
          header="Our Wellness Checklist"
          text="View the full Wellness Checklist on the My Dashboard Tab."
        />
      </View>

      <View style={styles.buttonWrapper}>
        <Button
          style={styles.button}
          isSubmitting={isSubmitting}
          onPress={handlePress}
        >
          Continue
        </Button>
      </View>
    </Container>
  )
}
