import { get } from 'http'
import { useState, useLayoutEffect, useEffect, Fragment } from 'react'
import { StyleSheet, View, Text, Platform, Switch } from 'react-native'
import { useNavigationState } from '@react-navigation/native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { findIndex } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import HeaderLabel from './components/HeaderLabel'
import Card from './components/Card'
import MultiSlider from './components/MultiSlider'
import InputSelect from './components/InputSelect'
import TouchableOpacity from './components/TouchableOpacity'

import formatCurrency from './functions/formatCurrency'

import { grayblue, gunmetal, primary, white } from './styles/colors'

import CareerAdditionalInformation from './components/careers/CareerAdditionalInformation'

import Form from './components/Form'
import CurrencyInput from './components/CurrencyInput'
import InformationBlock from './components/InformationBlock'

import timeout from './functions/timeout'

import BuilderWrapper from './components/plans/BuilderWrapper'

import { currentPlanSelector, currentUserSelector, fastPlanModeDataSelector } from './selectors'

import { ValidationSchema, Fields, InitialValues } from './schemas/incomeSchema'

import { saveCurrentPlan, saveFastPlan, setLoading } from './actions'
import { DEFAULT_SPACING } from './styles'
import FontAwesomeSpin from './components/FontAwesomeSpin'
import handleError from './functions/handleError'
import LoadingIcon from './components/planBuilder/LoadingIcon'

const basicStyles = {
  container: {
    backgroundColor: 'rgb(236, 241, 244)',
    flex: 1,
    paddingHorizontal: DEFAULT_SPACING
  },
  headerContainer: {
    backgroundColor: 'rgb(236, 241, 244)',
    paddingHorizontal: DEFAULT_SPACING
  },
  cardContainer: {},
  descriptionContainer: {
    padding: DEFAULT_SPACING,
    backgroundColor: white
  },
  scrollviewContentContainer: { paddingVertical: DEFAULT_SPACING },
  card: {
    padding: DEFAULT_SPACING,
    borderRadius: 12,
    flex: 1,
    marginBottom: 12
  },
  cardParallel: {
    width: '48%',
    flex: 0
  },
  rowParallel: { justifyContent: 'space-between' },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  cardRow: {
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  cardIcon: { marginRight: 12 },
  image: {
    marginVertical: DEFAULT_SPACING,
    width: '100%'
  },
  label: {
    fontSize: 14,
    color: 'rgb(109, 122, 125)',
    marginBottom: 6
  },
  value: {
    fontSize: 24,
    fontWeight: '600',
    color: grayblue
  },
  headerLabel: {
    fontSize: 18,
    color: 'rgb(51, 63, 75)',
    marginBottom: DEFAULT_SPACING
  },
  locationLabel: {
    fontSize: 16,
    color: gunmetal
  },
  buttonContainer: {
    position: 'relative',
    bottom: 0,
    marginBottom: 0,
    backgroundColor: 'rgb(236, 241, 244)',
    height: 70
  },
  listHeader: { paddingVertical: DEFAULT_SPACING }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    headerContainer: { marginBottom: 0 },
    container: { paddingRight: 40 },
    listHeader: {
      fontSize: 36,
      fontWeight: '600',
      paddingVertical: 0
    },
    buttonContainer: { marginHorizontal: 0 },
    locationLabel: { marginVertical: DEFAULT_SPACING }
  }
})

const experienceLevelOptions = ['BOTTOM_10', 'BOTTOM_25', 'MEDIAN', 'TOP_25', 'TOP_10']

const experienceLevelHash = {
  BOTTOM_10: 'Bottom 10%',
  BOTTOM_25: 'Bottom 25%',
  MEDIAN: 'Median',
  TOP_25: 'Top 25%',
  TOP_10: 'Top 10%'
}

const isNotWeb = Platform.OS !== 'web'

function CardRow({ label, value, Icon }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <View style={[styles.row, styles.cardRow]}>
        {Icon && <Icon style={styles.cardIcon} fill={grayblue} height={20} width={20} />}
        <Text style={styles.value}>{value}</Text>
      </View>
    </View>
  )
}

export function Career({ occupation, title, builder, buttonText, navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const form = useSelector((state) => currentPlanSelector(state))
  const { city } = form
  const navigationState = useNavigationState((state) => state)
  const dispatch = useDispatch()
  const [detail, setDetail] = useState({})
  const { id, name, national } = occupation
  const [experienceLevel, setExperienceLevel] = useState(form.experience_level || 'MEDIAN')
  const [useNational, setUseNational] = useState(false)
  const {
    birthday: birth_date,
    city: fCity,
    fastMode
  } = useSelector((state) => fastPlanModeDataSelector(state))

  const experienceLevelToWage = {
    BOTTOM_10: occupation.annual_pct10,
    BOTTOM_25: occupation.annual_pct25,
    MEDIAN: occupation.annual_median,
    TOP_25: occupation.annual_pct75,
    TOP_10: occupation.annual_pct90
  }

  const optionsForSelect = Object.keys(experienceLevelHash).map((key) => ({
    label: experienceLevelHash[key],
    value: key
  }))

  const fetchDetail = async () => {
    const response = await get(occupation.detail)
    setDetail(response.data)
  }

  useEffect(() => {
    fetchDetail()
  }, [occupation, city])

  function SalaryForOccupation({ experienceLevel, national }) {
    return (
      <View style={[styles.row, { alignItems: 'flex-end' }]}>
        <Text style={styles.value}>
          {experienceLevelToWage[experienceLevel]
            ? `$${formatCurrency(experienceLevelToWage[experienceLevel])}`
            : '-'}
        </Text>
        <Text style={styles.label}>{`(${experienceLevelHash[experienceLevel]})`}</Text>
      </View>
    )
  }

  const handleSubmit = async (values) => {
    const occ =
      ((national && useNational) || !national) &&
      experienceLevelToWage[experienceLevel] &&
      experienceLevelToWage[experienceLevel] > 0

    if (fastMode) {
      try {
        dispatch(
          setLoading({
            loading: true,
            fullScreen: true,
            loadingMessage: 'Building your financial plan...',
            useGradient: true,
            fullScreenOpacity: 1,
            fullScreenIconComponent: <LoadingIcon />
          })
        )

        dispatch(
          saveFastPlan({
            birth_date,
            city_id: fCity.id,
            occupation_id: occ ? id : null,
            step: 3
          })
        )

        setTimeout(() => {
          navigation.reset({
            index: 1,
            routes: [
              {
                name: 'Main',
                state: {
                  index: 0,
                  routes: [{ name: 'Plan Builder' }]
                }
              },
              {
                name: 'Modal',
                state: {
                  routes: [{ name: 'Plan Summary' }]
                }
              }
            ]
          })

          dispatch(
            setLoading({
              loading: false,
              fullScreen: false,
              loadingMessage: null,
              useGradient: null,
              fullScreenOpacity: null,
              fullScreenIconComponent: null
            })
          )
        }, 2000)
      } catch (error) {
        handleError(error, 'Oops. Looks like something went wrong.')

        dispatch(
          setLoading({
            loading: false,
            fullScreen: false,
            loadingMessage: null,
            useGradient: null,
            fullScreenOpacity: null,
            fullScreenIconComponent: null
          })
        )
      }
    } else {
      await timeout(600)

      const index = navigationState.routes.findIndex((route) => route.name === 'Plan Summary')

      if (builder) {
        navigation.navigate('Modal', {
          screen: 'Investment Options',
          params: { title, subtitle: 'Goal', builder }
        })
      } else {
        navigation.pop(navigationState.routes.length - index - 1)
      }

      dispatch(
        saveCurrentPlan({
          occupation: occ ? { id } : null,
          experience_level: occ ? experienceLevel : null,
          custom_career_salary: occ ? null : values.custom_career_salary,
          custom_career_name: occ ? null : name,
          input_custom_career: !occ,
          step: form.step === 1 ? 2 : form.step
        })
      )
    }
  }

  return (
    <>
      <View style={styles.headerContainer}>
        <HeaderLabel style={styles.listHeader}>{occupation.name}</HeaderLabel>
        <Text style={styles.locationLabel}>in {fastMode ? fCity.name : city.name}</Text>
      </View>
      <View style={styles.container}>
        <Form
          containerStyle={{
            backgroundColor: 'transparent',
            marginVertical: isNotWeb ? DEFAULT_SPACING : 0
          }}
          validationSchema={
            !(
              ((national && useNational) || !national) &&
              experienceLevelToWage[experienceLevel] &&
              experienceLevelToWage[experienceLevel] > 0
            )
              ? ValidationSchema
              : null
          }
          initialValues={InitialValues}
          withPadding={false}
          buttonText={buttonText || 'Choose this Career'}
          buttonContainerStyle={styles.buttonContainer}
          onSubmit={async (values) => handleSubmit(values)}
        >
          {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
            <View>
              <View style={styles.cardContainer}>
                <Card style={styles.card}>
                  {(!national || (national && useNational)) &&
                    experienceLevelToWage[experienceLevel] > 0 && (
                      <CardRow
                        label={occupation.national ? 'National Average Salary' : 'Average Salary'}
                        value={
                          <SalaryForOccupation
                            national={national}
                            experienceLevel={experienceLevel}
                          />
                        }
                      />
                    )}

                  {(!national || (national && useNational)) &&
                    experienceLevelToWage[experienceLevel] > 0 && (
                      <View
                        style={{
                          marginTop: DEFAULT_SPACING,
                          paddingHorizontal: 10
                        }}
                      >
                        <Text style={[styles.label, { marginTop: DEFAULT_SPACING }]}>
                          Experience Level
                        </Text>
                        <View>
                          {Platform.OS !== 'web' ? (
                            <MultiSlider
                              min={0}
                              max={experienceLevelOptions.length - 1}
                              values={[
                                findIndex(experienceLevelOptions, (v) => v === experienceLevel)
                              ]}
                              enableLabel={false}
                              onValuesChange={(values) => {
                                setExperienceLevel(experienceLevelOptions[values[0]])
                              }}
                            />
                          ) : (
                            <InputSelect
                              placeholder="--"
                              value={experienceLevel}
                              options={optionsForSelect}
                              onChangeText={(evt) => {
                                setExperienceLevel(evt.target.value)
                              }}
                              onSubmitEditing={(value) => {
                                setExperienceLevel(value)
                              }}
                            />
                          )}
                        </View>
                      </View>
                    )}

                  {national && (
                    <>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 12
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            setUseNational(!useNational)
                          }}
                        >
                          <Text style={{ marginTop: 8 }}>Use National Averages</Text>
                        </TouchableOpacity>
                        <Switch
                          value={useNational}
                          onValueChange={(value) => {
                            setUseNational(!useNational)
                          }}
                        />
                      </View>

                      {!useNational && (
                        <CurrencyInput
                          prefix="$"
                          label="Annual Income"
                          value={values.custom_career_salary}
                          name={Fields.custom_career_salary}
                          touched={touched[Fields.custom_career_salary]}
                          errorMessage={errors[Fields.custom_career_salary]}
                          onChangeText={handleChange(Fields.custom_career_salary)}
                          precision={0}
                          onSubmitEditing={handleSubmit}
                        />
                      )}

                      <InformationBlock
                        text="Hmm - income for the career/location you selected is not in our data set. What would you like to do?"
                        style={styles.description}
                      />
                    </>
                  )}

                  {(experienceLevelToWage[experienceLevel] == 0 ||
                    !experienceLevelToWage[experienceLevel]) && (
                    <View style={{ marginTop: DEFAULT_SPACING }}>
                      <CurrencyInput
                        prefix="$"
                        label="Annual Income"
                        value={values.custom_career_salary}
                        name={Fields.custom_career_salary}
                        touched={touched[Fields.custom_career_salary]}
                        errorMessage={errors[Fields.custom_career_salary]}
                        onChangeText={handleChange(Fields.custom_career_salary)}
                        precision={0}
                        onSubmitEditing={handleSubmit}
                      />

                      <InformationBlock
                        text="Looks like you chose a high-paying career! Our data set does not include incomes above $208,000, but you may enter a custom value instead."
                        style={styles.description}
                      />
                    </View>
                  )}
                </Card>
              </View>
              <View style={styles.cardContainer}>
                <CareerAdditionalInformation detail={detail} occupation={occupation} />
              </View>
            </View>
          )}
        </Form>
      </View>
    </>
  )
}

export default function CareerScreen({ navigation, route }) {
  const { params } = route
  const { groupName, occupation, builder, useGradient, includeProgress } = params

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      title: 'Explore Careers'
    })
  }, [navigation])

  useLayoutEffect(() => {
    if (builder || useGradient) {
      navigation.setOptions({
        headerShown: true,
        headerTransparent: true,
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0
        },
        headerTitleStyle: { color: white },
        headerTintColor: white,
        title: 'Explore Careers'
      })
    } else {
      navigation.setOptions({
        headerShown: true,
        title: 'Explore Careers'
      })
    }
  }, [builder, useGradient])

  const Wrapper = useGradient || builder ? BuilderWrapper : Fragment

  return (
    <Wrapper includeProgress={includeProgress} option="Career">
      <Career {...params} navigation={navigation} />
    </Wrapper>
  )
}
