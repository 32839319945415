import { get } from 'http'
import { setLifeEvents } from './setLifeEvents'
import handleError from '../functions/handleError'

export const getLifeEvents = function (planId) {
  return async (dispatch) => {
    try {
      const results = await get(`api/v1/plan/${planId}/events/`)

      dispatch(setLifeEvents(results.data, planId))
    } catch (e) {
      handleError(e, 'Oops. Looks like something went wrong.')
    }
  }
}
