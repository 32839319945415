import { useLayoutEffect, useRef, useState } from 'react'
import {
  Platform,
  View,
  Text,
  StyleSheet,
  useWindowDimensions
} from 'react-native'

import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Form from './components/Form'
import TouchableOpacity from './components/TouchableOpacity'

import {
  black,
  info,
  lightgray,
  primary,
  secondary,
  white
} from './styles/colors'
import {
  ValidationSchema,
  Fields,
  InitialValues
} from './schemas/netWorthCalculatorSchema'

import { DEFAULT_SPACING } from './styles'

import TabbedView from './components/TabbedView'
import CurrencyInput from './components/CurrencyInput'
import formatCurrency from './functions/formatCurrency'
import HeaderLabel from './components/HeaderLabel'

import { netWorthCalculator } from './actions'

import handleError from './functions/handleError'
import PageHeader from './components/PageHeader'
import ToolsHeaderWrapper from './components/tools/ToolsHeaderWrapper'

const basicStyles = {
  container: {},
  main: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  form: {},
  resultsContainer: {
    padding: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING * 5,
    backgroundColor: primary,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerText: {
    textAlign: 'center',
    fontSize: 14,
    color: white
  },
  moneyContainer: {
    backgroundColor: info,
    borderRadius: 12,
    paddingHorizontal: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING
  },
  moneyText: {
    textAlign: 'center',
    fontSize: 42,
    color: white,
    fontWeight: 'bold'
  },
  link: {
    textDecorationLine: 'underline',
    paddingTop: 0
  },
  modal: {
    padding: 0,
    paddingVertical: DEFAULT_SPACING * 3
  },
  modalImage: {
    height: 200,
    width: 300
  },
  modalHeaderText: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: '700',
    marginVertical: DEFAULT_SPACING
  },
  modalSubheader: {
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING * 2
  },
  modalBodyText: { color: secondary },
  bold: {
    color: black,
    fontWeight: 'bold',
    marginLeft: DEFAULT_SPACING / 2
  },
  header: {
    fontSize: 18,
    marginBottom: DEFAULT_SPACING
  },
  text: {
    fontSize: 14,
    color: secondary,
    marginBottom: DEFAULT_SPACING * 2
  },
  toggleButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: white,
    color: lightgray,
    zIndex: 100
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    container: {
      height: '100%',
      padding: 40,
      overflow: 'auto'
    },
    main: {
      height: 'calc(100% - 187px)',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      padding: 40,
      backgroundColor: 'white',
      borderRadius: 12,
      marginTop: 26
    },
    form: {
      marginRight: '17%',
      overflow: 'auto'
    },
    resultsContainer: {
      width: '50%',
      height: '100%',
      justifyContent: 'center',
      borderRadius: 12
    },
    headerText: {
      fontSize: 18,
      fontWeight: '600'
    },
    moneyText: {
      fontSize: 60,
      fontWeight: '700'
    },
    modal: { width: 'fit-content' }
  }
})

const isNotWeb = Platform.OS !== 'web'

function FormHeader({ header, description }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [visible, setVisible] = useState(true)

  return (
    <View>
      <TouchableOpacity
        style={styles.toggleButton}
        onPress={() => setVisible(!visible)}
      >
        <FontAwesomeIcon
          color={lightgray}
          icon={visible ? 'circle-minus' : 'circle-plus'}
        />
      </TouchableOpacity>

      <HeaderLabel style={styles.header}>{header}</HeaderLabel>
      {visible && <Text style={styles.text}>{description}</Text>}
    </View>
  )
}

function AssetsForm({
  handleChange,
  handleBlur,
  handleSubmit,
  values,
  errors,
  touched
}) {
  const savingsField = useRef(null)
  const realEstateField = useRef(null)
  const retirementField = useRef(null)
  const autoField = useRef(null)
  const otherField = useRef(null)

  return (
    <View style={{ padding: DEFAULT_SPACING }}>
      <FormHeader
        header="What are Assets?"
        description="Assets include cash (such as in your checking or savings account), investments (including retirement funds), and items such as vehicles or personal property you can sell for cash."
      />

      <CurrencyInput
        type="money"
        unit="$"
        name={Fields.checking}
        errorMessage={errors[Fields.checking]}
        touched={touched[Fields.checking]}
        onChangeText={handleChange(Fields.checking)}
        onBlur={handleBlur(Fields.checking)}
        value={values[Fields.checking]}
        label="Checking Account(s)"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => savingsField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={savingsField}
        name={Fields.savings}
        errorMessage={errors[Fields.savings]}
        touched={touched[Fields.savings]}
        onChangeText={handleChange(Fields.savings)}
        onBlur={handleBlur(Fields.savings)}
        value={values[Fields.savings]}
        label="Savings Account(s)"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => realEstateField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={realEstateField}
        name={Fields.realEstate}
        errorMessage={errors[Fields.realEstate]}
        touched={touched[Fields.realEstate]}
        onChangeText={handleChange(Fields.realEstate)}
        onBlur={handleBlur(Fields.realEstate)}
        value={values[Fields.realEstate]}
        label="Real Estate"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => retirementField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={retirementField}
        name={Fields.retirement}
        errorMessage={errors[Fields.retirement]}
        touched={touched[Fields.retirement]}
        onChangeText={handleChange(Fields.retirement)}
        onBlur={handleBlur(Fields.retirement)}
        value={values[Fields.retirement]}
        label="Investment Account(s)"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => autoField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={autoField}
        name={Fields.autos}
        errorMessage={errors[Fields.autos]}
        touched={touched[Fields.autos]}
        onChangeText={handleChange(Fields.autos)}
        onBlur={handleBlur(Fields.autos)}
        value={values[Fields.autos]}
        label="Auto(s)"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => otherField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={otherField}
        name={Fields.other}
        errorMessage={errors[Fields.other]}
        touched={touched[Fields.other]}
        onChangeText={handleChange(Fields.other)}
        onBlur={handleBlur(Fields.other)}
        value={values[Fields.other]}
        label="Other"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={handleSubmit}
      />
    </View>
  )
}

function LiabilitiesForm({
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  values,
  errors,
  touched
}) {
  const mortgageField = useRef(null)
  const personalField = useRef(null)
  const studentField = useRef(null)
  const autoLoanField = useRef(null)
  const otherDebtField = useRef(null)

  return (
    <View style={{ padding: DEFAULT_SPACING }}>
      <FormHeader
        header="What are liabilities?"
        description="Any debt that you owe. This category includes credit cards, student loans, auto loans, mortgages, etc."
      />

      <CurrencyInput
        type="money"
        unit="$"
        name={Fields.consumer}
        errorMessage={errors[Fields.consumer]}
        touched={touched[Fields.consumer]}
        onChangeText={handleChange(Fields.consumer)}
        onBlur={handleBlur(Fields.consumer)}
        value={values[Fields.consumer]}
        label="Credit Card Debt"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => personalField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={personalField}
        name={Fields.personal}
        errorMessage={errors[Fields.personal]}
        touched={touched[Fields.personal]}
        onChangeText={handleChange(Fields.personal)}
        onBlur={handleBlur(Fields.personal)}
        value={values[Fields.personal]}
        label="Personal Loan(s)"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => autoLoan.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={autoLoanField}
        name={Fields.autoLoan}
        errorMessage={errors[Fields.autoLoan]}
        touched={touched[Fields.autoLoan]}
        onChangeText={handleChange(Fields.autoLoan)}
        onBlur={handleBlur(Fields.autoLoan)}
        value={values[Fields.autoLoan]}
        label="Auto Loan(s)"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => studentField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={studentField}
        name={Fields.student}
        errorMessage={errors[Fields.student]}
        touched={touched[Fields.student]}
        onChangeText={handleChange(Fields.student)}
        onBlur={handleBlur(Fields.student)}
        value={values[Fields.student]}
        label="Student Loan(s)"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => mortgageField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={mortgageField}
        name={Fields.mortgage}
        errorMessage={errors[Fields.mortgage]}
        touched={touched[Fields.mortgage]}
        onChangeText={handleChange(Fields.mortgage)}
        onBlur={handleBlur(Fields.mortgage)}
        value={values[Fields.mortgage]}
        label="Mortgage(s)"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={() => otherDebtField.current.focus()}
      />

      <CurrencyInput
        type="money"
        unit="$"
        ref={otherDebtField}
        name={Fields.otherDebt}
        errorMessage={errors[Fields.otherDebt]}
        touched={touched[Fields.otherDebt]}
        onChangeText={handleChange(Fields.otherDebt)}
        onBlur={handleBlur(Fields.otherDebt)}
        value={values[Fields.otherDebt]}
        label="Other Debt"
        secureTextEntry={false}
        returnKeyType="next"
        placeholder="$10,000"
        keyboardType="number-pad"
        autoComplete="off"
        caretHidden
        enablesReturnKeyAutomatically
        onSubmitEditing={handleSubmit}
      />
    </View>
  )
}

function NetWorthCalculatorHeader({ total }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View style={[styles.resultsContainer]}>
      <Text style={styles.headerText}>Net Worth</Text>
      <View style={styles.moneyContainer}>
        <Text style={styles.moneyText}>${formatCurrency(total)}</Text>
      </View>
    </View>
  )
}

export default function NetWorthCalculatorScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const defaultValue = { total: 0 }
  const [calculation, setCalculation] = useState(defaultValue)
  const [option, setOption] = useState('Assets')

  const window = useWindowDimensions()
  const total = calculation?.total

  const options = {
    Assets: AssetsForm,
    Liabilities: LiabilitiesForm
  }

  const title = 'Net Worth Calculator'

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: isNotWeb })
  }, [navigation])

  return (
    <View style={styles.container}>
      {!isNotWeb && (
        <ToolsHeaderWrapper>
          <PageHeader
            title={title}
            subTitle="Input your assets and liabilities to calculate your net worth."
          />
        </ToolsHeaderWrapper>
      )}
      <View style={styles.main}>
        <NetWorthCalculatorHeader total={total} />
        <Form
          withPadding={false}
          buttonText="Calculate"
          enableReinitialize
          validationSchema={ValidationSchema}
          initialValues={InitialValues}
          containerStyle={styles.form}
          onSubmit={async (values) => {
            try {
              const response = await netWorthCalculator(values)
              setCalculation(response)
            } catch (e) {
              setCalculation(defaultValue)
              handleError(e, 'Unable to calculate net worth')
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values,
            errors,
            touched
          }) => (
            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <TabbedView
                  options={options}
                  option={option}
                  setOption={setOption}
                  totalWidth={
                    isNotWeb ? window.width : window.width * 0.33 - 70
                  }
                  containerStyle={{ backgroundColor: white }}
                  params={{
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    values,
                    errors,
                    touched
                  }}
                />
              </View>
            </View>
          )}
        </Form>
      </View>
    </View>
  )
}
