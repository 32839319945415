import { useRef } from 'react'
import { View, StyleSheet, Linking, useWindowDimensions } from 'react-native'

import qs from 'qs'
import { useSelector } from 'react-redux'
import Input from './components/Input'
import Container from './components/Container'
import CircleImage from './components/CircleImage'
import HeaderLabel from './components/HeaderLabel'
import Form from './components/Form'

import { currentUserSelector } from './selectors'
import { darkblue, lightestgray, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

import {
  ValidationSchema,
  Fields
} from './schemas/askTheAuthorSchema'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: lightestgray
  },
  form: { backgroundColor: lightestgray },
  formContainer: { marginTop: DEFAULT_SPACING * 3 },
  image: {
    backgroundColor: white,
    marginTop: DEFAULT_SPACING * 3
  },
  header: {
    color: darkblue,
    fontSize: 22,
    fontWeight: '600',
    marginTop: DEFAULT_SPACING * 2,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  input: { backgroundColor: white }
})

function sendEmail(to, subject, body, options = {}) {
  const { cc, bcc } = options
  let url = `mailto:${to}`

  const query = qs.stringify({
    subject,
    body,
    cc,
    bcc
  })

  if (query.length) {
    url += `?${query}`
  }

  const canOpen = Linking.canOpenURL(url)

  if (!canOpen) {
    throw new Error('Provided URL can not be handled')
  }

  return Linking.openURL(url)
}

export default function AskTheAuthorScreen({ navigation, route }) {
  const currentUser = useSelector((state) => currentUserSelector(state))
  const { width } = useWindowDimensions()

  const messageField = useRef()

  return (
    <Container style={styles.container}>
      <Form
        containerStyle={styles.form}
        buttonText="Submit"
        validationSchema={ValidationSchema}
        initialValues={{ email: currentUser && !currentUser.is_fake ? currentUser.email : '' }}
        onSubmit={async (values, actions) => {
          sendEmail(
            'support@troutwood.com',
            'Troutwood App - Ask the Author' + '',
            `Email From - ${values.email}\n${values.message}`,
            { cc: '' }
          )
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <View style={styles.center}>
              <CircleImage style={styles.image} image={require('./assets/gene-removebg-preview.png')} size={width / 2} />
              <HeaderLabel style={styles.header}>
                Reach out to Gene!
              </HeaderLabel>
            </View>

            <View style={styles.formContainer}>
              <Input
                name={Fields.email}
                style={styles.input}
                errorMessage={errors[Fields.email]}
                touched={touched[Fields.email]}
                onChangeText={handleChange(Fields.email)}
                onBlur={handleBlur(Fields.email)}
                value={values[Fields.email]}
                label="Your Email"
                placeholder="example@example.com"
                returnKeyType="next"
                onSubmitEditing={() => messageField.current.focus()}
              />

              <Input
                ref={messageField}
                style={styles.input}
                name={Fields.message}
                errorMessage={errors[Fields.message]}
                touched={touched[Fields.message]}
                onChangeText={handleChange(Fields.message)}
                onBlur={handleBlur(Fields.message)}
                value={values[Fields.message]}
                label="Your Message"
                placeholder="Enter your Message to Gene!"
                returnKeyType="done"
                multiline
                onSubmitEditing={handleSubmit}
              />
            </View>
          </>
        )}
      </Form>
    </Container>
  )
}
