import { useState, useRef } from 'react'
import { Platform, View, ScrollView, StyleSheet, Animated } from 'react-native'
import { indexOf } from 'lodash'
import TopTabItem from './TopTabItem'
import hapticFeedback from '../hapticFeedback'
import { primary, white } from '../../styles/colors'
import TouchableOpacity from '../TouchableOpacity'

const styles = StyleSheet.create(
  {
    tabContainer: {
      height: 30,
      flexDirection: 'row',
      shadowOffset: {
        width: 0,
        height: -1,
      },
      shadowOpacity: 0.1,
      shadowRadius: 4.0,
      backgroundColor: white,
      elevation: 10,
      position: 'absolute',
      top: 0,
      zIndex: 100,
    },
    slider: {
      height: 2,
      position: 'absolute',
      bottom: 0,
      left: 10,
      backgroundColor: primary,
    },
    tabButton: { flex: 1, },
    row: { flexDirection: 'row', },
  }
)

export default function TabBar({
  totalWidth,
  option,
  options,
  setOption,
  topTabBarStyle = {},
  topTabBarTextStyle = {},
  scrollable = true,
}) {
  const [translateValue] = useState(new Animated.Value(0))
  const tabWidth = totalWidth / options.length

  const animateSlider = (index) => {
    Animated.spring(translateValue, {
      toValue: index * tabWidth,
      velocity: 1,
      useNativeDriver: true,
    }).start()
  }

  const scrollView = useRef()

  return (
    <ScrollView
      ref={scrollView}
      scrollEnabled={scrollable}
      horizontal
      showsHorizontalScrollIndicator
      style={[styles.tabContainer, topTabBarStyle, Platform.OS !== 'web' ? {} : {
        width: '100%',
        height: 80,
      }]}
    >
      <View style={styles.row}>
        <Animated.View
          style={[
            styles.slider,
            {
              transform: [{ translateX: translateValue }],
              width: tabWidth - 20,
            },
          ]}
        />

        {options.map((opt, index) => {
          const label = opt
          const isFocused = indexOf(options, option) === index

          const onPress = () => {
            if (setOption) setOption(opt)

            scrollView.current.scrollTo({
              x: index * tabWidth,
              y: 0,
              animated: true,
            })

            animateSlider(index)
            hapticFeedback()
          }

          return (
            <TouchableOpacity
              onPress={onPress}
              style={[styles.tabButton]}
              key={index}
            >
              <TopTabItem
                topTabBarStyle={topTabBarStyle}
                topTabBarTextStyle={topTabBarTextStyle}
                tabWidth={tabWidth}
                isFocused={isFocused}
                label={label}
              />
            </TouchableOpacity>
          )
        })}
      </View>
    </ScrollView>
  )
}
