import { StyleSheet, Platform } from 'react-native'

import Form from '../Form'
import CurrencyInput from '../CurrencyInput'
import NumberInput from '../NumberInput'
import Input from '../Input'

import Card from './Card'
import HeaderLabel from '../HeaderLabel'

import {
  InitialValues,
  Fields,
  ValidationSchema
} from '../../schemas/opportunityCost/houseSchema'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  form: { backgroundColor: 'transparent' },
  headerLabel: {
    marginBottom: DEFAULT_SPACING,
    fontSize: 16
  }
})

function AssetForm({ handleChange, handleBlur, values, errors, touched, name }) {
  return (
    <>
      <Input
        label="Name"
        name={Fields[`${name}Name`]}
        touched={touched[Fields[`${name}Name`]]}
        errorMessage={errors[Fields[`${name}Name`]]}
        onChangeText={handleChange(Fields[`${name}Name`])}
        onBlur={handleBlur(Fields[`${name}Name`])}
        value={values[Fields[`${name}Name`]]}
      />

      <CurrencyInput
        label="Home Value*"
        name={Fields[`${name}AssetPrice`]}
        touched={touched[Fields[`${name}AssetPrice`]]}
        errorMessage={errors[Fields[`${name}AssetPrice`]]}
        onChangeText={handleChange(Fields[`${name}AssetPrice`])}
        onBlur={handleBlur(Fields[`${name}AssetPrice`])}
        value={values[Fields[`${name}AssetPrice`]]}
      />

      <CurrencyInput
        label="Down Payment*"
        name={Fields[`${name}DownPayment`]}
        touched={touched[Fields[`${name}DownPayment`]]}
        errorMessage={errors[Fields[`${name}DownPayment`]]}
        onChangeText={handleChange(Fields[`${name}DownPayment`])}
        onBlur={handleBlur(Fields[`${name}DownPayment`])}
        value={values[Fields[`${name}DownPayment`]]}
      />

      <NumberInput
        label="Interest Rate (Percent)*"
        suffixUnit="%"
        precision={3}
        name={Fields[`${name}InterestRate`]}
        touched={touched[Fields[`${name}InterestRate`]]}
        errorMessage={errors[Fields[`${name}InterestRate`]]}
        onChangeText={handleChange(Fields[`${name}InterestRate`])}
        onBlur={handleBlur(Fields[`${name}InterestRate`])}
        value={values[Fields[`${name}InterestRate`]]}
      />

      <NumberInput
        label="Term of Financing (Years)*"
        name={Fields[`${name}Years`]}
        touched={touched[Fields[`${name}Years`]]}
        errorMessage={errors[Fields[`${name}Years`]]}
        onChangeText={handleChange(Fields[`${name}Years`])}
        onBlur={handleBlur(Fields[`${name}Years`])}
        value={values[Fields[`${name}Years`]]}
      />

      <NumberInput
        label="Rate of Appreciation (%)"
        suffixUnit="%"
        precision={3}
        name={Fields[`${name}AppreciationRate`]}
        touched={touched[Fields[`${name}AppreciationRate`]]}
        errorMessage={errors[Fields[`${name}AppreciationRate`]]}
        onChangeText={handleChange(Fields[`${name}AppreciationRate`])}
        onBlur={handleBlur(Fields[`${name}AppreciationRate`])}
        value={values[Fields[`${name}AppreciationRate`]]}
      />

      <NumberInput
        label="Property Tax Rate (%)"
        suffixUnit="%"
        precision={3}
        name={Fields[`${name}PropertyTaxRate`]}
        touched={touched[Fields[`${name}PropertyTaxRate`]]}
        errorMessage={errors[Fields[`${name}PropertyTaxRate`]]}
        onChangeText={handleChange(Fields[`${name}PropertyTaxRate`])}
        onBlur={handleBlur(Fields[`${name}PropertyTaxRate`])}
        value={values[Fields[`${name}PropertyTaxRate`]]}
      />

      <NumberInput
        label="Insurance Rate (%)"
        suffixUnit="%"
        name={Fields[`${name}InsuranceRate`]}
        touched={touched[Fields[`${name}InsuranceRate`]]}
        errorMessage={errors[Fields[`${name}InsuranceRate`]]}
        onChangeText={handleChange(Fields[`${name}InsuranceRate`])}
        onBlur={handleBlur(Fields[`${name}InsuranceRate`])}
        value={values[Fields[`${name}InsuranceRate`]]}
      />

      <NumberInput
        label="HOA Fees (Per Month)"
        name={Fields[`${name}HOACost`]}
        touched={touched[Fields[`${name}HOACost`]]}
        errorMessage={errors[Fields[`${name}HOACost`]]}
        onChangeText={handleChange(Fields[`${name}HOACost`])}
        onBlur={handleBlur(Fields[`${name}HOACost`])}
        value={values[Fields[`${name}HOACost`]]}
      />
    </>
  )
}

export default function HouseForm({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'

  return (
    <Form
      containerStyle={styles.form}
      validationSchema={ValidationSchema}
      initialValues={InitialValues}
      onSubmit={async (values) => {
        const newValues = { ...values }
        navigation.navigate(
          'Modal',
          {
            screen: isNotWeb ? 'Opportunity Cost Result' : 'Opportunity Cost',
            params: { ...newValues, type: 'home' }
          }
        )
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
        <>
          <Card>
            <HeaderLabel style={styles.headerLabel}>Total cash available for down payment:</HeaderLabel>
            <CurrencyInput
              label="Amount*"
              name={Fields.totalDollarsAvailable}
              touched={touched[Fields.totalDollarsAvailable]}
              errorMessage={errors[Fields.totalDollarsAvailable]}
              onChangeText={handleChange(Fields.totalDollarsAvailable)}
              onBlur={handleBlur(Fields.totalDollarsAvailable)}
              value={values[Fields.totalDollarsAvailable]}
            />
          </Card>

          <Card header="Home A" defaultVisibility>
            <AssetForm
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              name="assetA"
            />
          </Card>

          <Card header="Home B">
            <AssetForm
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              name="assetB"
            />
          </Card>
        </>
      )}
    </Form>
  )
}
