import { Image, Platform, StyleSheet, Text, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import Card from '../Card'
import TextOrImage from '../TextOrImage'
import Button from '../Button'

import { darkblue, DEFAULT_SPACING, gray } from '../../styles'

const basicStyles = {
  wrapper: {
    alignItems: 'center',
    marginHorizontal: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING * 2,
    paddingHorizontal: DEFAULT_SPACING
  },
  container: {},
  title: {
    fontSize: 18,
    fontWeight: '800',
    marginVertical: DEFAULT_SPACING,
    textAlign: 'center',
    color: darkblue,
    alignSelf: 'center'
  },
  subtitle: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: '500',
    color: gray,
    letterSpacing: -0.583,
    marginBottom: DEFAULT_SPACING
  },
  image: {
    maxWidth: '100%',
    height: 200
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
    wrapper: {
      width: 306,
      maxWidth: 306,
      marginHorizontal: 4,
      marginVertical: 0,
      paddingHorizontal: 14,
      paddingVertical: 14
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 20,
      paddingHorizontal: 24,
      fontWeight: '600',
      marginVertical: 0,
      minHeight: 36
    },
    subtitle: {
      fontSize: 16,
      marginTop: 6,
      marginBottom: 0,
      paddingHorizontal: 36
    },
    container: {
      width: '100%',
      paddingBottom: 4
    },
    image: { height: 191 }
  }
})

export default function FeatureCard({
  title,
  subtitle,
  image,
  buttonText,
  onPress
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  const description = (
    <>
      <TextOrImage title={title} style={styles.title} />
      <Text style={styles.subtitle}>{subtitle}</Text>
    </>
  )

  return (
    <Card style={styles.wrapper}>
      <View style={styles.container}>
        <Image source={image} resizeMode="contain" style={styles.image} />
        {isNotWeb ? (
          description
        ) : (
          <View
            style={{
              height: 118,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {description}
          </View>
        )}

        <Button onPress={onPress}>{buttonText}</Button>
      </View>
    </Card>
  )
}
