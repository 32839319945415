import { useState, useEffect } from 'react'
import { Image } from 'react-native'

export default function useCheckImage(imageUrl) {
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (!imageUrl) {
      return
    }

    Image.getSize(imageUrl, (width, height) => {
      setIsValid(true)
    })
  }, [imageUrl])

  return isValid
}
