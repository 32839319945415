import { useState, useEffect } from 'react'
import { Platform, View, Text, ScrollView, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { get } from 'http'
import { map, compact } from 'lodash'
import {
  VictoryChart,
  VictoryArea,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel
} from 'victory-native'
import CircularProgress from './components/CircularProgress'

import Container from './components/Container'
import PillLabel from './components/PillLabel'
import TooltippedText from './components/TooltippedText'
import DataRow from './components/DataRow'

import GoalAdequacySlideupDrawer from './components/plans/GoalAdequacySlideupDrawer'

import {
  DEFAULT_SPACING,
  gunmetal,
  rgba,
  info,
  lightblue,
  primary,
  pink,
  orange,
  purple,
  lightestgray,
  warning,
  red,
  lightgray
} from './styles'

import formatCurrency from './functions/formatCurrency'

import handleError from './functions/handleError'

import abbreviateNumber from './functions/abbreviateNumber'

import Legend from './components/Legend'
import PillGroup from './components/PillGroup'
import { ANNUAL, WEEKLY, MONTHLY } from './constants'

const basicStyles = {
  container: {
    paddingHorizontal: DEFAULT_SPACING,
    alignItems: 'center'
  },
  scrollView: {
    alignItems: 'center',
    paddingBottom: 100
  },
  chartContainer: {
    flex: 1,
    padding: 10,
    flexDirection: 'row',
    maxWidth: '100%'
  },
  chartAndLegendContainer: { minHeight: 450 },
  title: {
    fontSize: 28,
    fontWeight: '600',
    textAlign: 'center',
    marginVertical: DEFAULT_SPACING * 2,
    letterSpacing: -0.6
  },
  smallTitle: {
    fontSize: 24,
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: -0.6,
    width: '80%',
    marginTop: DEFAULT_SPACING * 2
  },
  help: {
    fontSize: 16,
    textAlign: 'center',
    letterSpacing: -0.67,
    color: gunmetal,
    marginVertical: DEFAULT_SPACING * 2,
    lineHeight: 24
  },
  smallText: {
    textAlign: 'center',
    fontSize: 8,
    marginTop: 12
  },
  anchorStyle: {
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0.05,
    color: info,
    marginVertical: 12,
    textAlign: 'center'
  },
  tableContainer: { width: '100%', }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      tableContainer: {
        width: '40vw',
        alignSelf: 'center',
        borderWidth: 1,
        borderColor: lightgray,
        borderRadius: 6,
        padding: DEFAULT_SPACING,
        marginBottom: DEFAULT_SPACING,
      }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

function DataTable({ amount, amount_youll_have, extra, percent, setVisible }) {
  const data = [
    {
      label: 'Amount Needed',
      tooltipBody:
        'Annual estimate of the amount you may need in retirement to cover your current living essentials + projected inflation',
      value: `$${formatCurrency(amount)}`
    },
    {
      label: 'Projected Income Replacement',
      tooltipBody:
        'Annual estimated income ($ amount) taken from your total savings/investments, based on your spending policy. This does NOT include social security or pension funds.',
      value: `$${formatCurrency(amount_youll_have)}`
    },
    {
      label: 'Extra',
      tooltipBody:
        'Difference between projected income replacement and the amount needed to cover your essentials',
      value: `$${formatCurrency(extra)}`
    }
  ]

  if (percent) {
    data.push({
      label: 'Spending Policy',
      tooltipBody:
        'The amount (percentage) of your total savings/investments you intend to spend, annually, after retirement',
      value: `${percent}`,
      onPress: () => {
        setVisible(true)
      }
    })
  }

  return map(compact(data), (rowProps, index) => (
    <DataRow key={rowProps.label} {...rowProps} index={index} />
  ))
}

function RetirementSummaryChart({ data }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const colors = [orange, lightblue, purple, primary, pink]
  const keys = [3, 4, 5, 6, 7]

  const options = map(keys, (key, index) => ({
    text: `${key}%`,
    color: colors[index]
  }))

  return (
    <View style={styles.chartAndLegendContainer}>
      <Legend label="Spending Policy" options={options} />

      <View style={styles.chartContainer}>
        <VictoryChart
          theme={VictoryTheme.material}
          animate={{ duration: 600 }}
          style={{ axis: { stroke: 'transparent', strokeWidth: 1 } }}
        >
          {map(keys, (key, index) => (
            <VictoryArea
              key={`area-${index}`}
              name={`area-${index}`}
              interpolation="natural"
              style={{
                data: {
                  fill: colors[index],
                  stroke: colors[index],
                  strokeWidth: 2,
                  fillOpacity: 0.05
                }
              }}
              data={map(data[key], (d, i) => ({ x: i, y: d }))}
              series={index}
            />
          ))}

          <VictoryAxis
            tickFormat={(datum) => {
              if (datum == 0) {
                return 'Retirement'
              }
              return `Yr ${datum}`
            }}
            axisLabelComponent={<VictoryLabel dy={12 * 2} />}
            crossAxis={false}
            style={{
              axis: { strokeWidth: 1, stroke: lightestgray },
              grid: { stroke: 'transparent', strokeWidth: 0 },
              tickLabels: { fontSize: 10, fill: gunmetal, padding: 10 }
            }}
          />

          <VictoryAxis
            dependentAxis
            tickCount={5}
            crossAxis={false}
            tickFormat={(value) => `$${abbreviateNumber(value, 1)}`}
            style={{
              axis: { strokeWidth: 1, stroke: lightestgray },
              grid: {
                stroke: lightestgray,
                strokeWidth: 1,
                strokeDasharray: '3'
              },
              tickLabels: { fontSize: 10, fill: gunmetal, padding: 10 }
            }}
          />
        </VictoryChart>
      </View>

      <Text style={styles.smallText}>* Assumes inflation is 2.5%</Text>
    </View>
  )
}

export default function FreeAdequacyScoreScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { params } = route
  const { result: planDetails, plan, adequacy: adequacy0 } = params

  const [duration, setDuration] = useState(ANNUAL)

  const options = [
    {
      label: 'Weekly',
      inverted: true,
      active: duration === WEEKLY,
      onPress: () => setDuration(WEEKLY)
    },
    {
      label: 'Monthly',
      inverted: true,
      active: duration === MONTHLY,
      onPress: () => setDuration(MONTHLY)
    },
    {
      label: 'Annually',
      inverted: true,
      active: duration === ANNUAL,
      onPress: () => setDuration(ANNUAL)
    }
  ]

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)

  const [result, setResult] = useState({})

  async function fetchAndSetData(plan) {
    try {
      const response = await get(`plan/${plan.id}/adequacy/`)
      setResult(response.data)
    } catch (error) {
      handleError(error, 'Oops. Looks like something went wrong.')
    }
  }

  useEffect(() => {
    fetchAndSetData(plan)
  }, [plan])

  const [percent, setPercent] = useState('5%')
  const [visible, setVisible] = useState(false)
  const plan_adequacy = result.plan_adequacy || {}
  const adequacies = plan_adequacy.plan_adequacy
    ? plan_adequacy.plan_adequacy
    : {}
  const amount_needed = plan_adequacy.amount_needed || {}
  const retirement_summary = result.retirement_summary || {}
  const dynamicPercent = adequacies[percent] || {}
  const projected_spend_amount =
    (planDetails.contribution_selection !== 'PENSION'
      ? dynamicPercent.projected_spend_amount
      : plan_adequacy.plan_adequacy?.essentials?.projected_spend_amount) || 0
  const extra =
    (planDetails.contribution_selection !== 'PENSION'
      ? dynamicPercent.extra
      : plan_adequacy.plan_adequacy?.essentials?.extra) || 0
  const adequacy =
    planDetails.contribution_selection !== 'PENSION'
      ? dynamicPercent.adequacy
        ? dynamicPercent.adequacy * 100
        : 0
      : adequacy0

  let adequacyColor = warning
  let adequacyLabel = 'Fair'

  if (adequacy > 70) {
    adequacyColor = primary
    adequacyLabel = 'Good'
  } else if (adequacy < 30) {
    adequacyColor = red
    adequacyLabel = 'Poor'
  }

  let divisor = 1
  if (duration == MONTHLY) {
    divisor = 12
  } else if (duration == WEEKLY) {
    divisor = 52
  }

  function progressContent(percent) {
    return (
      <Text style={{ fontWeight: '600', fontSize: 36 }}>
        {`${percent.toFixed(0)}%`}
      </Text>
    )
  }

  return (
    <Container style={styles.container}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollView}
      >
        <CircularProgress
          progress={adequacy}
          size={160}
          width={12}
          fillFunction={() => progressContent(adequacy)}
          tintColor={adequacyColor}
        />

        <PillLabel
          width={50}
          style={{
            padding: 2,
            marginLeft: 2,
            marginTop: DEFAULT_SPACING,
            backgroundColor: rgba(adequacyColor, 0.1)
          }}
          textStyle={{
            color: adequacyColor,
            textTransform: 'capitalize',
            fontSize: 12,
            letterSpacing: 0
          }}
          title={adequacyLabel}
        />

        <Text style={styles.title}>What is adequacy?</Text>

        {planDetails.contribution_selection === 'PENSION' ? (
          <Text style={styles.help}>
            Goal adequacy evaluates if your pension will cover the cost of your
            living essentials in retirement. If your adequacy score is low,
            consider supplementary savings vehicles such as a 403b or Roth IRA
            to ensure you'll have enough.
          </Text>
        ) : (
          <Text style={styles.help}>
            If you stick to your plan and hit your goal this is how much of your
            income can be replaced after installing a spending policy. The
            higher your score, the more of your income will be replaced!
          </Text>
        )}

        <TooltippedText
          visible={tooltipIsVisible}
          setVisible={setTooltipIsVisible}
          position="top"
          anchorStyle={styles.anchorStyle}
          anchorText={"What's a Spending Policy?"}
          contentHeader={"What's a Spending Policy?"}
          contentBody="A Spending Policy is used in order to guide your spending of your retirement savings. By implementing a 5% spending policy, you can help your savings last as long as you need them."
        />

        <View style={styles.tableContainer}>
          <DataTable
            amount={amount_needed[duration]}
            amount_youll_have={projected_spend_amount / divisor}
            extra={extra / divisor}
            percent={
              planDetails.contribution_selection !== 'PENSION' ? percent : null
            }
            setPercent={setPercent}
            setVisible={setVisible}
          />
        </View>
        {planDetails.contribution_selection !== 'PENSION' && (
          <>
            <PillGroup options={options} />

            <Text style={styles.smallTitle}>Retirement Summary</Text>

            <Text style={styles.help}>
              See how your plan might hold up in retirement
            </Text>
            <RetirementSummaryChart data={retirement_summary} />
          </>
        )}
      </ScrollView>

      <GoalAdequacySlideupDrawer
        visible={visible}
        setVisible={setVisible}
        percent={percent}
        options={Object.keys(adequacies)}
        setPercent={setPercent}
      />
    </Container>
  )
}
