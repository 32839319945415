import { useEffect } from 'react'
import { View, StyleSheet } from 'react-native'

import { useDispatch } from 'react-redux'
import { isNull } from 'lodash'
import CurrencyInput from '../CurrencyInput'

import Form from '../Form'

import timeout from '../../functions/timeout'

import { saveCurrentPlan } from '../../actions'

import {
  ValidationSchema,
  Fields,
  InitialValues
} from '../../schemas/housingSchema'

const styles = StyleSheet.create({ container: { flex: 1 } })

export default function HousingForm({ route, navigation }) {
  const dispatch = useDispatch()

  const { title, custom_housing_cost } = route.params

  useEffect(() => {
    navigation.setOptions({ title })
  })

  return (
    <View style={styles.container}>
      <Form
        validationSchema={ValidationSchema}
        initialValues={custom_housing_cost > -1 ? { custom_housing_cost } : InitialValues}
        onSubmit={async (values) => {
          await timeout(600)

          navigation.pop()

          dispatch(saveCurrentPlan({
            custom_housing_cost: values.custom_housing_cost > -1 && !isNull(values.custom_housing_cost) ? values.custom_housing_cost : null,
            use_custom_housing_cost: !!(values.custom_housing_cost > -1 && !isNull(values.custom_housing_cost))
          }))
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <CurrencyInput
            label="Monthly Amount"

            name={Fields.custom_housing_cost}
            touched={touched[Fields.custom_housing_cost]}
            errorMessage={errors[Fields.custom_housing_cost]}
            onChangeText={handleChange('custom_housing_cost')}
            onBlur={handleBlur('custom_housing_cost')}
            value={values.custom_housing_cost}
            onSubmitEditing={handleSubmit}
          />
        )}
      </Form>
    </View>
  )
}
