import Lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import { View } from 'react-native';

const LottieView = (props) => {
  const lottieContainerRef = useRef(null);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: lottieContainerRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: props.source
    });

    return () => {
      animation.destroy();
    };
  }, []);

  return <View ref={lottieContainerRef} style={props.style}></View>;
}

export default LottieView
