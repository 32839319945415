import * as Sentry from 'sentry-expo';
import InAppAlertService, { AlertTypes } from './InAppAlertService'

function parseJSONOrReturn(dataOrString) {
  if (typeof dataOrString === 'string') {
    return JSON.parse(dataOrString)
  }
  return dataOrString
}

function extractMessage(e) {
  if (e?.response?.data) {
    const jsonResponse = parseJSONOrReturn(e.response.data)
    let potentialMessage;

    if (jsonResponse.message || jsonResponse.detail) {
      potentialMessage = jsonResponse.message || jsonResponse.detail
    } else if (jsonResponse.non_field_errors) {
      potentialMessage = jsonResponse.non_field_errors.join(', ')
    }

    return potentialMessage
  }
}

export default function handleError(e, header) {
  InAppAlertService.alert(
    AlertTypes.Error,
    header,
    extractMessage(e) || 'Unknown Error',
  )

  if (Sentry && Sentry.captureException) {
    Sentry.captureException(e, {
      extra: {
        request: e.request,
        response: e.response
      }
    })
  }
}
