import { useState } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import moment from 'moment'
import HeaderLabel from '../HeaderLabel'
import {
  darkblue,
  DEFAULT_SPACING,
  gunmetal,
  orange,
  secondary,
  white,
  primary,
  info,
  red
} from '../../styles'
import Card from '../Card'
import formatCurrency from '../../functions/formatCurrency'
import addColorAndIconToTransactions from '../../functions/addColorAndIconToTransactions'
import CircleIcon from '../CircleIcon'
import Button from '../Button'
import Link from '../Link'

const basicStyles = {
  card: {
    padding: DEFAULT_SPACING,
    margin: DEFAULT_SPACING,
    shadowColor: 'transparent',
    borderRadius: 6,
    backgroundColor: white,
    position: 'relative',
  },
  noProjectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: orange,
    padding: DEFAULT_SPACING
  },
  noProjectionsText: {
    color: white,
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: DEFAULT_SPACING
  },
  budgetItemHeader: {
    fontSize: 14,
    fontWeight: '600'
  },
  targetBudget: {
    color: gunmetal,
    fontSize: 10,
    marginBottom: DEFAULT_SPACING * 2,
    marginRight: 30
  },
  thisMonth: {
    color: secondary,
    fontSize: 12
  },
  smallButton: {
    paddingVertical: 8,
    width: 180,
    borderRadius: 8,
    alignSelf: 'center'
  },
  smallButtonText: {
    fontSize: 14
  },
  circleIconStyle: {
    marginRight: DEFAULT_SPACING
  },
  noTransactions: {
    borderRadius: 8,
    marginBottom: DEFAULT_SPACING
  },
  noTransactionsText: {
    fontSize: 14,
    color: gunmetal
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      card: {
        margin: 0,
        top: 0,
        borderRadius: 16,
        width: 'calc(33.33% - 12.67px)',
        maxWidth: 350,
        marginRight: DEFAULT_SPACING,
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function RecentTransactionsCard({
  transactions,
  budgetCategories,
  budgetCategory,
  navigation
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const coloredTransactions = addColorAndIconToTransactions(
    budgetCategories,
    transactions
  )

  const [showAll, setShowAll] = useState(false)
  const limitedTransactions = showAll
    ? coloredTransactions
    : coloredTransactions.slice(0, 2)

  function onAddPress() {
    navigation.navigate('Modal', { screen: 'Transaction Form' })
  }

  function handlePress() {
    navigation.navigate('Modal', {
      screen: 'Transactions',
      params: { budgetCategory }
    })
  }

  return (
    <Card style={styles.card}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <HeaderLabel style={styles.header}>
          {budgetCategory
            ? `${budgetCategory.label} Transactions`
            : `Recent Transactions`}
        </HeaderLabel>

        <Link onPress={handlePress}>View All</Link>
      </View>

      {limitedTransactions.length > 0 && (
        <View>
          {limitedTransactions.map((transaction) => (
            <View
              key={transaction.transactionId}
              style={{ flexDirection: 'row', marginBottom: DEFAULT_SPACING }}
            >
              <View style={{ alignItems: 'center' }}>
                <Text
                  style={{ fontSize: 20, color: darkblue, fontWeight: '600' }}
                >
                  {moment(transaction.date).format('DD')}
                </Text>
                <Text style={{ fontSize: 12, color: gunmetal }}>
                  {moment(transaction.date).format('MMM')}
                </Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginHorizontal: DEFAULT_SPACING,
                  flexGrow: 1
                }}
              >
                <CircleIcon
                  size={20}
                  icon={transaction.iconProps.icon}
                  color={transaction.iconProps.color}
                  backgroundColor={transaction.iconProps.backgroundColor}
                  style={styles.circleIconStyle}
                />

                <View style={{ flexGrow: 1 }}>
                  <Text
                    style={{ fontSize: 16, color: darkblue, fontWeight: '600' }}
                  >
                    {transaction.name && transaction.name.length > 24
                      ? `${transaction.name.substring(0, 24)}...`
                      : transaction.name}
                  </Text>
                  <Text style={{ fontSize: 12, color: gunmetal }}>
                    {transaction.description &&
                    transaction.description.length > 24
                      ? `${transaction.description.substring(0, 24)}...`
                      : transaction.description}
                  </Text>
                </View>

                <Text
                  style={[
                    {
                      fontSize: 14,
                      marginLeft: 'auto',
                      color: transaction.amount > 0 ? primary : red
                    }
                  ]}
                >
                  {formatCurrency(transaction.amount, {
                    accurate: true,
                    showDollarSign: true
                  })}
                </Text>
              </View>
            </View>
          ))}
        </View>
      )}

      {limitedTransactions.length === 0 && (
        <View style={styles.noTransactions}>
          <Text style={styles.noTransactionsText}>
            You have no
            {budgetCategory && ` ${budgetCategory.label.toLowerCase()}`}{' '}
            transactions for this month.
          </Text>
        </View>
      )}

      {budgetCategory && coloredTransactions.length > 3 && (
        <Link
          containerStyle={{ justifyContent: 'flex-start' }}
          onPress={() => setShowAll(!showAll)}
          icon={!showAll ? 'caret-up' : 'caret-down'}
          type="fas"
          iconColor={info}
          iconRight={true}
          iconSize={12}
        >
          {!showAll ? 'Show All' : 'Hide'} {budgetCategory.label} Transactions
        </Link>
      )}

      <Button
        color={info}
        onPress={onAddPress}
        style={styles.smallButton}
        titleStyle={styles.smallButtonText}
      >
        Add Transaction
      </Button>
    </Card>
  )
}
