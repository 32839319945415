import {
  View,
  Text,
  StyleSheet,
  Image,
  ScrollView,
  Platform,
  useWindowDimensions
} from 'react-native'

import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import * as WebBrowser from 'expo-web-browser'
import Card from './Card'
import { black, lightblue, secondary } from '../styles/colors'
import { DEFAULT_SPACING } from '../styles'
import BottomButton from './BottomButton'

const basicStyles = {
  card: {
    position: 'relative',
    borderRadius: 8,
    width: '100%',
    height: '85%',
    padding: DEFAULT_SPACING
  },
  scrollview: {
    flex: 1,
    position: 'relative'
  },
  image: {
    resizeMode: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  companyText: {
    color: black,
    fontSize: 16,
    paddingHorizontal: '10%',
    paddingBottom: 10,
    textAlign: 'center'
  },
  cost: {
    color: lightblue,
    textAlign: 'center'
  },
  bottomButtonContainer: {
    position: 'absolute',
    bottom: 20,
    width: '100%'
  },
  buttonLeft: { position: 'absolute' },
  buttonRight: {}
}

export default function CarouselCardItem({ item, index, title, value }) {
  const { width } = useWindowDimensions()
  const ITEM_WIDTH = Math.round(width * 0.8)
  const ITEM_HEIGHT = Math.round((ITEM_WIDTH * 3) / 4)

  basicStyles.image = {
    ...basicStyles.image,
    width: ITEM_WIDTH / 1.5,
    height: ITEM_HEIGHT / 3,
    marginVertical: isNotWeb ? ITEM_HEIGHT / 20 : 0
  }

  basicStyles.companyText = {
    ...basicStyles.companyText,
    height: isNotWeb ? ITEM_HEIGHT : 'auto'
  }

  const mobileStyles = StyleSheet.create(basicStyles)
  
  const useStyles = CreateResponsiveStyle(basicStyles, {
    [minSize(DEVICE_SIZES.MD)]: {
      card: { height: 'auto', padding: 26, marginBottom: 10 },
      image: {
        maxWidth: 200,
        maxHeight: 60,
        marginRight: 'auto',
        flex: 1,
        aspectRatio: 1,
        marginLeft: 0,
        resizeMode: 'contain'
      },
      companyText: {
        textAlign: 'left',
        color: secondary,
        marginTop: 30,
        paddingBottom: 0,
        paddingHorizontal: 4
      },
      bottomButtonContainer: {
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 32,
        bottom: 0
      },
      buttonLeft: {
        width: 245,
        position: 'relative',
        bottom: 'auto'
      },
      cost: {
        fontSize: 18,
        fontWeight: 600,
        marginLeft: 40
      }
    },
  })

  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <Card style={styles.card} key={index}>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
        <Image style={styles.image} source={item.imgUrl} resizeMode="contain" />
      </View>
      <ScrollView scrollEnabled={isNotWeb} contentContainerStyle={styles.scrollview}>
        <View>
          <Text style={styles.companyText}>"{item.mission}"</Text>
        </View>
        <View style={styles.bottomButtonContainer}>
          <BottomButton
            style={styles.buttonLeft}
            onPress={() => {
              WebBrowser.openBrowserAsync(item.links[value])
            }}
          >
            {title}
          </BottomButton>
          <Text
            style={styles.cost}
            onPress={() => {
              WebBrowser.openBrowserAsync(item.cost)
            }}
          >
            View Cost and Fees
          </Text>
        </View>
      </ScrollView>
    </Card>
  )
}
