import { useLayoutEffect } from 'react'
import { View, ScrollView, Text, StyleSheet } from 'react-native'

import { map, reduce, includes } from 'lodash'
import { useSelector } from 'react-redux'
import IconDataRow from './components/IconDataRow'

import formatCurrency from './functions/formatCurrency'

import { darkblue, rgba, white, red } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import Button from './components/Button'
import { proBudgetSelector, budgetCategoriesSelector } from './selectors'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white
  },
  header: {
    backgroundColor: white,
    paddingBottom: DEFAULT_SPACING
  },
  headerLabel: {
    textAlign: 'center',
    fontSize: 16,
    color: 'rgb(120, 138, 152)',
    paddingHorizontal: 40,
    fontWeight: '500'
  },
  scrollviewContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    backgroundColor: white
  },
  scrollviewContentContainer: { paddingVertical: 40 },
  iconDataRowContainer: { padding: 8 },
  button: { marginHorizontal: DEFAULT_SPACING, marginBottom: DEFAULT_SPACING }
})

function IconDataTable({ data }) {
  return map(data, (rowProps) => (
    <IconDataRow
      containerStyle={[styles.iconDataRowContainer, rowProps.containerStyle]}
      key={rowProps.label}
      {...rowProps}
    />
  ))
}

export default function CreateBudgetScreen({ navigation, route }) {
  const { params } = route || {}
  const { title, update } = params || {}
  const proBudget = useSelector(proBudgetSelector)
  const categories = useSelector(budgetCategoriesSelector)

  const callSuccessScreen = () => {
    if (update) {
      navigation.pop()
    } else {
      navigation.navigate('Modal', { screen: 'Create Budget Success' })
    }
  }

  useLayoutEffect(() => {
    if (title) {
      navigation.setOptions({ headerTitle: title })
    }
  }, [navigation, title])

  const data = categories.map((category) => {
    const { label, key } = category
    const budgetItems = proBudget.budgetItemsByCategory[key]
    const { count, total, items, color, icon } = budgetItems
    let totalWithoutExcluded = total

    if(key === 'SAVE_FIRST_CONTRIBUTION_TARGET') {
      totalWithoutExcluded = items.reduce((sum, item) => {
        const shouldIncludeInCalc = !(item.budgetSubCategory.match('401K') || item.budgetSubCategory.match('DEFINED_BENEFIT'))

        if(shouldIncludeInCalc){
          return sum + item.amount
        } else {
          return sum
        }
      }, 0)
    }

    return {
      key,
      label,
      total: totalWithoutExcluded,
      value: count ? `$${formatCurrency(totalWithoutExcluded)}` : '—',
      color: darkblue,
      title: label,
      route: 'Budget Items List',
      count,
      iconStyle: {
        icon,
        color,
        backgroundColor: rgba(color, 0.1)
      },
      params: { key, color, icon, label },
      tooltipBody: key === 'SAVE_FIRST_CONTRIBUTION_TARGET' ? `The amount shown on the main budget screen does not include savings made through payroll deferral (e.g 401K contributions or similar). Including payroll deferrals, your actual monthly savings target is ${formatCurrency(total, { showDollarSign: true })}, although a portion of this amount will have been "paid for" prior to receiving your net pay.` : null
    }
  })

  const total = reduce(data, (sum, row) => {
    const { total, key } = row
    if (key !== 'INCOME') {
      return sum + -total
    } else {
      return sum + total
    }
  }, 0)

  data.push({
    label: total && total >= 0 ? 'Monthly Surplus (+)' : 'Monthly Deficit (-)',
    value: total ? `${formatCurrency(total, { showDollarSign: true })}` : '—',
    color: total && total >= 0 ? darkblue : red,
    backgroundColor: white,
    containerStyle: {
      padding: 0,
      paddingVertical: DEFAULT_SPACING
    },
    rightLabelStyle: {
      fontSize: 14,
      fontWeight: '600'
    },
    leftLableStyle: {
      fontSize: 14,
      fontWeight: '600'
    }
  })

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerLabel}>Below is a breakdown of a budget created based off your living essentials from your plan. Feel free to add, modify or remove any item!</Text>
      </View>
      <ScrollView contentContainerStyle={styles.scrollviewContentContainer} style={styles.scrollviewContainer}>
        <IconDataTable data={data} />
      </ScrollView>
      <Button style={styles.button} onPress={callSuccessScreen}>Done</Button>
    </View>
  )
}
