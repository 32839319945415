import { Image, Text, StyleSheet, View, useWindowDimensions } from 'react-native'

import FadeInView from './FadeInView'
import { DEFAULT_SPACING } from '../styles'
import { darkgrayblue, white } from '../styles/colors'

const styles = StyleSheet.create({
  backgroundContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: white,
    flex: 1
  },
  logo: {
    resizeMode: 'contain',
    alignSelf: 'center'
  },
  genericImage: {
    width: '100%',
    resizeMode: 'contain',
    alignSelf: 'center'
  },
  troutwood: {
    width: 200,
    height: 40,
    resizeMode: 'contain',
    alignSelf: 'center'
  },
  header: {
    fontSize: 20,
    color: darkgrayblue,
    alignSelf: 'center',
    fontWeight: '700',
    marginBottom: DEFAULT_SPACING,
    textAlign: 'center'
  },
  subheader: {
    fontSize: 14,
    color: '#F5FEFC',
    alignSelf: 'center',
    marginTop: 4
  },
  auxText: {
    fontSize: 14,
    color: '#465A6A',
    alignSelf: 'center',
    textAlign: 'center',

    maxWidth: '70%',
    marginBottom: 24
  },
  container: {
    paddingHorizontal: DEFAULT_SPACING,
    flex: 1,
    alignSelf: 'stretch'
  }
})

const initialFadeIn = 600
const wordFadeIn = initialFadeIn + 200

export function GenericSlide({ header, auxText }) {
  return (
    <View style={{ marginTop: 40 }}>
      <Text style={styles.header}>{header}</Text>

      <Text style={styles.auxText}>{auxText}</Text>
    </View>
  )
}

export function GenericImageSlide({ image, style = {} }) {
  const window = useWindowDimensions()
  const marginForFirstImage = window.height > 800 ? 40 : 30
  styles.genericImage = { ...styles.genericImage, height: window.height > 800 ? 340 : 200 }

  return (
    <FadeInView delay={0} duration={400} initialValue={0} toValue={1}>
      <Image
        style={[styles.genericImage, style, { marginTop: marginForFirstImage }]}
        source={image}
      />
    </FadeInView>
  )
}
