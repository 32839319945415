import { get } from 'http'
import { NOTIFICATION_BASE_URL, } from '@env'
import { setNotifications } from './setNotifications'

export const getNotifications = (notifications = []) => async (dispatch) => {
  const lastNotificationId = notifications.length > 0
    ? notifications[notifications.length - 1].notificationId : null

  try {
    const res = await get(`${NOTIFICATION_BASE_URL}/user/notifications${lastNotificationId ? `?last_notification_id=${lastNotificationId}` : ''}`)

    dispatch(setNotifications(notifications.length > 0
      ? [...notifications, ...res.data.data] : res.data.data))
  } catch (e) {
    console.warn(e, e.response)
  }
}

export default {};
