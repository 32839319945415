import { patch, get } from 'http'
import moment from 'moment'
import { setCurrentUser } from './setCurrentUser'
import { setLoading } from './setLoading'

import handleError from '../functions/handleError'
import handleSuccess from '../functions/handleSuccess'

export const saveCurrentUser = function (user, payload) {
  return async (dispatch) => {
    try {
      setLoading({ loading: true })

      if (payload.birth_date) {
        payload.birth_date = moment(payload.birth_date).format('YYYY-MM-DD')
      }

      await patch('rest-auth/user/', payload)

      handleSuccess('Profile Updated', 'Your profile has been updated.')

      if (payload.is_active) {
        const meResult = await get('me/')
        dispatch(setCurrentUser(meResult.data))
      }

      setLoading({ loading: false })
    } catch (error) {
      console.error(error, error.response)
      handleError(error, 'Oops. Looks like something went wrong.')
      setLoading({ loading: false })
    }
  }
}
