import { useLayoutEffect } from 'react'
import { Platform, Text, View, ScrollView, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { map } from 'lodash'
import ListOptionItem from './components/ListOptionItem'
import { rgba, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import BuilderWrapper from './components/plans/BuilderWrapper'
import { employerSponsoredOptions, investorProfileOptions } from './constants'
import Divider from './components/Divider'

const basicStyles = {
  header: { paddingBottom: DEFAULT_SPACING },
  headerLabel: {
    fontSize: 16,
    color: white,
    paddingHorizontal: 40,
    fontWeight: '500'
  },
  disclosure: {
    fontSize: 10,
    textAlign: 'center',
    color: white
  },
  scrollviewContainer: { paddingHorizontal: DEFAULT_SPACING },
  scrollviewContentContainer: { paddingVertical: 40 },
  listContainer: {},
  divider: {
    borderBottomColor: '#558C61',
    width: '100%'
  },
  employerSponsoredLabel: {
    backgroundColor: '#558C61',
    paddingHorizontal: 15,
    paddingVertical: 7,
    fontSize: 12,
    fontWeight: '700',
    borderRadius: 14,
    overflow: 'hidden',
    position: 'absolute',
    color: white
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    headerLabel: {
      fontSize: 22,
      paddingHorizontal: 0,
      fontWeight: '400'
    },
    listContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: 16,
      width: '100%'
    },
    scrollviewContainer: { paddingHorizontal: 0 },
    scrollviewContentContainer: { paddingVertical: 57 },
    disclosure: { textAlign: 'left', fontSize: 16, marginTop: 24 }
  },
  [minSize(DEVICE_SIZES.XL)]: { listContainer: { width: '75%' } }
})

export default function InvestmentOptionsScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { params } = route
  const {
    title,
    subtitle,
    builder,
    useGradient,
    includeProgress,
    advancedSavings
  } = params

  function onPress(option) {
    const params = {
      title: 'Plan Builder',
      subtitle: 'Goal',
      builder,
      advancedSavings,
      useGradient,
      includeProgress,
      ...option
    }
    if (option.route) {
      navigation.navigate('Modal', { screen: option.route, params })
    } else {
      navigation.navigate('Modal', { screen: 'Investment Profile', params })
    }
  }
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      headerTintColor: white,
      title: null
    })
  }, [title, subtitle])

  return (
    <BuilderWrapper
      option="Goal"
      includeProgress={includeProgress}
    >
      <View style={{ flex: 1, flexDirection: 'column' }}>
        <View style={styles.header}>
          <Text style={styles.headerLabel}>
            Financial plans can be built around contribution targets or goals.
            Choose an option below to get started.
          </Text>
        </View>

        <ScrollView
          contentContainerStyle={styles.scrollviewContentContainer}
          style={styles.scrollviewContainer}
        >
          <View style={styles.listContainer}>
            {map(investorProfileOptions, (option) => {
              if (
                option.value !== 'CUSTOM_GOAL' &&
                option.value !== 'ADVANCE'
              ) {
                return (
                  <ListOptionItem
                    key={`type-${option.value}`}
                    action={`Plan Builder Option: ${option.label}`}
                    iconColor={option.color}
                    iconType="far"
                    iconBackgroundColor={rgba(option.color, 0.1)}
                    iconName={option.icon}
                    navigatable
                    onPress={() => onPress(option)}
                    {...option}
                  />
                )
              }
            })}
          </View>

          <View
            style={{
              width: '100%',
              alignItems: 'center',
              marginTop: 18,
              marginBottom: 30
            }}
          >
            <Divider style={styles.divider} />
            <Text style={styles.employerSponsoredLabel}>
              EMPLOYER SPONSORED
            </Text>
          </View>

          <View style={styles.listContainer}>
            {map(employerSponsoredOptions, (option) => {
              if (
                option.value !== 'CUSTOM_GOAL' &&
                option.value !== 'ADVANCE'
              ) {
                return (
                  <ListOptionItem
                    key={`type-${option.value}`}
                    action={`Plan Builder Option: ${option.label}`}
                    iconColor={option.color}
                    iconType="far"
                    iconBackgroundColor={rgba(option.color, 0.1)}
                    iconName={option.icon}
                    navigatable
                    onPress={() => onPress(option)}
                    {...option}
                  />
                )
              }
            })}
          </View>

          <View style={{ marginTop: 10 }}>
            <Text style={styles.disclosure}>
              Risk Disclosure. All investment strategies have the potential for
              profit and loss. Past performance is not a guide to future
              returns.
            </Text>
          </View>
        </ScrollView>
      </View>
    </BuilderWrapper>
  )
}
