import { useState, useLayoutEffect } from 'react'
import { View, Text, ScrollView, StyleSheet, Image } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { LinearGradient } from 'expo-linear-gradient'

import { groupBy, capitalize, sortBy, orderBy } from 'lodash'
import MilestoneGroup from './components/wellness/MilestoneGroup'
import CreateMilestoneSlideupDrawer from './components/CreateMilestoneSlideupDrawer'
import EditMilestoneSlideupDrawer from './components/EditMilestoneSlideupDrawer'
import AddRightButton from './components/AddRightButton'
import lighthouse from './assets/icons/lighthouse.png'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import {
  wellnessChecklistsSelector,
  currentUserSelector,
  personalMilestonesSelector
} from './selectors'

import { saveMetaData } from './actions'
import { DEFAULT_SPACING, gray, lightblue, lightestgray, lightgray, white } from './styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: lightestgray
  },
  linearGradient: {
    width: '100%',
    position: 'absolute',
    zIndex: 0,
    height: '100%',
  },
  contentContainer: { paddingBottom: 60 },
  subtitle: {
    color: white,
    textAlign: 'center',
    width: '100%',
    paddingBottom: DEFAULT_SPACING * 2
  },
  boldText: {
    fontWeight: 'bold',
  },
  centerText: {
    justifyContent: 'center', textAlign: 'center', color: gray, 
  },
  marginsText: {
    justifyContent: 'center', margin: 80,
  },
})

export default function MilestoneScreen({ navigation, route }) {
  const dispatch = useDispatch()
  const personalMilestoneChecklist = useSelector((state) => personalMilestonesSelector(state))
  const groupedMilestoneChecklist = orderBy(personalMilestoneChecklist, 'created', 'desc')

  const currentUser = useSelector((state) => currentUserSelector(state))
  const { metadata } = currentUser
  const [currentCreateMilestone, setCurrentCreateMilestone] = useState(null)

  const [currentEditMilestone, setCurrentEditMilestone] = useState(null)

  const metadataKey = 'TROUTWOOD_MILESTONES_COMPLETED'

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      title: 'Milestones',
      headerRight: () => (
        <AddRightButton
          color={white}
          onPress={() => {
            setCurrentCreateMilestone({ description: '' })
          }}
        />
      ),
      headerStyle: {
        height: 105,
        elevation: 0,
        shadowOpacity: 0,
        backgroundColor: '#D453A9'
      },
      headerTitleStyle: {
        fontSize: 20,
        color: white,
        textAlign: 'center'
      },
      iconContainer: {
        alignItems: 'center',
        position: 'relative'
      },
      headerSubtitleStyle: {
        fontSize: 14,
        textAlign: 'center',
        fontWeight: '400',
        letterSpacing: -0.48,
        marginTop: 2,
        color: white
      },
    });
  }, [navigation, metadata])

  const [currentMilestoneChecklistItemPopupText, setCurrentMilestoneChecklistItemPopupText] = useState(null)
  return (
    <View style={{ flex: 1 }}>
      <LinearGradient
        colors={['#D453A9', '#9C2974']}
        style={[styles.linearGradient]}
        locations={[0, 1]}
      />
        <View style={styles.iconContainer}>
        <Image resizeMode="contain" style={{ width: 90, height: 90, margin: 'auto', alignSelf:'center', marginBottom: 10}} source={lighthouse} />

        </View>
      <Text style={styles.subtitle}> To <Text style={styles.boldText}>guide</Text> your journey</Text>
      <View />
      <ScrollView showsVerticalScrollIndicator={false} style={styles.container} contentContainerStyle={styles.contentContainer}>
        { groupedMilestoneChecklist.length > 0 &&
          <MilestoneGroup

            goToEditGoal={(item) => {
              setCurrentEditMilestone(item.description
                ? {
                  description: item.description,
                  id: item.id

                }
                : null)
            }}
            onPress={(item) => {
              setCurrentEditMilestone(item.description
                ? {
                  description: item.description,
                  id: item.id

                }
                : null)
            }}
            key="milestone-group"
            milestoneList={groupedMilestoneChecklist}
          />
        }
        { groupedMilestoneChecklist.length <= 0 &&
          <>
          <View style={{justifyContent: 'center', margin: 80}}>
            <Text style={[styles.centerText, styles.boldText]}>
            You don't have any milestones added yet! Click the + button to begin setting your future goals.
            </Text>
          </View>
          </>
        }
      </ScrollView>
      <CreateMilestoneSlideupDrawer
        visible={currentCreateMilestone}
        setVisible={() => setCurrentCreateMilestone(null)}
        currentUser={currentUser}
        navigation={navigation}

      />
      <EditMilestoneSlideupDrawer
        visible={currentEditMilestone}
        setVisible={() => setCurrentEditMilestone(null)}
        currentEditMilestone={currentEditMilestone}
        metadataKey={null}
        currentUser={currentUser}
        navigation={navigation}

      />
    </View>
  )
}
