import { TROUTWOOD_CLIENT_ID, TROUTWOOD_REDIRECT_URI } from '@env'
import { post, get } from 'http'
import qs from 'qs'
import proApplicationEndpoints from '../api/proApplicationEndpoints'

export const SET_AUTHORIZATION_CODE = 'SET_AUTHORIZATION_CODE'
export const AUTHORIZATION_CODE_LOCAL_STORAGE_KEY =
  'AUTHORIZATION_CODE_LOCAL_STORAGE_KEY'

// eslint-disable-next-line import/prefer-default-export
export const getAuthorizationCode = () => async (dispatch, getState) => {
  try {
    const openIdResponse = await get('api/v1/openid/client/AWS-SERVICES')
    const {
      data: { scope }
    } = openIdResponse

    const body = qs.stringify({
      client_id: TROUTWOOD_CLIENT_ID,
      response_type: 'code',
      scope: scope.join(' '),
      redirect_uri: TROUTWOOD_REDIRECT_URI,
      allow: 1
    })

    const response = await post('web/openid/authorize/', body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*'
      }
    })

    const {
      data: { code }
    } = response

    await dispatch({
      type: SET_AUTHORIZATION_CODE,
      payload: {
        code,
        createdAt: Date.now()
      }
    })

    await post(proApplicationEndpoints.authorize(), { code })
  } catch (e) {
    console.warn(e, e.response)
  }
}
