import { useLayoutEffect, useRef, useState } from 'react'
import { Platform, View, Text, StyleSheet, ImageBackground } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import NumberInput from './components/NumberInput'
import Form from './components/Form'
import InputSelect from './components/InputSelect'

import { black, info, primary, secondary, white } from './styles/colors'
import {
  ValidationSchema,
  Fields,
  InitialValues
} from './schemas/FutureValueCalculatorSchema'

import { DEFAULT_SPACING } from './styles'

import CurrencyInput from './components/CurrencyInput'
import Input from './components/Input'
import formatCurrency from './functions/formatCurrency'

import { futureValueCalculator } from './actions'
import handleError from './functions/handleError'
import abbreviateCurrency from './functions/abbreviateCurrency'
import PageHeader from './components/PageHeader'
import ToolsHeaderWrapper from './components/tools/ToolsHeaderWrapper'

const basicStyles = {
  container: {},
  resultsContainer: {
    padding: DEFAULT_SPACING * 2,
    paddingVertical: DEFAULT_SPACING * 3
  },
  headerContent: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: primary,
    height: 150
  },
  headerImage: {
    resizeMode: 'cover',
    height: 150,
    top: undefined
  },
  headerText: {
    textAlign: 'center',
    fontSize: 14,
    color: white
  },
  moneyContainer: {
    backgroundColor: info,
    borderRadius: 12,
    paddingHorizontal: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING
  },
  moneyText: {
    textAlign: 'center',
    fontSize: 42,
    color: white,
    fontWeight: 'bold'
  },
  link: {
    textDecorationLine: 'underline',
    paddingTop: 0
  },
  modal: {
    padding: 0,
    paddingVertical: DEFAULT_SPACING * 3
  },
  modalImage: {
    height: 200,
    width: 300
  },
  modalHeaderText: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: '700',
    marginVertical: DEFAULT_SPACING
  },
  modalSubheader: {
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING * 2
  },
  modalBodyText: { color: secondary },
  bold: {
    color: black,
    fontWeight: 'bold',
    marginLeft: DEFAULT_SPACING / 2
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  form: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    container: {
      padding: 40,
      height: '100%',
      overflow: 'auto'
    },
    main: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      height: 'calc(100% - 170px)',
      borderRadius: 12,
      marginTop: 26,
      padding: 40,
      backgroundColor: 'white'
    },
    headerContent: {
      borderRadius: 12,
      maxWidth: '50%',
      height: '100%'
    },
    headerImage: { height: '100%' },
    form: {
      marginRight: '17%',
      maxWidth: 360
    },
    resultsContainer: {
      height: '100%',
      justifyContent: 'center'
    },
    headerText: {
      fontSize: 18,
      fontWeight: '600'
    }
  }
})

function FutureValueCalculatorHeader({ value }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <ImageBackground
      source={require('./assets/future-value-charts.png')}
      imageStyle={styles.headerImage}
      style={styles.headerContent}
    >
      <View style={[styles.resultsContainer]}>
        <Text style={styles.headerText}>Future Value</Text>
        <View style={styles.moneyContainer}>
          <Text style={styles.moneyText}>{value}</Text>
        </View>
      </View>
    </ImageBackground>
  )
}

const isNotWeb = Platform.OS !== 'web'

export default function FutureValueCalculatorScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const rateOfReturnField = useRef()
  const contributionAmountField = useRef()
  const yearsInMarketField = useRef()

  const [calculation, setCalculation] = useState({})

  const total = calculation?.result?.total

  const contributionFrequencyOptions = [
    {
      label: '',
      value: ''
    },
    {
      label: 'Weekly',
      value: 'W'
    },
    {
      label: 'Monthly',
      value: 'M'
    },
    {
      label: 'Annually',
      value: 'A'
    }
  ]

  let formattedTotal = '?'

  if (total && total > 1000000000) {
    formattedTotal = `$${abbreviateCurrency(total)}`
  } else if (total) {
    formattedTotal = `$${formatCurrency(total)}`
  }

  const title = 'Future Value Calculator'

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: isNotWeb })
  }, [navigation])

  return (
    <View style={styles.container}>
      {!isNotWeb && (
        <ToolsHeaderWrapper>
          <PageHeader
            title={title}
            subTitle="Use this calculator to determine the future value of an investment which can include an initial deposit and a stream of periodic deposits."
          />
        </ToolsHeaderWrapper>
      )}
      <View style={styles.main}>
        <FutureValueCalculatorHeader value={formattedTotal} />
        <Form
          withPadding={false}
          buttonText="Calculate"
          enableReinitialize
          validationSchema={ValidationSchema}
          initialValues={InitialValues}
          containerStyle={styles.form}
          onSubmit={async (values) => {
            try {
              const response = await futureValueCalculator(values)
              setCalculation(response)
            } catch (e) {
              setCalculation({})
              handleError(e, 'Unable to calculate future value')
            }
          }}
        >
          {({ handleChange, handleBlur, values, errors, touched }) => (
            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <View style={{ padding: DEFAULT_SPACING }}>
                  <CurrencyInput
                    type="money"
                    unit="$"
                    name={Fields.initialInvestment}
                    errorMessage={errors[Fields.initialInvestment]}
                    touched={touched[Fields.initialInvestment]}
                    onChangeText={handleChange(Fields.initialInvestment)}
                    onBlur={handleBlur(Fields.initialInvestment)}
                    value={values[Fields.initialInvestment]}
                    label="Starting Balance"
                    secureTextEntry={false}
                    returnKeyType="next"
                    placeholder="$10,000"
                    keyboardType="number-pad"
                    autoComplete="off"
                    caretHidden
                    enablesReturnKeyAutomatically
                    onSubmitEditing={() => rateOfReturnField.current.focus()}
                  />

                  <NumberInput
                    type="money"
                    precision={2}
                    suffixUnit="%"
                    ref={rateOfReturnField}
                    name={Fields.rateOfReturn}
                    errorMessage={errors[Fields.rateOfReturn]}
                    touched={touched[Fields.rateOfReturn]}
                    onChangeText={handleChange(Fields.rateOfReturn)}
                    onBlur={handleBlur(Fields.rateOfReturn)}
                    value={values[Fields.rateOfReturn]}
                    label="Rate of Return"
                    secureTextEntry={false}
                    returnKeyType="next"
                    placeholder="7%"
                    keyboardType="number-pad"
                    autoComplete="off"
                    caretHidden
                    enablesReturnKeyAutomatically
                    onSubmitEditing={() => rateOfReturnField.current.focus()}
                  />

                  <CurrencyInput
                    type="money"
                    unit="$"
                    ref={contributionAmountField}
                    name={Fields.contributionAmount}
                    errorMessage={errors[Fields.contributionAmount]}
                    touched={touched[Fields.contributionAmount]}
                    onChangeText={handleChange(Fields.contributionAmount)}
                    onBlur={handleBlur(Fields.contributionAmount)}
                    value={values[Fields.contributionAmount]}
                    label="Contribution Amount"
                    secureTextEntry={false}
                    returnKeyType="next"
                    placeholder="$10,000"
                    keyboardType="number-pad"
                    autoComplete="off"
                    caretHidden
                    enablesReturnKeyAutomatically
                    onSubmitEditing={() => yearsInMarketField.current.focus()}
                  />

                  <Input
                    ref={yearsInMarketField}
                    name={Fields.yearsInMarket}
                    errorMessage={errors[Fields.yearsInMarket]}
                    touched={touched[Fields.yearsInMarket]}
                    onChangeText={handleChange(Fields.yearsInMarket)}
                    onBlur={handleBlur(Fields.yearsInMarket)}
                    value={values[Fields.yearsInMarket]}
                    label="Years In Market"
                    returnKeyType="next"
                    placeholder="40"
                    keyboardType="number-pad"
                  />

                  <InputSelect
                    placeholder="--"
                    name={Fields.contributionFrequency}
                    errorMessage={errors[Fields.contributionFrequency]}
                    touched={touched[Fields.contributionFrequency]}
                    label="Contribution Frequency"
                    value={values[Fields.contributionFrequency]}
                    options={contributionFrequencyOptions}
                    onChangeText={handleChange(Fields.contributionFrequency)}
                    onSubmitEditing={handleChange(Fields.contributionFrequency)}
                  />
                </View>
              </View>
            </View>
          )}
        </Form>
      </View>
    </View>
  )
}
