import { View, Text, Platform, StyleSheet } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { FontAwesome5 } from '@expo/vector-icons'

import ListItem from '../ListItem'
import hapticFeedback from '../hapticFeedback'
import CircleIcon from '../CircleIcon'
import CircleImage from '../CircleImage'
import TouchableOpacity from '../TouchableOpacity'
import TextOrImage from '../TextOrImage'

import { darkblue, gunmetal, white } from '../../styles'

const basicStyles = {
  listItemContainer: { backgroundColor: white },
  title: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0,
    marginLeft: 20
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5,
    marginLeft: 20,
    marginTop: 4
  },
  icon: {
    width: 60,
    height: 60,
    borderRadius: 60
  },
  listItemWrapper: {},
  tag: {
    color: '#7CBA82',
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderRadius: 12,
    overflow: 'hidden',
    backgroundColor: '#7CBA821a',
    display: 'flex',
    alignSelf: 'flex-start'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    listItemWrapper: {
      width: 'calc(50% - 6px)',
      height: 127,
      marginVertical: 10
    },
    listItemContainer: {
      width: '100%',
      height: '100%',
      paddingHorizontal: 20,
      paddingVertical: 24
    },
    icon: {
      width: 86,
      height: 86
    },
    title: { fontSize: 22 },
    subtitle: {
      fontSize: 16,
      fontWeight: 600
    }
  }
})

function ToolsListItem({
  actionName,
  image,
  iconComponent,
  icon,
  iconColor,
  iconBackgroundColor,
  imageBackgroundColor,
  title,
  subtitle,
  subtitleStyle,
  tag,
  onPress,
  imageWidth = '70%',
  imageHeight = '70%'
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <TouchableOpacity
      action={actionName}
      style={styles.listItemWrapper}
      onPress={() => {
        onPress()
        hapticFeedback()
      }}
    >
      <ListItem style={styles.listItemContainer}>
        {!iconComponent && icon && (
          <CircleIcon
            style={styles.icon}
            size={isNotWeb ? 30 : 34}
            icon={icon}
            color={iconColor}
            backgroundColor={iconBackgroundColor}
          />
        )}
        {!iconComponent && image && (
          <CircleImage
            backgroundColor={imageBackgroundColor}
            size={isNotWeb ? 64 : 86}
            image={image}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
          />
        )}
        {iconComponent}
        <View style={{ flex: 2 }}>
          <View style={{ flexDirection: 'row' }}>
            <TextOrImage title={title} style={styles.title} />
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            {subtitle && <Text style={[styles.subtitle, subtitleStyle]}>{subtitle}</Text>}
            {tag && <Text style={[styles.subtitle, styles.tag]}>{tag}</Text>}
          </View>
        </View>
        <View style={{ width: 50 }}>
          <FontAwesome5
            style={{ alignSelf: 'flex-end' }}
            name="chevron-circle-right"
            size={isNotWeb ? 18 : 22}
            color="rgb(223, 230, 236)"
          />
        </View>
      </ListItem>
    </TouchableOpacity>
  )
}

export default ToolsListItem
