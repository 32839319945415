import { useEffect, useRef } from 'react'
import { AppState, Platform } from 'react-native'
import { Analytics } from 'aws-amplify'
import DeviceInfo from 'react-native-device-info'
import Axios from 'axios'
import { PINPOINT_ENABLED } from '@env'

const APP_STATE = 'APP_STATE'
const APP_NAVIGATION = 'APP_NAVIGATION'
const APP_BUTTON_TAP = 'APP_BUTTON_TAP'
const APP_FORM_SUBMIT = 'APP_FORM_SUBMIT'

const ACTIVE = 'active'
const INACTIVE = 'inactive'
const BACKGROUND = 'background'
const FOREGROUND = 'foreground'

function gatherUserAttributes(currentUser) {
  return {
    isFake: currentUser.is_fake,
    userId: currentUser.id,
    sponsors: currentUser.sponsors.map((sponsor) => sponsor.name).join(','),
    created: currentUser.created,
  }
}

async function updateEndpoint(currentUser) {
  let channelType = null
  if (Platform.OS === 'ios') {
    channelType = 'APNS'
  } else if (Platform.OS === 'android') {
    channelType = 'GCM'
  }

  const appVersion = DeviceInfo.getVersion()
  const make = await DeviceInfo.getManufacturer()
  const model = DeviceInfo.getModel()
  let location = {}

  try {
    const ip = await DeviceInfo.getIpAddress()
    const data = { ip }
    const config = { headers: { 'x-api-key': 'zeAOJCiCzE6w2PTdACHpWV3MC8aS1Os1Bn062EPj' } }

    const locationResponse = await Axios.post('https://prod-geolocation.troutwood-api.net', data, config)
    location = locationResponse.data.data
  } catch (error) {
    console.warn(error.response)
  }

  Analytics.updateEndpoint({
    address: currentUser.email,
    channelType,
    demographic: {
      appVersion, // The version of the application associated with the endpoint.
      make, // The manufacturer of the endpoint device, such as Apple or Samsung.
      model, // The model name or number of the endpoint device, such as iPhone.
      platform: Platform.OS, // The platform of the endpoint device, such as iOS or Android.
      platformVersion: Platform.Version, // The platform version of the endpoint device.
    },
    userId: currentUser.id,
    location: {
      city: location.city,
      country: location.country, // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
      latitude: location.latitude, // The latitude of the endpoint location, rounded to one decimal place.
      longitude: location.longitude, // The longitude of the endpoint location, rounded to one decimal place.
      postalCode: location.postal_code, // The postal code or zip code of the endpoint.
      // region: 'xxxxxx' // The region of the endpoint location. For example, in the United States, this corresponds to a state.
    },
    autoSessionRecord: false
  })
}

function trackAppState(appState, currentUser) {
  if (PINPOINT_ENABLED  == 'true' && currentUser?.id) {
    Analytics.record({
      name: APP_STATE,
      attributes: { appState, ...gatherUserAttributes(currentUser) },
      immediate: true
    })
  }
}

export function trackButtonTap(title, routeName, currentUser) {
  if (PINPOINT_ENABLED == 'true' && currentUser?.id && title && title !== '') {
    Analytics.record({
      name: APP_BUTTON_TAP,
      attributes: { routeName, button: title, ...gatherUserAttributes(currentUser) },
      immediate: true
    })
  }
}

export function trackFormSubmit(routeName, formAttributes, currentUser) {
  if (PINPOINT_ENABLED == 'true' && currentUser?.id) {
    Analytics.record({
      name: APP_FORM_SUBMIT,
      attributes: { routeName, formAttributes, ...gatherUserAttributes(currentUser) },
      immediate: true
    })
  }
}

export function trackNavigation(routeName, currentUser) {
  if (PINPOINT_ENABLED == 'true' && currentUser?.id) {
    Analytics.record({
      name: APP_NAVIGATION,
      attributes: { routeName, ...gatherUserAttributes(currentUser) },
      immediate: true
    })
  }
}

export function setupPinpoint(currentUser, accessToken) {
  if (PINPOINT_ENABLED == 'true' && currentUser) {
    updateEndpoint(currentUser, accessToken)
  }
}

export default function usePinpoint(store) {
  const appState = useRef(AppState.currentState)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      const { currentUser } = store.getState()

      if (nextAppState === ACTIVE && appState.current !== ACTIVE) {
        trackAppState(FOREGROUND, currentUser)
        appState.current = FOREGROUND
      } else if ((nextAppState == INACTIVE || nextAppState == BACKGROUND) && appState.current !== BACKGROUND) {
        trackAppState(BACKGROUND, currentUser)
        appState.current = BACKGROUND
      }
    })

    return () => {
      subscription.remove()
    }
  }, [])
}
