import { useLayoutEffect } from 'react'

import IntroScreen from './components/IntroScreen'

export default function NetWorthCalculatorIntroScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false });
  }, [navigation])

  return (
    <IntroScreen
      title="Net Worth Calculator"
      subtitle="Input your assets and liabilities to calculate your net worth."
      image={require('./assets/finn/networth.png')}
      buttonText="Next"
      onPress={() => {
        navigation.navigate('Modal', { screen: 'Net Worth Calculator' })
      }}
    />
  )
}
