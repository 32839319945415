import { View, Text, StyleSheet } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  danger,
  warning,
  primary,
  secondary,
  DEFAULT_SPACING,
  darkblue
} from '../../styles'
import formatCurrency from '../../functions/formatCurrency'

const styles = StyleSheet.create({
  container: (withoutMargin, withoutPadding) => ({
    flexDirection: 'row',
    alignItems: 'space-between',
    marginRight: withoutMargin ? 0 : DEFAULT_SPACING,
    paddingHorizontal: withoutPadding ? 0 : DEFAULT_SPACING
  }),
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1
  },
  rightContainer: { flex: 1, alignItems: 'flex-end' },
  thisMonth: {
    color: secondary,
    fontSize: 12
  }
})

export default function BudgetBreakDownForMonth({
  budgetAmount,
  actualAmount,
  withoutMargin = false,
  withoutPadding = false,
  isIncome = false
}) {
  const remainingAmount = budgetAmount - actualAmount
  let color = primary

  if (actualAmount > budgetAmount) {
    color = danger
  } else if (actualAmount > budgetAmount * 0.75) {
    color = warning
  } else if (actualAmount < budgetAmount && actualAmount > 0) {
    color = darkblue
  }

  if (isIncome && actualAmount) {
    color = primary
  }

  let icon = null
  if (actualAmount > budgetAmount) {
    icon = 'circle-exclamation'
  } else if (actualAmount > budgetAmount * 0.75) {
    icon = 'triangle-exclamation'
  }

  if (isIncome) {
    icon = null
  }

  return (
    <View style={styles.container(withoutMargin, withoutPadding)}>
      <View style={styles.leftContainer}>
        {icon && (
          <FontAwesomeIcon
            color={color}
            size={10}
            icon={icon}
            style={{ marginRight: 6 }}
          />
        )}

        <Text style={[styles.thisMonth, { color, fontWeight: '700' }]}>
          {`$${formatCurrency(actualAmount)}`}
        </Text>

        <Text style={styles.thisMonth}> of </Text>

        <Text style={styles.thisMonth}>{`$${formatCurrency(
          budgetAmount
        )}`}</Text>
      </View>

      <View style={styles.rightContainer}>
        <Text style={styles.thisMonth}>
          {formatCurrency(remainingAmount > 0 ? remainingAmount : 0, {
            showDollarSign: true
          })}{' '}
          Remaining
        </Text>
      </View>
    </View>
  )
}
