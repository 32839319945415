import { useState, useEffect } from 'react'
import { Platform, View, Text, ScrollView, StyleSheet } from 'react-native'
import { get } from 'http'
import { map, compact } from 'lodash'
import {
  VictoryChart,
  VictoryArea,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel
} from 'victory-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import CircularProgress from './components/CircularProgress'

import Container from './components/Container'
import PillLabel from './components/PillLabel'
import TooltippedText from './components/TooltippedText'
import DataRow from './components/DataRow'

import GoalAdequacySlideupDrawer from './components/plans/GoalAdequacySlideupDrawer'

import { DEFAULT_SPACING, gunmetal, rgba, info, darkblue, lightgray } from './styles'

import formatCurrency from './functions/formatCurrency'

import PillGroup from './components/PillGroup'
import { ANNUAL, WEEKLY, MONTHLY } from './constants'
import formatPercent from './functions/formatPercent'
import Divider from './components/Divider'

const basicStyles = {
  container: {
    paddingHorizontal: DEFAULT_SPACING,
    alignItems: 'center'
  },
  scrollView: {
    alignItems: 'center',
    paddingBottom: 100
  },
  title: {
    fontSize: 28,
    fontWeight: '600',
    textAlign: 'center',
    marginVertical: DEFAULT_SPACING * 2,
    letterSpacing: -0.6
  },
  help: {
    fontSize: 16,
    textAlign: 'center',
    letterSpacing: -0.67,
    color: gunmetal,
    marginVertical: DEFAULT_SPACING * 2,
    lineHeight: 24
  },
  smallText: {
    textAlign: 'center',
    fontSize: 14,
    color: gunmetal
  },
  darkText: {
    color: darkblue,
    fontWeight: '600'
  },
  largeMoneyText: {
    fontSize: 28,
    fontWeight: '700'
  },
  anchorStyle: {
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0.05,
    color: info,
    marginVertical: 12,
    textAlign: 'center'
  },
  tableContainer: {
    width: '100%',
  },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      tableContainer: {
        width: '40vw',
        borderWidth: 1,
        borderColor: lightgray,
        borderRadius: 6,
        padding: DEFAULT_SPACING,
        marginTop: DEFAULT_SPACING,
      }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

function DataTable({ amount, amount_youll_have, extra, percent }) {
  const data = [
    {
      label: 'Amount Needed',
      tooltipBody:
        'Annual estimate of the amount you may need in retirement to cover your current living essentials + projected inflation',
      value: `$${formatCurrency(amount)}`
    },
    {
      label: 'Projected Income Replacement',
      tooltipBody:
        'Annual estimated income ($ amount) taken from your total savings/investments, based on your spending policy. This does NOT include social security or pension funds.',
      value: `$${formatCurrency(amount_youll_have)}`
    },
    {
      label: 'Extra',
      tooltipBody:
        'Difference between projected income replacement and the amount needed to cover your essentials',
      value: `$${formatCurrency(extra)}`
    }
  ]

  if (percent) {
    data.push({
      label: 'Spending Policy',
      tooltipBody:
        'The amount (percentage) of your total savings/investments you intend to spend, annually, after retirement',
      value: `${percent}`
    })
  }

  return map(compact(data), (rowProps, index) => (
    <DataRow key={rowProps.label} {...rowProps} index={index} />
  ))
}

export default function ProAdequacyScoreScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { params } = route
  const { adequacyColor, adequacyScore, adequacyShield } = params

  const { amountNeeded, projectedIncomeReplacement, spendingPolicy } =
    adequacyShield || {}

  const deficitOrSurplus = projectedIncomeReplacement - amountNeeded

  console.log(
    amountNeeded,
    projectedIncomeReplacement,
    spendingPolicy,
    'adequacyShield'
  )

  const [duration, setDuration] = useState(ANNUAL)

  const options = [
    {
      label: 'Weekly',
      inverted: true,
      active: duration === WEEKLY,
      onPress: () => setDuration(WEEKLY)
    },
    {
      label: 'Monthly',
      inverted: true,
      active: duration === MONTHLY,
      onPress: () => setDuration(MONTHLY)
    },
    {
      label: 'Annually',
      inverted: true,
      active: duration === ANNUAL,
      onPress: () => setDuration(ANNUAL)
    }
  ]

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)

  let adequacyLabel = 'Fair'

  if (adequacyScore > 70) {
    adequacyLabel = 'Good'
  } else if (adequacyScore < 30) {
    adequacyLabel = 'Poor'
  }

  let divisor = 1
  let divisorLabel = 'year'

  if (duration == MONTHLY) {
    divisor = 12
    divisorLabel = 'month'
  } else if (duration == WEEKLY) {
    divisor = 52
    divisorLabel = 'week'
  }

  function progressContent(percent) {
    return (
      <Text style={{ fontWeight: '600', fontSize: 36 }}>
        {`${percent.toFixed(0)}%`}
      </Text>
    )
  }

  return (
    <Container style={styles.container}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollView}
      >
        <CircularProgress
          progress={adequacyScore}
          size={160}
          width={12}
          fillFunction={() => progressContent(adequacyScore)}
          tintColor={adequacyColor}
        />
        <PillLabel
          width={50}
          style={{
            padding: 2,
            marginLeft: 2,
            marginTop: DEFAULT_SPACING,
            backgroundColor: rgba(adequacyColor, 0.1)
          }}
          textStyle={{
            color: adequacyColor,
            textTransform: 'capitalize',
            fontSize: 12,
            letterSpacing: 0
          }}
          title={adequacyLabel}
        />
        <Text style={styles.title}>What is adequacy?</Text>
        <Text style={styles.help}>
          If you stick to your plan and hit your goal this is how much of your
          income can be replaced after installing a spending policy. The higher
          your score, the more of your income will be replaced!
        </Text>

        <TooltippedText
          visible={tooltipIsVisible}
          setVisible={setTooltipIsVisible}
          position="top"
          anchorStyle={styles.anchorStyle}
          anchorText={"What's a Spending Policy?"}
          contentHeader={"What's a Spending Policy?"}
          contentBody="A Spending Policy is used in order to guide your spending of your retirement savings. By implementing a 5% spending policy, you can help your savings last as long as you need them."
        />

        <Divider style={{ width: '100%' }} />

        <View>
          <Text style={styles.smallText}>You will have</Text>
          <Text style={styles.largeMoneyText}>
            {formatCurrency(projectedIncomeReplacement / divisor, {
              showDollarSign: true
            })}

            {adequacyScore > 70 && <Text style={{ fontSize: 14 }}>🎉</Text>}
          </Text>
          <Text style={[styles.smallText, styles.darkText]}>
            per {divisorLabel}
          </Text>
        </View>

        <View style={styles.tableContainer}>
          <DataTable
            amount={amountNeeded / divisor}
            amount_youll_have={projectedIncomeReplacement / divisor}
            extra={deficitOrSurplus / divisor}
            percent={formatPercent(spendingPolicy)}
          />
        </View>

        <PillGroup options={options} style={{ width: 300 }} />
      </ScrollView>
    </Container>
  )
}
