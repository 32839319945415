import PageHeader from '../PageHeader'

function CollegeExploreSearchPageHeader({ navigation }) {
  const title = 'College Explore'
  const subTitle = 'Explore demographics, tuition costs and more!'

  return <PageHeader title={title} subTitle={subTitle} />
}

export default CollegeExploreSearchPageHeader
