import { post, put } from 'http'

import handleError from '../functions/handleError'
import { currentUserSelector } from '../selectors'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { setProPlan } from './setProPlan'
import yearMonthDayOnly from '../functions/yearMonthDateOnly'

export function sanitizeProPlanPayload(plan) {
  const sanitizedPlan = { ...plan, }

  if (sanitizedPlan.goal && sanitizedPlan.goal.horizonDate) {
    sanitizedPlan.goal.horizonDate = yearMonthDayOnly(sanitizedPlan.goal.horizonDate)
  }

  if (sanitizedPlan.createdAt) {
    delete sanitizedPlan.createdAt
  }

  if (sanitizedPlan.updatedAt) {
    delete sanitizedPlan.updatedAt
  }

  if (sanitizedPlan.shieldScores) {
    delete sanitizedPlan.shieldScores
  }

  if (sanitizedPlan.cashSweepLogic) {
    delete sanitizedPlan.cashSweepLogic
  }

  // Converty inflation rate to float since that's what the API needs
  if (sanitizedPlan.inflationRate) {
    sanitizedPlan.inflationRate = parseFloat(sanitizedPlan.inflationRate)
  }

  return sanitizedPlan
}

// eslint-disable-next-line import/prefer-default-export
export const saveProPlan = (newPlan) => async (dispatch, getState) => {
  const { planId } = newPlan
  const { id: userId } = currentUserSelector(getState())
  let result

  try {
    if (!planId) {
      // New plan
      result = await post(proApplicationEndpoints.createPlan(userId), sanitizeProPlanPayload(newPlan))
    } else {
      // Update the plan
      result = await put(proApplicationEndpoints.updatePlan(userId, planId), sanitizeProPlanPayload(newPlan))
    }

    dispatch(setProPlan({ planId, plan: result.data }))
  } catch (error) {
    console.error(error, error.response, error.response.body)
    handleError(error, 'Oops. Looks like something went wrong.')
  }
}
