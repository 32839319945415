import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { View, Text, StyleSheet } from 'react-native'
import { rgba, white } from '../styles/colors'

const styles = StyleSheet.create({
  tabContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16
  },
  tabText: {
    textAlign: 'center',
    marginTop: 6,
    fontSize: 8
  }
})

export default function BottomTabItem({ tabBarIcon, isFocused, disabled, label }) {
  return (
    <View style={[styles.tabContainer, { opacity: disabled ? 0.5 : 1 }]}>
      <FontAwesomeIcon
        icon={['fas', tabBarIcon]}
        color={isFocused ? white : rgba(white, 0.7)}
        size={18}
      />
      <Text style={[styles.tabText, { color: isFocused ? white : rgba(white, 0.7) }]}>{label}</Text>
    </View>
  )
}
