import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { currentProPlanSelector } from './selectors'

import ProEstimatedValueScreen from './ProEstimatedValueScreen'
import FreeEstimatedValueScreen from './FreeEstimatedValueScreen'

export default function EstimatedValueScreen({ navigation, route }) {
  const currentProPlan = useSelector(currentProPlanSelector)
  const isPro = !isEmpty(currentProPlan)

  return isPro ? (
    <ProEstimatedValueScreen navigation={navigation} route={route} />
  ) : (
    <FreeEstimatedValueScreen navigation={navigation} route={route} />
  )
}
