import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  Animated,
  Platform,
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { useDispatch, useSelector } from 'react-redux'
import { get, post } from 'http'
import moment from 'moment'

import CurrencyInput from './components/CurrencyInput'
import InvestmentProfileCard from './components/investmentProfile/InvestmentProfileCard'
import {
  darkblue,
  gunmetal,
  white,
  gray
} from './styles/colors'

import { employerSponsoredOptions } from './constants'
import { formatFinancialPayload } from './api/financialBreakdown'
import formatCurrency from './functions/formatCurrency'

import { saveCurrentPlan } from './actions'
import { currentPlanSelector, loadingSelector } from './selectors'
import Divider from './components/Divider'
import Button from './components/Button'
import PensionForm from './components/pension/PensionForm'
import ContributionSlider from './components/pension/ContributionSlider'
import NumberInput from './components/NumberInput'
import Form from './components/Form'
import { Fields, ValidationSchema } from './schemas/pensionSchema'
import InvestorProfileLayout from './components/investmentProfile/InvestorProfileLayout'
import { DEFAULT_SPACING } from './styles'

const basicStyles = {
  containerModel: {
    position: 'relative',
    backgroundColor: white
  },
  contentContainer: {
    paddingBottom: 420,
    paddingHorizontal: 4
  },
  formContent: { marginTop: 15, paddingHorizontal: 16 },
  estimatedValue: {
    color: darkblue,
    fontSize: 32,
    fontWeight: '600',
    textAlign: 'center'
  },
  estimatedValueText: {
    textAlign: 'center',
    marginTop: 4,
    fontSize: 10,
    color: gunmetal
  },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    estimatedValue: { fontSize: 38, color: '#283A49' },
    estimatedValueText: { fontSize: 14, color: gray },
    formContent: {
      width: '100%',
      alignItems: 'flex-start',
      marginTop: 48,
    },
  },
  [minSize(DEVICE_SIZES.LG)]: {
    containerModel: { padding: 40 },
    contentContainer: { marginBottom: 0, paddingBottom: 0, width: '100%' }
  },
  [minSize(DEVICE_SIZES.XL)]: {
    containerModel: { padding: 80 },
    contentContainer: { marginBottom: 0, paddingBottom: 0, width: '80%' }
  }
})

const isNotWeb = Platform.OS !== 'web'

export default function PensionScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const currentPlan = useSelector((state) => currentPlanSelector(state))
  const { loading } = useSelector((state) => loadingSelector(state))

  const form = currentPlan

  const offset = useRef(new Animated.Value(0)).current
  const height = 400
  const opacity = offset.interpolate({
    inputRange: [0, height - 250],
    outputRange: [0, 1],
    extrapolate: 'clamp',
    useNativeDriver: true
  })

  const { params } = route
  const { title, subtitle, builder, value, includeProgress, buttonText } =
    params

  const selectedInvestmentProfileOption = employerSponsoredOptions.find(
    (option) => option.value === 'PENSION'
  )
  const { label, sublabel, icon, color, moreInformation } =
    selectedInvestmentProfileOption

  const valueObject = {
    investment_selection: 'SP500',
    contribution_selection: value,
    birth_date: moment(form.birth_date).format('YYYY-MM-DD'),
    periods: form.periods,
    city_id: form.city?.id || form.city_id
  }

  const [pensionValues, setPensionValues] = useState({
    payout: plan?.extra_data?.pension.multiplier
      ? 0
      : plan?.extra_data?.pension.annual_payout || 50000,
    years_retire: plan?.extra_data?.pension.years_retire || 30
  })
  const [estimatedValue, setEstimatedValue] = useState(0)

  const [plan, setPlan] = useState({})
  const [contribution, setContribution] = useState()
  const [visible, setVisible] = useState(false)
  const [customPension, setCustomPension] = useState({
    periods: 25,
    salary: 175000,
    multiplier: 2,
    years_retire: 30
  })

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      title: (
        <Animated.View style={{ opacity }}>
          <Text style={styles.headerText}>Investor Profile</Text>
        </Animated.View>
      )
    })
  }, [navigation])

  const getSalary = (occupation) => {
    if (occupation.payscale) {
      if (occupation.type === 'civil' && occupation.payscale.TOP_25) {
        return occupation.payscale.TOP_25
      }
      if (occupation.type === 'military') {
        return Object.values(occupation.payscale).sort((a, b) => b - a)[0]
      }
    }
    return occupation.salary
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,

      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      headerTintColor: white,
      title: 'Investor Profile'
    })
    setVisible(buttonText === 'Update')
  }, [title, subtitle])

  async function fetchPlanDetails(id) {
    const response = await get(`plans/${id}/`)
    console.log('testing....')
    setPlan({ ...form, ...response.data })
    setContribution(response.data.contribution_pct_of_salary)
    setCustomPension((prev) => ({
      ...prev,
      periods: response.data.periods,
      salary: Math.floor(getSalary(response.data.occupation))
    }))

    try {
      const res = await post(`api/v1/plan/${form.id}/opportunity-cost/`, {
        ...form,
        ...response.data,
        contribution_amount: 0,
        contribution_selection: 'PENSION',
        contribution_pct_of_salary: contribution,
        periods: response.data.periods,
        extra_data: {
          pension: {
            salary: Math.floor(getSalary(response.data.occupation)),
            multiplier: pensionValues.multiplier || 1,
            annual_payout: pensionValues.payout * 1,
            years_retire: pensionValues.years_retire * 1
          }
        }
      })

      setEstimatedValue(res.data.savings_goal)
    } catch (error) {
      console.error(error, error.response.data)
    }
  }

  useEffect(() => {
    fetchPlanDetails(form.id)
  }, [form])

  useEffect(() => {
    fetchPlanDetails(form.id)
  }, [pensionValues])

  const goToGoalScreen = (estimatedValue) => {
    setTimeout(() => {
      navigation.navigate('Modal', {
        screen: 'Goal Lockin',
        params: {
          title: 'Plan Builder',
          subtitle: 'Goal Lockin',
          builder,
          includeProgress,
          estimatedValue,
          buttonText: 'Next',
          investorProfile: 'PENSION'
        }
      })
    }, 500)
  }

  function handlePress(values) {
    const estimatedValue = values[Fields.payout] * values[Fields.years_retire]
    if (estimatedValue > 0) {
      goToGoalScreen(estimatedValue)

      setTimeout(async () => {
        const currPlan = { ...form, ...valueObject, step: 3 }
        currPlan.contribution_pct_of_salary = contribution
        currPlan.periods = customPension.periods * 1
        currPlan.extra_data = {
          pension: {
            annual_payout: values[Fields.payout] * 1,
            years_retire: values[Fields.years_retire] * 1
          }
        }

        dispatch(saveCurrentPlan(formatFinancialPayload(currPlan)))
      }, 300)
    }
  }

  const investmentProfileCard = (
    <InvestmentProfileCard
      label={label}
      sublabel={sublabel}
      icon={icon}
      color={color}
      moreInformation={moreInformation}
      annualContributionAmount={(plan.salary * contribution) / 100 || 0}
      loading={loading}
      animatedValue={offset}
      onPress={() => handlePress(pensionValues)}
    />
  )

  const handleContributionChange = (val) => {
    setContribution(val)
  }

  const handleSave = (values) => {
    setVisible(false)

    goToGoalScreen(estimatedValue)

    setTimeout(async () => {
      const currPlan = { ...form, ...valueObject, step: 3 }
      currPlan.contribution_pct_of_salary = contribution
      currPlan.periods = values.periods * 1
      currPlan.extra_data = {
        pension: {
          salary: values.salary * 1,
          multiplier: values.multiplier * 1,
          years_retire: values.years_retire * 1
        }
      }

      dispatch(saveCurrentPlan(formatFinancialPayload(currPlan)))
    }, 300)
  }

  const details = (<ScrollView
    style={[styles.containerModel, { paddingTop: isNotWeb ? 310 : 86 }]}
    contentContainerStyle={styles.contentContainer}
    scrollEventThrottle={16}
    onScroll={Animated.event(
      [{ nativeEvent: { contentOffset: { y: offset } } }],
      { useNativeDriver: false }
    )}
  >
    <Form
      buttonText="Choose this Investor Profile"
      withPadding={false}
      validationSchema={ValidationSchema}
      initialValues={pensionValues}
      buttonContainerStyle={{ paddingHorizontal: 0, marginHorizontal: 16 }}
      showSubmitButton={isNotWeb}
      onSubmit={(values) => {
        handlePress(values)
        setTimeout(async () => {
          const currPlan = { ...form, ...valueObject, step: 3 }
          currPlan.contribution_pct_of_salary = contribution
          currPlan.periods = customPension.periods * 1
          currPlan.extra_data = {
            pension: {
              annual_payout: values[Fields.payout] * 1,
              years_retire: values[Fields.years_retire] * 1
            }
          }

          dispatch(saveCurrentPlan(formatFinancialPayload(currPlan)))
        }, 300)
      }}
      noRoute
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched
      }) => {
        useEffect(() => {
          setPensionValues({ ...values })
        }, [values])

        return (
          <View style={styles.formContent}>
            <Text style={styles.estimatedValue}>
              $
              {loading || !values[Fields.payout]
                ? '—'
                : formatCurrency(estimatedValue)}
              *
            </Text>
            <Text style={styles.estimatedValueText}>* Estimated Value</Text>
            <ContributionSlider
              value={contribution}
              onChange={handleContributionChange}
            />
            <View style={{ marginTop: 44, width: '100%' }}>
              <Text
                style={{ color: darkblue, fontSize: 20, fontWeight: '600', marginBottom: DEFAULT_SPACING, }}
              >
                Already know your pension amount?
              </Text>
              <CurrencyInput
                prefix="$"
                label="Annual Payout in Retirement"
                value={values[Fields.payout]}
                name={Fields.payout}
                touched={touched[Fields.payout]}
                errorMessage={errors[Fields.payout]}
                onChangeText={handleChange(Fields.payout)}
              />
              <NumberInput
                label="Expected Years of Retirement"
                name={Fields.years_retire}
                value={values[Fields.years_retire]}
                touched={touched[Fields.years_retire]}
                errorMessage={errors[Fields.years_retire]}
                onChangeText={(v) => {
                  setFieldValue(Fields.years_retire, v)
                }}
              />
              <View
                style={{
                  width: '100%',
                  alignItems: 'center',
                  marginTop: 18,
                  marginBottom: 18
                }}
              >
                <Divider
                  style={{ borderBottomColor: '#EBF0F3', width: '100%', borderBottomWidth: 1, }}
                />
                <Text
                  style={{
                    backgroundColor: '#EBF0F3',
                    paddingHorizontal: 16,
                    paddingVertical: 4,
                    fontSize: 14,
                    fontWeight: '600',
                    borderRadius: 12,
                    overflow: 'hidden',
                    position: 'absolute',
                    color: '#707A7D'
                  }}
                >
                  OR
                </Text>
              </View>
              <Button
                style={{ backgroundColor: '#5F8EE8', marginTop: 12 }}
                onPress={() => {
                  setVisible(true)
                }}
              >
                Customize My Pension
              </Button>
            </View>
          </View>
        )
      }}
    </Form>
  </ScrollView>)

  return (
    <>
      <InvestorProfileLayout
        navigation={navigation}
        route={route}
        investmentProfileCard={investmentProfileCard}
        details={details}
      />
      <PensionForm
        visible={visible}
        setVisible={() => {
          setVisible(false)
        }}
        onSave={handleSave}
        defaultValues={customPension}
      />
    </>
  )
}
