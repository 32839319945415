import { TouchableWithoutFeedback, StyleSheet, View, Text, Platform } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { red, darkblue, lightestgray, gainsboro } from '../styles/colors'

import hapticFeedback from './hapticFeedback'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  touchable: { flexGrow: 1 },
  container: {
    backgroundColor: lightestgray,
    flexDirection: 'column',
    padding: 2 * 5,
    borderRadius: 2 * 3,
    marginBottom: 24
  },
  label: {
    fontSize: 12,
    color: darkblue
  },
  input: {
    color: darkblue,
    fontSize: 18,
    marginTop: 2 * 2,
    fontWeight: '500',
    paddingVertical: 0,
    ...Platform.select({ web: { outlineStyle: 'none' } })
  },
  closeButton: {
    position: 'absolute',
    right: 2 * 6,
    top: 2 * 7,
    zIndex: 1000
  },
  icon: {
    position: 'absolute',
    left: 2 * 6,
    top: 2 * 7
  },
  error: {
    color: red,
    marginBottom: 12,
    fontSize: 12
  },
  hint: {
    marginBottom: 12,
    color: darkblue,
    fontSize: 12
  }
})

export default function InputWrapper(props) {
  const {
    touched,
    errorMessage,
    hint,
    icon,
    label,
    style,
    inputElement,
    children,
    clearable,
    value,
    onChangeText,
    setValue,
    onCancel
  } = props

  const Container = Platform.OS == 'web' ? View : TouchableWithoutFeedback

  // This View cannot be flex: 1

  return (
    <View style={styles.touchable}>
      <Container onPress={() => inputElement?.current?.focus()}>
        <View
          style={[
            styles.container,
            { borderColor: touched && errorMessage ? 'red' : gainsboro, borderWidth: 1 },
            style,
          ]}
        >
          {icon && (
            <FontAwesomeIcon
              icon={['fa', icon]}
              color="rgba(40, 58, 73, 0.48)"
              size={18}
              style={styles.icon}
            />
          )}
          {label && (
            <Text
              style={[
                styles.label,
                { color: touched && errorMessage ? 'red' : styles.label.color }
              ]}
            >
              {label}
            </Text>
          )}

          {children}

          {clearable && value && (
            <TouchableOpacity
              onPress={() => {
                if (onChangeText) onChangeText(null)
                if (setValue) setValue(null)
                if (onCancel) onCancel()
                hapticFeedback()
              }}
              style={styles.closeButton}
            >
              <FontAwesomeIcon icon={['fa', 'xmark']} fill="rgb(40, 58, 73, 0.45)" size={18} />
            </TouchableOpacity>
          )}
        </View>
      </Container>
      {touched && errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
      {hint && !errorMessage && <Text style={styles.hint}>{hint}</Text>}
    </View>
  )
}
