import { View } from 'react-native'
import ProgressBar from '../ProgressBar'
import PropTypes from 'prop-types'
import { ICON_TYPE } from '../../constants'
import {
  DEFAULT_SPACING,
  gunmetal,
  warning,
  danger,
  primary,
  antiFlashWhite,
  rgba
} from '../../styles'
import BudgetBreakDownForMonth from 'components/budgeting/BudgetBreakdownForMonth'

import formatCurrency from '../../functions/formatCurrency'
import Accordion from '../Accordion'
import Link from '../Link'

function determineBarColor(actualAmount, budgetAmount, isIncome) {
  let barColor = '#EDF1F4'

  if (actualAmount > budgetAmount) {
    barColor = danger
  } else if (actualAmount > budgetAmount * 0.75) {
    barColor = warning
  } else if (actualAmount < budgetAmount && actualAmount > 0) {
    barColor = primary
  }

  if (isIncome && actualAmount) barColor = primary

  return barColor
}

function determinePercentage(actualAmount, budgetAmount) {
  return budgetAmount > 0 && actualAmount > 0
    ? (actualAmount / budgetAmount) * 100
    : 100
}

function ThinBudgetBreakdownCard({
  title,
  budgetAmount,
  actualAmount,
  isIncome,
  onAddPress,
  children,
  style
}) {
  const barColor = determineBarColor(actualAmount, budgetAmount, isIncome)
  const percent = determinePercentage(actualAmount, budgetAmount)

  return (
    <View style={[{ paddingBottom: DEFAULT_SPACING }, style]}>
      <Accordion
        title={title}
        subtitle={formatCurrency(budgetAmount, {
          accurate: true,
          showDollarSign: true
        })}
        includeInlineChevron={true}
        compressed={true}
        titleStyle={{
          fontSize: 14
        }}
        subtitleStyle={{
          color: gunmetal,
          fontWeight: '400',
          letterSpacing: -0.6,
          fontSize: 14
        }}
        includeLeftChevron={true}
      >
        <View
          style={{
            backgroundColor: rgba(antiFlashWhite, 0.29),
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            padding: DEFAULT_SPACING
          }}
        >
          <View
            style={{
              margin: DEFAULT_SPACING
            }}
          >
            <ProgressBar
              width="100%"
              percent={percent}
              progressBarColor={barColor}
              backgroundBarColor={'#EDF1F4'}
              height={6}
            />
          </View>

          <BudgetBreakDownForMonth
            budgetAmount={budgetAmount}
            actualAmount={actualAmount}
            isIncome={isIncome}
          />

          {children}

          <Link
            containerStyle={{
              justifyContent: 'flex-start',
              marginLeft: 10,
              marginTop: 10
            }}
            style={{
              fontSize: 14
            }}
            onPress={onAddPress}
          >
            + Add New Item
          </Link>
        </View>
      </Accordion>
    </View>
  )
}

ThinBudgetBreakdownCard.defaultProps = {
  iconProps: {},
  style: {},
  actualAmount: 0
}

ThinBudgetBreakdownCard.propTypes = {
  title: PropTypes.string.isRequired,
  budgetAmount: PropTypes.number.isRequired,
  actualAmount: PropTypes.number,
  onPress: PropTypes.func.isRequired,
  iconProps: ICON_TYPE,
  style: PropTypes.object
}

export default ThinBudgetBreakdownCard
