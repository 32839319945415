import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_AMOUNT,
  INVALID_INT,
  INVALID_MIN,
  INVALID_PERCENT,
  INVALID_PERCENT_MAX
} from './constants'

export const Fields = {
  initialInvestment: 'initial_investment',
  rateOfReturn: 'rate_of_return',
  contributionAmount: 'contribution_amount',
  yearsInMarket: 'years_in_market',
  contributionFrequency: 'contribution_frequency'
}

export const ValidationSchema = Yup.object().shape({
  initial_investment: Yup.number()
    .required(REQUIRED)
    .nullable()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000000000, `${INVALID_AMOUNT}1,000,000,000,000`),
  rate_of_return: Yup.number()
    .nullable()
    .min(0, INVALID_PERCENT)
    .max(100, INVALID_PERCENT_MAX)
    .required(REQUIRED)
    .positive(INVALID_MIN),
  contribution_amount: Yup.number()
    .nullable()
    .required(REQUIRED)
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_AMOUNT)
    .max(1000000, `${INVALID_AMOUNT}1,000,000`),
  years_in_market: Yup.number()
    .nullable()
    .required(REQUIRED)
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_AMOUNT)
    .max(100, `${INVALID_AMOUNT}100`),
  contribution_frequency: Yup.string()
    .when('contribution_amount', {
      is: (amount) => amount > 0,
      then: Yup.string().required(REQUIRED)
    }),

})

export const InitialValues = {
  [Fields.initialInvestment]: '',
  [Fields.rateOfReturn]: '',
  [Fields.contributionAmount]: '',
  [Fields.yearsInMarket]: '',
  [Fields.contributionFrequency]: ''
}
