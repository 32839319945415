import { StyleSheet } from 'react-native'
import Screen from './Screen'
import BottomButton from './BottomButton'

const styles = StyleSheet.create({
  flex: { flex: 1 },
  scrollView: { paddingBottom: 120 }
})

export default function ScrollingListContainer({ children, onSelect, disabled, buttonText, style = {}, displayButton = true }) {
  return (
    <>
      <Screen style={[styles.flex, style]} safeAreaStyle={styles.flex} keyboardAvoidingViewStyles={styles.flex} scrollViewStyle={styles.scrollView}>
        {children}
      </Screen>

      { displayButton
        && (
          <BottomButton withMargin disabled={disabled} onPress={onSelect}>
            {buttonText || 'Save'}
          </BottomButton>
        )}
    </>
  )
}
