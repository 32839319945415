function calculateDecimalLength(number) {
  const [, decimal] = String(number).split('.');
  let decimalLength = decimal ? decimal.length : 0;
  if (decimalLength > 3) {
    decimalLength = 3
  }
  return decimalLength
}

export default function formatPercent(value, decimal) {
  if (value !== undefined && value !== null) {
    const decimalLength = calculateDecimalLength(value)
    const parsedValue = parseFloat(value)

    return `${(parsedValue).toFixed(decimal > -1 ? decimal : decimalLength)}%`
  }
}
