import {
  View,
  StyleSheet,
  Text,
  Platform
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import SlideupDrawer from '../SlideupDrawer'
import { darkblue } from '../../styles/colors'
import Form from '../Form'
import { Fields, ValidationSchema } from '../../schemas/customPensionSchema'
import CurrencyInput from '../CurrencyInput'
import NumberInput from '../NumberInput'

const basicStyles = {
  container: {
    paddingHorizontal: 30,
    paddingTop: 20,
    minHeight: 450
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    marginBottom: 6
  },
  label: { color: darkblue, fontSize: 16, marginLeft: 20, fontWeight: '600' }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    container: {
      minHeight: 468,
    }
  }
})

const isNotWeb = Platform.OS !== 'web'

export default function PensionForm({
  defaultValues,
  visible,
  setVisible,
  onSave
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <SlideupDrawer
      scrollable
      snapPointIndex={1}
      visible={visible}
      setVisible={setVisible}
      snapPoints={
        Platform.OS === 'android' ? ['60%', '80%', '100%'] : undefined
      }
    >
      <View style={styles.container}>
        <Text
          style={{
            color: darkblue,
            fontSize: 26,
            fontWeight: '700',
            marginBottom: 16
          }}
        >
          Customize My Pension
        </Text>

        <Form
          buttonText="Save"
          withPadding={false}
          validationSchema={ValidationSchema}
          initialValues={defaultValues}
          buttonContainerStyle={{
            paddingHorizontal: 0,
            marginHorizontal: 0,
            bottom: 0
          }}
          onSubmit={(values) => {
            onSave(values)
          }}
          noRoute
        >
          {({
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched
          }) => (
            <View style={{ height: Platform.OS === 'android' ? 340 : 300 }}>
              <NumberInput
                label="Years of Service (at Retirement)"
                name={Fields.periods}
                value={values[Fields.periods]}
                touched={touched[Fields.periods]}
                errorMessage={errors[Fields.periods]}
                onChangeText={(v) => {
                  setFieldValue(Fields.periods, v)
                }}
              />
              <CurrencyInput
                prefix="$"
                label="Estimated Salary (at Retirement)"
                value={values[Fields.salary]}
                name={Fields.salary}
                touched={touched[Fields.salary]}
                errorMessage={errors[Fields.salary]}
                onChangeText={handleChange(Fields.salary)}
              />
              <NumberInput
                label="Pension Multiplier (Percent)"
                suffixUnit="%"
                name={Fields.multiplier}
                value={values[Fields.multiplier]}
                touched={touched[Fields.multiplier]}
                errorMessage={errors[Fields.multiplier]}
                onChangeText={(v) => {
                  setFieldValue(Fields.multiplier, v)
                }}
              />
              <NumberInput
                label="Expected Years of Retirement"
                name={Fields.years_retire}
                value={values[Fields.years_retire]}
                touched={touched[Fields.years_retire]}
                errorMessage={errors[Fields.years_retire]}
                onChangeText={(v) => {
                  setFieldValue(Fields.years_retire, v)
                }}
              />
            </View>
          )}
        </Form>
      </View>
    </SlideupDrawer>
  )
}
