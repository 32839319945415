// import { get, post } from 'http'
import { get, post } from 'http'

import { setAccessToken } from './setAccessToken'
import { setLoading } from './setLoading'
import { setCurrentUser } from './setCurrentUser'
import { getDebts } from './getDebts'

import { getPlans } from './getPlans'
import { setDevice } from './setDevice'
import { setPendingInvitations } from './setPendingInvitations'
import { getWellnessChecklists } from './getWellnessChecklists'
import { getBSAccountCategories } from './getBSAccountCategories'
import { getPersonalMilestones } from './getPersonalMilestones'
import { getBudgetCategories } from './getBudgetCategories'
import { getProPlans } from './getProPlans'
import { getAppConfig } from './getAppConfig'
import { getAuthorizationCode } from './getAuthorizationCode'
import { getBSAccounts } from './getBSAccounts'
import { getFinPulsePreferences } from './getFinPulsePreferences'
import { getFinPulseStreaks } from './getFinPulseStreaks'

import { setupPinpoint } from '../hooks/usePinpoint'

// eslint-disable-next-line import/prefer-default-export
export const login = function (payload) {
  return async (dispatch) => {
    async function loginViaToken(token) {
      dispatch(setAccessToken(token))
    }

    async function loginViaEmailAndPassword(email, password) {
      const loginResponse = await post('rest-auth/login/', { password, email })
      const accessToken = loginResponse.data.key
      loginViaToken(accessToken)
      return accessToken
    }

    try {
      dispatch(setLoading({ loading: true }))

      let token = null

      if (payload.token) {
        await loginViaToken(payload.token)
        token = payload.token
      } else if (payload.email && payload.password) {
        token = await loginViaEmailAndPassword(payload.email, payload.password)
      }

      const identityResponse = await get('me/')
      const user = identityResponse.data

      dispatch(setCurrentUser(user))
      dispatch(setPendingInvitations(user.pending_invitations))

      await dispatch(getAuthorizationCode())

      const asyncFunctions = [
        dispatch(getPlans()),
        dispatch(setDevice()),
        dispatch(getWellnessChecklists()),
        dispatch(getPersonalMilestones()),
        dispatch(getDebts()),
        dispatch(getBSAccountCategories()),
        dispatch(getBudgetCategories()),
        dispatch(getProPlans()),
        dispatch(getAppConfig()),
        dispatch(getBSAccounts()),
        dispatch(getFinPulsePreferences()),
        dispatch(getFinPulseStreaks())
      ];

      setupPinpoint(user, token)

      await Promise.all(asyncFunctions)

      dispatch(setLoading({ loading: false }))
    } catch (e) {
      dispatch(setLoading({ loading: false }))
      console.warn(e, e.response, 'failed to login...')
      throw e
    }
  }
}
