import Input from '../Input'
import CurrencyInput from '../CurrencyInput'
import NumberInput from '../NumberInput'
import CheckBox from '../CheckBox'

export default function CollegeSaveForm({ Fields, touched, errors, handleChange, handleBlur, values, handleSubmit }) {
  return (
    <>
      <Input
        label="Name"
        name={Fields.name}
        touched={touched[Fields.name]}
        errorMessage={errors[Fields.name]}
        onChangeText={handleChange(Fields.name)}
        onBlur={handleBlur(Fields.name)}
        value={values[Fields.name]}
        returnKeyType="next"
      />

      <Input
        name={Fields.year}
        errorMessage={errors[Fields.year]}
        touched={touched[Fields.year]}
        onChangeText={handleChange(Fields.year)}
        onBlur={handleBlur(Fields.year)}
        value={values[Fields.year]}
        label="Years until saving"
        returnKeyType="next"
        keyboardType="number-pad"
        enablesReturnKeyAutomatically
      />

      <CurrencyInput
        prefix="$"
        label={"Today's Value"}
        value={values[Fields.currentValue]}
        name={Fields.currentValue}
        touched={touched[Fields.currentValue]}
        errorMessage={errors[Fields.currentValue]}
        onChangeText={handleChange(Fields.currentValue)}
        precision={0}
      />

      <CheckBox
        name={Fields.isRecurring}
        label="Is Recurring"
        value={values[Fields.isRecurring]}
        onToggle={() => setFieldValue(Fields.isRecurring, !values[Fields.isRecurring])}
        errorMessage={errors[Fields.isRecurring]}
        touched={touched[Fields.isRecurring]}
        checked={values[Fields.isRecurring]}
      />

      {values[Fields.isRecurring] && (
        <Input
          name={Fields.yearEnd}
          errorMessage={errors[Fields.yearEnd]}
          touched={touched[Fields.yearEnd]}
          onChangeText={handleChange(Fields.yearEnd)}
          onBlur={handleBlur(Fields.yearEnd)}
          value={values[Fields.yearEnd]}
          label="For how many years?"
          returnKeyType="next"
          keyboardType="number-pad"
          enablesReturnKeyAutomatically
        />
      )}

      <NumberInput
        precision={3}
        suffixUnit="%"
        name={Fields.rate}
        errorMessage={errors[Fields.rate]}
        touched={touched[Fields.rate]}
        onChangeText={handleChange(Fields.rate)}
        onBlur={handleBlur(Fields.rate)}
        value={values[Fields.rate]}
        label="Rate"
        returnKeyType="next"
        keyboardType="number-pad"
        onSubmitEditing={handleSubmit}
      />
    </>

  )
}
