import PropTypes from 'prop-types'

// eslint-disable-next-line import/prefer-default-export
export const ICON_TYPE = PropTypes.shape({
  icon: (
    PropTypes.string
      || PropTypes.shape([PropTypes.string, PropTypes.string])
  ).isRequired,
  style: PropTypes.object
})
