import { stateReducer } from '@truefit/redux-utils'
import { map } from 'lodash'
import { SET_PERSONAL_MILESTONES, SET_PERSONAL_MILESTONE_ITEM, CLEAR } from '../actions'

const initialState = []

export default stateReducer(initialState, {
  [SET_PERSONAL_MILESTONES]: (_, payload) => payload,
  [SET_PERSONAL_MILESTONE_ITEM]: (state, payload) => {
    map(state, (item) => {
      if (item.id == payload.id) {
        item.is_complete = payload.is_complete
      }
    })
    return [...state]
  },
  [CLEAR]: () => initialState
});
