import { View, Text, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { darkblue, DEFAULT_SPACING, gunmetal, info, primary } from '../styles'
import Link from './Link'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  container: { paddingVertical: DEFAULT_SPACING },
  iconAndLabelContainer: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  },
  leftLabel: {
    fontSize: 14,
    fontWeight: '600',
    color: 'rgb(66, 86, 103)'
  },
  rightLabel: {
    letterSpacing: -0.6,
    fontSize: 14,
    flex: 1,
    textAlign: 'right',
    fontWeight: '400'
  },
  icon: { marginRight: DEFAULT_SPACING },
  editIcon: {
    color: info,
    marginVertical: 10
  },
  textContainer: { justifyContent: 'center' },
  title: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5
  },
  touchableContainer: {
    flex: 1,
    flexDirection: 'row'
  }
})

export default function BudgetItemRow({
  route,
  params,
  title,
  subtitle,
  containerStyle = {},
  titleStyle = {},
  subtitleStyle = {},
  editable = true,
  children
}) {
  const navigation = useNavigation()
  const callRouteScreen = () => {
    navigation.navigate(
      'Modal',
      {
        screen: route,
        params: { ...{ title, ...params } }
      }
    )
  }

  return (
    <View
      style={[styles.container, containerStyle]}
    >
      <TouchableOpacity
        disabled={!route}
        onPress={callRouteScreen}
        style={styles.touchableContainer}
      >
        <View style={styles.iconAndLabelContainer}>
          <View style={styles.textContainer}>
            <Text style={[styles.title, titleStyle]}>{title}</Text>
            <Text style={[styles.subtitle, subtitleStyle]}>{subtitle}</Text>
          </View>
        </View>

        { editable
          && (
            <Link disabled iconStyle={styles.editIcon} style={styles.edit} icon="pencil" type="fal" iconSize={15}>
              Edit
            </Link>
          )}
      </TouchableOpacity>

      {children}
    </View>
  )
}
