const data = [
  {
    name: 'Vangard',
    imgUrl: require('../vanguard.png'),
    links: {
      'roth-ira': 'https://investor.vanguard.com/accounts-plans/iras/how-to-open-an-ira?WT.srch=1&cmpgn=PS:RE&gclid=CjwKCAjwr56IBhAvEiwA1fuqGo0n0fRpSR_mS5O0RZgjQWjLSCdf5in-9hbhp3OC3hAc6SCuEh0pSxoC7k0QAvD_BwE&gclsrc=aw.ds',
      trading: 'https://investor.vanguard.com/accounts-plans/individual-joint-brokerage',
      'transfer-401k': 'https://investor.vanguard.com/investor-resources-education/education/how-to-roll-over-401k',
      'transfer-ira': 'https://investor.vanguard.com/investor-resources-education/education/how-to-transfer-money',
      etf: 'https://investor.vanguard.com/etf/profile/VOO',
      'target-date': 'https://institutional.vanguard.com/investment/solutions/target-date-funds.html',
      custodial: 'https://personal.vanguard.com/us/whatweoffer/college/vanguardugmautma?lang=en&dd_pm=none&dd_pm_cat=brokerage'
    },
    mission: "At Vanguard you're more \nthan just an investor, \nyou're an owner. Vanguard isn't owned by shareholders. It's owned by the people who invest in our funds.* As an owner you have access to personalized financial advice, high-quality investments, retirement tools, and relevant market insights that help you build a future for those you love.",
    cost: 'https://personal.vanguard.com/pdf/v414.pdf?2210079054'
  },
  {
    name: 'Schwabb',
    imgUrl: require('../schwab.png'),
    links: {
      'roth-ira': 'https://www.schwab.com/ira/roth-ira',
      trading: 'https://www.schwab.com/brokerage',
      'transfer-401k': 'https://www.schwab.com/ira/rollover-ira/rollover-options',
      'transfer-ira': 'https://www.schwab.com/ira/rollover-ira/rollover-options',
      etf: 'https://www.schwab.com/research/mutual-funds/quotes/summary/swppx',
      'target-date': 'https://www.schwab.com/mutual-funds/invest-in-a-portfolio-solution',
      custodial: 'https://www.schwab.com/custodial-account'
    },
    mission: "There's more than one way to achieve your financial goals. Schwab isn't one-size-fits-all because everyone has unique financial goals. Whether you're working toward retirement, saving for college or a house, or looking to invest in the market on your own, we have investing options that meet your needs on your terms.",
    cost: 'https://www.schwab.com/pricing'
  },
  {
    name: 'Fidelity',
    imgUrl: require('../fidelity.png'),
    links: {
      'roth-ira': 'https://www.fidelity.com/retirement-ira/roth-ira',
      trading: 'https://www.fidelity.com/trading/the-fidelity-account',
      'transfer-401k': 'https://www.fidelity.com/go/401k-rollover-hub/?imm_pid=700000001752670&immid=100776&imm_eid=ep64418055203&gclid=CjwKCAjwr56IBhAvEiwA1fuqGkqsDncZJixg2itEpY3Bk4ansHL0mPdTxKfyHmrsFLqgjOP4uASE2RoCqSYQAvD_BwE&gclsrc=aw.ds',
      'transfer-ira': 'https://www.fidelity.com/retirement-ira/ira-transfer',
      etf: 'https://fundresearch.fidelity.com/mutual-funds/summary/315911750',
      'target-date': 'https://www.fidelity.com/mutual-funds/fidelity-fund-portfolios/freedom-funds',
      custodial: 'https://www.fidelity.com/open-account/custodial-account'
    },
    mission: "We believe the more we can make investing and financial planning clear and simple, the more confident you’ll be about the decisions you make. And the more you’ll get from our relationship together. Whatever you're investing for, we can help",
    cost: 'https://www.fidelity.com/why-fidelity/pricing-fees?imm_pid=700000001008518&immid=100766&imm_eid=ep29938741900&gclid=CjwKCAiAo4OQBhBBEiwA5KWu_zvtLoffgrB49RQVvkUKFEJxzGyuNaeo9Jh75rVPSYH_r8DuyXth6BoCb9wQAvD_BwE&gclsrc=aw.ds'
  }
]

export default data
