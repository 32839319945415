import { Platform, StyleSheet, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { map } from 'lodash'
import { DEFAULT_SPACING, darkgrayblue, gunmetal } from '../styles'

import HeaderLabel from './HeaderLabel'

import Dot from './Dot'

const basicStyles = {
  container: {
    paddingHorizontal: DEFAULT_SPACING,
    alignItems: 'center',
    maxWidth: '100%'
  },
  spaceAround: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: DEFAULT_SPACING
  },
  header: {
    marginTop: DEFAULT_SPACING,
    fontSize: 16
  },
  text: {
    color: gunmetal,
    fontSize: 14,
    fontWeight: '400'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      header: {
        color: darkgrayblue,
        fontSize: 24,
        fontWeight: 600,
      },
      text: {
        marginTop: 10,
        fontSize: 16,
        fontWeight: 500
      },
      spaceAround: {
        marginTop: DEFAULT_SPACING * 2,
      },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function Legend({ options, label, sublabel, style = {} }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View style={[styles.container, style]}>
      {label && <HeaderLabel style={styles.header}>{label}</HeaderLabel>}
      {sublabel && <HeaderLabel style={styles.text}>{sublabel}</HeaderLabel>}
      <View style={styles.spaceAround}>
        {map(options, (item) => <Dot {...item} />)}
      </View>
    </View>
  )
}
