import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { first } from 'lodash'
import {
  currentUserSelector,
  completedWellnessChecklistsSelector
} from './selectors'

import IntroScreen from './components/IntroScreen'

export default function WellnessChecklistIntroScreen({ navigation }) {
  const currentUser = useSelector((state) => currentUserSelector(state))
  const completedWellnessChecklistItems = useSelector((state) => completedWellnessChecklistsSelector(state))
  const firstName = first(currentUser.full_name.split(' '))

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  const hasAnyWellnessChecklistItems = completedWellnessChecklistItems.length > 0
  const title = hasAnyWellnessChecklistItems ? `Hey ${firstName}, Welcome back!`
    : `Hey ${firstName}, let's get started!`
  const subtitle = hasAnyWellnessChecklistItems ? 'What\'s new? Update your checklist to track your progress.'
    : 'Remove some uncertainty in life to see how prepared you are for the unexpected. This can be a living and working checklist to keep your mind at ease and prepare for your financial future.'

  return (
    <IntroScreen
      title={title}
      subtitle={subtitle}
      image={require('./assets/wellness-check.png')}
      buttonText="Get Started"
      onPress={() => {
        navigation.navigate('Modal', { screen: 'Wellness Checklist Toolkit' })
      }}
    />
  )
}
