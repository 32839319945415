import * as Yup from 'yup'

import { REQUIRED, INVALID_EMAIL } from './constants'

export const Fields = { email: 'email' }

export const ValidationSchema = Yup.object().shape({
  [Fields.email]: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED)
})

export const InitialValues = { [Fields.email]: '', }
