import { StyleSheet, View, Text } from 'react-native'
import { useDispatch } from 'react-redux'
import CircleIcon from './components/CircleIcon'

import Container from './components/Container'
import Header from './components/Header'
import { darkblue, DEFAULT_SPACING, rgba, white } from './styles'

import VacationForm from './components/lifeEvent/VacationForm'
import DonationForm from './components/lifeEvent/DonationForm'
import ExpendForm from './components/lifeEvent/ExpendForm'
import WindfallForm from './components/lifeEvent/WindfallForm'
import BuyCarForm from './components/lifeEvent/BuyCarForm'
import HouseBuyForm from './components/lifeEvent/HouseBuyForm'
import HouseSellForm from './components/lifeEvent/HouseSellForm'
import WeddingForm from './components/lifeEvent/WeddingForm'
import WeddingKidForm from './components/lifeEvent/WeddingKidForm'
import CollegeSaveForm from './components/lifeEvent/CollegeSaveForm'
import CollegePayForm from './components/lifeEvent/CollegePayForm'
import CollegeKidPayForm from './components/lifeEvent/CollegeKidPayForm'
import PetForm from './components/lifeEvent/PetForm'

import {
  VACATION,
  DONATION,
  EXPEND,
  WINDFALL,
  BUY_CAR,
  HOME_BUY,
  HOME_SELL,
  WEDDING,
  WEDDING_KID,
  COLLEGE_SAVE,
  COLLEGE_PAY,
  COLLEGE_KID_PAY,
  PET
} from './constants'

import Form from './components/Form'

import {
  ValidationSchema as VacationSchema,
  InitialValues as VacationInitialValues,
  Fields as VacationFields
} from './schemas/lifeEvents/VacationSchema'

import {
  ValidationSchema as DonationSchema,
  InitialValues as DonationInitialValues,
  Fields as DonationFields
} from './schemas/lifeEvents/DonationSchema'

import {
  ValidationSchema as ExpendSchema,
  InitialValues as ExpendInitialValues,
  Fields as ExpendFields
} from './schemas/lifeEvents/ExpendSchema'

import {
  ValidationSchema as WindfallSchema,
  InitialValues as WindfallInitialValues,
  Fields as WindfallFields
} from './schemas/lifeEvents/WindfallSchema'

import {
  ValidationSchema as BuyCarSchema,
  InitialValues as BuyCarInitialValues,
  Fields as BuyCarFields
} from './schemas/lifeEvents/BuyACarSchema'

import {
  ValidationSchema as HouseBuySchema,
  InitialValues as HouseBuyInitialValues,
  Fields as HouseBuyFields
} from './schemas/lifeEvents/BuyAHouseSchema'

import {
  ValidationSchema as HouseSellSchema,
  InitialValues as HouseSellInitialValues,
  Fields as HouseSellFields
} from './schemas/lifeEvents/SellAHouseSchema'

import {
  ValidationSchema as WeddingSchema,
  InitialValues as WeddingInitialValues,
  Fields as WeddingFields
} from './schemas/lifeEvents/WeddingSchema'

import {
  ValidationSchema as WeddingKidSchema,
  InitialValues as WeddingKidInitialValues,
  Fields as WeddingKidFields
} from './schemas/lifeEvents/WeddingKidSchema'

import {
  ValidationSchema as CollegeSaveSchema,
  InitialValues as CollegeSaveInitialValues,
  Fields as CollegeSaveFields
} from './schemas/lifeEvents/CollegeSavingsSchema'

import {
  ValidationSchema as CollegePaySchema,
  InitialValues as CollegePayInitialValues,
  Fields as CollegePayFields
} from './schemas/lifeEvents/CollegePaymentSchema'

import {
  ValidationSchema as CollegeKidPaySchema,
  InitialValues as CollegeKidPayInitialValues,
  Fields as CollegeKidPayFields
} from './schemas/lifeEvents/CollegeKidPaymentSchema'

import {
  ValidationSchema as PetSchema,
  InitialValues as PetInitialValues,
  Fields as PetFields
} from './schemas/lifeEvents/PetSchema'

import {
  saveLifeEvent,
  deleteLifeEvent
} from './actions'

const styles = StyleSheet.create({
  container: { backgroundColor: white },
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: DEFAULT_SPACING
  },
  circleText: {
    fontSize: 18,
    color: darkblue,
    fontWeight: '600',
    marginTop: DEFAULT_SPACING
  }
})

const FORMS = {
  [VACATION]: VacationForm,
  [DONATION]: DonationForm,
  [EXPEND]: ExpendForm,
  [WINDFALL]: WindfallForm,
  [BUY_CAR]: BuyCarForm,
  [HOME_BUY]: HouseBuyForm,
  [HOME_SELL]: HouseSellForm,
  [WEDDING]: WeddingForm,
  [WEDDING_KID]: WeddingKidForm,
  [COLLEGE_SAVE]: CollegeSaveForm,
  [COLLEGE_PAY]: CollegePayForm,
  [COLLEGE_KID_PAY]: CollegeKidPayForm,
  [PET]: PetForm
}

const SCHEMAS = {
  [VACATION]: VacationSchema,
  [DONATION]: DonationSchema,
  [EXPEND]: ExpendSchema,
  [WINDFALL]: WindfallSchema,
  [BUY_CAR]: BuyCarSchema,
  [HOME_BUY]: HouseBuySchema,
  [HOME_SELL]: HouseSellSchema,
  [WEDDING]: WeddingSchema,
  [WEDDING_KID]: WeddingKidSchema,
  [COLLEGE_SAVE]: CollegeSaveSchema,
  [COLLEGE_PAY]: CollegePaySchema,
  [COLLEGE_KID_PAY]: CollegeKidPaySchema,
  [PET]: PetSchema
}

const INITIAL_VALUES = {
  [VACATION]: VacationInitialValues,
  [DONATION]: DonationInitialValues,
  [EXPEND]: ExpendInitialValues,
  [WINDFALL]: WindfallInitialValues,
  [BUY_CAR]: BuyCarInitialValues,
  [HOME_BUY]: HouseBuyInitialValues,
  [HOME_SELL]: HouseSellInitialValues,
  [WEDDING]: WeddingInitialValues,
  [WEDDING_KID]: WeddingKidInitialValues,
  [COLLEGE_SAVE]: CollegeSaveInitialValues,
  [COLLEGE_PAY]: CollegePayInitialValues,
  [COLLEGE_KID_PAY]: CollegeKidPayInitialValues,
  [PET]: PetInitialValues
}

const FIELDS = {
  [VACATION]: VacationFields,
  [DONATION]: DonationFields,
  [EXPEND]: ExpendFields,
  [WINDFALL]: WindfallFields,
  [BUY_CAR]: BuyCarFields,
  [HOME_BUY]: HouseBuyFields,
  [HOME_SELL]: HouseSellFields,
  [WEDDING]: WeddingFields,
  [WEDDING_KID]: WeddingKidFields,
  [COLLEGE_SAVE]: CollegeSaveFields,
  [COLLEGE_PAY]: CollegePayFields,
  [COLLEGE_KID_PAY]: CollegeKidPayFields,
  [PET]: PetFields
}

export default function AddLifeEventScreen({ navigation, route }) {
  const { params } = route
  const {
    type,
    label,
    icon,
    color,
    plan,
    id,
    meta_data,
    current_value,
    year,
    name,
    key
  } = params

  const SelectedForm = FORMS[type]
  const SelectedSchema = SCHEMAS[type]
  const SelectedInitialValues = INITIAL_VALUES[type]
  const SelectedFields = FIELDS[type]

  const event = {
    id,
    type,
    plan,
    meta_data,
    current_value,
    year,
    name,
    key
  }

  const initialValues = event.id ? event : SelectedInitialValues
  initialValues[SelectedFields.periods] = plan.periods

  const dispatch = useDispatch()

  async function onNegativeButtonPress() {
    await dispatch(deleteLifeEvent(event, plan.id))
    navigation.popToTop()
  }

  return (
    <Container style={styles.container}>
      <Header
        text="Add the details for your event"
      />

      <Form
        validationSchema={SelectedSchema}
        withPadding
        initialValues={initialValues}
        buttonText={event.id ? 'Update Event' : 'Add Event'}
        negativeButtonText={event.id ? 'Remove Event' : null}
        onNegativeButtonPress={event.id ? onNegativeButtonPress : null}
        onSubmit={async (values) => {
          await dispatch(saveLifeEvent(values, plan.id))
          navigation.popToTop()
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            <View style={styles.circleContainer}>
              <CircleIcon
                icon={icon}
                color={color}
                backgroundColor={rgba(color, 0.1)}
                size={40}
              />
              <Text style={styles.circleText}>{label}</Text>
            </View>

            <SelectedForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              Fields={SelectedFields}
            />
          </>
        )}
      </Form>
    </Container>
  )
}
