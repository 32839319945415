import axios from 'axios'
import { BASE_URL, BENCHMARK } from '@env'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { getAccessToken } from '../actions/getAccessToken'
import { AUTHORIZATION_CODE_LOCAL_STORAGE_KEY } from '../actions/getAuthorizationCode'

console.log('BASE_URL', BASE_URL)

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  withCredentials: false
})

instance.interceptors.request.use(
  async (config) => {
    const token = await getAccessToken()
    const unparsedTokens = await AsyncStorage.getItem(
      AUTHORIZATION_CODE_LOCAL_STORAGE_KEY
    )
    const tokens = unparsedTokens ? JSON.parse(unparsedTokens) : {}
    const { code } = tokens

    if (token) {
      config.headers.Authorization = `Token ${token}`
    } else {
      delete config.headers.Authorization
    }

    if (code) {
      config.headers['x-troutwood-token'] = code
    } else {
      delete config.headers['x-troutwood-token']
    }

    config.metadata = { startTime: new Date() }

    return config
  },
  (error) => Promise.reject(error)
)

if (BENCHMARK == 'true') {
  instance.interceptors.response.use(
    (response) => {
      // Set the end time for the request and calculate the duration
      response.config.metadata.endTime = new Date()
      response.duration =
        response.config.metadata.endTime - response.config.metadata.startTime

      // Log the request URL and time taken
      console.log(
        `Request to ${response.config.url} took ${response.duration} ms`
      )

      return response
    },
    (error) => Promise.reject(error)
  )
}

export const { get } = instance
export const { post } = instance
export const { patch } = instance
export const { put } = instance
export const del = instance.delete
