import { Platform, Text, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import {
  currentProPlanSelector
} from './selectors'

import ProPlanSummaryScreen from './ProPlanSummaryScreen'
import FreePlanSummaryScreen from './FreePlanSummaryScreen'
import { useEffect } from 'react'
import { setCurrentProPlan, setCurrentPlan } from './actions'
import Sidebar from './components/Sidebar'

const isNotWeb = Platform.OS !== 'web'

export default function PlanSummaryScreen({ navigation, route }) {
  const currentProPlan = useSelector(currentProPlanSelector)
  const isPro = !isEmpty(currentProPlan)
  const dispatch = useDispatch()

  useEffect(() => {
    // when a user leaves the plan summary screen, 
    // we should set the current plan and current pro plan to null
    return () => {
      dispatch(setCurrentPlan({}))
      dispatch(setCurrentProPlan({}))
    }
  }, [])

  const SummaryContent = isPro ? ProPlanSummaryScreen : FreePlanSummaryScreen
  const props = { navigation, route }

  return (<>
    {isNotWeb ? (
      <SummaryContent {...props} />) : (
      <View style={{flexDirection: 'row', height: '100%'}}>
        <Sidebar navigation={navigation} />
        <SummaryContent {...props} />
      </View>
    )}
  </>)
}
