import { View, Text, StyleSheet } from 'react-native'
import { white, info, DEFAULT_SPACING, gunmetal, darkblue, lightestgray } from './styles'
import PulseCircle from './components/PulseCircle'
import HeaderLabel from './components/HeaderLabel'
import Form from './components/Form'
import Switch from './components/Switch'
import InputSelect from './components/InputSelect'

import { useDispatch, useSelector } from 'react-redux'
import { finPulseSelector } from './selectors'
import { 
  Fields,
  ValidationSchema,
  InitialValues
} from './schemas/finPulsePreferencesSchema'
import { saveFinPulsePreferences } from './actions'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white
  },
  pulseCircleContainer: {
    paddingVertical: DEFAULT_SPACING * 2,  
  },   
  form: {
    paddingHorizontal: DEFAULT_SPACING,
  },  
  header: {
    fontWeight: '600',
    fontSize: 24,
    color: darkblue,
    textAlign: 'center',
    marginTop: DEFAULT_SPACING * 2,
    marginBottom: DEFAULT_SPACING
  },
  text: {
    fontSize: '500',
    fontSize: 16,
    color: gunmetal,
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING,
    letterSpacing: -0.66,
    lineHeight: 24
  },
})

export default function FinPulseSettingsScreen({ navigation }) {
  const optionsForSelect = [
    { label: '', value: null },
    { label: 'Monthly', value: 'MONTHLY' },
    { label: 'Bi-Monthly', value: 'BI_MONTHLY' }
  ]

  const finPulse = useSelector(finPulseSelector)
  const dispatch = useDispatch()

  return (
    <View style={styles.container}>
      <Form 
        withPadding={false}
        initialValues={finPulse.enabled ? finPulse : InitialValues}
        onSubmit={(values) => {
          try {
            dispatch(saveFinPulsePreferences(values))
            navigation.pop()
          } catch (err) {
            console.warn(err)
          }
        }}
        validationSchema={ValidationSchema}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <View style={{flex: 1}}>
            <View style={styles.pulseCircleContainer}>
              <PulseCircle 
                size={180}
                scaleEnd={1.2}
                color={values[Fields.enabled] ? info : lightestgray}
                iconSize={80}
                iconColor={white}
                icon={'wave-pulse'}
                style={styles.pulseCircle} 
                disabled={!values[Fields.enabled]}
              />

              <View>
                <HeaderLabel style={styles.header}>
                  FinPulse
                </HeaderLabel>

                <Text style={styles.text}>
                  Your FinPulse score is calculated by tracking progress towards 
                  your long term savings goal, and answers the question “Am I on track?”.
                </Text>
              </View>

              <View style={styles.form}>
                <Switch
                  value={values[Fields.enabled]}
                  onPress={(val) => {
                    setFieldValue(Fields.enabled, val)
                  }}
                  label={'Enable FinPulse'}
                />   

                <InputSelect 
                  placeholder="--"
                  label="Reminder Frequency"
                  value={values[Fields.periodicity]}
                  touched={touched[Fields.periodicity]}
                  errorMessage={errors[Fields.periodicity]}
                  options={optionsForSelect}
                  onChangeText={(evt) => {
                    setFieldValue(Fields.periodicity, evt.target.value)
                  }}
                  onSubmitEditing={(value) => {
                    setFieldValue(Fields.periodicity, value)
                  }}            
                />
              </View>
            </View>
          </View>
        )}
      </Form>
    </View>
  )
}
