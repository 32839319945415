import { Platform } from 'react-native'
import Container from './components/Container'
import Success from './components/teamOnboarding/Success'

export default function JoinTeamSuccessScreen({ navigation, route }) {

  return (
    <Container style={{ marginTop: Platform.OS !== 'web' ? 0 : 'auto', justifyContent: 'center' }}>
      <Success navigation={navigation} invitation={route.params.invitation} />
    </Container>
  )
}
