import { post } from 'http'
import { each } from 'lodash'

function isNumber(value) {
  return typeof value === 'number' && isFinite(value)
}

function formattedPayload(payload) {
  const returnPayload = {
    ...payload,
    asset: payload.type == 'home' ? 'house' : payload.type,
    investment_return_rate: 9,
  }

  const asset_a = {}
  const asset_b = {}

  each(Object.keys(payload), (key) => {
    if (key.includes('asset_a')) {
      asset_a[key.replace('asset_a_', '')] = isNumber(Number(payload[key])) ? Number(payload[key]) : payload[key]
      delete returnPayload[key]
    } else if (key.includes('asset_b')) {
      asset_b[key.replace('asset_b_', '')] = isNumber(Number(payload[key])) ? Number(payload[key]) : payload[key]
      delete returnPayload[key]
    }
  })

  if (Object.keys(asset_a).length > 0) {
    returnPayload.asset_a = asset_a
  }
  if (Object.keys(asset_b).length > 0) {
    returnPayload.asset_b = asset_b
  }

  delete returnPayload.type

  return returnPayload
}

export async function opportunityCostCalculator(payload) {
  try {
    const newPayload = formattedPayload(payload)
    const result = await post('calculator/opportunity-cost/', newPayload)

    const asssetA = { ...newPayload.asset_a, ...result.data.asset_a }
    const asssetB = { ...newPayload.asset_b, ...result.data.asset_b }

    const { data } = result

    data.asset_a = asssetA
    data.asset_b = asssetB

    if (result) {
      return { ...data }
    }
    return null
  } catch (e) {
    console.warn(e, e.response)
  }
}
