import { stateReducer } from '@truefit/redux-utils'
import { ADD_BRANCH_EVENT, CLEAR_BRANCH_EVENT, CLEAR } from '../actions'

const initialState = []

export default stateReducer(initialState, {
  [ADD_BRANCH_EVENT]: (state, payload) => {
    const newState = [...state, payload]
    return newState.filter((item, index) => newState.indexOf(item) === index)
  },
  [CLEAR_BRANCH_EVENT]: (state, payload) => state.filter((event) => event !== payload),
  [CLEAR]: () => initialState
});
