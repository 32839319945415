import { post } from 'http'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_REJECTED = `${FORGOT_PASSWORD}_REJECTED`;
export const FORGOT_PASSWORD_PENDING = `${FORGOT_PASSWORD}_PENDING`;
export const FORGOT_PASSWORD_FULFILLED = `${FORGOT_PASSWORD}_FULFILLED`;

export function forgotPassword(model) {
  return {
    type: FORGOT_PASSWORD,
    payload: post('authentication/forgot-password', model),
  };
}
