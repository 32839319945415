import { View, Text, StyleSheet } from 'react-native'
import { isNull } from 'lodash'
import InputSelect from 'components/InputSelect'
import Input from 'components/Input'
import NumberInput from 'components/NumberInput'
import ButtonGroup from 'components/ButtonGroup'
import Divider from 'components/Divider'
import DateTimePicker from 'components/DateTimePicker'
import CurrencyInput from 'components/CurrencyInput'
import { lightestgray, red, white, DEFAULT_SPACING } from 'styles'
import {
  DurationValues,
  TermPreferenceValues,
  AMORTIZATION_TYPE_REVOLVING_LOAN,
  AMORTIZATION_TYPE_TERM_LOAN,
  RevolvingOptions
} from 'schemas/balanceSheetSchema'
import { ASSET, LIABILITY } from 'constants'

const styles = StyleSheet.create({
  container: { backgroundColor: white },
  contentContainer: {
    height: '100%',
    justifyContent: 'space-between'
  },
  footerContainer: { marginBottom: 20 },
  body: {
    borderTopWidth: 1,
    borderColor: lightestgray,
    justifyContent: 'space-evenly'
  },
  accountDurationLabel: {
    textDecorationLine: 'underline',
    marginBottom: 8
  },
  accountDurationContainer: { marginBottom: 20 },
  asterisk: { color: red },
  modalContainer: {
    paddingHorizontal: DEFAULT_SPACING
  }
})

export default function BSAccountForm({
  values,
  Fields,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  allCategories,
  applicableCategories,
  accountType,
  labelsPack
}) {
  return (
    <View>
      <InputSelect
        label={labelsPack.categorySelectorLabel}
        options={['', ...applicableCategories]}
        value={values[Fields.categoryId]}
        touched={touched[Fields.categoryId]}
        errorMessage={errors[Fields.categoryId]}
        onSubmitEditing={(v) => {
          if (!v) return

          setFieldValue(Fields.categoryId, v)

          setFieldValue(
            Fields.amortizationType,
            RevolvingOptions.includes(v)
              ? AMORTIZATION_TYPE_REVOLVING_LOAN
              : AMORTIZATION_TYPE_TERM_LOAN
          )

          const category = allCategories.find((c) => c.key === v)

          if (category) {
            if (!isNull(category.defaultRate) && category.defaultRate > -1)
              setFieldValue(
                Fields.rateOfReturn,
                category.defaultRate.toFixed(3)
              )
            if (category.amortizationTermMonths)
              setFieldValue(Fields.termMonths, category.amortizationTermMonths)
            if (category.liquidityType)
              setFieldValue(
                Fields.duration,
                category.liquidityType
                  .toLowerCase()
                  .replace('_', ' ')
                  .replace(/\b[a-z]/g, (c) => c.toUpperCase())
              )
          }
        }}
      />
      {values[Fields.categoryId] && (
        <View className="detailed-inputs" style={styles.body}>
          <Input
            label={
              <Text>
                Name
                <Text style={styles.asterisk}>*</Text>
              </Text>
            }
            name={Fields.name}
            value={values[Fields.name]}
            touched={touched[Fields.name]}
            errorMessage={errors[Fields.name]}
            onChangeText={handleChange(Fields.name)}
          />

          <View style={styles.accountDurationContainer}>
            <Text style={styles.accountDurationLabel}>
              {labelsPack.accountDurationLabel}
              <Text style={styles.asterisk}>*</Text>
            </Text>
            <ButtonGroup
              options={DurationValues}
              selected={values[Fields.duration]}
              onPress={(v) => {
                setFieldValue(Fields.duration, v)
              }}
            />
          </View>

          <CurrencyInput
            label={
              <Text>
                {accountType === ASSET ? 'Current Value' : 'Current Balance'}
                <Text style={styles.asterisk}>*</Text>
              </Text>
            }
            name={Fields.value}
            value={values[Fields.value]}
            touched={touched[Fields.value]}
            errorMessage={errors[Fields.value]}
            onChangeText={handleChange(Fields.value)}
            precision={2}
          />

          <NumberInput
            label={
              <Text>
                {accountType === ASSET
                  ? 'Rate of Return (assumed)'
                  : 'Interest Rate (assumed)'}
                <Text style={styles.asterisk}>*</Text>
              </Text>
            }
            precision={3}
            suffixUnit="%"
            name={Fields.rateOfReturn}
            value={values[Fields.rateOfReturn]}
            touched={touched[Fields.rateOfReturn]}
            errorMessage={errors[Fields.rateOfReturn]}
            onChangeText={handleChange(Fields.rateOfReturn)}
            allowNegative={accountType === ASSET}
          />

          {values && values[Fields.categoryId] && accountType === LIABILITY && (
            <View>
              <Divider style={styles.divider} />

              <Text style={{ marginVertical: 20 }}>
                Unlock the full power of PRO by entering some additional details
                to gain insights and Troutwood's Debt Optimization Tool.
              </Text>
            </View>
          )}

          {values &&
            values[Fields.categoryId] &&
            !RevolvingOptions.includes(values[Fields.categoryId]) &&
            accountType === LIABILITY && (
              <View>
                <CurrencyInput
                  label={
                    <Text>
                      Original Loan Amount
                      <Text style={styles.asterisk}>*</Text>
                    </Text>
                  }
                  name={Fields.termOriginalLoanAmount}
                  value={values[Fields.termOriginalLoanAmount]}
                  touched={touched[Fields.termOriginalLoanAmount]}
                  errorMessage={errors[Fields.termOriginalLoanAmount]}
                  onChangeText={handleChange(Fields.termOriginalLoanAmount)}
                  precision={2}
                />

                <NumberInput
                  label={
                    <Text>
                      Term (Months)
                      <Text style={styles.asterisk}>*</Text>
                    </Text>
                  }
                  name={Fields.termMonths}
                  value={values[Fields.termMonths]}
                  touched={touched[Fields.termMonths]}
                  errorMessage={errors[Fields.termMonths]}
                  onChangeText={handleChange(Fields.termMonths)}
                  allowNegative={false}
                />

                <DateTimePicker
                  label={
                    <Text>
                      End Date
                      <Text style={styles.asterisk}>*</Text>
                    </Text>
                  }
                  value={values[Fields.termEndDate]}
                  errorMessage={errors[Fields.termEndDate]}
                  touched={touched[Fields.termEndDate]}
                  onChangeText={handleChange(Fields.termEndDate)}
                  onBlur={handleBlur(Fields.termEndDate)}
                  onChange={(date) => {
                    setFieldValue(Fields.termEndDate, date)
                  }}
                />

                <Text style={{ marginVertical: 20 }}>
                  If extra payments are made, will the term of the loan become
                  shorter, or will the payments decrease in size?
                </Text>

                <InputSelect
                  label={
                    <Text>
                      Extra Payments
                      <Text style={styles.asterisk}>*</Text>
                    </Text>
                  }
                  options={[
                    '',
                    ...TermPreferenceValues.map((v) => ({
                      value: v,
                      label: v
                        .replace('_', ' ')
                        .toLowerCase()
                        .replace(/\b[a-z]/g, (c) => c.toUpperCase())
                    }))
                  ]}
                  value={values[Fields.termExtraPaymentPreference]}
                  touched={touched[Fields.termExtraPaymentPreference]}
                  errorMessage={errors[Fields.termExtraPaymentPreference]}
                  onSubmitEditing={(v) =>
                    setFieldValue(Fields.termExtraPaymentPreference, v)
                  }
                />
              </View>
            )}

          {values &&
            values[Fields.categoryId] &&
            RevolvingOptions.includes(values[Fields.categoryId]) &&
            accountType === LIABILITY && (
              <View>
                <CurrencyInput
                  label={
                    <Text>
                      Minimum Monthly Payment ($)
                      <Text style={styles.asterisk}>*</Text>
                    </Text>
                  }
                  name={Fields.revolvingMinPaymentAmount}
                  value={values[Fields.revolvingMinPaymentAmount]}
                  touched={touched[Fields.revolvingMinPaymentAmount]}
                  errorMessage={errors[Fields.revolvingMinPaymentAmount]}
                  onChangeText={handleChange(Fields.revolvingMinPaymentAmount)}
                  precision={2}
                />

                <NumberInput
                  label={
                    <Text>
                      Minimum Monthly Payment (%)
                      <Text style={styles.asterisk}>*</Text>
                    </Text>
                  }
                  precision={3}
                  suffixUnit="%"
                  name={Fields.revolvingMinPaymentPct}
                  value={values[Fields.revolvingMinPaymentPct]}
                  touched={touched[Fields.revolvingMinPaymentPct]}
                  errorMessage={errors[Fields.revolvingMinPaymentPct]}
                  onChangeText={handleChange(Fields.revolvingMinPaymentPct)}
                  allowNegative={false}
                />

                <NumberInput
                  label={'What day of the month is the payment made?'}
                  name={Fields.revolvingPaymentDayOfMonth}
                  value={values[Fields.revolvingPaymentDayOfMonth]}
                  touched={touched[Fields.revolvingPaymentDayOfMonth]}
                  errorMessage={errors[Fields.revolvingPaymentDayOfMonth]}
                  onChangeText={handleChange(Fields.revolvingPaymentDayOfMonth)}
                  allowNegative={false}
                />
              </View>
            )}
        </View>
      )}
    </View>
  )
}
