import { get } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { setFinPulseStreaks } from './setFinPulseStreaks'
import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export const getFinPulseStreaks = () => async (dispatch) => {
  try {
    const startDate = moment().subtract(2, 'year').startOf('month').format('YYYY-MM')
    const endDate = moment().endOf('month').format('YYYY-MM')
    const response = await get(proApplicationEndpoints.getFinPulseStreaks(startDate, endDate))
    const data = response.data.filter(streak => streak.contributionCount > 0)
    dispatch(setFinPulseStreaks(data))
  } catch (e) {
    console.warn(e, e.response)
  }
}
