import { useState } from 'react'
import { View, Text, StyleSheet, Image } from 'react-native'
import { map } from 'lodash'
import ShareTroutwoodImage from './components/ShareTroutwoodImage'
import { DEFAULT_SPACING, darkblue, gunmetal } from './styles'
import Container from './components/Container'
import ListOptionItem from './components/ListOptionItem'
import ShareButton from './components/makeAnImpact/ShareButton'

const styles = StyleSheet.create({
  container: { alignItems: 'center', },
  header: {
    fontSize: 28,
    fontWeight: 'bold',
    color: darkblue,
    marginBottom: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING * 2
  },
  subheader: {
    fontSize: 14,
    color: gunmetal,
    marginBottom: DEFAULT_SPACING / 2
  },
  troutwood: {
    width: 90,
    height: 18,
    marginHorizontal: 4
  },
  subheaderContainer: { flexDirection: 'row' },
  questionsHeader: {
    fontSize: 16,
    marginTop: DEFAULT_SPACING * 4,
    marginBottom: DEFAULT_SPACING,
  },
  question: {
    fontSize: 14,
    letterSpacing: -0.6,
    fontWeight: '500'
  },
  questionsContainer: {
    width: '100%',
    paddingHorizontal: DEFAULT_SPACING,
  }
})

export default function MakeAnImpactScreen({ navigation }) {
  const questions = [
    'The journey to a million dollars begins with the first dollar.',
    'Dream it. Build it. Do it. Start your financial plan today.',
    'Free your mind from financial stress with a financial plan.'
  ]

  const [selected, setSelected] = useState(questions[0])

  return (
    <Container style={styles.container}>
      <ShareTroutwoodImage />
      <Text style={styles.header}>Make an Impact</Text>
      <View style={styles.subheaderContainer}>
        <Text style={styles.subheader}>Share</Text>
        <Image
          style={styles.troutwood}
          source={require('./assets/troutwood-alt.png')}
          resizeMode="contain"
        />
        <Text style={styles.subheader}>with friends and family</Text>
      </View>
      <Text style={styles.subheader}>to help empower them on their financial journey.</Text>

      <View style={styles.questionsContainer}>
        <Text style={styles.questionsHeader}>Choose an encouraging message</Text>
        {map(questions, (question, index) => (
          <ListOptionItem
            key={index}
            label={question}
            labelStyle={styles.question}
            checked={selected === question}
            numberOfLines={3}
            onCheck={() => {
              setSelected(question)
            }}
          />
        ))}
      </View>

      <ShareButton selected={selected} />
    </Container>
  )
}
