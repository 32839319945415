import { stateReducer } from '@truefit/redux-utils'
import { filter } from 'lodash'
import { SET_PLANS, DELETE_PLAN, CLEAR } from '../actions'

const initialState = []

export default stateReducer(initialState, {
  [SET_PLANS]: (_, payload) => payload,
  [DELETE_PLAN]: (state, payload) => filter(state, (plan) => plan.id != payload.id),
  [CLEAR]: () => initialState
});
