import { View } from 'react-native'
import PropTypes from 'prop-types'
import Input from './Input'
import { DEFAULT_SPACING, white } from '../styles'

export default function SearchInput({
  ref,
  autoFocus = false,
  includeMargin = false,
  inverse = false,
  style,
  value,
  placeholder,
  onChange
}) {
  const inputStyle = []

  if (includeMargin) {
    inputStyle.push({ margin: DEFAULT_SPACING })
  }
  if (inverse) {
    inputStyle.push({ backgroundColor: white })
  }

  if (style) {
    inputStyle.push(style)
  }

  return (
    /** The View is required because Input is a flex: 1 component.
     * If we don't encompass it into a View it will overflow unpredictably
     * */
    <View>
      <Input
        style={inputStyle}
        placeholder={placeholder}
        autoFocus={autoFocus}
        clearable
        icon="magnifying-glass"
        value={value}
        setValue={onChange}
        ref={ref}
      />
    </View>
  )
}

SearchInput.defaultProps = {
  ref: undefined,
  includeMargin: false,
  inverse: false,
  style: {},
  placeholder: undefined,
}

SearchInput.propTypes = {
  ref: PropTypes.any,
  includeMargin: PropTypes.bool,
  inverse: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired
}
