import { useRef, useEffect } from 'react'
import { StyleSheet, Platform, View, Text, Image } from 'react-native'
import Modal from 'components/Modal'
import ConfettiCannon from 'react-native-confetti-cannon'
import FadeInView from 'components/FadeInView'
import HeaderLabel from 'components/HeaderLabel'
import { DEFAULT_SPACING } from 'styles'


const styles = StyleSheet.create({
  finn: {
    width: 300, 
    height: 300
  },
  modal: {
    width: '100%',
    padding: 0,
    margin: 0,
  },
  modalContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
    paddingHorizontal: 0
  },
  innerContainer: {
    flex: 1, 
    height: '100%', 
    width: '100%',
    paddingHorizontal: 0,
    paddingVertical: 0
  },
  tall: {
    height: '100%'
  },
  fadeInView: {
    position: 'absolute', 
    left: 0, 
    right: 0
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContainer: {
    marginVertical: DEFAULT_SPACING * 6,
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%'
  },
  header: { 
    fontSize: 28,
    marginBottom: DEFAULT_SPACING,
    textAlign: 'center'
  },
  subheader: {
    fontSize: 16,
    textAlign: 'center'
  }
})

export default function CongratsFinPulseModal({ visible, setVisible }) {
  const isWeb = Platform.OS === 'web'  
  const explosion = useRef()

  useEffect(() => {
    if (!isWeb) explosion?.current?.start()

    setTimeout(() => {
      setVisible(false)
    }, 5000)
  }, [visible])

  return (
    <Modal 
      width={'100%'}
      style={styles.modal} 
      containerStyle={styles.modalContainer}
      innerContainerStyle={styles.innerContainer}
      scrollEnabled={false} 
      visible={visible} 
      setVisible={setVisible}
      tint={'light'}
    >
      <View style={styles.tall}>

      { !isWeb && (
        <FadeInView style={[styles.tall, styles.fadeInView]}>
          <ConfettiCannon
            count={200}
            explosionSpeed={0}
            origin={{ x: 0, y: 0 }}
            autoStart
            ref={explosion}
            fadeOut
          />      
        </FadeInView>
        )}

        <View style={styles.container}>
          <Image 
            source={require('../../assets/finn/party.png')}
            resizeMode='contain'
            style={styles.finn}
          />

          <View style={styles.textContainer}>
            <HeaderLabel style={styles.header}>
              Keep up the great work! 
            </HeaderLabel>

            <Text style={styles.subheader}>
              Consistency is a major factor of hitting your goal. Keep it up!
            </Text>
          </View>
        </View>
      </View>
    </Modal>
  )
}
