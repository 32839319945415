import { useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'

import { useDispatch } from 'react-redux'
import { isNull, map } from 'lodash'
import CurrencyInput from '../CurrencyInput'
import ListOptionItem from '../ListOptionItem'

import Form from '../Form'

import timeout from '../../functions/timeout'

import { saveCurrentPlan } from '../../actions'

import {
  ValidationSchema,
  Fields,
  InitialValues
} from '../../schemas/medicalInsuranceSchema'

const styles = StyleSheet.create({ container: { flex: 1 } })

const CUSTOM = 'CUSTOM'
const BRONZE = 'BRONZE'
const SILVER = 'SILVER'
const SPONSOR = 'SPONSOR'

const OPTIONS = [
  { label: 'ACA Bronze', value: BRONZE },
  { label: 'ACA Silver', value: SILVER, tooltip: 'As part of the Affordable Care Act (ACA), Silver plans on average turn out to be the lowest-overall-cost option for many people, covering approximately 70% of the cost of care.' },
  { label: 'Employer Sponsored', value: SPONSOR },
  { label: 'Custom', value: CUSTOM }
]

export default function MedicalInsuranceForm({ route, navigation }) {
  const dispatch = useDispatch()

  const { title, custom_healthcare_cost, healthcare_selection } = route.params

  useEffect(() => {
    navigation.setOptions({ title })
  })

  return (
    <View style={styles.container}>
      <Form
        validationSchema={ValidationSchema}
        initialValues={
          custom_healthcare_cost && custom_healthcare_cost > -1
            ? { custom_healthcare_cost, healthcare_selection: CUSTOM } : ({ ...InitialValues, healthcare_selection })
        }
        onSubmit={async (values) => {
          await timeout(600)

          navigation.pop()

          dispatch(saveCurrentPlan({
            custom_healthcare_cost: values.custom_healthcare_cost > -1 && !isNull(values.custom_healthcare_cost) ? values.custom_healthcare_cost : null,
            healthcare_selection: values.custom_healthcare_cost > -1 && !isNull(values.custom_healthcare_cost) ? CUSTOM : values.healthcare_selection
          }))
        }}
      >
        {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <>
            {map(OPTIONS, (option, i) => (
              <ListOptionItem
                key={`healthcare-${option.value}`}
                labelStyle={styles.labelStyle}
                onCheck={() => {
                  setFieldValue('healthcare_selection', option.value)
                  if (option.value !== CUSTOM) {
                    setFieldValue('custom_healthcare_cost', null)
                  }
                }}
                label={option.label}
                learnMoreValue={option.tooltip}
                checked={values.healthcare_selection == option.value}
                children={option.value == CUSTOM && (
                  <CurrencyInput
                    label="Monthly Amount"
                    name={Fields.custom_healthcare_cost}
                    touched={touched[Fields.custom_healthcare_cost]}
                    errorMessage={errors[Fields.custom_healthcare_cost]}
                    onChangeText={handleChange('custom_healthcare_cost')}
                    onBlur={handleBlur('custom_healthcare_cost')}
                    value={values.custom_healthcare_cost}
                    onSubmitEditing={handleSubmit}
                  />
                )}
              />
            ))}
          </>
        )}
      </Form>
    </View>
  )
}
