import { useCallback, useState } from 'react'
import { Animated, View, Text, StyleSheet, useWindowDimensions, Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { white, DEFAULT_SPACING, red, primary, gunmetal } from '../../styles'
import TouchableOpacity from '../TouchableOpacity'
import ProfilePicture from './ProfilePicture'
import Card from '../Card'
import HeaderLabel from '../HeaderLabel'
import ShieldContainer from '../ShieldContainer'
import ProShieldContainer from '../ProShieldContainer'
import SponsorButton from './SponsorButton'
import MyRiskProfileCard from './MyRiskProfileCard'
import BuildPlanCard from './BuildPlanCard'
import Carousel from '../Carousel'
import MyPlanCard from './MyPlanCard'
import SlideupDrawer from '../SlideupDrawer'
import Button from '../Button'
import Link from '../Link'

import { setCurrentPlan, setCurrentProPlan, getNotifications } from '../../actions'
import { notificationsSelector } from '../../selectors'
import CircleGradient from '../CircleGradient'

const basicStyles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 201,
    elevation: 0
  },
  contentContainer: {
    paddingHorizontal: 30,
    paddingTop: 30,
    zIndex: 1000,
    elevation: 1000
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  gradient: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 0,
    elevation: 0
  },
  name: {
    fontWeight: '500',
    fontSize: 12,
    color: white,
    letterSpacing: -0.5
  },
  greeting: {
    fontWeight: '600',
    fontSize: 16,
    color: white,
    letterSpacing: 0
  },
  greetingsContainer: {
    marginLeft: DEFAULT_SPACING,
    justifyContent: 'center'
  },
  buttonContainer: { justifyContent: 'center' },
  statusItem: {},
  quickActionCard: {
    zIndex: 2,
    padding: DEFAULT_SPACING * 2,
    backgroundColor: white,
    borderRadius: 20
  },
  financialWellnessContainer: {},
  myTeamsContainer: { marginVertical: DEFAULT_SPACING * 2 },
  teamsContainr: {
    flexDirection: 'row',
    height: 60
  },
  myTeam: {},
  sliderupDrawerFooter: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: DEFAULT_SPACING
  },
  loginLinkContainer: {
    minHeight: 'auto',
    marginLeft: DEFAULT_SPACING / 2
  },
  loginLink: {
    color: primary,
    fontWeight: '600'
  },
  helperText: {
    color: gunmetal,
    fontSize: 14,
    marginBottom: DEFAULT_SPACING * 3
  },
  slideupDrawerContainer: { paddingHorizontal: DEFAULT_SPACING }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.SM)]: {
    contentContainer: { paddingHorizontal: DEFAULT_SPACING },
    financialWellnessContainer: {
      flexDirection: 'row',
      paddingHorizontal: DEFAULT_SPACING,
      justifyContent: 'space-between'
    },
    statusItem: { width: '49%' }
  },
  [minSize(DEVICE_SIZES.LG)]: {
    contentContainer: { paddingTop: 20, paddingHorizontal: DEFAULT_SPACING * 2 },
    greetingsContainer: { marginLeft: 40 },
    greeting: { fontSize: 38 },
    name: { fontSize: 20 },
    myTeamsContainer: {
      marginVertical: 40,
      paddingLeft: 10
    },
    myTeam: { fontSize: 34 },
    financialWellnessContainer: {
      justifyContent: 'flex-start',
      paddingHorizontal: DEFAULT_SPACING * 2
    },
    statusItem: { width: '33%' },
    quickActionCard: { marginRight: DEFAULT_SPACING }
  }
})

const isNotWeb = Platform.OS !== 'web'

function greeting() {
  const date = new Date()
  const hrs = date.getHours()

  let greet

  if (hrs < 12) {
    greet = 'Morning'
  } else if (hrs >= 12 && hrs <= 17) {
    greet = 'Afternoon'
  } else if (hrs >= 17 && hrs <= 24) {
    greet = 'Evening'
  }

  return greet
}

function FinancialWellness({ pro, primaryPlan, navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  if (!primaryPlan) {
    return <BuildPlanCard navigation={navigation} />
  }
  return (
    <Card style={styles.quickActionCard}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <HeaderLabel>My Financial Health</HeaderLabel>
      </View>

      {pro ? <ProShieldContainer /> : <ShieldContainer />}
    </Card>
  )
}

export default function Banner({
  currentUser,
  primaryPlan,
  pro = false,
  animatedValue,
  navigation,
  monthlyContribution = 0,
  goalAmount = 0
}) {
  const sponsors = !isEmpty(currentUser.sponsors) ? currentUser.sponsors : []
  const styles = isNotWeb ? mobileStyles : useStyles()
  const insets = useSafeAreaInsets()
  const window = useWindowDimensions()
  const height = isNotWeb || window.width < 1024
    ? currentUser.sponsors.length > 0
      ? 350
      : 284
    : currentUser.sponsors.length > 0
      ? 462
      : 412
  const heightFake = height

  const headerHeight = animatedValue.interpolate({
    inputRange: [0, height + insets.top],
    outputRange: [height + insets.top, insets.top],
    useNativeDriver: true,
    extrapolate: 'clamp'
  })

  const headerHeightFake = animatedValue.interpolate({
    inputRange: [0, heightFake + insets.top],
    outputRange: [heightFake + insets.top, insets.top],
    useNativeDriver: true,
    extrapolate: 'clamp'
  })

  const opacity = animatedValue.interpolate({
    inputRange: [0, height - 200],
    outputRange: [1, 0],
    extrapolate: 'clamp',
    useNativeDriver: true
  })

  const gradientOpacity = animatedValue.interpolate({
    inputRange: [0, height - 250],
    outputRange: [1, 0],
    extrapolate: 'clamp',
    useNativeDriver: true
  })

  const gradientTop = animatedValue.interpolate({
    inputRange: [0, height],
    outputRange: [0, -400],
    extrapolate: 'clamp',
    useNativeDriver: true
  })

  const top = animatedValue.interpolate({
    inputRange: [0, height - 200, height - 90],
    outputRange: [20, 0, -400],
    extrapolate: 'clamp',
    useNativeDriver: true
  })

  const currentGreeting = `Good ${greeting()}`

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)

  useFocusEffect(
    useCallback(() => {
      dispatch(getNotifications())
    }, [dispatch])
  )

  const notifications = useSelector(notificationsSelector)
  const notificationsCount = notifications.filter((notification) => !notification.readStatus).length

  const financialWellnessOrBuildPlan = !isEmpty(primaryPlan) ? (
    <FinancialWellness primaryPlan={primaryPlan} pro={pro} navigation={navigation} />
  ) : (
    <BuildPlanCard navigation={navigation} />
  )

  return (
    <Animated.View
      style={[styles.container, { height: currentUser.is_fake ? headerHeightFake : headerHeight }]}
    >
      <Animated.View style={{ opacity: gradientOpacity, top: gradientTop }}>
        <CircleGradient height={height} />
      </Animated.View>

      <Animated.View style={[{ opacity, top }]}>
        <View style={styles.contentContainer}>
          <View style={styles.row}>
            <View style={styles.row}>
              <ProfilePicture currentUser={currentUser} />

              <View style={styles.greetingsContainer}>
                <Text style={styles.greeting}>{currentGreeting}</Text>
                {!currentUser.is_fake && <Text style={styles.name}>{currentUser.first_name}!</Text>}
              </View>
            </View>

            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {!currentUser.is_fake && (
                <View>
                  <TouchableOpacity
                    onPress={() => navigation.navigate('Modal', { screen: 'Notifications' })}
                  >
                    <FontAwesomeIcon color={white} size={20} icon="bell" style={{}} />
                  </TouchableOpacity>
                  {notificationsCount > 0 && (
                    <FontAwesomeIcon
                      color={red}
                      size={8}
                      icon="circle"
                      style={{ position: 'absolute', right: -4, top: -4 }}
                    />
                  )}
                </View>
              )}
              <View style={{ marginHorizontal: DEFAULT_SPACING }}>
                <TouchableOpacity
                  onPress={() => {
                    if (currentUser.is_fake) {
                      setVisible(true)
                    } else {
                      navigation.navigate('Modal', { screen: 'User Options Menu' })
                    }
                  }}
                >
                  <FontAwesomeIcon color={white} size={20} icon="ellipsis" />
                </TouchableOpacity>
              </View>
            </View>
          </View>

          <View style={styles.myTeamsContainer}>
            <Text style={[styles.greeting, styles.myTeam]}>My Team</Text>
            <View style={styles.teamsContainr}>
              {sponsors.map((sponsor, index) => (
                <SponsorButton key={index} sponsor={sponsor} currentUser={currentUser} />
              ))}
            </View>
          </View>
        </View>

        <SlideupDrawer
          title="Get Started"
          visible={visible}
          setVisible={setVisible}
          style={styles.slideupDrawer}
        >
          <View style={styles.slideupDrawerContainer}>
            <Text style={styles.helperText}>
              Live your plan and track your progress by creating an account!
            </Text>

            <Button
              onPress={() => {
                setVisible(false)
                navigation.navigate('Modal', { screen: 'Pre Auth Registration' })
              }}
            >
              Create your Free Account
            </Button>

            <View style={styles.sliderupDrawerFooter}>
              <Text>Already have an account?</Text>
              <Link
                containerStyle={styles.loginLinkContainer}
                style={styles.loginLink}
                onPress={() => {
                  setVisible(false)
                  navigation.navigate('Modal', { screen: 'Pre Auth Login' })
                }}
              >
                Log in
              </Link>
            </View>
          </View>
        </SlideupDrawer>

        {currentUser.is_fake ? (
          <View style={styles.financialWellnessContainer}>
            {!isEmpty(primaryPlan) ? (
              <MyPlanCard
                navigation={navigation}
                monthlyContribution={monthlyContribution}
                goalAmount={goalAmount}
                onPress={() => {
                  dispatch(setCurrentPlan(primaryPlan))
                  dispatch(setCurrentProPlan(null))
                  navigation.navigate('Modal', { screen: 'Plan Summary' })
                }}
              />
            ) : (
              <BuildPlanCard navigation={navigation} />
            )}
          </View>
        ) : (
          <View style={styles.financialWellnessContainer}>
            {isNotWeb ? (
              <Carousel
                width={window.width}
                height={200}
                data={
                  currentUser.is_beta
                    ? [financialWellnessOrBuildPlan, <MyRiskProfileCard navigation={navigation} />]
                    : [financialWellnessOrBuildPlan]
                }
              />
            ) : (
              <>
                <View style={styles.statusItem}>
                  <FinancialWellness primaryPlan={primaryPlan} pro={pro} navigation={navigation} />
                </View>
                {currentUser.is_beta && (
                  <View style={styles.statusItem}>
                    <MyRiskProfileCard navigation={navigation} />
                  </View>
                )}
              </>
            )}
          </View>
        )}
      </Animated.View>
    </Animated.View>
  )
}
