import { useState, useEffect } from 'react'
import {
  Switch,
  View,
  ScrollView,
  SafeAreaView,
  Text,
  KeyboardAvoidingView,
  StyleSheet,
  Platform
} from 'react-native'
import { useDispatch } from 'react-redux'

import InputSelect from '../InputSelect'

import CircleIcon from '../CircleIcon'

import Button from '../Button'
import Link from '../Link'
import InformationBlock from '../InformationBlock'
import TouchableOpacity from '../TouchableOpacity'

import { saveCurrentPlan } from '../../actions'
import { darkblue, darkgrayblue, gray, grayblue, gunmetal, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'
import CurrencyInput from '../CurrencyInput'

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'left',
    fontWeight: '500'
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'left',
    letterSpacing: -0.4
  },
  shadow: {
    ...Platform.select({
      ios: {
        shadowColor: 'rgba(0, 0, 0, 0.24)',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowRadius: 11,
        shadowOpacity: 1
      },
      web: {
        shadowColor: 'rgba(0, 0, 0, 0.24)',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowRadius: 11,
        shadowOpacity: 1
      },
      android: {
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.24)'
      }
    })
  },
  container: {
    flex: 1,
    backgroundColor: white
  },
  scrollContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 10
  },
  careerLabel: {
    letterSpacing: -0.37,
    fontSize: 24,
    textAlign: 'center',
    color: darkgrayblue,
    fontWeight: '600',
    marginBottom: 10
  },
  locationLabel: {
    fontSize: 16,
    textAlign: 'center',
    color: gunmetal,
    marginBottom: 44
  },
  label: {
    fontSize: 14,
    color: gunmetal,
    marginBottom: 9
  },
  value: {
    fontSize: 16,
    fontWeight: '400',
    color: gunmetal
  },
  salary: {
    fontSize: 32,
    fontWeight: '700',
    color: darkgrayblue,
    marginBottom: 27
  },
  description: {
    letterSpacing: -0.6,
    fontSize: 14.4,
    color: 'rgb(66, 86, 103)',
    marginBottom: 9
  },
  wrapper: { flexDirection: 'row' },
  row: { height: 50 },
  head: { height: 80 },
  title: {
    fontWeight: '600',
    fontSize: 12,
    color: grayblue,
    letterSpacing: 0,
    paddingVertical: 9,
    paddingHorizontal: 9
  },
  titleContainer: {},
  titleText: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    fontWeight: '400'
  },
  text: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    color: gray,
    letterSpacing: -0.5
  }
})

const houstingOptions = [
  {
    label: '',
    value: ''
  },
  {
    label: 'Live at home with your parents',
    value: 'parents'
  },
  {
    label: 'Split housing cost with a roommate',
    value: 'roommates'
  }
]

const transportationOptions = [
  {
    label: '',
    value: ''
  },
  {
    label: 'Used Auto',
    value: 'USED_VEHICLE'
  },
  {
    label: 'Public Transport',
    value: 'PUBLIC_TRANSPORTATION'
  },
  {
    label: 'No Auto',
    value: 'NO_VEHICLE'
  }
]

const foodOptions = [
  {
    label: '',
    value: null
  },
  {
    label: '24/6 Ramen noodles and PBJ',
    value: 'ramen'
  }
]

function sanitize(value) {
  return value != '' ? Number((String(value) || '').replace(/\D+/g, '')) : null
}

function fetchOptionForHousing({ life_hack_live_with_parents, life_hack_live_with_roommates }) {
  if (life_hack_live_with_parents) {
    return 'parents'
  }
  if (life_hack_live_with_roommates) {
    return 'roommates'
  }
  return null
}

export default function LifeHacksForm({ route, navigation }) {
  const dispatch = useDispatch()

  const { title, currentPlan } = route.params

  useEffect(() => {
    navigation.setOptions({ title })
  })

  const [additionalIncomeAmount, setAdditionalIncomeAmount] = useState(
    currentPlan.additional_income_amount
  )
  const [lifeHackHousing, setLifeHackHousing] = useState(fetchOptionForHousing(currentPlan))
  const [transportation, setTransportation] = useState(currentPlan.transportation_selection)
  const [lifeHackReduceFoodSpend, setLifeHackReduceFoodSpend] = useState(
    currentPlan.life_hack_reduce_food_spend ? 'ramen' : null
  )
  const [studentLoanForgiveness, setStudentLoanForgiveness] = useState(
    currentPlan.student_loan_forgiveness
  )
  const [lifeHackOnParentsHealthInsurance, setLifeHackOnParentsHealthInsurance] = useState(
    currentPlan.life_hack_on_parents_health_insurance
  )

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: white }}>
      <KeyboardAvoidingView
        style={{ flex: 1, paddingHorizontal: DEFAULT_SPACING, backgroundColor: white }}
        behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
      >
        <ScrollView>
          <View style={{ flexDirection: 'row', paddingTop: 40 }}>
            <CircleIcon
              icon="house"
              style={{
                marginRight: DEFAULT_SPACING,
                marginTop: DEFAULT_SPACING,
                backgroundColor: 'rgba(66, 86, 103, 0.1)'
              }}
              color="rgb(66, 86, 103)"
              size={14}
            />

            <InputSelect
              label="Housing"
              value={lifeHackHousing}
              options={houstingOptions}
              onSubmitEditing={(value) => setLifeHackHousing(value)}
            />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <CircleIcon
              icon="car"
              style={{
                marginRight: DEFAULT_SPACING,
                marginTop: DEFAULT_SPACING,
                backgroundColor: 'rgba(118, 88, 227, 0.1)'
              }}
              color="rgb(118, 88, 227)"
              size={14}
            />

            <InputSelect
              label="Transportation"
              value={transportation}
              options={transportationOptions}
              onSubmitEditing={(value) => setTransportation(value)}
            />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <CircleIcon
              icon="utensils"
              style={{
                marginRight: DEFAULT_SPACING,
                marginTop: DEFAULT_SPACING,
                backgroundColor: 'rgba(106, 123, 126, 0.1)'
              }}
              color={gunmetal}
              size={14}
            />

            <InputSelect
              label="Food"
              value={lifeHackReduceFoodSpend}
              options={foodOptions}
              onSubmitEditing={(value) => setLifeHackReduceFoodSpend(value)}
            />
          </View>

          <View style={{ flexDirection: 'row' }}>
            <CircleIcon
              icon="chart-line"
              style={{
                marginRight: DEFAULT_SPACING,
                marginTop: DEFAULT_SPACING,
                backgroundColor: 'rgba(70, 186, 122, 0.1)'
              }}
              color="rgb(70, 186, 122)"
              size={14}
            />

            <CurrencyInput
              type="money"
              unit="$"
              label="Add a Side Hustle (estimate annual income)"
              value={additionalIncomeAmount}
              setValue={(value) => setAdditionalIncomeAmount(value)}
            />
          </View>

          {currentPlan.have_loans && (
            <>
              <View style={{ flexDirection: 'row' }}>
                <CircleIcon
                  icon="graduation-cap"
                  style={{
                    marginRight: DEFAULT_SPACING,
                    marginTop: DEFAULT_SPACING,
                    backgroundColor: 'rgba(255, 201, 77, 0.1)'
                  }}
                  color="rgb(255, 201, 77)"
                  size={14}
                />

                <CurrencyInput
                  type="money"
                  unit="$"
                  label="Student Loan Assistance/Forgiveness (Per Year)"
                  value={studentLoanForgiveness}
                  setValue={(value) => setStudentLoanForgiveness(value)}
                />
              </View>
              <InformationBlock
                text={
                  "Employers may contribute towards the repayment of employees' student loans, up to $5,250 per year on a tax-free basis."
                }
              />
            </>
          )}

          <View style={{ flexDirection: 'row' }}>
            <CircleIcon
              icon="heart"
              style={{
                marginRight: DEFAULT_SPACING,
                marginTop: DEFAULT_SPACING,
                backgroundColor: 'rgba(255, 61, 99, 0.1)'
              }}
              color="rgb(255, 61, 99)"
              size={14}
            />

            <TouchableOpacity
              onPress={() => {
                setLifeHackOnParentsHealthInsurance(!lifeHackOnParentsHealthInsurance)
              }}
            >
              <Text style={{ marginTop: 18 }}>Use Parent's Health Insurance</Text>
            </TouchableOpacity>

            <Switch
              style={{ marginTop: DEFAULT_SPACING, marginLeft: DEFAULT_SPACING }}
              value={lifeHackOnParentsHealthInsurance}
              onValueChange={(value) => {
                setLifeHackOnParentsHealthInsurance(!lifeHackOnParentsHealthInsurance)
              }}
            />
          </View>

          <Link
            style={{ marginVertical: DEFAULT_SPACING * 2, fontSize: 16, fontWeight: '600' }}
            onPress={() => {
              setLifeHackHousing(null)
              setLifeHackReduceFoodSpend(null)
              setLifeHackOnParentsHealthInsurance(false)
              setStudentLoanForgiveness(0)
              setAdditionalIncomeAmount(0)
              setTransportation(null)
            }}
          >
            Clear Life Hacks
          </Link>

          <Button
            style={{ marginTop: DEFAULT_SPACING }}
            onPress={() => {
              navigation.pop()

              setTimeout(() => {
                dispatch(
                  saveCurrentPlan({
                    transportation_selection: transportation,
                    life_hack_live_with_parents: lifeHackHousing == 'parents',
                    life_hack_live_with_roommates: lifeHackHousing == 'roommates',
                    life_hack_reduce_food_spend: lifeHackReduceFoodSpend == 'ramen',
                    life_hack_additional_income_source: !!sanitize(additionalIncomeAmount),
                    additional_income_amount: sanitize(additionalIncomeAmount),
                    student_loan_forgiveness: sanitize(studentLoanForgiveness),
                    life_hack_on_parents_health_insurance: lifeHackOnParentsHealthInsurance
                  })
                )
              }, 300)
            }}
          >
            Save
          </Button>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  )
}
