import { isValidElement } from 'react'
import { useNavigation } from '@react-navigation/native'
import { TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import { currentUserSelector } from '../selectors'
import hapticFeedback from '../components/hapticFeedback'
import { trackButtonTap } from '../hooks/usePinpoint'

export default function _TouchableOpacity(props) {
  const navigation = useNavigation()

  const { onPress, disabled, children, action } = props

  const navigationState = navigation.getState()
  const { routes } = navigationState || { routes: [] }
  const currentRoute = routes[routes.length - 1] || {}
  const currentRouteName = currentRoute.name
  const currentUser = useSelector(currentUserSelector)

  function extractTextFromChildren(children) {
    if (!children) return ''

    if (
      children.props &&
      children.props.label &&
      typeof children === 'string'
    ) {
      return children.props.label.trim()
    }

    if (children.props && children.props.name) {
      return children.props.name.trim()
    }

    if (typeof children === 'string' || typeof children === 'number') {
      return children.toString().trim()
    }

    if (Array.isArray(children)) {
      return children.map((child) => extractTextFromChildren(child)).join(' ')
    }

    if (isValidElement(children)) {
      return extractTextFromChildren(children.props.children)
    }

    return ''
  }

  function handleOnPress() {
    if (!disabled) {
      if (onPress) onPress()
      hapticFeedback()
    }

    const childrenAsString = extractTextFromChildren(children).trim()

    if (
      action ||
      (childrenAsString && !childrenAsString.match('object Object'))
    ) {
      trackButtonTap(action || childrenAsString, currentRouteName, currentUser)
    }
  }

  return (
    <TouchableOpacity {...props} onPress={handleOnPress}>
      {children}
    </TouchableOpacity>
  )
}
