import { useRef, useEffect } from 'react'
import { Animated, StyleSheet, View } from 'react-native'
import { lightestgray, primary } from '../styles'
import PropTypes from 'prop-types'
import { LinearGradient } from 'expo-linear-gradient'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    overflow: 'hidden'
  },
  backgroundContainer: (backgroundBarColor, height) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: backgroundBarColor,
    height
  }),
  progressContainer: (progressBarColor, height) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: progressBarColor,
    height
  }),
  maskContainer: (backgroundBarColor, height) => ({
    position: 'absolute',
    top: 0,
    right: 0, // Position the mask on the right
    bottom: 0,
    backgroundColor: backgroundBarColor,
    height
  }),
  gradientContainer: (height) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height
  })
})

export default function ProgressBar({
  percent,
  width,
  backgroundBarColor,
  progressBarColor,
  height,
  style,
  gradient,
  duration,
  delay
}) {
  const animatedValue = useRef(new Animated.Value(0)).current

  useEffect(() => {
    // delay animation until component is mounted
    setTimeout(() => {
      Animated.timing(animatedValue, {
        toValue: percent,
        duration: duration,
        useNativeDriver: false
      }).start()
    }, delay)
  }, [percent, duration])

  const animatedWidth = animatedValue.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%']
  })

  const maskWidth = animatedValue.interpolate({
    inputRange: [0, 100],
    outputRange: ['100%', '0%']
  })

  return (
    <View style={[styles.container, { width, height }, style]}>
      <View style={styles.backgroundContainer(backgroundBarColor, height)} />
      {gradient ? (
        <>
          <LinearGradient
            colors={['red', 'yellow', 'green']}
            start={[0, 0]}
            end={[1, 0]}
            style={[styles.gradientContainer(height), { width }]}
          />
          <Animated.View
            style={[
              styles.maskContainer(backgroundBarColor, height),
              { width: maskWidth }
            ]}
          />
        </>
      ) : (
        <Animated.View
          style={[
            styles.progressContainer(progressBarColor, height),
            { width: animatedWidth }
          ]}
        />
      )}
    </View>
  )
}

ProgressBar.defaultProps = {
  backgroundBarColor: lightestgray,
  progressBarColor: primary,
  height: 5,
  style: {},
  gradient: false,
  duration: 500,
  delay: 500
}

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  backgroundBarColor: PropTypes.string,
  progressBarColor: PropTypes.string,
  style: PropTypes.object,
  gradient: PropTypes.bool,
  duration: PropTypes.number
}
