import { post, put } from 'http';
import proApplicationEndpoints from '../api/proApplicationEndpoints';
import { setTransaction } from './setTransaction';

// eslint-disable-next-line import/prefer-default-export
export const saveTransaction = (transaction) => async (dispatch, getState) => {
  try {
    const { userId, transactionId } = transaction

    let result;

    if (transactionId) {
      delete transaction.userId
      delete transaction.transactionId

      /* Update transaction item */
      result = await put(
        proApplicationEndpoints.updateTransaction(userId, transactionId),
        transaction
      )
    } else {
      /* New transaction */

      result = await post(
        proApplicationEndpoints.createTransaction(userId),
        transaction
      )
    }

    dispatch(setTransaction(result.data))
  } catch (e) {
    console.warn(e, e.response)
  }
}
