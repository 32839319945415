import Card from './Card'
import CardHeader from './CardHeader'

import formatCurrency from '../../functions/formatCurrency'

export default function TuitionAndCostCard({ college }) {
  const header = (
    <CardHeader
      rightIcon="location-dot"
      rightHeader={college.tuitionfee_in ? `$${formatCurrency(college.tuitionfee_in)}` : '-'}
      rightSubheader={'Tuiton Fee\n(In State)'}
      rightSubheaderTooltip={'The "sticker price" of tuition, per year, for In State residents. Many public schools offer significant tuition discounts (sometimes exceeding $10,000 per year) for students from the state where the school is located.'}
      rightColor="#4F8FF0"

      leftIcon="location-dot-slash"
      leftHeader={college.tuitionfee_out ? `$${formatCurrency(college.tuitionfee_out)}` : '-'}
      leftSubheader={'Tuition Fee\n(Out of State)'}
      leftSubheaderTooltip={'The "sticker price" of tuition, per year, for Out of State residents. At most public schools, students coming from Out of State will have to pay more for tuition.'}
      leftColor="#F7CB64"
    />
  )

  const rows = [{
    label: 'Room & Board\n(on campus)',
    value: college.roomboard_on ? `$${formatCurrency(college.roomboard_on)}` : '-'
  }, {
    label: 'Other Expenses',
    value: college.otherexpense_on ? `$${formatCurrency(college.otherexpense_on)}` : '-',
    tooltipBody: 'Other Expenses may include a wide range of additional costs related to attending a school, such as moving expenses, travel, activity fees, etc.'
  }, {
    label: 'Books & Supplies',
    value: college.booksupply ? `$${formatCurrency(college.booksupply)}` : '-'
  }, {
    label: 'Instructional Spending\n(per student)',
    value: college.inexpfte ? `$${formatCurrency(college.inexpfte)}` : '-',
    tooltipBody: "If you were paying $50,000 per year for tuition, but your school was only spending $1,000 of that on instruction - how would you feel? Exactly. Troutwood includes data for Instructional Spending (per student) to help give you another angle on a school's value proposition. Although Troutwood does not recommend any particular schools, we do not like to see Instructional Spending figures that are far lower than Average Net Price. A very small number of schools show Instuctional Spending (per student) that is greater than Average Net Price - representing exceptional value for students who attend those schools."
  }, {
    label: 'Average Net Price',
    value: college.npt4_priv || college.npt4_pub ? `$${formatCurrency(college.npt4_priv || college.npt4_pub)}` : '-',
    tooltipBody: "How much does it really cost to go to a school? Average Net Price is one of the most important items that we show in the Troutwood App's College Explore. It is arguably the best estimate of what the typical student actually paid to attend a school for the most recent year. Net Price factors all costs (Tuition, Room & Board, Books & Supplies,etc.), then subtracts cost offsets (scholarships, grants, tuition discounts, etc.). We view Average Net Price as a fair estimate of what the typical student had to pay out of cash, savings, and/or loan borrowing for the most recent school year."
  }]

  return (
    <Card
      title="Tuiton & Cost"
      header={header}
      rows={rows}
    />
  )
}
