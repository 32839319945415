import { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import hapticFeedback from './hapticFeedback'
import ActionsDrawer from './ActionsDrawer'
import TouchableOpacity from './TouchableOpacity'

import Avatar from './Avatar'

import { darkblue, gunmetal, antiFlashWhite, primary, rgba, white } from '../styles/colors'

const styles = StyleSheet.create({
  touchableContainer: {
    backgroundColor: white,
    borderRadius: 12,
    marginVertical: 5,
    marginVertical: 10,
    paddingHorizontal: 10,
    paddingVertical: 10,
    position: 'relative'
  },
  container: { flexDirection: 'row' },
  textContainer: {
    justifyContent: 'center',
    marginLeft: 12
  },
  actionsContainer: {
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 12,
    bottom: 12
  },
  title: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue,
    letterSpacing: 0
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5
  },
  icon: { alignSelf: 'center' },
  iconContainer: {
    justifyContent: 'center',
    borderRadius: 25,
    height: 50,
    width: 50,
    backgroundColor: rgba(primary, 0.1)
  }
})

export default function LineItemCard({ onPress, title, subtitle, color, customAvatar, Icon, icon, onEdit, onDelete }) {
  const [visible, setVisible] = useState(false)

  const onPressOnCard = function () {
    if (onPress) onPress()
    hapticFeedback()
  }

  const onDotPressOnCard = function () {
    setVisible(true)
    hapticFeedback()
  }

  return (
    <>
      <TouchableOpacity onPress={onPressOnCard} style={styles.touchableContainer}>
        <View style={styles.container}>
          { !Icon && !customAvatar && <Avatar color={color} initial={title[0]} /> }

          { !Icon && customAvatar}

          { Icon
            && (
              <View style={styles.iconContainer}>
                <Icon style={styles.icon} fill={primary} height={25} width={25} />
              </View>
            )}

          <View style={styles.textContainer}>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>

          <TouchableOpacity onPress={onDotPressOnCard} style={styles.actionsContainer}>
            <FontAwesomeIcon
              icon={['far', 'ellipsis-vertical']}
              color={antiFlashWhite}
              size={25}
            />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>

      <ActionsDrawer
        title={`Actions for ${title}`}
        visible={visible}
        setVisible={setVisible}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  )
}
