import { useState } from 'react'
import {
  Platform,
  Text,
  Image,
  View,
  StyleSheet,
  ActivityIndicator,
  useWindowDimensions,
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { useDispatch, useSelector } from 'react-redux'
import { post, get } from 'http'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Button from './components/Button'

import generateUUID from './functions/generateUUID'

import { info, white, primary } from './styles/colors'
import Link from './components/Link'
import FadeInView from './components/FadeInView'

import { accessTokenSelector, loadingSelector } from './selectors'
import generatePassword from './functions/generatePassword'

import { setAccessToken, setCurrentUser } from './actions'
import CircleGradient from './components/CircleGradient'

import { DEFAULT_SPACING } from './styles'

const basicStyles = {
  overlay: {
    alignItems: 'center',
    flex: 1
  },
  container: {
    paddingHorizontal: DEFAULT_SPACING,
    alignSelf: 'stretch',
    height: 150,
    justifyContent: 'center'
  },
  text: {
    color: white,
    textAlign: 'center'
  },
  subtext: {
    fontSize: 18,
    marginVertical: DEFAULT_SPACING * 2
  },
  mainContainer: {
    alignSelf: 'center',
    justifyContent: 'center'
  },
  linkStyle: {
    alignSelf: 'center',
    marginBottom: 12,
    fontWeight: '500',
    fontSize: 14,
    marginLeft: 12,
    color: primary
  },
  buttonContainer: {
    marginTop: 0,
    alignSelf: 'stretch',
    zIndex: 1000
  },
  getStartedWithoutAccount: {
    zIndex: 1000,
    marginBottom: 12,
    backgroundColor: info
  },
  troutwoodIconLogo: {
    width: 166,
    height: 166,
    alignSelf: 'center',
    marginBottom: DEFAULT_SPACING * 3
  },
  troutwoodLogo: {
    width: 260,
    height: 25,
    resizeMode: 'contain',
    alignSelf: 'center'
  },
  loginTxt: { flexDirection: 'row', alignSelf: 'center' }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      overlay: {
        justifyContent: 'center',
      },
      troutwoodIconLogo: {
        width: 236,
        height: 240,
        marginBottom: 44,
      },
      troutwoodLogo: {
        width: 432,
        height: 55,
      },
      text: {
        fontSize: 18,
        lineHeight: 21,
        fontWeight: '500',
      },
      subtext: {
        fontSize: 24,
        lineHeight: 28,
        fontWeight: '300',
      },
      loginTxt: { marginTop: 30, },
      linkStyle: {
        fontSize: 18,
        lineHeight: 21,
      }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

const initialFadeIn = 600
const wordFadeIn = initialFadeIn + 200

export default function GetStartedScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const window = useWindowDimensions()
  const { loading } = useSelector((state) => loadingSelector(state))
  const accessToken = useSelector((state) => accessTokenSelector(state))
  const [disableButton, setDisableButton] = useState(false)
  const dispatch = useDispatch()

  let height = window.height - 205
  if (window.width > 600) {
    const width = window.width > 800 ? 500 : window.width - 40
    styles.container = { ...styles.container, alignSelf: 'center', width: isNotWeb ? width : 370 }
  } else if (window.height > 750) {
    styles.container = { ...styles.container, height: 250 }
    height = window.height - 250
  }

  return (
    <View style={styles.overlay}>
      <CircleGradient height={'100%'} />

      <View style={[styles.mainContainer, { height: isNotWeb ? height : 548 }]}>
        <Image source={require('./assets/logo2.png')} style={styles.troutwoodIconLogo} />

        <Image source={require('./assets/troutwood.png')} style={styles.troutwoodLogo} />

        <Text style={[styles.text, styles.subtext]}>A financial plan for life.</Text>
      </View>

      {loading && accessToken && <ActivityIndicator />}

      {!loading && !accessToken && (
        <FadeInView
          style={styles.container}
          initialValue={0}
          toValue={1}
          delay={wordFadeIn}
          duration={initialFadeIn}
        >
          <View style={styles.buttonContainer}>
            <Button
              style={styles.getStartedWithoutAccount}
              disabled={disableButton}
              onPress={async () => {
                setDisableButton(true)

                const deviceID = generateUUID()
                const password = generatePassword()

                await AsyncStorage.setItem('deviceID', deviceID)
                await AsyncStorage.setItem('password', password)

                try {
                  const response = await post('rest-auth/registration/', {
                    device_id: deviceID,
                    password1: password,
                    password2: password
                  })
                  const { data } = response

                  const loginResponse = await post('rest-auth/login/', {
                    email: data.email,
                    password
                  })
                  const token = loginResponse.data.key

                  await dispatch(setAccessToken(token))

                  const meResponse = await get('me/')
                  const user = meResponse.data

                  await dispatch(setCurrentUser({ ...data, ...user }))

                  navigation.reset({
                    key: null,
                    index: 0,
                    routes: [{ name: 'Main' }]
                  })
                } catch (e) {
                  setDisableButton(false)
                }
              }}
            >
              Build My Financial Plan
            </Button>

            <Button
              onPress={() => navigation.navigate('Registration')}
              style={{ marginBottom: DEFAULT_SPACING, backgroundColor: primary }}
            >
              Sign Up
            </Button>

            <View style={styles.loginTxt}>
              <Text style={styles.text}>Already have an account?</Text>
              <Link style={styles.linkStyle} onPress={() => navigation.navigate('Login')}>
                Log In
              </Link>
            </View>
          </View>
        </FadeInView>
      )}
    </View>
  )
}
