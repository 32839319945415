import React, { useLayoutEffect, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { each } from 'lodash'
import Container from 'components/Container'
import { gunmetal, red, white, DEFAULT_SPACING } from 'styles'
import Form from 'components/Form'
import {
  DURATION_LONG_TERM,
  DURATION_SHORT_TERM,
  AMORTIZATION_TYPE_REVOLVING_LOAN,
  AMORTIZATION_TYPE_TERM_LOAN,
  Fields,
  InitialValues,
  ValidationSchema
} from 'schemas/balanceSheetSchema'
import { saveBSAccount, deleteBSAccount } from 'actions'
import { LIABILITY, LONG_TERM_BS_ACCOUNT_TYPE, SHORT_TERM_BS_ACCOUNT_TYPE } from 'constants'
import {
  bsAccountCategoriesSelector,
  bsAccountSelector,
  bsAccountsSelector,
  primaryProPlanSelector
} from 'selectors'
import BSAccountForm from 'components/budgeting/BSAccountForm'
import SlideupDrawer from 'components/SlideupDrawer'
import HeaderLabel from '../HeaderLabel'
import handleError from '../../functions/handleError'

const styles = StyleSheet.create({
  container: { backgroundColor: white },
  contentContainer: {
    height: '100%',
    justifyContent: 'space-between'
  },
  footerContainer: { marginBottom: 20 },
  body: { justifyContent: 'space-evenly' },
  divider: { marginBottom: DEFAULT_SPACING * 2 },
  accountDurationLabel: {
    textDecorationLine: 'underline',
    marginBottom: 8
  },
  accountDurationContainer: { marginBottom: 20 },
  asterisk: { color: red },
  modalContainer: { paddingHorizontal: DEFAULT_SPACING }
})

function getApplicableCategories(allCategories, categories, accountType) {
  const applicableCategories = []

  if (categories && categories.length > 0) {
    each(categories, (cat) => {
      const category = allCategories.find((c) => c.key === cat)
      if (category && category.type === accountType) {
        applicableCategories.push({
          ...category,
          value: category.key
        })
      }
    })
  } else {
    each(allCategories, (cat) => {
      if (cat.type === accountType) {
        applicableCategories.push({
          ...cat,
          value: cat.key
        })
      }
    })
  }

  return applicableCategories
}

function getInitialValues(bsAccount) {
  const baseValues = {
    [Fields.name]: bsAccount[Fields.name],
    [Fields.value]: bsAccount[Fields.value],
    [Fields.rateOfReturn]: bsAccount[Fields.rateOfReturn],
    [Fields.duration]:
      bsAccount[Fields.duration] === SHORT_TERM_BS_ACCOUNT_TYPE
        ? DURATION_SHORT_TERM
        : DURATION_LONG_TERM,
    [Fields.categoryId]: bsAccount[Fields.categoryId],
    [Fields.amortizationType]: bsAccount?.advanced?.amortization?.type
      ? bsAccount?.advanced?.amortization?.type
      : AMORTIZATION_TYPE_REVOLVING_LOAN
  }

  if (bsAccount?.advanced?.amortization?.type === AMORTIZATION_TYPE_TERM_LOAN) {
    return {
      ...baseValues,
      [Fields.termOriginalLoanAmount]:
        bsAccount?.advanced?.amortization?.conditions?.[Fields.termOriginalLoanAmount],
      [Fields.termMonths]: bsAccount?.advanced?.amortization?.conditions?.[Fields.termMonths],
      [Fields.termEndDate]: bsAccount?.advanced?.amortization?.conditions?.[Fields.termEndDate],
      [Fields.termExtraPaymentPreference]:
        bsAccount?.advanced?.amortization?.conditions?.[Fields.termExtraPaymentPreference]
    }
  }
  return {
    ...baseValues,
    [Fields.revolvingMinPaymentAmount]:
      bsAccount?.advanced?.amortization?.conditions?.[Fields.revolvingMinPaymentAmount],
    [Fields.revolvingMinPaymentPct]:
      bsAccount?.advanced?.amortization?.conditions?.[Fields.revolvingMinPaymentPct],
    [Fields.revolvingPaymentDayOfMonth]:
      bsAccount?.advanced?.amortization?.conditions?.[Fields.revolvingPaymentDayOfMonth]
  }
}

function getAdvancedInitialValues(initialValues) {
  return { ...initialValues }
}

export default function DebtRankForm({ navigation, route }) {
  const { params } = route
  const { accountId, categories } = params || {}
  const [visible, setVisible] = useState(false)

  const bsAccount = accountId
    ? useSelector((state) => bsAccountSelector(state, accountId, LIABILITY))
    : null

  const initialValues = bsAccount
    ? getInitialValues(bsAccount)
    : getAdvancedInitialValues(InitialValues)

  const dispatch = useDispatch()

  const allCategories = useSelector(bsAccountCategoriesSelector)
  const applicableCategories = getApplicableCategories(allCategories, categories, LIABILITY)
  const { assetAccounts, liabilityAccounts } = useSelector(bsAccountsSelector)
  const bsAccounts = [...assetAccounts, ...liabilityAccounts]

  const primaryProPlan = useSelector(primaryProPlanSelector)
  const { cashSweepLogic } = primaryProPlan || {}

  const onDelete = async () => {
    navigation.popToTop()
    navigation.pop()
    dispatch(deleteBSAccount({ accountId, LIABILITY }))
  }

  const onDeletePopup = () => {
    setVisible(true)
  }

  const onSave = async (values) => {
    const position = bsAccount && bsAccount.position ? bsAccount.position : bsAccounts.length

    const amortizationType = values[Fields.amortizationType]

    const conditions = {
      [Fields.termOriginalLoanAmount]:
        amortizationType == AMORTIZATION_TYPE_TERM_LOAN
          ? values[Fields.termOriginalLoanAmount]
          : undefined,
      [Fields.termMonths]:
        amortizationType == AMORTIZATION_TYPE_TERM_LOAN
          ? Number(values[Fields.termMonths])
          : undefined,
      [Fields.termEndDate]:
        amortizationType == AMORTIZATION_TYPE_TERM_LOAN ? values[Fields.termEndDate] : undefined,
      [Fields.termExtraPaymentPreference]:
        amortizationType == AMORTIZATION_TYPE_TERM_LOAN
          ? values[Fields.termExtraPaymentPreference]
          : undefined,
      [Fields.revolvingMinPaymentAmount]:
        amortizationType == AMORTIZATION_TYPE_REVOLVING_LOAN
          ? values[Fields.revolvingMinPaymentAmount]
          : undefined,
      [Fields.revolvingMinPaymentPct]:
        amortizationType == AMORTIZATION_TYPE_REVOLVING_LOAN
          ? parseFloat(values[Fields.revolvingMinPaymentPct])
          : undefined,
      [Fields.revolvingPaymentDayOfMonth]:
        amortizationType == AMORTIZATION_TYPE_REVOLVING_LOAN
          ? Number(values[Fields.revolvingPaymentDayOfMonth])
          : undefined
    }

    const amortization = { type: amortizationType, conditions }
    const advanced = { amortization }

    const payload = {
      accountId,
      accountType: LIABILITY,
      position,
      ...values,
      [Fields.duration]:
        values[Fields.duration] === DURATION_SHORT_TERM
          ? SHORT_TERM_BS_ACCOUNT_TYPE
          : LONG_TERM_BS_ACCOUNT_TYPE,
      advanced
    }

    // Because the form for some reason returns a string
    payload.defaultRate = parseFloat(payload.defaultRate)

    try {
      await dispatch(saveBSAccount(payload))
      navigation.goBack()
    } catch (error) {
      handleError(error)
    }
  }

  const possibleIds = cashSweepLogic
    ? [
        cashSweepLogic.shortTermCashAccountId,
        cashSweepLogic.cashOverflowAccountId,
        cashSweepLogic.shortTermDebtAccountId
      ]
    : []

  const includedInPossibleIds = possibleIds.includes(accountId)
  const accountType = LIABILITY
  const labelsPack = {
    categorySelectorLabel: 'Liability Category',
    accountDurationLabel: 'Liability Type'
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitleStyle: {
        color: white,
        fontSize: 20
      },
      headerTransparent: true,
      headerTintColor: white
    })
  }, [navigation])

  return (
    <Container card={true} style={styles.container}>
      <Form
        validationSchema={ValidationSchema}
        initialValues={initialValues}
        onSubmit={onSave}
        onNegativeButtonPress={
          accountId && !includedInPossibleIds
            ? onDelete
            : includedInPossibleIds
            ? onDeletePopup
            : undefined
        }
        disabled
      >
        {({ handleChange, handleBlur, setFieldValue, values, touched, errors }) => (
          <View>
            <HeaderLabel>Manually Add Account</HeaderLabel>
            <Text style={{ fontSize: 16, color: gunmetal, marginVertical: 20 }}>
              Choose which type of category you would like to manually add:
            </Text>

            <BSAccountForm
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              accountType={accountType}
              applicableCategories={applicableCategories}
              allCategories={allCategories}
              labelsPack={labelsPack}
              Fields={Fields}
            />
          </View>
        )}
      </Form>

      <SlideupDrawer
        title="Cannot Delete Account"
        snapPointIndex={1}
        visible={visible}
        setVisible={setVisible}
      >
        <View style={styles.modalContainer}>
          <Text>
            This account is currently designated as a Cashflow Waterfall account. Please
            re-designate a different account as a Cashflow Waterfall account in this account’s place
            before attempting to delete this account.
          </Text>
        </View>
      </SlideupDrawer>
    </Container>
  )
}
