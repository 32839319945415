import { Platform, View, Text, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { DEFAULT_SPACING } from '../../styles'
import CircularProgress from '../CircularProgress'

const basicStyles = {
  container: {
    alignSelf: 'center',
    paddingTop: 10,
    paddingRight: 0,
  },
  label: {
    color: 'rgb(66, 86, 103)',
    fontWeight: '700',
    fontSize: 16,
  },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: { paddingRight: DEFAULT_SPACING * 2 },
      label: { fontSize: 24, }
    },
  }
)

const isWeb = Platform.OS === 'web'

function ConfidenceIntervalChart({ confidenceInterval, style={} }) {
  const styles = isWeb ? useStyles() : mobileStyles

  const progressContent = () => (
    <Text style={styles.label}>
      {`${(confidenceInterval || 0).toFixed(0)}%`}
    </Text>
  )

  return (
    <View style={[styles.container, style]}>
      <CircularProgress
        progress={confidenceInterval}
        size={isWeb ? 54 * 1.8 : 54}
        width={isWeb ? 8 : 4}
        fillFunction={progressContent}
      />
    </View>
  )
}

export default ConfidenceIntervalChart
