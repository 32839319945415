import { useRef } from 'react'
import {
  StyleSheet,
  Keyboard,
  Platform
} from 'react-native'

import { createElement } from 'react-native-web'

import moment from 'moment'

import InputWrapper from './InputWrapper'

const styles = StyleSheet.create({
  input: {
    color: 'rgb(66, 86, 103)',
    fontSize: 18,
    marginTop: 2 * 2,
    fontWeight: '500',
    paddingVertical: 0,
    outlineStyle: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
  }
})

export default function DateTimePicker(props, ref) {
  const {
    icon,
    secureTextEntry,
    onSubmitEditing,
    disabled,
    setValue,
    onChange,
    placeholder,
    multiline,
  } = props

  let { value } = props

  const inputElement = ref || useRef(null)

  if (value && moment(value).isValid()) {
    value = moment(value).format('YYYY-MM-DD')
  }

  return (
    <InputWrapper
      {...props}
      inputElement={inputElement}
      onPress={() => {
        inputElement.current.focus()
      }}
    >
      {
        createElement('input', {
          type: 'date',
          value,
          placeholderTextColor: 'rgb(133, 151, 165)',
          placeholder,
          style: [styles.input, { marginLeft: icon ? 2 * 18 : 0 }, { height: multiline ? DEFAULT_SPACING * 6 : 'auto' }],
          editable: !disabled,
          secureTextEntry,
          onInput: (evt) => {
            const val = evt.target.value

            if (setValue) setValue(val)
            if (onChange) onChange(val)
          },
          onSubmitEditing

        })
      }
    </InputWrapper>
  )
}
