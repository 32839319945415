import { useLayoutEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { lightestgray, white, gunmetal } from './styles/colors'
import Link from './components/Link'
import { ASSET, LIABILITY, NAVIGATION_TYPE } from './constants'
import { SHORT_TERM_BS_ACCOUNT_TYPE } from './constants'
import { DEFAULT_SPACING } from './styles'
import { bsAccountCategoriesSelector, bsAccountSelector } from './selectors'
import { deleteBSAccount } from './actions'
import HeaderLabel from './components/HeaderLabel'
import { each } from 'lodash'
import formatPercent from './functions/formatPercent'

const SEARCH_BAR_OFFSET = 20

const styles = StyleSheet.create({
  container: { 
    flex: 1,
    backgroundColor: white
  },
  accountContainer: {
    backgroundColor: lightestgray,
    flex: 1,
    flexShrink: 0,
    marginHorizontal: DEFAULT_SPACING
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: DEFAULT_SPACING
  },
  headerText: {
    marginBottom: DEFAULT_SPACING,
    textAlign: 'center',
    color: gunmetal
  },
  headerSearch: {
    marginBottom: 0,
    bottom: -SEARCH_BAR_OFFSET,
    // add a border, not sure why it's not showing up
    borderBottomWidth: 1,
    borderBottomColor: lightestgray,
    borderTopWidth: 1,
    borderTopColor: lightestgray,
    borderLeftWidth: 1,
    borderLeftColor: lightestgray,
    borderRightWidth: 1,
    borderRightColor: lightestgray

  },
  body: { paddingTop: SEARCH_BAR_OFFSET },
  assetList: { marginTop: DEFAULT_SPACING },
  bottomButton: { margin: DEFAULT_SPACING },
  accountHeader: { fontWeight: '600', fontSize: 18 },
  accountAddLink: { justifyContent: 'flex-start' },

  lineItem: {
    padding: DEFAULT_SPACING
  },
  lineItemLabel: {
    fontSize: 12
  },
  lineItemValue: {
    fontSize: 18,
    fontWeight: '500'
  }
})

function getApplicableCategories(allCategories, accountType) {
  const applicableCategories = []

  each(allCategories, (cat) => {
    if (cat.type === accountType) {
      applicableCategories.push({
        ...cat,
        value: cat.key
      })
    }
  })

  return applicableCategories
}

const labels = {
  [ASSET]: {
    categorySelectorLabel: 'Asset Category',
    accountDurationLabel: 'Asset Type',
  },
  [LIABILITY]: {
    categorySelectorLabel: 'Liability Category',
    accountDurationLabel: 'Liability Type',
  }
}

export default function ViewBSAccountScreen({ navigation, route }) {
  const { params: { account: { accountId } }} = route
  const account = useSelector((state) => bsAccountSelector(state, accountId)) || {}

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    navigation.setOptions({
      title: account.name
    })
  }, [navigation])

  const onAddAccount = (type) => () => {
    navigation.navigate('Modal', {
      screen: 'Edit/Add BS Accounts',
      params: { accountType: type }
    })
  }

  const onEditAccount = (account) => {
    navigation.navigate('Modal', {
      screen: 'Edit/Add BS Accounts',
      params: account
    })
  }

  const onDeleteAccount = (type) => (accountId) => {
    // Dispatch a delete account action
    dispatch(deleteBSAccount({ accountType: type, accountId }))
  }

  const onNextPress = () => {
    navigation.navigate('Modal', { screen: 'Upgrade Plan Add Goal' })
  }

  const { 
    accountType = '',
    category='',
    name='',
    defaultRate=0
  } = account

  const allCategories = useSelector(bsAccountCategoriesSelector)
  const applicableCategories = getApplicableCategories(allCategories, accountType)

  const labelsPack = labels[accountType]

  const liquidityName = account.liquidityType === SHORT_TERM_BS_ACCOUNT_TYPE ? 'Short Term' : 'Long Term'
  const categoryName = applicableCategories.find(({ key }) => key === category)?.label

  const lineItems = [{
    label: 'Name',
    value: name
  }, {
    label: labelsPack?.categorySelectorLabel,
    value: categoryName
  }, {
    label: labelsPack?.accountDurationLabel,
    value: liquidityName
  }, {
    label: accountType === LIABILITY ? 'Interest Rate (assumed)' : 'Rate of Return',
    value: formatPercent(defaultRate, 3)
  }]

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <HeaderLabel>
          Account Information
        </HeaderLabel>

        <Link onPress={() => onEditAccount(account)}>
          Edit
        </Link>
      </View>

      {lineItems.map(({ label, value }) => (
        <View style={styles.lineItem}>
          <Text style={styles.lineItemLabel}>
            {label}
          </Text>
          <Text style={styles.lineItemValue}>
            {value}
          </Text>
        </View>
      ))}
    </View>
  );
}

ViewBSAccountScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
