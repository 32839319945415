import { useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import Card from './Card'

import formatCurrency from '../../functions/formatCurrency'

import TooltippedText from '../TooltippedText'

import { primary, danger, gray } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: DEFAULT_SPACING
  },
  headerCaret: {
    fontSize: 16,
    color: primary,
    fontWeight: '600',
    marginRight: 6
  },
  header: {
    fontSize: 28,
    color: primary,
    fontWeight: '600'
  },
  subheader: {
    fontSize: 12,
    color: gray
  }
})

function Header({ college }) {
  const percentChanged = college.endowment_rate * 100
  const colorStyle = { color: percentChanged > 0 ? primary : danger }
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)

  return (
    <View>
      <View style={styles.row}>
        <Text style={[styles.headerCaret, colorStyle]}>
          { percentChanged > 0 && '▲'}
          { percentChanged < 0 && '▼' }
        </Text>
        <Text style={[styles.header, colorStyle]}>
          { college.endowend && `${percentChanged.toFixed(2)}%` }
          { !college.endowend && '-' }
        </Text>
      </View>
      <TooltippedText
        visible={tooltipIsVisible}
        setVisible={setTooltipIsVisible}
        anchorStyle={[styles.subheader]}
        anchorText="Percent Change"
        contentHeader="Percent Change"
        contentBody={"The rate at which a school's total endowment has increased or decreased vs.the prior year. Common reasons for an endowment increase include favorable performance of the school's investments and/or generous donations made to the school. Poor performance of a school's investments, weak alumni giving, and/or budget deficits are all factors that could contribute to endowment declines."}
      />
    </View>
  )
}

export default function EndowmentCard({ college }) {
  const rows = [{
    label: 'Last Year',
    value: college.endowbegin ? `$${formatCurrency(college.endowbegin)}` : '-'
  }, {
    label: 'Most Recent Year',
    value: college.endowend ? `$${formatCurrency(college.endowend)}` : '-'
  }]

  return (
    <Card
      title="Endowment"
      tooltipBody={"Troutwood shows Endowment data because it is a measure of a school's overall financial resources, especially private institutions. Schools with large endowments can often afford to be more generous with financial aid grants and scholarships, often resulting in favorable Net Cost for students. Endowment size can also be a reflection on the strength and engagement of a school's alumni network, its capacity to build and maintain great facilities, as well as its ability to attract top talent."}
      header={<Header college={college} />}
      rows={rows}
    />
  )
}
