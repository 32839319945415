import { useRef, useState } from 'react'
import { View, StyleSheet, Text, ScrollView } from 'react-native'
import { gunmetal, DEFAULT_SPACING, darkblue } from 'styles'
import HeaderLabel from 'components/HeaderLabel'
import Link from 'components/Link'
import moment from 'moment'
import formatCurrency from 'functions/formatCurrency'

import CalendarItem from 'components/finPulse/CalendarItem'
import { primary } from '../../styles'
import { useNavigation } from '@react-navigation/native'

const styles = StyleSheet.create({
  horizontalContentContainer: {
    paddingRight: 100, 
    paddingVertical: DEFAULT_SPACING
  },  
  smallHeader: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'center',
  },
  lead: {
    fontSize: 20,
    color: darkblue,
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING
  },
  transactionsHeaderContainer: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    padding: DEFAULT_SPACING
  },
  transaction: (last) => ({
    marginHorizontal: DEFAULT_SPACING,
    borderBottomColor: last ? 'transparent' : 'rgba(70, 90, 106, 0.12)',
    borderBottomWidth: 1,
    paddingVertical: DEFAULT_SPACING
  }),
  transactionHeader: {
    flexDirection: 'row', 
    justifyContent: 'space-between'
  },
  transactionAmount: {
    fontSize: 14,
    color: primary
  },  
  transactionDescription: {
    fontSize: 16,
    color: darkblue,
    fontWeight: '600'
  },
  transactionDate: {
    color: gunmetal,
    fontSize: 14,
  }
})

function Transaction({ transaction, last }) {
  return (
    <View style={styles.transaction(last)}>
      <View style={styles.transactionHeader}>
        <Text style={styles.transactionDescription}>{transaction.name}</Text>
        <Text style={styles.transactionAmount}>{formatCurrency(transaction.amount, { accurate: true, showDollarSign: true })}</Text>
      </View>

      <View>  
        <Text style={styles.transactionDate}>{moment(transaction.date).format('MMM D, YYYY')}</Text>
      </View>      
    </View>
  )
}

export default function GoalContribution({ monthlyContributions=[], streakLength=0, transactions=[] }) {
  const scrollViewRef = useRef()
  const [expanded, setExpanded] = useState(true)
  const navigation = useNavigation()

  return (
    <>
      <Text style={styles.smallHeader}>Goal Contribution</Text>
      <Text style={styles.lead}>{streakLength > 0 ? '🔥 ' : null}{streakLength} Month Streak</Text>
      <ScrollView
        horizontal={true}
        ref={scrollViewRef}
        onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: false })}
        contentContainerStyle={styles.horizontalContentContainer}
      >
        {monthlyContributions.map((monthlyContribution, index) => (
          <CalendarItem 
            key={index}
            date={monthlyContribution.label}
            checked={monthlyContribution.checked}
            future={monthlyContribution.currentMonth}
          />
        ))}
      </ScrollView>

      <View style={styles.transactionsHeaderContainer}>
        <HeaderLabel>
          Recent Transactions
        </HeaderLabel>

        <Link onPress={() => setExpanded(!expanded)}>
          { expanded ? 'Hide' : 'Show' }
        </Link>
      </View>  

      { expanded && (
        transactions.map((transaction, index) => (
          <Transaction 
            transaction={transaction}
            last={transactions.length - 1 === index}
            key={index}
          />
        ))
      )}
    </>
  )
}
