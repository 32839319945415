// import { put, post } from 'http'
// import proApplicationEndpoints from '../api/proApplicationEndpoints';
import { post, put } from 'http';
import proApplicationEndpoints from '../api/proApplicationEndpoints';
import handleError from '../functions/handleError';
import { currentUserSelector } from '../selectors';
import { setBSAccount } from './setBSAccount'

function sanitize(account) {
  delete account.amortizationType
  delete account.termOriginalLoanAmount
  delete account.termMonths
  delete account.termEndDate
  delete account.termExtraPaymentPreference
  delete account.revolvingMinPaymentAmount
  delete account.revolvingMinPaymentPct
  delete account.revolvingPaymentDayOfMonth

  return { ...account }
}

// eslint-disable-next-line import/prefer-default-export
export const saveBSAccount = (account) => async (dispatch, getState) => {
  try {
    let result;
    const { accountId } = account
    const state = getState()
    const { id: userId } = currentUserSelector(state)

    const sanitizedAccount = sanitize(account)

    if (accountId) {
      result = await put(
        proApplicationEndpoints.updateAccount(userId, accountId),
        sanitizedAccount
      )
    } else {
      result = await post(
        proApplicationEndpoints.createAccount(userId),
        sanitizedAccount
      )
    }

    const { data: newAccount = {} } = result || {}

    dispatch(setBSAccount({ account: newAccount, accountId }))
  } catch (e) {
    console.warn(e?.response?.data)
    handleError(e)
  }
}
