import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import {
  Animated,
  Platform,
  Text,
  View,
  ScrollView,
  StyleSheet,
  useWindowDimensions
} from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { map, flatten, find, isEmpty } from 'lodash'
import { get } from 'http'
import { useIsFocused } from '@react-navigation/native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Button from './components/Button'
import CircleGradient from './components/CircleGradient'

import LifeEventCard from './components/lifeEvent/LifeEventCard'
import SurplusCard from './components/planSummary/SurplusCard'

import ProNudge from './components/planSummary/ProNudge'

import { getBSAccounts, assignPrimaryPlan, setLoading } from './actions'

import {
  currentPlanSelector,
  currentUserSelector,
  loadingSelector,
  lifeEventsSelector,
  appConfigSelector
} from './selectors'
import NamePlanSlideupDrawer from './components/plans/NamePlanSlideupDrawer'
import BirthDateIntroModal from './components/plans/BirthDateIntroModal'

import {
  primary,
  darkblue,
  gunmetal,
  lightestgray,
  white,
  info,
  rgba,
  magicPotion,
  indianYellow
} from './styles/colors'

import { DEFAULT_SPACING } from './styles'
import handleError from './functions/handleError'

import PlanDetailsCard from './components/planSummary/PlanDetailsCard'
import LivingEssentialsCard from './components/planSummary/LivingEssentialsCard'
import PlanSummaryHeader from './components/planSummary/PlanSummaryHeader'
import PlanSummaryOptions from './components/planSummary/PlanSummaryOptions'
import CircularProgress from './components/CircularProgress'
import PillLabel from './components/PillLabel'

import abbreviateCurrency from './functions/abbreviateCurrency'
import formatPercent from './functions/formatPercent'

import { ANNUAL, AVERAGE } from './constants'
import StartInvestingCard from './components/planSummary/StartInvestingCard'
import { useDispatch, useSelector } from 'react-redux'
import PlanSummaryPageHeader from './components/planSummary/PlanSummaryPageHeader'

const basicStyles = {
  card: {
    padding: 10,
    paddingTop: DEFAULT_SPACING,
    margin: 8,
    shadowColor: 'transparent',
    borderRadius: 6
  },
  title: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'left',
    fontWeight: '500'
  },
  cardtitle: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: 12
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'left',
    letterSpacing: -0.4
  },
  headerText: {
    fontSize: 24,
    color: white,
    fontWeight: '700'
  },
  summaryContainer: {
    flex: 1,
    flexDirection: 'column',
    marginTop: 160
  },
  subLabel: {
    minHeight: 32
  },
  progressContent: { fontWeight: '600', fontSize: 32 },
  loadingBlock: { backgroundColor: lightestgray, height: 16, width: 40 },
  summaryDetailsContainer: {
    paddingTop: 160,
    backgroundColor: lightestgray
  },
  activateBtn: { flex: 1, margin: DEFAULT_SPACING * 2 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
    summaryContainer: {
      marginTop: DEFAULT_SPACING * 2,
      backgroundColor: lightestgray
    },
    summaryDetailsContainer: {
      paddingTop: DEFAULT_SPACING * 2,
      backgroundColor: 'none',
      maxWidth: 1600
    }
  },
  [minSize(DEVICE_SIZES.LG)]: {
    scrollView: { paddingHorizontal: DEFAULT_SPACING },
    progressContent: { fontSize: 20 },
    summaryDetailsContainer: {
      paddingTop: 36,
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingHorizontal: 40,
      justifyContent: 'flex-start'
    },
    activateBtn: {
      minWidth: 688,
      maxWidth: 1074,
      height: 48,
      marginTop: 0,
      marginHorizontal: 0
    },
    summaryContainer: { marginTop: 0 }
  }
})

const isNotWeb = Platform.OS !== 'web'

export default function PlanSummaryScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => currentUserSelector(state))
  const currentPlan = useSelector((state) => currentPlanSelector(state))
  const events = useSelector((state) => lifeEventsSelector(state, currentPlan.id))
  const { width } = useWindowDimensions()

  const { loading } = useSelector((state) => loadingSelector(state))
  const [saving, setSaving] = useState(false)

  const [duration, setDuration] = useState(ANNUAL)
  const [result, setResult] = useState({})

  const [visible, setVisible] = useState(false)
  const [birthDateVisible, setBirthDateVisible] = useState(false)

  const isFocused = useIsFocused()

  const scenario = AVERAGE

  const summary_stats = result.summary_stats || {}
  const portfolio_value = summary_stats[scenario] || {}
  const projectedPortfolioValue = portfolio_value.projected_ending_value

  const years = result.years || []

  async function fetchAndSetData(currentPlan) {
    try {
      dispatch(setLoading({ loading: true }))

      const plan = await get(`api/v1/plans/${currentPlan.id}/`)

      if (plan.data.occupation) {
        const occupations = await get(`location/${currentPlan.city.id}/occupations/`)
        const { data: militaryOccupations } = await get('api/v1/occupations/military/')
        const mCareers = []
        if (militaryOccupations) {
          Object.keys(militaryOccupations.careers).map((key) => {
            mCareers.push(...militaryOccupations.careers[key])
          })
        }

        const allOccupations = flatten(map(occupations.data, (occupation) => occupation.careers))

        const fullOccupation = find(
          [...allOccupations, ...mCareers],
          (occupation) => occupation.occ_code === plan.data.occupation.occ_code
        )

        plan.data.occupation = fullOccupation
      }

      setResult({ ...currentPlan, ...plan.data })

      dispatch(setLoading({ loading: false }))
    } catch (error) {
      handleError(error, 'Error fetching plan data')
    }
  }

  useEffect(() => {
    if (birthDateVisible === false && currentPlan && currentPlan.id) {
      fetchAndSetData(currentPlan)
    }
  }, [birthDateVisible, currentPlan, isFocused, events.length])

  const offset = useRef(new Animated.Value(0)).current

  const height = 400

  const opacity = offset.interpolate({
    inputRange: [0, height - 250],
    outputRange: [0, 1],
    extrapolate: 'clamp',
    useNativeDriver: true
  })

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      title: (
        <Animated.View style={{ opacity }}>
          <Text style={styles.headerText}>Plan Summary</Text>
        </Animated.View>
      )
    })
  }, [navigation, currentPlan, saving])

  function progressContent(percent) {
    return <Text style={styles.progressContent}>{`${percent.toFixed(0)}%`}</Text>
  }

  function loadingBlock() {
    return <View style={styles.loadingBlock} />
  }

  const { probability_of_success, plan_adequacy } = result
  const { created } = result || null

  const planAdequacy = plan_adequacy || {}
  const probabilityOfSuccess = probability_of_success || 0

  let chanceColor = indianYellow
  if (probabilityOfSuccess > 70) {
    chanceColor = primary
  } else if (probabilityOfSuccess < 30) {
    chanceColor = magicPotion
  }

  const planAdequacyFivePercent = planAdequacy.plan_adequacy ? planAdequacy.plan_adequacy['5%'] : {}
  const adequacy =
    result.contribution_selection !== 'PENSION'
      ? planAdequacyFivePercent && planAdequacyFivePercent.adequacy
        ? planAdequacyFivePercent.adequacy * 100
        : 0
      : result.plan_adequacy?.plan_adequacy?.essentials?.adequacy * 100 ?? 0

  let adequacyColor = indianYellow
  let adequacyLabel = 'Fair'

  if (adequacy > 70) {
    adequacyColor = primary
    adequacyLabel = 'Good'
  } else if (adequacy < 30) {
    adequacyColor = magicPotion
    adequacyLabel = 'Poor'
  }

  const planSummaryOptions = [
    {
      label: 'Likelihood',
      sublabel: (
        <Text style={styles.sublabel}>
          You have a<Text style={{ color: chanceColor }}> {probabilityOfSuccess}% chance</Text> of
          achieving your goal!
        </Text>
      ),
      leftComponent: (
        <CircularProgress
          progress={probabilityOfSuccess}
          size={isNotWeb ? 110 : 72}
          width={isNotWeb ? 9 : 5}
          fillFunction={() => progressContent(probabilityOfSuccess)}
          tintColor={chanceColor}
        />
      ),
      onPress: () => {
        navigation.navigate('Likelihood', {
          probabilityOfSuccess,
          chanceColor,
          isPension: result.contribution_selection === 'PENSION'
        })
      }
    },
    {
      label: 'Estimated Value',
      sublabel: (
        <Text style={styles.sublabel}>
          {result.contribution_selection === 'PENSION'
            ? 'Amount received if pension benefits are taken as a single payment'
            : 'Projected scenarios based on historical market performance'}
        </Text>
      ),
      leftComponent: (
        <View style={{ alignItems: 'center', height: isNotWeb ? 'auto' : 72 }}>
          <FontAwesomeIcon
            icon={['fas', 'arrow-trend-up']}
            size={isNotWeb ? 54 : 40}
            color={info}
          />
          {loading ? (
            loadingBlock()
          ) : (
            <Text style={{ fontWeight: '600', fontSize: isNotWeb ? 30 : 24 }}>
              ${abbreviateCurrency(result?.projected_portfolio_value ?? 0)}
            </Text>
          )}
        </View>
      ),
      isPension: result.contribution_selection === 'PENSION',
      onPress: () => navigation.navigate('Estimated Value', { result, scenario, years })
    },
    {
      label: 'Adequacy',
      sublabel: (
        <View
          style={{
            flexDirection: isNotWeb ? 'column' : 'row',
            alignItems: isNotWeb ? 'flex-start' : 'center'
          }}
        >
          <Text style={styles.sublabel}>Is your goal enough?</Text>
          <PillLabel
            width={50}
            style={{
              padding: 1,
              marginLeft: 2,
              marginVertical: isNotWeb ? 6 : 0,
              backgroundColor: rgba(adequacyColor, 0.1)
            }}
            textStyle={{
              color: adequacyColor,
              textTransform: 'capitalize',
              fontSize: 12,
              letterSpacing: 0
            }}
            title={adequacyLabel}
          />
        </View>
      ),
      leftComponent: (
        <CircularProgress
          progress={adequacy}
          size={isNotWeb ? 110 : 72}
          width={isNotWeb ? 9 : 5}
          fillFunction={() => progressContent(adequacy)}
          tintColor={adequacyColor}
        />
      ),
      onPress: () =>
        navigation.navigate('Adequacy Score', {
          result,
          adequacy,
          adequacyColor,
          adequacyLabel,
          plan: currentPlan
        })
    }
  ]

  const playSummaryHeaderOptions = [
    {
      label: 'Likelihood',
      value: formatPercent(probabilityOfSuccess),
      color: chanceColor,
      backgroundColor: rgba(chanceColor, 0.1),
      icon: 'bullseye',
      onPress: () => {
        navigation.navigate('Likelihood', { probabilityOfSuccess, chanceColor })
      }
    },
    {
      label: 'Estimated Value',
      value: `$${abbreviateCurrency(result?.projected_portfolio_value ?? 0)}`,
      color: info,
      backgroundColor: rgba(info, 0.1),
      icon: 'stars',
      onPress: () => navigation.navigate('Estimated Value', { result, scenario, years })
    },
    {
      label: 'Adequacy',
      value: formatPercent(adequacy, 0),
      color: adequacyColor,
      backgroundColor: rgba(adequacyColor, 0.1),
      icon: 'chart-simple',
      onPress: () =>
        navigation.navigate('Adequacy Score', {
          result,
          adequacy,
          adequacyColor,
          adequacyLabel,
          plan: currentPlan
        })
    }
  ]

  const appConfig = useSelector((state) => appConfigSelector(state))

  const proEnabled =
    appConfig?.enable_pro?.state && appConfig?.enable_pro?.enabled && currentUser?.is_pro

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <CircleGradient />

      {!isNotWeb && <PlanSummaryPageHeader />}

      <PlanSummaryHeader
        loading={loading}
        name={currentPlan?.name}
        created={created}
        onPressSave={setVisible}
        animatedValue={offset}
        options={playSummaryHeaderOptions}
        planSummaryOptions={planSummaryOptions}
      />

      <View style={{ flex: 1, position: 'relative' }}>
        <ScrollView
          scrollEventThrottle={16}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], {
            useNativeDriver: false
          })}
          style={styles.summaryContainer}
        >
          {isNotWeb && (
            <PlanSummaryOptions
              loading={loading}
              name={currentPlan?.name}
              created={created}
              animatedValue={offset}
              onPressSave={setVisible}
              options={planSummaryOptions}
            />
          )}

          <View style={styles.summaryDetailsContainer}>
            {proEnabled && (
              <ProNudge
                disabled={isEmpty(result)}
                onPress={() => {
                  dispatch(getBSAccounts())
                  navigation.navigate('Modal', {
                    screen: 'Upgrade Plan Intro',
                    params: { freePlan: { ...currentPlan, ...result } }
                  })
                }}
              />
            )}

            <PlanDetailsCard
              loading={loading}
              duration={duration}
              setDuration={setDuration}
              currentUser={currentUser}
              result={result}
              currentPlan={currentPlan}
            />

            {isNotWeb && <StartInvestingCard navigation={navigation} />}

            <LivingEssentialsCard
              duration={duration}
              setDuration={setDuration}
              loading={loading}
              result={result}
              navigation={navigation}
            />

            <SurplusCard
              duration={duration}
              setDuration={setDuration}
              loading={loading}
              result={result}
              navigation={navigation}
            />

            {!isNotWeb && <StartInvestingCard navigation={navigation} />}

            {result.contribution_selection !== 'PENSION' && (
              <LifeEventCard
                width={width}
                navigation={navigation}
                isFocused={isFocused}
                plan={currentPlan}
                data={result?.summary_stats?.average ? result.summary_stats.average : {}}
              />
            )}

            {currentUser && !currentUser.is_fake && (
              <Button
                disabled={currentPlan.is_primary_plan}
                onPress={async () => {
                  dispatch(setLoading({ loading: true }))
                  dispatch(assignPrimaryPlan(true))
                  dispatch(setLoading({ loading: false }))
                }}
                style={styles.activateBtn}
              >
                {currentPlan.is_primary_plan ? 'Current Active Plan' : 'Set as Active Plan'}
              </Button>
            )}

            <NamePlanSlideupDrawer
              plan={currentPlan}
              visible={visible}
              setVisible={setVisible}
              setSaving={setSaving}
            />

            <BirthDateIntroModal visible={birthDateVisible} setVisible={setBirthDateVisible} />
          </View>
        </ScrollView>
      </View>
    </View>
  )
}
