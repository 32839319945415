import {
  faCheck,
  faMinusCircle,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const styles = StyleSheet.create({
  talkBubble: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    top: 20,
    borderColor: 'transparent',
    padding: 10,
    borderRadius: 10,
    zIndex: 0
  },
  innerTriangle: {
    position: 'relative',
    top: 20,
    left: 30,
    width: 0,
    zIndex: 4,
    height: 0,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    borderBottomWidth: 10,
    borderBottomColor: 'white',
    borderRightWidth: 10,
    borderRightColor: 'transparent'
  }
})
function ExpandableItem({ title, content }) {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }
  function splitContent(content) {
    const myBrokeContentIntro = content.slice(0, content.indexOf('!') + 1)
    const myBrokeContentFinal = content.slice(
      content.indexOf('!') + 2,
      content.length
    )

    return (
      <>
        <View style={styles.innerTriangle} />
        <View style={styles.talkBubble}>
          <Text style={{ fontSize: 16, fontWeight: '500', marginBottom: 10 }}>
            {myBrokeContentIntro}
          </Text>
          <Text>{myBrokeContentFinal}</Text>
        </View>
      </>
    )
  }

  return (
    <View>
      <TouchableOpacity
        onPress={toggleExpand}
        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <View
          style={{ flexDirection: 'row', alignItems: 'center', width: 300 }}
        >
          <View style={{ marginRight: 10 }}>
            <FontAwesomeIcon icon={faCheck} size={18} color="#7CBA82" />
          </View>
          <Text style={{ fontWeight: '600', fontSize: 18 }}>{title}</Text>
        </View>
        <View style={{ width: 50 }}>
          <FontAwesomeIcon
            icon={expanded ? faMinusCircle : faPlusCircle}
            size={18}
            color="#D1D7D9"
            style={{ alignSelf: 'flex-end' }}
          />
        </View>
      </TouchableOpacity>
      {expanded && (
        <View style={{ marginTop: 0, marginBottom: 40 }}>
          {splitContent(content)}
        </View>
      )}
    </View>
  )
}

export default ExpandableItem
