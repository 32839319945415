import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { DEFAULT_SPACING, } from '../styles';

import CircleCheckBox from './CircleCheckBox';
import InputWrapper from './InputWrapper';

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    marginHorizontal: 0,
    paddingHorizontal: 0
  },
  checkboxContainer: { marginTop: DEFAULT_SPACING, },
  checkbox: { marginTop: DEFAULT_SPACING / 2 }
})

export default function RadioGroup(props) {
  const { options, value, onPress } = props

  return (
    <InputWrapper
      {...props}
      style={styles.container}
    >
      <View style={styles.checkboxContainer}>
        {options.map((option, index) => (
          <CircleCheckBox
            key={index}
            label={option.leftText}
            checked={value === option.value}
            styleCheckboxContainerStyle={styles.checkbox}
            onPress={(value) => {
              if (value) onPress(option.value)
            }}
          />
        ))}
      </View>
    </InputWrapper>
  )
}
