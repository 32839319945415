import { useState } from 'react'
import { View, Text } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import CircleIcon from '../CircleIcon'
import TooltippedText from '../TooltippedText'
import { grayblue, gray, rgba } from '../../styles/colors'

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginVertical: 12
    },
    iconContainer: {
      width: '50%',
      flexDirection: 'row',
      alignItems: 'center'
    },
    headerContainer: { marginLeft: 12 },
    header: {
      fontSize: 18,
      color: grayblue,
      fontWeight: '600'
    },
    subheader: {
      fontSize: 12,
      color: gray
    }
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      header: { fontSize: 22, },
      subheader: { fontSize: 16, }
    },
  }
)

export default function CardHeader({ RightIcon, rightIcon, rightColor, rightHeader, rightSubheader, rightSubheaderTooltip, leftIcon, LeftIcon, leftColor, leftHeader, leftSubheader, leftSubheaderTooltip }) {
  const styles = useStyles()

  function HeaderAndSubtitle({ header, subheader, headerTooltipBody, subheaderTooltipBody, icon, Icon, color }) {
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const [subheaderTooltipIsVisible, setSubheaderTooltipIsVisible] = useState(false)

    return (
      <View style={styles.iconContainer}>
        <CircleIcon size={20} icon={icon || Icon} color={color || grayblue} backgroundColor={rgba(color || grayblue, 0.1)} />
        <View style={styles.headerContainer}>

          {!tooltipVisible && <Text style={styles.header}>{header}</Text>}
          {tooltipVisible
            && (
              <TooltippedText
                visible={subheaderTooltipIsVisible}
                setVisible={setTooltipVisible}
                anchorStyle={[styles.header]}
                anchorText={header}
                contentHeader={header}
                contentBody={headerTooltipBody}
              />
            )}

          {!subheaderTooltipBody && <Text style={styles.subheader}>{subheader}</Text>}
          {subheaderTooltipBody
            && (
              <TooltippedText
                visible={subheaderTooltipIsVisible}
                setVisible={setSubheaderTooltipIsVisible}
                anchorStyle={[styles.subheader]}
                anchorText={subheader}
                contentHeader={subheader}
                contentBody={subheaderTooltipBody}
              />
            )}

        </View>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <HeaderAndSubtitle
        icon={rightIcon}
        Icon={RightIcon}
        header={rightHeader}
        subheader={rightSubheader}
        subheaderTooltipBody={rightSubheaderTooltip}
        color={rightColor}
      />

      <HeaderAndSubtitle
        icon={leftIcon}
        Icon={LeftIcon}
        header={leftHeader}
        subheader={leftSubheader}
        subheaderTooltipBody={leftSubheaderTooltip}
        color={leftColor}
      />
    </View>
  )
}
