import { ActivityIndicator } from 'react-native'
import { primary, white } from '../styles/colors'
import Link from './Link'

export default function Button({
  style,
  color,
  disabled = false,
  titleStyle,
  onPress,
  icon,
  type,
  isSubmitting,
  inverted,
  withBorder = true,
  children,
  actionName
}) {
  const rectangle = {
    paddingVertical: 17,
    borderRadius: 15.4,
    backgroundColor: disabled ? 'rgb(183, 197, 204)' : color || primary,
    alignSelf: 'stretch'
  }

  if (inverted) {
    rectangle.backgroundColor = 'transparent'
    rectangle.borderColor = disabled ? 'rgb(183, 197, 204)' : color || primary
    rectangle.borderWidth = 2
  }

  if (!withBorder) {
    rectangle.borderWidth = 0
  }

  const title = {
    fontSize: 19.2,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0.05,
    textAlign: 'center',
    color: white
  }

  const activityIndicator = { marginRight: 12 }

  if (inverted) {
    title.color = disabled ? 'rgb(183, 197, 204)' : color || primary
  }

  return (
    <Link action={actionName} type={type} icon={icon} disabled={disabled} onPress={onPress} containerStyle={[rectangle, style]} style={[title, titleStyle]}>
      {isSubmitting && <ActivityIndicator style={activityIndicator} color="white" size="small" />}
      {children}
    </Link>
  )
}
