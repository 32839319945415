import { post, } from 'http'
import { setCurrentPlan } from './setCurrentPlan'
import { getPlans } from './getPlans'
import { getCurrentUser } from './getCurrentUser'
import { formatFinancialPayload } from '../api/financialBreakdown'
import handleError from '../functions/handleError'

export const saveFastPlan = function (plan) {
  return async (dispatch) => {
    try {
      const payload = formatFinancialPayload(plan);
      const result = await post('/api/v1/plans/', payload)
      dispatch(setCurrentPlan(result.data))
      dispatch(getPlans())
      dispatch(getCurrentUser())
    } catch (error) {
      handleError(error, 'Oops. Looks like something went wrong.')
    }
  }
}
