import { Platform, View, Text } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { DEFAULT_SPACING } from '../styles'
import { darkblue } from '../styles/colors'
import TouchableOpacity from './TouchableOpacity'
import Button from './Button'
import SlideupDrawer from './SlideupDrawer'

const useStyles = CreateResponsiveStyle(
  {
    touchable: { textDecorationLine: 'underline', },
    finn: {
      width: 50,
      height: '100%',
      marginRight: DEFAULT_SPACING,
    },
    content: {
      backgroundColor: '#151E27',
      padding: DEFAULT_SPACING,
      borderRadius: 10,
    },
    contentContainer: { width: '100%', },
    contentHeader: {
      fontSize: 24,
      color: 'rgb(40, 58, 73)',
      fontWeight: '700',
    },
    contentBody: {
      color: darkblue,
      fontSize: 14,
      paddingHorizontal: DEFAULT_SPACING,
    },
    closeButton: {
      position: 'absolute',
      top: -10,
      right: -10,
      height: 16,
      width: 16,
    },
    contentTextContainer: { flex: 1, },
    button: {
      marginTop: DEFAULT_SPACING * 2,
      marginHorizontal: DEFAULT_SPACING,
    },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      touchable: { textDecorationLine: 'underline', },
      finn: {
        width: 50,
        height: '100%',
        marginRight: DEFAULT_SPACING,
      },
      content: {
        backgroundColor: '#151E27',
        padding: DEFAULT_SPACING,
        borderRadius: 10,
      },
      contentContainer: { width: '100%', },
      contentBody: {
        color: darkblue,
        fontSize: 14,
        paddingHorizontal: 0,
        marginTop: 16,
      },
      closeButton: {
        position: 'absolute',
        top: -10,
        right: -10,
        height: 16,
        width: 16,
      },
      contentTextContainer: { flex: 1, },
      button: {
        marginTop: DEFAULT_SPACING * 2,
        marginHorizontal: DEFAULT_SPACING,
      },
    },
  }
)

function Content({
  contentHeader,
  contentBody,
  onButtonPress,
  buttonText,
  setVisible,
}) {
  const styles = useStyles()
  function handlePress() {
    setVisible(false)
    onButtonPress && onButtonPress()
  }

  return (
    <View style={styles.contentContainer}>
      {Platform.OS !== 'web' ? '' : <Text style={styles.contentHeader}>{contentHeader}</Text>}
      <Text style={styles.contentBody}>{contentBody}</Text>
      {buttonText && (
        <Button style={styles.button} onPress={handlePress}>
          {buttonText}
        </Button>
      )}
    </View>
  )
}

export default function TooltippedText({
  visible,
  setVisible,
  contentHeader,
  contentBody,
  anchorText,
  anchorStyle,
  icon,
  iconColor,
  onButtonPress,
  buttonText,
  snapPointIndex = 0,
  scrollable = false,
}) {
  const styles = useStyles()
  const isNotWeb = Platform.OS !== 'web'

  return (
    <View style={{ flexDirection: 'row' }}>
      <SlideupDrawer scrollable={scrollable} snapPointIndex={snapPointIndex} icon={icon} iconColor={iconColor} closeEnabled title={contentHeader} visible={visible} setVisible={setVisible}>
        <Content onButtonPress={onButtonPress} buttonText={buttonText} setVisible={setVisible} contentHeader={contentHeader} contentBody={contentBody} />
      </SlideupDrawer>

      <TouchableOpacity
        action={`Tooltip: ${anchorText}`}
        onPress={() => setVisible(true)}
      >
        <Text style={[anchorStyle, styles.touchable]}>{anchorText}</Text>
      </TouchableOpacity>
    </View>
  )
}
