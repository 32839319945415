import { StyleSheet } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { darkblue } from '../styles'

const styles = StyleSheet.create({ arrowLeftStyle: { marginHorizontal: 12 } })

export default function HeaderBackImage(props) {
  const { fill, tintColor } = props || { fill: darkblue }
  let color = (tintColor || fill)
  color = color && color === '#ffffff' ? color : darkblue

  return (
    <FontAwesomeIcon
      icon={['fas', 'arrow-left']}
      style={styles.arrowLeftStyle}
      size={26}
      color={color}
    />
  )
}
