import moment from 'moment'
import * as Yup from 'yup'
import { MONTHLY_PERIODICITY } from '../constants'

import { REQUIRED } from './constants'

export const Fields = {
  name: 'name',
  amount: 'amount',
  date: 'date',
  budgetSubCategory: 'budgetSubCategory',
  budgetCategory: 'budgetCategory',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.name]: Yup.string().required(REQUIRED),
  [Fields.amount]: Yup.number().positive().required(REQUIRED),
  [Fields.date]: Yup.date().required(REQUIRED),
  [Fields.budgetCategory]: Yup.string().required(REQUIRED),
  [Fields.budgetSubCategory]: Yup.string().required(REQUIRED),
})

export const InitialValues = (transaction, bc, bsc) => {
  const today = moment()

  const {
    name = '',
    amount = 0.0,
    date = today,
    budgetSubCategory = bsc ? bsc.key : '',
    budgetCategory = bc ? bc.key : '',
  } = transaction

  return {
    [Fields.name]: name,
    [Fields.amount]: amount,
    [Fields.date]: date,
    [Fields.budgetSubCategory]: budgetSubCategory,
    [Fields.budgetCategory]: budgetCategory
  }
}
