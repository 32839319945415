import { useEffect } from 'react'
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  Image,
  ActivityIndicator,
  Platform
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { map, sortBy, find, filter, isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import CareerCardPlaceholderButton from './components/planBuilder/CareerCardPlaceholderButton'
import CareerCard from './components/CareerCard'
import Button from './components/Button'
import HeaderLabel from './components/HeaderLabel'
import ShareAndEmpowerCard from './components/ShareAndEmpowerCard'
import ProCareerCard from './components/ProCareerCard'

import { setCurrentPlan, setFastPlanModeData } from './actions'
import {
  currentUserSelector,
  deviceSelector,
  loadingSelector,
  plansSelector,
  primaryProPlanSelector,
  proPlansSelector
} from './selectors'

import { gunmetal, lightestgray } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const basicStyles = {
  container: {
    backgroundColor: lightestgray,
    position: 'relative'
  },
  activePlanWrapper: { marginHorizontal: DEFAULT_SPACING },
  shareCard: {
    marginTop: DEFAULT_SPACING * 2,
    marginBottom: 100
  },
  careerList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: DEFAULT_SPACING,
    paddingHorizontal: DEFAULT_SPACING
  },
  careerCard: { width: '49%' }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    activePlanWrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginVertical: DEFAULT_SPACING
    },
    shareCard: { marginBottom: 40 },
    careerList: { justifyContent: 'flex-start', paddingRight: 0 },
    careerCard: { width: 'calc(33.33% - 12px)', marginRight: DEFAULT_SPACING }
  }
})

const isNotWeb = Platform.OS !== 'web'

function Plans({ plans, currentUser, navigation, dispatch, device }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  /**
   * If there is a primary pro plan, then the primary plan is the free plan associated with it
   * Otherwise its the regular primary plan
   * */
  const primaryProPlan = useSelector(primaryProPlanSelector)
  let primaryPlan = isEmpty(primaryProPlan)
    ? find(plans, (plan) => plan.is_primary_plan === true) // Use primary free plan
    : find(plans, (plan) => plan.id === primaryProPlan.freePlanId) // Use the underlying free plan

  // if no primary plan, use the last free plan
  primaryPlan = primaryPlan || find(plans, (plan) => plan.is_primary_plan === true)

  const otherPlans = primaryPlan ? filter(plans, (plan) => plan.id !== primaryPlan.id) : []
  const proPlans = useSelector(proPlansSelector)

  return (
    <View style={{ paddingVertical: DEFAULT_SPACING }}>
      {primaryPlan && (
        <>
          <HeaderLabel style={{ paddingHorizontal: DEFAULT_SPACING }}>My Active Plan</HeaderLabel>

          <View style={styles.activePlanWrapper}>
            {!isEmpty(primaryProPlan) && primaryProPlan.freePlanId ? (
              <ProCareerCard
                style={{ width: '100%' }}
                key={primaryPlan.id}
                navigation={navigation}
                dispatch={dispatch}
                vertical={false}
                freePlan={primaryPlan}
                proPlan={primaryProPlan}
                primary
              />
            ) : (
              <CareerCard
                key={primaryPlan.id}
                style={{ width: '100%' }}
                navigation={navigation}
                dispatch={dispatch}
                form={primaryPlan}
                vertical={false}
                primary
              />
            )}
          </View>
        </>
      )}

      <HeaderLabel
        style={{
          paddingHorizontal: DEFAULT_SPACING,
          marginTop: DEFAULT_SPACING
        }}
      >
        Other Plans
      </HeaderLabel>
      <View style={styles.careerList}>
        <CareerCardPlaceholderButton
          onPress={() => {
            const birth_date = currentUser.is_fake ? null : currentUser.birth_date
            dispatch(setCurrentPlan({ step: 0, device, birth_date }))
            navigation.navigate('Modal', { screen: 'Plan Builder Intro' })
          }}
        />
        {otherPlans.length > 0 &&
          map(otherPlans, (career, index) => {
            const proPlan = find(proPlans, (proPlan) => proPlan.freePlanId === career.id)
            const isPro = !isEmpty(proPlan)

            const commonProps = {
              navigation,
              dispatch,
              form: career
            }

            const CardComponent = isPro ? ProCareerCard : CareerCard

            return (
              <View key={career.id} style={styles.careerCard}>
                <CardComponent
                  {...commonProps}
                  freePlan={isPro ? career : undefined}
                  proPlan={isPro ? proPlan : undefined}
                />
              </View>
            )
          })}
      </View>
    </View>
  )
}

function NoState({ subtitle }) {
  return (
    <View style={{ alignItems: 'center' }}>
      <Image
        style={{ width: 200, height: 200 }}
        source={require('./assets/empty-state-saved-plans.png')}
      />
      <Text
        style={{
          fontSize: 12,
          color: gunmetal,
          letterSpacing: -0.4,
          textAlign: 'center',
          marginBottom: 12
        }}
      >
        {subtitle}
      </Text>
    </View>
  )
}

export default function PlanBuilderScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const currentUser = useSelector((state) => currentUserSelector(state))
  const device = useSelector((state) => deviceSelector(state))
  const plans = sortBy(
    useSelector((state) => plansSelector(state)),
    'modified'
  ).reverse()
  const { loading } = useSelector((state) => loadingSelector(state))

  const dispatch = useDispatch()

  const initalState = plans.length === 0

  useEffect(() => {
    dispatch(setFastPlanModeData({ fastMode: false }))
  }, [])

  return (
    <ScrollView style={styles.container}>
      {!loading && !initalState && (
        <Plans
          device={device}
          plans={plans}
          currentUser={currentUser}
          navigation={navigation}
          dispatch={dispatch}
        />
      )}

      {!loading && initalState && (
        <NoState
          subtitle={
            'Explore millions of unique data points with \n near infinite combinations and personalize the \n goals that matter most to you.'
          }
          image={<FontAwesomeIcon name="folder-open" color="#B7C5CC" size={48} />}
        />
      )}

      {loading && <ActivityIndicator size="large" />}

      {!loading && initalState && (
        <Button
          style={{ margin: DEFAULT_SPACING }}
          onPress={() => {
            const birth_date = currentUser.is_fake ? null : currentUser.birth_date
            dispatch(setCurrentPlan({ step: 0, device, birth_date }))
            navigation.navigate('Modal', { screen: 'Plan Builder Intro' })
          }}
        >
          Create a New Financial Plan
        </Button>
      )}

      <View style={styles.shareCard}>
        <ShareAndEmpowerCard />
      </View>
    </ScrollView>
  )
}
