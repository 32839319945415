import Card from './Card'
import CardHeader from './CardHeader'

import formatPercent from '../../functions/formatPercent'
import rangeOrSingle from '../../functions/rangeOrSingle'

export default function AdmissionsCard({ college }) {
  const header = (
    <CardHeader
      rightIcon="arrow-right-to-bracket"
      rightColor="#E547AD"
      rightHeader={(college.adm_rate || college.adm_rate_all) ? `${((college.adm_rate || college.adm_rate_all) * 100).toFixed(1)}%` : '-'}
      rightSubheader="Admission Rate"
      rightSubheaderTooltip={"Is a school hard to get into? Admission Rate shows the % of students who were accepted to attend a school vs. the total number of students who applied. Schools with a lower Admission Rate are more selective and difficult to get into. Though it is not always the case, schools with the lowest Admission Rates often require applicants to have very high SAT or ACT scores, high GPA's, and to have taken multiple honors, AP or IB classes in high school. If Admission Rate is low, it is often for good reason. Many schools in this group offer students generous financial aid, low Net Price, high Instructional Spending (per student), low Default Rate, and have graduates with some of the highest Median Earnings After Graduation."}

      leftIcon="users"
      leftColor="#7159DB"
      leftHeader={college.enrollment.toLocaleString('en-US') || '-'}
      leftSubheader="Enrollment"
    />
  )

  const {
    satmt25, satvr25, satmt75, satvr75, sat_avg_all, sat_avg,
    actcm25, actcm75, actcm_avg_all, actcm_avg
  } = college

  const rows = [{
    label: 'SAT Range',
    value: rangeOrSingle((satmt25) + (satvr25 || 0), (satmt75 || 0) + (satvr75 || 0), sat_avg_all || sat_avg),
    tooltipBody: "The SAT is a major college entrance exam. Scores range from 400 to 1600 points, with 1600 considered perfect. The exam is comprised of two parts: Math and Evidence-Based Reading and Writing (EBRW), each having a maximum score of 800 points. Traditionally, high SAT scores may increase a student's odds of admission into schools and also increase opportunities to receive scholarships.The Essay section was discontinued in 2021."
  }, {
    label: 'ACT Range',
    value: rangeOrSingle(actcm25, actcm75, actcm_avg_all || actcm_avg),
    tooltipBody: 'Like the SAT, the ACT is a major college entrance exam. A perfect score on the ACT is 36 points. Exam sections include English, Math, Reading, Science, and an optional Writing section. Some highly selective schools ask applicants to complete the Writing section, if they take the ACT.'
  }, {
    label: 'Applicants Applying to 5 or \nMore Schools',
    value: college.appl_sch_pct_ge5 ? formatPercent(college.appl_sch_pct_ge5 * 100) : '-',
    tooltipBody: 'Does a school attract applicants who have researched and are competing to get into many other schools? Troutwood includes data for Applicants Applying to 5 or More Schools because it may give some indication of how thoroughly a school is vetted by highly selective applicants.'
  }, {
    label: '1st Generation Students',
    value: college.par_ed_pct_1stgen ? formatPercent(college.par_ed_pct_1stgen * 100) : '-',
    tooltipBody: "The % of 1st Generation Students shows how many of a school's students are the first in their family to pursue higher education."
  }]

  return (
    <Card
      title="Admissions"
      header={header}
      rows={rows}
    />
  )
}
