import { forwardRef, useRef } from 'react'
import { StyleSheet, Platform, Keyboard } from 'react-native'
import CurrencyInput from 'react-native-currency-input'
import InputWrapper from './InputWrapper'

import { red } from '../styles/colors'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(242, 248, 250, 0.9)',
    flexDirection: 'column',
    padding: 2 * 5,
    borderRadius: 2 * 3,
    marginBottom: 24
  },
  label: {
    color: 'rgb(66, 86, 103)',
    fontSize: 12
  },
  input: {
    color: 'rgb(66, 86, 103)',
    fontSize: 18,
    marginTop: 2 * 2,
    fontWeight: '500',
    paddingVertical: 0,
    ...Platform.select({ web: { outlineStyle: 'none' } })
  },
  closeButton: {
    position: 'absolute',
    right: 2 * 6,
    top: 2 * 7,
    zIndex: 1000
  },
  icon: {
    position: 'absolute',
    left: 2 * 6,
    top: 2 * 7
  },
  error: {
    color: red,
    marginBottom: 12
  }
})

const _CurrencyInput = function (props, ref) {
  const {
    prefix,
    precision,
    errorMessage,
    touched,
    Icon,
    secureTextEntry,
    returnKeyType,
    onSubmitEditing,
    disabled,
    style,
    label,
    value,
    setValue,
    onChangeText,
    onBlur,
    onFocus,
    onPress,
    placeholder,
    clearable,
    name
  } = props

  // The reason we need the ref is so that if the user
  // presses on the touchable without feedback, we can focus on the
  // input. The user could also touch the label and we'd
  // want to do the same thing and focus on the input.
  const inputElement = ref || useRef(null)

  function inputPress() {
    setValue(null)
    Keyboard.dismiss()
  }

  return (
    <InputWrapper
      {...props}
      inputElement={inputElement}
      onPress={() => inputPress()}
    >
      <CurrencyInput
        ref={inputElement}
        placeholderTextColor="rgb(133, 151, 165)"
        placeholder={placeholder}
        value={value}
        precision={precision || 0}
        separator="."
        delimiter=","
        prefix={prefix || '$'}
        style={[styles.input, { marginLeft: Icon ? 2 * 18 : 0 }]}
        returnKeyType={returnKeyType || 'done'}
        returnKeyLabel={returnKeyType || 'done'}
        editable={!disabled}
        secureTextEntry={secureTextEntry}
        onChangeValue={(val, t) => {
          if (setValue) setValue(val)
          if (onChangeText) onChangeText({ target: { name, value: val } })
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        onPress={onPress}
        onSubmitEditing={onSubmitEditing}
      />
    </InputWrapper>
  )
}

export default forwardRef(_CurrencyInput)
