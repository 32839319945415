import { View, ScrollView, StyleSheet, Platform, SafeAreaView } from 'react-native'
import Svg, { Line } from 'react-native-svg'
import { lightblue, grayblue } from '../styles/colors'

import hapticFeedback from './hapticFeedback'
import Link from './Link'
import { DEFAULT_SPACING } from '../styles'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  modal: {
    padding: DEFAULT_SPACING,
    flex: 1,
    marginTop: DEFAULT_SPACING
  },
  closeContainer: {
    alignItems: 'flex-end',
    marginHorizontal: DEFAULT_SPACING,
    paddingTop: DEFAULT_SPACING
  },
  close: { zIndex: 1000 },
  closeButton: {
    color: lightblue,
    fontSize: 14,
    paddingVertical: DEFAULT_SPACING
  }
})

function PlatformSpecificModal() {
  if (Platform.OS == 'web') {
    return require('modal-react-native-web')
  }
  return require('react-native-modal').Modal
}

export default function FullScreenModal({ children, visible, setVisible, style, scrollEnabled, closeStrokeColor, useTextClose, closeable }) {
  const Modal = PlatformSpecificModal()
  const ViewElement = scrollEnabled == true ? ScrollView : View

  return (
    <Modal
      animationType="slide"
      animationIn="slideInUp"
      transparent={false}
      visible={visible}
      isVisible={visible}
    >
      {visible
        && (
          <>
            <SafeAreaView style={styles.closeContainer}>
              {closeable && !useTextClose
              && (
                <TouchableOpacity
                  style={styles.close}
                  onPress={() => {
                    hapticFeedback()
                    setVisible(false)
                  }}
                >
                  <Svg style={{ width: 40, height: 40 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <Line x1="15" y1="15" x2="25" y2="25" stroke={closeStrokeColor || grayblue} strokeWidth="2.5" strokeLinecap="round" strokeMiterlimit="10" />
                    <Line x1="25" y1="15" x2="15" y2="25" stroke={closeStrokeColor || grayblue} strokeWidth="2.5" strokeLinecap="round" strokeMiterlimit="10" />
                  </Svg>
                </TouchableOpacity>
              )}

              { closeable && useTextClose
              && <Link style={styles.closeButton} onPress={() => setVisible(false)}>Close</Link>}
            </SafeAreaView>

            <ViewElement style={[styles.modal, style]}>
              {children}
            </ViewElement>
          </>
        )}
    </Modal>
  )
}
