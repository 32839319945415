import { View, StyleSheet, Platform, Animated } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { white, darkJungleGreen } from '../../styles'

import BuilderProgressBar from './BuilderProgressBar'
import Breadcrumbs from '../Breadcrumbs'
import CircleGradient from '../CircleGradient'

const basicStyles = StyleSheet.create({
  container: { flex: 1 },
  cardTop: {
    backgroundColor: white,
    borderRadius: 20,
    flex: 1,
    paddingTop: Platform.OS !== 'android' ? 50 : 0
  },
  breadcrumbsWrapper: {},
  contentWrapper: { flex: 1 },
  gradientBg: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: -1,
    left: 0,
    top: 0
  }
})

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    container: { padding: 40 },
    cardTop: {
      paddingVertical: 36,
      paddingHorizontal: 46,
      width: 870,
      maxHeight: 'calc(100vh - 200px)',
      flex: 'none',
      overflow: 'hidden'
    },
    breadcrumbsWrapper: { paddingBottom: 0 }
  }
})

export default function BuilderWrapper({
  children,
  option,
  includeTopper = false,
  includeProgress = true,
  removeFormStepper = false
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  const options = [
    {
      label: 'Profile',
      icon: 'circle-user',
      incompleteBackgroundColor: darkJungleGreen
    },
    {
      label: 'Career',
      icon: 'briefcase',
      incompleteBackgroundColor: darkJungleGreen
    },
    {
      label: 'Goal',
      icon: 'piggy-bank',
      incompleteBackgroundColor: darkJungleGreen
    },
    {
      label: 'Review',
      icon: 'clipboard-list-check',
      incompleteBackgroundColor: darkJungleGreen
    }
  ]
  return (
    <View style={styles.container}>
      <CircleGradient height={'100%'} />

      {!removeFormStepper && (
        <>
          {!isNotWeb && (
            <View style={styles.breadcrumbsWrapper}>
              <Breadcrumbs mode="dark" />
            </View>
          )}

          {includeProgress && <BuilderProgressBar options={options} option={option} />}
          {!includeProgress && <View style={{ height: 120 }} />}
        </>
      )}
      {removeFormStepper && <Animated.View style={{ height: 170 }} />}
      <View style={includeTopper ? styles.cardTop : styles.contentWrapper}>{children}</View>
    </View>
  )
}
