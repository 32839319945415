import { useEffect, useState, useRef } from 'react'
import { StyleSheet, Text, View, Dimensions, TextInput, Keyboard, useWindowDimensions } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import SlideupDrawer from './SlideupDrawer'
import Button from './Button'
import Input from './Input'
import Form from '../components/Form'

import { plansSelector } from '../selectors'
import { darkblue, grayblue, gunmetal, lightestgray, lightgray } from '../styles/colors'
import { savePersonalMilestoneItem } from '../actions'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 20,
  },

  header: {
    fontWeight: '600',
    width: '100%',
    fontSize: 28,
    color: darkblue,
    zIndex: 100
  },
  body: {
    marginVertical: 20,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'left',
    zIndex: 100
  },
  input: { marginTop: 20,  marginBottom: 100 },

  button: { marginTop: -50 }
})

export default function CreateMilestoneSlideupDrawer({ visible, setVisible, onClose, screenToNavigate, currentGetWellness, navigation, currentUser }) {
  const dispatch = useDispatch()

  const [tempInput, setTempInput] = useState('');
  const inputValues = { input: '' }

  const windowDimensions = useWindowDimensions();

  function handleClose() {
    setVisible(false)
    setTimeout(() => {
      if (onClose) onClose()
    }, 1000)
  }
  function handlePress() {
    setVisible(false)
    dispatch(savePersonalMilestoneItem({ description: tempInput }))
    if (onClose) onClose()
  }
  const messageField = useRef()

  return (
    <SlideupDrawer snapPointIndex={1} visible={visible} setVisible={handleClose}>
      <View style={styles.container}>

        <Text style={styles.header}>New Milestone</Text>
        <Formik
          enableReinitialize
          showSubmitButton={false}
          initialValues={inputValues}
          onSubmit={async (values) => {
            await timeout(600)
            setTempInput(values.input)
          }}
        >
          {({ touched, values }) => (
            <>
              <Input
                ref={messageField}
                style={[styles.input, { width: windowDimensions.width - 60, height: 100, backgroundColor: lightestgray,  }]}
                label="Your Goal"
                onChangeText={(e) => { setTempInput(e) }}
                placeholder="Your Goal"
                returnKeyType="done"
                keyboardType="default"
                multiline={true}
                blurOnSubmit={true}
                onSubmitEditing={()=>{Keyboard.dismiss()}}
           
              />


              <Button
                onPress={handlePress}
                style={[styles.button, {}]}
                disabled={tempInput.length <= 0}
              >
                Save
              </Button>

            </>
          )}
        </Formik>
      </View>
    </SlideupDrawer>
  )
}
