import { put, post } from 'http'
import { getDebts } from './getDebts'
import handleError from '../functions/handleError'

function sanitize(payload) {
  for (const key in payload) {
    if (payload[key] === '') {
      delete payload[key]
    }
  }

  return payload
}

export const saveLoan = function (payload) {
  return async (dispatch) => {
    try {
      if (payload.category == 'CREDIT_CARD') {
        payload.type = 'REVOLVING_LOAN'
      } else {
        payload.type = 'TERM_LOAN'
      }

      payload.id ? await put(`loans/${payload.id}/`, sanitize(payload))
        : await post('loans/', sanitize(payload))

      dispatch(getDebts())
    } catch (e) {
      console.warn(e, e.response)
      handleError(e, 'Oops. Looks like something went wrong.')
    }
  }
}
