import { StyleSheet, View, Text, Image, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { map, orderBy } from 'lodash'
import {
  faChevronCircleUp,
  faChevronCircleDown
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { LinearGradient } from 'expo-linear-gradient'
import Button from '../Button'
import HeaderLabel from '../HeaderLabel'
import Card from '../Card'
import ListOptionItem from '../ListOptionItem'
import { savePersonalMilestoneItem } from '../../actions'

import { personalMilestonesSelector } from '../../selectors'

import { lightestgray, secondary } from '../../styles/colors'
import { DEFAULT_SPACING, white, lightgray } from '../../styles'
import TouchableOpacity from '../TouchableOpacity'
import lighthouse from '../../assets/icons/lighthouse.png'

import capitalize from '../../functions/capitalize'

const styles = StyleSheet.create({
  container: {
    marginHorizontal: DEFAULT_SPACING / 2,
    marginTop: DEFAULT_SPACING,
    width: '90%',
    height: 400
  },
  card: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING,
    margin: 10
  },
  headerLabel: {
    marginVertical: DEFAULT_SPACING,
    marginBottom: 10,
    fontSize: 15,
    position: 'relative'
  },
  milestoneHeaderLabel: {
    marginVertical: DEFAULT_SPACING,
    marginBottom: 0,
    fontSize: 15,
    fontWeight: '600',
    color: 'white',
    position: 'relative'
  },
  iconContainer: { alignItems: 'center' },
  progressContainer: {
    position: 'absolute',
    bottom: 5,
    margin: 0,
    width: '100%',
    justifyContent: 'center',
    left: 5,
    right: 5
  },
  progressBarContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  progressText: {
    fontSize: 12,
    color: secondary,
    marginTop: 5
  },
  linearGradient: {
    width: '100%',
    position: 'absolute',
    zIndex: 0,
    height: '100%',
    borderRadius: 12
  },
  headerContainer: {
    margin: 10,
    position: 'relative'
  },
  divider: { borderBottomColor: lightgray },
  pillLabel: {
    backgroundColor: lightgray,
    alignSelf: 'center',
    position: 'absolute',
    top: -10
  },
  pillLabelText: { color: white },
  boldText: {
    fontWeight: 'bold'
  },
  labelStyle: { fontSize: 12 },
  talkBubble: {
    position: 'relative',
    width: '100%',
    backgroundColor: lightestgray,
    borderWidth: 1,
    borderColor: 'transparent',
    padding: 10,
    borderRadius: 10,
    zIndex: 0
  }
})
function formatDate(dateString) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const parts = dateString.split(/[-:T]/)
  const year = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const day = parseInt(parts[2], 10)

  const formattedMonth = months[month - 1]

  return `${formattedMonth} ${day}, ${year}`
}
export default function MilestoneButton({
  onPress,
  navigation,
  expanded = false
}) {
  const dispatch = useDispatch()
  const personalMilestoneChecklist = useSelector((state) =>
    personalMilestonesSelector(state)
  )
  const groupedMilestoneChecklist = orderBy(
    personalMilestoneChecklist,
    'created',
    'desc'
  )
  const height = expanded && groupedMilestoneChecklist.length > 0 ? 400 : 90

  function sublabelMilestone(item) {
    return (
      <>
        <Text style={{ marginBottom: 50 }}>
          {`Added ${formatDate(item.created)}${item.is_complete ? '\n\n' : ''}`}
        </Text>
        {item.is_complete && (
          <View style={[styles.talkBubble, { flexDirection: 'row' }]}>
            <Text
              style={{
                fontSize: 15,
                fontWeight: '500',
                marginBottom: 0,
                textAlign: 'center',
                justifyContent: 'center'
              }}
            >
              👏
            </Text>
            <Text style={{ width: 200 }}>
              Way to go! You are making awesome progress towards your goals.
            </Text>
          </View>
        )}
      </>
    )
  }
  function decideOnPress() {
    if (groupedMilestoneChecklist.length > 0) {
      onPress()
    } else {
      navigation.navigate('Modal', { screen: 'Milestones' })
    }
  }
  return (
    <TouchableOpacity style={[styles.container, { height }]}>
      <LinearGradient
        colors={['#D453A9', '#9C2974']}
        style={[styles.linearGradient]}
        locations={[0, 1]}
      />
      <TouchableOpacity onPress={decideOnPress}>
        <View style={{ flexDirection: 'row' }}>
          <Image
            resizeMode="cover"
            style={{
              width: 60,
              height: 60,
              display: 'flex',
              marginLeft: 10,
              marginTop: 10
            }}
            source={lighthouse}
          />

          <View style={{ flexDirection: 'column', padding: 10 }}>
            <HeaderLabel style={styles.milestoneHeaderLabel}>
              {capitalize('Milestones')}
            </HeaderLabel>

            <Text style={[styles.progressText, { color: 'white' }]}>
              To <Text style={styles.boldText}>guide</Text> your journey
            </Text>
          </View>
        </View>
        <View
          style={{
            color: 'white',
            alignSelf: 'flex-end',
            fontSize: 7,
            right: 10,
            top: -70
          }}
        >
          <Button
            style={[
              styles.progressText,
              {
                color: 'white',
                alignSelf: 'flex-end',
                width: 40,
                fontWeight: '500',
                backgroundColor: 'transparent'
              }
            ]}
            withBorder={false}
            titleStyle={{ fontSize: 12 }}
            inverted
            color="white"
            onPress={() => {
              navigation.navigate('Modal', { screen: 'Milestones' })
            }}
          >
            <Text style={{ textAlign: 'right' }}>
              {groupedMilestoneChecklist.length > 0 ? `Edit` : `＋`}
            </Text>
          </Button>
          {groupedMilestoneChecklist.length > 0 && (
            <View
              style={{ width: 20, alignSelf: 'flex-end', paddingLeft: -20 }}
            >
              <FontAwesomeIcon
                icon={!expanded ? faChevronCircleDown : faChevronCircleUp}
                size={18}
                color="white"
              />
            </View>
          )}
        </View>
      </TouchableOpacity>
      {expanded && groupedMilestoneChecklist.length > 0 && (
        <Card
          style={[
            styles.card,
            { backgroundColor: 'white', height: height - 90, marginTop: -60 }
          ]}
        >
          <View style={styles.iconContainer} />
          <View>
            <ScrollView showsVerticalScrollIndicator={false}>
              {map(groupedMilestoneChecklist, (item) => (
                <ListOptionItem
                  key={`financial-wellness-item-${item.id}`}
                  labelStyle={styles.labelStyle}
                  onCheck={() => {
                    if (item.id) {
                      dispatch(
                        savePersonalMilestoneItem({
                          id: item.id,
                          description: item.description,
                          is_complete: !item.is_complete
                        })
                      )
                    }
                  }}
                  milestoneSubLabel={sublabelMilestone(item)}
                  onPress={() => {
                    if (item.id) {
                      dispatch(
                        savePersonalMilestoneItem({
                          id: item.id,
                          is_complete: !item.is_complete
                        })
                      )
                    }
                  }}
                  label={
                    item.description.length > 45
                      ? `${item.description.substring(0, 45)}...`
                      : item.description
                  }
                  checked={item.is_complete === null ? false : item.is_complete}
                />
              ))}
            </ScrollView>
          </View>
        </Card>
      )}
    </TouchableOpacity>
  )
}
