import { useEffect, useLayoutEffect, useState, } from 'react'
import { Text, StyleSheet, Platform, View, } from 'react-native'
import { useDispatch } from 'react-redux'
import ConfettiCannon from 'react-native-confetti-cannon'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import Button from '../Button'
import Link from '../Link'
import { getCurrentUser } from '../../actions'
import { darkblue, gunmetal, DEFAULT_SPACING, primary, white, } from '../../styles'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30,
    flex: 1,
  },
  organizationLogo: {
    width: 120,
    height: 120,
    marginVertical: DEFAULT_SPACING * 2
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    zIndex: 100,
    marginVertical: DEFAULT_SPACING * 2
  },
  body: {
    marginBottom: DEFAULT_SPACING * 4,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center',
    zIndex: 100,
    fontSize: 16
  },
  button: { marginHorizontal: DEFAULT_SPACING * 2 },
  link: {
    fontSize: 19.2,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0.05,
    textAlign: 'center',
    marginVertical: DEFAULT_SPACING * 2,
  },
})

const isNotWeb = Platform.OS !== 'web'

export default function Success({ navigation, invitation, onClose }) {
  const [shoot, setShoot] = useState(false);
  const { organization_name: organizationName } = invitation
  const dispatch = useDispatch()

  useEffect(() => {
    setShoot(true)
    dispatch(getCurrentUser())
  }, [])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  }, [navigation])

  function handlePress() {
    navigation.popToTop()
    navigation.pop()
    navigation.navigate('Modal', { screen: 'Plan Builder Intro' })
  }

  const handleClose = () => {
    if (isNotWeb) handleFinishPress()
    else onClose()
  }

  function handleFinishPress() {
    navigation.popToTop()
    navigation.pop()
  }

  return (
    <View style={styles.container}>
      {shoot && (
        <ConfettiCannon
          count={200}
          origin={{ x: -10, y: 0 }}
          explosionSpeed={350}
          fallSpeed={2500}
          onAnimationEnd={() => setShoot(false)}
        />
      )}
      {/* success icon */}
      <FontAwesomeIcon icon={['far', 'circle-check']} size={120} color={primary} />

      <Text style={styles.header}>Congratulations!</Text>
      <Text style={styles.body}>
        You are now a part of the
        {' '}
        {organizationName}
        {'\n'}
        team. You can access team information from
        {' '}
        {'\n'}
        your dashboard!
      </Text>

      <Button onPress={handlePress} style={styles.button}>Build Plan</Button>
      <Link onPress={handleClose} style={styles.link}>I'm Finished</Link>
    </View>
  )
}
