import { Platform, View, ScrollView, StyleSheet, ActivityIndicator } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { useSelector } from 'react-redux'
import SearchedOccupations from './SearchedOccupations'
import Occupations from './Occupations'
import { loadingSelector } from '../../selectors'
import { DEFAULT_SPACING } from '../../styles'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: 'rgb(235, 241, 244)',
    paddingHorizontal: DEFAULT_SPACING
  },
  scrollViewContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingVertical: DEFAULT_SPACING,
    paddingBottom: DEFAULT_SPACING
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  { [minSize(DEVICE_SIZES.LG)]: { container: { paddingHorizontal: 40, }, } }
)

export default function OccupationGroupList({ occupationGroup, onOccupationPress, careerSearch }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { loading } = useSelector((state) => loadingSelector(state))

  return (
    <View style={styles.container}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        keyboardDismissMode="on-drag"
        style={styles.scrollViewContainer}
      >

        {loading && !occupationGroup && <ActivityIndicator size="large" />}

        {careerSearch && careerSearch.length > 3
          && (
            <SearchedOccupations
              onOccupationPress={onOccupationPress}
              careerSearch={careerSearch}
              groupOptions={[occupationGroup]}
            />
          )}

        <Occupations
          name={occupationGroup.name}
          image={occupationGroup.image}
          careers={occupationGroup.careers}
          onOccupationPress={onOccupationPress}
        />

      </ScrollView>
    </View>
  )
}
