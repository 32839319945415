import { useEffect, useRef } from 'react'
import DropdownAlert from 'react-native-dropdownalert'
import InAppAlertService from '../functions/InAppAlertService'
import { white } from '../styles/colors'

export default function InAppAlert() {
  const refDropdownAlert = useRef()

  useEffect(() => {
    InAppAlertService.addDropDownListener(refDropdownAlert.current)
    return () => {
      InAppAlertService.removeDropDownListener(refDropdownAlert.current)
    }
  }, [refDropdownAlert])

  return (
    <DropdownAlert
      ref={refDropdownAlert}
      updateStatusBar={false}
      closeInterval={4000}
      messageStyle={{ alignSelf: 'center', color: white }}
      titleStyle={{ alignSelf: 'center', color: white, fontWeight: 'bold' }}
      successImageSrc={null}
      zIndex={1}
    />
  )
}
