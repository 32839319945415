import { useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import PropTypes from 'prop-types'
import moment from 'moment'
import { white } from '../styles/colors'
import DeleteSlideupDrawer from './DeleteSlideupDrawer'
import NamePlanSlideupDrawer from './plans/NamePlanSlideupDrawer'

import planNameOrOccupationName from '../functions/planNameOrOccupationName'
import investmentSelection from '../functions/investmentSelection'

import {
  deleteProPlan,
  setCurrentProPlan
} from '../actions'
import CareerCardBase, { CARD_BORDER_RADIUS } from './CareerCardBase'

const proFlagStyles = StyleSheet.create({
  container: { position: 'absolute', top: 10, right: 0 },
  cornerTriangle: {
    backgroundColor: 'transparent',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderLeftWidth: 50,
    borderTopWidth: 50,
    borderLeftColor: 'transparent',
    borderTopColor: '#3491F7',
    borderTopRightRadius: CARD_BORDER_RADIUS
  },
  iconStyle: {
    color: white,
    position: 'absolute',
    top: 7,
    right: 7,
  }
})

function ProFlag() {
  return (
    <View style={proFlagStyles.container}>
      <View style={proFlagStyles.cornerTriangle} />
      <FontAwesomeIcon
        icon={['far', 'gem']}
        style={proFlagStyles.iconStyle}
        size={17}
      />
    </View>
  )
}

export default function ProCareerCard({
  proPlan,
  freePlan,
  actionButton,
  vertical,
  primary,
  navigation,
  style
}) {
  const dispatch = useDispatch()
  const [deleteDrawerVisible, setDeleteDrawerVisible] = useState(false)
  const [nameDrawerVisible, setNameDrawerVisible] = useState(false)

  const { id, city, modified, } = freePlan
  const { name, goal } = proPlan
  const { amount, horizonDate } = goal
  const periods = moment(horizonDate).diff(moment(), 'years')

  const { shieldScores = [] } = proPlan || {};
  const likelihood = shieldScores.find((score) => score.type === 'LIKELIHOOD') || {};
  const likelihoodScore = likelihood.score || 0;

  const handleView = () => {
    dispatch(setCurrentProPlan(proPlan))
    navigation.navigate('Modal', { screen: 'Plan Summary' })
  }

  const handleDelete = async () => {
    setTimeout(() => { // Because another modal is being closed before this one is opened
      setDeleteDrawerVisible(true)
    }, 400)
  }

  const handleRename = () => {
    setTimeout(() => {
      // dispatch(setCurrentPlan(form))
      setNameDrawerVisible(true)
    }, 400)
  }

  const handleDeleteConfirmation = () => {
    dispatch(deleteProPlan(proPlan.planId))
    setDeleteDrawerVisible(false)
  }

  return (
    <>
      <CareerCardBase
        key={`career-card-${id}-${modified}`}
        careerInfo={{
          id,
          modified,
          name,
          city,
          periods,
          investmentSelection: null,
          amount,
          confidenceInterval: likelihoodScore,
        }}
        vertical={vertical}
        primary={primary}
        pro
        onDelete={handleDelete}
        onRename={handleRename}
        onView={handleView}
        actionButton={actionButton}
        style={style}
      />
      {!primary && <ProFlag />}

      <NamePlanSlideupDrawer
        plan={{ name: proPlan.name, userId: proPlan.userId, planId: proPlan.planId, pro: true }}
        visible={nameDrawerVisible}
        setVisible={setNameDrawerVisible}
      />

      <DeleteSlideupDrawer
        visible={deleteDrawerVisible}
        setVisible={setDeleteDrawerVisible}
        onPress={handleDeleteConfirmation}
        header="Delete Pro Plan"
        body="Are you sure you want to delete this plan?"
        buttonText="Delete Plan"
      />
    </>
  )
}

ProCareerCard.defaultProps = {
  vertical: true,
  style: {},
  primary: false,
  actionButton: null
}

ProCareerCard.propTypes = {
  freePlan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    projected_portfolio_value: PropTypes.number.isRequired,
    city: PropTypes.object.isRequired,
    modified: PropTypes.string.isRequired,
    periods: PropTypes.number.isRequired,
    investment_selection: PropTypes.string.isRequired,
    probability_of_success: PropTypes.number.isRequired
  }).isRequired,
  proPlan: PropTypes.object.isRequired,
  actionButton: PropTypes.object,
  vertical: PropTypes.bool,
  primary: PropTypes.bool,
  navigation: PropTypes.object.isRequired,
  style: PropTypes.object
}
