import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Text, StyleSheet } from 'react-native'
import TouchableOpacity from '../TouchableOpacity'

import { darkgrayblue, DEFAULT_SPACING } from '../../styles'

import hapticFeedback from '../hapticFeedback'

const styles = StyleSheet.create({
  occupationButton: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1
  },
  occupationButtonText: {
    flex: 5,
    fontSize: 14,
    color: darkgrayblue,
    paddingVertical: DEFAULT_SPACING,
    paddingHorizontal: 12
  },
  arrowIcon: {
    flex: 1,
    marginRight: DEFAULT_SPACING
  }
})

export default function Occupation({ index, careerCount, onOccupationPress, ...occupation }) {
  const { name } = occupation

  return (
    <TouchableOpacity
      action={`Occupation: ${name}`}
      onPress={() => {
        hapticFeedback()
        onOccupationPress(occupation)
      }}
      style={[styles.occupationButton, { borderBottomColor: index != careerCount ? 'rgb(235, 241, 244)' : 'transparent' }]}
    >

      <Text style={styles.occupationButtonText}>
        {name}
      </Text>
      <FontAwesomeIcon icon="arrow-right" style={styles.arrowIcon} color="rgb(133, 151, 165)" size={15} />
    </TouchableOpacity>
  )
}
