import { useRef, useState, useEffect } from 'react'
import Carousel from 'react-native-reanimated-carousel'

import { Platform, StyleSheet, View, useWindowDimensions, } from 'react-native'
import Animated, { useSharedValue } from 'react-native-reanimated'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { primary } from '../styles/colors'
import hapticFeedback from './hapticFeedback'
import FadeInView from './FadeInView'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  paginationDot: {
    width: 9,
    height: 9,
    borderRadius: 4,
    marginHorizontal: 0,
  },
  paginationContainer: {
    padding: 0,
    margin: 0,
    height: 70,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

const initialFadeIn = 600
const wordFadeIn = initialFadeIn + 200

function PaginationItem(props) {
  const { opacity } = props
  const width = 8

  return (
    <Animated.View
      style={{
        backgroundColor: primary,
        width,
        height: width,
        borderRadius: 50,
        overflow: 'hidden',
        opacity,
        marginHorizontal: 8,
      }}
    />
  )
}

export default function CustomCarousel({
  containerStyle,
  data,
  height,
  onSwipe,
  paginationContainerStyle,
  disablePagination,
  page
}) {
  const isNotWeb = Platform.OS !== 'web'
  const window = useWindowDimensions()
  const SLIDER_WIDTH = window.width

  const sliderRef = useRef()
  const [activeSlide, setActiveSlide] = useState(0)

  const isWeb = Platform.OS === 'web'

  const progressValue = useSharedValue(0)
  const [chartWidth, setChartWidth] = useState(320)

  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    if (width) setChartWidth(width)
  }

  const onSlide = (n) => {
    setActiveSlide((n + data.length) % data.length)
    sliderRef.current.scrollTo({ index: (n + data.length) % data.length, animated: true })
  }

  useEffect(() => {
    if (page) {
      sliderRef.current.scrollTo({ index: page })
      setActiveSlide(page)
    }
  }, [page])

  return (
    <View style={containerStyle} onLayout={(event) => onLayout(event)}>
      <Carousel
        loop
        ref={sliderRef}
        width={isNotWeb ? SLIDER_WIDTH : chartWidth}
        height={height ?? (isNotWeb ? 300 : chartWidth * 1.1)}
        data={data}
        mode="parallax"
        modeConfig={{
          parallaxScrollingScale: 0.9,
          parallaxScrollingOffset: 50,
        }}
        onProgressChange={(_, absoluteProgress) => (progressValue.value = absoluteProgress)}
        onSnapToItem={(index) => {
          setActiveSlide(index)
          if (onSwipe) {
            onSwipe(index)
            hapticFeedback()
          }
        }}
        renderItem={({ item }) => item}
        panGestureHandlerProps={{ activeOffsetX: [-10, 10], }}
      />

      {!disablePagination
        && (
          <FadeInView
            style={[styles.row, paginationContainerStyle]}
            initialValue={0}
            toValue={1}
            delay={wordFadeIn}
            duration={initialFadeIn}
          >
            {isWeb && data.length > 0
              && (
                <TouchableOpacity onPress={() => onSlide(activeSlide - 1)}>
                  <FontAwesomeIcon icon="chevron-left" size={30} color={primary} />
                </TouchableOpacity>
              )}
            <View style={styles.paginationContainer}>
              {data.map((_item, index) => (
                <TouchableOpacity key={`pagination-touchable-${index}`} onPress={() => onSlide(index)}>
                  <PaginationItem
                    key={`pagination-item-${index}`}
                    index={index}
                    length={data.length}
                    opacity={activeSlide === index ? 1 : 0.4}
                    animValue={progressValue}
                    isRotate
                  />
                </TouchableOpacity>
              ))}
            </View>

            {isWeb && data.length > 0
              && (
                <TouchableOpacity onPress={() => onSlide(activeSlide + 1)}>
                  <FontAwesomeIcon icon="chevron-right" size={30} color={primary} />
                </TouchableOpacity>
              )}
          </FadeInView>
        )}

      {disablePagination
        && <View style={styles.paginationContainer} />}
    </View>
  )
}
