import { useState, useLayoutEffect } from 'react'
import { StyleSheet } from 'react-native'
import IntroScreen from './components/IntroScreen'

const styles = StyleSheet.create({
  mockInput: {
    width: '80%',
    marginVertical: 24
  },
  finn: { height: 200 }
})

export default function OpportunityCostIntroScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  return (
    <IntroScreen
      title={'Our financial choices \nhave consequences'}
      subtitle={'Each time we make a choice, we give up\n opportunities associated with different\n decisions. This tool will help estimate the \n financial opportunity cost of making one \n decision vs another.'}
      image={require('./assets/opp-cost-intro.png')}
      buttonText="Next"
      finnStyle={styles.finn}
      onPress={() => {
        navigation.navigate('Modal', { screen: 'Opportunity Cost' })
      }}
    />
  )
}
