import BottomTabItem from './BottomTabItem'
import TabWrapper from './TabWrapper'
import TouchableOpacity from './TouchableOpacity'

const TabBar = ({ state, descriptors, navigation }) => (
  <TabWrapper style={{ position: 'absolute' }}>
    {state.routes.map((route, index) => {
      const { options } = descriptors[route.key]
      const { disabled } = options
      const label =
        options.tabBarLabel !== undefined
          ? options.tabBarLabel
          : options.title !== undefined
          ? options.title
          : route.name

      const isFocused = state.index === index

      const onPress = () => {
        const event = navigation.emit({
          type: 'tabPress',
          target: route.key,
          canPreventDefault: true
        })

        if (!isFocused && !event.defaultPrevented) {
          navigation.navigate(route.name)
        }
        animateSlider(index)
      }

      const { tabBarIcon } = options

      return (
        <TouchableOpacity onPress={onPress} key={index} disabled={disabled}>
          <BottomTabItem
            disabled={disabled}
            isFocused={isFocused}
            label={label}
            tabBarIcon={tabBarIcon}
          />
        </TouchableOpacity>
      )
    })}
  </TabWrapper>
)

export default TabBar
