import { Platform, Text, View, StyleSheet } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { info, success, warning, error, rgba } from '../styles'

const basicStyles = {
  informationBlockContainer: {
    flexDirection: 'row',
    marginVertical: 12,
    padding: 14,
    backgroundColor: rgba(info, 0.1)
  },
  informationBlock: {
    alignSelf: 'center',
    width: 30,
    flex: 1
  },
  informationBlockTextContainer: { flex: 10 },
  infomationBlockText: {
    letterSpacing: 0,
    color: info,
    fontSize: 12
  },
  informationBlockHeader: {
    letterSpacing: 0,
    color: info,
    fontSize: 14,
    marginBottom: 6,
    fontWeight: '600',
    alignSelf: 'flex-start',
    marginLeft: 6
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  mobileStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: { informationBlockContainer: { marginVertical: 0 } },
    [minSize(DEVICE_SIZES.LG)]: {},
  }
)

const typeToColor = {
  info,
  warning,
  error,
  success
}

const typeToIcon = {
  info: 'circle-info',
  warning: 'circle-exclamation',
  error: 'circle-x',
  success: 'circle-check'
}

export default function InformationBlock({ text, header, style, type = 'info' }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const color = typeToColor[type]
  const icon = typeToIcon[type]

  return (
    <View style={[styles.informationBlockContainer, { backgroundColor: rgba(color, 0.1) }, style]}>
      <View style={styles.informationBlock}>
        <FontAwesomeIcon icon={icon} color={color} size={15} />
      </View>
      <View style={styles.informationBlockTextContainer}>
        {header && <Text style={[styles.informationBlockHeader, { color }]}>{header}</Text> }
        <Text style={[styles.infomationBlockText, { color }]}>{text}</Text>
      </View>
    </View>
  )
}
