import { get } from 'http'
import { setLoans } from './setLoans'
import handleError from '../functions/handleError'

export const getDebts = function (payload) {
  return async (dispatch) => {
    try {
      const results = await get('loans/')

      dispatch(setLoans(results.data))
    } catch (e) {
      handleError(e, 'Oops. Looks like something went wrong.')
    }
  }
}
