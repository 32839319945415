import { Platform, StyleSheet, Text, View, Image } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { useDispatch, useSelector } from 'react-redux'
import Button from '../Button'
import Modal from '../Modal'

import { saveMetaData } from '../../actions'

import { currentUserSelector } from '../../selectors'
import { darkblue, gunmetal, white } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const basicStyles = {
  modal: { backgroundColor: 'rgba(6, 15, 18, 0.85)', },
  container: {
    paddingVertical: DEFAULT_SPACING,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 180,
    height: 20
  },
  hi: { fontSize: 50 },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    zIndex: 100
  },
  body: {
    marginVertical: DEFAULT_SPACING,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center',
    zIndex: 100
  },
  emphasisedBody: {
    fontSize: 18,
    textAlign: 'center',
    zIndex: 100,
    paddingVertical: DEFAULT_SPACING
  },
  button: { marginTop: DEFAULT_SPACING },
  finn: {
    height: 120,
    width: 120,
    position: 'absolute',
    bottom: -105,
    left: 0
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        backgroundColor: white,
        paddingHorizontal: DEFAULT_SPACING * 2,
        paddingVertical: DEFAULT_SPACING * 2,
        borderRadius: DEFAULT_SPACING,
      }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function IntroScreen({ metadataKey }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => currentUserSelector(state))
  const { metadata } = currentUser
  const visibleBasedOnMetaData = metadata && !metadata[metadataKey]

  function handleClose() {
    dispatch(saveMetaData({ [metadataKey]: true }))
  }

  return (
    <Modal closeable={false} scrollEnabled={false} visible={visibleBasedOnMetaData} setVisible={handleClose}>
      <View style={styles.container}>
        <Text style={styles.hi}>👋</Text>

        <Text style={styles.header}>Welcome to</Text>
        <Image style={styles.logo} source={require('../../assets/troutwood-alt.png')} resizeMode="contain" />

        <View>
          <Text style={styles.body}>We are here to help you plan for and achieve your financial goals. Explore locations, careers and investment options.</Text>
          <Text style={styles.emphasisedBody}>
            Prepare to plan for
            {' '}
            <Text style={{ fontWeight: '800', }}>awesome!</Text>
          </Text>
        </View>

        <Button onPress={handleClose} style={styles.button}>Get Started!</Button>
      </View>
      <Image style={styles.finn} source={require('../../assets/finn/smart.png')} resizeMode="contain" />
    </Modal>
  )
}
