import { post } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { setFinPulsePreferences } from './setFinPulsePreferences'
import handleError from '../functions/handleError'

// eslint-disable-next-line import/prefer-default-export
export const saveFinPulsePreferences = (values) => async (dispatch) => {
  try {
    const { data } = await post(proApplicationEndpoints.updateFinPulsePreferences(), values)
    dispatch(setFinPulsePreferences(data))
  } catch (e) {
    console.warn(e, e.response)
    // handleError(e, 'Failed to save FinPulse preferences')
    throw e    
  }
}
