import { View, Text, StyleSheet, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { white, info, DEFAULT_SPACING, gunmetal } from '../../styles'
import Card from '../Card'
import HeaderLabel from '../HeaderLabel'
import Button from '../Button'
import SavingsBadge from './SavingsBadge'

import abbreviateCurrency from '../../functions/abbreviateCurrency'
import PrimaryPlanFlag from '../PrimaryPlanFlag'

const basicStyles = {
  container: {
    zIndex: 2,
    padding: DEFAULT_SPACING * 2,
    backgroundColor: white,
    borderRadius: 20,
    flexDirection: 'row'
  },
  header: {
    fontSize: 16,
    marginBottom: DEFAULT_SPACING / 3
  },
  image: {
    width: 120,
    height: 120
  },
  textContainer: {
    flex: 1,
    marginLeft: DEFAULT_SPACING * 2
  },
  text: {
    fontSize: 12,
    color: gunmetal,
    marginBottom: DEFAULT_SPACING * 2
  },
  buttonTitle: { fontSize: 14 },
  button: {
    paddingVertical: 12,
    borderRadius: 8,
    width: 180
  },
  bold: { fontWeight: 'bold' }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, { [minSize(DEVICE_SIZES.LG)]: { container: { width: '100%', marginRight: '1%' } } })

export default function MyPlanCard({
  monthlyContribution = 0,
  goalAmount = 0,
  pro = false,
  onPress
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <Card style={styles.container}>
      <PrimaryPlanFlag left={false} pro={pro} />

      <SavingsBadge
        number={monthlyContribution}
        style={{ marginRight: DEFAULT_SPACING * 2 }}
        styles={styles}
      />

      <View style={styles.textContainer}>
        <HeaderLabel style={styles.header}>My Plan</HeaderLabel>
        <Text style={styles.text}>
          Helping you reach your
          <Text style={styles.bold}>
            {' '}
            $
            {abbreviateCurrency(goalAmount, 1)}
            {' '}
          </Text>
          goal, one step at a time
        </Text>
        <Button
          onPress={onPress}
          style={styles.button}
          titleStyle={styles.buttonTitle}
          color={info}
        >
          {pro ? 'View Plan and Budget' : 'View Plan'}
        </Button>
      </View>
    </Card>
  )
}
