import { useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View
} from 'react-native'

import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import Card from '../Card'
import FadeInView from '../FadeInView'
import HeaderLabel from '../HeaderLabel'
import SearchedOccupations from './SearchedOccupations'

import hapticFeedback from '../hapticFeedback'
import TouchableOpacity from '../TouchableOpacity'

import { loadingSelector } from '../../selectors'
import { DEFAULT_SPACING } from '../../styles'
import militaryOccupationSelector from '../../selectors/militaryOccupation'
import MilitaryOccupationalGroupCard from './MilitaryOccupationalGroupCard'
import { getMilitaryOccupations } from '../../actions'

const basicStyles = {
  scrollViewContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: DEFAULT_SPACING
  },
  scrollViewContentContainer: { paddingVertical: DEFAULT_SPACING },
  cardContainer: {
    marginVertical: DEFAULT_SPACING,
    borderRadius: 10
  },
  cardImage: {
    height: 150,
    minHeight: 150,
    flex: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  cardText: {
    fontSize: 16,
    color: 'rgb(66, 86, 103)',
    padding: DEFAULT_SPACING,
    minHeight: 56
  },
  listWrapper: {},
  listHeader: {},
  cardWrapper: {}
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    scrollViewContainer: { paddingHorizontal: 40 },
    scrollViewContentContainer: { paddingVertical: 30 },
    listWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 28
    },
    cardWrapper: { width: '33%' },
    cardContainer: { marginVertical: 5 },
    cardImage: {
      height: 230,
      minHeight: 230
    },
    cardText: {
      fontSize: 20,
      fontWeight: '500',
      minHeight: 93
    },
    listHeader: {
      fontSize: 36,
      fontWeight: '600'
    }
  }
})

const isNotWeb = Platform.OS !== 'web'

function OccupationalGroupCard({ onPress, occupationGroup }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { name, image } = occupationGroup

  return (
    <TouchableOpacity
      action={`Occupation Group: ${name}`}
      onPress={() => {
        hapticFeedback()
        onPress(occupationGroup)
      }}
      style={styles.cardWrapper}
    >
      <FadeInView initialValue={0} toValue={1} duration={300}>
        <Card style={styles.cardContainer}>
          <Image source={{ uri: image.url }} style={styles.cardImage} />
          <Text style={styles.cardText}>{name}</Text>
        </Card>
      </FadeInView>
    </TouchableOpacity>
  )
}

export default function OccupationGroupsList({
  careerSearch,
  onGroupPress,
  onOccupationPress,
  groupOptions,
  locationId,
  onMilitaryGroupPress
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { loading } = useSelector((state) => loadingSelector(state))
  const { militaryOccupations } = useSelector((state) =>
    militaryOccupationSelector(state)
  )
  const dispatch = useDispatch()
  const [militaryCareers, setMilitaryCareers] = useState([])
  const [militaryCardPos, setMilitaryCardPos] = useState(0)

  useEffect(() => {
    dispatch(getMilitaryOccupations())
  }, [])

  useEffect(() => {
    const careers = []
    if (militaryOccupations) {
      Object.keys(militaryOccupations.careers).map((key) => {
        careers.push(...militaryOccupations.careers[key])
      })
      setMilitaryCareers(careers)
    }
  }, [militaryOccupations])

  useEffect(() => {
    if (groupOptions.length > 0 && militaryOccupations) {
      const pos = groupOptions.findIndex(
        (group) => group.name > militaryOccupations.name
      )
      setMilitaryCardPos(pos)
    }
  }, [groupOptions, militaryOccupations])

  return (
    <ScrollView
      keyboardShouldPersistTaps="handled"
      keyboardDismissMode="on-drag"
      contentContainerStyle={styles.scrollViewContentContainer}
      style={styles.scrollViewContainer}
    >
      {careerSearch && careerSearch.length > 3 && (
        <SearchedOccupations
          onOccupationPress={onOccupationPress}
          careerSearch={careerSearch}
          groupOptions={[
            ...groupOptions,
            { ...militaryOccupations, careers: militaryCareers }
          ]}
        />
      )}

      <HeaderLabel style={styles.listHeader}>Occupational Groups</HeaderLabel>

      {loading && groupOptions.length === 0 && (
        <ActivityIndicator size="large" />
      )}

      <View style={styles.listWrapper}>
        {map(groupOptions.slice(0, militaryCardPos), (group) => (
          <OccupationalGroupCard
            key={group.id}
            occupationGroup={group}
            onPress={onGroupPress}
            locationId={locationId}
            {...group}
          />
        ))}
        {militaryOccupations && (
          <MilitaryOccupationalGroupCard
            key={militaryOccupations.id}
            onPress={onMilitaryGroupPress}
            {...militaryOccupations}
          />
        )}
        {map(
          groupOptions.slice(militaryCardPos, groupOptions.length),
          (group) => (
            <OccupationalGroupCard
              key={group.id}
              occupationGroup={group}
              onPress={onGroupPress}
              locationId={locationId}
              {...group}
            />
          )
        )}
      </View>
    </ScrollView>
  )
}
