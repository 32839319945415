import { View, Image, Text, StyleSheet } from 'react-native'
import { darkgrayblue, DEFAULT_SPACING } from '../styles'

const styles = StyleSheet.create({
  container: { marginVertical: DEFAULT_SPACING, },
  image: {
    width: 160,
    height: 160,
    marginBottom: DEFAULT_SPACING,
    alignSelf: 'center'
  },
  title: {
    color: darkgrayblue,
    fontSize: 22,
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 12
  },
  subtitle: {
    color: 'rgb(120, 138, 152)',
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center'
  }
})

export default function SearchResultFinn({ title, subtitle, image }) {
  return (
    <View style={styles.container}>
      <Image
        resizeMode="contain"
        style={styles.image}
        source={image}
      />
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subtitle}>{subtitle}</Text>
    </View>
  )
}
