import { post } from 'http'

export const SET_ASSESS_SCORE_REQUEST = 'SET_ASSESS_SCORE_REQUEST'
export const SET_ASSESS_SCORE_SUCCESS = 'SET_ASSESS_SCORE_SUCCESS'
export const SET_ASSESS_SCORE_FAILURE = 'SET_ASSESS_SCORE_FAILURE'

export const setAssessScoreRequest = () => ({ type: SET_ASSESS_SCORE_REQUEST, })

export const setAssessScoreSuccess = (data) => ({
  type: SET_ASSESS_SCORE_SUCCESS,
  payload: data,
})

export const setAssessScoreFailure = (error) => ({
  type: SET_ASSESS_SCORE_FAILURE,
  payload: error,
})

export const setAssessScore = (payload) => async (dispatch) => {
  dispatch(setAssessScoreRequest())

  try {
    const res = await post('/api/v1/risk-assessment/risk-profile/', payload)
    dispatch(setAssessScoreSuccess({ ...res.data, is_self_assessed: true }))
  } catch (error) {
    dispatch(setAssessScoreFailure(error.message))
  }
}
