import { each } from 'lodash'
import * as Yup from 'yup'

import {
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX,
} from './constants'

export const EnrollmentMin = 0
export const EnrollmentMax = 100000

export const AdmissionRateMin = 0
export const AdmissionRateMax = 100

export const MedianDebtMin = 0
export const MedianDebtMax = 200000

export const SATmin = 400
export const SATmax = 1600

export const ACTmin = 1
export const ACTmax = 36

export const TuitionMin = 0
export const TuitionMax = 80000

export const MedianEarningsMin = 0
export const MedianEarningsMax = 200000

export const EndowmentMin = 0
export const EndowmentMax = 75000000000

export const Fields = {
  listOfObjectStates: 'listOfObjectStates',
  admissionsRate: 'admission_rate',
  enrollment: 'enrollment',
  sat: 'sat',
  act: 'act',
  inState: 'inState',
  outOfState: 'outOfState',
  tuition: 'tuition',
  tuitionfee_in: 'tuitionfee_in',
  tuitionfee_out: 'tuitionfee_out',
  studentDebtRate: 'graduate_default_rate',
  medianDebt: 'graduate_median_debt',
  endowment: 'endowment',
  sixYears: 'sixYears',
  eightYears: 'eightYears',
  tenYears: 'tenYears',
  medianEarnings: 'median_Earnings',
  medianEarnings6: 'earning_6year__gte',
  medianEarnings8: 'earning_8year__gte',
  medianEarnings10: 'earning_10year__gte',

}

export const ValidationSchema = Yup.object().shape({
  median_Earnings: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(5000000, `${INVALID_MAX}5,000,000`)
})

export const InitialValues = {
  [Fields.listOfObjectStates]: [],
  [Fields.admissionsRate]: [AdmissionRateMin, AdmissionRateMax],
  [Fields.enrollment]: [EnrollmentMin, EnrollmentMax],
  [Fields.sat]: [SATmin, SATmax],
  [Fields.act]: [ACTmin, ACTmax],
  [Fields.inState]: false,
  [Fields.outOfState]: false,
  [Fields.tuition]: [TuitionMin, TuitionMax],
  [Fields.tuitionfee_in]: '',
  [Fields.tuitionfee_out]: '',
  [Fields.studentDebtRate]: '',
  [Fields.medianDebt]: [MedianDebtMin, MedianDebtMax],
  [Fields.endowment]: [EndowmentMin, EndowmentMax],
  [Fields.sixYears]: false,
  [Fields.eightYears]: false,
  [Fields.tenYears]: false,
  [Fields.medianEarnings]: '',
  [Fields.medianEarnings6]: '',
  [Fields.medianEarnings8]: '',
  [Fields.medianEarnings10]: '',
}

const _locationOptions = []
const unformattedOptions = [
  {
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    name: 'California',
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC'
  },
  {
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY'
  }
]

each(unformattedOptions, (location) => {
  _locationOptions.push({
    id: location.abbreviation,
    item: `${location.name} (${location.abbreviation})`
  })
})

export const locationOptions = _locationOptions
