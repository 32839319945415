import { View, Text, StyleSheet, Image, Platform } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { useDispatch, useSelector } from 'react-redux'
import { white, info, DEFAULT_SPACING, gunmetal } from '../../styles'
import Card from '../Card'
import HeaderLabel from '../HeaderLabel'
import Button from '../Button'

import { currentUserSelector, deviceSelector } from '../../selectors'

import { setCurrentPlan } from '../../actions'

const basicStyles = {
  container: {
    zIndex: 2,
    padding: DEFAULT_SPACING * 2,
    backgroundColor: white,
    borderRadius: 20,
    flexDirection: 'row'
  },
  header: {
    fontSize: 16,
    marginBottom: DEFAULT_SPACING / 3
  },
  image: {
    width: 120,
    height: 120
  },
  textContainer: {
    flex: 1,
    marginLeft: DEFAULT_SPACING * 2
  },
  text: {
    fontSize: 12,
    color: gunmetal,
    marginBottom: DEFAULT_SPACING * 3
  },
  buttonTitle: { fontSize: 14 },
  button: {
    paddingVertical: 12,
    borderRadius: 8,
    width: 180,
    zIndex: 100000
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, { [minSize(DEVICE_SIZES.LG)]: {} })

export default function BuildPlanCard({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const device = useSelector(deviceSelector)
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => currentUserSelector(state))

  function handlePress() {
    const birth_date = currentUser.is_fake ? null : currentUser.birth_date
    dispatch(setCurrentPlan({ step: 0, device, birth_date }))
    navigation.navigate('Modal', { screen: 'Plan Builder Intro' })
  }

  return (
    <Card style={styles.container}>
      <Image source={require('../../assets/finn-plan.png')} style={styles.image} />

      <View style={styles.textContainer}>
        <HeaderLabel style={styles.header}>Prepare to be Awesome!</HeaderLabel>
        <Text style={styles.text}>Go build your first plan.</Text>
        <Button
          onPress={handlePress}
          style={styles.button}
          titleStyle={styles.buttonTitle}
          color={info}
        >
          Build Plan
        </Button>
      </View>
    </Card>
  )
}
