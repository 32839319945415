import { Platform, View, StyleSheet, Text, Switch } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import TouchableOpacity from './TouchableOpacity'

const basicStyles = {
  container: {
    flexDirection: 'row',
    marginBottom: 24,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelContainer: { order: 1, },
  label: {},
  switch: { marginLeft: 8, order: 2, },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    container: {
      justifyContent: 'flex-start',
    },
    labelContainer: { order: 2, },
    label: { fontSize: 18, },
    switch: { order: 1, marginLeft: 0, marginRight: 8, }
  },
})

const isNotWeb = Platform.OS !== 'web'

export default function CustomSwitch({ onPress, label, value }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          onPress(!value)
        }}
        style={styles.labelContainer}
      >
        <Text style={styles.label}>{label}</Text>
      </TouchableOpacity>
      <Switch
        style={styles.switch}
        value={value}
        onValueChange={(v) => {
          onPress(v)
        }}
      />
    </View>
  )
}
