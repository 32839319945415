import { post } from 'http'
import { isEmpty } from 'lodash'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import handleError from '../functions/handleError'
import { currentUserSelector, primaryPlanSelector, primaryProPlanSelector } from '../selectors'
import { saveCurrentPlan } from './saveCurrentPlan'
import { setProPlan } from './setProPlan'

// eslint-disable-next-line import/prefer-default-export
export const assignPrimaryPlan = (isPrimary = true) => async (dispatch, getState) => {
  await dispatch(saveCurrentPlan({ is_primary_plan: isPrimary }))

  /* expecting the state to be updated after dispatch */
  const state = getState()
  const primaryProPlan = primaryProPlanSelector(state)

  /**
   * If you are setting this plan to primary plan,
   * then we need to set any primary pro plans to not primary
  */
  if (isPrimary && !isEmpty(primaryProPlan)) {
    try {
      const { id: userId } = currentUserSelector(state)
      const { planId: primaryProPlanId } = primaryProPlan
      await post(proApplicationEndpoints.deactivatePlan(userId, primaryProPlanId))
      dispatch(
        setProPlan({
          planId: primaryProPlanId,
          plan: {
            ...primaryProPlan,
            isPrimary: false
          }
        })
      )
    } catch (error) {
      /* UNDO because the pro plan couldn't be non-primaried */
      await dispatch(saveCurrentPlan({ is_primary_plan: !isPrimary }))
      handleError(error, 'Oops. Couldn\'t make that the primary plan. There was an error. We\'re looking at it')
    }
  }
}
