import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { useDispatch } from 'react-redux'
import { get } from 'http'
import { map } from 'lodash'
import SearchInput from './components/SearchInput'
import Container from './components/Container'
import ScrollingListContainer from './components/ScrollingListContainer'
import SearchResultFinn from './components/SearchResultFinn'
import SearchListItem from './components/SearchListItem'
import CareerExploreSearchPageHeader from './components/careers/CollegeExploreSearchPageHeader'
import { setCareerLocation } from './actions'

const styles = StyleSheet.create({ modalContainer: { padding: 0 } })

export default function CareerExploreLocationSearchScreen({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const [citySearch, setCitySearch] = useState()
  const [cityOptions, setCityOptions] = useState([])

  const dispatch = useDispatch()
  const searchInput = useRef()

  async function searchCities(value) {
    if (value && value.length >= 3) {
      try {
        const results = await get(`cities/?search=${value}`)
        setCityOptions(results.data)
      } catch (error) {
        console.error(error.response, ' -- error')
      }
    } else {
      setCityOptions([])
    }
  }

  useEffect(() => {
    searchCities(citySearch)
  }, [citySearch])

  useEffect(() => {
    if (searchInput.current) searchInput.current.focus()
  }, [searchInput.current])

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Explore Careers',
      headerShown: isNotWeb,
    })
  }, [navigation])

  return (
    <Container withPadding style={isNotWeb ? {} : { padding: 40 }}>
      {!isNotWeb && (<CareerExploreSearchPageHeader navigation={navigation} />)}
      <SearchInput
        style={{ marginHorizontal: 0, marginTop: isNotWeb ? 0 : 12 }}
        ref={searchInput}
        value={citySearch}
        onChange={(value) => {
          setCitySearch(value || null)
        }}
        onCancel={() => {
          setCitySearch(null)
        }}
      />

      <ScrollingListContainer
        displayButton={false}
      >
        {map(cityOptions, (c) => (
          <SearchListItem
            key={c.id}
            onPress={() => {
              dispatch(setCareerLocation(c))
              navigation.navigate('Modal', { screen: 'Career Explore Occupation Groups' })
            }}
            leftLabel={`${c.name}`}
          />
        ))}

        {cityOptions.length == 0 && citySearch && citySearch.length > 3
          && (
            <SearchResultFinn
              image={require('./assets/finn/question.png')}
              title="No Results"
              subtitle="Explore another location"
            />
          )}

        {cityOptions.length == 0 && !citySearch
          && (
            <SearchResultFinn
              image={require('./assets/finn/happy.png')}
              title="Search for a Location"
              subtitle="Type the name of a Location that interest you, then search Careers to see income ranges for that Location."
            />
          )}

      </ScrollingListContainer>
    </Container>
  )
}
