import { useState } from 'react'
import { Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import TooltippedText from './TooltippedText'
import hapticFeedback from './hapticFeedback'
import TouchableOpacity from './TouchableOpacity'
import { gray, grayblue, gunmetal, rgba } from '../styles/colors'

const useStyles = CreateResponsiveStyle(
  {
    container: {
      borderBottomColor: 'rgb(241, 246, 247)',
      paddingVertical: 14,
      borderBottomWidth: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    lastItem: { borderBottomColor: 'transparent', },
    leftLabel: {
      fontSize: 14,
      fontWeight: '500',
      color: grayblue,
      letterSpacing: 0,
    },
    rightLabel: {
      letterSpacing: -0.6,
      fontSize: 14,
      flex: 1,
      textAlign: 'right',
      color: gunmetal,
      fontWeight: '400',
    },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        justifyContent: 'space-between',
        borderBottomWidth: 0,
      },
      leftLabel: {
        fontSize: 16,
        fontWeight: '600',
      },
      rightLabel: {
        fontSize: 16,
        width: '45%',
        maxWidth: '45%',
        marginLeft: 'auto',
        textAlign: 'left',
      },
    }
  }
)

export default function DataRow({ label, tooltipBody, route, onPress, title, subtitle, editable, value, color, params = {}, divisor, lastItem, style = {}, rightLabelStyle = {} }) {
  const styles = useStyles()
  const navigation = useNavigation()

  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)
  const disabled = !(onPress || route)

  const navigationParams = { ...{ title, subtitle, editable, divisor }, ...params }

  function handlePress() {
    hapticFeedback()

    if (onPress) {
      onPress()
    } else if (route) {
      navigation.navigate('Modal', { screen: route, params: navigationParams })
    }
  }

  const Wrapper = (props) => (onPress || route) ? <TouchableOpacity {...props} /> : <View {...props} />

  return (
    <Wrapper style={[styles.container, lastItem ? styles.lastItem : {}, style]} onPress={handlePress}>
      {!tooltipBody && <Text style={styles.leftLabel}>{label}</Text>}
      {tooltipBody
        && (
          <TooltippedText
            visible={tooltipIsVisible}
            setVisible={setTooltipIsVisible}
            anchorStyle={styles.leftLabel}
            anchorText={label}
            contentHeader={label}
            contentBody={tooltipBody}
          />
        )}

      <Text style={[styles.rightLabel, { color: color || '#6A7B7E' }, rightLabelStyle]}>
        {value}
      </Text>

      <FontAwesomeIcon style={{ marginLeft: 12 }} icon={['fas', 'chevron-right']} size={12} color={(route || onPress) ? rgba(gray, 0.55) : 'transparent'} />
    </Wrapper>
  )
}
