import {
  Text,
  StyleSheet,
  Platform,
  View
} from 'react-native'
import { first } from 'lodash'

import { Image } from 'react-native-expo-image-cache'
import moment from 'moment'
import CircleImage from './CircleImage'
import HeaderLabel from './HeaderLabel'
import TouchableOpacity from './TouchableOpacity'

import { darkblue, white, secondary, DEFAULT_SPACING } from '../styles'

const styles = StyleSheet.create({
  title: {
    fontWeight: '500',
    fontSize: 14,
    color: darkblue,
    letterSpacing: 0,
    paddingTop: 15,
    paddingHorizontal: 10,
    paddingBottom: 35
  },
  videoThumbnail: {
    overflow: 'hidden',
    width: '46%',
    marginVertical: 12,
    marginHorizontal: '2%',
    borderRadius: 20,
    backgroundColor: white
  },
  videoThumbnailWeb: {
    width: 343,
    height: 334,
    overflow: 'hidden',
    marginVertical: 12,
    marginHorizontal: '2%',
    borderRadius: 20,

    backgroundColor: white

  },
  articleThumbnail: {
    overflow: 'hidden',
    margin: DEFAULT_SPACING,
    borderRadius: 20,
    backgroundColor: white
  },
  articleExcerpt: {
    fontSize: 14,
    color: secondary,
    paddingHorizontal: DEFAULT_SPACING,
    paddingBottom: DEFAULT_SPACING,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: DEFAULT_SPACING,
  },
  author: {
    marginLeft: DEFAULT_SPACING,
    fontSize: 18,
    fontWeight: '700'
  },
  publishTime: {
    marginLeft: DEFAULT_SPACING,
    fontSize: 14,
    color: secondary
  }
})

export default function YoutubeTile(props) {
  const { snippet, navigation, route } = props
  const { title, thumbnails, resourceId } = snippet
  const { videoId } = resourceId
  const maxres = thumbnails.maxres || thumbnails.standard || thumbnails.high || thumbnails.medium || {}
  const preview = { uri: Platform.OS === 'web' ? maxres?.url : thumbnails?.default?.url };
  const uri = maxres.url
  const finnImage = require('../assets/finn/purple.png')

  return (
    <TouchableOpacity
      action={`Youtube: ${title}`}
      onPress={() => navigation.navigate('Modal', { screen: route, title, params: { videoId } })}
      style={Platform.OS === 'web' ? styles.videoThumbnailWeb : styles.videoThumbnail}
    >
      {Platform.OS === 'web'
        && (
          <View style={styles.header}>
            <CircleImage
              size={70}
              image={finnImage}
            />
            <View style={{ flexDirection: 'column', justifyContent: 'center' }}>
              <HeaderLabel style={styles.author}>
                Troutwood
              </HeaderLabel>
            </View>
          </View>
        )}
      <Image
        transitionDuration={800}
        style={{ width: '100%', backgroundColor: 'white', height: Platform.OS == 'web' ? 180 : 120 }}
        {...{ preview, uri }}
        resizeMode="cover"
      />
      <Text style={styles.title}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}
