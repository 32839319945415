import { View, StyleSheet, ScrollView, Text, Image, Platform } from 'react-native'

import { useSelector } from 'react-redux'
import { isEmpty, map } from 'lodash'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import {
  ListItem,
  Divider
} from 'react-native-elements'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { DEFAULT_SPACING } from './styles'
import { darkblue, secondary, white } from './styles/colors'
import { useLayoutEffect } from 'react'
import { currentUserSelector } from './selectors'
import Button from './components/Button'
import PageHeader from './components/PageHeader'
import ShareAndEmpowerCard from './components/ShareAndEmpowerCard'
import PageLayout from './components/userOptionsMenu/PageLayout'

const basicStyles = {
  scrollView: {
    paddingBottom: DEFAULT_SPACING * 3,
    paddingHorizontal: 16,
  },
  title: { color: darkblue, fontWeight: '600', fontSize: 24, lineHeight: 29, },
  content: { paddingVertical: 18 },
  label: {
    color: darkblue,
    fontSize: 18,
    lineHeight: 22,
    fontWeight: '500',
  },
  logo: {
    width: 72,
    height: 47,
    alignSelf: 'center',
  },
  joinButton: { width: 128, paddingVertical: 10, borderRadius: 6, marginTop: 50 },
  joinButtonTxt: { fontSize: 12, fontWeight: '600', },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      scrollView: { paddingHorizontal: 0 },
      joinButton: { width: 180, paddingVertical: DEFAULT_SPACING, },
      joinButtonTxt: { fontSize: 18, },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'
const title = 'My Teams'
const subTitle = 'Your teams are here to help you on your financial journey.'

export default function MyTeamsScreen({ route, navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const currentUser = useSelector((state) => currentUserSelector(state))
  const sponsors = !isEmpty(currentUser.sponsors) ? currentUser.sponsors : []

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTitle: null
    })
  }, [navigation])

  const handlePress = () => {
    navigation.navigate('Modal', { screen: 'Join Team' })
  }

  const main = (<ScrollView style={styles.scrollView}>
    {isNotWeb && (<>
      <Text style={styles.title}>{title}</Text>
      <Text style={{
        color: secondary,
        fontWeight: '500',
        fontSize: 16,
        lineHeight: 20,
        marginTop: 15,
        marginBottom: 22,
      }}>{subTitle}</Text>
    </>)}

    {map(sponsors, (option) => {
      const { name, logo_url } = option

      return (
        <View key={name}>
          <ListItem
            containerStyle={{ paddingHorizontal: 0 }}
          >
            <Image
              source={{ uri: logo_url }}
              style={styles.logo}
              resizeMode="contain"
            />
            <ListItem.Content style={styles.content}>
              <ListItem.Title style={styles.label}>{name}</ListItem.Title>
            </ListItem.Content>
            {/* <FontAwesomeIcon icon="ellipsis" size={18} color={darkblue} /> */}
          </ListItem>

          <Divider color="rgb(223, 230, 236)" />
        </View>
      )
    })}

    <Button
      color="#3491F6"
      style={styles.joinButton}
      titleStyle={styles.joinButtonTxt}
      onPress={handlePress}
    >
      Join New Team
    </Button>
  </ScrollView>)

  return (
    <PageLayout
      title={title}
      subTitle={subTitle}
      main={main}
    />
  );
}
