import { Image, Text, View } from 'react-native'
import { Slider } from 'react-native-elements'
import { TouchableOpacity } from 'react-native-gesture-handler'

function SliderOptionGroup({ options, value = 1, onChange, }) {
  const handleChange = (val) => {
    onChange(options[val].value)
  }

  return (
    <View style={{ marginHorizontal: 40, overflow: 'visible', height: 200, }}>
      <Slider
        style={{ transform: 'scaleY(3)', zIndex: 1 }}
        allowTouchTrack
        value={options.findIndex((option) => option.value === value)}
        minimumValue={0}
        maximumValue={options.length - 1}
        minimumTrackTintColor="#4F8FEF"
        maximumTrackTintColor="#DADDDD"
        step={1}
        onSlidingComplete={handleChange}
        thumbStyle={{ height: 12, width: 12, backgroundColor: 'transparent' }}
        thumbProps={{
          children: (
            <Image
              source={require('../../../assets/icons/pentagon-icon.png')}
              style={{ width: 30, height: 40, top: -13, left: -8, transform: 'scaleY(0.34)', }}
              resizeMode="contain"
            />
          ),
        }}
      />
      <View style={{ transform: 'scaleX(0.95)', width: '100%', overflow: 'visible', backgroundColor: 'red' }}>
        {options.map((option, index) => (
          <View style={{
            position: 'absolute',
            left: `${index * (100 / (options.length - 1))}%`,
            top: -16,
            height: (((index + 1) % 2) + 1) * 50,
            backgroundColor: '#DADDDD',
            width: 1,
            overflow: 'visible',
            alignItems: 'center',
          }}
          >

            <View style={{ width: 120, textAlign: 'center', top: '100%', position: 'absolute', padding: 10 }}>
              <TouchableOpacity onPress={() => onChange(option.value)}>
                <Text style={{ textAlign: 'center', }}>{option.label}</Text>
              </TouchableOpacity>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

export default SliderOptionGroup
