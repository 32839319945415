import { useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { getBudgetItems } from './actions';
import IntroScreenPro from './components/IntroScreenPro';
import { NAVIGATION_TYPE } from './constants';
import { currentProPlanSelector } from './selectors';

const styles = StyleSheet.create({ container: { alignItems: 'center' } })

export default function CreateBudgetIntroScreen({ navigation }) {
  const dispatch = useDispatch()
  const { planId } = useSelector(currentProPlanSelector)

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  function callCreateBudget() {
    dispatch(getBudgetItems(planId))
    navigation.navigate('Modal', { screen: 'Create Budget' })
  }

  return (
    <IntroScreenPro
      style={styles.container}
      image={require('./assets/finn/cool.png')}
      backgroundImage={require('./assets/budget/budget-bg.png')}
      buttonText="Create Budget"
      onPress={callCreateBudget}
      subtitle="This tool will help track and forecast your finances. Create a budget that is optimized to meet your financial goals!"
    />
  )
}

CreateBudgetIntroScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
