import {
  Platform,
  View,
  Text,
  StyleSheet,
  TouchableOpacity
} from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import {
  calPolyPomonaGreen,
  lightSilverGreen,
  iguanaGreen,
  white,
  DEFAULT_SPACING,
  rgba
} from 'styles'
import CircleIcon from '../CircleIcon'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const basicStyles = {
  container: {
    backgroundColor: calPolyPomonaGreen,
    height: 84,
    paddingHorizontal: DEFAULT_SPACING,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: DEFAULT_SPACING * 2,
    marginTop: -DEFAULT_SPACING
  },
  textContainer: {
    marginRight: DEFAULT_SPACING,
  },
  header: {
    fontWeight: '600',
    color: white,
    fontSize: 16,
    marginBottom: 6
  },
  text: {
    color: lightSilverGreen,
    fontSize: 12,
    letterSpacing: -0.5,
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        width: '100%',
        maxWidth: 1074,
        margin: 0,
        marginTop: DEFAULT_SPACING,
        marginBottom: DEFAULT_SPACING,
      },
      textContainer: {
        marginLeft: DEFAULT_SPACING * 2,
        marginRight: 'auto',
        alignItems: 'center',
        flexDirection: 'row',
      },
      header: { marginRight: DEFAULT_SPACING },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function StartInvestingCard({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  function onPress() {
    navigation.navigate('Modal', { screen: 'Start Investing Category' })
  }

  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <CircleIcon
        icon='seedling'
        type='fas'
        size={26}
        color={iguanaGreen}
        backgroundColor={rgba(iguanaGreen, 0.1)}
      />
      <View style={styles.textContainer}>
        <Text style={styles.header}>Start Investing</Text>
        <Text style={styles.text}>
          Compare investment companies and {isNotWeb ? `\n` : ''}take the first step
          towards your finanical goals.
        </Text>
      </View>

      <FontAwesomeIcon
        icon='circle-chevron-right'
        color={rgba(lightSilverGreen, 0.5)}
        size={16}
        style={{ marginRight: DEFAULT_SPACING }}
      />
    </TouchableOpacity>
  )
}
