import { View, StyleSheet, ScrollView, Text, Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { map } from 'lodash'
import {
  ListItem,
  Divider
} from 'react-native-elements'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import ShareAndEmpowerCard from './components/ShareAndEmpowerCard'
import hapticFeedback from './components/hapticFeedback'
import { DEFAULT_SPACING } from './styles'
import { darkblue, magicPotion, white } from './styles/colors'
import { logout } from './actions'
import { useLayoutEffect } from 'react'
import PageHeader from './components/PageHeader'
import PageLayout from './components/userOptionsMenu/PageLayout'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: white,
  },
  scrollView: {
    paddingBottom: DEFAULT_SPACING * 3,
    paddingHorizontal: 16,
  },
  title: { color: darkblue, fontWeight: '600', fontSize: 24, lineHeight: 29, marginBottom: 22, },
  content: { paddingVertical: 12 },
  label: {
    color: darkblue,
    fontSize: 18,
    lineHeight: 22,
    fontWeight: '500',
  },
  left: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: {
        paddingHorizontal: DEFAULT_SPACING * 4,
        paddingVertical: 56,
      },
      scrollView: { paddingHorizontal: 0 },
      left: { width: '40%', maxWidth: 536, }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function UserOptionsMenuScreen({ route, navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()

  const options = [{
    icon: 'user',
    label: 'Edit Profile',
    onPress: () => {
      navigation.navigate('Modal', { screen: 'Edit User Profile' })
    }
  }, {
    icon: 'lock-keyhole',
    label: 'Change Password',
    onPress: () => {
      navigation.navigate('Modal', { screen: 'Edit Password' })
    }
  }, {
    icon: 'people-group',
    label: 'My Teams',
    onPress: () => {
      navigation.navigate('Modal', { screen: 'My Teams' })
    }
  }, {
    icon: 'arrow-right-from-bracket',
    label: 'Sign Out of Troutwood',
    onPress: () => {
      dispatch(logout())

      navigation.reset({
        key: null,
        index: 0,
        routes: [
          { name: 'Login' }
        ],
      })
    }
  }]

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTitle: null
    })
  }, [navigation])

  const main = (<>
    <ScrollView style={styles.scrollView}>
      {isNotWeb && (<Text style={styles.title}>More</Text>)}

      {map(options, (option, index) => {
        const { label, icon } = option

        return (
          <>
            <ListItem
              containerStyle={{ paddingHorizontal: 0 }}
              onPress={() => {
                hapticFeedback()
                option.onPress()
              }}
            >
              <FontAwesomeIcon icon={['fa', icon]} color={index === options.length - 1 ? magicPotion : '#5F8EE8'} size={24} />
              <ListItem.Content style={styles.content}>
                <ListItem.Title style={styles.label}>{label}</ListItem.Title>
              </ListItem.Content>
              <FontAwesomeIcon icon="circle-chevron-right" size={14} color="#D0D7D9" />
            </ListItem>

            <Divider color="rgb(223, 230, 236)" />
          </>
        )
      })}
    </ScrollView>
    {isNotWeb && (<View style={{ bottom: 60, }}>
      <ShareAndEmpowerCard />
    </View>)}
  </>
  )

  return (
    <PageLayout
      title='More'
      main={main}
    />
  );
}
