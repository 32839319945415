import AsyncStorage from '@react-native-async-storage/async-storage'
import { stateReducer } from '@truefit/redux-utils'
import { GET_FINANCIAL_PROJECTIONS, CLEAR } from '../actions'
import getFinancialProjectionsOverview from '../functions/getFinancialProjectionsOverview'

const initialState = []

export default stateReducer(initialState, { 
  [GET_FINANCIAL_PROJECTIONS]: (_, payload) => getFinancialProjectionsOverview(payload),
  [CLEAR]: () => initialState
})
