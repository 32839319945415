import moment from 'moment/moment'

/** UTILITY FUNCTIONS */
const DATE_KEY = 'forecast_date'
const BS_ACCOUNT_KEY = 'account'
const BUDGET_ITEM_KEY = 'budget'
const NET_WORTH_KEY = 'net'
const BS_ACCOUNT_TYPE = 'BS_ACCOUNT'
const BUDGET_ITEM_TYPE = 'BUDGET_ITEM'
const NET_WORTH_TYPE = 'NET_WORTH'
const DATE_TYPE = 'DATE'
const UNKNOWN_TYPE = 'UNKNOWN_ITEM'

/**
* returns an object which looks like this [
*  {
*      type: BS_ACCOUNT / BUDGET_ITEM / NET_WORTH
*      id: ID of the BS_ACCOUNT / BUDGET_ITEM (if applicable)
*  }
* ]
* */
function getColumnMapping(headerRow) {
  const columnMapping = [] // The first column is always the date

  /* The first item is the date */
  for (let i = 0; i < headerRow.length; i += 1) {
    const headerText = headerRow[i]

    const [type, id] = headerText.split(':')
    let mapping = {}
    switch (type) {
    case DATE_KEY:
      mapping = { type: DATE_TYPE }
      break;
    case NET_WORTH_KEY:
      mapping = { type: NET_WORTH_TYPE }
      break;
    case BS_ACCOUNT_KEY:
      mapping = { type: BS_ACCOUNT_TYPE, id }
      break;
    case BUDGET_ITEM_KEY:
      mapping = { type: BUDGET_ITEM_TYPE, id }
      break;
    default:
      mapping = { type: UNKNOWN_TYPE, id }
      break;
    }

    columnMapping[i] = mapping
  }

  return columnMapping
}

/** END UTILITY FUNCTIONS */

/**
* Output:
* [
*    {
*        date: Date, // Date of the projection
*        netWorth: Number, // The networth on this date
*        budgetItems: [BudgetItem], // Same data structure as getBSAccounts
*        bsAccounts: [BalanceSheetAccount] Same data structure as getBudgetItems
*    }
* ]
*/
export default function getFinancialProjectionsOverview(rawProjections) {
  const header = rawProjections[0]
  const columnMapping = getColumnMapping(header)
  const processedProjections = []

  for (let i = 1; i < rawProjections.length; i += 1) {
    const row = rawProjections[i]
    const projection = {
      netWorth: 0,
      bsAccounts: [],
      budgetItems: []
    }
    for (let j = 0; j < row.length; j += 1) {
      const col = row[j]

      switch (columnMapping[j].type) {
      case DATE_TYPE:
        projection.date = moment(col).toDate()
        break;
      case NET_WORTH_TYPE:
        projection.netWorth = parseFloat(col)
        break;
      case BS_ACCOUNT_TYPE:
        projection.bsAccounts.push({
          accountId: columnMapping[j].id,
          balance: parseFloat(col)
        })
        break;
      case BUDGET_ITEM_TYPE:
        projection.budgetItems.push({
          budgetItemId: columnMapping[j].id,
          amount: parseFloat(col)
        })
        break;
      default:
        break;
      }
    }
    processedProjections.push(projection)
  }

  return processedProjections
}
 