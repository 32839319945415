const LibraryData = {
  books: {
    tms: {
      img_url: '../assets/missing-semester.png',
      image: require('../assets/missing-semester.png'),
      pdf: 'https://tw-mobile-app-resources.s3.us-east-2.amazonaws.com/missing-semester.pdf',
      title: 'The Missing Semester',
    },
    tmss: {
      img_url: '../assets/missing-2nd-semester.png',
      image: require('../assets/missing-2nd-semester.png'),
      pdf: 'https://tw-mobile-app-resources.s3.us-east-2.amazonaws.com/missing-2nd-semester.pdf',
      title: 'The Missing 2nd Semester',
    }
  },
  resources: {
    tms: {
      image: require('../assets/tms-presentation.png'),
      pdf: 'https://tw-mobile-app-resources.s3.us-east-2.amazonaws.com/tms-presentation.pdf',
      title: 'Chapter Discussions',
      description: 'Chapter discussion questions designed to engage students',
    },
    tmss: {
      image: require('../assets/tms-2-presentation.png'),
      pdf: 'https://tw-mobile-app-resources.s3.us-east-2.amazonaws.com/tms-2-presentation.pdf',
      title: 'Chapter Discussions',
      description: 'Chapter discussion questions designed to engage students',
    }
  },
  navigationText: {
    quiz: {
      title: 'Quiz',
      pretext: 'Test your knowlege with ',
      posttext: ' Quiz'
    },
    ask: {
      title: 'Ask the Author',
      description: 'Have a question or Comment? \nAsk Gene!'
    }
  }

}

export default LibraryData;
