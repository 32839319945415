import { put, post } from 'http'
import { getCurrentUser } from './getCurrentUser'
import { getPlans } from './getPlans'
import { setAccount } from './setAccount'
import { getWellnessChecklists } from './getWellnessChecklists'
import { getPersonalMilestones } from './getPersonalMilestones'

export const saveAccount = function (payload) {
  return async (dispatch) => {
    try {
      const result = payload.id ? await put(`accounts/${payload.id}/`, payload)
        : await post('accounts/', payload)

      dispatch(setAccount(result.data, payload.id))
      dispatch(getCurrentUser())
      dispatch(getPlans())
      dispatch(getWellnessChecklists())
      dispatch(getPersonalMilestones())
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
