// import proApplicationEndpoints from '../api/proApplicationEndpoints'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { get } from 'http'
import { isNull } from 'lodash'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { currentUserSelector } from '../selectors'
import getProjectionsFromStorage from 'functions/getProjectionsFromStorage'
import downloadProjections from 'functions/downloadProjections'
import getFilePath from 'functions/getFilePath'

export const GET_FINANCIAL_PROJECTIONS = 'GET_FINANCIAL_PROJECTIONS'
export const PROJECTIONS_LOCAL_STORE_KEY = (planId) => `PROJNS_OBJ_${planId}`

const fetchProjectionInfoFromHomebase = async (
  userId,
  planId,
  maxRetries = 10,
  retryInterval = 3000
) => {
  if (maxRetries === 0) {
    throw new Error('Maximum retries reached')
  }

  const result = await get(proApplicationEndpoints.getProjection(userId, planId))
  const { data } = result

  if (data.status !== 'COMPLETE') {
    console.log('Status not COMPLETE, retrying in', retryInterval, 'ms')
    await new Promise((resolve) => setTimeout(resolve, retryInterval))
    return await fetchProjectionInfoFromHomebase(userId, planId, maxRetries - 1, retryInterval)
  }

  return result
}

/**
 * EXAMPLES
 * * RESPONSE {
    userId: INT,
    planId: UUID,
    projectionUri: String('s3_bucket_uri'),
    timestamp: String(ISO8601 date string)
  }
*/
/**
 * TODO TODO TODO: ADD ERROR HANDLING
 * */
export const getFinancialProjections = (planId) => async (dispatch, getState) => {
  // try {
  const { id: userId } = currentUserSelector(getState())

  /* Step: 1 - Get projection information from Async storage */
  const rawProjectionInfo = await AsyncStorage.getItem(PROJECTIONS_LOCAL_STORE_KEY(planId))
  const storedProjectionInfo = isNull(rawProjectionInfo) ? {} : JSON.parse(rawProjectionInfo)

  console.log('about to get projections')

  /* Step: 2 - Fetch the projection information from Homebase */
  // TODO: Error handling
  const result = await fetchProjectionInfoFromHomebase(userId, planId)

  const { data: infoFromHome } = result

  /* Step: 3 - Check if timestamps match between the two */
  if (storedProjectionInfo.timestamp !== infoFromHome.timestamp) {
    /* Step: 3.b
      - If timestamps DON'T match then;
        get the actual projection from Homebase;
        store in device blob storage;
        make entry in Async storage
      */
    //  TODO: Error handling
    console.log('downloading projections', infoFromHome)
    await downloadProjections(getFilePath(planId), infoFromHome.projectionUrl).then((res) => {
      /*
          The file is downloaded successfully. Make entry in Async storage;
          remember that Async storage is string only values
        */
      AsyncStorage.setItem(PROJECTIONS_LOCAL_STORE_KEY(planId), JSON.stringify(infoFromHome))
    })
  }

  /* Step: 3.a
      If timestamps for both exist and match then;
      get the actual projection from device blob storage
    */
  const projections = await getProjectionsFromStorage(getFilePath(planId))

  /* Step: 4 - pass projections to reducer */
  dispatch({
    type: GET_FINANCIAL_PROJECTIONS,
    payload: projections
  })
  // } catch (error) {
  //   console.log(error.response)
  //   console.log(error, ' ---- error from getFinancialProjections action')
  // }
}
