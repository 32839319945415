import { stateReducer } from '@truefit/redux-utils'
import { each, filter } from 'lodash'
import { SET_BS_ACCOUNTS, SET_BS_ACCOUNT, DELETE_BS_ACCOUNT, CLEAR } from '../actions'
import { ASSET, LIABILITY } from '../constants'

const initialState = { assetAccounts: [], liabilityAccounts: [] }

const enrichAccount = (account) => ({
  ...account,
  balance: parseFloat(account.balance),
  defaultRate: account.defaultRate
})

function bsAccountsResponse(assetAccounts, liabilityAccounts) {
  return {
    assetAccounts,
    liabilityAccounts,
    totalAccounts: assetAccounts.length + liabilityAccounts.length
  }
}

export default stateReducer(initialState, {
  [SET_BS_ACCOUNT]: (state, { account, accountId }) => {
    const { accountType } = account
    const accountState = accountType === ASSET ? state.assetAccounts : state.liabilityAccounts

    const newAccountsState = []

    each(accountState, (item) => {
      let newItem = item
      if (item.accountId === accountId) {
        newItem = { ...item, ...account }
      }
      newAccountsState.push(enrichAccount(newItem))
    })

    if (!accountId) { /* If this is a new account */
      newAccountsState.push(enrichAccount(account))
    }

    if (accountType === ASSET) {
      return bsAccountsResponse(newAccountsState, [...state.liabilityAccounts])
    }

    if (accountType === LIABILITY) {
      return bsAccountsResponse([...state.assetAccounts], newAccountsState)
    }

    return state
  },
  [SET_BS_ACCOUNTS]: (_, { accounts }) => {
    const [assetAccounts, liabilityAccounts] = [[], []]
    // order accounts by position (0 to n)
    const orderedAccounts = accounts.sort((a, b) => a.position - b.position)

    each(orderedAccounts, (account) => {
      const enrichedAccount = enrichAccount(account)

      if (account.accountType === ASSET) {
        assetAccounts.push(enrichedAccount)
      }

      if (account.accountType === LIABILITY) {
        liabilityAccounts.push(enrichedAccount)
      }
    })

    return bsAccountsResponse(assetAccounts, liabilityAccounts)
  },
  [DELETE_BS_ACCOUNT]: (state, { accountType, accountId }) => {
    // Look through the account category items and delete this item.
    // Then update all the other stuff like total etc.

    if (accountType === ASSET) {
      return bsAccountsResponse(
        filter(state.assetAccounts, (account) => (account.accountId !== accountId)),
        state.liabilityAccounts
      )
    }

    if (accountType === LIABILITY) {
      return bsAccountsResponse(
        state.assetAccounts,
        filter(state.liabilityAccounts, (account) => (account.accountId !== accountId))
      )
    }

    return state
  },
  [CLEAR]: () => initialState
});
