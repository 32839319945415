import { useState, } from 'react'
import { StyleSheet, View, Text, useWindowDimensions } from 'react-native'
import Carousel from './components/Carousel'
import CarouselCardItem from './components/CarouselCardItem' // NEW Component :MM
import data from './assets/data/startInvestingMissionData'
import { black, } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const basicStyles = {
  paginationContainerStyle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  }
}

const styles = StyleSheet.create(basicStyles)

export function fixTitle(title) {
  let output = ''
  const openText = title.slice(0, 4)

  if (title.slice(0, 7) == 'Open a ') {
    output = title.slice(7)
  } else if (openText == 'Open') {
    output = title.slice(4)
  } else {
    output = title
  }
  return output
}

export default function StartInvestingCarouselScreen({ route, navigation }) {
  const [carIndex, setCarIndex] = useState(0)

  const { title, value } = route.params

  const filtered_data = data.map(({ name, imgUrl, links, mission, cost }) => ({ name, imgUrl, links, mission, cost }))

  const window = useWindowDimensions()
  const height = window.height * 0.9

  const slides = filtered_data.map((item) => (
    <CarouselCardItem
      {...item}
      item={item}
      value={value}
      title={fixTitle(title)}
    />
  ))

  return (
    <View>
      <Text style={{ textAlign: 'center', marginVertical: DEFAULT_SPACING, fontSize: 14, color: black }}>
        The investment companies listed below have been
        {' '}
        {'\n'}
        selected by Troutwood for you to learn more,
        {' '}
        {'\n'}
        compare to one another, and gain confidence.
      </Text>

      <Carousel
        data={slides}
        height={height}
        onSwipe={(carIndex) => setCarIndex(carIndex)}
        paginationContainerStyle={styles.paginationContainerStyle}
      />
    </View>
  )
}
