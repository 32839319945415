import { useLayoutEffect, useState } from 'react'
import { StyleSheet, ScrollView, Platform, View, } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { gunmetal, info, orange, red, primary, warning, pink, purple, } from './styles'

import Container from './components/Container'
import CheckboxList from './components/CheckboxList'
import Header from './components/Header'
import BackButton from './components/teamOnboarding/BackButton'
import Title from './components/teamOnboarding/Title'
import Button from './components/Button'

const basicStyles = {
  container: { alignItems: 'center', paddingBottom: 24, },
  headerTextStyle: { textAlign: 'center', },
  checkboxListContainer: {
    width: '100%',
    flex: 1,
    paddingHorizontal: 20,
  },
  buttonWrapper: { width: '100%', },
  button: { marginHorizontal: 20, },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      headerTextStyle: { fontSize: 18, lineHeight: 21, fontWeight: '500' },
      buttonWrapper: {
        position: 'absolute',
        alignItems: 'center',
        bottom: 54,
      },
      checkboxListContainer: { maxHeight: 412, paddingHorizontal: 48, },
      button: {
        width: 345,
        marginHorizontal: 'auto',
      },
    },
  }
)

const options = [
  {
    leftText: 'Stocks',
    value: 'Stocks',
    icon: 'chart-line-up',
    color: info
  }, {
    leftText: 'Bonds',
    value: 'Bonds',
    icon: 'badge-dollar',
    color: orange
  }, {
    leftText: 'Real Estate',
    value: 'Real Estate',
    icon: 'house',
    color: red
  }, {
    leftText: 'Budgeting',
    value: 'Budgeting',
    icon: 'dollar-sign',
    color: primary
  }, {
    leftText: 'Crypto',
    value: 'Crypto',
    icon: 'gem',
    color: warning
  }, {
    leftText: 'Debt Repayment',
    value: 'Debt Repayment',
    icon: 'hand-holding-dollar',
    color: pink
  }, {
    leftText: 'Retirement Planning',
    value: 'Retirement Planning',
    icon: 'tree-palm',
    color: purple
  }, {
    leftText: 'Long-term Investing',
    value: 'Long-term Investing',
    icon: 'clock',
    color: gunmetal
  }
]

const isNotWeb = Platform.OS !== 'web'

export default function JoinOrganizationInterestsScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [selected, setSelected] = useState([])

  const { invitation } = route.params

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: null,
      headerTransparent: true,
      headerLeft: () => (<BackButton onPress={() => navigation.pop()} />)
    })
  }, [navigation])

  function handlePress() {
    const invitationParams = { ...invitation, interests: selected }

    navigation.navigate('Modal', { screen: 'Join Team Wellness Checklist', params: { invitation: invitationParams } })
  }

  function handleSelect(value) {
    const newSelected = selected.includes(value)
      ? selected.filter((item) => item !== value) : [...selected, value]

    setSelected(newSelected)
  }

  return (
    <Container card={true} style={[styles.container, { marginTop: isNotWeb ? 160 : 'auto' }]}>
      <Title />
      <Header
        textStyle={styles.headerTextStyle}
        text={'Do any of the topics below interest you? \n Check all that apply.'}
      />

      <ScrollView style={styles.checkboxListContainer}>
        <CheckboxList
          options={options}
          value={selected}
          onChange={handleSelect}
          size={isNotWeb ? 26 : 28}
          variant='circle'
        />
      </ScrollView>

      <View style={styles.buttonWrapper}>
        <Button
          style={styles.button}
          onPress={handlePress}
        >
          Continue
        </Button>
      </View>
    </Container>
  )
}
