import { useState } from 'react'
import { View, Text, StyleSheet, Image } from 'react-native'
import { DEFAULT_SPACING, gunmetal, info, rgba, white } from '../../styles'
import TouchableOpacity from '../TouchableOpacity'

import openLink from '../../functions/openLink'

import CircleImage from '../CircleImage'

import SlideupDrawer from '../SlideupDrawer'
import Link from '../Link'
import CircleIcon from '../CircleIcon'

const styles = StyleSheet.create({
  container: { paddingHorizontal: DEFAULT_SPACING, },
  circleImage: {
    backgroundColor: white,
    marginTop: DEFAULT_SPACING,
    marginRight: DEFAULT_SPACING
  },
  proudly: {
    fontSize: 14,
    color: gunmetal,
    textAlign: 'center',
    marginTop: DEFAULT_SPACING * 4
  },
  logo: {
    width: 100,
    height: 100,
    alignSelf: 'center'
  },
  imageContainer: {
    borderRadius: 12,
    overflow: 'hidden'
  },
  bannerImage: {
    width: '100%',
    height: 200,
  },
  link: {
    alignSelf: 'center',
    marginVertical: DEFAULT_SPACING
  },
  rowOfSocials: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '90%',
    alignSelf: 'center',
    flexWrap: 'wrap',
    marginTop: DEFAULT_SPACING
  }
})

export default function SponsorButton({ sponsor, currentUser }) {
  const [visible, setVisible] = useState(false)

  function handlePress() {
    setVisible(true)
  }

  const socials = [
    { icon: 'globe', url: sponsor.website, type: 'fas' },
    { icon: 'facebook', url: sponsor.facebook, type: 'fa-brands' },
    { icon: 'twitter', url: sponsor.twitter, type: 'fa-brands' },
    { icon: 'instagram', url: sponsor.instagram, type: 'fa-brands' },
    { icon: 'linkedin', url: sponsor.linkedin, type: 'fa-brands' },
    { icon: 'youtube', url: sponsor.youtube, type: 'fa-brands' },
    { icon: 'tiktok', url: sponsor.tiktok, type: 'fa-brands' }
  ].filter((social) => social.url)

  return (
    <>
      <TouchableOpacity onPress={handlePress}>
        <CircleImage
          size={50}
          type="fa-brands"
          resizeMode="contain"
          style={styles.circleImage}
          image={{ uri: sponsor.logo_url }}
        />
      </TouchableOpacity>

      <SlideupDrawer closeEnabled scrollable snapPointIndex={1} visible={visible} setVisible={setVisible}>
        <View style={styles.container}>
          <Text style={styles.proudly}>Proudly sponsored by:</Text>
          <Image
            source={{ uri: sponsor.logo_url }}
            style={styles.logo}
            resizeMode="contain"
          />

          {sponsor.banner_image && (
            <View style={styles.imageContainer}>
              <Image
                source={{ uri: sponsor.banner_image }}
                style={styles.bannerImage}
                resizeMode="cover"
              />
            </View>
          )}

          { sponsor.website && (
            <Link
              style={styles.link}
              onPress={() => {
                openLink(sponsor.website)
              }}
            >
              {sponsor.website}
            </Link>
          ) }

          <View style={styles.rowOfSocials}>
            {socials.map((social) => (
              <TouchableOpacity
                key={social.icon}
                onPress={() => {
                  openLink(social.url)
                }}
              >
                <CircleIcon
                  type={social.type}
                  icon={social.icon}
                  color={info}
                  backgroundColor={rgba(info, 0.1)}
                  size={16}
                />
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </SlideupDrawer>
    </>
  )
}
