import { ScrollView, StyleSheet, Text, View } from 'react-native'
import pluralize from 'pluralize'
import SlideupDrawer from '../SlideupDrawer'
import CircleIcon from '../CircleIcon'

import { rgba, DEFAULT_SPACING, darkblue, darkOrange, lightestgray, orange, pink, primary, red, info, purple, moss, gray, skyblue, lightgray } from '../../styles'
import hapticFeedback from '../hapticFeedback'
import TouchableOpacity from '../TouchableOpacity'

import { lifeEventOptions } from '../../constants'

const styles = StyleSheet.create({
  eventContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: DEFAULT_SPACING * 2,
    paddingHorizontal: DEFAULT_SPACING,
    borderBottomColor: lightestgray,
    borderBottomWidth: 1
  },
  eventTitleContainer: { marginLeft: DEFAULT_SPACING, },
  eventTitle: {
    fontWeight: '500',
    fontSize: 16,
    color: darkblue
  },
  eventYear: {
    color: gray,
    fontSize: 14,
    marginTop: DEFAULT_SPACING / 3
  }
})

export default function EventsSlideup({
  marginTop = 200,
  events,
  plan,
  visible,
  setVisible,
  setModalVisible,
  navigation
}) {
  const height = 800

  const currentYear = new Date().getFullYear()

  function handlePress(event) {
    hapticFeedback()

    setVisible(false)

    const mergedEvent = { ...event, ...lifeEventOptions[event.key] }
    mergedEvent.type = event.key
    mergedEvent.plan = { id: event.plan }

    navigation.navigate('Modal', { screen: 'Add Life Event', params: { ...mergedEvent, plan } })

    setTimeout(() => {
      setModalVisible(true)
    }, 1000)
  }

  return (
    <SlideupDrawer title={`${events.length} ${pluralize('Events', events)}`} closeEnabled marginTop={marginTop} height={height} visible={visible} setVisible={setVisible}>
      <ScrollView contentContainerStyle={{ paddingBottom: 200 }}>
        {events.map((event, i) => (
          <TouchableOpacity onPress={() => handlePress(event)} style={styles.eventContainer}>
            <CircleIcon icon={lifeEventOptions[event.key].icon} color={lifeEventOptions[event.key].color} backgroundColor={rgba(lifeEventOptions[event.key].color, 0.1)} />
            <View style={styles.eventTitleContainer}>
              <Text style={styles.eventTitle}>
                {lifeEventOptions[event.key].label}
                {' '}
                (
                {event.name}
                )
              </Text>
              <Text style={styles.eventYear}>{currentYear + event.year}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </SlideupDrawer>
  )
}
