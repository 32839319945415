// import { get } from 'http'
import { get } from 'http';
import proApplicationEndpoints from '../api/proApplicationEndpoints';
import { currentUserSelector } from '../selectors';
import { setBSAccounts } from './setBSAccounts'

// eslint-disable-next-line import/prefer-default-export
export const getBSAccounts = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const currentUser = currentUserSelector(state)
    const { data: accounts } = await get(proApplicationEndpoints.getAccounts(currentUser.id))
    dispatch(setBSAccounts({ accounts }))
  } catch (e) {
    console.error(e, e.response)
  }
}
