import * as Yup from 'yup'

import {
  INVALID_MUST_BE_GREATER_THAN_ZERO,
  INVALID_INT,
  INVALID_MIN,
  REQUIRED,
} from './constants'

export const Fields = {
  category: 'category',
  balance: 'balance',
  interestRate: 'interest_rate',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.category]: Yup.string().required(REQUIRED),
  [Fields.balance]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MUST_BE_GREATER_THAN_ZERO)
    .required(REQUIRED),
  [Fields.interestRate]: Yup.number().positive(INVALID_MIN)
    .min(0, INVALID_MUST_BE_GREATER_THAN_ZERO)
    .required(REQUIRED)
})

export const InitialValues = {
  [Fields.category]: '',
  [Fields.balance]: '',
  [Fields.interestRate]: '',
}
