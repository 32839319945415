import { useState } from 'react'
import { Text, StyleSheet, Platform } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { darkblue } from '../styles/colors'
import TooltippedText from './TooltippedText'

const useStyles = CreateResponsiveStyle(
  {
    text: {
      fontWeight: '500',
      fontSize: 20,
      letterSpacing: 0,
      color: darkblue,
      textAlign: 'left',
    },
  },
  {
    [minSize(DEVICE_SIZES.LG)]: {
      text: {
        fontWeight: '600',
        fontSize: 22,
      },
    },

  }
)

export default function HeaderLabel({ children, tooltipBody, style }) {
  const styles = useStyles()
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)

  return (
    tooltipBody
      ? (
        <TooltippedText
          visible={tooltipIsVisible}
          setVisible={setTooltipIsVisible}
          anchorStyle={[styles.text, style]}
          anchorText={children}
          contentHeader={children}
          contentBody={tooltipBody}
        />
      )
      : (
        <Text style={[styles.text, style]}>
          {children}
        </Text>
      )
  )
}
