import * as Yup from 'yup'
import { INVALID_INT, INVALID_MAX, INVALID_MIN, REQUIRED } from './constants'

export const Fields = {
  age: 'age',
  city: 'city',
  career: 'career',
}

export const AgeValidationSchema = Yup.object().shape({
  [Fields.age]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MIN)
    .max(95, `${INVALID_MAX}95`)
    .required(REQUIRED),
})

export const LocationValidationSchema = Yup.object().shape(
  { [Fields.city]: Yup.object().required(REQUIRED), }
)

export const CareerValidationSchema = Yup.object().shape(
  { [Fields.career]: Yup.object().required(REQUIRED), }
)
