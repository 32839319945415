import { useState, useEffect, useLayoutEffect } from 'react'
import { Platform, View, StyleSheet } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { useSelector, useDispatch } from 'react-redux'
import SearchInput from './components/SearchInput'
import OccupationGroupList from './components/careers/OccupationGroupList'
import LocationSwitcher from './components/careers/LocationSwitcher'

import { getOccupations, setCareerLocation } from './actions'
import { careerLocationSelector } from './selectors'
import { white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import { CareerExplore } from './CareerExploreScreen'
import CareerExploreSearchPageHeader from './components/careers/CollegeExploreSearchPageHeader'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: white,
  },
  pageHeader: {
    padding: 40,
    paddingBottom: 0,
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'rgb(236, 241, 244)',
    paddingBottom: DEFAULT_SPACING * 3,
  },
  listWrapper: {
    width: '100%',
    height: '100%',
  },
  details: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 18,
      },
      listWrapper: { width: '40%', },
      details: {
        width: '60%',
        height: '100%',
      },
    }
  }
)

export default function CareerExploreOccupationsScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()

  const { params } = route
  const { occupationGroup } = params
  const [careerSearch, setCareerSearch] = useState(null)
  const [career, setCareer] = useState(null)
  const careerLocation = useSelector((state) => careerLocationSelector(state))

  async function searchGroups(careerLocation) {
    try {
      dispatch(getOccupations(careerLocation.id))
    } catch (error) {
      console.error(error.response)
    }
  }

  useEffect(() => {
    searchGroups(careerLocation)
  }, [careerLocation])

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Explore Careers',
      headerShown: isNotWeb,
    });
  }, [navigation])

  return (
    <View style={styles.container}>
      {!isNotWeb && (
        <View style={styles.pageHeader}>
          <CareerExploreSearchPageHeader navigation={navigation} />
        </View>
      )}
      <SearchInput
        style={{ marginHorizontal: isNotWeb ? 12 : 40, }}
        includeMargin
        value={careerSearch}
        placeholder="Find Career"
        onChange={(value) => {
          setCareerSearch(value || null)
        }}
      />

      <LocationSwitcher
        city={careerLocation}
        setCity={(loc) => dispatch(setCareerLocation(loc))}
      />

      <View style={styles.mainContainer}>
        <View style={styles.listWrapper}>
          <OccupationGroupList
            occupationGroup={occupationGroup}
            careerSearch={careerSearch}
            onOccupationPress={(occupation) => {
              if (isNotWeb) navigation.navigate('Modal', { screen: 'Career Explore', params: { occupation } })
              else setCareer(occupation)
            }}
          />
        </View>

        {!isNotWeb && !!career && (
          <View style={styles.details}>
            <CareerExplore occupation={career} />
          </View>
        )}
      </View>
    </View>
  )
}
