import { View, Text, StyleSheet, useWindowDimensions, Platform } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { DEFAULT_SPACING, darkJungleGreen, white } from '../../styles'

const CIRCLE_SIZE = 25

const basicStyles = {
  container: {
    height: 150,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: DEFAULT_SPACING,
    paddingTop: 105,
    marginBottom: DEFAULT_SPACING * 2
  },
  builderProgressBarItem: { alignItems: 'center', width: CIRCLE_SIZE },
  builderProgressBarItemCircle: {
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: white,
    justifyContent: 'center',
    alignItems: 'center'
  },
  builderProgressBarItemLabel: {
    textAlign: 'center',
    marginTop: 5,
    fontSize: 10,
    color: white,
    width: 100
  },
  builderProgressBarItemLine: {
    width: 100,
    height: 1,
    backgroundColor: white,
    position: 'absolute',
    left: CIRCLE_SIZE,
    top: 13,
    zIndex: -1
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    container: {
      width: '50%',
      paddingHorizontal: 0,
      paddingTop: 28,
      height: 80
    }
  }
})

const isNotWeb = Platform.OS !== 'web'

function BuilderProgressBarItem({ option, status, showDivider = true, width }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const color = status === 'complete' ? darkJungleGreen : white

  return (
    <View style={[styles.builderProgressBarItem, { opacity: status === 'incomplete' ? 0.8 : 1 }]}>
      <View
        style={[
          styles.builderProgressBarItemCircle,
          { backgroundColor: status === 'complete' ? white : option.incompleteBackgroundColor }
        ]}
      >
        <FontAwesomeIcon size={14} color={color} icon={option.icon} />
      </View>

      <Text style={styles.builderProgressBarItemLabel}>{option.label}</Text>

      {showDivider && <View style={[styles.builderProgressBarItemLine, { width }]} />}
    </View>
  )
}

export default function BuilderProgressBar({ options, option }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const indexOfOption = options.findIndex((o) => o.label === option)
  const window = useWindowDimensions()
  const width =
    ((isNotWeb ? window.width : window.width * 0.5) -
      DEFAULT_SPACING * 2 -
      CIRCLE_SIZE * options.length) /
    (options.length - 1)

  return (
    <View style={styles.container}>
      {options.map((o, i) => {
        let status = 'incomplete'

        if (i === indexOfOption) {
          status = 'current'
        } else if (i < indexOfOption) {
          status = 'complete'
        }

        return (
          <BuilderProgressBarItem
            key={o.label}
            option={o}
            status={status}
            width={width}
            showDivider={i !== options.length - 1}
          />
        )
      })}
    </View>
  )
}
