import { useRef } from 'react'
import { StyleSheet } from 'react-native'

import { post } from 'http'
import Input from './components/Input'
import Form from './components/Form'
import Container from './components/Container'

import {
  ValidationSchema,
  InitialValues,
  Fields
} from './schemas/passwordSchema'
import { white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: white
  },
  safeAreaStyle: { flex: 1, },
  formContainer: {
    padding: DEFAULT_SPACING,
    flex: 1
  },
  linkContainer: {
    justifyContent: 'flex-start',
    marginTop: 30
  },
  link: {
    color: 'rgb(222, 54, 84)',
    fontSize: 16,
    fontWeight: '600'
  }
})

export default function EditProfileScreen({ route, navigation }) {
  const oldPasswordField = useRef(null)
  const passwordField = useRef(null)
  const newPassword2Field = useRef(null)

  return (
    <Container style={styles.container}>
      <Form
        buttonText="Update Password"
        validationSchema={ValidationSchema}
        initialValues={InitialValues}
        onSubmit={async (values, actions) => {
          try {
            await post('rest-auth/password/change/', values)

            navigation.popToTop()
            navigation.pop()
          } catch (e) {
            if (e.response && e.response.data && e.response.data.newPassword1) {
              actions.setFieldError(Fields.newPassword1, e.response.data.newPassword1.join(', '));
            } else if (e.response && e.response.data && e.response.data.newPassword2) {
              actions.setFieldError(Fields.newPassword2, e.response.data.newPassword2.join(', '));
            } else if (e.response && e.response.data && e.response.data.old_password) {
              actions.setFieldError(Fields.oldPassword, e.response.data.old_password.old_password);
            } else {
              actions.setFieldError(Fields.oldPassword, 'Unable to reset password');
            }
          }
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched }) => (
          <>
            <Input
              ref={oldPasswordField}
              name={Fields.oldPassword}
              errorMessage={errors[Fields.oldPassword]}
              touched={touched[Fields.oldPassword]}
              onChangeText={handleChange(Fields.oldPassword)}
              onBlur={handleBlur(Fields.oldPassword)}
              value={values[Fields.oldPassword]}
              label="Old Password"
              secureTextEntry
              returnKeyType="next"
              onSubmitEditing={() => passwordField.current.focus()}
            />

            <Input
              ref={passwordField}
              name={Fields.newPassword1}
              errorMessage={errors[Fields.newPassword1]}
              touched={touched[Fields.newPassword1]}
              onChangeText={handleChange(Fields.newPassword1)}
              onBlur={handleBlur(Fields.newPassword1)}
              value={values[Fields.newPassword1]}
              label="Password"
              secureTextEntry
              returnKeyType="next"
              onSubmitEditing={() => newPassword2Field.current.focus()}
            />

            <Input
              ref={newPassword2Field}
              name={Fields.newPassword2}
              errorMessage={errors[Fields.newPassword2]}
              touched={touched[Fields.newPassword2]}
              onChangeText={handleChange(Fields.newPassword2)}
              onBlur={handleBlur(Fields.newPassword2)}
              value={values[Fields.newPassword2]}
              label="Password Confirmation"
              secureTextEntry
              returnKeyType="done"
              onSubmitEditing={handleSubmit}
            />
          </>
        )}
      </Form>
    </Container>
  );
}
