import AsyncStorage from '@react-native-async-storage/async-storage'
import { stateReducer } from '@truefit/redux-utils'
import { SET_CURRENT_USER, SET_METADATA, CLEAR } from '../actions'

const initialState = null;

export const CURRENT_USER_LOCAL_STORAGE_KEY = 'CURRENT_USER'

const setCurrentUser = (_, payload) => {
  AsyncStorage.setItem(CURRENT_USER_LOCAL_STORAGE_KEY, JSON.stringify(payload));
  return payload;
};

const setMetaData = (state, payload) => {
  state.metadata = payload
  return { ...state }
}

export default stateReducer(initialState, {
  [SET_CURRENT_USER]: setCurrentUser,

  [SET_METADATA]: setMetaData,

  [CLEAR]: () => {
    AsyncStorage.removeItem(CURRENT_USER_LOCAL_STORAGE_KEY);
    return initialState;
  },
});
