import { get } from 'http'

export const GET_ASSESS_SCORE_REQUEST = 'GET_ASSESS_SCORE_REQUEST'
export const GET_ASSESS_SCORE_SUCCESS = 'GET_ASSESS_SCORE_SUCCESS'
export const GET_ASSESS_SCORE_FAILURE = 'GET_ASSESS_SCORE_FAILURE'

export const getAssessScoreRequest = () => ({ type: GET_ASSESS_SCORE_REQUEST, })

export const getAssessScoreSuccess = (data) => ({
  type: GET_ASSESS_SCORE_SUCCESS,
  payload: data,
})

export const getAssessScoreFailure = (error) => ({
  type: GET_ASSESS_SCORE_FAILURE,
  payload: error,
})

export const getAssessScore = () => async (dispatch) => {
  dispatch(getAssessScoreRequest())

  try {
    const res = await get('/api/v1/risk-assessment/score/')
    dispatch(getAssessScoreSuccess(res.data))
  } catch (error) {
    dispatch(getAssessScoreFailure(error.message))
  }
}
