import { Text, View, StyleSheet, Platform } from 'react-native'
import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Card from './Card'
import FadeInView from './FadeInView'
import HeaderLabel from './HeaderLabel'

import TouchableOpacity from './TouchableOpacity'

import { darkblue, gunmetal, info, secondary, white } from '../styles/colors'
import { DEFAULT_SPACING } from '../styles'

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'left',
    fontWeight: '500'
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'left',
    letterSpacing: -0.4
  },
  finishedContainer: {
    flex: 1,
    flexDirection: 'row',
    marginVertical: 8,
    marginTop: 16
  },
  circleIcon: {
    height: 50,
    width: 50,
    borderRadius: 50 / 2,
    borderColor: white,
    backgroundColor: 'rgb(30, 184, 110)',
    alignSelf: 'center',
    position: 'absolute',
    zIndex: 100
  },
  card: {
    marginTop: 30,
    padding: DEFAULT_SPACING,
    borderRadius: 10,
    position: 'relative'
  },
  headerLabel: { paddingBottom: DEFAULT_SPACING * 2 },
  editButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    zIndex: 1000,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  editText: {
    fontSize: 14,
    color: info,
    marginLeft: DEFAULT_SPACING / 2
  },
  noShadow: { shadowColor: 'transparent' },
  shadow: {
    ...Platform.select({
      ios: {
        shadowColor: 'rgba(0, 0, 0, 0.12)',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowRadius: 11,
        shadowOpacity: 1
      },
      web: {
        shadowColor: 'rgba(0, 0, 0, 0.12)',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowRadius: 11,
        shadowOpacity: 1
      },
      android: {
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.12)'
      }
    })
  }
});

function Row({ label, value, index, total }) {
  return (
    <View style={{ flexDirection: 'row', paddingVertical: 10, borderBottomWidth: 1, borderBottomColor: index + 1 == total ? 'transparent' : '#EDF2F5' }}>
      <Text style={{ flex: 1, color: secondary }}>{label}</Text>
      <Text style={{ flex: 1, fontWeight: '600' }}>{(value || '').length > 24 ? `${value.substring(0, 24)}...` : value}</Text>
    </View>
  )
}

function RowGroup({ label, rows }) {
  return (
    <View>
      {label && <Text style={{ fontWeight: '600', marginVertical: 12 }}>{label}</Text> }
      {map(rows, (row, index) => <Row {...row} index={index} total={rows.length} />)}
    </View>
  )
}

function EditButton({ onPress }) {
  return (
    <TouchableOpacity style={styles.editButton} onPress={onPress}>
      <FontAwesomeIcon
        icon="pencil"
        color={info}
        size={14}
      />
      <Text style={styles.editText}>Edit</Text>
    </TouchableOpacity>
  )
}

export default function InvestmentCard({ title, subtitle, label, rowGroups, route, navigation, visible }) {
  function handlePress() {
    navigation.navigate('Modal', { screen: route, params: { title, subtitle, builder: true, useGradient: true } })
  }

  return (
    <>
      { visible
        && (
          <FadeInView initialValue={0} toValue={1}>
            <TouchableOpacity onPress={handlePress}>
              <Card style={[styles.card, styles.noShadow]}>
                <View style={styles.row}>
                  <HeaderLabel style={styles.headerLabel}>{label}</HeaderLabel>
                  <EditButton onPress={handlePress} />
                </View>

                <View>
                  {map(rowGroups, (group) => <RowGroup {...group} />)}
                </View>
              </Card>
            </TouchableOpacity>
          </FadeInView>
        )}
    </>
  )
}
