import { get } from 'http'
import { SET_PERSONAL_MILESTONES } from './setPersonalMilestone'

export const getPersonalMilestones = function () {
  return async (dispatch) => {
    try {
      const result = await get('api/v1/personal-milestones/')
      dispatch({
        type: SET_PERSONAL_MILESTONES,
        payload: result.data
      })
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
