import { get } from 'http'
// import { get } from 'http'
import { isNull, isUndefined } from 'lodash'
import { setPlans } from './setPlans'

async function getPlanByPage(next) {
  const url = next || 'api/v1/plans/'
  const result = await get(url)
  return { data: result.data, next: result.next }
}

export const getPlans = function () {
  return async (dispatch) => {
    try {
      let data = []
      let next

      while (isUndefined(next) || !isNull(next)) {
        const result = await getPlanByPage(next)
        next = result.data.next
        data = [...data, ...result.data.results]
      }

      dispatch(setPlans(data))
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
