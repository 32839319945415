import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_AMOUNT,
  INVALID_INT,
  INVALID_MIN,
  INVALID_PERCENT,
  INVALID_PERCENT_MAX,
  INVALID_PERIOD,
  INVALID_PERIOD_30_MAX,
  INVALID_PERIOD_84_MAX
} from './constants'

export const Fields = {
  amount: 'amount',
  percent: 'percent',
  period: 'period',
  type: 'type'
}

export const ValidationSchema = Yup.object().shape({
  amount: Yup.number()
    .required(REQUIRED)
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_AMOUNT)
    .when('type', {
      is: 'mortgage',
      then: Yup.number().max(1000000000, `${INVALID_AMOUNT}1,000,000,000`)
    })
    .when('type', {
      is: 'auto',
      then: Yup.number().max(25000000, `${INVALID_AMOUNT}25,000,000`)
    }),
  percent: Yup.number()
    .min(0, INVALID_PERCENT)
    .max(100, INVALID_PERCENT_MAX)
    .required(REQUIRED)
    .positive(INVALID_MIN),
  period: Yup.number()
    .min(1, INVALID_PERIOD)
    .positive(INVALID_MIN)
    .integer()
    .required(REQUIRED)
    .when('type', {
      is: 'mortgage',
      then: Yup.number().max(30, INVALID_PERIOD_30_MAX)
    })
    .when('type', {
      is: 'auto',
      then: Yup.number().max(84, INVALID_PERIOD_84_MAX)
    })
})

export const InitialAutoValues = {
  [Fields.type]: 'auto',
  [Fields.amount]: '',
  [Fields.percent]: '',
  [Fields.period]: ''
}

export const InitialMortgageValues = {
  [Fields.type]: 'mortgage',
  [Fields.amount]: '',
  [Fields.percent]: '',
  [Fields.period]: ''
}

export const InitialStudentLoanValues = {
  [Fields.type]: 'student-loans',
  [Fields.amount]: '',
  [Fields.percent]: '',
  [Fields.period]: ''
}
