import { StyleSheet, Text, View } from 'react-native'
import { DEFAULT_SPACING, gunmetal, primary, red, white } from '../../styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import moment from 'moment'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: white,
    marginLeft: DEFAULT_SPACING,
    width: 95,
    height: 111,
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    borderRadius: 10,
  },
  dateBox: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: gunmetal,
    width: '100%',
    padding: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 38
  },
  dateText: {
    fontSize: 16,
    color: white
  },
  check: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  future: {
    opacity: 0.5,
  }
})

export default function CalendarItem({ date, checked, future })  {
  const containerStyle = [
    styles.container,
    future && !checked ? styles.future : null
  ]

  const iconName = checked ? 'circle-check' : 
    future ? 'circle' : 'circle-xmark'
  const iconColor = checked ? primary : 
    future ? white : red

  return (
    <View style={containerStyle}>
      <View style={styles.dateBox}>
        <Text style={styles.dateText}>
          {moment(date, 'YYYY-MM').format('MMM YYYY')}
        </Text>
      </View>
      <View style={styles.check}>
        <FontAwesomeIcon 
          icon={iconName} 
          size={36} 
          color={iconColor}
        />
      </View>
    </View>
  )
}
