import { Platform, View, StyleSheet, useWindowDimensions } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { white } from 'styles'
import Card from 'components/Card'
import CircleGradient from './CircleGradient'

const basicStyles = {
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: white
  },
  padding: { paddingHorizontal: 20 },
  proContainer: (height, card) => ({
    flex: 1,
    backgroundColor: card ? 'transparent' : white,
    height
  }),
  card: {
    marginTop: 200,
    paddingTop: 30,
    borderRadius: 20
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    card: {
      width: 576,
      height: '80%',
      marginTop: 'auto',
      marginHorizontal: 'auto',
      marginVertical: 'auto',
      borderRadius: 12,
      overflow: 'hidden',
      maxHeight: 692
    }
  }
})

const isNotWeb = Platform.OS !== 'web'

export default function Container({
  children,
  style,
  gradientHeight = 300,
  card = false,
  withPadding = false
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const appliedStyles = [styles.container]

  if (withPadding) {
    appliedStyles.push(styles.padding)
  }
  const window = useWindowDimensions()
  const height = window.height

  appliedStyles.push(styles.contentContainer)
  if (card) appliedStyles.push(styles.card)
  if (style) appliedStyles.push(style)

  const ViewComponent = card ? Card : View

  return (
    <View style={styles.proContainer(height, card)}>
      <CircleGradient height={isNotWeb ? gradientHeight : height} />
      <ViewComponent style={appliedStyles}>{children}</ViewComponent>
    </View>
  )
}
