import { useState, useLayoutEffect, useEffect } from 'react'
import { StyleSheet, Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import IntroScreen from './components/IntroScreen'

import { careerLocationSelector } from './selectors'

import { getOccupations } from './actions'

const styles = StyleSheet.create({
  mockInput: {
    width: '80%',
    paddingVertical: 12
  },
  finn: { width: '85%' }
})

export default function CareerExploreIntroScreen({ navigation }) {
  const dispatch = useDispatch()
  const careerLocation = useSelector((state) => careerLocationSelector(state))

  useEffect(() => {
    if (careerLocation) {
      dispatch(getOccupations(careerLocation.id))
    }
  }, [careerLocation])

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  return (
    <IntroScreen
      title="Explore Careers"
      subtitle="Select a location to start exploring careers and compare salaries, growth outlook, job satisfaction and much more!"
      image={require('./assets/career-explore-intro.png')}
      buttonText="Explore Careers"
      titleFirst
      finnStyle={styles.finn}
      onPress={() => {
        navigation.navigate('Modal', { screen: 'Career Explore Location Search' })
      }}
    />
  )
}
