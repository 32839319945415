import { del } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { currentUserSelector } from '../selectors'

export const DELETE_BUDGET_ITEM = 'DELETE_BUDGET_ITEM'

export const deleteBudgetItem = ({ budgetItemId, planId }) => async (dispatch, getState) => {
  try {
    const { id: userId } = currentUserSelector(getState())
    await del(proApplicationEndpoints.deleteBudgetItem(planId, budgetItemId, userId))

    dispatch({
      type: DELETE_BUDGET_ITEM,
      payload: { budgetItemId }
    })
  } catch (e) {
    console.warn(e, e.response)
  }
}
