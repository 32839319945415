import { stateReducer } from '@truefit/redux-utils'
import { filter, find, map } from 'lodash'
import {
  SET_PRO_PLANS,
  SET_PRO_PLAN,
  DELETE_PRO_PLAN,
  CLEAR
} from '../actions'

const initialState = {
  primaryPlan: undefined,
  plans: []
}

function getPrimaryPlan(plans) {
  return find(plans, (plan) => plan.isPrimary === true)
}

export default stateReducer(initialState, {
  [SET_PRO_PLANS]: (_, payload) => {
    const plans = payload

    return {
      primaryPlan: getPrimaryPlan(plans),
      plans
    }
  },
  [SET_PRO_PLAN]: (state, { planId, plan: newPlan }) => {
    const sanitizedNewPlan = newPlan
    const newPlans = map(state.plans.slice(), (plan) => {
      if (planId === plan.planId) {
        return sanitizedNewPlan
      }

      return plan
    })

    if (!planId) {
      // New plan
      newPlans.push(sanitizedNewPlan)
    }

    // Existing plan is updated
    return {
      primaryPlan: getPrimaryPlan(newPlans),
      plans: newPlans
    }
  },
  [DELETE_PRO_PLAN]: (state, deletedPlanId) => {
    const newPlans = filter(
      state.plans,
      (plan) => plan.planId !== deletedPlanId
    )

    return {
      primaryPlan: getPrimaryPlan(newPlans),
      plans: newPlans
    }
  },
  [CLEAR]: () => initialState
});
