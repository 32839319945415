import { stateReducer } from '@truefit/redux-utils'
import { each, forEach, map } from 'lodash'
import { SET_BUDGET_ITEM, SET_BUDGET_ITEMS, DELETE_BUDGET_ITEM, CLEAR } from '../actions'
import getMonthlyProBudgetTotal from '../functions/getMonthlyProBudgetTotal'
import getProBudgetStats from '../functions/getProBudgetStats'

const initialState = {}

/**
 * INPUT:
 *  budgetItems: List of budget items
 *  budgetCategories: List of budget categories
 * OUTPUT: budgetItemsByCategory = {
 *  "INCOME": {
 *      name: "Income",
 *      items: [],
 *      count: 1,
 *      total: 5000
 *  },
 *  "HOUSING": {...}
 * }
*/
const getBudgetItemsByCategory = (budgetItems, budgetCategories) => {
  const budgetItemsByCategory = {}

  const initialize = (budgetCategory) => (budgetItemsByCategory[budgetCategory] || { items: [] })

  /* Populate the budget items by category */
  each(budgetItems, (budgetItem) => {
    const { budgetCategory } = budgetItem
    budgetItemsByCategory[budgetCategory] = initialize(budgetCategory)
    budgetItemsByCategory[budgetCategory].items.push(budgetItem)
  })


  /**
   * Iterate over the budget categories
   * because some budget items may not have been created
   * but a budget category exists for it */
  each(budgetCategories, ({ label, icon, color, subCategories, key: budgetCategory }) => {
    budgetItemsByCategory[budgetCategory] = initialize(budgetCategory)
    budgetItemsByCategory[budgetCategory].name = label
    budgetItemsByCategory[budgetCategory].color = color
    budgetItemsByCategory[budgetCategory].icon = icon

    budgetItemsByCategory[budgetCategory].subCategories = subCategories

    const budgetItemsForThisCategory = budgetItemsByCategory[budgetCategory].items
    budgetItemsByCategory[budgetCategory].count = budgetItemsForThisCategory.length
    budgetItemsByCategory[budgetCategory].total = getMonthlyProBudgetTotal(
      budgetItemsForThisCategory
    )
    budgetItemsByCategory[budgetCategory].totalExpenses = getMonthlyProBudgetTotal(
      budgetItemsForThisCategory.filter((item) => item.budgetCategory !== 'INCOME')
    )
  })

  return budgetItemsByCategory
}

function getProBudgetItemsByHash(budgetItemsByCategory) {
  const budgetItems = map(budgetItemsByCategory, (category) => category.items).flat()
  const budgetItemsByHash = {}

  each(budgetItems, (budgetItem) => {
    budgetItemsByHash[budgetItem.budgetItemId] = budgetItem
  })

  return budgetItemsByHash
}

/**
 * INPUT:
 *  budgetItems: List of budget items
 *  budgetCategories: List of budget categories
 * OUTPUT: {
 *  budgetItemsByHash: {...},
 *  budgetItemsByCategory: {
 *    "INCOME": {
 *        name: "Income",
 *        items: [],
 *        count: 1,
 *        total: 5000
 *    },
 *    "HOUSING": {...}
 *  },
 *  total: 1222, // The total amount i.e. INCOME - EXPENSES
 *  count: 20 // Number of total budget items
 * }
*/
function processBudgetItems(budgetItems, budgetCategories) {
  const budgetItemsByCategory = getBudgetItemsByCategory(budgetItems, budgetCategories)
  const { total, totalExpenses, count } = getProBudgetStats(budgetItemsByCategory)
  const budgetItemsByHash = getProBudgetItemsByHash(budgetItemsByCategory)

  return {
    budgetItemsByHash,
    budgetItemsByCategory,
    total,
    totalExpenses,
    count
  }
}

function updateBudgetItemList(state, budgetItem) {
  const { budgetItemsByCategory } = state
  const { budgetItemId, budgetCategory } = budgetItem

  const budgetCategoryData = budgetItemsByCategory[budgetCategory] || {}
  let found = false

  /* IF existing item then update the information */
  const existingBudgetItems = map(
    budgetCategoryData.items,
    (item) => {
      if (item.budgetItemId === budgetItemId) {
        found = true
        return budgetItem
      }
      return item
    }
  )

  /* IF new item, then add it to the list */
  if (!found) {
    existingBudgetItems.push(budgetItem)
  }

  /* UPDATE the information for this budget category */
  budgetCategoryData.items = existingBudgetItems
  budgetCategoryData.count = existingBudgetItems.length
  budgetCategoryData.total = getMonthlyProBudgetTotal(existingBudgetItems)
  budgetCategoryData.totalExpenses = getMonthlyProBudgetTotal(existingBudgetItems.filter((item) => item.budgetCategory !== 'INCOME'))

  /* UPDATE the overall hashmap of category wise budget items grouping */
  budgetItemsByCategory[budgetItem.budgetCategory] = budgetCategoryData

  const { total, totalExpenses, count } = getProBudgetStats(budgetItemsByCategory)
  const budgetItemsByHash = getProBudgetItemsByHash(budgetItemsByCategory)

  return {
    ...state,
    budgetItemsByHash,
    budgetItemsByCategory,
    total,
    totalExpenses,
    count
  }
}

function deleteBudgetItemFromList(state, budgetItemId) {
  const { budgetItemsByHash, budgetItemsByCategory } = state

  // Delete the budget item from the hash
  delete budgetItemsByHash[budgetItemId]

  // Delete the budget item from the category
  forEach(budgetItemsByCategory, (budgetCategoryData, key) => {
    forEach(
      budgetCategoryData.items,
      (item) => {
        if (item.budgetItemId === budgetItemId) {
          // filter from the category
          budgetCategoryData.items = budgetCategoryData.items.filter((item) => item.budgetItemId !== budgetItemId)
          budgetCategoryData.count = budgetCategoryData.items.length
          budgetCategoryData.total = getMonthlyProBudgetTotal(budgetCategoryData.items)
          budgetCategoryData.totalExpenses = getMonthlyProBudgetTotal(budgetCategoryData.items.filter((item) => item.budgetCategory !== 'INCOME'))
        }
      }
    )
  })

  // need to recalculate the total and count
  const { total, totalExpenses, count } = getProBudgetStats(budgetItemsByCategory)

  return {
    ...state,
    budgetItemsByHash,
    budgetItemsByCategory,
    total,
    totalExpenses,
    count
  }
}

/**
 * WARNING WARNING WARNING: PLEASE READ THIS
 * Make sure you are dispatching a call for budget items based on the planID.
 * This reducer will only store budget items ASSUMING they are ALL FROM THE SAME PLAN
 * There are no checks for grouping by plan because it becomes really tedious to navigate
 *  plan -> budget by category -> budget items
 * This is tedious both when
 *  - storing : Especially when a single budget item is updated
 *  - processing : we are grouping multiple levels
 * ESPECIALLY SINCE we don't have screns where budget items from multiple plans are displayed
 *
*/
export default stateReducer(initialState, {
  [SET_BUDGET_ITEMS]: (_, { budgetItems, budgetCategories }) => {
    const newstate = processBudgetItems(budgetItems, budgetCategories)
    return newstate
  },
  [SET_BUDGET_ITEM]: (state, { budgetItem }) => {
    const newstate = updateBudgetItemList(state, budgetItem)
    return newstate
  },
  [DELETE_BUDGET_ITEM]: (state, { budgetItemId }) => {
    const newState = deleteBudgetItemFromList(state, budgetItemId)
    return newState
  },
  [CLEAR]: () => initialState
});
