import { View, Text, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
})

export default function CircleInitial({ initial, size, height, width, containerStyle, style }) {
  return (
    <View style={[styles.circleContainer, containerStyle, { borderRadius: (size || height) / 2, height: size || height || 27, width: size || width || 27 }]}>
      <Text style={[styles.text, style]}>{initial}</Text>
    </View>
  )
}
