import { Animated, StyleSheet, Text } from 'react-native'
import { grayblue } from '../styles/colors'

const styles = StyleSheet.create({
  iconCircle: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 27,
    height: 27,
    borderRadius: 27 / 2,
    borderWidth: 1
  }
})

export default function CircleNumber({ number, style, size = 24, color = grayblue, backgroundColor }) {
  const containerStyles = [styles.iconCircle]

  if (backgroundColor) {
    const backgroundStyles = StyleSheet.create({
      background: {
        backgroundColor,
        borderRadius: size,
        height: size * 2,
        width: size * 2,
        borderColor: color
      }
    })

    containerStyles.push(backgroundStyles.background)
  }

  if (style) {
    containerStyles.push(style)
  }

  return (
    <Animated.View style={containerStyles}>
      <Text style={{ color, fontWeight: '600', fontSize: 20 }}>{number}</Text>
    </Animated.View>
  )
}
