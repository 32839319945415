import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import {
  Text,
  useWindowDimensions,
  View,
  ScrollView,
  StyleSheet,
  Animated,
  Platform
} from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import Svg, { Line, Circle } from 'react-native-svg'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { post } from 'http'
import moment from 'moment'
import { LinearGradient } from 'expo-linear-gradient'

import { darkblue, gunmetal, info, primary, red, white, gray, secondary } from './styles/colors'
import BottomButton from './components/BottomButton'
import hapticFeedback from './components/hapticFeedback'

import Link from './components/Link'
import TouchableOpacity from './components/TouchableOpacity'
import Modal from './components/Modal'

import SlideupDrawer from './components/SlideupDrawer'

import { formatFinancialPayload } from './api/financialBreakdown'

import formatCurrency from './functions/formatCurrency'
import computeAge from './functions/computeAge'

import { currentPlanSelector, loadingSelector } from './selectors'

import ButtonGroup from './components/ButtonGroup'

import PillGroup from './components/PillGroup'
import Divider from './components/Divider'

import OutcomeTable from './components/investmentProfile/OutcomeTable'

import InvestmentProfileCard from './components/investmentProfile/InvestmentProfileCard'
import LineItem from './components/investmentProfile/LineItem'

import { investorProfileOptions, BEST, WORST, AVERAGE, employerSponsoredOptions } from './constants'

import { saveCurrentPlan, setLoading } from './actions'

import { DEFAULT_SPACING } from './styles'
import DataDonut from './components/investmentProfile/DataDonut'
import differentAllocationsDescriptions from './components/investmentProfile/differentAllocationsDescriptions'
import InvestorProfileLayout from './components/investmentProfile/InvestorProfileLayout'

const basicStyles = {
  container: {
    backgroundColor: white,
    paddingTop: 20
  },
  splitBody: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    top: 0,
    left: 10,
    height: 600
  },
  allocationContainer: {
    left: 20,
    right: 20
  },
  highToLow: {
    left: -20,
    top: 50
  },
  bondAllocationList: {
    width: 400,
    left: 5,
    height: 500,
    top: 50
  },
  sideHeadings: {
    justifyContent: 'space-around',
    height: 500,
    width: 30
  },
  allocationOption: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '70%',
    paddingRight: 30
  },
  allocationTitle: { fontSize: 16 },
  allocationDescription: { fontSize: 12 },
  allocationLeft: { width: 250 },
  checkButton: {
    paddingLeft: 20,
    top: 10
  },
  sideGradient: {
    position: 'absolute',
    backgroundColor: gunmetal,
    width: 5,
    height: 480,
    left: 40
  },
  sideHeading: {
    fontSize: 8,
    width: 100,
    left: -20,
    margin: 0,
    transform: 'rotate(-90deg)'
  },
  splitHeader: {
    fontSize: 20,
    fontWeight: '600',
    top: 20,
    left: 10
  },
  splitSubHeader: { fontSize: 14 },
  containerModel: {
    position: 'relative',
    backgroundColor: white,
    paddingTop: 220
  },
  contentContainer: {
    paddingBottom: 420,
    paddingHorizontal: DEFAULT_SPACING
  },
  cardContainer: {
    borderRadius: 12,
    padding: 15,
    flex: 1,
    marginBottom: 10,
    shadowOpacity: 0
  },
  cardHeaderStyle: {
    fontWeight: '600',
    fontSize: 14,
    color: 'rgb(66, 86, 103)'
  },
  headerTitleStyle: {
    color: white,
    fontSize: 20
  },
  headerSubtitleStyle: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: '400',
    letterSpacing: -0.48,
    marginTop: 6,
    color: white
  },
  headerStyle: {
    backgroundColor: primary,
    shadowOpacity: 0,
    borderBottomWidth: 0,
    elevation: 0,
    height: 130
  },
  estimatedValueWrapper: {
    alignItems: 'center',
    marginTop: 15
  },
  estimatedValue: {
    color: darkblue,
    fontSize: 24,
    fontWeight: '600'
  },
  estimatedValueText: {
    textAlign: 'center',
    marginTop: 4,
    fontSize: 8,
    color: gunmetal
  },
  divider: { marginVertical: DEFAULT_SPACING },
  buttonGroup: { marginVertical: 12 },
  talkBubble: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#E7ECED',
    borderRadius: 10,
    zIndex: 0
  },
  border: { border: '8px solid #666' },
  boldText: { fontWeight: 'bold' },
  modal: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    padding: 12
  },
  checkbox: { width: 20, height: 20 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    headerLabel: {
      fontSize: 22,
      paddingHorizontal: 0,
      fontWeight: '400'
    },
    listContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: 16,
      width: '75%'
    },
    scrollviewContainer: { paddingHorizontal: 0 },
    scrollviewContentContainer: { paddingVertical: 57 },
    disclosure: { textAlign: 'left', fontSize: 16, marginTop: 24 },
    allocationTitle: { fontSize: 24 },
    estimatedValue: { fontSize: 38, color: '#283A49' },
    estimatedValueText: { fontSize: 14, color: gray },
    estimatedValueWrapper: { alignItems: 'flex-start', marginTop: 48 },
    modal: {
      width: '60%',
      height: 'auto',
      maxWidth: 600,
      minHeight: 200,
      borderRadius: 8,
      paddingHorizontal: 28,
      paddingVertical: 60
    },
    bondAllocationList: { width: 'calc(100% - 36px)' },
    allocationContainer: { left: 0 },
    allocationLeft: { width: 'calc(100% - 80px)' },
    allocationOption: {
      width: '100%',
      paddingRight: 28,
      justifyContent: 'space-between'
    },
    allocationDescription: { fontSize: 16, marginTop: 8, color: secondary },
    sideHeadings: { marginLeft: -56, marginRight: 74 },
    sideHeading: {
      width: 160,
      fontSize: 14,
      fontWeight: '500',
      color: darkblue
    },
    checkButton: { top: 40 },
    splitHeader: { fontSize: 34 },
    checkbox: { width: 34, height: 34 }
  },
  [minSize(DEVICE_SIZES.LG)]: {
    containerModel: { padding: 40 },
    contentContainer: { marginBottom: 0, paddingBottom: 0, width: '100%' }
  },
  [minSize(DEVICE_SIZES.XL)]: {
    containerModel: { padding: 80 },
    contentContainer: { marginBottom: 0, paddingBottom: 0, width: '80%' }
  }
})

const isNotWeb = Platform.OS !== 'web'

function AccordionWithTooltip({ title, children }) {
  const [open, setOpen] = useState(true)

  return (
    <View style={{ marginBottom: 10 }}>
      <Link
        icon={open ? 'caret-up' : 'caret-down'}
        type="fas"
        iconColor={info}
        iconRight
        containerStyle={{ justifyContent: 'flex-start' }}
        onPress={() => setOpen(!open)}
        style={{ fontSize: 16 }}
      >
        {`${open ? 'Hide' : 'Show'} ${title}`}
      </Link>

      {open && children}
    </View>
  )
}

function StockBondSplitSlideUp({
  visible,
  toSetVisible,
  bondAlloc,
  handleAllocationPress,
  onHide
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const windowDimensions = useWindowDimensions()

  return (
    <View>
      <SlideupDrawer
        scrollable
        snapPointIndex={3}
        scrollEnabled
        visible={visible}
        style={styles.slideUp}
        setVisible={toSetVisible}
      >
        <View style={styles.allocationContainer}>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              display: 'flex',
              justifyContent: 'space-between',
              width: '90%'
            }}
          >
            <Text style={styles.splitHeader}>Select Split</Text>
            {isNotWeb && (
              <View>
                <TouchableOpacity onPress={() => onHide()}>
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    style={{
                      fontSize: 0,
                      marginLeft: 'auto',
                      marginRight: 5,
                      color: '#AAAAAA',
                      transform: [{ scaleX: 1 }, { scaleY: 1 }]
                    }}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
          <View style={[styles.splitBody, { height: windowDimensions.height }]}>
            <View style={styles.highToLow}>
              <View style={styles.sideHeadings}>
                <Text style={styles.sideHeading}>High Risk / High Reward</Text>
                <Text style={styles.sideHeading}>Low Risk / Low Reward</Text>
              </View>
              <LinearGradient
                colors={['#7172F6', '#B2E6FC']}
                style={styles.sideGradient}
                start={[0, 0]}
                end={[0, 0.5]}
              />
            </View>
            <View style={styles.bondAllocationList}>
              {Object.keys(differentAllocationsDescriptions).map((key) => {
                if (key !== 'bondA' && key !== 'bondG') {
                  return (
                    <>
                      <TouchableOpacity
                        onPress={() => {
                          handleAllocationPress(differentAllocationsDescriptions[key].bond)
                          onHide()
                        }}
                      >
                        <View style={styles.allocationOption}>
                          <View style={styles.allocationLeft}>
                            <Text
                              style={[styles.allocationTitle, { fontSize: isNotWeb ? 16 : 22 }]}
                            >
                              {`${100 - differentAllocationsDescriptions[key].bond}% Stocks/${
                                differentAllocationsDescriptions[key].bond
                              }% Bonds`}
                            </Text>
                            <Text style={styles.allocationDescription}>
                              This{' '}
                              <Text
                                style={styles.boldText}
                              >{`${differentAllocationsDescriptions[key].boldSection}`}</Text>{' '}
                              {`${differentAllocationsDescriptions[key].description}`}
                            </Text>
                          </View>
                          <View style={styles.checkButton}>
                            {bondAlloc === parseInt(differentAllocationsDescriptions[key].bond) ? (
                              <Svg style={styles.checkbox} viewBox="0 0 20 20">
                                <Circle cx="10" cy="10" r="10" fill="#7CBA82" />
                                <Line
                                  x1="6"
                                  y1="10"
                                  x2="10"
                                  y2="13"
                                  stroke={white}
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeMiterlimit=".5"
                                />
                                <Line
                                  x1="10"
                                  y1="13"
                                  x2="15"
                                  y2="6"
                                  stroke={white}
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeMiterlimit=".5"
                                />
                              </Svg>
                            ) : (
                              <Svg style={styles.checkbox} viewBox="0 0 20 20">
                                <Circle cx="10" cy="10" r="10" fill="#E9EFF0" />
                              </Svg>
                            )}
                          </View>
                        </View>
                      </TouchableOpacity>
                      <View style={{ width: '80%' }}>
                        <Divider style={styles.divider} />
                      </View>
                    </>
                  )
                }
              })}
            </View>
          </View>
        </View>
      </SlideupDrawer>
    </View>
  )
}

export default function InvestmentProfileScreen({ navigation, route }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const currentPlan = useSelector((state) => currentPlanSelector(state))
  const { loading } = useSelector((state) => loadingSelector(state))
  const form = currentPlan

  const currentFixedWeight = form.fixed_weight
  let currentAllocationName = 'All Stocks'
  if (form.fixed_weight === 100) {
    currentAllocationName = 'All Bonds'
  } else if (form.fixed_weight > 0) {
    currentAllocationName = 'Stock/Bond Split'
  }

  const [selected, setSelected] = useState(currentAllocationName)
  const [donutData, setDonutData] = useState({
    header: 'All Stocks',
    result: { stocks: 100, bonds: 0 }
  })
  const [bondAllocation, setBondAllocation] = useState(
    currentAllocationName === 'Stock/Bond Split' ? currentFixedWeight : 50
  )
  const [visible, setVisible] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)
  const offset = useRef(new Animated.Value(0)).current
  const height = 400
  const opacity = offset.interpolate({
    inputRange: [0, height - 250],
    outputRange: [0, 1],
    extrapolate: 'clamp',
    useNativeDriver: true
  })
  const { params } = route
  const { contributionAmount, title, subtitle, builder, value, includeProgress, savingsGoal } =
    params

  const selectedInvestmentProfileOption = [
    ...investorProfileOptions,
    ...employerSponsoredOptions
  ].find((option) => option.value === value)
  const { label, sublabel, icon, color, moreInformation } = selectedInvestmentProfileOption

  const valueObject = {
    investment_selection: 'SP500',
    contribution_selection: value,
    contribution_amount: contributionAmount,
    birth_date: moment(form.birth_date).format('YYYY-MM-DD'),
    periods: form.periods,
    city_id: form.city?.id || form.city_id
  }
  const [result, setResult] = useState({})
  const [scenario, setScenario] = useState(form.expectation_selection || AVERAGE)
  if (value === 'DOLLAR_AMOUNT') {
    valueObject.contribution_amount = contributionAmount
  } else if (value === 'CUSTOM_GOAL') {
    valueObject.savings_goal = savingsGoal
    delete valueObject.contribution_amount
  } else {
    delete valueObject.contribution_amount
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      title: (
        <Animated.View style={{ opacity }}>
          <Text style={styles.headerText}>Investor Profile</Text>
        </Animated.View>
      )
    })
  }, [navigation])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,

      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      headerTintColor: white,
      title: 'Investor Profile'
    })
  }, [title, subtitle])

  async function fetchAndSetData() {
    try {
      dispatch(setLoading({ loading: true }))
      const currPlan = { ...form, ...valueObject }
      // we assign the fixed weight based on the current selected
      if (selected === 'All Stocks') {
        currPlan.fixed_weight = 0
      } else if (selected === 'Stock/Bond Split') {
        currPlan.fixed_weight = bondAllocation
      } else if (selected === 'All Bonds') {
        currPlan.fixed_weight = 100
      }
      if (currPlan.custom_career_name === null) {
        const occupationId = currPlan.occupation ? currPlan.occupation.id : ''
        delete currPlan.occupation
        delete currPlan.custom_career_name
        delete currPlan.custom_career_salary
        if (occupationId !== '') {
          currPlan.occupation_id = occupationId
        }
      }
      currPlan.expectation_selection = scenario

      const res = await post(`api/v1/plan/${form.id}/opportunity-cost/`, currPlan)
      setResult(res.data)

      dispatch(setLoading({ loading: false }))
    } catch (error) {
      console.error(error.response)
    }
  }

  useEffect(() => {
    fetchAndSetData()
  }, [currentPlan.id, currentPlan.contrib, bondAllocation, selected])

  useEffect(() => {
    let data = {}
    if (selected === 'All Stocks') {
      data = {
        header: '100% Stocks / 0% Bonds',
        result: { stocks: 100, bonds: 0 }
      }
    } else if (selected === 'Stock/Bond Split') {
      data = {
        header: `${100 - bondAllocation}% Stocks / ${bondAllocation}% Bonds`,
        result: { stocks: 100 - bondAllocation, bonds: bondAllocation }
      }
    } else if (selected === 'All Bonds') {
      data = {
        header: '0% Stocks / 100% Bonds',
        result: { stocks: 0, bonds: 100 }
      }
    }
    setDonutData(data)
  }, [selected, bondAllocation])

  const summaryStats = result.summary_stats || {}
  const investmentData = summaryStats[scenario] || {}

  const projectedMillionaireAge = investmentData.millionaire_age

  const totalContributionsPercentage = investmentData.pct_returns_contributions * 100
  const totalReturnsPercentage = 100 - totalContributionsPercentage

  const estimatedValue = investmentData.projected_ending_value

  function handleAllocationPress(fixed_weight) {
    setBondAllocation(parseInt(fixed_weight))
  }

  function handlePress() {
    setTimeout(() => {
      navigation.navigate('Modal', {
        screen: 'Goal Lockin',
        params: {
          investorProfile: value,
          title: 'Plan Builder',
          subtitle: 'Goal Lockin',
          builder,
          includeProgress,
          estimatedValue,
          scenario,
          buttonText: 'Next'
        }
      })
    }, 500)

    setTimeout(async () => {
      const currPlan = { ...form, ...valueObject, step: 3 }
      if (selected === 'All Stocks') {
        currPlan.fixed_weight = 0
      } else if (selected === 'Stock/Bond Split') {
        currPlan.fixed_weight = bondAllocation
      } else if (selected === 'All Bonds') {
        currPlan.fixed_weight = 100
      }

      if (currPlan.contribution_selection === 'DOLLAR_AMOUNT') {
        currPlan.contribution_selection = 'CUSTOM_GOAL'
        currPlan.savings_goal = estimatedValue
      }

      currPlan.extra_data = null
      currPlan.is_custom_goal = false

      dispatch(saveCurrentPlan(formatFinancialPayload(currPlan)))
    }, 300)
  }

  const options = [
    {
      label: 'Pessimistic',
      inverted: true,
      active: scenario === WORST,
      onPress: () => setScenario(WORST)
    },
    {
      label: 'Average',
      inverted: true,
      active: scenario === AVERAGE,
      onPress: () => setScenario(AVERAGE)
    },
    {
      label: 'Optimist',
      inverted: true,
      active: scenario === BEST,
      onPress: () => setScenario(BEST)
    }
  ]

  const ageData = [
    {
      label: 'Starting Age',
      value: loading ? '—' : computeAge(currentPlan.birth_date)
    },
    {
      label: 'Projected Millionaire Age',
      value: loading ? '—' : estimatedValue >= 1000000 ? projectedMillionaireAge : '—'
    },
    {
      label: 'Ending Age',
      value: loading ? '—' : computeAge(currentPlan.birth_date) + parseInt(currentPlan.periods)
    }
  ]

  const valueData = [
    {
      label: 'Value from Market Returns',
      value: `${loading ? '—' : (totalReturnsPercentage || 0).toFixed(0)}%`
    },
    {
      label: 'Value from Contributions',
      value: `${loading ? '—' : (totalContributionsPercentage || 0).toFixed(0)}%`
    }
  ]
  const annualContributionAmount = result.annual_contribution_amount || 0
  const opportunityCost = summaryStats[scenario]?.opportunity_cost || {}

  const opportunityCostTableHeaders = {
    label: '',
    fiveYears: '5 Years',
    tenYears: '10 Years',
    fifteenYears: '15 Years'
  }

  const formatters = {
    fiveYears: (value) => (value === '—' ? value : formatCurrency(value)),
    tenYears: (value) => (value === '—' ? value : formatCurrency(value)),
    fifteenYears: (value) => (value === '—' ? value : formatCurrency(value)),
    optimistic: (value) => (value === '—' ? value : formatCurrency(value)),
    average: (value) => (value === '—' ? value : formatCurrency(value)),
    pessimistic: (value) => (value === '—' ? value : formatCurrency(value))
  }

  const prefixes = {
    fiveYears: '$',
    tenYears: '$',
    fifteenYears: '$',
    optimistic: '$',
    average: '$',
    pessimistic: '$'
  }

  const opportunityCostTableData = [
    {
      label: 'Opportunity Cost',
      fiveYears: loading ? '—' : opportunityCost['5 Year Opportunity Cost'] || 0,
      tenYears: loading ? '—' : opportunityCost['10 Year Opportunity Cost'] || 0,
      fifteenYears: loading ? '—' : opportunityCost['15 Year Opportunity Cost'] || 0
    },
    {
      label: 'Contributions Not Made',
      fiveYears: loading ? '—' : annualContributionAmount * 5,
      tenYears: loading ? '—' : annualContributionAmount * 10,
      fifteenYears: loading ? '—' : annualContributionAmount * 15
    }
  ]

  const historicalOutcomesTableHeaders = {
    label: '',
    optimistic: 'Optimistic',
    average: 'Average',
    pessimistic: 'Pessimistic'
  }

  const historicalOutcomesTableData = [
    {
      label: 'Total Value',
      optimistic: loading ? '—' : summaryStats?.best?.projected_ending_value || 0,
      average: loading ? '—' : summaryStats?.average?.projected_ending_value || 0,
      pessimistic: loading ? '—' : summaryStats?.worst?.projected_ending_value || 0
    },
    {
      label: 'Contributions',
      optimistic: loading ? '—' : summaryStats?.best?.total_contributions || 0,
      average: loading ? '—' : summaryStats?.average?.total_contributions || 0,
      pessimistic: loading ? '—' : summaryStats?.worst?.total_contributions || 0
    }
  ]

  function setFixedWeight(value) {
    setSelected(value)
    if (value === 'Stock/Bond Split') {
      handleAllocationPress(bondAllocation)
    }
  }

  function distanceFromTop() {
    let distance = 290
    if (value === 'DOLLAR_A_DAY') {
      distance = 250
    }
    if (value === 'MILLIONAIRE_MAKER') {
      distance = 220
    }
    if (value === 'PCT_OF_SALARY') {
      distance = 290
    }
    if (value === 'ROTH_IRA') {
      distance = 290
    }
    if (value === 'MAX_401K') {
      distance = 290
    }

    return distance + (Platform.OS === 'android' ? 30 : 0)
  }

  const investmentProfileCard = (
    <InvestmentProfileCard
      label={label}
      sublabel={sublabel}
      icon={icon}
      color={color}
      moreInformation={moreInformation}
      annualContributionAmount={annualContributionAmount}
      loading={loading}
      animatedValue={offset}
      onPress={handlePress}
    />
  )

  const details = (
    <>
      <ScrollView
        style={[styles.containerModel, { paddingTop: isNotWeb ? distanceFromTop() : 86 }]}
        contentContainerStyle={styles.contentContainer}
        scrollEventThrottle={16}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], {
          useNativeDriver: false
        })}
      >
        <View>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Text style={styles.allocationTitle}>
              <Text style={styles.boldText}>Model Investment</Text>
            </Text>
            <TouchableOpacity>
              <Text
                style={{ color: '#4F8FEF', fontSize: isNotWeb ? 14 : 18 }}
                onPress={() => {
                  hapticFeedback()
                  setPopupVisible(true)
                }}
              >
                What's this?
              </Text>
            </TouchableOpacity>
          </View>
          <ButtonGroup
            options={['All Stocks', 'Stock/Bond Split', 'All Bonds']}
            selected={selected}
            onPress={(value) => {
              setFixedWeight(value)
            }}
            colorSelected="#5F8EE8"
            colorSelectedFont={white}
            style={styles.buttonGroup}
          />
          <View>
            <DataDonut
              data={donutData}
              loading={loading}
              selected={selected}
              onShow={() => setVisible(true)}
            />
          </View>
        </View>
        <View style={styles.estimatedValueWrapper}>
          <Text style={styles.estimatedValue}>
            ${loading ? '—' : formatCurrency(estimatedValue)}*
          </Text>
          <Text style={styles.estimatedValueText}>* Estimated Value</Text>
          <PillGroup options={options} />
        </View>

        {ageData.map((item) => (
          <LineItem key={item.label} {...item} />
        ))}

        <Divider style={styles.divider} />

        {valueData.map((item, index) => (
          <LineItem {...item} />
        ))}

        <Divider style={styles.divider} />

        <AccordionWithTooltip title="Opportunity Cost of Waiting">
          <OutcomeTable
            labels={opportunityCostTableHeaders}
            data={opportunityCostTableData}
            formatters={formatters}
            prefixes={prefixes}
            highlightedColumn={0}
            highlightedColor={red}
            scrollable={false}
          />
        </AccordionWithTooltip>

        <Divider style={styles.divider} />

        <AccordionWithTooltip title="Historical Outcomes">
          <OutcomeTable
            labels={historicalOutcomesTableHeaders}
            data={historicalOutcomesTableData}
            formatters={formatters}
            prefixes={prefixes}
            highlightedColumn={0}
            highlightedColor={primary}
            scrollable={false}
          />
        </AccordionWithTooltip>
      </ScrollView>
      {isNotWeb && (
        <>
          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: 50,
              bottom: 0,
              left: 0
            }}
          >
            <LinearGradient
              colors={['rgba(255,255,255,0)', 'rgba(255,255,255,255)']}
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: -1
              }}
              start={[0, 0]}
              end={[0, 0.5]}
            />
          </View>
          <BottomButton withMargin onPress={handlePress}>
            Choose this Investor Profile
          </BottomButton>
        </>
      )}
    </>
  )

  return (
    <>
      <InvestorProfileLayout
        navigation={navigation}
        route={route}
        investmentProfileCard={investmentProfileCard}
        details={details}
      />
      <StockBondSplitSlideUp
        stockAlloc={100 - bondAllocation}
        bondAlloc={bondAllocation}
        visible={visible}
        toSetVisible={setVisible}
        handleAllocationPress={handleAllocationPress}
        onHide={() => setVisible(false)}
      />
      <Modal
        visible={popupVisible}
        setVisible={setPopupVisible}
        style={styles.modal}
        onClose={() => setPopupVisible(false)}
        closeable
      >
        {popupVisible && (
          <View style={{ zIndex: 100, marginBottom: 30 }}>
            <Text style={[styles.splitHeader, { marginBottom: 10 }]}>What's this?</Text>
            <Text style={{ left: 10, top: 20 }}>
              Modeling different investment types means analyzing and understanding the unique
              features, risks, and potential returns of various investment options. It involves
              examining how different investments have performed in the past and predicting how they
              might perform in the future. Remember, the goal of modeling different investment types
              is to make informed decisions that align with your goals and risk tolerance.
            </Text>
          </View>
        )}
      </Modal>
    </>
  )
}
