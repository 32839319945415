import * as Yup from 'yup'

import { REQUIRED } from './constants'

export const Fields = {
  balance: 'balance',
  name: 'name'
}

export const ValidationSchema = Yup.object().shape({
  // the balance should max out at what the database allows
  [Fields.balance]: Yup.string().required(REQUIRED),
})

export const InitialValues = { [Fields.balance]: '', }
