import { useRef, useState, useLayoutEffect } from 'react'
import { View, Text, Image, Platform, StyleSheet } from 'react-native'
import ConfettiCannon from 'react-native-confetti-cannon'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

// Components
import NumberInput from './components/NumberInput'
import Form from './components/Form'
import Modal from './components/Modal'

import { black, danger, info, primary, secondary, white } from './styles/colors'
import {
  ValidationSchema,
  Fields,
  InitialValues
} from './schemas/millionaireCalculatorSchema'

import { DEFAULT_SPACING } from './styles'

import CurrencyInput from './components/CurrencyInput'
import Input from './components/Input'
import formatCurrency from './functions/formatCurrency'
import Link from './components/Link'

import { millionaireCalculator } from './actions'
import handleError from './functions/handleError'
import PageHeader from './components/PageHeader'
import ToolsHeaderWrapper from './components/tools/ToolsHeaderWrapper'

const basicStyles = {
  container: {},
  resultsContainer: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: primary,
    padding: DEFAULT_SPACING * 2
  },
  headerText: {
    textAlign: 'center',
    paddingTop: 10,
    fontSize: 14,
    color: white
  },
  moneyText: {
    textAlign: 'center',
    padding: 2,
    fontSize: 28,
    color: white,
    fontWeight: 'bold'
  },
  link: {
    textDecorationLine: 'underline',
    paddingTop: 0
  },
  modalContainer: {
    padding: 0,
    paddingVertical: DEFAULT_SPACING * 3,
    alignItems: 'center'
  },
  modal: {
    width: 360,
    height: 'auto',
    backgroundColor: 'white',
    padding: 24,
    borderRadius: 8,
    resizeMode: true
  },
  modalImage: {
    height: 200,
    width: 300
  },
  modalHeaderText: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: '700',
    marginVertical: DEFAULT_SPACING
  },
  modalSubheader: {
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING * 2
  },
  modalBodyText: { color: secondary },
  bold: {
    color: black,
    fontWeight: 'bold',
    marginLeft: DEFAULT_SPACING / 2
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  form: {},
  confetti: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '40%',
    width: '100%'
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    container: {
      height: '100%',
      padding: 40,
      overflow: 'auto'
    },
    main: {
      height: 'calc(100% - 187px)',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      padding: 40,
      backgroundColor: 'white',
      borderRadius: 12,
      marginTop: 26
    },
    form: {
      maxWidth: 360,
      marginRight: '17%'
    },
    resultsContainer: {
      width: '50%',
      height: '100%',
      justifyContent: 'center',
      borderRadius: 12
    },
    headerText: {
      fontSize: 18,
      fontWeight: '600'
    },
    moneyText: {
      fontSize: 60,
      fontWeight: '700'
    },
    modal: { width: 'fit-content' }
  }
})

const isNotWeb = Platform.OS !== 'web'

function ModalRow({ children }) {
  return <View style={{ flexDirection: 'row' }}>{children}</View>
}

function MillionaireHeader({
  total,
  millionaire,
  millionaireState,
  shortFall,
  setVisible
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const explosion = useRef()
  let headerText = "Let's see how much you need to save to have"
  if (millionaire) {
    headerText =
      'Congratulations! Based on your input, \nyou are estimated to have'
  } else if (shortFall) {
    headerText = 'Based on your inputs, \nyou are projected to fall short by'
  }

  let money = 1000000

  if (millionaire) {
    money = total
  } else if (shortFall) {
    money = shortFall
  }

  const secondaryText = 'within your chosen timeframe.'

  let backgroundColor = info
  let background = ''

  if (millionaire) {
    backgroundColor = primary
    background =
      'linear-gradient(135deg, #67BC7C 0%, #36CD73 100%, #E10B35 100%)'
    if (!isNotWeb) explosion?.current?.start()
  } else if (shortFall) {
    backgroundColor = danger
    background = 'linear-gradient(129.67deg, #DE3653 1.46%, #E10B35 124.71%)'
  }

  return (
    <View
      style={[
        styles.resultsContainer,
        isNotWeb ? { backgroundColor } : { background }
      ]}
    >
      {!isNotWeb && millionaire && (
        <ConfettiCannon
          count={200}
          explosionSpeed={0}
          origin={{ x: 0, y: 0 }}
          autoStart
          ref={explosion}
          fadeOut
        />
      )}
      <Text style={styles.headerText}>{headerText}</Text>
      <Text style={styles.moneyText}>{`$${formatCurrency(money)}`}</Text>
      <Text style={styles.headerText}>{secondaryText}</Text>
      {!millionaireState && (
        <Link
          icon="circle-info"
          onPress={() => setVisible(true)}
          style={[styles.headerText, styles.link]}
        >
          Finn's Tips and Tricks
        </Link>
      )}
    </View>
  )
}

export default function MillionaireCalculatorScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const rateOfReturnField = useRef()
  const contributionAmountField = useRef()
  const currentAgeField = useRef()
  const retirementAgeField = useRef()

  const [calculation, setCalculation] = useState({})
  const [visible, setVisible] = useState(false)

  const total = calculation?.result?.total
  const shortFall = calculation?.result?.short_fall
  const tips = calculation?.result?.tips || {}
  const millionaire = total && total >= 1000000
  const millionaireState = !calculation?.result || millionaire
  const explosion = useRef()

  const title = 'Millionaire Calculator'

  if (millionaire && isNotWeb) explosion?.current?.start()

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: isNotWeb })
  }, [navigation])

  return (
    <View style={styles.container}>
      {!isNotWeb && (
        <ToolsHeaderWrapper>
          <PageHeader
            title={title}
            subTitle="What might it take to save one million dollars? This financial calculator helps you find out. Enter in your current savings plan and graphically view your financial results for each year until you retire."
          />
        </ToolsHeaderWrapper>
      )}
      <View style={styles.main}>
        <MillionaireHeader
          total={total}
          millionaire={millionaire}
          millionaireState={millionaireState}
          shortFall={shortFall}
          setVisible={setVisible}
        />
        <Form
          withPadding={false}
          buttonText="Calculate"
          enableReinitialize
          validationSchema={ValidationSchema}
          initialValues={InitialValues}
          containerStyle={styles.form}
          onSubmit={async (values) => {
            try {
              const response = await millionaireCalculator(values)
              setCalculation(response)
            } catch (e) {
              setCalculation({})
              handleError(e, 'Unable to calculate millionaire status')
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values,
            errors,
            touched
          }) => (
            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <View style={{ padding: DEFAULT_SPACING }}>
                  <CurrencyInput
                    type="money"
                    unit="$"
                    name={Fields.initialInvestment}
                    errorMessage={errors[Fields.initialInvestment]}
                    touched={touched[Fields.initialInvestment]}
                    onChangeText={handleChange(Fields.initialInvestment)}
                    onBlur={handleBlur(Fields.initialInvestment)}
                    value={values[Fields.initialInvestment]}
                    label="Starting Balance"
                    secureTextEntry={false}
                    returnKeyType="next"
                    placeholder="$10,000"
                    keyboardType="number-pad"
                    autoComplete="off"
                    caretHidden
                    enablesReturnKeyAutomatically
                    onSubmitEditing={() => rateOfReturnField.current.focus()}
                  />

                  <NumberInput
                    type="money"
                    precision={2}
                    suffixUnit="%"
                    ref={rateOfReturnField}
                    name={Fields.rateOfReturn}
                    errorMessage={errors[Fields.rateOfReturn]}
                    touched={touched[Fields.rateOfReturn]}
                    onChangeText={handleChange(Fields.rateOfReturn)}
                    onBlur={handleBlur(Fields.rateOfReturn)}
                    value={values[Fields.rateOfReturn]}
                    label="Rate of Return"
                    secureTextEntry={false}
                    returnKeyType="next"
                    placeholder="7%"
                    keyboardType="number-pad"
                    autoComplete="off"
                    caretHidden
                    enablesReturnKeyAutomatically
                    onSubmitEditing={() => rateOfReturnField.current.focus()}
                  />

                  <CurrencyInput
                    type="money"
                    unit="$"
                    ref={contributionAmountField}
                    name={Fields.contributionAmount}
                    errorMessage={errors[Fields.contributionAmount]}
                    touched={touched[Fields.contributionAmount]}
                    onChangeText={handleChange(Fields.contributionAmount)}
                    onBlur={handleBlur(Fields.contributionAmount)}
                    value={values[Fields.contributionAmount]}
                    label="Contribution Amount (Yearly)"
                    secureTextEntry={false}
                    returnKeyType="next"
                    placeholder="$10,000"
                    keyboardType="number-pad"
                    autoComplete="off"
                    caretHidden
                    enablesReturnKeyAutomatically
                    onSubmitEditing={() => currentAgeField.current.focus()}
                  />

                  <Input
                    ref={currentAgeField}
                    name={Fields.currentAge}
                    errorMessage={errors[Fields.currentAge]}
                    touched={touched[Fields.currentAge]}
                    onChangeText={handleChange(Fields.currentAge)}
                    onBlur={handleBlur(Fields.currentAge)}
                    value={values[Fields.currentAge]}
                    label="Current Age"
                    returnKeyType="next"
                    placeholder="18"
                    keyboardType="number-pad"
                    onSubmitEditing={() => retirementAgeField.current.focus()}
                  />

                  <Input
                    ref={retirementAgeField}
                    name={Fields.retirementAge}
                    errorMessage={errors[Fields.retirementAge]}
                    touched={touched[Fields.retirementAge]}
                    onChangeText={handleChange(Fields.retirementAge)}
                    onBlur={handleBlur(Fields.retirementAge)}
                    value={values[Fields.retirementAge]}
                    label="Retirement Age"
                    placeholder="65"
                    keyboardType="number-pad"
                    onSubmitEditing={handleSubmit}
                  />
                </View>
              </View>
            </View>
          )}
        </Form>
        {isNotWeb && millionaire && (
          <ConfettiCannon
            count={200}
            explosionSpeed={0}
            origin={{ x: 0, y: 0 }}
            autoStart
            ref={explosion}
            fadeOut
          />
        )}
      </View>
      <Modal
        containerStyle={styles.modalContainer}
        style={styles.modal}
        closeable
        visible={visible}
        setVisible={setVisible}
        onClose={() => setVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalBody}>
            <Image
              source={require('./assets/finn/smart-bulb.png')}
              style={styles.modalImage}
              resizeMode="contain"
            />
            <Text style={styles.modalHeaderText}>
              Want to be a millionaire?
            </Text>
            <Text style={[styles.modalBodyText, styles.modalSubheader]}>
              Try some of these suggestions:
            </Text>

            <ModalRow>
              <Text style={styles.modalBodyText}>
                Change your yearly savings to:
              </Text>
              <Text style={[styles.modalBodyText, styles.bold]}>
                {`$${formatCurrency(tips.contribution_amount)}`}
              </Text>
            </ModalRow>

            <ModalRow>
              <Text style={styles.modalBodyText}>
                Change your starting balance to:
              </Text>
              <Text style={[styles.modalBodyText, styles.bold]}>
                {`$${formatCurrency(tips.initial_investment)}`}
              </Text>
            </ModalRow>

            <ModalRow>
              <Text style={styles.modalBodyText}>Change your periods to:</Text>
              <Text style={[styles.modalBodyText, styles.bold]}>
                {tips.periods}
              </Text>
            </ModalRow>
          </View>
        </View>
      </Modal>
    </View>
  )
}
