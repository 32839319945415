import * as Yup from 'yup'

import { REQUIRED, PASSWORD_REGEX } from './constants'

export const Fields = {
  oldPassword: 'old_password',
  newPassword1: 'new_password1',
  newPassword2: 'new_password2'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.oldPassword]: Yup.string().required(REQUIRED),
  [Fields.newPassword1]: Yup.string()
    .required(REQUIRED)
    .matches(PASSWORD_REGEX, 'must be 9 characters and have an uppercase, lowercase, number, and 1 special character.'),
  [Fields.newPassword2]: Yup.string()
    .required(REQUIRED)
    .matches(PASSWORD_REGEX, 'must be 9 characters and have an uppercase, lowercase, number, and 1 special character.')
    .oneOf([Yup.ref(Fields.newPassword1), null], "Passwords don't match")
})

export const InitialValues = {
  [Fields.oldPassword]: '',
  [Fields.newPassword1]: '',
  [Fields.newPassword2]: ''
}
