export default function occupationName(props) {
  const { occupation, input_custom_career, custom_career_name } = props

  if (input_custom_career) {
    return custom_career_name
  }
  if (occupation && occupation.occ_title) {
    return occupation.occ_title
  }
  if (occupation && occupation.name) {
    return occupation.name
  }
  return 'N/A'
}
