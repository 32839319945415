import { useRef, useLayoutEffect } from 'react'
import { Platform, Text, View, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import { useNavigationState } from '@react-navigation/native'
import * as WebBrowser from 'expo-web-browser'
import { useDispatch } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { first } from 'lodash'
import Container from 'components/Container'
import Form from 'components/Form'
import Input from 'components/Input'
import CloseButton from 'components/CloseButton'
import InformationBlock from 'components/InformationBlock'
import CheckBox from 'components/CheckBox'
import Divider from 'components/Divider'
import DateTimePicker from 'components/DateTimePicker'
import HeaderLabel from 'components/HeaderLabel'
import Link from 'components/Link'

import { DEFAULT_CONFIG } from './configure/config/config.default'

import { ValidationSchema, Fields, InitialValues } from './schemas/registrationSchema'

import { register, clear } from './actions'

import { DEFAULT_SPACING, white, gunmetal } from 'styles'
import BackButton from './components/teamOnboarding/BackButton'

const basicStyles = {
  headerLabel: {
    marginBottom: 12,
    textAlign: 'center',
    width: '100%'
  },
  subtext: {
    fontSize: 16,
    marginBottom: 24,
    color: gunmetal,
    textAlign: 'center',
    width: '80%',
    alignSelf: 'center'
  },
  container: { flex: 1, },
  button: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      headerLabel: {
        fontWeight: '600',
        fontSize: 38,
        lineHeight: 46,
      },
      subtext: {
        fontSize: 18,
        lineHeight: 22,
        fontWeight: '500',
      },
      container: {
        paddingHorizontal: 115,
        overflow: 'auto',
        marginBottom: 120,
      },
      button: {
        maxWidth: 345,
        marginHorizontal: 'auto',
        marginBottom: DEFAULT_SPACING * 2,
      },
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function RegistrationScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const emailField = useRef(null)
  const password1Field = useRef(null)
  const password2Field = useRef(null)
  const lastNameField = useRef(null)
  const routes = useNavigationState((state) => state.routes)
  const firstRoute = first(routes)

  useLayoutEffect(() => {
    navigation.setOptions({
      title: null,
      headerTransparent: true,
      headerLeft: () =>
        firstRoute.name == 'Get Started' ? (
          <BackButton onPress={() => navigation.pop()} />
        ) : (
          <CloseButton color={white} onPress={() => navigation.pop()} />
        )
    })
  }, [navigation])

  return (
    <Container card={true}>
      <Form
        buttonText="Sign Up"
        buttonContainerStyle={styles.button}
        validationSchema={ValidationSchema}
        initialValues={InitialValues}
        onSubmit={async (values, actions) => {
          try {
            const device_id = await AsyncStorage.getItem('deviceID')

            const { first_name, last_name, birth_date, password1, password2, email, newsletter } =
              values

            await dispatch(
              register({
                first_name,
                last_name,
                birth_date,
                email,
                password1,
                password2,
                newsletter,
                device_id
              })
            )

            if (device_id) {
              AsyncStorage.removeItem('deviceID')
              AsyncStorage.removeItem('password')
            }

            navigation.navigate('Modal', { screen: 'Join Team', params: { isOnboarding: true } })
          } catch (e) {
            if (e.response && e.response.data && e.response.data.password1) {
              actions.setFieldError(Fields.password1, e.response.data.password1.join(', '))
            } else if (e.response && e.response.data && e.response.data.email) {
              actions.setFieldError(Fields.email, e.response.data.email.join(', '))
            } else if (e.response && e.response.data && e.response.data.detail) {
              actions.setFieldError(Fields.email, e.response.data.detail)
            } else {
              actions.setFieldError(Fields.email, 'Unable to create a user with that email')
            }

            dispatch(clear())
          }
        }}
      >
        {({ setFieldValue, handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
          <View style={styles.container}>
            <HeaderLabel style={styles.headerLabel}>Sign Up</HeaderLabel>
            <Text style={styles.subtext}>
              Live your best life today while planning for tomorrow!
            </Text>

            <Input
              name={Fields.firstName}
              errorMessage={errors[Fields.firstName]}
              touched={touched[Fields.firstName]}
              onChangeText={handleChange(Fields.firstName)}
              onBlur={handleBlur(Fields.firstName)}
              value={values.firstName}
              label="First Name"
              secureTextEntry={false}
              returnKeyType="next"
              onSubmitEditing={() => lastNameField.current.focus()}
            />

            <Input
              ref={lastNameField}
              name={Fields.lastName}
              errorMessage={errors[Fields.lastName]}
              touched={touched[Fields.lastName]}
              onChangeText={handleChange(Fields.lastName)}
              onBlur={handleBlur(Fields.lastName)}
              value={values.lastName}
              label="Last Name"
              secureTextEntry={false}
              returnKeyType="next"
              onSubmitEditing={() => emailField.current.focus()}
            />

            <Input
              ref={emailField}
              name={Fields.email}
              errorMessage={errors[Fields.email]}
              touched={touched[Fields.email]}
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              label="Email Address"
              secureTextEntry={false}
              returnKeyType="next"
              onSubmitEditing={() => password1Field.current.focus()}
            />

            <DateTimePicker
              name={Fields.birthDate}
              label="Date Of Birth"
              errorMessage={errors[Fields.birthDate]}
              touched={touched[Fields.birthDate]}
              headerText="Select your Date of Birth"
              onChange={(date) => {
                setFieldValue(Fields.birthDate, date)
              }}
              value={values[Fields.birthDate]}
            />

            <InformationBlock
              text={
                "Don't worry, your security is our top priority. We use your birthdate to build a more accurate plan with detailed financial forecasting and modeling."
              }
            />

            <Input
              ref={password1Field}
              name={Fields.password1}
              errorMessage={errors[Fields.password1]}
              touched={touched[Fields.password1]}
              onChangeText={handleChange('password1')}
              onBlur={handleBlur('password1')}
              value={values.password1}
              label="Password"
              secureTextEntry
              onSubmitEditing={() => password2Field.current.focus()}
            />

            <Input
              ref={password2Field}
              name={Fields.password2}
              errorMessage={errors[Fields.password2]}
              touched={touched[Fields.password2]}
              onChangeText={handleChange('password2')}
              onBlur={handleBlur('password2')}
              value={values.password2}
              label="Password Confirmation"
              secureTextEntry
              onSubmitEditing={handleSubmit}
            />

            <CheckBox
              name={Fields.newsletter}
              label="Subscribe to our newsletter to receive updates on new features, podcast episodes, and more!"
              errorMessage={errors[Fields.newsletter]}
              touched={touched[Fields.newsletter]}
              onToggle={(value) => {
                setFieldValue(Fields.newsletter, value)
              }}
              value={values[Fields.newsletter]}
              checked={values[Fields.newsletter]}
            />

            <Divider style={{ margin: DEFAULT_SPACING }} />

            <CheckBox
              name={Fields.tos}
              label={
                <View style={{ justifyContent: 'center' }}>
                  <Text style={{ fontSize: 12, padding: 0, margin: 0 }}>
                    By checking this box, you accept the
                  </Text>

                  <Link
                    containerStyle={{
                      minHeight: 0,
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                    onPress={() => {
                      WebBrowser.openBrowserAsync(`${DEFAULT_CONFIG.baseUrl}tos/`)
                    }}
                  >
                    terms of service and privacy policy
                  </Link>
                </View>
              }
              errorMessage={errors[Fields.tos]}
              touched={touched[Fields.tos]}
              onToggle={(value) => {
                setFieldValue(Fields.tos, value)
              }}
              value={values[Fields.tos]}
              checked={values[Fields.tos]}
            />
          </View>
        )}
      </Form>
    </Container>
  )
}
