import { View, } from 'react-native'
import Wrapper from './Wrapper'
import FastBuilderProgressBar from './FastBuilderProgressBar'
import { DEFAULT_SPACING, white } from '../../styles'

function FastBuilderContainer({ currentPage, children }) {
  return (
    <Wrapper>
      <FastBuilderProgressBar currentPage={currentPage} />
      <View style={{
        flex: 1,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        backgroundColor: white,
        paddingHorizontal: DEFAULT_SPACING * 2,
        paddingTop: 24,
      }}
      >
        {children}
      </View>
    </Wrapper>
  )
}

export default FastBuilderContainer
