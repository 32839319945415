import { View, StyleSheet, Text, ScrollView } from 'react-native'

import PropTypes from 'prop-types'

import BottomButton from './BottomButton'
import { darkblue, gunmetal, DEFAULT_SPACING } from 'styles'
import IntroProHeader from 'components/IntroProHeader'
import Container from 'components/Container'

const styles = StyleSheet.create({
  container: {
    marginTop: 350
  },
  contentContainer: {
    flex: 1
  },
  proHeader: {
    alignItems: 'center',
    position: 'absolute',
    top: 100,
    zIndex: 100,
    width: '100%'
  },
  finn: {
    width: '65%',
    marginVertical: 60,
    height: 200
  },
  title: {
    color: darkblue,
    fontSize: 20,
    fontWeight: '600',
    marginTop: 0,
    marginBottom: DEFAULT_SPACING
  },
  text: {
    color: gunmetal,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
    width: '80%'
  }
})

export default function IntroScreenPro({
  onPress,
  showHeader,
  title,
  subtitle,
  children,
  disabled,
  isSubmitting,
  buttonText
}) {
  return (
    <>
      {showHeader && <IntroProHeader style={styles.proHeader} />}
      <Container style={styles.container} gradientHeight={400} card={true}>
        <View style={styles.contentContainer}>
          <View style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
            <ScrollView contentContainerStyle={{ alignItems: 'center' }}>
              {title && <Text style={styles.title}>{title}</Text>}
              {subtitle && <Text style={styles.text}>{subtitle}</Text>}
              {children}
            </ScrollView>
          </View>

          {onPress && (
            <BottomButton
              withMargin
              disabled={disabled}
              isSubmitting={isSubmitting}
              onPress={onPress}
            >
              {buttonText}
            </BottomButton>
          )}
        </View>
      </Container>
    </>
  )
}

IntroScreenPro.defaultProps = { showHeader: true }

IntroScreenPro.propTypes = {
  onPress: PropTypes.func.isRequired,
  showHeader: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  buttonText: PropTypes.string,
  image: PropTypes.object,
  finnStyle: PropTypes.object,
  backgroundImage: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string
}
