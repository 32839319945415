export * from './bsAccountConstants'

export * from './periodicityConstants'

export * from './navigationPropType'

export * from './iconPropType'

export * from './other'

export const DARK_BACKGROUND_ROUTES = [
  'Investment Profile',
  'Wellness Checklist'
]
