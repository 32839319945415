import { Image, Platform, Text } from 'react-native'

export default function TextOrImage({ title, style }) {
  const isNotWeb = Platform.OS !== 'web'
  if (typeof title === 'string' && !title.includes('/static')) {
    return (
      <Text numberOfLines={isNotWeb ? 1 : 2} style={style}>
        {title}
      </Text>
    )
  }
  return (
    <Image
      style={[style, { width: 150, height: 25 }]}
      source={title}
      resizeMode="contain"
    />
  )
}
