import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import HeaderLabel from '../HeaderLabel'
import { DEFAULT_SPACING, darkblue, gunmetal } from '../../styles'
import TooltippedText from '../TooltippedText'

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: DEFAULT_SPACING
  },
  smallTitle: {
    fontSize: 16,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '600',
    marginBottom: DEFAULT_SPACING
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'center',
    letterSpacing: -0.5,
    marginBottom: DEFAULT_SPACING * 2
  },
  tooltipHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  container: {
    width: '90%',
    alignSelf: 'center',
  }
})

function HeaderLabelWithTooltip({ title, titleTooltip, tooltipIsVisible, setTooltipIsVisible, small }) {
  return (
    <View style={styles.tooltipHeaderContainer}>
      <TooltippedText
        visible={tooltipIsVisible}
        setVisible={setTooltipIsVisible}
        anchorStyle={small ? styles.smallTitle : styles.title}
        anchorText={title}
        contentHeader={title}
        contentBody={titleTooltip}
      />
    </View>
  )
}

export default function HeaderTitleAndSubtitle({ title, subtitle, titleTooltip, small }) {
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false)

  return (
    <View style={styles.container}>
      { title && titleTooltip
        && (
          <HeaderLabelWithTooltip
            title={title}
            titleTooltip={titleTooltip}
            tooltipIsVisible={tooltipIsVisible}
            setTooltipIsVisible={setTooltipIsVisible}
            small={small}
          />
        )}
      { title && !titleTooltip && <HeaderLabel style={small ? styles.smallTitle : styles.title}>{title}</HeaderLabel> }
      { subtitle && <HeaderLabel style={styles.subtitle}>{subtitle}</HeaderLabel> }
    </View>
  )
}
