import {
  Platform,
  View,
  Text,
  StyleSheet,
  TouchableOpacity
} from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import {
  calPolyPomonaGreen,
  lightSilverGreen,
  kombuGreen,
  iguanaGreen,
  white,
  spanishGray,
  romanSilver,
  DEFAULT_SPACING,
  rgba
} from 'styles'
import PulseCircle from '../PulseCircle'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { useSelector } from 'react-redux'
import { finPulseSelector } from 'selectors'
import { isEmpty } from 'lodash'
import {
  calculateStreak,
  convertMonthlyContributionsToHash
} from 'functions/finPulse'
import PillLabel from '../PillLabel'

const basicStyles = {
  container: {
    height: 84,
    paddingHorizontal: DEFAULT_SPACING,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: DEFAULT_SPACING * 2,
    marginTop: DEFAULT_SPACING * 2
  },
  textContainer: {
    marginRight: DEFAULT_SPACING * 2,
    flex: 1
  },
  header: {
    fontWeight: '600',
    fontSize: 16,
    marginBottom: 2
  },
  lead: {
    color: white,
    fontSize: 16,
    letterSpacing: -0.5,
    fontWeight: 'bold'
  },
  leadContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    fontSize: 12,
    letterSpacing: -0.5,
  },
  onTrackText: {
    fontSize: 12,
    fontWeight: '500',
    textTransform: 'none',
    letterSpacing: 0.5,
    color: iguanaGreen
  },
  onTrackPill: {
    backgroundColor: kombuGreen,
    borderRadius: 12,
    marginLeft: 8
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        width: '100%',
        maxWidth: 1074,
        margin: 0,
        marginTop: DEFAULT_SPACING,
        marginBottom: DEFAULT_SPACING,
      },
      textContainer: {
        marginLeft: DEFAULT_SPACING * 2,
        marginRight: 'auto',
        alignItems: 'center',
        flexDirection: 'row',
      },
      header: { marginRight: DEFAULT_SPACING },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function MyFinPulseCard({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const finPulse = useSelector(finPulseSelector)
  const finPulseStreaks = useSelector(state => state.finPulseStreaks)

  const { enabled } = finPulse
  const monthlyContributions = isEmpty(finPulseStreaks) ? [] : finPulseStreaks
  const calculatedMonthlyContributions = convertMonthlyContributionsToHash(monthlyContributions)
  const count = calculateStreak(calculatedMonthlyContributions)

  function onPress() {
    navigation.navigate('Modal', { screen: 'FinPulse' })
  }

  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, {
      backgroundColor: !enabled ? romanSilver : calPolyPomonaGreen,
    }]}>
      <PulseCircle
        size={65}
        scaleEnd={1.2}
        color={enabled ? kombuGreen : spanishGray}
        iconSize={26}
        iconColor={enabled ? iguanaGreen : white}
        icon={'wave-pulse'}
        disabled={!enabled}
      />
      <View style={styles.textContainer}>
        <Text style={[styles.header, {
          color: !enabled ? white : lightSilverGreen,
        }]}>{enabled ? 'My FinPulse' : 'FinPulse'}</Text>
        {enabled ? (
          <View style={styles.leadContainer}>
            <Text style={styles.lead}>{count > 0 ? '🔥 ' : null}{count} Month Streak</Text>
            {count > 0 ? <PillLabel style={styles.onTrackPill} textStyle={styles.onTrackText} width={85} title='On Track' /> : null}
          </View>
        ) : (
          <Text style={[styles.text, {
            color: disabled ? white : lightSilverGreen,
          }]}>
            Are you already saving and investing? {`\n`}Activate your FinPulse!
          </Text>
        )}
      </View>

      <FontAwesomeIcon
        icon='circle-chevron-right'
        color={rgba(lightSilverGreen, 0.5)}
        size={16}
        style={{ marginRight: DEFAULT_SPACING }}
      />
    </TouchableOpacity>
  )
}
