import { useState } from 'react'
import { View, StyleSheet, Image, Text } from 'react-native'

import { darkgrayblue, primary, white } from '../styles/colors'
import TooltippedText from './TooltippedText'

import formatPercent from '../functions/formatPercent'
import { DEFAULT_SPACING } from '../styles'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100
  },
  shieldContainer: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 40 / 2,
    marginBottom: 4,
    backgroundColor: white
  },
  activeText: {
    fontSize: 10,
    color: darkgrayblue,
    marginVertical: DEFAULT_SPACING / 2
  },
  percent: (index) => ({
    fontSize: 12,
    fontWeight: 'bold',
    color: index === 2 ? primary : darkgrayblue
  })
})

export default function Shield({ 
  label, 
  tooltipHeader, 
  tooltip, 
  onButtonPress, 
  buttonText,
  shieldIndex,
  shields,
  value
}) {
  const [visible, setVisible] = useState(false)
  const shieldImageSource = shields[shieldIndex]

  return (
    <View style={styles.container}>
      <View style={styles.shieldContainer}>
        <Image 
          source={shieldImageSource} 
          style={{height: 50, width: 50}}
          resizeMode={'cover'}
        />
      </View>

      <TooltippedText
        visible={visible}
        setVisible={setVisible}
        anchorStyle={styles.activeText}
        anchorText={label}
        contentHeader={tooltipHeader}
        contentBody={tooltip}
        onButtonPress={onButtonPress}
        buttonText={buttonText}
      />

      <Text style={styles.percent(shieldIndex)}>{formatPercent(value, 0)}</Text>
    </View>
  )
}
