import { useState, useEffect, useLayoutEffect } from 'react'
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import IntroScreen from './components/IntroScreen'
import Pill from './components/Pill'
import TooltippedText from './components/TooltippedText'
import ListOptionItem from './components/ListOptionItem'
import DebtRankSlideupDrawer from './components/debtRank/DebtRankSlideupDrawer'

import formatCurrency from './functions/formatCurrency'
import formatPercent from './functions/formatPercent'

import DebtRank from 'components/debtRank/DebtRank'
import ProDebtRank from 'components/proDebtRank/DebtRank'

import {
  bsAccountsSelector,
  loansSelector,
  primaryProPlanSelector
} from './selectors'

import { DEFAULT_SPACING, info, primary, white, rgba, darkblue, red, lightestgray, secondary } from './styles'

import { isEmpty } from 'lodash'

const basicStyles = {
  container: { flex: 1 },
  headerText: {
    textAlign: 'center',
    padding: DEFAULT_SPACING
  },
  subtitle: { fontSize: 12 },
  link: { marginBottom: DEFAULT_SPACING },
  linkLeft: { justifyContent: 'flex-start' },
  iconStyle: { color: info },
  tooltipLink: {
    color: info,
    marginTop: DEFAULT_SPACING,
    fontSize: 12
  },
  finn: { height: 200, },
  scrollView: {
    paddingVertical: DEFAULT_SPACING,
    paddingBottom: DEFAULT_SPACING * 5
  },
  debtContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING * 2,
  },
  addButton: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        padding: 40,
        overflow: 'auto',
        backgroundColor: lightestgray,
        paddingRight: '50%',
      },
      scrollView: {
        paddingBottom: DEFAULT_SPACING,
        height: 'calc(100% - 60px)',
        overflow: 'auto',
      },
      addButton: {
        width: 240,
        left: 20,
      }
    }
  }
)

const typeOptions = [
  { label: 'Federal Student Loan', value: 'FEDERAL' },
  { label: 'Private Student Loan', value: 'PRIVATE' },
  { label: 'Mortgage', value: 'MORTGAGE' },
  { label: 'Auto', value: 'AUTO' },
  { label: 'Credit Card', value: 'CREDIT_CARD' },
  { label: 'Personal Loan', value: 'PERSONAL' },
]

const typeIcons = {
  FEDERAL: 'graduation-cap',
  PRIVATE: 'graduation-cap',
  MORTGAGE: 'house',
  AUTO: 'car',
  CREDIT_CARD: 'credit-card',
  PERSONAL: 'money-bill'
}

function DebtItem({ debt, index, last, onPress }) {
  const [visible, setVisible] = useState(false)
  const { balance, category, interest_rate } = debt

  const name = typeOptions.find((option) => option.value === category).label
  const icon = typeIcons[category]
  const color = icon == 'credit-card' ? red : info

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ backgroundColor: rgba(primary, 0.3), borderRadius: 35, height: 35, width: 35, justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ backgroundColor: primary, borderRadius: 25, height: 25, width: 25, justifyContent: 'center', alignItems: 'center' }}>
            {index == 0
              && <FontAwesomeIcon size={14} color={white} icon="crosshairs" />}
            {index != 0 && <Text style={{ fontSize: 16, color: white }}>{index + 1}</Text>}
          </View>
        </View>
        {!last && <FontAwesomeIcon style={{ position: 'absolute', bottom: -10 }} size={18} color="#D0D9DE" icon="arrow-down" />}
      </View>

      <View style={{ flex: 1, marginLeft: DEFAULT_SPACING }}>
        <ListOptionItem
          label={name}
          iconName={icon}
          iconColor={color}
          iconType="far"
          checked
          onPress={onPress}
          onEdit={onPress}
        >
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 36 }}>
            <Pill small color={darkblue}>
              {formatPercent(parseFloat(interest_rate))}
            </Pill>

            <Text style={{ fontSize: 12, color: secondary, marginLeft: DEFAULT_SPACING }}>
              $
              {formatCurrency(balance)}
            </Text>
          </View>
        </ListOptionItem>
      </View>

      <DebtRankSlideupDrawer
        visible={visible}
        setVisible={setVisible}
        debt={debt}
      />
    </View>
  )
}

export default function MyDebtScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [tooltipVisible, setTooltopVisible] = useState(false)
  const primaryProPlan = useSelector(primaryProPlanSelector)

  let loans = useSelector((state) => loansSelector(state))
  loans = loans.sort((a, b) => a.interest_rate - b.interest_rate).reverse()

  const { liabilityAccounts} = useSelector(bsAccountsSelector)
  const liabilitiesWithAdvanced = liabilityAccounts.filter((account) => account.advanced)

  const shouldShowIntro = (primaryProPlan && liabilitiesWithAdvanced.length === 0) || (!primaryProPlan && loans.length === 0)

  useLayoutEffect(() => {
    navigation.setOptions({ 
      headerShown: !shouldShowIntro ? isNotWeb : false,
    })
  }, [navigation, shouldShowIntro])


  if (shouldShowIntro) {
    return (
      <IntroScreen
        title="Rank Your Debt"
        subtitle={'Do you like Debt that lasts forever?\nPaying the most interest possible?\nNeither do we.\n\n That\'s why we made the Debt Ranking Tool.\n\nStep 1 - Enter some information about your debt\nStep 2 - Learn the best way to pay it off'}
        subtitleStyle={styles.subtitle}
        image={require('./assets/debt-ranking-intro.png')}
        titleFirst={false}
        finnStyle={styles.finn}
        closeable={true}
        buttonText="Add New Loan"
        onPress={() => {
          navigation.navigate('Modal', { screen: 'My Debts Form' })
        }}
      >
        <TooltippedText
          visible={tooltipVisible}
          setVisible={setTooltopVisible}
          anchorStyle={styles.tooltipLink}
          anchorText="Why should I rank my debt?"
          contentHeader="Why should I rank my debt?"
          contentBody="Debts with a higher interest rate generally cost you more money over time than debts with lower interest rates. Ranking your debt will help you create the most cost-effective repayment strategy and keep more money in your pocket."
        />
      </IntroScreen>
    )
  }

  return isEmpty(primaryProPlan) ? 
    <DebtRank navigation={navigation} route={route} /> : 
      <ProDebtRank navigation={navigation} route={route} />
  
}
