import { useState, useLayoutEffect } from 'react'
import { StyleSheet } from 'react-native'
import IntroScreen from './components/IntroScreen'

const styles = StyleSheet.create({
  mockInput: {
    width: '80%',
    marginVertical: 24
  },
  finn: { height: 250 }
})

export default function LoanCalculatorIntroScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  return (
    <IntroScreen
      title="Loan Calculators"
      subtitle={'Use our suite of loan calculators to\n explore costs for mortgages, auto loans\n and student loans'}
      image={require('./assets/loan-calculator-intro.png')}
      buttonText="Next"
      finnStyle={styles.finn}
      onPress={() => {
        navigation.navigate('Modal', { screen: 'Loan Calculator Selection' })
      }}
    />
  )
}
