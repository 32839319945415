import { View, Image, StyleSheet, Text } from 'react-native'
import { darkgrayblue, DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  container: { marginVertical: DEFAULT_SPACING },
  image: {
    marginBottom: DEFAULT_SPACING,
    height: 200,
    width: 200,
    alignSelf: 'center'
  },
  header: {
    color: darkgrayblue,
    fontSize: 22,
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 12
  },
  information: {
    color: 'rgb(120, 138, 152)',
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center'
  }
})

export default function SearchFinn({ header, information }) {
  return (
    <View style={styles.container}>
      <Image
        resizeMode="contain"
        style={styles.image}
        source={require('../../assets/finn/question.png')}
      />
      <Text style={styles.header}>{header}</Text>
      <Text style={styles.information}>{information}</Text>
    </View>
  )
}
