import { useLayoutEffect } from 'react'

import IntroScreen from './components/IntroScreen'

export default function FutureValueCalculatorIntroScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false });
  }, [navigation])

  return (
    <IntroScreen
      title="Future Value Calculator"
      subtitle="Use this calculator to determine the future value of an investment which can include an initial deposit and a stream of periodic deposits."
      image={require('./assets/finn/future-value.png')}
      buttonText="Next"
      onPress={() => {
        navigation.navigate('Modal', { screen: 'Future Value Calculator' })
      }}
    />
  )
}
