import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Text, StyleSheet, Image } from 'react-native'
import { info } from '../styles/colors'
import hapticFeedback from './hapticFeedback'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  container: {
    minHeight: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0.05,
    color: info
  },
  iconLeft: { marginRight: 6 },
  iconRight: { marginLeft: 6 },
  image: {
    width: 14,
    height: 14,
    marginRight: 6
  }
})

export default function Link(props) {
  const {
    style,
    image,
    iconStyle,
    iconSize,
    containerStyle,
    onPress,
    disabled,
    children,
    iconRight,
    imageRight,
    icon,
    type,
    actionName,
    imageStyle
  } = props
  const iconColor =
    (iconStyle && iconStyle.color) ||
    (style && style.color) ||
    props?.iconColor ||
    'white'

  function handleOnPress() {
    if (!disabled) {
      onPress()
      hapticFeedback()
    }
  }

  return (
    <TouchableOpacity
      style={[styles.container, containerStyle]}
      onPress={handleOnPress}
      disabled={disabled}
      action={actionName}
    >
      {image && !imageRight && (
        <Image
          source={image}
          style={[styles.image, imageStyle]}
          resizeMode="contain"
        />
      )}
      {icon && !iconRight && (
        <FontAwesomeIcon
          icon={[type || 'far', icon]}
          color={iconColor}
          size={iconSize || 20}
          style={[styles.iconLeft, iconStyle]}
        />
      )}
      <Text style={[styles.link, style]}>{children}</Text>
      {icon && iconRight && (
        <FontAwesomeIcon
          icon={[type || 'far', icon]}
          color={iconColor}
          size={iconSize || 20}
          style={[styles.iconRight, iconStyle]}
        />
      )}
      {image && imageRight && (
        <Image
          source={image}
          style={[styles.image, imageStyle]}
          resizeMode="contain"
        />
      )}
    </TouchableOpacity>
  )
}
