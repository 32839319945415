import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from 'react-native'
import { VictoryPie } from 'victory-native'

import { reduce } from 'lodash'
import formatCurrency from '../functions/formatCurrency'

import { grayblue } from '../styles/colors'
import { DEFAULT_SPACING } from '../styles'

const styles = StyleSheet.create({
  donutContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  centerTextView: {
    position: 'absolute',
    alignItems: 'center',
  },
  centerTextAmount: {
    fontWeight: '500',
    fontSize: 24,
    letterSpacing: -1.03
  },
  centerTextTotal: {
    fontWeight: '500',
    fontSize: 14,
    letterSpacing: 0
  },
  legendView: {
    paddingHorizontal: DEFAULT_SPACING,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  pieLabelText: {
    fontSize: 12,
    color: grayblue,
    letterSpacing: 0,
    flexWrap: 'wrap',
    width: '80%'
  },
  pieLabelColor: (color) => ({
    marginRight: DEFAULT_SPACING,
    backgroundColor: color,
    borderRadius: 15 / 2,
    height: 15,
    width: 15
  }),
  pieLabelView: {
    flexDirection: 'row',
    display: 'flex',
    width: '48%',
    marginBottom: DEFAULT_SPACING
  }
})

/**
 * This is a generic Donut component that will render your data with a gap in between
 * to display any additional information. Since this is a generic component,
 * it is expected to pass in as much data as possible w.r.t labels, values and colors.
 * Here is the specification of the props
 * {
 *  chartData: <List: A collection of chartObject(s)>
 * }
 *
 * chartObject: Object{
 *  label: <String: Name of the information you are displaying in the pie chart>,
 *  val: <Int: Amount of the pie chart the value will cover>
 *  color: <String: The hexcode of the color used to represent this label>
 * }
 *
 * Example chartData:
 * const chartData = [
 * {label: "Real Estate", val: 10, color: labelMap.assets["Real Estate"]},
 * {label: "Roth IRA", val: 10, color: labelMap.assets["Roth IRA"]},
 * {label: "Mutual Funds", val: 10, color: labelMap.assets["Mutual Funds"]},
 * {label: "401k", val: 10, color: labelMap.assets["401k"]},
 * {label: "Savings Account", val: 10, color: labelMap.assets["Savings Account"]},
 * ]
*/
export default function DataDonut({ chartData, innerRadius = 120, height = 300 }) {
  const graphicData = chartData.map((data) => ({ y: data.val }))
  const graphicLabels = chartData.map((data) => (data.label))
  const graphicColor = chartData.map((data) => (data.color))
  const total = reduce(chartData, (sum, data) => (sum + (data.val || 0)), 0)

  return (
    <View>
      <View style={styles.donutContainer}>
        <VictoryPie
          animate={{ easing: 'exp' }}
          data={graphicData}
          colorScale={graphicColor}
          innerRadius={innerRadius}
          labels={() => null}
          height={height}
        />

        <View testID="total-container" style={styles.centerTextView}>
          <Text testID="currency" style={styles.centerTextAmount}>{`$${formatCurrency(total)}`}</Text>
          <Text testID="total" style={styles.centerTextTotal}>TOTAL</Text>
        </View>
      </View>

      <View testID="legend-container" style={styles.legendView}>
        {
          graphicLabels.map(
            (label, index) => (
              <PieLabel key={label} label={(!label) ? 'Unknown' : label} color={graphicColor[index]} />
            )
          )
        }
      </View>
    </View>
  )
}

export function PieLabel({ label, color }) {
  return (
    <View style={styles.pieLabelView}>
      <View style={styles.pieLabelColor(color)} />
      <Text style={styles.pieLabelText}>{label}</Text>
    </View>
  )
}

DataDonut.propTypes = { chartData: PropTypes.arrayOf(PropTypes.object).isRequired }
PieLabel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}
