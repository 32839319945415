import { StyleSheet, ScrollView, View, Platform, useWindowDimensions } from 'react-native'

import { Table, Row, Col, Cell, TableWrapper } from 'react-native-table-component'

import { map } from 'lodash'
import { rgba, white, info, darkgrayblue } from '../../styles'

const styles = StyleSheet.create({
  wrapper: { flexDirection: 'row' },
  row: { height: 50 },
  title: {
    fontWeight: '600',
    fontSize: 12,
    color: darkgrayblue,
    letterSpacing: 0,
    paddingVertical: 9,
    paddingHorizontal: 9,
  },
  text: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    color: darkgrayblue,
    letterSpacing: -0.5
  },
  tableBorder: {
    borderLeftWidth: 0.5,
    borderBottomWidth: 0.5,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderColor: 'rgb(221, 228, 230)'
  },
  even: {
    backgroundColor: white,
    borderColor: 'rgb(221, 228, 230)',
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0.5,
  },
  odd: {
    backgroundColor: '#F8FBFC',
    borderColor: 'rgb(221, 228, 230)',
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0.5,
  }
})

function calculateRow(value, formatter, suffix, prefix) {
  return (prefix || '') + (formatter ? formatter(value) : value) + (suffix || '')
}

export default function _Table({ labels, assetLabels, data, prefixes, suffixes, formatters, scrollable, highlightedColumn, width: containerWidth }) {
  const isNotWeb = Platform.OS !== 'web'
  const width = (isNotWeb ? useWindowDimensions().width : (containerWidth || useWindowDimensions().width)) / 4

  const tableData = [
    assetLabels,
    ...map(Object.keys(labels), (key) => map(data, (row) => calculateRow(row[key], formatters[key], suffixes[key], prefixes[key])))
  ]

  const tableTitle = [''].concat(Object.values(labels))

  const tableConfig = {
    tableTitle,
    tableData
  }

  const height = 50
  // needs to be less by .5 because of the top border applied to the cell
  const headerHeight = 49.5

  return (
    <Table>
      <TableWrapper style={[styles.wrapper]}>
        <Table style={{ width: width * 2, marginTop: -0.5 }} borderStyle={styles.tableBorder}>
          {map(tableConfig.tableTitle, (title, index) => (
            <Col data={[title]} heightArr={[headerHeight]} textStyle={styles.title} style={index == 0 || index % 2 ? styles.even : styles.odd} />
          ))}
        </Table>

        <ScrollView scrollEnabled={scrollable} horizontal>
          <Table style={{ flex: 1 }}>
            {map(tableConfig.tableData, (data, index) => (
              <View style={[{ flexDirection: 'row', width: width * data.length, height }]}>
                {map(data, (rowData, rowIndex) => {
                  let backgroundColor = (index == 0 || index % 2) ? white : '#F8FBFC'

                  if (rowIndex == highlightedColumn) {
                    backgroundColor = index == 0 || index % 2 ? rgba(info, 0.1) : rgba(info, 0.13)
                  }

                  return (
                    <Cell
                      key={rowIndex}
                      data={rowData}
                      width={width}
                      height={height}
                      textStyle={[styles.text]}
                      style={[styles.tableBorder, { backgroundColor }]}
                    />
                  )
                })}
              </View>
            ))}
          </Table>
        </ScrollView>
      </TableWrapper>
    </Table>
  )
}
