import { View, StyleSheet, Text, Platform } from 'react-native'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import SlideupDrawer from '../SlideupDrawer'
import Button from '../Button'
import Screen from '../Screen'
import NumberInput from '../NumberInput'
import CurrencyInput from '../CurrencyInput'

import {
  ValidationSchema,
  Fields,
  InitialValues
} from '../../schemas/loanSchema'

import {
  saveLoan,
  deleteLoan
} from '../../actions'
import { darkblue, red } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'
import RadioGroup from '../RadioGroup'

const basicStyles = {
  container: {
    paddingHorizontal: 30,
    paddingHorizontal: 30,
    paddingTop: 80
  },
  header: {
    fontWeight: '600',
    fontSize: 20,
    color: darkblue,
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING
  },
  button: { marginTop: DEFAULT_SPACING },
  deleteButton: {
    backgroundColor: red,
    marginTop: 24,
  },
  input: {
    backgroundColor: 'rgba(242, 248, 250, 0.9)',
    marginBottom: 12
  },
  buttonGroup: { display: 'flex', },
  cancelButton: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        paddingTop: 0,
        paddingHorizontal: 16,
      },
      header: {
        textAlign: 'left',
        fontSize: 28,
      },
      buttonGroup: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: DEFAULT_SPACING,
      },
      button: { width: 210, },
      cancelButton: {
        color: '#4F8FF0',
        fontSize: 18,
      },
    }
  }
)

export default function DebtRankSlideupDrawer({ visible, setVisible, debt }) {


  return (
    <SlideupDrawer snapPointIndex={3} closeEnabled scrollEnabled visible={visible} setVisible={setVisible}>
 
    </SlideupDrawer>
  )
}
