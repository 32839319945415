import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_INT,
  INVALID_MIN,
} from './constants'

export const Fields = {
  custom_career_name: 'custom_career_name',
  custom_career_salary: 'custom_career_salary',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.custom_career_salary]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(50000000, 'Must be less than 50,000,000')
    .required(REQUIRED)
    .nullable(),
  [Fields.custom_career_name]: Yup.string().required(REQUIRED),
})

export const InitialValues = {
  [Fields.custom_career_name]: '',
  [Fields.custom_career_salary]: ''
}
