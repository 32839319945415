import { useEffect, useState } from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'

import { useDispatch } from 'react-redux'
import { each, first } from 'lodash'
import { get } from 'http'
import Button from '../Button'
import Modal from '../Modal'

import { clearBranchEvent, clearPendingInvitation } from '../../actions'

import useImageAspectRatio from '../../hooks/useAspectRatio'

import { darkblue, lightgray, gunmetal, red, primary } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

const styles = StyleSheet.create({
  modal: { backgroundColor: 'rgba(6, 15, 18, 0.85)', },
  container: {
    paddingVertical: DEFAULT_SPACING,
    justifyContent: 'center',
    alignItems: 'center'
  },
  organizationName: {
    fontSize: 50,
    color: primary,
    textAlign: 'center'
  },
  plus: {
    fontSize: 50,
    color: lightgray
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center',
    zIndex: 100,
    marginBottom: DEFAULT_SPACING
  },
  troutwoodLogo: {
    width: 120,
    height: 120
  },
  organizationLogo: { width: '100%' },
  body: {
    marginVertical: DEFAULT_SPACING,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center',
    zIndex: 100
  },
  button: { marginTop: DEFAULT_SPACING },
  errorMessage: {
    color: red,
    marginBottom: 12,
    fontSize: 12
  }
})

export default function OnboardingModal({ pendingInvitations, navigation }) {
  const dispatch = useDispatch()
  const visible = pendingInvitations.length > 0
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  const firstInvite = first(pendingInvitations)

  function handleClose() {
    each(pendingInvitations, (invite) => {
      dispatch(clearBranchEvent(invite))
      dispatch(clearPendingInvitation(invite))
    })
  }

  function handleNavigation() {
    navigation.navigate('Modal', { screen: 'Join Team Welcome', params: { invitation: firstInvite } })
    handleClose()
  }

  const {
    organization_logo: organizationLogo,
    organization_name: organizationName,
  } = firstInvite || {}

  const aspectRatio = useImageAspectRatio(organizationLogo)

  async function fetchInvitation() {
    try {
      const response = await get(`/api/v1/premium/invitation/status/${firstInvite.key || firstInvite.invitation_key}`)
      const { data } = response

      if (data.status == 'ACCEPTED') {
        setErrorMessage("You've already accepted this invitation")
      }

      setLoading(false)
    } catch (e) {
      setErrorMessage('Invalid Invitation')
    }
  }

  useEffect(() => {
    fetchInvitation()
  }, [firstInvite])

  return (
    <Modal closeable scrollEnabled={false} visible={visible} setVisible={handleClose}>
      <View style={styles.container}>

        { organizationLogo && (
          <Image
            style={[styles.organizationLogo, { aspectRatio }]}
            source={{ uri: organizationLogo }}
            resizeMode="contain"
          />
        ) }
        { !organizationLogo && <Text style={styles.organizationName}>{organizationName}</Text> }

        <Text style={styles.plus}>+</Text>
        <Image
          style={[styles.troutwoodLogo, { aspectRatio: 1 }]}
          source={require('../../assets/logo2.png')}
          resizeMode="contain"
        />

        <View>
          <Text style={styles.header}>Congratulations!</Text>

          <Text style={styles.body}>
            {organizationName}
            {' '}
            and Troutwood have partnered to help you reach your financial goals.
          </Text>
        </View>

        { errorMessage ? (
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : (
          <Button disabled={loading} onPress={handleNavigation} style={styles.button}>
            Accept Invite
          </Button>
        )}
      </View>
    </Modal>
  )
}
