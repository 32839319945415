import { stateReducer } from '@truefit/redux-utils'
import { SET_FAST_PLAN_MODE_DATA, CLEAR } from '../actions';

const initialState = {
  birthday: null,
  fastMode: false,
  city: null,
}

export default stateReducer(initialState, {
  [SET_FAST_PLAN_MODE_DATA]: (state, payload) => ({ ...state, ...payload }),
  [CLEAR]: () => initialState
});
