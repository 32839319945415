import { View, Image, StyleSheet } from 'react-native'
import { white } from '../styles'

const styles = StyleSheet.create({
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
})

export default function CircleImage({ url, backgroundColor = white, resizeMode = 'cover', image, size, height = 27, width = 27, imageHeight = '100%', imageWidth = '100%', style }) {
  return (
    <View style={[styles.circleContainer, style, { borderRadius: (size || height) / 2, height: size || height, width: size || width, backgroundColor }]}>
      <Image source={image || { uri: url }} resizeMode={resizeMode} style={{ width: imageWidth, height: imageHeight }} />
    </View>
  )
}
