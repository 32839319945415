import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { Animated, Platform, Text, View, ScrollView, StyleSheet, Image } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { useDispatch, useSelector } from 'react-redux'
import { useIsFocused } from '@react-navigation/native'
import PropTypes from 'prop-types'
import moment from 'moment'
import { LinearGradient } from 'expo-linear-gradient'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Card from './components/Card'
import Button from './components/Button'
import Link from './components/Link'
import ProPlanDetailsCard from './components/planSummary/ProPlanDetailsCard'
import BudgetForMonthCard from './components/proPlanSummary/BudgetForMonthCard'
import { MONTHLY, NAVIGATION_TYPE } from './constants'
import PlanSummaryOptions from './components/planSummary/PlanSummaryOptions'
import PillLabel from './components/PillLabel'
import PlanSummaryHeader from './components/planSummary/PlanSummaryHeader'
import financialProjectionUtils from './functions/financialProjectionUtils'
import formatPercent from './functions/formatPercent'
import { isEmpty, find } from 'lodash'
import { get } from 'http'
import getProjectedBudgetItemsByCategory from './functions/getProjectedBudgetItemsByCategory'
import getProBudgetStats from './functions/getProBudgetStats'
import proApplicationEndpoints from './api/proApplicationEndpoints'

import {
  getBudgetItems,
  getFinancialProjections,
  setLoading,
  getTransactions,
  assignPrimaryProPlan,
  setProPlan
} from './actions'

import {
  currentUserSelector,
  loadingSelector,
  financialProjectionsSelector,
  currentProPlanSelector,
  proBudgetSelector,
  plansSelector,
  transactionsByMonthAndYearSelector,
  budgetCategoriesSelector
} from './selectors'

import NamePlanSlideupDrawer from './components/plans/NamePlanSlideupDrawer'
import ProTutorialSlideupDrawer from './components/plans/ProTutorialSlideupDrawer'

import {
  darkblue,
  info,
  gunmetal,
  lightestgray,
  primary,
  rgba,
  white,
  red,
  magicPotion,
  indianYellow
} from './styles/colors'
import { DEFAULT_SPACING } from './styles'
import handleError from './functions/handleError'

import HeaderLabel from './components/HeaderLabel'

import CircularProgress from './components/CircularProgress'
import ProBudgetItem from './components/ProBudgetItem'
import SetPrimaryPlanSlideupDrawer from './components/plans/SetPrimaryPlanSlideupDrawer'
import MyFinPulseCard from 'components/proPlanSummary/MyFinPulseCard'

import abbreviateCurrency from './functions/abbreviateCurrency'
import RecentTransactionsCard from './components/budgeting/RecentTransactionsCard'
import PlanSummaryPageHeader from './components/planSummary/PlanSummaryPageHeader'
import CircleGradient from './components/CircleGradient'

const basicStyles = {
  budgetCard: {
    marginHorizontal: DEFAULT_SPACING,
    padding: DEFAULT_SPACING,
    position: 'relative'
  },
  budgetCardComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: DEFAULT_SPACING
  },
  card: {
    padding: 10,
    paddingTop: DEFAULT_SPACING,
    margin: 8,
    shadowColor: 'transparent',
    borderRadius: 6
  },
  title: {
    fontSize: 20,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '500'
  },
  cardtitle: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: 12
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'center',
    letterSpacing: -0.5
  },
  title: {
    fontSize: 18,
    color: darkblue,
    textAlign: 'left',
    fontWeight: '500'
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'left',
    letterSpacing: -0.4
  },
  headerText: {
    fontSize: 24,
    color: white,
    fontWeight: '700'
  },
  summaryContainer: {
    flex: 1,
    flexDirection: 'column',
    marginTop: 160,
  },
  subLabel: {
    minHeight: 32
  },
  loadingSquare: { backgroundColor: lightestgray, height: 100, width: 100 },
  progressContent: { fontWeight: '600', fontSize: 32 },
  loadingBlock: { backgroundColor: lightestgray, height: 16, width: 40 },
  summaryDetailsContainer: {
    paddingTop: 160
  },
  activateBtn: {
    flex: 1,
    marginVertical: DEFAULT_SPACING * 2,
    marginHorizontal: DEFAULT_SPACING
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
    summaryContainer: {
      marginTop: DEFAULT_SPACING * 2,
      backgroundColor: lightestgray,
    },
    summaryDetailsContainer: {
      paddingTop: DEFAULT_SPACING * 2,
      maxWidth: 1600,
    },
    loadingSquare: { height: 72, width: 72 }
  },
  [minSize(DEVICE_SIZES.LG)]: {
    scrollView: { paddingHorizontal: DEFAULT_SPACING },
    progressContent: { fontSize: 20 },
    summaryDetailsContainer: {
      paddingTop: 36,
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingHorizontal: 40,
      justifyContent: 'flex-start'
    },
    activateBtn: {
      minWidth: 688,
      maxWidth: 1074,
      height: 48,
      marginTop: DEFAULT_SPACING * 3,
      marginHorizontal: 0
    },
    summaryContainer: { marginTop: 0 }
  }
})

const isNotWeb = Platform.OS !== 'web'

function BudgetCard({ projectedBudget, onPress, onLinkPress, style, transactions = [] }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const actual = transactions.reduce((sum, transaction) => {
    if (transaction.budgetCategory !== 'INCOME') {
      return sum + transaction.amount
    } else {
      return sum
    }
  }, 0)

  return (
    <Card onPress={onPress} style={[styles.budgetCard, style]}>
      <Link
        containerStyle={{
          position: 'absolute',
          top: 8,
          right: 12,
          zIndex: 200
        }}
        onPress={onLinkPress}
      >
        Edit
      </Link>

      <View style={styles.budgetCardComponent}>
        <HeaderLabel style={{ alignSelf: 'center' }}>My Budget</HeaderLabel>
      </View>

      <ProBudgetItem
        onPress={() => {}}
        title={`${moment().format('MMM')} Budget`}
        budgetAmount={projectedBudget.totalExpenses}
        actualAmount={actual}
      />
    </Card>
  )
}

BudgetCard.defaultProps = { style: {} }

BudgetCard.propTypes = {
  onPress: PropTypes.func.isRequired,
  style: PropTypes.object,
  budget: PropTypes.shape({ total: PropTypes.number.isRequired }).isRequired
}

export default function ProPlanSummaryScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const currentProPlan = useSelector(currentProPlanSelector)
  const { planId: proPlanId, freePlanId } = currentProPlan
  const { goal: { horizonDate } = {} } = currentProPlan || {}
  const [shieldScores, setShieldScores] = useState([])
  const dispatch = useDispatch()
  const currentPlan = find(useSelector(plansSelector), (plan) => plan.id === freePlanId)
  const [freePlan, setFreePlan] = useState(currentPlan)

  const currentUser = useSelector(currentUserSelector)
  const { metadata } = currentUser || {}

  const { loading } = useSelector((state) => loadingSelector(state))
  const budget = useSelector(proBudgetSelector)
  const [saving, setSaving] = useState(false)

  const [visible, setVisible] = useState(false)
  const [slideupVisible, showSlideup] = useState(false)
  const [proTutorialVisible, setProTutorialVisible] = useState(false)
  const isFocused = useIsFocused()
  const projections = useSelector(financialProjectionsSelector)

  async function fetchProPlan() {
    try {
      const result = await get(proApplicationEndpoints.getPlan(currentUser.id, proPlanId))

      dispatch(setProPlan({ planId: proPlanId, plan: result.data }))
      setShieldScores(result.data.shieldScores)
    } catch (error) {
      console.error(error, error.response, error.response)
      handleError(error, 'Oops. Looks like something went wrong.')
    }
  }

  async function fetchAndSetData() {
    try {
      dispatch(setLoading({ loading: true }))

      // get transactions for today - last 4 months (for budget trends)
      const startDate = moment().subtract(4, 'months').startOf('month').format('YYYY-MM-DD')
      const endDate = moment().format('YYYY-MM-DD')

      // Define the tasks we want to run in parallel
      const tasks = [
        dispatch(getTransactions(currentUser.id, startDate, endDate)),
        dispatch(getBudgetItems(proPlanId)),
        dispatch(getFinancialProjections(proPlanId)),
        get(`api/v1/plans/${currentPlan.id}/`)
      ]

      // Run tasks in parallel and wait for all of them to complete
      const [, , , planResult] = await Promise.all(tasks)

      const plan = planResult.data
      plan.id = currentPlan.id

      setFreePlan(plan)

      dispatch(setLoading({ loading: false }))
    } catch (error) {
      handleError(error, 'Error fetching plan data')
    }
  }

  useEffect(() => {
    if (isFocused) fetchAndSetData()
  }, [isFocused])

  useEffect(() => {
    if (isFocused) fetchProPlan()
  }, [isFocused, projections])

  useEffect(() => {
    const metadataKey = 'TROUTWOOD_PRO_TUTORIAL'

    if (!metadata[metadataKey] && isFocused) {
      setProTutorialVisible(true)
    }
  }, [isFocused, metadata])

  const height = 400

  const offset = useRef(new Animated.Value(0)).current
  const transactionsByMonthAndYear = useSelector(transactionsByMonthAndYearSelector)

  // Get transactions for the current month
  const currentMonthTransactions = transactionsByMonthAndYear[moment().format('YYYY-MM')] || []

  // Get transactions for the last month
  const lastMonthTransactions =
    transactionsByMonthAndYear[moment().subtract(1, 'months').format('YYYY-MM')] || []

  // Combine transactions for both months
  const recentTransactions = [...currentMonthTransactions, ...lastMonthTransactions]

  const opacity = offset.interpolate({
    inputRange: [0, height - 250],
    outputRange: [0, 1],
    extrapolate: 'clamp',
    useNativeDriver: true
  })

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      title: (
        <Animated.View style={{ opacity }}>
          <Text style={styles.headerText}>Plan Summary</Text>
        </Animated.View>
      )
    })
  }, [navigation, currentPlan, saving])

  const onBudgetTrendsCardPress = () => {
    navigation.navigate('Modal', {
      screen: 'Budget Trends Details',
      params: { planId: proPlanId }
    })
  }

  const getProjectedBudget = (projection) => {
    if (isEmpty(projection)) {
      return {
        budgetItemsByCategory: {},
        total: 0,
        count: 0
      }
    }

    const projectedBudgetItemsByCategory = getProjectedBudgetItemsByCategory(projection, budget)
    return {
      budgetItemsByCategory: projectedBudgetItemsByCategory,
      ...getProBudgetStats(projectedBudgetItemsByCategory)
    }
  }

  function progressContent(percent) {
    return <Text style={styles.progressContent}>{`${percent.toFixed(0)}%`}</Text>
  }

  function loadingBlock() {
    return <View style={{ backgroundColor: lightestgray, height: 16, width: 40 }} />
  }

  function loadingSquare() {
    return <View style={styles.loadingSquare} />
  }

  const [duration, setDuration] = useState(MONTHLY)

  const getScoreByType = (scores, type) =>
    (scores?.find((score) => score.type === type) || {}).score || 0

  const likelihoodScore = getScoreByType(shieldScores, 'LIKELIHOOD')
  const adequacyScore = getScoreByType(shieldScores, 'ADEQUACY')
  const adequacyShield = shieldScores?.find((score) => score.type === 'ADEQUACY')

  const { created } = currentPlan || {}

  const { findAtDate: findProjection } = financialProjectionUtils(projections)
  const finalProjection = findProjection(horizonDate)
  const currentProjection = findProjection(moment())
  const projectedBudget = getProjectedBudget(currentProjection)
  const budgetCategories = useSelector(budgetCategoriesSelector)
  let { netWorth } = finalProjection || {}
  netWorth = netWorth || 0

  let chanceColor = indianYellow
  if (likelihoodScore > 70) {
    chanceColor = primary
  } else if (likelihoodScore < 30) {
    chanceColor = magicPotion
  }

  let adequacyColor = indianYellow
  let adequacyLabel = 'Fair'

  if (adequacyScore > 70) {
    adequacyColor = primary
    adequacyLabel = 'Good'
  } else if (adequacyScore < 30) {
    adequacyColor = magicPotion
    adequacyLabel = 'Poor'
  }

  const planSummaryOptions = [
    {
      label: 'Likelihood',
      sublabel: (
        <Text style={styles.sublabel}>
          You have a
          {loading ? (
            ' ???'
          ) : (
            <Text style={{ color: chanceColor }}> {likelihoodScore}% chance</Text>
          )}{' '}
          of achieving your goal!
        </Text>
      ),
      leftComponent: loading ? (
        loadingSquare()
      ) : (
        <CircularProgress
          progress={likelihoodScore}
          size={isNotWeb ? 110 : 72}
          width={isNotWeb ? 9 : 5}
          fillFunction={() => progressContent(likelihoodScore)}
          tintColor={chanceColor}
        />
      ),
      onPress: () => {
        navigation.navigate('Likelihood', {
          probabilityOfSuccess: likelihoodScore,
          pro: true,
          chanceColor
        })
      }
    },
    {
      label: 'Estimated Value',
      sublabel: (
        <Text style={styles.sublabel}>
          Projected scenarios based on historical market performance
        </Text>
      ),
      // disabled: true,
      leftComponent: (
        <View style={{ alignItems: 'center', height: isNotWeb ? 'auto' : 72 }}>
          <FontAwesomeIcon
            icon={['fas', 'arrow-trend-up']}
            size={isNotWeb ? 54 : 40}
            color={info}
          />
          {loading ? (
            loadingBlock()
          ) : (
            <Text style={{ fontWeight: '600', fontSize: isNotWeb ? 30 : 24 }}>
              ${abbreviateCurrency(netWorth, 1)}
            </Text>
          )}
        </View>
      ),
      onPress: () => {
        navigation.navigate('Estimated Value', {
          netWorth
        })
      }
    },
    {
      label: 'Adequacy',
      sublabel: (
        <View
          style={{
            flexDirection: isNotWeb ? 'column' : 'row',
            alignItems: isNotWeb ? 'flex-start' : 'center'
          }}
        >
          <Text style={styles.sublabel}>Is your goal enough?</Text>
          {loading ? (
            loadingBlock()
          ) : (
            <PillLabel
              width={50}
              style={{
                padding: 1,
                marginLeft: 2,
                marginVertical: isNotWeb ? 6 : 0,
                backgroundColor: rgba(adequacyColor, 0.1)
              }}
              textStyle={{
                color: adequacyColor,
                textTransform: 'capitalize',
                fontSize: 12,
                letterSpacing: 0
              }}
              title={adequacyLabel}
            />
          )}
        </View>
      ),
      leftComponent: loading ? (
        loadingSquare()
      ) : (
        <CircularProgress
          progress={adequacyScore}
          size={isNotWeb ? 110 : 72}
          width={isNotWeb ? 9 : 5}
          fillFunction={() => progressContent(adequacyScore)}
          tintColor={adequacyColor}
        />
      ),
      onPress: () => {
        navigation.navigate('Adequacy Score', {
          adequacyScore,
          adequacyColor,
          adequacyShield
        })
      }
    }
  ]

  const planSummaryHeader = [
    {
      label: 'Likelihood',
      value: formatPercent(likelihoodScore),
      color: chanceColor,
      backgroundColor: rgba(chanceColor, 0.1),
      icon: 'bullseye',
      onPress: () => {
        navigation.navigate('Likelihood', {
          probabilityOfSuccess: likelihoodScore,
          pro: true,
          chanceColor
        })
      }
    },
    {
      label: 'Estimated Value',
      value: `$${abbreviateCurrency(netWorth)}`,
      color: info,
      backgroundColor: rgba(info, 0.1),
      icon: 'stars',
      onPress: () => {
        navigation.navigate('Estimated Value', {
          netWorth
        })
      }
    },
    {
      label: 'Adequacy',
      value: formatPercent(adequacyScore, 0),
      color: adequacyColor,
      backgroundColor: rgba(adequacyColor, 0.1),
      icon: 'chart-simple',
      onPress: () => {
        navigation.navigate('Adequacy Score', {
          adequacyScore,
          adequacyColor,
          adequacyShield
        })
      }
    }
  ]

  const { totalExpenses, total } = projectedBudget
  const totalIncome = total + totalExpenses

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <CircleGradient />

      {!isNotWeb && <PlanSummaryPageHeader />}

      <PlanSummaryHeader
        loading={loading}
        name={currentProPlan?.name}
        created={created}
        onPressSave={setVisible}
        animatedValue={offset}
        options={planSummaryHeader}
        planSummaryOptions={planSummaryOptions}
      />

      <View style={{ flex: 1, position: 'relative' }}>
        <ScrollView
          scrollEventThrottle={16}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: offset } } }], {
            useNativeDriver: false
          })}
          style={styles.summaryContainer}
        >
          {isNotWeb && (
            <PlanSummaryOptions
              loading={loading}
              name={currentPlan?.name}
              created={created}
              animatedValue={offset}
              onPressSave={setVisible}
              options={planSummaryOptions}
            />
          )}

          <View style={styles.summaryDetailsContainer}>
            <ProPlanDetailsCard
              duration={duration}
              setDuration={setDuration}
              currentUser={currentUser}
              currentProPlan={currentProPlan}
              currentPlan={currentPlan}
              freePlan={freePlan}
              totalIncome={totalIncome}
              totalExpenses={totalExpenses}
              total={total}
            />

            {isNotWeb && <MyFinPulseCard navigation={navigation} />}

            <BudgetForMonthCard
              header={`${moment().format('MMMM')} Budget`}
              projectedBudget={projectedBudget}
              budgetItems={projectedBudget.budgetItemsByCategory}
              budgetCategories={budgetCategories}
              navigation={navigation}
              transactions={transactionsByMonthAndYear[moment().format('YYYY-MM')]}
            />

            <RecentTransactionsCard
              transactions={recentTransactions}
              navigation={navigation}
              budgetCategories={budgetCategories}
            />

            {/* {budget.count > 0 && (
              <BudgetTrendsCard
                onPress={onBudgetTrendsCardPress}
                style={{ marginTop: DEFAULT_SPACING }}
                planId={proPlanId}
                userId={currentUser.id}
              />
            )} */}

            {!isNotWeb && <MyFinPulseCard navigation={navigation} />}

            {currentUser && !currentUser.is_fake && (
              <Button
                disabled={currentPlan?.is_primary_plan}
                onPress={() => {
                  dispatch(setLoading({ loading: true }))
                  dispatch(assignPrimaryProPlan(currentProPlan, true))
                  dispatch(setLoading({ loading: false }))
                }}
                style={styles.activateBtn}
              >
                {currentPlan?.is_primary_plan || currentProPlan.isPrimary
                  ? 'Current Primary Plan'
                  : 'Set as Primary Plan'}
              </Button>
            )}
          </View>

          <NamePlanSlideupDrawer
            plan={{
              name: currentProPlan.name,
              planId: currentProPlan.planId,
              pro: true
            }}
            visible={visible}
            setVisible={setVisible}
            setSaving={setSaving}
          />

          <SetPrimaryPlanSlideupDrawer
            plan={currentProPlan}
            visible={slideupVisible}
            setVisible={showSlideup}
            onClose={() => showSlideup(false)}
          />
        </ScrollView>
      </View>

      <ProTutorialSlideupDrawer
        visible={proTutorialVisible}
        setVisible={setProTutorialVisible}
        onClose={() => setProTutorialVisible(false)}
        metadata={metadata}
      />
    </View>
  )
}

ProPlanSummaryScreen.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
