import PageHeader from '../PageHeader'

function DebtRankingPageHeader({ navigation }) {
  const title = 'Debt Ranking'
  const subTitle = 'If you can make more than the minimum payment, this is the repayment order that could save you the most money.'
  const tooltip = {
    label: 'Learn more',
    title: 'Learn more about minumum payments',
    text: "Remember, you must make payments (at least the minimum due) for each debt you own. Use this strategy to focus your extra money on paying more than the minimum due on your highest interest rate loan first. After you've paid off the first balance, move to the next one until you are debt free!",
  }

  return <PageHeader title={title} subTitle={subTitle} tooltip={tooltip} />
}

export default DebtRankingPageHeader
