import { Text, View } from 'react-native';
import PropTypes from 'prop-types'
import { DEFAULT_SPACING, black, gunmetal, primary, red, rgba } from '../styles';
import CircleIcon from './CircleIcon';
import formatCurrency from '../functions/formatCurrency';

const listHeaderStyles = {
  header: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 20,
    marginBottom: 20
  },
  headerTitle: {
    fontWeight: '500',
    fontSize: 16,
    color: black,
    letterSpacing: 0,
    marginLeft: DEFAULT_SPACING
  },
  headerSubtitle: {
    fontSize: 14,
    fontWeight: '500',
    color: gunmetal,
    letterSpacing: -0.5,
    marginLeft: DEFAULT_SPACING
  },
  circleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: DEFAULT_SPACING,
    width: 45,
    height: 45,
    borderRadius: 45 / 2
  }
}

function addBalances(accounts) {
  return accounts.reduce((acc, account) => acc + account.balance, 0)
}

export default function AccountsListHeader({ accounts, isAsset, label }) {
  const color = isAsset ? primary : red;
  return (
    <View style={listHeaderStyles.header}>
      <View style={{ justifyContent: 'center' }}>
        <Text style={listHeaderStyles.headerTitle}>
          {label}
        </Text>
        <Text style={listHeaderStyles.headerSubtitle}>
          Total:
          <Text testId="accountSum" style={{ color }}>{` $${formatCurrency(addBalances(accounts))}`}</Text>
        </Text>
      </View>
    </View>
  )
}

AccountsListHeader.propTypes = {
  accounts: PropTypes.array.isRequired,
  isAsset: PropTypes.bool.isRequired
}
