import { useState } from 'react'
import { Platform, View, ScrollView, StyleSheet, } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import { map, find, includes, remove, filter } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { primary, white } from './styles/colors'
import Button from './components/Button'

import CareerCard from './components/CareerCard'

import { plansSelector } from './selectors'
import { DEFAULT_SPACING } from './styles'

const basicStyles = {
  cardList: {
    padding: DEFAULT_SPACING,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  card: { width: '49%', }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      cardList: { justifyContent: 'flex-start', paddingRight: 0, },
      card: { width: 'calc(33.33% - 12px)', marginRight: DEFAULT_SPACING, }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

function ActionButton({ compare, compares, setCompares, navigation, form }) {
  const selected = find(compares, (comp) => form.id == comp.id)

  const buttonStyle = (!compare || selected) ? {
    borderRadius: 12,
    paddingHorizontal: 10,
    paddingVertical: 12
  } : {
    borderRadius: 12,
    paddingHorizontal: 10,
    paddingVertical: 12,
    backgroundColor: white,
    borderWidth: 2,
    borderColor: primary
  }

  const buttonTitleStyle = (!compare || selected) ? { fontSize: 14 } : {
    fontSize: 14,
    color: primary
  }

  function ButtonLabel() {
    let compareLabel = compare ? 'Compare' : 'View'
    compareLabel = compare && selected ? 'Selected' : compareLabel

    return compareLabel
  }

  return (
    <Button
      icon={selected && compare ? 'check' : null}
      titleStyle={buttonTitleStyle}
      style={buttonStyle}
      onPress={() => {
        if (compare) {
          const ids = map(compares, 'id')
          if (includes(ids, form.id)) {
            setCompares(remove(compares, (comp) => form.id != comp.id))
          } else {
            setCompares(compares.concat({ ...form }))
          }
        } else {
          if (navigation.canGoBack()) {
            // beginning of stack
            navigation.pop()
          } else {
            navigation.reset({
              key: null,
              index: 0,
              routes: [{ name: 'Plan Summary' }]
            })
          }

          dispatch({
            type: 'SET_FORM',
            parameters: form
          })
        }
      }}
    >
      <ButtonLabel />
    </Button>
  )
}

export default function SavedCareersScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()
  const plans = useSelector((state) => plansSelector(state))
  const filteredPlans = filter(plans, (plan) => plan.step == 3)

  const [compare, setCompare] = useState(true)
  const [compares, setCompares] = useState([])

  return (
    <View style={{ flex: 1, backgroundColor: 'rgb(235, 241, 244)' }}>
      <ScrollView
        contentContainerStyle={{ paddingBottom: 100 }}
        style={styles.scrollViewContainer}
      >

        <View style={styles.cardList}>
          {map(filteredPlans, (career) => (
            <CareerCard
              key={career.id}
              style={styles.card}
              compare={compare}
              compares={compares}
              setCompares={setCompares}
              navigation={navigation}
              dispatch={dispatch}
              form={career}
              actionButton={(
                <ActionButton
                  compare={compare}
                  compares={compares}
                  setCompares={setCompares}
                  setCompare={setCompare}
                  navigation={navigation}
                  form={career}
                />
              )}
            />
          ))}
        </View>

      </ScrollView>
      <View style={{ justifyContent: 'flex-end', padding: 20 }}>
        <Button
          disabled={compares && compares.length <= 1}
          style={{ width: '96%', marginHorizontal: '2%' }}
          onPress={() => {
            navigation.navigate('Modal', { screen: 'Comparison Tool', title: 'Comparison Tool', params: { compares } })
          }}
        >
          Compare
        </Button>
      </View>
    </View>
  )
}
