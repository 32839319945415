import { useSelector } from 'react-redux'
import PageHeader from '../PageHeader'
import { careerLocationSelector } from '../../selectors'

function CareerExploreSearchPageHeader({ navigation }) {
  const title = 'Career Explore'
  const subTitle = 'Select a location to start exploring careers and compare salaries, growth outlook, job satisfaction and much more!'
  const careerLocation = useSelector((state) => careerLocationSelector(state))

  return <PageHeader title={title} subTitle={subTitle} />
}

export default CareerExploreSearchPageHeader
