import { StyleSheet } from 'react-native'

import { find } from 'lodash'
import { useDispatch } from 'react-redux'
import CircleIcon from '../CircleIcon'
import LineItemCard from '../LineItemCard'

import formatCurrency from '../../functions/formatCurrency'
import { deleteAccount } from '../../actions'
import { gunmetal, primary, rgba } from '../../styles/colors'

import capitalize from '../../functions/capitalize'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12
  },
  circleIcon: {
    backgroundColor: rgba(primary, 0.1),
    height: 50,
    width: 50,
    borderRadius: 50 / 2,
    marginRight: 12
  },
  headerLabel: {
    marginBottom: 0,
    textAlign: 'center',
    fontSize: 16,
    letterSpacing: -0.48
  },
  text: {
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5
  }
})

export default function AccountListItem({ navigation, sources, id, name, registration, balance }) {
  const selected = find(sources, (o) => o.registration == registration)
  const icon = selected ? selected.icon : 'star'
  const dispatch = useDispatch()

  const onEdit = function () {
    navigation.navigate('Modal', { screen: 'Emergency Preparedness Source Form', params: { id, name, registration, balance } })
  }

  const onDelete = async function () {
    dispatch(deleteAccount(id))
  }

  let formattedName = name.replace(/_/g, ' ')
  formattedName = capitalize(formattedName)
  formattedName = formattedName.replace('Ira', 'IRA')

  return (
    <LineItemCard
      customAvatar={<CircleIcon style={styles.circleIcon} color={primary} icon={icon} size={26} />}
      color={primary}
      title={formattedName}
      subtitle={`$${formatCurrency(balance)}`}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  )
}
