// React
import { useEffect, useLayoutEffect, useState } from 'react'
import { View, Text, Platform, ScrollView, StyleSheet } from 'react-native'
import { VictoryChart, VictoryArea, VictoryLabel, VictoryLine, VictoryTheme, VictoryAxis } from 'victory-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { capitalize, last, map } from 'lodash'
import Container from './components/Container'
import HouseTable from './components/opportunityCost/HouseTable'
import CarTable from './components/opportunityCost/CarTable'
import CollegeTable from './components/opportunityCost/CollegeTable'
import Header from './components/opportunityCost/Header'
import Carousel from './components/Carousel'
import HeaderLabel from './components/HeaderLabel'
import Link from './components/Link'
import Modal from './components/Modal'

import abbreviateNumber from './functions/abbreviateNumber'

import { opportunityCostCalculator } from './actions/opportunityCostCalculator'

import { lightestgray, info, rgba, DEFAULT_SPACING, primary, danger, red, purple, white, secondary, gunmetal } from './styles'
import Card from './components/Card'
import Legend from './components/Legend'

const basicStyles = {
  chartsContainer: {
    backgroundColor: lightestgray,
    paddingVertical: DEFAULT_SPACING,
  },
  tableContainer: {
    backgroundColor: white,
    paddingVertical: DEFAULT_SPACING * 2,
  },
  tableText: {
    paddingHorizontal: DEFAULT_SPACING * 2,
    textAlign: 'center',
    color: gunmetal,
  },
  link: { marginVertical: DEFAULT_SPACING * 2 },
  chartContainer: {
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    padding: DEFAULT_SPACING
  },
  chartLabel: {
    textAlign: 'center',
    marginTop: DEFAULT_SPACING
  },
  chartSublabel: {
    textAlign: 'center',
    color: secondary,
    fontSize: 12
  },
  card: { paddingVertical: DEFAULT_SPACING },
  infoModal: {
    backgroundColor: white,
    maxWidth: 600,
    padding: 24,
    borderRadius: 12,
  },
  modalHeader: { textAlign: 'center', },
  modalText: {
    color: secondary,
    textAlign: 'center',
    marginTop: DEFAULT_SPACING
  },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      chartsContainer: { backgroundColor: white, },
      card: { paddingVertical: 0, },
      chartSublabel: {
        fontSize: 16,
        marginTop: 10,
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

function getKeys(obj) {
  return map(obj, (value, key) => parseInt(key))
}

function getData(keys, obj) {
  return map(keys, (key) => obj[String(key)])
}

function OpportunityCostByPeriodChart({ opportunityCostData }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const label = 'Years'

  return (
    <Card style={styles.card}>
      <HeaderLabel style={styles.chartLabel}>Opportunity Cost</HeaderLabel>
      <Text style={styles.chartSublabel}>factoring cash flow</Text>

      <VictoryChart
        theme={VictoryTheme.material}
      >
        <VictoryAxis
          axisLabelComponent={<VictoryLabel dy={DEFAULT_SPACING * 2} />}
          label={label}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(value) => `$${abbreviateNumber(value)}`}
        />
        <VictoryArea
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 }
          }}
          style={{ data: { stroke: info, fill: rgba(info, 0.2) } }}
          data={opportunityCostData}
        />
      </VictoryChart>
    </Card>
  )
}

function OpportunityCostEquityChart({ type, assetValueData, balanceData, equityData, totalInvestedData }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const label = 'Years'
  const eligibleForValueOrEquity = type == 'home' || type == 'car'

  const options = []

  if (eligibleForValueOrEquity) {
    options.push({
      text: `${capitalize(type)} Value`,
      color: red
    })
  }

  options.push({
    text: 'Debt Remaining',
    color: purple
  })

  if (eligibleForValueOrEquity) {
    options.push({
      text: `${capitalize(type)} Equity`,
      color: info
    })
  }

  options.push({
    text: 'Cash Invested in Market',
    color: primary
  })

  return (
    <Card style={styles.card}>
      <HeaderLabel style={styles.chartLabel}>Opportunity Cost</HeaderLabel>
      <Text style={styles.chartSublabel}>factoring cash flow</Text>

      <VictoryChart
        theme={VictoryTheme.material}
        height={280}
      >
        <VictoryAxis
          axisLabelComponent={<VictoryLabel dy={DEFAULT_SPACING * 2} />}
          label={label}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(value) => `$${abbreviateNumber(value)}`}
        />
        {eligibleForValueOrEquity
          && (
            <VictoryLine
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 }
              }}
              style={{ data: { stroke: red } }}
              data={assetValueData}
            />
          )}
        <VictoryLine
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 }
          }}
          style={{ data: { stroke: purple } }}
          data={balanceData}
        />

        {eligibleForValueOrEquity
          && (
            <VictoryLine
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 }
              }}
              style={{ data: { stroke: info } }}
              data={equityData}
            />
          )}

        <VictoryLine
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 }
          }}
          style={{ data: { stroke: primary } }}
          data={totalInvestedData}
        />
      </VictoryChart>

      <Legend
        options={options}
      />
    </Card>
  )
}

export function OpportunityCostResults({ params }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [result, setResult] = useState()
  const [visible, setVisible] = useState(false)
  const [year, setYear] = useState(30)

  async function fetchData() {
    const response = await opportunityCostCalculator(params)
    setResult(response)
  }

  useEffect(() => {
    fetchData()
  }, [params])

  const TableScreens = {
    home: HouseTable,
    car: CarTable,
    college: CollegeTable
  }

  const TableScreen = TableScreens[params.type]

  const dataResult = result?.result || {}

  const bestChoice = last(dataResult.best_choice || '')

  const opportunityCost = dataResult.detail?.opportunity_cost || {}

  const assetValue = dataResult.detail?.asset_value || {}
  const balance = dataResult.detail?.balance || {}
  const equity = dataResult.detail?.equity || {}
  const totalInvested = dataResult.detail?.total_invested || {}

  const keys = getKeys(assetValue)
  const divisor = 12
  const assetValueData = map(getData(keys, assetValue), (value, i) => ({ y: value, x: keys[i] / divisor }))
  const balanceData = map(getData(keys, balance), (value, i) => ({ y: value, x: keys[i] / divisor }))
  const equityData = map(getData(keys, equity), (value, i) => ({ y: value, x: keys[i] / divisor }))
  const totalInvestedData = map(getData(keys, totalInvested), (value, i) => ({ y: value, x: keys[i] / divisor }))
  const opportunityCostData = map(getData(keys, opportunityCost), (value, i) => ({ y: value, x: keys[i] / divisor }))
  const bestValue = opportunityCostData[year - 1]
  const [containerWidth, setContainerWidth] = useState(0)

  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout
    setContainerWidth(width)
  }

  return (
    <>
      <ScrollView style={{ width: '100%' }}>
        <Header
          type={params.type}
          pillValue={year}
          bestPick={bestChoice}
          assetAAmount={bestValue?.y || 0}
          assetBAmount={bestValue?.y || 0}
          onPillPress={(value) => setYear(value)}
          pillOptions={[
            {
              label: '10 Years',
              value: 10
            }, {
              label: '20 Years',
              value: 20
            }, {
              label: '30 Years',
              value: 30
            }
          ]}
        />

        <View style={styles.chartsContainer}>
          <Carousel
            height={containerWidth}
            data={[
              <OpportunityCostByPeriodChart
                opportunityCostData={opportunityCostData}
              />,
              <OpportunityCostEquityChart
                assetValueData={assetValueData}
                balanceData={balanceData}
                equityData={equityData}
                totalInvestedData={totalInvestedData}
                type={params.type}
              />,
            ]}
          />
        </View>

        <View style={styles.tableContainer} onLayout={(event) => onLayout(event)}>
          <Text style={styles.tableText}>
            This result takes into account what the price difference between your choices, including any difference in down payment, interest associated with financing costs, and difference in monthly cash flow, could grow into if invested instead - using actual historical return data from S&P 500.
          </Text>

          <Link onPress={() => setVisible(true)} style={styles.link}>Learn More</Link>

          <TableScreen result={result} bestChoice={bestChoice} width={containerWidth} />
        </View>
      </ScrollView>

      <Modal
        visible={visible}
        setVisible={setVisible}
        closeable
        style={styles.infoModal}
        onClose={() => setVisible(false)}
      >
        <HeaderLabel style={styles.modalHeader}>Opportunity Cost</HeaderLabel>
        <Text style={styles.modalText}>
          If you buy a more expensive home, vehicle, etc. more of your cash will be locked up in that asset, meaning you will have less cash available to invest in the stock market. The rate of return on the stock market, especially over a period of 10 years, has historically been higher than the rate of return on real estate. Vehicles are terrible investments - with few exceptions. 99% will only lose value and have a negative rate of return. So a more expensive choice of home or car (today) will immediately reduce the amount of money you can invest in the highest returning buckets (stocks). Unless you buy a home that rapidly increases in value or a car that becomes an instant collectible, your net worth will not grow as fast as it could. In addition, a more expensive home or car will hurt your monthly budget. Sometimes by a huge amount. Your mortgage (principal, interest, taxes, and insurance) or auto payment will be higher each month. This means your free cash flow will be lower. You will have less free cash to invest in the stock market each month - a second reason your net worth will not grow as fast as it could.
        </Text>
      </Modal>
    </>
  )
}

export default function OpportunityCostResultScreen({ navigation, route }) {
  const { params } = route

  useLayoutEffect(() => {
    navigation.setOptions({ subtitle: map(params.type.split(' '), capitalize).join(' ') })
  })

  return (
    <Container>
      <OpportunityCostResults params={params} />
    </Container>
  );
}
