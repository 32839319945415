import { useEffect, useState, useLayoutEffect } from 'react'
import { View, ScrollView, Platform, StyleSheet } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { map } from 'lodash'
import { get } from 'http'
import SearchInput from './components/SearchInput'
import SearchFinn from './components/colleges/SearchFinn'

import Container from './components/Container'
import CollegeListItem from './components/CollegeListItem'

import formatPercent from './functions/formatPercent'
import rangeOrSingle from './functions/rangeOrSingle'
import formatCurrency from './functions/formatCurrency'

import FilterBanner from './components/collegeExplore/FilterBanner'
import SortBanner from './components/collegeExplore/SortBanner'
import SearchOptionsSlideupDrawer from './components/collegeExplore/SearchOptionsSlideupDrawer'
import Button from './components/Button'
import FilterButton from './components/FilterButton'

import { lightestgray, white } from './styles/colors'
import Divider from './components/Divider'
import { DEFAULT_SPACING } from './styles'
import CollegeExploreFilterScreen from './CollegeExploreFilterScreen'
import Modal from './components/Modal'

const basicStyles = {
  container: {
    backgroundColor: lightestgray,
    flex: 1
  },
  modalContainer: {
    padding: 0,
    flex: 1
  },
  searchFormWrapper: {
    backgroundColor: 'white',
    paddingHorizontal: DEFAULT_SPACING
  },
  searchForm: { marginRight: 50 },
  divider: {
    padding: 5,
    backgroundColor: white
  },
  filterButton: {
    position: 'absolute',
    right: 0
  },
  collegeList: {
    paddingTop: 12,
    paddingBottom: 24
  },
  modal: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    padding: 12
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    modalContainer: {
      padding: 0,
      flex: 1
    },
    searchFormWrapper: {
      backgroundColor: 'none',
      paddingHorizontal: 0
    },
    searchForm: {
      marginRight: 50,
      marginBottom: 12,
      backgroundColor: 'white'
    },
    divider: { display: 'none' },
    collegeList: {
      paddingTop: 0,
      paddingBottom: 12
    },
    modal: {
      width: '60%',
      height: '60%',
      borderRadius: 8
    }
  }
})

const sortOptions = [
  { label: 'Alphabetical', value: 'name' },
  { label: 'Admissions Rate', value: 'admission_rate' },
  { label: 'Enrollment Rate', value: 'enrollment' },
  { label: 'SAT Range', value: 'sat' },
  { label: 'ACT Range', value: 'act' },
  { label: 'Average Net Cost of Tuition', value: 'tuition_avg' },
  { label: 'Default Rate', value: 'graduate_default_rate' },
  { label: 'Median Debt', value: 'graduate_median_debt' },
  { label: 'Median Earnings After Graduation', value: 'earning_6year' }
]

const subheader = (college, sortBy) => {
  const {
    satmt25,
    satmt75,
    satvr25,
    satvr75,
    sat_avg_all,
    sat_avg,
    actcm25,
    actcm75,
    actcm_avg_all,
    actcm_avg
  } = college

  const satRange = rangeOrSingle(
    satmt25 + (satvr25 || 0),
    (satmt75 || 0) + (satvr75 || 0),
    sat_avg_all || sat_avg
  )
  const actRange = rangeOrSingle(actcm25, actcm75, actcm_avg_all || actcm_avg)

  // convert this to a switch statement
  switch (sortBy) {
    case 'name':
      return `${college.city}, ${college.state}`
    case 'admission_rate':
      return formatPercent(college.admission_rate * 100, 1)
    case 'enrollment':
      return `${college.enrollment} students`
    case 'sat':
      return satRange
    case 'act':
      return actRange
    case 'tuitionfee_in':
      return college.tuitionfee_in ? `$${formatCurrency(college.tuitionfee_in)}` : 'Unknown'
    case 'graduate_default_rate':
      return college.graduate_default_rate
        ? formatPercent(college.graduate_default_rate * 100, 1)
        : 'Unknown'
    case 'graduate_median_debt':
      return college.graduate_median_debt
        ? `$${formatCurrency(college.graduate_median_debt)}`
        : 'Unknown'
    case 'earning_6year':
      return college.earning_6year ? `$${formatCurrency(college.earning_6year)}` : 'Unknown'
    default:
      return `${college.city}, ${college.state}`
  }
}

export default function CollegeExploreSearch({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [searchText, setSearchText] = useState('')
  const [collegeList, setCollegeList] = useState([])
  const [loading, setLoading] = useState(false)
  const [sort, setSort] = useState('asc')
  const [sortBy, setSortBy] = useState(sortOptions[0].value)
  const [sortByLabel, setSortByLabel] = useState(sortOptions[0].label)
  const [isShowSortOptions, setIsShowSortOptions] = useState(false)

  const { query } = route?.params || ''
  const [filterQuery, setFilterQuery] = useState(query)
  const [numFilters, setNumberOfFilters] = useState(0)
  const [nextPage, setNextPage] = useState(query)
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false)

  const getCollegesByPage = async (page) => {
    setLoading(true)
    try {
      const res = await get(page)
      setNextPage(res.data.next)
      setLoading(false)
      return res.data.results
    } catch (e) {
      console.error('ERROR: ', e)
    }
  }

  const getNextPage = async (currentPage) => {
    const newColleges = await getCollegesByPage(currentPage)
    if (newColleges && newColleges.length > 0) setCollegeList([...collegeList, ...newColleges])
  }

  const getFirstPage = async (
    pageUrl = 'api/v1/colleges/search/',
    sortDirection = 'asc',
    sortOption = 'name',
    searchIdx = ''
  ) => {
    try {
      const ordering = sortOption
        ? `ordering=${sortDirection === 'asc' ? '' : '-'}${sortOption}`
        : ''
      const minLength = pageUrl ? 1 : 3
      let searchParam = ''
      let orderParam = ''
      if (searchIdx.length >= minLength) {
        searchParam = `${pageUrl.match(/\?/) ? '&' : '?'}name=${searchIdx}`
        orderParam = `&${ordering}`
      } else {
        orderParam = (pageUrl.match(/\?/) ? '&' : '?') + ordering
      }
      const queryParam = pageUrl + searchParam + orderParam
      const colleges = await getCollegesByPage(queryParam)
      setCollegeList(colleges)
    } catch (error) {
      console.error(error.response, ' -- error')
    }
  }

  const getNumFilters = (query) => {
    if (query) {
      if (query.match(/gt/g)) {
        return query.match(/=/g).length - query.match(/gt/g).length
      }
      if (query.match(/=/g)) {
        return query.match(/=/g).length
      }
      return 0
    }
    return 0
  }

  useEffect(() => {
    query && setFilterQuery(query)
  }, [query])

  useEffect(() => {
    const pageUrl = filterQuery ?? 'api/v1/colleges/search/'
    getFirstPage(pageUrl, sort, sortBy, searchText)
    setNumberOfFilters(getNumFilters(filterQuery))
  }, [filterQuery, sort, sortBy, searchText])

  useEffect(() => {
    const { label } = sortOptions.find((option) => option.value === sortBy)
    setSortByLabel(label)
  }, [sortBy])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      title: 'Explore Colleges'
    })
  }, [navigation])

  const handleFilterButtonPress = () => {
    if (isNotWeb) navigation.navigate('Modal', { screen: 'College Explore Filter' })
    else setIsOpenFilterModal(true)
  }

  return (
    <View style={styles.container}>
      <View style={styles.searchFormWrapper}>
        <SearchInput
          autoFocus
          value={searchText}
          onChange={(value) => {
            setSearchText(value || null)
          }}
          onCancel={() => {
            setSearchText(null)
          }}
          style={{ marginRight: isNotWeb ? 0 : 50, backgroundColor: isNotWeb ? '' : white }}
        />

        <FilterButton
          style={styles.filterButton}
          iconSize={24}
          onPress={() => handleFilterButtonPress()}
        />
      </View>

      <Divider style={styles.divider} />

      {numFilters > 0 && (
        <FilterBanner
          numFilters={numFilters}
          onPress={() => {
            setFilterQuery(undefined)
            setNumberOfFilters(0)
            setNextPage(undefined)
            setCollegeList([])
          }}
        />
      )}

      {numFilters > 0 && (
        <SortBanner
          sortBy={sortByLabel}
          sort={sort}
          onSortByPress={() => setIsShowSortOptions(true)}
          onSortPress={() => setSort((prev) => (prev === 'asc' ? 'desc' : 'asc'))}
        />
      )}

      <View style={{ flex: 1, overflow: isNotWeb ? 'visible' : 'auto' }}>
        <ScrollView style={styles.collegeList}>
          {!loading &&
            map(collegeList, (col) => (
              <CollegeListItem
                key={`college-${col.id}`}
                onPress={() => {
                  navigation.navigate(isNotWeb ? 'College Explore' : 'College Explore Page', {
                    college: col
                  })
                }}
                navigatable
                college={col}
                subheader={subheader(col, sortBy)}
              />
            ))}

          {!!nextPage && <Button onPress={() => getNextPage(nextPage)}>Load More Results</Button>}

          {collegeList.length == 0 && filterQuery && !searchText && (
            <SearchFinn header="No Results" information="Your filters did not match any schools" />
          )}

          {collegeList.length == 0 && !filterQuery && searchText && searchText.length > 3 && (
            <SearchFinn header="No Results" information="Try searching for another school..." />
          )}
        </ScrollView>

        <SearchOptionsSlideupDrawer
          options={sortOptions}
          selected={sortBy}
          visible={isShowSortOptions}
          setVisible={setIsShowSortOptions}
          onPress={(value) => {
            setIsShowSortOptions(false)
            setSortBy(value)
          }}
        />
      </View>
      <Modal
        visible={isOpenFilterModal}
        style={styles.modal}
        onClose={() => setIsOpenFilterModal(false)}
      >
        <CollegeExploreFilterScreen
          navigation={navigation}
          onClose={() => setIsOpenFilterModal(false)}
        />
      </Modal>
    </View>
  )
}
