import { View, Text, StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { isEmpty, isUndefined } from 'lodash'
import {
  DEFAULT_SPACING,
  danger,
  lightestgray,
  gray,
  primary,
  secondary,
  warning,
  lightgray
} from '../styles'
import ProgressBar from './ProgressBar'
import CircleIcon from './CircleIcon'
import { ICON_TYPE } from '../constants'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

const styles = StyleSheet.create({
  budgetCardComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: DEFAULT_SPACING
  },
  amount: {
    color: secondary,
    fontSize: 12
  },
  remainingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: DEFAULT_SPACING / 2
  },
  pill: {
    backgroundColor: lightestgray,
    borderRadius: 14,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginLeft: DEFAULT_SPACING
  },
  pillText: {
    fontSize: 10,
    color: gray
  }
})

function determineBarColor(actualAmount, budgetAmount, isIncome) {
  let barColor = lightestgray

  if (actualAmount > budgetAmount) {
    barColor = danger
  } else if (actualAmount > budgetAmount * 0.75) {
    barColor = warning
  } else if (actualAmount < budgetAmount && actualAmount > 0) {
    barColor = primary
  }

  if (isIncome && actualAmount) barColor = primary

  return barColor
}

function determinePercentage(actualAmount, budgetAmount) {
  return budgetAmount > 0 && actualAmount > 0
    ? (actualAmount / budgetAmount) * 100
    : 100
}

export default function ProBudgetItem({
  onPress,
  style,
  iconProps,
  title,
  titleStyle,
  budgetAmount,
  actualAmount,
  count,
  isIncome = false
}) {
  const barColor = determineBarColor(actualAmount, budgetAmount, isIncome)
  const percent = determinePercentage(actualAmount, budgetAmount)

  return (
    <View style={[{ paddingBottom: DEFAULT_SPACING }, style]} onPress={onPress}>
      <View style={styles.budgetCardComponent}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {!isEmpty(iconProps) && !!iconProps.icon && (
              <CircleIcon
                size={20}
                {...iconProps}
                style={{
                  ...(!!iconProps.style && iconProps.style),
                  marginRight: DEFAULT_SPACING / 2
                }}
              />
            )}
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={titleStyle}>{title}</Text>

              {!isUndefined(count) && (
                <View style={styles.pill}>
                  <Text style={styles.pillText}>{count}</Text>
                </View>
              )}
            </View>
          </View>

          {onPress && (
            <FontAwesomeIcon color={lightgray} size={14} icon="chevron-right" />
          )}
        </View>
      </View>

      <ProgressBar
        width="100%"
        percent={percent}
        progressBarColor={barColor}
        backgroundBarColor={lightestgray}
        height={9}
      />
    </View>
  )
}

ProBudgetItem.defaultProps = {
  style: {},
  iconProps: { style: {} },
  titleStyle: {},
  budgetAmountStyle: {}
}

ProBudgetItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  style: PropTypes.object,
  iconProps: ICON_TYPE,
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.object,
  budgetAmount: PropTypes.number.isRequired,
  budgetAmountStyle: PropTypes.object,
  actualAmount: PropTypes.number.isRequired
}
