import { View, Text, StyleSheet } from 'react-native'

import { grayblue } from '../../styles/colors'

const styles = StyleSheet.create({
  tabContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabText: {
    textAlign: 'center',
    marginTop: 6,
    fontSize: 14,
    width: '100%'
  }
})

export default function TopTabItem({ isFocused, label, topTabBarTextStyle, topTabBarStyle, tabWidth }) {
  return (
    <View style={[styles.tabContainer, { width: tabWidth }, topTabBarStyle]}>
      <Text style={[styles.tabText, { color: isFocused ? grayblue : '#6A7B7E', fontWeight: isFocused ? '600' : '500' }, topTabBarTextStyle]}>
        {label}
      </Text>
    </View>
  )
}
