import * as Yup from 'yup'

import { REQUIRED, INVALID_EMAIL } from './constants'

export const Fields = {
  email: 'email',
  password: 'password'
}

export const ValidationSchema = Yup.object().shape({
  [Fields.email]: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED),
  [Fields.password]: Yup.string()
    .nullable()
    .required(REQUIRED)
})

export const InitialValues = {
  [Fields.email]: '',
  [Fields.password]: ''
}
