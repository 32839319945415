import { View, StyleSheet, Text } from 'react-native'
import { darkblue, gunmetal } from '../styles'

const styles = StyleSheet.create({
  spaceAround: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  dot: (size) => ({
    width: size ? size : 8,
    height: size ? size : 8,
    borderRadius: size ? size / 2 : 8 / 2,
    marginRight: 4,
    marginTop: 3
  }),
  mediumText: {
    fontSize: 10,
    color: gunmetal
  },
  subtext: {
    fontSize: 12,
    fontWeight: '600',
    color: darkblue
  }
})

export default function Dot({ text, subtext, color, dotSize, textStyle={}  }) {
  return (
    <View style={styles.spaceAround}>
      <View style={[styles.dot(dotSize), { backgroundColor: color }]} />

      <View>
        <Text style={[styles.mediumText, textStyle]}>{text}</Text>
        { subtext && <Text style={styles.subtext}>{subtext}</Text> }
      </View>
    </View>
  )
}
