import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import {
  Animated,
  View,
  ScrollView,
  StyleSheet,
  Platform,
  useWindowDimensions
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { useIsFocused } from '@react-navigation/native'
import { find, last } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'http'
import Banner from './components/dashboard/Banner'

import PreAuthScreen from './components/dashboard/PreAuthScreen'
import PostAuthScreen from './components/dashboard/PostAuthScreen'
import Tout from './components/dashboard/Tout'

import CongratsSlideupDrawer from './components/emergency-preparedness/CongratsSlideupDrawer'

import { plansSelector, currentUserSelector } from './selectors'

import { getAccounts, getCurrentUser } from './actions'

import { lightestgray } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const basicStyles = {
  container: { flex: 1, backgroundColor: lightestgray, position: 'relative' },
  listItem: { marginHorizontal: DEFAULT_SPACING },
  scrollView: {
    zIndex: 200,
    elevation: 200,
    backgroundColor: lightestgray
  },
  scrollViewContainer: { paddingBottom: 200, }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  { [minSize(DEVICE_SIZES.LG)]: { scrollView: { paddingHorizontal: DEFAULT_SPACING } }, }
)

const isNotWeb = Platform.OS !== 'web'

function findPrimaryPlan(plans) {
  const primaryPlan = find(plans, (plan) => plan.is_primary_plan)
  return primaryPlan
}

export default function DashboardScreen({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const window = useWindowDimensions()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => currentUserSelector(state))
  const [primaryPlan, setPrimaryPlan] = useState({})
  const plans = useSelector((state) => plansSelector(state))
  const paddingTop = isNotWeb
    ? window.height / 8
    : window.width < 1024
      ? currentUser.sponsors.length > 0 ? 476 : 360
      : currentUser.sponsors.length > 0 ? 588 : 488
  const isFocused = useIsFocused()

  const [emergencyPrepardness, setEmergencypreparedness] = useState({})

  async function fetchPrimaryPlan() {
    if (!currentUser) return
    if (!currentUser.primary_plan) return
    if (!currentUser.primary_plan.id) return

    try {
      const primaryPlanResponse = await get(
        `/api/v1/plans/${currentUser.primary_plan.id}/`
      )
      const plan = findPrimaryPlan(plans)

      setPrimaryPlan({ ...plan, ...primaryPlanResponse.data })

      const emergencyPreparednessResponse = await get(
        '/emergency-preparedness/'
      )
      setEmergencypreparedness(emergencyPreparednessResponse.data)
    } catch (e) {
      console.warn(e, e.response)
    }
  }

  useEffect(() => {
    if (isFocused) dispatch(getCurrentUser())
  }, [isFocused])

  useEffect(() => {
    fetchPrimaryPlan()
    dispatch(getAccounts())
  }, [currentUser])

  let circleColor = 'white'

  if (emergencyPrepardness.status === 'LEVEL_2') {
    circleColor = '#FBBD29'
  } else if (emergencyPrepardness.status === 'LEVEL_3') {
    circleColor = '#43CF62'
  } else {
    circleColor = '#F2285B'
  }

  let status = emergencyPrepardness.status
    ? emergencyPrepardness.status
    : 'LEVEL_0'
  status = status.replace('_', ' ')

  const offset = useRef(new Animated.Value(0)).current

  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false })
  }, [navigation])

  return (
    <View style={styles.container}>
      <Banner
        currentUser={currentUser}
        animatedValue={offset}
        navigation={navigation}
        primaryPlan={primaryPlan}
        monthlyContribution={primaryPlan.annual_contribution_amount / 12}
        goalAmount={primaryPlan.savings_goal}
      />
      <ScrollView
        contentContainerStyle={styles.scrollViewContainer}
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [
            { nativeEvent: { contentOffset: { y: offset } } }
          ],
          { useNativeDriver: false }
        )}
        style={[styles.scrollView, { paddingTop }]}
      >
        {currentUser.is_fake ? (
          <PreAuthScreen currentUser={currentUser} navigation={navigation} />
        ) : (
          <PostAuthScreen currentUser={currentUser} navigation={navigation} />
        )}
        <Tout />
      </ScrollView>

      <CongratsSlideupDrawer statusNumber={last(status.split(' '))} />
    </View>
  )
}
