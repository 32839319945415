import { View, Text, StyleSheet } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Link from '../Link'
import { DEFAULT_SPACING, info, rgba, secondary } from '../../styles'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING / 2
  },
  label: {
    paddingLeft: 10,
    fontWeight: '400',
    fontSize: 12,
    marginRight: DEFAULT_SPACING / 2,
    color: secondary
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  link: {
    fontSize: 14,
    fontWeight: 'bold'
  }
})

export default function SortBanner({ onSortByPress, onSortPress, sort, sortBy }) {
  return (
    <View style={styles.container}>
      <View style={styles.inline}>
        <Text style={styles.label}>Sort By:</Text>
        <Link style={styles.link} onPress={onSortByPress}>{sortBy}</Link>
      </View>
      <Link iconColor={info} icon={sort == 'desc' ? 'arrow-up-wide-short' : 'arrow-up-short-wide'} onPress={onSortPress} />
    </View>
  )
}
