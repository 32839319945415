import { Platform, View, StyleSheet, Text, Image } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import HeaderLabel from '../HeaderLabel'

import Card from '../Card'
import { DEFAULT_SPACING, darkblue, gunmetal, lightgray } from '../../styles'
import Link from '../Link'

const basicStyles = {
  container: {},
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: DEFAULT_SPACING
  },
  subtitle: {
    fontSize: 14,
    color: gunmetal,
    marginBottom: DEFAULT_SPACING
  },
  toolsList: {},
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING * 2,
    marginBottom: DEFAULT_SPACING,
    borderRadius: 12
  },
  textContainer: { flex: 1 },
  label: {
    fontWeight: '600',
    fontSize: 16,
    marginBottom: 10,
    color: darkblue
  },
  description: {
    flexWrap: 'wrap',
    fontSize: 12,
    color: gunmetal,
    letterSpacing: -0.5,
    width: '80%'
  },
  image: {
    height: 105,
    width: 128,
    marginRight: DEFAULT_SPACING * 2
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
      container: { width: '100%', },
      header: {
        marginTop: 32,
        marginBottom: 12,
      },
      toolsList: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      },
      card: {
        width: '100%',
        marginVertical: 10,
      }
    },
    [minSize(DEVICE_SIZES.LG)]: { card: { width: 'calc(50% - 6px)', } }
  }
)

export default function ExploreCard({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()

  const options = [{
    label: 'College Explore',
    description: 'Tuition and cost, net price, student debt, demographics and more',
    image: require('../../assets/college-explore-person.png'),
    onPress: () => navigation.navigate('Modal', { screen: 'College Explore Intro' })
  }, {
    label: 'Career Explore',
    description: 'Compare salaries, growth outlook, job satisifaction and more',
    image: require('../../assets/career-explore-person.png'),
    onPress: () => navigation.navigate('Modal', { screen: 'Career Explore Intro' })
  }, {
    label: 'Calculate',
    description: 'A powerful suite of finanical calculators',
    image: require('../../assets/calculate-person.png'),
    onPress: () => navigation.navigate('Modal', { screen: 'Calculators' })
  }]

  const handleLinkPress = () => {
    navigation.navigate('Tools')
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <HeaderLabel>Explore</HeaderLabel>
        <Link onPress={() => handleLinkPress()}>View All Tools</Link>
      </View>
      <Text style={styles.subtitle}>
        Explore our suite of tools designed to help you make informed financial decisions.
      </Text>

      <View style={styles.toolsList}>
        {map(options, (option) => (
          <Card style={styles.card} key={option.label} onPress={option.onPress}>
            <Image
              style={styles.image}
              source={option.image}
              resizeMode="contain"
            />
            <View style={styles.textContainer}>
              <Text style={styles.label}>{option.label}</Text>
              <Text style={styles.description}>{option.description}</Text>
            </View>

            <FontAwesomeIcon
              icon={['fas', 'circle-chevron-right']}
              size={20}
              color={lightgray}
            />
          </Card>
        ))}
      </View>
    </View>
  )
}
