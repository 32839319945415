import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

function objectOrId(objOrId) {
  if (objOrId && objOrId.id) {
    return objOrId.id
  }
  return null
}

export function formatFinancialPayload(form) {
  const formParam = { ...form }

  // django doesn't need these
  delete formParam.id
  delete formParam.created
  delete formParam.modified
  delete formParam.savings_goal_annual_contribution

  // django requires occupation as the integer
  if (formParam.input_custom_career) {
    formParam.occupation_id = null
  } else if (!formParam.occupation_id && formParam.occupation) {
    formParam.occupation_id = objectOrId(form.occupation)
  }

  if (!form.city_id && form.city) {
    formParam.city_id = objectOrId(form.city)
  }

  // delete these if they are null
  // if (!formParam.occupation || formParam.input_custom_career) delete formParam.occupation
  // if (form.city) delete formParam.city

  formParam.birth_date = form.birth_date ? moment(form.birth_date).format('YYYY-MM-DD') : null
  // if null, delete the attr
  if (!formParam.birth_date) delete formParam.birth_date

  formParam.loan_rate = formParam.loan_rate ? formParam.loan_rate : 0
  formParam.loan_balance = formParam.loan_balance ? parseFloat(String(formParam.loan_balance).replace(/\D+/g, '')) : 0
  formParam.loan_period = formParam.loan_period ? parseFloat(formParam.loan_period) : 0

  // ensure that if the user filled out rate, balance, period then make sure we assign that they have loans
  formParam.have_loans = !!(formParam.loan_rate || formParam.loan_balance || formParam.loan_period)

  formParam.additional_income_amount = formParam.additional_income_amount !== '' ? (String(formParam.additional_income_amount) || '').replace(/\D+/g, '') : null
  formParam.student_loan_forgiveness = formParam.student_loan_forgiveness !== '' ? (String(formParam.student_loan_forgiveness) || '').replace(/\D+/g, '') : null

  formParam.additional_income_amount = formParam.additional_income_amount !== '' ? (String(formParam.additional_income_amount) || '').replace(/\D+/g, '') : null
  formParam.student_loan_forgiveness = formParam.student_loan_forgiveness !== '' ? (String(formParam.student_loan_forgiveness) || '').replace(/\D+/g, '') : null

  if (formParam.additional_income_amount === '') formParam.additional_income_amount = 0
  if (formParam.student_loan_forgiveness === '') formParam.student_loan_forgiveness = 0
  return formParam
}
