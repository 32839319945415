import { useState } from 'react'
import { StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'
import IntroScreenPro from './components/IntroScreenPro'
import { currentProPlanSelector } from './selectors'
import SetPrimaryPlanSlideupDrawer from './components/plans/SetPrimaryPlanSlideupDrawer'

const styles = StyleSheet.create({ container: { alignItems: 'center' } })

export default function CreateBudgetSuccessScreen({ navigation }) {
  const currentPlan = useSelector(currentProPlanSelector)
  const [slideupVisible, showSlideup] = useState(false)

  const goToNextStep = () => {
    navigation.navigate('Main', { screen: 'Plan Builder Intro' })
  }

  const completeSteps = async () => {
    showSlideup(true)
  }

  return (
    <>
      <IntroScreenPro
        style={styles.container}
        image={require('./assets/finn/party.png')}
        backgroundImage={require('./assets/budget/confetti-bg.png')}
        buttonText="Done"
        showHeader={false}
        title="Congratulations!"
        subtitle={"You've added a budget that you can now track your income and expenses towards!"}
        onPress={completeSteps}
      />
      <SetPrimaryPlanSlideupDrawer
        plan={currentPlan}
        visible={slideupVisible}
        setVisible={showSlideup}
        onClose={goToNextStep}
      />
    </>
  )
}
