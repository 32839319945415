import Card from './Card'
import CardHeader from './CardHeader'

import formatPercent from '../../functions/formatPercent'
import formatCurrency from '../../functions/formatCurrency'
import { primary } from '../../styles/colors'

export default function StudentDebtProfileCard({ college }) {
  const header = (
    <CardHeader
      rightIcon="dollar-sign"
      rightColor={primary}
      rightHeader={college.graduate_median_debt ? `$${formatCurrency(college.graduate_median_debt)}` : '-'}
      rightSubheader={'Median Debt\nUpon Graduation'}

      leftIcon="calendar"
      leftColor="#DE8D3F"
      leftHeader={college.grad_debt_mdn10yr ? `$${formatCurrency(college.grad_debt_mdn10yr)}` : '-'}
      leftSubheader={'Median Monthly\nLoan Payment'}
    />
  )

  const rows = [{
    label: 'Total Debt Range',
    value: college.cuml_debt_p10 && college.cuml_debt_p90 ? `$${formatCurrency(college.cuml_debt_p10)} - $${formatCurrency(college.cuml_debt_p90)}` : '-',
    tooltipBody: 'Total Debt Range shows the level of debt for the middle 50% of students.'
  }, {
    label: 'Receiving Federal Loans',
    value: college.ftftpctfloan_pooled_supp ? formatPercent(college.ftftpctfloan_pooled_supp * 100) : '-',
    tooltipBody: 'The % of students attending a school who have received federal loans. There are four main types of federal student loans: Direct Subsidized, Direct Unsubsidized, Direct PLUS, and Direct Consolidation. A majority of schools in the U.S. accept federal student loans. Some private schools do not accept or certify any loans under the Federal Direct Student Loan Program, meaning that students who need to borrow would have to do so through private lenders in those situations.'
  }, {
    label: 'Receiving Pell Grants',
    value: college.ftftpctpell ? formatPercent(college.ftftpctpell * 100) : '-',
    tooltipBody: 'Pell Grants are a form of need-based aid, provided by the federal government. Unlike loans, students do not have to repay Pell Grants, making them one of the absolute best forms of financial aid that a student can receive. To qualify for a Pell Grant, students must complete a FAFSA application in a timely manner and their household must not exceed the income and asset thresholds. From a demographic perspective, schools with a high % of students receiving Pell Grants tend to have a more socioeconomically diverse enrollment.'
  }, {
    label: 'Median Debt',
    value: college.firstgen_debt_mdn ? `$${formatCurrency(college.firstgen_debt_mdn)}` : '-',
    tooltipBody: 'This represents the median debt of all students, including those currently earning their degree, graduates, and non-graduates.'
  }, {
    label: 'Default Rate',
    value: college.graduate_default_rate ? formatPercent(college.graduate_default_rate * 100) : '-',
    tooltipBody: 'Is a school a good financial decision for its students? If loans are needed to attend, are they worth it? Troutwood includes Default Rate data because it gives a sense of how many students who attend a school are unable to earn enough income, after attending, to manage their student loan payments. The median Default Rate for all schools is around 10%. While Troutwood does not recommend any particular schools, we are concerned by schools with high Default Rates and like to see numbers <5%. Our general feeling: schools with low Default Rates seem to do a better job of making good on the implied promise to help students attain positive financial outcomes. If you are considering a school with a high Default Rate, do your research, ask a lot of questions about why the numbers are poor, and use it as leverage to negotiate for grants and scholarships.'
  }]

  return (
    <Card
      title="Student Debt Profile"
      header={header}
      rows={rows}
    />
  )
}
