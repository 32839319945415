import { Text, StyleSheet } from 'react-native'
import { lightblue } from '../styles/colors'
import TouchableOpacity from './TouchableOpacity'

const styles = StyleSheet.create({
  closeButton: (color) => ({
    color: color || lightblue,
    fontSize: 14,
    marginHorizontal: 12
  })
})

export default function CloseButton({ onPress, color }) {
  return (
    <TouchableOpacity onPress={onPress}>
      <Text style={styles.closeButton(color)}>Close</Text>
    </TouchableOpacity>
  )
}
