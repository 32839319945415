import * as Yup from 'yup'

import {
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX
} from './constants'

export const Fields = { custom_auto_cost: 'custom_auto_cost' }

export const ValidationSchema = Yup.object().shape({
  [Fields.custom_auto_cost]: Yup.number().positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(10000, `${INVALID_MAX}10000`)
    .nullable()
})

export const InitialValues = { [Fields.custom_auto_cost]: null }
