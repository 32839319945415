import { useState, useEffect, useLayoutEffect } from 'react'
import {
  ActivityIndicator,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import handleError from 'functions/handleError'

import { map } from 'lodash'
import { get } from 'http'
import Header from 'components/Header'
import ProgressBar from 'components/ProgressBar'
import Pill from 'components/Pill'
import ListOptionItem from 'components/ListOptionItem'
import DebtRankingPageHeader from 'components/debtRank/DebtRankingPageHeader'

import formatCurrency from 'functions/formatCurrency'
import formatPercent from 'functions/formatPercent'

import { useIsFocused } from '@react-navigation/native'

import { loadingSelector, } from 'selectors'

import { setLoading } from 'actions'

import { DEFAULT_SPACING, info, primary, white, rgba, darkblue, red, lightestgray, secondary } from 'styles'
import ToolsHeaderWrapper from 'components/tools/ToolsHeaderWrapper'
import moment from 'moment'
import proApplicationEndpoints from '../../api/proApplicationEndpoints'
import Card from '../Card'
import { gunmetal } from '../../styles'
import HeaderLabel from '../HeaderLabel'
import CalendarItem from '../CalendarItem'
import Link from '../Link'
import { bsAccountsSelector } from '../../selectors'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: lightestgray
  },
  header: { backgroundColor: 'transparent' },
  headerText: {
    textAlign: 'center',
    padding: DEFAULT_SPACING,
    color: gunmetal,
    fontSize: 16,
    width: '80%'
  },
  debtFreeHeader: {
    color: darkblue,
    fontSize: 16
  },
  subtitle: {
    fontSize: 12,
    color: gunmetal
  },
  link: { marginBottom: DEFAULT_SPACING },
  linkLeft: { justifyContent: 'flex-start' },
  iconStyle: { color: info },
  tooltipLink: {
    color: info,
    marginTop: DEFAULT_SPACING,
    fontSize: 12
  },
  finn: { height: 200, },
  scrollView: {
    paddingVertical: DEFAULT_SPACING,
    paddingBottom: DEFAULT_SPACING * 5
  },
  debtContainer: {
    paddingHorizontal: DEFAULT_SPACING,
    paddingVertical: DEFAULT_SPACING * 2,
  },
  addButton: {},
  debtFreeCard: {
    marginHorizontal: DEFAULT_SPACING,
    padding: DEFAULT_SPACING,
    borderRadius: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  calendarItem: {
    position: 'relative',
    width: 80,
  },
  debtFreeCardContent: { width: '70%', },
  listItemLineItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 36
  },
  listItemLineItemLabel: {
    fontSize: 12,
    color: gunmetal,
    marginRight: 6
  },
  listItemLineItemLarge: {
    fontSize: 14,
    fontWeight: '500',
    color: darkblue,
  },
  listItemLineItemValue: {
    fontSize: 12,
    color: darkblue,
    fontWeight: '500'
  },
  progressBarContainer: {
    position: 'relative',
    marginVertical: 24
  },
  progressBarLabel: {
    fontSize: 12,
    color: gunmetal,
    position: 'absolute',
    right: 0,
    top: 12
  },
  notSeeingAccount: {
    fontSize: 12,
    color: gunmetal,
    textAlign: 'center',
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        padding: 40,
        overflow: 'auto',
        backgroundColor: lightestgray,
        paddingRight: '50%',
      },
      scrollView: {
        paddingBottom: DEFAULT_SPACING,
        height: 'calc(100% - 60px)',
        overflow: 'auto',
      },
      addButton: {
        width: 240,
        left: 20,
      }
    }
  }
)

const typeIcons = {
  REVOLVING_LOAN: 'credit-card',
  TERM_LOAN: 'money-bill',
}

const isNotWeb = Platform.OS !== 'web'

function DebtItem({ debt, index, last, onPress }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  const { name, interestRate, amortizationType, minMonthlyPayment, payoffDate, payoffProgress } = debt
  const { principalBorrowed, unpaidBalance } = payoffProgress || {}
  const icon = typeIcons[amortizationType]
  const color = icon == 'credit-card' ? red : info

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ backgroundColor: primary, borderRadius: 25, height: 25, width: 25, justifyContent: 'center', alignItems: 'center' }}>
          {index == 0
            && <FontAwesomeIcon size={14} color={white} icon="crosshairs" />}
          {index != 0 && <Text style={{ fontSize: 16, color: white }}>{index + 1}</Text>}
        </View>

        {!last && <FontAwesomeIcon style={{ position: 'absolute', bottom: -10 }} size={18} color="#D0D9DE" icon="arrow-down" />}
      </View>

      <View style={{ flex: 1, marginLeft: DEFAULT_SPACING }}>
        <ListOptionItem
          label={name}
          iconName={icon}
          iconColor={color}
          iconBackgroundColor={rgba(color, 0.1)}
          iconSize={14}
          iconType="far"
          checked
          onPress={onPress}
        >
          <View style={[styles.listItemLineItem, { top: -10 }]}>
            <Text style={[styles.listItemLineItemLabel, styles.listItemLineItemLarge]}>
              Interest Rate:
            </Text>

            <Pill small square color={darkblue}>
              {formatPercent(parseFloat(interestRate), 2)}
            </Pill>
          </View>

          <View style={styles.listItemLineItem}>
            <Text style={styles.listItemLineItemLabel}>
              Estimated Pay Off Date:
              <Text style={styles.listItemLineItemValue}>
                {' '}
                {moment(payoffDate).format('MMM DD, YYYY')}
              </Text>
            </Text>
          </View>

          <View style={styles.listItemLineItem}>
            <Text style={styles.listItemLineItemLabel}>
              Min. Monthly Payment
              <Text style={styles.listItemLineItemValue}>
                {' '}
                {formatCurrency(minMonthlyPayment, { showDollarSign: true })}
              </Text>
            </Text>
          </View>

          { (principalBorrowed || unpaidBalance) && (
            <View style={styles.progressBarContainer}>
              <ProgressBar
                percent={
                  ((principalBorrowed - unpaidBalance) / principalBorrowed) * 100
                }
              />
              <Text style={styles.progressBarLabel}>
                {formatCurrency(principalBorrowed, { showDollarSign: true })}
              </Text>
            </View>
          )}
        </ListOptionItem>
      </View>
    </View>
  )
}

export default function DebtRank({ navigation }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const dispatch = useDispatch()

  const [myDebt, setMyDebt] = useState({ summary: {}, debtItems: [] })
  const loading = useSelector((state) => loadingSelector(state))
  const isFocused = useIsFocused()

  useEffect(() => {
    async function fetchDebtRank() {
      try {
        dispatch(setLoading({ loading: true }))
        const response = await get(proApplicationEndpoints.getDebtRank())
        setMyDebt(response.data)
        dispatch(setLoading({ loading: false }))
      } catch (e) {
        dispatch(setLoading({ loading: false }))
        handleError(e)
        console.error(e, e.response)
      }
    }

    fetchDebtRank()
  }, [navigation, isFocused])

  const { summary, debtItems } = myDebt

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerStyle: {
        backgroundColor: lightestgray,
        shadowColor: 'transparent',
        elevation: 0,
      },
      title: 'Summary'
    })
  }, [navigation, debtItems])

  if (loading.loading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {isNotWeb ? (
        <Header
          style={styles.header}
          textStyle={styles.headerText}
          text="Below is a summary of your debt ranked and in what order you should make extra payments to save money on interest!"
        />
      ) : (
        <ToolsHeaderWrapper>
          <DebtRankingPageHeader navigation={navigation} />
        </ToolsHeaderWrapper>
      )}
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Card style={styles.debtFreeCard}>
          <View style={styles.debtFreeCardContent}>
            <HeaderLabel style={styles.debtFreeHeader}>
              Debt-free Date!
            </HeaderLabel>
            <Text style={styles.subtitle}>
              {'Your estimate date of \npaying off all your loans!'}
            </Text>
          </View>
          { summary.overallPayoffDate
            && (
              <CalendarItem
                label={moment(summary.overallPayoffDate).format('MMMM')}
                style={styles.calendarItem}
              >
                <Text style={{ fontSize: 28, fontWeight: 'bold' }}>
                  {moment(summary.overallPayoffDate).format('DD')}
                </Text>
                <Text style={{ fontSize: 12, color: gunmetal }}>
                  {moment(summary.overallPayoffDate).format('YYYY')}
                </Text>
                <Text style={{ position: 'absolute', bottom: -6, right: -6, fontSize: 18 }}>
                  🎉
                </Text>
              </CalendarItem>
            )}
        </Card>

        <View style={styles.debtContainer}>
          {map(debtItems, (debt, index) => (
            <DebtItem
              index={index}
              key={`${debt.balance}-${index}`}
              debt={debt}
              last={index === debtItems.length - 1}
              onPress={() => {
                navigation.navigate('Modal', { screen: 'My Debts Form', params: { accountId: debt.accountId } })
              }}
            />
          ))}
        </View>

        <Text style={styles.notSeeingAccount}>Not seeing an account</Text>
        <Link onPress={() => {
          navigation.navigate('Modal', { screen: 'My Debts Form' })
        }}
        >
          Add New Debt
        </Link>
      </ScrollView>
    </View>
  )
}
