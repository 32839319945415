import { StyleSheet, Text, View } from 'react-native'
import { DEFAULT_SPACING } from '../../../styles'
import GradientSliderResult from './GradientSliderResult'

const styles = StyleSheet.create({
  container: { borderTopWidth: 1, borderTopColor: '#F3F6F7' },
  title: {
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center'
  },
  description: {
    fontSize: 14,
    textAlign: 'center',
    marginVertical: DEFAULT_SPACING
  }
})

function FieldSliderScore({ title, description, score }) {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.description}>{description}</Text>
      <GradientSliderResult value={score} />
    </View>
  )
}

export default FieldSliderScore
