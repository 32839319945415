import { useState } from 'react'
import { View, StyleSheet, ScrollView, Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { lightestgray, white, gunmetal } from './styles/colors'
import Link from './components/Link'
import { ASSET, LIABILITY, NAVIGATION_TYPE } from './constants'
import SearchInput from './components/SearchInput'
import { DEFAULT_SPACING } from './styles'
import AccountList from './components/AccountList'
import Button from './components/Button'
import { bsAccountsSelector } from './selectors'
import { deleteBSAccount } from './actions'

const SEARCH_BAR_OFFSET = 20

const styles = StyleSheet.create({
  containerStyle: { flex: 1 },
  accountContainer: {
    backgroundColor: lightestgray,
    flex: 1,
    flexShrink: 0,
    marginHorizontal: DEFAULT_SPACING
  },
  headerStyle: {
    backgroundColor: white,
    paddingHorizontal: DEFAULT_SPACING,
    zIndex: 1,
  },
  headerText: {
    marginBottom: DEFAULT_SPACING,
    textAlign: 'center',
    color: gunmetal
  },
  headerSearch: {
    marginBottom: 0,
    bottom: -SEARCH_BAR_OFFSET,
    // add a border, not sure why it's not showing up
    borderBottomWidth: 1,
    borderBottomColor: lightestgray,
    borderTopWidth: 1,
    borderTopColor: lightestgray,
    borderLeftWidth: 1,
    borderLeftColor: lightestgray,
    borderRightWidth: 1,
    borderRightColor: lightestgray

  },
  body: { paddingTop: SEARCH_BAR_OFFSET },
  assetList: { marginTop: DEFAULT_SPACING },
  bottomButton: { margin: DEFAULT_SPACING },
  accountHeader: { fontWeight: '600', fontSize: 18 },
  accountAddLink: { justifyContent: 'flex-start' }
})

function AccountListContainer({
  title,
  addAccountText,
  accounts,
  onAddAccount,
  onEditAccount,
  onDeleteAccount,
  style
}) {
  return (
    <View style={[styles.accountContainer, style]}>
      <Text style={styles.accountHeader}>
        { title }
        {' '}
        (
        {accounts.length}
        )
      </Text>

      <Link onPress={onAddAccount} containerStyle={styles.accountAddLink}>{ addAccountText }</Link>

      <AccountList
        list={accounts}
        onEditAccount={onEditAccount}
        onDeleteAccount={onDeleteAccount}
      />
    </View>
  )
}

AccountListContainer.defaultProps = { style: {} }

AccountListContainer.propTypes = {
  title: PropTypes.string.isRequired,
  addAccountText: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddAccount: PropTypes.func.isRequired,
  onEditAccount: PropTypes.func.isRequired,
  onDeleteAccount: PropTypes.func.isRequired,
  style: PropTypes.object
}

export default function UpgradePlanAddAccounts({ navigation }) {
  const [searchText, setSearchText] = useState()
  const dispatch = useDispatch()

  const onSearchChange = (text) => {
    setSearchText(text)
  }

  const onAddAccount = (type) => () => {
    navigation.navigate('Modal', {
      screen: 'Edit/Add BS Accounts',
      params: { accountType: type }
    })
  }

  const onEditAccount = (type) => (accountId) => {
    navigation.navigate('Modal', {
      screen: 'Edit/Add BS Accounts',
      params: { accountType: type, accountId }
    })
  }

  const onDeleteAccount = (type) => (accountId) => {
    // Dispatch a delete account action
    dispatch(deleteBSAccount({ accountType: type, accountId }))
  }

  const onNextPress = () => {
    navigation.navigate('Modal', { screen: 'Upgrade Plan Add Goal' })
  }

  const bsAccounts = useSelector(bsAccountsSelector)

  const assets = bsAccounts.assetAccounts
  const liabilities = bsAccounts.liabilityAccounts

  return (
    <View style={styles.containerStyle}>
      <View style={styles.headerStyle}>
        <Text style={styles.headerText}>
          To get started, we need to know of any cash, investments, property,
          loans, or debt that you may have
        </Text>
        <SearchInput
          value={searchText}
          onChange={onSearchChange}
          style={styles.headerSearch}
          inverse
          placeholder="Search..."
        />
      </View>

      <ScrollView style={styles.body}>
        <AccountListContainer
          title="Cash, Investments, and Property"
          addAccountText="+ Add Account Manually"
          accounts={assets}
          onAddAccount={onAddAccount(ASSET)}
          onEditAccount={onEditAccount(ASSET)}
          onDeleteAccount={onDeleteAccount(ASSET)}
          style={styles.assetList}
        />

        <AccountListContainer
          title="Loans and Debt"
          addAccountText="+ Add Account Manually"
          accounts={liabilities}
          onAddAccount={onAddAccount(LIABILITY)}
          onEditAccount={onEditAccount(LIABILITY)}
          onDeleteAccount={onDeleteAccount(LIABILITY)}
        />

        <Button style={styles.bottomButton} onPress={onNextPress}>
          <Text>Next</Text>
        </Button>
      </ScrollView>
    </View>
  );
}

UpgradePlanAddAccounts.propTypes = { navigation: NAVIGATION_TYPE.isRequired }
