import { get } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { currentUserSelector } from '../selectors'
import { setTransactions } from './setTransactions'

// eslint-disable-next-line import/prefer-default-export
export const getTransactions = (userId, startDate, endDate) => async (dispatch) => {
  try {
    const { data } = await get(proApplicationEndpoints.getTransactions(userId, startDate, endDate))
    dispatch(setTransactions(data))
  } catch (e) {
    console.warn(e, e.response)
  }
}
