import { View, Text, Image, Platform, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import * as WebBrowser from 'expo-web-browser'

import useImageAspectRatio from '../../hooks/useAspectRatio'
import useCheckImage from '../../hooks/useCheckImage'

import Link from '../Link'
import CircleIcon from '../CircleIcon'
import { white } from '../../styles/colors'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: white,
    alignItems: 'center',
  },
  main: { flex: 1, },
  image: {
    height: 100,
    width: 100,
    marginBottom: 12,
  },
  header: {
    fontWeight: '600',
    fontSize: 18,
    maxWidth: '90%',
    textAlign: 'center',
  },
  subheader: {
    fontWeight: '500',
    fontSize: 16,
    maxWidth: '90%',
    textAlign: 'center',
    color: 'rgb(109, 122, 125)',
  },
  link: {
    fontSize: 16,
    marginVertical: 12,
  },
  icon: {
    height: 100,
    width: 100,
    borderRadius: 100 / 2,
    marginBottom: 12
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  mobileStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: {
        alignItems: 'center',
        flexDirection: 'row',
      },
      image: {
        width: 'auto',
        maxWidth: 120,
        resizeMode: 'contain',
        marginBottom: 0,
        marginRight: 16,
      },
      header: {
        textAlign: 'left',
        fontSize: 22,
        width: '100%',
      },
      subheader: {
        width: '100%',
        textAlign: 'left',
      },
      link: {
        width: '100%',
        marginBottom: 0,
      },
      icon: {
        marginRight: 32,
        marginBottom: 0,
      }
    },
    [minSize(DEVICE_SIZES.LG)]: {
      image: {
        marginRight: 40,
        maxWidth: 200,
      },
      header: { fontSize: 28, },
    },
  }
)

export default function CollegeHeader({ college }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const aspectRatio = useImageAspectRatio(college.logo_url)
  const isValidImage = useCheckImage(college.logo_url)

  let url = college.url.replace('http://', '').replace('https://', '')
  url = `https://${url}`

  function handlePress() {
    WebBrowser.openBrowserAsync(url)
  }

  return (
    <View style={styles.container}>
      {isValidImage && (
        <Image
          style={[styles.image, { aspectRatio }]}
          source={{ uri: college.logo_url }}
        />
      )}
      {!isValidImage && (
        <CircleIcon
          icon="building-columns"
          style={[styles.icon, { backgroundColor: 'rgba(235, 136, 38, 0.1)' }]}
          color="rgb(235, 136, 38)"
          size={50}
        />
      )}
      <View style={styles.main}>
        <Text style={styles.header}>{college.name}</Text>
        <Text style={styles.subheader}>
          {college.city}
          ,
          {college.state}
        </Text>
        <Link style={styles.link} onPress={handlePress}>
          {url}
        </Link>
      </View>
    </View>
  )
}
