import { Text, StyleSheet } from 'react-native'
import Card from './Card'

import { gray } from '../../styles/colors'
import { DEFAULT_SPACING } from '../../styles'

import formatCurrency from '../../functions/formatCurrency'

const styles = StyleSheet.create({
  subheader: {
    fontSize: 18,
    color: gray,
    marginVertical: DEFAULT_SPACING
  }
})

function Header({ college }) {
  return (
    <Text style={styles.subheader}>{college.npt41_pub ? 'Public' : 'Private'}</Text>
  )
}

export default function PriceByFamilyIncomeCard({ college }) {
  const rowGroups = {
    Private: [{
      label: '$0 - $30,000',
      value: college.npt41_priv ? `$${formatCurrency(college.npt41_priv)}` : '-'
    }, {
      label: '$30,001 - $48,000',
      value: college.npt42_priv ? `$${formatCurrency(college.npt42_priv)}` : '-'
    }, {
      label: '$48,001 - $75,000',
      value: college.npt43_priv ? `$${formatCurrency(college.npt43_priv)}` : '-'
    }, {
      label: '$75,001 - $110,000',
      value: college.npt44_priv ? `$${formatCurrency(college.npt44_priv)}` : '-'
    }, {
      label: '$110,000+',
      value: college.npt45_priv ? `$${formatCurrency(college.npt45_priv)}` : '-'
    }, {
      label: 'Average Net Price',
      value: college.npt4_priv ? `$${formatCurrency(college.npt4_priv)}` : '-'
    }],
    Public: [{
      label: '$0 - $30,000',
      value: college.npt41_pub ? `$${formatCurrency(college.npt41_pub)}` : '-'
    }, {
      label: '$30,001 - $48,000',
      value: college.npt42_pub ? `$${formatCurrency(college.npt42_pub)}` : '-'
    }, {
      label: '$48,001 - $75,000',
      value: college.npt43_pub ? `$${formatCurrency(college.npt43_pub)}` : '-'
    }, {
      label: '$75,001 - $110,000',
      value: college.npt44_pub ? `$${formatCurrency(college.npt44_pub)}` : '-'
    }, {
      label: '$110,000+',
      value: college.npt45_pub ? `$${formatCurrency(college.npt45_pub)}` : '-'
    }, {
      label: 'Average Net Price',
      value: college.npt4_pub ? `$${formatCurrency(college.npt4_pub)}` : '-'
    }]
  }

  return (
    <Card
      title="Net Price by Family Income"
      tooltipBody={'Not everyone pays "sticker price". Net Price by Family Income data provides some insight into the true annual cost of attending a school - taking into account tuition, room and board, and other expenses, then deducting grants, scholarships, and other non-loan forms of financial aid. Generally, the data will show that students from high-income families tend to pay a higher Net Price at most institutions.'}
      header={<Header college={college} />}
      rows={college.npt41_pub ? rowGroups.Public : rowGroups.Private}
    />
  )
}
