import { useState, useEffect, useLayoutEffect } from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'

import { orderBy, } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useIsFocused } from '@react-navigation/native'
import { currentPlanSelector, fastPlanModeDataSelector, occupationsSelector } from './selectors'

import SearchInput from './components/SearchInput'
import OccupationGroupsList from './components/careers/OccupationGroupsList'

import { getMilitaryOccupations, getOccupations } from './actions'

import { white } from './styles'

import BuilderWrapper from './components/plans/BuilderWrapper'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: 'rgb(236, 241, 244)'
  },
  searchInput: {},
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      searchInput: {
        width: '50%',
        marginTop: 12,
      },
      container: {
        marginHorizontal: -40,
        marginBottom: -40,
      }
    }
  }
)

export default function ExploreCareersScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const isFocused = useIsFocused()
  const dispatch = useDispatch()
  const form = useSelector((state) => currentPlanSelector(state))
  const { fastMode, city } = useSelector((state) => fastPlanModeDataSelector(state))
  const { params } = route
  const { title, subtitle, builder, useGradient, includeProgress, } = params
  const city_id = fastMode ? city.id : form.city ? form.city.id : form.city_id

  const occupationGroups = useSelector((state) => occupationsSelector(state, city_id))

  const [careerSearch, setCareerSearch] = useState(null)

  async function searchGroups(city_id) {
    try {
      dispatch(getOccupations(city_id))
      dispatch(getMilitaryOccupations())
    } catch (error) {
      console.error(error.response)
    }
  }

  useEffect(() => {
    if (isFocused) {
      searchGroups(city_id)
    }
  }, [city_id, isFocused])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0,
      },
      headerTitleStyle: { color: white },
      headerTintColor: white,
      title: subtitle,
    })
  }, [title, subtitle])

  const groups = orderBy(occupationGroups, ['name'])

  const handleMilitaryGroupPress = () => {
    navigation.navigate('Modal', { screen: 'Explore Military Occupation Group', params: { title, subtitle, builder, useGradient, includeProgress, } })
  }

  return (
    <BuilderWrapper includeProgress={includeProgress} option="Career">
      <View style={{ flex: 1 }}>
        <SearchInput
          includeMargin={isNotWeb}
          inverse
          value={careerSearch}
          placeholder="Find Career"
          onChange={(value) => setCareerSearch(value || null)}
          style={styles.searchInput}
        />

        <View style={styles.container}>
          <OccupationGroupsList
            careerSearch={careerSearch}
            navigation={navigation}
            groupOptions={groups}
            locationId={city_id}
            onGroupPress={(occupationGroup) => {
              navigation.navigate('Modal', { screen: 'Explore Occupation Group', params: { occupationGroup, title, subtitle, builder, useGradient, includeProgress, } })
            }}
            onMilitaryGroupPress={handleMilitaryGroupPress}
            onOccupationPress={(occupation) => {
              navigation.navigate('Modal', { screen: occupation.object !== 'occupation-detail' ? 'Explore Military Career' : 'Explore Career', params: { occupation, builder, title, subtitle, useGradient, includeProgress, } })
            }}
          />
        </View>
      </View>
    </BuilderWrapper>
  )
}
