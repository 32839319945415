import _ from 'lodash'
import { BASE_CONFIG } from './config.base'

export const PROD_CONFIG = _.merge({}, BASE_CONFIG, {
  envName: 'Production',
  //baseUrl: 'https://production.troutwood-api.net/',
  baseUrl: 'https://troutwood-staging.herokuapp.com/',
  // baseUrl: 'http://localhost:8000/',
  dns: 'https://f3bd5277469947208456e44b94437af4@o1223910.ingest.sentry.io/6368432',
  pinpointEnabled: false,
  sentryEnabled: false
});
