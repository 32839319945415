import { useLayoutEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import {
  transactionsByMonthAndYearSelector,
  budgetCategoriesSelector
} from './selectors'
import { DEFAULT_SPACING, gunmetal, orange, secondary, white } from './styles'
import TransactionsSection from './components/budgeting/TransactionsSection'

const styles = StyleSheet.create({
  noProjectionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: orange,
    padding: DEFAULT_SPACING
  },
  noProjectionsText: {
    color: white,
    flexWrap: 'wrap'
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: DEFAULT_SPACING
  },
  budgetItemHeader: {
    fontSize: 14,
    fontWeight: '600'
  },
  targetBudget: {
    color: gunmetal,
    fontSize: 10,
    marginBottom: DEFAULT_SPACING * 2,
    marginRight: 30
  },
  thisMonth: {
    color: secondary,
    fontSize: 10
  }
})

export default function BudgetSubCategoryDetailsScreen({ navigation, route }) {
  const { params } = route
  const { budgetSubCategoryKey, selectedDate } = params

  const budgetCategories = useSelector(budgetCategoriesSelector)
  const transactionsByMonthAndYear = useSelector(
    transactionsByMonthAndYearSelector
  )

  const budgetSubCategories = budgetCategories
    .map((category) => category.subCategories)
    .flat()
  const budgetSubCategory = budgetSubCategories.find(
    (subCategory) => subCategory.key === budgetSubCategoryKey
  )
  // iterate through budgetCategories and find the budgetCategory that matches the budgetSubCategoryKey
  const budgetCategory = budgetCategories.find((category) =>
    category.subCategories.find(
      (subCategory) => subCategory.key === budgetSubCategoryKey
    )
  )

  const monthYear = selectedDate.format('YYYY-MM')
  const transactions = transactionsByMonthAndYear[monthYear] || []
  const filteredTransactions = transactions.filter(
    (transaction) => transaction.budgetSubCategory === budgetSubCategoryKey
  )

  useLayoutEffect(() => {
    navigation.setOptions({ headerTitle: budgetSubCategory.label })
  }, [navigation])

  return (
    <View style={styles.container}>
      <TransactionsSection
        navigation={navigation}
        transactions={filteredTransactions}
        budgetSubCategory={budgetSubCategory}
        budgetCategory={budgetCategory}
      />
    </View>
  )
}

BudgetSubCategoryDetailsScreen.propTypes = {}
