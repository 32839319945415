import { post, patch } from 'http'
import { SET_CURRENT_PLAN } from './setCurrentPlan'
import { getPlans } from './getPlans'
import { setLoading } from './setLoading'
import { getCurrentUser } from './getCurrentUser'

import { formatFinancialPayload } from '../api/financialBreakdown'

import handleError from '../functions/handleError'

export const saveCurrentPlan = function (plan) {
  return async (dispatch, getState) => {
    const { currentPlan } = getState()

    const mergedPlan = { ...currentPlan, ...plan }

    try {
      setLoading({ loading: true })

      const payload = formatFinancialPayload(mergedPlan);

      const result = mergedPlan.id ? await patch(`api/v1/plans/${mergedPlan.id}/`, payload)
        : await post('api/v1/plans/', payload)

      mergedPlan.id = result.data.id
      mergedPlan.city = result.data.city
      mergedPlan.occupation = result.data.occupation

      dispatch({
        type: SET_CURRENT_PLAN,
        payload: mergedPlan
      })

      dispatch(getPlans())
      dispatch(getCurrentUser())

      setLoading({ loading: false })
    } catch (error) {
      handleError(error, 'Oops. Looks like something went wrong.')
      setLoading({ loading: false })
    }
  }
}
