import { post, patch } from 'http'
import { getCurrentUser } from './getCurrentUser'
import { setPersonalMilestoneItem } from './setPersonalMilestoneItem'
import { setPersonalMilestones } from './setPersonalMilestone'
import { getPersonalMilestones } from './getPersonalMilestones'

export const savePersonalMilestoneItem = function (payload) {
  return async (dispatch) => {
    try {
      payload.id ? await patch(`api/v1/personal-milestones/${payload.id}/`, payload) : await post('api/v1/personal-milestones/', payload)
      dispatch(getPersonalMilestones());
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
