import { Platform, StyleSheet, Text, View } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import { DEFAULT_SPACING, darkblue, secondary } from '../../styles'

const basicStyles = {
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8
  },
  label: {
    letterSpacing: -0.6,
    flex: 2,
    fontSize: 16,
    color: secondary
  },
  value: {
    textAlign: 'right',
    flex: 1,
    fontWeight: '600',
    fontSize: 16,
    color: darkblue
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  mobileStyles,
  {
    [minSize(DEVICE_SIZES.MD)]: {
      container: { marginBottom: DEFAULT_SPACING, },
      value: { fontSize: 18, }
    },
    [minSize(DEVICE_SIZES.LG)]: {},
  }
)

export default function LineItem({ label, value }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  )
}
