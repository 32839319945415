import * as Yup from 'yup'

import {
  REQUIRED,
  INVALID_INT,
  INVALID_MIN,
  INVALID_MAX,
  INVALID_MUST_BE_GREATER_THAN_ZERO
} from '../constants'

export const Fields = {
  asset: 'asset',
  totalDollarsAvailable: 'total_dollars_available',
  assetAName: 'asset_a_name',
  assetADownPayment: 'asset_a_down_payment',
  assetAAssetPrice: 'asset_a_asset_price',
  assetAInterestRate: 'asset_a_interest_rate',
  assetAYears: 'asset_a_years',
  assetAInsuranceRate: 'asset_a_insurance_rate',
  assetAAppreciationRate: 'asset_a_appreciation_rate',
  assetAPropertyTaxRate: 'asset_a_property_tax_rate',
  assetAHOACost: 'asset_a_hoa_cost',
  assetBName: 'asset_b_name',
  assetBDownPayment: 'asset_b_down_payment',
  assetBAssetPrice: 'asset_b_asset_price',
  assetBInterestRate: 'asset_b_interest_rate',
  assetBYears: 'asset_b_years',
  assetBInsuranceRate: 'asset_b_insurance_rate',
  assetBAppreciationRate: 'asset_b_appreciation_rate',
  assetBPropertyTaxRate: 'asset_b_property_tax_rate',
  assetBHOACost: 'asset_b_hoa_cost',
}

export const ValidationSchema = Yup.object().shape({
  [Fields.totalDollarsAvailable]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetAName]: Yup.string()
    .nullable(),
  [Fields.assetADownPayment]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .test(Fields.assetADownPayment, 'Down Payment cannot be greater than loan amount', function (value) {
      const { asset_a_asset_price } = this.parent
      if (!value) return true;
      return value < asset_a_asset_price
    })
    .test(Fields.assetADownPayment, 'Down Payment cannot be greater than total dollars available', function (value) {
      const { total_dollars_available } = this.parent
      if (!value) return true;
      return value < total_dollars_available
    })
    .required(REQUIRED),
  [Fields.assetAAssetPrice]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MUST_BE_GREATER_THAN_ZERO)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetAInterestRate]: Yup.number()
    .positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetAYears]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetAInsuranceRate]: Yup.number()
    .positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(10, `${INVALID_MAX}10`)
    .nullable(),
  [Fields.assetAAppreciationRate]: Yup.number()
    .min(-100, INVALID_MIN)
    .max(500, `${INVALID_MAX}500`)
    .nullable(),
  [Fields.assetAPropertyTaxRate]: Yup.number()
    .positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(10, `${INVALID_MAX}10`)
    .nullable(),
  [Fields.assetAHOACost]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(1000000, `${INVALID_MAX}1,000,000`)
    .nullable(),

  [Fields.assetBName]: Yup.string()
    .nullable(),
  [Fields.assetBDownPayment]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .test(Fields.assetBDownPayment, 'Down Payment cannot be greater than loan amount', function (value) {
      const { asset_b_asset_price } = this.parent
      if (!value) return true;
      return value < asset_b_asset_price
    })
    .test(Fields.assetBDownPayment, 'Down Payment cannot be greater than total dollars available', function (value) {
      const { total_dollars_available } = this.parent
      if (!value) return true;
      return value < total_dollars_available
    })
    .required(REQUIRED),
  [Fields.assetBAssetPrice]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(1, INVALID_MUST_BE_GREATER_THAN_ZERO)
    .max(100000000000, `${INVALID_MAX}100,000,000,000`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetBInterestRate]: Yup.number()
    .positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetBYears]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(100, `${INVALID_MAX}100`)
    .nullable()
    .required(REQUIRED),
  [Fields.assetBInsuranceRate]: Yup.number()
    .positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(10, `${INVALID_MAX}10`)
    .nullable(),
  [Fields.assetBAppreciationRate]: Yup.number()
    .min(-100, INVALID_MIN)
    .max(500, `${INVALID_MAX}500`)
    .nullable(),
  [Fields.assetBPropertyTaxRate]: Yup.number()
    .positive(INVALID_MIN)
    .min(0, INVALID_MIN)
    .max(10, `${INVALID_MAX}10`)
    .nullable(),
  [Fields.assetBHOACost]: Yup.number()
    .positive(INVALID_MIN)
    .integer(INVALID_INT)
    .min(0, INVALID_MIN)
    .max(1000000, `${INVALID_MAX}1,000,000`)
    .nullable()

})

export const InitialValues = {
  [Fields.totalDollarsAvailable]: '',
  [Fields.asset]: '',
  [Fields.assetAAssetPrice]: '100000',
  [Fields.assetADownPayment]: '10000',
  [Fields.assetAInterestRate]: '2',
  [Fields.assetAYears]: '30',
  [Fields.assetAInsuranceRate]: '2',
  [Fields.assetAAppreciationRate]: '2',
  [Fields.assetAPropertyTaxRate]: '2',
  [Fields.assetAHOACost]: '0',
  [Fields.assetBAssetPrice]: '110000',
  [Fields.assetBDownPayment]: '10000',
  [Fields.assetBInterestRate]: '2',
  [Fields.assetBYears]: '20',
  [Fields.assetBInsuranceRate]: '2',
  [Fields.assetBAppreciationRate]: '2',
  [Fields.assetBPropertyTaxRate]: '2',
  [Fields.assetBHOACost]: '0',
}
