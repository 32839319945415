import React, { useState, useLayoutEffect, useEffect } from 'react'
import {
  View,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableOpacity,
  Text
} from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import SearchInput from './components/SearchInput'

import { DEFAULT_SPACING, white } from './styles'

import BuilderWrapper from './components/plans/BuilderWrapper'
import militaryOccupationSelector from './selectors/militaryOccupation'
import SearchedOccupations from './components/careers/SearchedOccupations'
import OccupationGroupCard from './components/careers/OccupationGroupCard'
import { ListItem } from './MilitaryCareerExploreOccupationsScreen'
import { Career } from './MilitaryCareerScreen'

const basicStyles = {
  container: {
    flex: 1,
    backgroundColor: 'rgb(236, 241, 244)',
    paddingBottom: DEFAULT_SPACING * 3
  },
  searchInput: {},
  listWrapper: {
    width: '100%',
    height: '100%',
    paddingHorizontal: DEFAULT_SPACING
  },
  details: {},
  occupationName: {
    fontSize: 18,
    color: 'rgb(48, 64, 77)',
    fontWeight: '600',
    marginVertical: 15
  },
  arrowIcon: { opacity: 0.6 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.LG)]: {
    searchInput: {
      width: '50%',
      marginTop: 12
    },
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 18,
      marginHorizontal: -40,
      marginBottom: -40
    },
    listWrapper: { width: '40%', paddingHorizontal: 40 },
    occupationName: {
      fontSize: 36,
      fontWeight: '600'
    },
    details: {
      width: '60%',
      height: '100%'
    }
  }
})

export default function MilitaryOccupationGroupScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const { militaryOccupations } = useSelector((state) =>
    militaryOccupationSelector(state)
  )
  const { title, subtitle, builder, useGradient, includeProgress } =
    route.params
  const [careerSearch, setCareerSearch] = useState(null)
  const [career, setCareer] = useState(null)
  const [groupKey, setGroupKey] = useState()
  const [militaryCareers, setMilitaryCareers] = useState([])

  useLayoutEffect(() => {
    if (builder || useGradient) {
      navigation.setOptions({
        headerShown: isNotWeb,
        headerTransparent: true,
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0
        },
        headerTitleStyle: { color: white },
        headerTintColor: white,
        title: subtitle
      })
    } else {
      navigation.setOptions({
        headerShown: isNotWeb,
        title,
        subtitle
      })
    }
  }, [title, subtitle, builder, useGradient])

  useEffect(() => {
    const careers = []
    if (militaryOccupations) {
      Object.keys(militaryOccupations.careers).map((key) => {
        careers.push(...militaryOccupations.careers[key])
      })
      setMilitaryCareers(careers)
    }
  }, [militaryOccupations])

  const Wrapper =
    builder || useGradient || !isNotWeb ? BuilderWrapper : Fragment

  const handleCareerPress = (occupation) => {
    if (isNotWeb)
      navigation.navigate('Modal', {
        screen: 'Explore Military Career',
        params: {
          occupation,
          builder,
          title,
          subtitle,
          useGradient,
          includeProgress
        }
      })
    else setCareer(occupation)
  }

  return (
    <Wrapper
      includeProgress={includeProgress}
      option="Career"
    >
      <SearchInput
        includeMargin={isNotWeb}
        inverse
        value={careerSearch}
        placeholder="Find Career"
        onChange={(value) => {
          setCareerSearch(value || null)
        }}
        style={styles.searchInput}
      />
      <View style={styles.container}>
        {militaryOccupations && (
          <View style={styles.listWrapper}>
            <ScrollView
              style={{ width: '100%', height: '100%', borderRadius: 12 }}
              contentContainerStyle={{ borderRadius: 12, overflow: 'hidden' }}
            >
              {careerSearch && careerSearch.length > 3 && (
                <SearchedOccupations
                  onOccupationPress={(career) => handleCareerPress(career)}
                  careerSearch={careerSearch}
                  groupOptions={[
                    {
                      ...militaryOccupations,
                      careers: groupKey
                        ? militaryOccupations.careers[groupKey]
                        : militaryCareers
                    }
                  ]}
                />
              )}
              <View
                style={{
                  marginVertical: 15,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <TouchableOpacity
                  style={groupKey ? {} : { width: '100%' }}
                  disabled={!groupKey}
                  onPress={() => setGroupKey(null)}
                >
                  <Text
                    style={[
                      styles.occupationName,
                      {
                        marginVertical: 0,
                        textDecorationLine: groupKey ? 'underline' : 'none'
                      }
                    ]}
                  >
                    {groupKey ? 'Military' : militaryOccupations.name}
                  </Text>
                </TouchableOpacity>
                {groupKey && (
                  <>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="#B9C5C9"
                      style={{ marginHorizontal: 6 }}
                    />
                    <Text
                      style={[
                        styles.occupationName,
                        { marginVertical: 0, opacity: 0.6 }
                      ]}
                    >
                      {groupKey}
                    </Text>
                  </>
                )}
              </View>
              {militaryOccupations.image && (
                <OccupationGroupCard image={militaryOccupations?.image} />
              )}
              <Text
                style={[styles.occupationName, { fontSize: 18, marginTop: 0 }]}
              >
                Branches
              </Text>
              {!groupKey
                ? Object.keys(militaryOccupations.careers).map(
                    (subGroupKey) => (
                      <ListItem
                        key={subGroupKey}
                        name={subGroupKey}
                        onPress={() => setGroupKey(subGroupKey)}
                      />
                    )
                  )
                : militaryOccupations.careers[groupKey].map((career) => (
                    <ListItem
                      key={career.id}
                      name={career.rank}
                      onPress={() => handleCareerPress(career)}
                    />
                  ))}
            </ScrollView>
          </View>
        )}

        {!isNotWeb && !!career && (
          <View style={styles.details}>
            <Career
              builder={builder}
              title={title}
              occupation={career}
              navigation={navigation}
            />
          </View>
        )}
      </View>
    </Wrapper>
  )
}
