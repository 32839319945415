import { get } from 'http'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import { currentUserSelector } from '../selectors'
import { setProPlans } from './setProPlans'

// eslint-disable-next-line import/prefer-default-export
export const getProPlans = () => async (dispatch, getState) => {
  try {
    const { id: userId } = currentUserSelector(getState())
    const { data: plans } = await get(proApplicationEndpoints.getPlans(userId))
    dispatch(setProPlans(plans))
  } catch (e) {
    console.warn(e, e.response)
  }
}
