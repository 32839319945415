import { View } from 'react-native'
import CircleGradient from '../CircleGradient'

function Wrapper({ children, gradientHeight }) {
  return (
    <View style={{ height: '100%' }}>
      <CircleGradient height={gradientHeight} />
      {children}
    </View>
  )
}

export default Wrapper
