import { del } from 'http'
import { getLifeEvents } from './getLifeEvents'

export const deleteLifeEvent = function (payload, planId) {
  return async (dispatch) => {
    try {
      await del(`api/v1/plan/${planId}/events/${payload.id}/`)

      dispatch(getLifeEvents(planId))
    } catch (e) {
      console.warn(e, e.response)
    }
  }
}
