import { View, StyleSheet, Text } from 'react-native'
import SlideupDrawer from './SlideupDrawer'
import Button from './Button'
import { darkblue, gunmetal } from '../styles/colors'

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 30,
    paddingVertical: 80
  },
  header: {
    fontWeight: '600',
    fontSize: 28,
    color: darkblue,
    textAlign: 'center'
  },
  body: {
    marginVertical: 20,
    letterSpacing: -0.5,
    color: gunmetal,
    textAlign: 'center'
  },
  button: {
    marginTop: 20,
    backgroundColor: 'rgb(222, 54, 84)'
  },
})

export default function DeleteSlideupDrawer({ header, body, buttonText, visible, setVisible, onPress }) {
  const marginTop = 300
  const height = 300

  return (
    <SlideupDrawer snapPointIndex={1} scrollEnabled={false} marginTop={marginTop} height={height} visible={visible} setVisible={setVisible}>
      <View style={styles.container}>
        <Text style={styles.header}>{header}</Text>
        <Text style={styles.body}>{body}</Text>

        <Button style={styles.button} onPress={onPress}>
          {buttonText}
        </Button>
      </View>
    </SlideupDrawer>
  )
}
