import { Image, Platform, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

const basicStyles = {
  image: {
    width: 95 / 2,
    height: 110 / 2,
    position: 'absolute',
    top: -7
  },
  left: { left: 16, },
  right: { right: 16 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      image: {
        width: 63,
        height: 71,
        top: -8,
      },
      left: { left: 22, }
    },
  }
)

const isNotWeb = Platform.OS !== 'web'

export default function Flag({ source, left = true }) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  return (
    <Image
      style={[styles.image, left ? styles.left : styles.right]}
      source={source}
    />
  )
}
