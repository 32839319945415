import { View, ActivityIndicator } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'
import Button from './Button'

const useStyles = CreateResponsiveStyle(
  {
    bottomButton: {
      position: 'absolute',
      bottom: 20,
      marginBottom: 10,
      left: 0,
      right: 0
    },
    button: {
      marginTop: 12,
      width: '100%',
      minHeight: 56,
    },
    activityIndicator: { marginRight: 6 },
    withMargin: { marginHorizontal: 20 }
  },
  { [minSize(DEVICE_SIZES.LG)]: { button: { padding: 12, }, }, }
)

export default function BottomButton({
  style = {},
  color,
  withMargin = false,
  relative = true,
  disabled,
  isSubmitting,
  onPress,
  children,
  inverted,
  withBorder = true
}) {
  const styles = useStyles()
  const appliedStyles = [styles.bottomButton]

  if (style) {
    appliedStyles.push(style)
  }
  if (withMargin) {
    appliedStyles.push(styles.withMargin)
  }

  if (relative) {
    appliedStyles.push(styles.relative)
  }

  return (
    <View style={appliedStyles}>
      <Button
        disabled={disabled || isSubmitting}
        style={styles.button}
        color={color}
        inverted={inverted}
        withBorder={withBorder}
        onPress={onPress}
      >
        {isSubmitting && <ActivityIndicator style={styles.activityIndicator} size="small" color="white" />}
        {children || 'Save'}
      </Button>
    </View>
  )
}
