import { useEffect, useState } from 'react'
import { Platform, View, Text, StyleSheet, Keyboard } from 'react-native'

import { Formik } from 'formik'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/native'
import Screen from './Screen'
import BottomButton from './BottomButton'

import { lightblue, red, white } from '../styles'
import { currentUserSelector } from '../selectors'

import { trackFormSubmit } from '../hooks/usePinpoint'

const SANITIZED_KEYS = ['password', 'password_confirmation']

const basicStyles = {
  container: {
    backgroundColor: white,
    flex: 1
  },
  formContainer: {
    position: 'relative',
    flex: 1
  },
  scrollView: { paddingBottom: 120 },
  closeButton: {
    color: lightblue,
    fontSize: 14,
    marginHorizontal: 12
  },
  flex: { flex: 1 },
  padding: { paddingHorizontal: 12 },
  negativeButtonButton: {
    marginHorizontal: 12,
    bottom: 20
  },
  bottomButton: { marginHorizontal: 12 }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    scrollView: { paddingBottom: 0 },
    negativeBottomButton: {
      position: 'relative',
      margin: 0,
      padding: 0,
      bottom: 0,
      marginBottom: 0
    }
  }
})

export default function Form({
  children,
  disableScroll,
  showSubmitButton = true,
  containerStyle = {},
  buttonContainerStyle = {},
  validationSchema,
  initialValues = {},
  onSubmit,
  onNegativeButtonPress,
  buttonText,
  negativeButtonText,
  withPadding = true,
  noRoute = false
}) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const appliedStyles = [styles.scrollView]
  const route = !noRoute ? useRoute() : null
  const currentUser = useSelector(currentUserSelector)

  if (withPadding) {
    appliedStyles.push(styles.padding)
  }

  async function handleSubmit(values, actions) {
    const routeName = route && route.name

    const sanitizedValues = { ...values }

    SANITIZED_KEYS.forEach((key) => {
      delete sanitizedValues[key]
    })

    if (route) trackFormSubmit(routeName, JSON.stringify(sanitizedValues), currentUser)

    await onSubmit(values, actions)
  }

  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setIsKeyboardVisible(true)
    })

    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setIsKeyboardVisible(false)
    })

    return () => {
      keyboardDidShowListener.remove()
      keyboardDidHideListener.remove()
    }
  }, [])

  return (
    <View style={[styles.container, containerStyle]}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        style={styles.flex}
      >
        {(props) => (
          <View style={styles.formContainer}>
            <Screen
              style={styles.flex}
              safeAreaStyle={styles.flex}
              keyboardAvoidingViewStyles={styles.flex}
              scrollViewStyle={appliedStyles}
              disableScroll={disableScroll}
            >
              {children(props)}

              {onNegativeButtonPress && !isKeyboardVisible && (
                <BottomButton
                  isSubmitting={props.isSubmitting}
                  style={[
                    styles.negativeBottomButton,
                    onNegativeButtonPress && showSubmitButton ? {} : styles.fullWidth
                  ]}
                  color={red}
                  disabled={props.isSubmitting}
                  onPress={onNegativeButtonPress}
                  inverted
                  withBorder={false}
                >
                  <Text>{negativeButtonText || 'Delete'}</Text>
                </BottomButton>
              )}
            </Screen>

            {showSubmitButton && !isKeyboardVisible && (
              <BottomButton
                isSubmitting={props.isSubmitting}
                style={[
                  styles.bottomButton,
                  buttonContainerStyle,
                  onNegativeButtonPress && showSubmitButton ? {} : styles.fullWidth
                ]}
                disabled={props.isSubmitting}
                onPress={props.handleSubmit}
              >
                <Text>{buttonText || 'Save'}</Text>
              </BottomButton>
            )}
          </View>
        )}
      </Formik>
    </View>
  )
}
