import { useEffect, useState, useLayoutEffect } from 'react'
import { View, ScrollView, Text, StyleSheet } from 'react-native'
import YoutubePlayer from './components/YoutubePlayer'

import axios from 'axios'

import { black, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(40,40,40)',
    position: 'relative',
    flex: 1
  },
  playerContainer: {
    padding: DEFAULT_SPACING,
    backgroundColor: black
  },
  titleStyle: {
    padding: DEFAULT_SPACING,
    fontSize: 18,
    fontWeight: '500',
    color: white
  },
  descriptionStyle: {
    padding: DEFAULT_SPACING,
    fontSize: 12,
    color: white
  }
})

export default function VideoPlayerScreen({ route, navigation }) {
  const [youtubeVideo, setYoutubeVideo] = useState({ snippet: { title: '', description: '' } })
  const { videoId } = route.params

  async function fetchYoutubeVideo() {
    const response = await axios.get(`https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails&id=${videoId}&key=AIzaSyAy5Mkun2RcEkJ-mLBwwk43PKr0MaKkIcs`)
    setYoutubeVideo(response.data.items[0])
  }

  useLayoutEffect(() => {
    navigation.setOptions({ headerTitle: youtubeVideo.snippet.title })
  }, [youtubeVideo])

  useEffect(() => {
    fetchYoutubeVideo()
  }, [])

  const { snippet } = youtubeVideo
  const { title, description } = snippet

  return (
    <View style={styles.container}>
      <ScrollView
        contentContainerStyle={{ paddingBottom: 100 }}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.playerContainer}>
          <YoutubePlayer videoId={videoId} />
        </View>

        <Text style={styles.titleStyle}>{title}</Text>
        <Text style={styles.descriptionStyle}>{description}</Text>
      </ScrollView>
    </View>
  );
}
