import { useState, useEffect } from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'

import { map, sum } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'http'
import HeaderLabel from './components/HeaderLabel'
import Button from './components/Button'

import { accountsSelector } from './selectors'

import CongratsSlideupDrawer from './components/emergency-preparedness/CongratsSlideupDrawer'
import AccountListItem from './components/emergency-preparedness/AccountListItem'
import FireIcon from './components/emergency-preparedness/FireIcon'

import formatCurrency from './functions/formatCurrency'

import AddSourceDrawer from './components/emergency-preparedness/AddSourceDrawer'
import { darkblue, lightestgray, white } from './styles/colors'
import { DEFAULT_SPACING } from './styles'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: DEFAULT_SPACING,
    backgroundColor: lightestgray
  },
  resultContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: DEFAULT_SPACING * 2,
    backgroundColor: white
  },
  invertedButton: { marginBottom: 12 },
  bottomButtonStyle: {
    justifyContent: 'flex-end',
    padding: DEFAULT_SPACING,
    marginBottom: DEFAULT_SPACING,
    backgroundColor: lightestgray
  },
  input: {
    backgroundColor: 'rgba(242, 248, 250, 0.9)',
    marginBottom: 12
  },
  sliderContainer: {
    paddingHorizontal: 30,
    paddingVertical: 80
  },
})

const sources = [{
  label: 'Checking',
  icon: 'money-check',
  registration: 'CHECKING'
}, {
  label: 'Savings',
  icon: 'piggy-bank',
  registration: 'SAVINGS'
}, {
  label: 'Money Market',
  icon: 'piggy-bank',
  registration: 'MONEY_MARKET'
}, {
  label: 'Taxable',
  icon: 'piggy-bank',
  registration: 'CHECKING'
}, {
  label: 'Roth IRA',
  icon: 'piggy-bank',
  registration: 'ROTH_IRA'
}]

export default function EmergencypreparednessListScreen({ navigation }) {
  const marginTop = 150
  const height = 900

  const [visible, setVisible] = useState(false)
  const [formVisible, setFormVisible] = useState(false)
  const [registration, setRegistration] = useState()

  const currentAccounts = useSelector((state) => accountsSelector(state))
  const [emergencypreparedness, setEmergencypreparedness] = useState({})

  const dispatch = useDispatch()

  function calculateTotal(currentAccounts) {
    return sum(map(currentAccounts, (s) => Number(s.balance)))
  }

  async function fetchEmergencypreparedness() {
    const result = await get('emergency-preparedness/')
    setEmergencypreparedness(result.data)
  }

  useEffect(() => {
    fetchEmergencypreparedness()
  }, [currentAccounts])

  useEffect(() => {
    if (!visible) {
      setFormVisible(false)
      setRegistration()
    }
  }, [visible])

  let status = emergencypreparedness.status || 'Level 1'
  status = status.replace('_', ' ').replace('LEVEL', 'Level')

  const statusNumber = status[status.length - 1]

  let circleColor = 'white'
  let circleDistance = 0

  if (statusNumber == '1') {
    circleColor = '#F2285B'
    circleDistance = '5%'
  } else if (statusNumber == '2') {
    circleColor = '#FBBD29'
    circleDistance = '50%'
  } else if (statusNumber == '3') {
    circleColor = '#43CF62'
    circleDistance = '95%'
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.resultContainer}>
        <FireIcon color={circleColor} size={110} iconSize={60} />

        <Text style={{
          fontWeight: '600',
          fontSize: 48,
          color: darkblue,
          letterSpacing: 0,
          textAlign: 'center'
        }}
        >
          $
          {formatCurrency(calculateTotal(currentAccounts))}
        </Text>
        <Text style={{
          fontWeight: '600',
          fontSize: 16,
          color: darkblue,
          letterSpacing: 0,
          textAlign: 'center'
        }}
        >
          {status || 'Level 1'}
          {' '}
          •
          {emergencypreparedness.months_of_expenses_covered || 0}
          {' '}
          Months
        </Text>
      </View>

      <ScrollView style={styles.container}>
        <HeaderLabel>Emergency Funds</HeaderLabel>
        {map(currentAccounts, (account) => <AccountListItem key={`account-${account.id}`} navigation={navigation} sources={sources} {...account} />)}
      </ScrollView>

      <View style={styles.bottomButtonStyle}>
        <Button onPress={() => setVisible(true)} style={styles.invertedButton}>+ Add Source</Button>
      </View>

      <AddSourceDrawer
        sources={sources}
        visible={visible}
        setVisible={setVisible}
        marginTop={marginTop}
        height={height}
        formVisible={formVisible}
        setFormVisible={setFormVisible}
      />

      <CongratsSlideupDrawer statusNumber={statusNumber} />
    </View>
  );
}
