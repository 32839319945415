export const AlertTypes = {
  Info: 'info',
  Warn: 'warn',
  Error: 'error',
  Success: 'success',
};

export default class InAppAlertService {
  static dropDownList = [];

  static addDropDownListener(dropDown) {
    this.dropDownList.push(dropDown);
  }

  static removeDropDownListener(dropDown) {
    this.dropDownList = this.dropDownList.filter((x) => x && x !== dropDown);
  }

  static alert(type, title, message) {
    this.dropDownList.forEach((dropDown) => {
      if (dropDown.alertWithType) dropDown.alertWithType(type, title, message);
    });
  }
}
