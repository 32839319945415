import { Text, StyleSheet, useWindowDimensions, } from 'react-native'

import { last } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

import { useNavigation, useNavigationState } from '@react-navigation/native'

import { useSafeAreaInsets } from 'react-native-safe-area-context'
import HeaderBackImage from './HeaderBackImage'
import TouchableOpacity from './TouchableOpacity'
import { lightblue, white } from '../styles/colors'

import { DARK_BACKGROUND_ROUTES } from '../constants'

import hapticFeedback from './hapticFeedback'

const styles = StyleSheet.create({
  closeButton: {
    color: lightblue,
    fontSize: 14,
    marginHorizontal: 12,
    paddingTop: 20,
    zIndex: 1000
  },
  closeButtonRight: {
    color: lightblue,
    fontSize: 17,
    paddingTop: 0,
    zIndex: 1001
  },
  closeButtonContainer: { zIndex: 1001 }
})

export default function CloseLeftButton({ close, onPress, closeButtonTextStyle = {}, offset = false, right = false, planSummary = false }) {
  const window = useWindowDimensions()
  const navigation = useNavigation()
  const navigationState = useNavigationState((state) => state)

  const navigationIndex = useNavigationState((state) => state.index)
  const routes = useNavigationState((state) => state.routes)
  const currentRoute = last(routes)
  const insets = useSafeAreaInsets()

  function onPressAndNavigate() {
    hapticFeedback()
    if (onPress) onPress()
    navigation.pop()
  }
  function onPressAndNavigateClose() {
    hapticFeedback()
    if (onPress) onPress()

    if (planSummary === true) {
      navigation.navigate('Plan Builder')
    } else {
      navigation.pop()
    }
  }
  if (navigationIndex > 0 && !close) {
    return (
      <>
        <TouchableOpacity
          style={[styles.closeButtonContainer, { marginTop: offset ? insets.top : -20 }]}
          onPress={() => onPressAndNavigate()}
        >
          <HeaderBackImage fill={currentRoute && DARK_BACKGROUND_ROUTES.includes(currentRoute.name) ? white : null} />
        </TouchableOpacity>
        {right
          && (
            <TouchableOpacity
              style={[styles.closeButtonContainer, { marginTop: offset ? insets.top : -20, left: window.width - 30 }]}
              onPress={() => onPressAndNavigateClose()}
            >
              <Text style={[right === false ? styles.closeButton : styles.closeButtonRight, closeButtonTextStyle]}>
                <FontAwesomeIcon icon={['far-solid', 'circle-xmark']} color="#66666652" size={24} />
              </Text>
            </TouchableOpacity>
          )}
      </>
    )
  } else {
  return (
    <TouchableOpacity style={[styles.closeButtonContainer, { marginTop: offset ? insets.top : -20 }]} onPress={() => onPressAndNavigateClose()}>
      <Text style={[right === false ? styles.closeButton : styles.closeButtonRight, closeButtonTextStyle]}>
        Close
      </Text>
    </TouchableOpacity>
  )
  }
}
