import { useLayoutEffect } from 'react'
import { Platform, View, Text, StyleSheet, ScrollView } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'

import { Table, Row, Col, TableWrapper } from 'react-native-table-component'
import { StackedBarChart, AreaChart, Grid, XAxis, YAxis } from 'react-native-svg-charts'
import { times, map } from 'lodash'
import moment from 'moment'
import * as shape from 'd3-shape'
import Container from './components/Container'
import HeaderLabel from './components/HeaderLabel'

import abbreviateNumber from './functions/abbreviateNumber'
import formatCurrency from './functions/formatCurrency'
import formatPercent from './functions/formatPercent'

import { primary, white, pink, lightblue, rgba, gunmetal, darkgrayblue } from './styles/colors'

import { DEFAULT_SPACING } from './styles'
import { useWindowDimensions } from 'react-native'

const basicStyles = {
  resultsWrapper: {
    flex: 1,
    width: '100%',
  },
  mainResults: {
    backgroundColor: primary,
    flex: 0,
    padding: '6%',
    paddingBottom: '8%',
  },
  paragraphWrapper: {
    padding: DEFAULT_SPACING,
    marginTop: 0
  },
  headerText: {
    textAlign: 'center',
    paddingTop: 10,
    fontSize: 14,
    color: white
  },
  moneyText: {
    textAlign: 'center',
    padding: 2,
    fontSize: 42,
    color: white,
    fontWeight: 'bold'
  },
  chartContainer: {
    height: 300,
    flexDirection: 'row',
    position: 'relative',
    width: '100%'
  },
  spaceAround: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 8 / 2,
    marginRight: 4,
    marginTop: 3
  },
  mediumText: {
    fontSize: 11
  },
  wrapper: {
    flexDirection: 'row'
  },
  row: {
    height: 50
  },
  title: {
    fontWeight: '600',
    fontSize: 12,
    color: darkgrayblue,
    letterSpacing: 0,
    paddingVertical: 9,
    paddingHorizontal: 9,
  },
  text: {
    paddingVertical: 9,
    paddingHorizontal: 9,
    fontSize: 12,
    color: darkgrayblue,
    letterSpacing: -0.5
  },
  table: { width: 170 },
  tableBorder: {
    borderWidth: 0.5,
    borderColor: 'rgb(221, 228, 230)'
  },
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      mainResults: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '9%',
        borderRadius: 12,
      },
      paragraphWrapper: {
        padding: 0,
        marginTop: 24
      },
      headerText: {
        fontSize: 16,
      },
      moneyText: {
        fontSize: 52,
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

function PaydownChart({ period, type, balance, ppmt, ipmt }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const data = []
  const iterations = type == 'Mortgage' || type == 'Student Loans' ? balance.length / 12 : balance.length
  const currentYear = moment().year()

  times(iterations, (idx) => {
    data.push({
      ppmt: type == 'Mortgage' || type == 'Student Loans' ? ppmt[idx * 12] : ppmt[idx],
      ipmt: type == 'Mortgage' || type == 'Student Loans' ? ipmt[idx * 12] : ipmt[idx],
      year: type == 'Mortgage' || type == 'Student Loans' ? currentYear + idx + 1 : idx + 1,
      balance: type == 'Mortgage' || type == 'Student Loans' ? balance[idx * 12] : balance[idx]
    })
  })

  const verticalContentInset = { top: 0, bottom: 10 }
  const horizontalContentInset = { left: 20, right: 20 }
  const xAxisHeight = 40
  const axesSvg = { fontSize: 10, fill: 'grey' }

  return (
    <View style={{ alignItems: 'center' }}>
      <HeaderLabel style={{ marginBottom: DEFAULT_SPACING }}>Debt Paydown</HeaderLabel>

      <View style={{ width: '70%', marginBottom: DEFAULT_SPACING }}>
        <View style={styles.spaceAround}>
          <View style={styles.spaceAround}>
            <View style={[styles.dot, { backgroundColor: primary }]} />
            <Text style={styles.mediumText}>Principal</Text>
          </View>
          <View style={styles.spaceAround}>
            <View style={[styles.dot, { backgroundColor: lightblue }]} />
            <Text style={styles.mediumText}>Interest</Text>
          </View>
        </View>
      </View>

      <View style={{ position: 'relative', width: '100%' }}>
        <Text style={{ position: 'absolute', top: 125, left: -20, zIndex: 100, transform: [{ rotate: '270deg' }] }}>Payment</Text>

        <View style={{ width: '100%', paddingLeft: 40, paddingRight: isNotWeb ? 40 : 0, alignItems: 'center', justifyContent: 'center' }}>
          <View style={styles.chartContainer}>
            <YAxis
              style={{ marginBottom: 0 }}
              data={data}
              contentInset={verticalContentInset}
              yAccessor={({ item }) => item.ppmt}
              xAccessor={({ item }) => item.year}
              svg={{
                fill: gunmetal,
                fontSize: 8
              }}
              numberOfTicks={4}
              formatLabel={value => `$${abbreviateNumber(value, 1)}`}
            />
            <View style={{ flex: 1, marginHorizontal: 5 }}>
              <StackedBarChart
                style={{ width: '100%', height: 300, zIndex: 99 }}
                spacingInner={0.2}
                data={data}
                keys={['ppmt', 'ipmt']}
                colors={[primary, lightblue]}
              />
              <XAxis
                style={{ paddingTop: 10, marginHorizontal: -10, height: xAxisHeight }}
                data={data}
                formatLabel={(value, index) => {
                  if (type == 'Mortgage' || type == 'Student Loans') {
                    if (index % 6 == 0 || index + 1 == period) {
                      return data[index].year
                    }
                  } else {
                    if (index % 12 == 0 || index + 1 == period) {
                      return Math.ceil(currentYear + (index / 12))
                    }
                  }
                }}
                contentInset={horizontalContentInset}
                svg={axesSvg}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

function PaydownLineChart({ period, type, balance, ppmt, ipmt }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const data = []
  const iterations = type == 'Mortgage' || type == 'Student Loans' ? balance.length / 12 : balance.length
  const currentYear = moment().year()

  times(iterations, (idx) => {
    data.push({
      ppmt: type == 'Mortgage' || type == 'Student Loans' ? ppmt[idx * 12] : ppmt[idx],
      ipmt: type == 'Mortgage' || type == 'Student Loans' ? ipmt[idx * 12] : ipmt[idx],
      year: type == 'Mortgage' || type == 'Student Loans' ? currentYear + idx + 1 : idx + 1,
      balance: type == 'Mortgage' || type == 'Student Loans' ? balance[idx * 12] : balance[idx]
    })
  })

  const verticalContentInset = { top: 0, bottom: 10 }
  const horizontalContentInset = { left: 20, right: 20 }
  const xAxisHeight = 40
  const axesSvg = { fontSize: 10, fill: 'grey' }

  return (
    <View style={{ alignItems: 'center' }}>
      <HeaderLabel style={{ marginTop: DEFAULT_SPACING }}>Balance</HeaderLabel>

      <View style={{ position: 'relative', width: '100%' }}>
        <Text style={{ position: 'absolute', top: 125, left: -20, zIndex: 100, transform: [{ rotate: '270deg' }] }}>Amount</Text>

        <View style={{ width: '100%', paddingLeft: 40, paddingRight: isNotWeb ? 40 : 0, alignItems: 'center', justifyContent: 'center' }}>
          <View style={styles.chartContainer}>
            <YAxis
              style={{ marginTop: 10 }}
              data={data}
              contentInset={verticalContentInset}
              yAccessor={({ item }) => item.balance}
              xAccessor={({ item }) => item.year}
              svg={{
                fill: gunmetal,
                fontSize: 8
              }}
              numberOfTicks={4}
              formatLabel={(value) => `$${abbreviateNumber(value)}`}
            />
            <View style={{ flex: 1, marginHorizontal: 5 }}>
              <AreaChart
                style={{ width: '100%', height: 300, zIndex: 100 }}
                data={balance}
                curve={shape.curveNatural}
                contentInset={{ top: 30, bottom: 0, left: -1 }}
                numberOfTicks={4}
                svg={{ stroke: pink, fill: rgba(pink, 0.2) }}
              >
                <Grid
                  svg={{
                    strokeWidth: 1,
                    strokeDasharray: [5, 5]
                  }}
                />
              </AreaChart>
              <XAxis
                style={{ paddingTop: 10, marginHorizontal: -10, height: xAxisHeight }}
                data={data}
                formatLabel={(value, index) => {
                  if (type == 'Mortgage' || type == 'Student Loans') {
                    if (index % 6 == 0 || index + 1 == period) {
                      return data[index].year
                    }
                  } else if (index % 12 == 0 || index + 1 == period) {
                    return Math.ceil(currentYear + (index / 12))
                  }
                }}
                contentInset={horizontalContentInset}
                svg={axesSvg}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

function LoanTable({ type, period, percent, amount, monthlyPaymentAmount, totalCostOfMortgage }) {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const width = useWindowDimensions().width

  const tableData = [
    [`$${formatCurrency(amount)}`],
    [formatPercent(percent)],
    [`${period} ${type == 'Mortgage' || type == 'Student Loans' ? 'Years' : 'Months'}`],
    [`$${formatCurrency(totalCostOfMortgage)}`],
    [`$${formatCurrency(monthlyPaymentAmount)}`]
  ]

  const tableTitle = [
    'Loan Amount',
    'Rate',
    'Term',
    'Total Cost',
    'Monthly Payment'
  ]

  const tableConfig = {
    tableTitle: tableTitle,
    tableData: tableData
  }

  return (
    <Table>
      <ScrollView>
        <TableWrapper style={[styles.wrapper]}>
          <Table style={[styles.table]} borderStyle={styles.tableBorder}>
            <Col data={tableConfig.tableTitle} heightArr={map(tableTitle, () => 50)} textStyle={styles.title} />
          </Table>

          <Table style={{ flex: 1 }} borderStyle={styles.tableBorder}>
            {map(tableConfig.tableData, (data, index) => {
              const backgroundColor = (index % 2) == 0 ? 'rgba(52, 145, 247, 0.05)' : 'white'
              return (
                <Row
                  widthArr={[width - 50]}
                  data={data}
                  style={[styles.row, { backgroundColor }]}
                  textStyle={styles.text}
                />
              )
            })}
          </Table>
        </TableWrapper>
      </ScrollView>
    </Table>
  )
}

export const LoanCalculatorResults = ({ title, period, amount, interestRate, output }) => {
  const styles = isNotWeb ? mobileStyles : useStyles()
  const monthlyPaymentAmount = output.result.monthly
  const totalCostOfMortgage = output.result.total

  function currencyFormat(num, decimalPlaces = 2) {
    return `$${num.toFixed(decimalPlaces).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
  }

  return (<ScrollView style={styles.resultsWrapper} contentContainerStyle={{ paddingBottom: DEFAULT_SPACING * 4 }}>
    <View style={styles.mainResults}>
      <View>
        <Text style={styles.headerText}>Monthly Payment Amount</Text>
        <Text style={styles.moneyText}>{currencyFormat(monthlyPaymentAmount)}</Text>
      </View>
      <View style={{ marginLeft: isNotWeb ? 0 : 24 }}>
        <Text style={styles.headerText}>Total Cost of {title}</Text>
        <Text style={styles.moneyText}>{currencyFormat(totalCostOfMortgage, 0)}</Text>
      </View>
    </View>

    <View style={styles.paragraphWrapper}>
      <PaydownChart
        type={title}
        period={period}
        balance={output.result.detail.balance}
        ppmt={output.result.detail.ppmt}
        ipmt={output.result.detail.ipmt}
      />
    </View>
    <View style={styles.paragraphWrapper}>
      <PaydownLineChart
        type={title}
        period={period}
        balance={output.result.detail.balance}
        ppmt={output.result.detail.ppmt}
        ipmt={output.result.detail.ipmt}
      />
    </View>

    <View style={[styles.paragraphWrapper, { paddingTop: 48 }]}>
      <LoanTable
        type={title}
        period={period}
        amount={amount}
        percent={interestRate}
        monthlyPaymentAmount={monthlyPaymentAmount}
        totalCostOfMortgage={totalCostOfMortgage}
      />
    </View>
  </ScrollView>)
}

export default function LoanCalculatorResultsScreen({ navigation, route }) {
  const { params } = route
  const { title } = params

  useLayoutEffect(() => {
    navigation.setOptions({ subtitle: title })
  }, [navigation, title])

  return (
    <Container>
      <LoanCalculatorResults {...params} />
    </Container>
  );
}
