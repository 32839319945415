import { useEffect, useState } from 'react'
import { ActivityIndicator, Platform, StyleSheet, Text, View } from 'react-native'

import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../Button'
import Card from '../Card'
import HeaderLabel from '../HeaderLabel'
import { DEFAULT_SPACING, darkblue, success, white } from '../../styles'
import { riskAssessmentSelector } from '../../selectors'
import { getAssessScore } from '../../actions'

const Icons = {
  willingness: 'shapes',
  ability: 'hand'
}

const basicStyles = {
  container: {
    zIndex: 2,
    padding: DEFAULT_SPACING * 2,
    backgroundColor: white,
    borderRadius: 20,
    alignItems: 'center',
    textAlign: 'center'
  },
  headerWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12
  },
  title: { fontWeight: '600', color: darkblue },
  viewMore: {
    fontSize: 14,
    color: '#3491F7'
  },
  description: {
    width: '100%',
    fontSize: 10,
    fontWeight: '500',
    color: '#707A7D'
  },
  takeAssessmentBtn: {
    marginTop: DEFAULT_SPACING * 3,
    marginHorizontal: 60
  },
  scoreContainer: { flexDirection: 'row', width: '100%', marginTop: DEFAULT_SPACING }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.EXTRA_SMALL_DEVICE)]: {
    container: { height: '100%' },
    scoreContainer: { marginTop: 'auto' }
  }
})

function MyRiskProfileCard({ navigation }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [isSelfAssessed, setIsSelfAssessed] = useState()
  const [data, setData] = useState({
    ability: null,
    willingness: null
  })
  const { assessScore, loading, setting, error } = useSelector((state) =>
    riskAssessmentSelector(state)
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAssessScore())
  }, [dispatch])

  const handleClick = () => {
    navigation.navigate('Modal', {
      screen: isSelfAssessed ? 'Risk Profile' : 'My Risk Profile'
    })
  }

  useEffect(() => {
    if (assessScore && Object.keys(assessScore).length !== 0) {
      const { ability, willingness, is_self_assessed } = assessScore
      setData({ willingness, ability })
      setIsSelfAssessed(is_self_assessed)
    }
  }, [assessScore])

  if (loading || setting) {
    return (
      <Card style={styles.container}>
        <View style={styles.headerWrapper}>
          <HeaderLabel style={styles.title}>My Risk Profile</HeaderLabel>
        </View>
        <ActivityIndicator
          style={{ paddingVertical: DEFAULT_SPACING * 3 }}
          color={success}
          size="small"
        />
      </Card>
    )
  }

  if (error) {
    return (
      <Card style={styles.container}>
        <View style={styles.headerWrapper}>
          <HeaderLabel style={styles.title}>My Risk Profile</HeaderLabel>
        </View>
        <Text style={{ paddingVertical: DEFAULT_SPACING * 3 }}>{error}</Text>
      </Card>
    )
  }

  return (
    <Card style={styles.container}>
      <View style={styles.headerWrapper}>
        <HeaderLabel style={styles.title}>My Risk Profile</HeaderLabel>
        {data.ability && (
          <TouchableOpacity onPress={() => handleClick()}>
            <Text style={styles.viewMore}>View More</Text>
          </TouchableOpacity>
        )}
      </View>
      <Text style={styles.description}>
        Your willingness and ability to take risk play an important role in helping achieve your
        financial goals.
      </Text>
      {!data.ability ? (
        <Button
          color="#5F8EE8"
          style={styles.takeAssessmentBtn}
          titleStyle={{ fontSize: 14 }}
          onPress={() => navigation.navigate('Modal', { screen: 'Risk Profile' })}
        >
          Take Assessment
        </Button>
      ) : (
        <View style={styles.scoreContainer}>
          {Object.keys(data).map((key) => (
            <View key={key} style={{ flexDirection: 'row', width: '50%' }}>
              <View
                style={{
                  shadowColor: '#3A424B',
                  shadowOffset: { width: 0, height: 17 },
                  shadowOpacity: 0.11,
                  shadowRadius: 11,
                  elevation: 5,
                  width: 40,
                  height: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  borderRadius: 20
                }}
              >
                <FontAwesomeIcon color="#3491F7" icon={['fas', Icons[key]]} size={16} />
              </View>
              <View style={{ marginLeft: 14 }}>
                <Text
                  style={{
                    fontSize: 12,
                    color: '#2F3946',
                    fontWeight: '600',
                    textTransform: 'capitalize'
                  }}
                >
                  {key}
                </Text>
                <Text
                  style={{
                    color: '#7CBA82',
                    fontSize: 12,
                    fontWeight: '600',
                    marginTop: 4
                  }}
                >
                  {data[key].toFixed(2) * 1}%
                </Text>
              </View>
            </View>
          ))}
        </View>
      )}
    </Card>
  )
}

export default MyRiskProfileCard
