import { Text, View } from 'react-native'
import { Slider } from 'react-native-elements'
import { darkblue, white } from '../../styles'

function ContributionSlider({ value = 5, onChange }) {
  return (
    <View style={{ width: '100%', marginTop: 32 }}>
      <Text
        style={{
          fontSize: 16,
          color: darkblue,
          zIndex: 1,
          paddingBottom: 8,
          marginBottom: -8
        }}
      >
        Employee Contribution: {value}%
      </Text>
      <Slider
        style={{ transform: 'scaleY(2)' }}
        allowTouchTrack
        value={value}
        minimumValue={0}
        maximumValue={20}
        minimumTrackTintColor="#7CBA82"
        maximumTrackTintColor="#ECF2F7"
        step={1}
        onSlidingComplete={onChange}
        thumbStyle={{ height: 12, width: 12, backgroundColor: 'transparent' }}
        thumbProps={{
          children: (
            <View
              style={{
                width: 8,
                height: 4,
                borderWidth: 1,
                top: 4,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <View
                style={{
                  width: 32,
                  height: 32,
                  borderRadius: 16,
                  transform: 'scaleY(0.5)',
                  borderWidth: 1,
                  borderColor: '#BEC6CD',
                  backgroundColor: white,
                  // top: -9,
                  shadowColor: '#000000',
                  shadowOffset: { width: 0, height: 2 },
                  shadowOpacity: 0.2,
                  shadowRadius: 8
                }}
              />
            </View>
          )
        }}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: -8,
          zIndex: 1,
          paddingTop: 8
        }}
      >
        {new Array(0, 5, 10, 15, 20).map((val) => (
          <Text
            key={val}
            style={{ fontSize: 12, fontWeight: '500', color: darkblue }}
          >{`${val}%`}</Text>
        ))}
      </View>
    </View>
  )
}

export default ContributionSlider
