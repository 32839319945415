import { View, StyleSheet } from 'react-native'
import { createElement } from 'react-native-web'

import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

const basicStyles = StyleSheet.create({
  container: {
    justifyContent: 'center', 
    alignSelf: 'center',
    alignItems: 'center',
    height: 200,
    width: 400,
  }
})

const useStyles = CreateResponsiveStyle(
  basicStyles,
  { 
    [minSize(DEVICE_SIZES.MD)]: {
      container: {
        height: 300,
        width: 600,
      }
    },
    [minSize(DEVICE_SIZES.LG)]: {
      container: {
        height: 500,
        width: 1000,
      }
    }
  }
)

export default function _YoutubePlayer({ videoId }) {
  const styles = useStyles()

  return (
    <View style={styles.container}>
    {
      createElement('iframe', {
        width: '100%',
        height: '100%',
        src: `https://www.youtube.com/embed/${videoId}`,
        frameborder: 0,
        allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
        allowfullscreen: true
      })
    }
    </View>
  );
}
