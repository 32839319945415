import PropTypes from 'prop-types'

/* Borrowed from https://github.com/react-navigation/react-navigation/issues/104#issuecomment-276387578 */
// eslint-disable-next-line import/prefer-default-export
export const NAVIGATION_TYPE = PropTypes.shape({
  dispatch: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
  state: PropTypes.shape({
    key: PropTypes.string.isRequired,
    routeName: PropTypes.string.isRequired,
    path: PropTypes.string,
    params: PropTypes.object
  }).isRequired
}).isRequired;
