import { find } from 'lodash';
import { ASSET } from '../constants';

export default (state, accountId, accountType) => {
  if (!state.bsAccounts) {
    return null
  }

  const allBsAccounts = state.bsAccounts
  const assetAccounts = allBsAccounts.assetAccounts 
  const liabilityAccounts = allBsAccounts.liabilityAccounts

  const bsAccounts = accountType ? 
    (accountType === ASSET ? assetAccounts : liabilityAccounts)
    : [...assetAccounts, ...liabilityAccounts]

  return find(bsAccounts, (account) => account.accountId === accountId)
};
