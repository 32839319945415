import { useLayoutEffect, useState, useEffect } from 'react'
import { View, Text, ScrollView, StyleSheet } from 'react-native'
import { get } from 'http'
import { groupBy } from 'lodash'
import Container from './components/Container'
import CircleImage from './components/CircleImage'
import PillLabel from './components/PillLabel'
import Divider from './components/Divider'
import TouchableOpacity from './components/TouchableOpacity'
import { DEFAULT_SPACING } from './styles'

const styles = StyleSheet.create({
  finmojiContainer: {
    marginBottom: DEFAULT_SPACING * 2,
    marginHorizontal: DEFAULT_SPACING / 2,
    width: '21%',
    alignItems: 'center'
  },
  finmojiLabel: {
    fontSize: 10,
    textAlign: 'center'
  },
  finmojiSectionContainer: { marginVertical: DEFAULT_SPACING * 2 },
  finmojiSectionDividerContainer: {
    marginTop: 0,
    position: 'relative'
  },
  finmojiSectionTrusteesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: DEFAULT_SPACING * 2
  }
})

function FinmojiCircle({ navigation, trustee }) {
  function handlePress() {
    navigation.navigate('Modal', { screen: 'Finmoji', params: { finmoji: trustee } })
  }

  return (
    <TouchableOpacity action={`Browse Finmoji: ${trustee.display_name}`} onPress={handlePress} style={styles.finmojiContainer}>
      <CircleImage
        size={70}
        url={trustee.image_url}
      />
      <Text style={styles.finmojiLabel}>{trustee.display_name}</Text>
    </TouchableOpacity>
  )
}

function FinmojiSection({ navigation, title, trustees }) {
  return (
    <View style={styles.finmojiSectionContainer}>
      <View style={styles.finmojiSectionDividerContainer}>
        <Divider style={{ width: '90%' }} />
        <PillLabel title={title} width={200} style={{ alignSelf: 'center', position: 'absolute', top: -10 }} />
      </View>

      <View style={styles.finmojiSectionTrusteesContainer}>
        {trustees.map((trustee, index) => (
          <FinmojiCircle
            key={index}
            trustee={trustee}
            navigation={navigation}
          />
        ))}
      </View>
    </View>
  )
}

export default function FinmojiLibraryScreen({ navigation, route }) {
  const { params } = route
  const { trustees } = params

  useLayoutEffect(() => {
    navigation.setOptions({ title: 'All Trustees', })
  }, [navigation])

  const filteredTrustees = trustees.filter((trustee) => trustee.enabled)
  const groupedTrustees = groupBy(filteredTrustees, 'bias')

  return (
    <Container>
      <ScrollView>
        {Object.keys(groupedTrustees).map((key, index) => (
          <FinmojiSection
            key={index}
            navigation={navigation}
            title={key}
            trustees={groupedTrustees[key]}
          />
        ))}
      </ScrollView>
    </Container>
  )
}
