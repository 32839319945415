import { useLayoutEffect, useEffect, useState } from 'react'
import { View, ScrollView, StyleSheet, Platform, Text, TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, minSize } from 'rn-responsive-styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Formik } from 'formik'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { get } from 'http'
import { useNavigationState } from '@react-navigation/native'
import CurrencyInput from './components/CurrencyInput'

import BottomButton from './components/BottomButton'
import HeaderLabel from './components/HeaderLabel'
import InformationBlock from './components/InformationBlock'
import timeout from './functions/timeout'
import SlideupDrawer from './components/SlideupDrawer'

import { formatFinancialPayload } from './api/financialBreakdown'
import { currentPlanSelector } from './selectors'
import Link from './components/Link'
import Divider from './components/Divider'

import { Fields } from './schemas/customGoalSchema'

import { saveCurrentPlan } from './actions'

import { DEFAULT_SPACING } from './styles'

import { lightestgray, white, gunmetal } from './styles/colors'

import BuilderWrapper from './components/plans/BuilderWrapper'
import formatCurrency from './functions/formatCurrency'
import { AVERAGE, BEST, WORST } from './constants'

const basicStyles = {
  formContainer: { flex: 1 },
  container: {
    backgroundColor: lightestgray,
    paddingVertical: 12
  },
  header: {
    textAlign: 'center',
    marginBottom: DEFAULT_SPACING * 2
  },
  subheader: {
    marginBottom: DEFAULT_SPACING * 2,
    color: gunmetal,
    marginLeft: 50,
    marginRight: 50,
    textAlign: 'center',
    alignSelf: 'center'
  },
  estimatedValueText: {
    textAlign: 'center',
    marginTop: 4,
    fontSize: 35,
    fontWeight: '700'
  },
  divider: {
    marginVertical: DEFAULT_SPACING,
    margin: 50
  },
  goalsubheader: {
    textAlign: 'center',
    marginTop: 4,
    fontSize: 12
  },
  editButton: {
    color: '#4F8FEF',
    top: -8,
    left: 10
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(basicStyles, {
  [minSize(DEVICE_SIZES.MD)]: {
    formContainer: {
      borderRadius: 20,
      maxHeight: 480,
      maxWidth: 800,
      paddingHorizontal: 16,
      paddingTop: 26,
      backgroundColor: white
    }
  },
  [minSize(DEVICE_SIZES.LG)]: {}
})

export default function GoalLockin({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const navigationState = useNavigationState((state) => state)
  const dispatch = useDispatch()

  const form = useSelector((state) => currentPlanSelector(state))
  const { params } = route
  const {
    title,
    subtitle,
    builder,
    savingsGoal,
    estimatedValue,
    buttonText,
    investorProfile,
    scenario
  } = params

  // needs to be fixed when backend is functioning, for now this just
  // changes the goal to a custom_goal when edited
  const [yourGoal, setYourGoal] = useState(0)
  const isPension = investorProfile === 'PENSION'
  const [visible, setVisible] = useState(false)
  const planValues = { savingsGoal }

  async function fetchPlanDetails(id) {
    const response = await get(`api/v1/plans/${id}/`)
    const summayStats = response.data.summary_stats

    if (scenario && !isPension) {
      if (scenario == WORST) {
        setYourGoal(summayStats.worst.projected_ending_value)
      } else if (scenario == BEST) {
        setYourGoal(summayStats.best.projected_ending_value)
      } else if (scenario == AVERAGE) {
        setYourGoal(summayStats.average.projected_ending_value)
      }
    } else {
      setYourGoal(response.data.savings_goal)
    }
  }
  function restrictValueBetweenMinMax(valueGoal) {
    return Math.max(0, Math.min(Number.isNaN(valueGoal) ? 0 : valueGoal, 1000000000))
  }
  useEffect(() => {
    fetchPlanDetails(form.id)
  }, [form])

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      headerTintColor: white,
      title: null
    })
  }, [title, subtitle])

  return (
    <BuilderWrapper includeTopper={isNotWeb} option="Review">
      <View style={{ width: '100%', marginBottom: 24 }}>
        <TouchableOpacity onPress={() => navigation.pop()}>
          <FontAwesomeIcon icon={faArrowLeft} color="white" size={32} />
        </TouchableOpacity>
      </View>
      <View style={styles.formContainer}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: isNotWeb ? 120 : 140 }}
        >
          <View>
            <HeaderLabel style={styles.header}>Goal</HeaderLabel>
            <Text style={styles.subheader}>
              Based on your inputs, we have calculated your estimated future value.
            </Text>
            <Text style={styles.goalsubheader}>Your Goal</Text>
            <Text style={styles.estimatedValueText}>${formatCurrency(yourGoal)}</Text>
            {!isPension && (
              <Link
                onPress={() => {
                  setVisible(true)
                }}
              >
                <Text style={{ color: '#4F8FEF', marginLeft: 25 }}>
                  <FontAwesomeIcon
                    style={{
                      fontSize: 0,
                      marginLeft: 'auto',
                      marginRight: 5,
                      color: '#4F8FEF',
                      transform: [{ scaleX: 0.9 }, { scaleY: 0.9 }]
                    }}
                    icon={faPencilAlt}
                  />
                  Edit
                </Text>
              </Link>
            )}
            <Divider style={styles.divider} />
            <Text style={styles.subheader}>
              Setting goals can be hard and personal situations vary, but here are some helpful
              rules of thumb:
            </Text>
          </View>
          <View style={{ marginLeft: 30, marginRight: 30 }}>
            <InformationBlock text="Accumulate 10x your earned income in savings/investments" />
            <InformationBlock text="Replace between 70-100% of your annual living costs with a withdrawal rule of 3%, 4%, or 5%" />
          </View>
        </ScrollView>
        <BottomButton
          withMargin
          onPress={async () => {
            await timeout(600)
            const index = navigationState.routes.findIndex(
              (routeIndex) => routeIndex.name === 'Plan Summary'
            )
            setTimeout(() => {
              const currentPlan = {
                ...form,
                step: form.step === 2 ? 3 : form.step
              }
              currentPlan.savings_goal = yourGoal
              currentPlan.is_custom_goal = yourGoal !== estimatedValue
              currentPlan.expectation_selection = scenario

              dispatch(saveCurrentPlan(formatFinancialPayload(currentPlan)))

              if (builder) {
                navigation.reset({
                  index: 1,
                  routes: [
                    {
                      name: 'Main',
                      state: {
                        index: 0,
                        routes: [{ name: 'Plan Builder' }]
                      }
                    },
                    {
                      name: 'Modal',
                      state: {
                        routes: [
                          {
                            name: 'Review',
                            params: {
                              title: 'Plan Builder',
                              subtitle: 'Review',
                              builder
                            }
                          }
                        ]
                      }
                    }
                  ]
                })
              } else {
                navigation.pop(navigationState.routes.length - index - 1)
              }
            }, 500)
          }}
        >
          {buttonText}
        </BottomButton>
        <View>
          <SlideupDrawer scrollable snapPointIndex={1} visible={visible} setVisible={setVisible}>
            <Formik
              enableReinitialize
              showSubmitButton={false}
              initialValues={planValues}
              onSubmit={async (values) => {
                await timeout(600)
                setYourGoal(restrictValueBetweenMinMax(values.savingsGoal))
              }}
            >
              {({ touched }) => (
                <>
                  <CurrencyInput
                    prefix="$"
                    label="End Goal Amount"
                    value={restrictValueBetweenMinMax(yourGoal)}
                    style={{ margin: 10 }}
                    name={Fields.savings_goal}
                    touched={touched[Fields.savings_goal]}
                    setValue={(e) => {
                      setYourGoal(restrictValueBetweenMinMax(e))
                    }}
                    precision={0}
                  />
                  <BottomButton
                    style={{ position: 'relative', margin: 30 }}
                    onPress={() => {
                      setVisible(false)
                    }}
                  >
                    Save
                  </BottomButton>
                </>
              )}
            </Formik>
          </SlideupDrawer>
        </View>
      </View>
    </BuilderWrapper>
  )
}
