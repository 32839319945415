import { useLayoutEffect, useEffect, useState } from 'react'
import { View, ScrollView, StyleSheet, Platform } from 'react-native'

import { useDispatch, useSelector } from 'react-redux'

import { get } from 'http'
import InvestmentCards from './components/InvestmentCards'
import BottomButton from './components/BottomButton'

import { currentPlanSelector } from './selectors'

import { saveCurrentPlan, setCurrentPlan, setLoading } from './actions'

import { lightestgray, white, primary } from './styles/colors'
import handleError from './functions/handleError'

import BuilderWrapper from './components/plans/BuilderWrapper'

import LoadingIcon from './components/planBuilder/LoadingIcon'

const styles = StyleSheet.create({
  container: {
    backgroundColor: lightestgray,
    paddingVertical: 12
  }
})

export default function ReviewPlanScreen({ navigation, route }) {
  const isNotWeb = Platform.OS !== 'web'
  const dispatch = useDispatch()
  const form = useSelector((state) => currentPlanSelector(state))
  const { params } = route
  const { title, subtitle } = params
  const [plan, setPlan] = useState(form)

  async function fetchPlanDetails(id) {
    const response = await get(`api/v1/plans/${id}/`)
    setPlan({ ...form, ...response.data })
  }

  useEffect(() => {
    fetchPlanDetails(form.id)
  }, [form])
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: isNotWeb,
      headerTransparent: true,
      headerStyle: {
        elevation: 0,
        shadowOpacity: 0
      },
      headerTitleStyle: { color: white },
      headerTintColor: white,
      title: null
    })
  }, [title, subtitle])

  return (
    <BuilderWrapper includeTopper option="Review">
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 120 }}
      >
        <InvestmentCards navigation={navigation} dispatch={dispatch} plan={plan} form={form} />
      </ScrollView>

      <BottomButton
        withMargin
        disabled={form.step < 3}
        onPress={async () => {
          try {
            await saveCurrentPlan(form)
            await dispatch(setCurrentPlan(form))

            dispatch(
              setLoading({
                loading: true,
                fullScreen: true,
                loadingMessage: 'Building your financial plan...',
                useGradient: true,
                fullScreenOpacity: 1,
                fullScreenIconComponent: <LoadingIcon />
              })
            )

            setTimeout(() => {
              navigation.reset({
                index: 1,
                routes: [
                  {
                    name: 'Main',
                    state: {
                      index: 0,
                      routes: [{ name: 'Plan Builder' }]
                    }
                  },
                  {
                    name: 'Modal',
                    state: {
                      routes: [
                        {
                          name: 'Plan Summary',
                          params: form
                        }
                      ]
                    }
                  }
                ]
              })

              dispatch(
                setLoading({
                  loading: false,
                  fullScreen: false,
                  loadingMessage: null,
                  useGradient: null,
                  fullScreenOpacity: null,
                  fullScreenIconComponent: null
                })
              )
            }, 1600)
          } catch (error) {
            handleError(error, 'Oops. Looks like something went wrong.')

            dispatch(
              setLoading({
                loading: false,
                fullScreen: false,
                loadingMessage: null,
                useGradient: null,
                fullScreenOpacity: null,
                fullScreenIconComponent: null
              })
            )
          }
        }}
      >
        Build My Financial Plan
      </BottomButton>
    </BuilderWrapper>
  )
}
