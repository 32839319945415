import { Platform, View, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize
} from 'rn-responsive-styles'
import Card from '../Card'
import { DEFAULT_SPACING, rgba, lightgray, white } from '../../styles'
import Divider from '../Divider'
import { FlatList } from 'react-native-gesture-handler'
import { has, forEach } from 'lodash'
import BudgetBreakdownCard from '../budgeting/BudgetBreakdownCard'
import moment from 'moment'
import MonthCongradulations from './MonthCongradulations'

const basicStyles = {
  card: {
    padding: 0,
    margin: DEFAULT_SPACING,
    shadowColor: 'transparent',
    borderRadius: 6,
    backgroundColor: white,
    position: 'relative',
    paddingTop: DEFAULT_SPACING,
  }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  {
    [minSize(DEVICE_SIZES.LG)]: {
      card: {
        margin: 0,
        top: 0,
        borderRadius: 16,
        width: 'calc(33.33% - 12.67px)',
        maxWidth: 350,
        marginRight: DEFAULT_SPACING,
      },
    }
  }
)

const isNotWeb = Platform.OS !== 'web'

const sumTransactions = (key, transactions) => {
  let actualTotal = 0
  let filteredTransactions = []

  if (key) {
    forEach(transactions, (transaction) => {
      if (transaction.budgetCategory == key) {
        actualTotal += transaction.amount
        filteredTransactions.push(transaction)
      }
    })
  } else {
    forEach(transactions, (transaction) => {
      if (transaction.budgetCategory !== 'INCOME') {
        actualTotal += transaction.amount
        filteredTransactions.push(transaction)
      }
    })
  }

  return { actualTotal, filteredTransactions }
}

export default function BudgetForMonthCard({
  budgetCategories,
  projectedBudget = {},
  transactions = [],
  header,
  navigation
}) {
  const styles = isNotWeb ? mobileStyles : useStyles()

  const renderBudgetCategoryData = ({ item: budgetCategory }) => {
    if (!has(projectedBudget.budgetItemsByCategory, budgetCategory)) {
      return null
    }

    const { name, total, icon, color } =
      projectedBudget.budgetItemsByCategory[budgetCategory]
    const { actualTotal, filteredTransactions } = sumTransactions(
      budgetCategory,
      transactions
    )

    return (
      <BudgetBreakdownCard
        title={name}
        budgetAmount={total}
        actualAmount={actualTotal}
        isIncome={
          budgetCategory === 'INCOME' ||
          budgetCategory === 'SAVE_FIRST_CONTRIBUTION_TARGET'
        }
        iconProps={{
          size: 14,
          icon,
          color,
          backgroundColor: rgba(color, 0.1)
        }}
        onPress={() => {
          navigation.navigate('Modal', {
            screen: 'Budget Category Details',
            params: {
              budgetCategoryKey: budgetCategory,
              transactions: filteredTransactions,
              selectedDate: moment()
            }
          })
        }}
        style={{
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderColor: lightgray,
          marginBottom: DEFAULT_SPACING,
        }}
      />
    )
  }

  const actual = transactions.reduce((sum, transaction) => {
    if (transaction.budgetCategory !== 'INCOME') {
      return sum + transaction.amount
    } else {
      return sum
    }
  }, 0)

  const remainingAmount = projectedBudget.totalExpenses - actual

  const keys = budgetCategories.map((category) => category.key)

  return (
    <Card style={styles.card}>
      <MonthCongradulations
        remainingAmount={remainingAmount}
        actual={actual}
        totalExpenses={projectedBudget.totalExpenses}
        header={header}
      />

      <Divider />

      <FlatList data={keys} renderItem={renderBudgetCategoryData} />
    </Card>
  )
}
