import { StyleSheet, View, TextInput } from 'react-native'
import { useState, useRef } from 'react'
import { red } from '../styles/colors'
import InputWrapper from './InputWrapper'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(242, 248, 250, 0.9)',
    flexDirection: 'column',
    padding: 2 * 5,
    borderRadius: 2 * 3,
    marginBottom: 24,
    borderWidth: 1
  },
  label: {
    color: 'rgb(66, 86, 103)',
    fontSize: 12
  },
  input: {
    color: 'rgb(66, 86, 103)',
    fontSize: 18,
    marginTop: 2 * 2,
    fontWeight: '500',
    paddingVertical: 0
  },
  error: {
    borderColor: red,
    borderWidth: 1,
  },
  suffix: {
    position: 'absolute',
    fontSize: 18,
    top: 4,
    color: 'rgb(66, 86, 103)',
    fontWeight: '600',
  },
  errorStyle: {
    paddingVertical: 6,
    paddingHorizontal: 6
  },
  error: {
    color: red,
    marginBottom: 12,
    fontSize: 12
  }
})

export default function NumberInput(props) {
  const { ref, placeholder, containerStyle, errorMessage, touched, returnKeyType, onSubmitEditing, style, disabled, label, value, type, precision, suffixUnit, unit, setValue, onChangeText, onPress } = props
  // The reason we need the ref is so that if the user
  // presses on the touchable without feedback, we can focus on the
  // input. The user could also touch the label and we'd
  // want to do the same thing and focus on the input.
  const inputElement = ref || useRef(null)
  const [size, setSize] = useState({ width: 0 })

  function inputPress() {
    if (onPress) onPress()
    setTimeout(() => {
      if (inputElement) {
        inputElement.current.focus()
      }
    })
  }

  const mask = '[990]{.}[99]'

  return (
    <InputWrapper {...props} inputElement={inputElement} onPress={() => inputPress()}>
      <View>
        <TextInput
          keyboardType="numeric"
          placeholder={placeholder}
          mask={mask}
          ref={inputElement}
          editable={!disabled}
          value={value || ''}
          style={[
            containerStyle,
            styles.input,
            { borderBottomColor: touched && errorMessage ? 'red' : 'transparent' },
          ]}
          onChangeText={(val) => {
            if (setValue) setValue(val)
            if (onChangeText) onChangeText(val)
          }}
          onBlur={() => {
            if (value && value[value.length - 1] == '.') {
              if (setValue) setValue(value.slice(0, -1))
              if (onChangeText) onChangeText(value.slice(0, -1))
            }
          }}
          onPress={inputPress}
          onSubmitEditing={onSubmitEditing}
        />
      </View>
    </InputWrapper>
  )
}
