import { StyleSheet, Text, View } from 'react-native'
import { black, white } from '../../../styles'

const styles = StyleSheet.create({
  centerAxisGroup: {
    position: 'absolute',
    left: 24,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerAxis: {
    backgroundColor: '#2C3A4833',
    position: 'absolute',
    overflow: 'visible',
  },
  centerY: {
    width: 2,
    height: '100%',
    alignItems: 'center',
  },
  centerX: {
    width: '100%',
    height: 2,
    justifyContent: 'center',
  },
  centerLabel: { position: 'absolute', fontSize: 10 },
  centerYLabelTop: { top: 0, left: 6, width: 50 },
  centerYLabelBottom: { bottom: 0, left: 6, width: 40 },
  centerYTitle: {
    color: white,
    backgroundColor: black,
    borderRadius: 6,
    paddingHorizontal: 4,
    overflow: 'hidden',
    width: 40,
    top: '23%'
  },
  centerXLabelTop: { right: 0, top: 8 },
  centerXLabelBottom: { left: 0, top: 8 },
  centerXTitle: {
    left: 20,
    color: white,
    backgroundColor: black,
    borderRadius: 6,
    paddingHorizontal: 4,
    overflow: 'hidden'
  },
})
function CenterAxisLabelGroup() {
  return (
    <View style={styles.centerAxisGroup}>
      <View style={[styles.centerAxis, styles.centerY]}>
        <Text style={[styles.centerLabel, styles.centerYLabelTop]}>High</Text>
        <Text style={[styles.centerLabel, styles.centerYLabelBottom]}>Low</Text>
        <Text style={[styles.centerLabel, styles.centerYTitle]}>Ability</Text>
      </View>
      <View style={[styles.centerAxis, styles.centerX]}>
        <Text style={[styles.centerLabel, styles.centerXLabelTop]}>High</Text>
        <Text style={[styles.centerLabel, styles.centerXLabelBottom]}>Low</Text>
        <Text style={[styles.centerLabel, styles.centerXTitle]}>Risk Willingness</Text>
      </View>
    </View>
  )
}

export default CenterAxisLabelGroup
