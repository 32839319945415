import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { StyleSheet } from 'react-native'
import hapticFeedback from '../hapticFeedback'
import TouchableOpacity from '../TouchableOpacity'

const styles = StyleSheet.create({ icon: { marginHorizontal: 12 } })

export default function HeaderButton({ onPress, icon, iconColor, iconSize }) {
  return (
    <TouchableOpacity onPress={() => {
      hapticFeedback()
      if (onPress) onPress()
    }}
    >
      <FontAwesomeIcon
        icon={['fas', icon]}
        color={iconColor}
        size={iconSize}
        style={styles.icon}
      />
    </TouchableOpacity>
  )
}
