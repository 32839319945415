import { View, useWindowDimensions, StyleSheet } from 'react-native'
import { darkJungleGreen, kombuGreen, DEFAULT_SPACING } from 'styles'
import { Circle, Defs, Stop, Svg, RadialGradient } from 'react-native-svg'

const styles = StyleSheet.create({
  circularGradient: (height) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    overflow: 'hidden',
    height
  }),
  container: (height) => ({
    backgroundColor: darkJungleGreen,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height
  })
})

export default function CircleGradient({ height: height0 = 300 }) {
  const window = useWindowDimensions()
  const { width, height: wHeight } = window
  let height = height0
  if (typeof height === 'string' && height.endsWith('%'))
    height = (parseFloat(height0) * wHeight) / 100
  const bgHeight = height * 2
  const top = height * -1

  return (
    <View style={[styles.container(height)]}>
      <View style={[styles.circularGradient(height)]}>
        <Svg
          height={bgHeight}
          width="100%"
          style={{
            position: 'absolute',
            top
          }}
          viewBox={`0 0 ${width - DEFAULT_SPACING * 2} ${bgHeight}`}
        >
          <Circle cx="50%" cy="50%" r="50%" fill="url(#gradient)" />
          <Defs>
            <RadialGradient id="gradient" cx="50%" cy="50%" r="50%">
              <Stop offset="0%" stopColor={kombuGreen} />
              <Stop offset="100%" stopColor={darkJungleGreen} />
            </RadialGradient>
          </Defs>
        </Svg>
      </View>
    </View>
  )
}
