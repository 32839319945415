import { View, StyleSheet, Image, Text, useWindowDimensions, } from 'react-native'

import { LinearGradient } from 'expo-linear-gradient'
import CloseLeftButton from './CloseLeftButton'

import BottomButton from './BottomButton'
import FadeInView from './FadeInView'
import { darkblue, gunmetal, lightestgray, white } from '../styles/colors'
import { DEFAULT_SPACING } from '../styles'

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1
  },
  finn: {
    width: '65%',
    marginVertical: 20,
    height: 235
  },
  safeContainer: { flex: 1 },
  linearGradient: {
    width: '100%',
    position: 'absolute',
    zIndex: 0
  },
  title: {
    color: darkblue,
    fontSize: 20,
    fontWeight: '600',
  },
  text: {
    color: gunmetal,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
    width: '80%'
  },
  titleFirst: {
    marginTop: 0,
    marginBottom: DEFAULT_SPACING
  },
  titleSecond: { marginVertical: DEFAULT_SPACING }
})

export default function IntroScreen({
  onPress,
  closeable = true,
  reverseGradient,
  titleFirst,
  title,
  subtitle,
  subtitleStyle = {},
  children,
  disabled,
  buttonText,
  image,
  finnStyle
}) {
  const { height } = useWindowDimensions()

  return (
    <View style={styles.container}>
      <LinearGradient
        colors={
          reverseGradient
            ? [white, lightestgray, lightestgray]
            : [lightestgray, lightestgray, white]
        }
        style={[styles.linearGradient, { height }]}
        locations={[0, 0.2, 1]}
      />
      <View style={[styles.safeContainer, { height }]}>
        { closeable && <CloseLeftButton offset close /> }

        <View style={{ flex: 1, justifyContent: height > 668 ? 'center' : 'flex-start' }}>
          <FadeInView style={{ alignItems: 'center' }}>
            {
              titleFirst
              && title
              && (
                <Text style={[styles.title, styles.titleFirst]}>{title}</Text>
              )
            }
            {
              titleFirst
              && subtitle
              && (
                <Text style={[styles.text, subtitleStyle]}>{subtitle}</Text>
              )
            }

            <Image style={[styles.finn, finnStyle]} source={image} resizeMode="contain" />

            {
              !titleFirst
              && title
              && (
                <Text style={[styles.title, styles.titleSecond]}>{title}</Text>
              )
            }

            {
              !titleFirst
              && subtitle
              && (
                <Text style={[styles.text, subtitleStyle]}>{subtitle}</Text>
              )
            }

            { children }
          </FadeInView>
        </View>

        {
          buttonText
          && onPress
          && (
            <BottomButton withMargin disabled={disabled} onPress={onPress}>
              {buttonText}
            </BottomButton>
          )
        }
      </View>
    </View>
  )
}
