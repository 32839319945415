import { post } from 'http'
import { find, isEmpty } from 'lodash'
import proApplicationEndpoints from '../api/proApplicationEndpoints'
import handleError from '../functions/handleError'
import { currentUserSelector, plansSelector, primaryPlanSelector, primaryProPlanSelector } from '../selectors'
import { saveCurrentPlan } from './saveCurrentPlan'
import { setCurrentPlan } from './setCurrentPlan'
import { setCurrentProPlan } from './setCurrentProPlan'
import { setProPlan } from './setProPlan'
import { getPlans } from './getPlans'
import { getProPlans } from './getProPlans'

// eslint-disable-next-line import/prefer-default-export
export const assignPrimaryProPlan = (plan, isPrimary) => async (dispatch, getState) => {
  /* Don't make an unnecessary call if the primary values are the same */
  if (plan.isPrimary === isPrimary) {
    return
  }

  const state = getState()
  const { planId } = plan
  const { id: userId } = currentUserSelector(state)

  try {
    if (isPrimary) {
      await post(proApplicationEndpoints.activatePlan(userId, planId))
    } else {
      await post(proApplicationEndpoints.deactivatePlan(userId, planId))
    }

    const newProPlan = {
      ...plan,
      isPrimary
    }

    await dispatch(setProPlan({
      planId,
      plan: newProPlan
    }))

    await dispatch(setCurrentProPlan(newProPlan))
  } catch (error) {
    console.warn(error, error.response)
    handleError(error, 'Oops. Looks like something went wrong.')
  }

  /* Expecting state to be updated after dispatch */
  const underlyingFreePlan = find(
    plansSelector(state),
    (freePlan) => freePlan.id === plan.freePlanId
  )

  /**
   * If you are setting this pro plan to primary,
   * then set the underlying free plan to primary as */
  if (isPrimary) {
    /* Set the primary plan as current in state */
    await dispatch(setCurrentPlan(underlyingFreePlan))
    await dispatch(saveCurrentPlan({ is_primary_plan: true }))
  }

  // get all plans 
  dispatch(getPlans())
  dispatch(getProPlans())
}
