import { forwardRef, useRef } from 'react'
import {
  StyleSheet,
  TextInput,
  Keyboard,
  Platform
} from 'react-native'
import { grayblue } from '../styles/colors'

import InputWrapper from './InputWrapper'
import { DEFAULT_SPACING } from '../styles'

const styles = StyleSheet.create({
  input: {
    color: grayblue,
    fontSize: 18,
    marginTop: 2 * 2,
    fontWeight: '500',
    paddingVertical: 0,
    ...Platform.select({ web: { outlineStyle: 'none', } })
  }
})

const Input = function (props, ref) {
  const {
    keyboardType,
    icon,
    type,
    autoFocus,
    secureTextEntry,
    returnKeyType,
    onSubmitEditing,
    disabled,
    value,
    setValue,
    onChangeText,
    onFocus,
    placeholder,
    multiline,
    onBlur,
    blurOnSubmit,
  } = props

  // The reason we need the ref is so that if the user
  // presses on the touchable without feedback, we can focus on the
  // input. The user could also touch the label and we'd
  // want to do the same thing and focus on the input.
  const inputElement = ref || useRef(null)

  function onPress() {
    setValue(null)
    Keyboard.dismiss()
  }

  return (
    <InputWrapper
      {...props}
      inputElement={inputElement}
      onPress={() => {
        inputElement.current.focus()
      }}
    >
      <TextInput
        ref={inputElement}
        autoFocus={autoFocus}
        placeholderTextColor="rgb(133, 151, 165)"
        placeholder={placeholder}
        multiline={multiline || false}
        value={value ? String(value) : null}
        style={[styles.input, { marginLeft: icon ? 2 * 18 : 0 }, { height: multiline ? DEFAULT_SPACING * 6 : 'auto' }]}
        returnKeyType={returnKeyType || 'done'}
        returnKeyLabel={returnKeyType || 'done'}
        editable={!disabled}
        secureTextEntry={secureTextEntry}
        onChangeText={(val) => {
          if (setValue) setValue(val)
          if (onChangeText) onChangeText(val)
        }}
        onFocus={onFocus}
        onPress={onPress}
        onBlur={onBlur}
        blurOnSubmit={blurOnSubmit}

        onSubmitEditing={onSubmitEditing}
        keyboardType={keyboardType}
      />
    </InputWrapper>
  )
}

export default forwardRef(Input)
