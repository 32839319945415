import * as Yup from 'yup'

import {
  INVALID_MIN,
  REQUIRED,
  INVALID_PERCENT_MAX,
  INVALID_PERCENT_MIN
} from './constants'

export const Fields = {
  categoryId: 'category',
  name: 'name',
  value: 'balance',
  rateOfReturn: 'defaultRate',
  duration: 'liquidityType',
  amortizationType: 'amortizationType',

  revolvingMinPaymentAmount: 'revolvingMinPaymentAmount',
  revolvingMinPaymentPct: 'revolvingMinPaymentPct',
  revolvingPaymentDayOfMonth: 'revolvingPaymentDayOfMonth',

  termOriginalLoanAmount: 'termOriginalLoanAmount',
  termMonths: 'termMonths',
  termEndDate: 'termEndDate',
  termExtraPaymentPreference: 'termExtraPaymentPreference',
}

export const DURATION_SHORT_TERM = 'Short Term'
export const DURATION_LONG_TERM = 'Long Term'

export const AMORTIZATION_TYPE_TERM_LOAN = "TERM_LOAN"
export const AMORTIZATION_TYPE_REVOLVING_LOAN = "REVOLVING_LOAN"

export const RevolvingOptions = [
  'CREDIT_CARD',
  'BNPL_LOAN'
]

export const TERM_PREFERENCE_SHORTEN_LOAN = 'SHORTEN_LOAN'
export const TERM_PREFERENCE_REDUCE_PAYMENT = 'REDUCE_PAYMENT'

export const DurationValues = [DURATION_SHORT_TERM, DURATION_LONG_TERM]
export const TermPreferenceValues = [TERM_PREFERENCE_SHORTEN_LOAN, TERM_PREFERENCE_REDUCE_PAYMENT]

export const ValidationSchema = Yup.object().shape({
  [Fields.categoryId]: Yup.string().required(REQUIRED),
  [Fields.name]: Yup.string()
    .nullable()
    .required(REQUIRED),
  [Fields.duration]: Yup.string()
    .required(REQUIRED)
    .matches(new RegExp(DurationValues.join('|'))),
  [Fields.value]: Yup.number()
    .nullable()
    .min(0, INVALID_MIN)
    .required(REQUIRED),
  [Fields.rateOfReturn]: Yup.string()
    .nullable()
    .required(REQUIRED)
    .test('is-valid-number', 'Must be a valid number', (value) => {
      const number = parseFloat(value);
      return !isNaN(number);
    })
    .test('min', `${INVALID_PERCENT_MIN}-100%`, (value) => {
      const number = parseFloat(value);
      return number >= -100;
    })
    .test('max', INVALID_PERCENT_MAX, (value) => {
      const number = parseFloat(value);
      return number <= 100;
    }),
  // advanced fields
  [Fields.amortizationType]: Yup.string()
    .nullable()
    .matches(new RegExp([AMORTIZATION_TYPE_TERM_LOAN, AMORTIZATION_TYPE_REVOLVING_LOAN].join('|'))),
  [Fields.revolvingMinPaymentAmount]: Yup.number()
    .nullable()
    .min(0, INVALID_MIN)
    .when(Fields.amortizationType, {
      is: AMORTIZATION_TYPE_REVOLVING_LOAN,
      then: Yup.number().required(REQUIRED),
    }),
  [Fields.revolvingMinPaymentPct]: Yup.number()
    .nullable()
    .min(0, INVALID_MIN)
    .max(100, INVALID_PERCENT_MAX)
    .when(Fields.amortizationType, {
      is: AMORTIZATION_TYPE_REVOLVING_LOAN,
      then: Yup.number().required(REQUIRED),
    }),
  [Fields.revolvingPaymentDayOfMonth]: Yup.number()
    .nullable()
    .min(1, INVALID_MIN)
    .max(31, INVALID_MIN)
    .when(Fields.amortizationType, {
      is: AMORTIZATION_TYPE_REVOLVING_LOAN,
      then: Yup.number().required(REQUIRED),
    }),
  [Fields.termOriginalLoanAmount]: Yup.number()
    .nullable()
    .min(0, INVALID_MIN)
    .when(Fields.amortizationType, {
      is: AMORTIZATION_TYPE_TERM_LOAN,
      then: Yup.number().required(REQUIRED),
    }),
  [Fields.termMonths]: Yup.number()
    .nullable()
    .min(0, INVALID_MIN)
    .when(Fields.amortizationType, {
      is: AMORTIZATION_TYPE_TERM_LOAN,
      then: Yup.number().required(REQUIRED),
    }),
  [Fields.termEndDate]: Yup.date()
    .nullable()
    .min(new Date(), 'Must be in the future')
    .when(Fields.amortizationType, {
      is: AMORTIZATION_TYPE_TERM_LOAN,
      then: Yup.date().required(REQUIRED),
    }),
  [Fields.termExtraPaymentPreference]: Yup.string()
    .nullable()
    .matches(new RegExp([TERM_PREFERENCE_SHORTEN_LOAN, TERM_PREFERENCE_REDUCE_PAYMENT].join('|')))
    .when(Fields.amortizationType, {
      is: AMORTIZATION_TYPE_TERM_LOAN,
      then: Yup.string().required(REQUIRED),
    }),

})

export const InitialValues = {
  [Fields.categoryId]: undefined,
  [Fields.value]: undefined,
  [Fields.rateOfReturn]: undefined,
  [Fields.duration]: DURATION_SHORT_TERM,
  [Fields.name]: '',
  // advanced fields
  [Fields.amortizationType]: undefined,
  [Fields.revolvingMinPaymentAmount]: undefined,
  [Fields.revolvingMinPaymentPct]: undefined,
  [Fields.revolvingPaymentDayOfMonth]: undefined,
  [Fields.termOriginalLoanAmount]: undefined,
  [Fields.termMonths]: undefined,
  [Fields.termEndDate]: undefined,
  [Fields.termExtraPaymentPreference]: undefined,
}
