import { del } from 'http'
import { getPersonalMilestones } from './getPersonalMilestones'
import handleError from '../functions/handleError'

export const deletePersonalMilestoneItem = function (payload) {
  return async (dispatch) => {
    try {
      await del(`/api/v1/personal-milestones/${payload.id}/`)

      dispatch(getPersonalMilestones())
    } catch (e) {
      console.warn(e)
      handleError(e, 'Oops. Looks like something went wrong.')
    }
  }
}
