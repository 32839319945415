import { faBriefcase, faCakeCandles, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { View } from 'react-native'
import BuilderProgressBar from '../plans/BuilderProgressBar'

const fastBuilderSteps = [
  {
    label: 'Age',
    icon: faCakeCandles,
  },
  {
    label: 'Location',
    icon: faLocationDot,
  },
  {
    label: 'Career',
    icon: faBriefcase,
  },
]

export default function FastBuilderProgressBar({ currentPage }) {
  return (
    <View style={{ paddingTop: 15, }}>
      <BuilderProgressBar options={fastBuilderSteps} option={currentPage} />
    </View>
  )
}
