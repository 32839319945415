const differentAllocationsDescriptions = {
  bondA: { description: 'allocation is appropriate only for investors with a long-term time horizon, who are willing to ride out short-term market volatility', bond: 0, boldSection: 'aggressive' },
  bondB: { description: 'allocation is appropriate for investors with a long-term time horizon, who are willing to ride out short-term market volatility', bond: 20, boldSection: 'moderately aggressive' },
  bondC: { description: 'allocation is appropriate for savers with long-term goals or investors with a short to intermediate-term time horizon', bond: 40, boldSection: 'moderate' },
  bondD: { description: 'allocation is appropriate for savers with long-term goals or investors with a short to intermediate-term time horizon', bond: 50, boldSection: 'moderate' },
  bondE: { description: 'allocation is appropriate for savers with long-term goals or investors with a short to intermediate-term time horizon', bond: 60, boldSection: 'moderate' },
  bondF: { description: 'allocation is appropriate only for savers with near or intermediate-term financial goals, who are unwilling to accept much market volatility', bond: 80, boldSection: 'moderately conservative' },
  bondG: { description: 'allocation is appropriate only for savers with very near-term financial goals, who are unwilling to accept market volatility', bond: 100, boldSection: 'conservative' },
}

export default differentAllocationsDescriptions
