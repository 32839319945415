import { useState } from 'react'
import { Platform, View, Text, StyleSheet } from 'react-native'
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  minSize,
} from 'rn-responsive-styles'

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Link from '../Link'
import { grayblue, lightestgray, white } from '../../styles/colors'

import CitySearch from '../colleges/CitySearch'
import { DEFAULT_SPACING } from '../../styles'

const basicStyles = {
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: white,
    borderTopWidth: 1,
    borderTopColor: lightestgray,
    padding: DEFAULT_SPACING
  },
  textContainer: { flexDirection: 'row' },
  text: {
    marginLeft: 14,
    color: grayblue,
    fontSize: 14
  },
  linkContainer: { alignItems: 'center' }
}

const mobileStyles = StyleSheet.create(basicStyles)

const useStyles = CreateResponsiveStyle(
  basicStyles,
  { [minSize(DEVICE_SIZES.LG)]: { container: { paddingHorizontal: 40, }, }, }
)

export default function LocationSwitcher({ city, setCity }) {
  const isNotWeb = Platform.OS !== 'web'
  const styles = isNotWeb ? mobileStyles : useStyles()
  const [visible, setVisible] = useState(false)

  return (
    <>
      <View style={styles.container}>
        <View style={styles.textContainer}>
          <FontAwesomeIcon icon="location-dot" color="rgb(205, 69, 87)" size={16} />
          <Text style={styles.text}>{city.name}</Text>
        </View>
        <Link onPress={() => setVisible(true)} containerStyle={styles.linkContainer}>Edit</Link>
      </View>

      <CitySearch
        visible={visible}
        setVisible={setVisible}
        location={city}
        setCity={(loc) => {
          setCity(loc)
        }}
      />
    </>
  )
}
