import { useLayoutEffect } from 'react'

import IntroScreen from './components/IntroScreen'

export default function MillionaireCalculatorIntroScreen({ navigation }) {
  useLayoutEffect(() => {
    navigation.setOptions({ headerShown: false });
  }, [navigation])

  return (
    <IntroScreen
      title="Millionaire Calculator"
      subtitle="What might it take to save one million dollars? This financial calculator helps you find out. Enter in your current savings plan and graphically view your financial results for each year until you retire."
      image={require('./assets/finn/money-bag.png')}
      buttonText="Next"
      onPress={() => {
        navigation.navigate('Modal', { screen: 'Millionaire Calculator' })
      }}
    />
  )
}
